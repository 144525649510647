import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Button, CircularProgress, TextField } from '@material-ui/core'
import _ from 'lodash'

import agent from 'agent'
import { REQUEST_PASSWORD_RESET } from 'constants/actionTypes'
import { LOGIN } from 'routes/routes'
import { validateEmail } from 'utils/formValidation'

const mapStateToProps = state => ({
  requestingPasswordReset: state.auth.requestingPasswordReset
})

const mapDispatchToProps = dispatch => ({
  forgotPassword: email => dispatch({ type: REQUEST_PASSWORD_RESET, payload: agent.Auth.forgotPassword(email) })
})

class ForgotPassword extends React.Component {
  state = {
    email: '',
    formError: {},
    requestSent: false
  }

  componentDidUpdate(prevProps) {
    if (prevProps.requestingPasswordReset && !this.props.requestingPasswordReset) {
      this.setState({ requestSent: true })
    }
  }

  onChangeFormElement = e => {
    const type = e.target.name
    const value = e.target.value
    this.setState({ [type]: value })
  }

  validateFormElement = type => {
    let errorMsg = ''
    if (type === 'email') {
      errorMsg = validateEmail(this.state[type])
    }

    let tempFormError = this.state.formError
    if (errorMsg) {
      tempFormError[type] = errorMsg
    } else {
      delete tempFormError[type]
    }
    this.setState({ formError: tempFormError })
  }

  requestPasswordReset = () => {
    this.validateFormElement('email')

    if (_.isEmpty(this.state.formError)) {
      this.props.forgotPassword(this.state.email.toLocaleLowerCase())
    }
  }

  render() {
    const { requestingPasswordReset } = this.props
    const { email, formError, requestSent } = this.state

    return (
      <div className="landing d-flex" style={{ height: '100%' }}>
        <Helmet>
          <title>Forgot Password | EPA</title>
          <meta
            name="description"
            content="Sign up for online tutoring classes with Tenopy to prepare for Mathematics, Science and English exams"
          />
          <meta
            name="keywords"
            content="Online tuition,Math tuition,English tuition,Secondary school tuition,Primary school tuition,Online tuition singapore,Online learning,Online education,Education platform,Online tutor,Home tutor,home tuition,science tuition,sa1,sa2,ca1,ca2,psle,revision,online revision,online study,online learning,psle revision"
          />
        </Helmet>
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-6">
              <h2 className="text-center">Having trouble logging in?</h2>
              <div className="text-muted text-center">
                Just enter the email address you registered with and we&#39;ll send you an email with instructions on
                how to reset your password
              </div>

              <p className="text-center header-desc mb-5">
                <Link to={LOGIN}>Back to log in</Link>
              </p>
              {requestSent && (
                <div className="alert alert-success">
                  Please check your email for instructions on how to reset your password.
                </div>
              )}
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 col-md-6 col-lg-4">
              <TextField
                label="Email"
                fullWidth
                onChange={event => this.setState({ email: event.target.value })}
                value={email}
                margin="normal"
                className="bg-light-gray mb-3"
                variant="outlined"
                error={formError.email ? true : false}
                helperText={formError.email}
              />

              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={requestingPasswordReset || requestSent}
                onClick={this.requestPasswordReset}>
                {requestSent ? 'Please check your email' : 'Go'}
                {requestingPasswordReset && <CircularProgress size={15} color="primary" className="ml-2" />}
              </Button>

              {requestSent && (
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  fullWidth
                  onClick={() => {
                    this.setState({ requestSent: false, email: '' })
                  }}>
                  Re-enter email
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
