import React from 'react'
import { connect } from 'react-redux'
import { Button, CircularProgress, Divider } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { Clear } from '@material-ui/icons'
import moment from 'moment'

import agent from 'agent'
import { CREATE_SUBMISSION, UPDATE_SUBMISSION } from 'constants/actionTypes'
import SubmitConfirmationDialog from 'components/online-class/dialog-submit-homework-confirmation'
import AudioRecorder from 'components/shared/Media/AudioRecorder'
import Waveform from 'components/shared/Media/Waveform'

import { Modal, ModalHeader } from 'reactstrap'

const mapStateToProps = state => ({
  creatingSubmission: state.submission.creatingSubmission,
  updatingSubmission: state.submission.updatingSubmission,
  uploadFail: state.submission.uploadFail
})

const mapDispatchToProps = dispatch => ({
  submitHomework: (data, homeworkId) =>
    dispatch({ type: CREATE_SUBMISSION, payload: agent.Submission.create('classic', data), homeworkId }),
  updateSubmission: (data, submissionId) =>
    dispatch({ type: UPDATE_SUBMISSION, payload: agent.Submission.update('classic', submissionId, data), submissionId })
})

// TODO: review this component and merge it with /pages/app/dashboard/dialog-submit-homework.js
class DialogSubmitHomework extends React.Component {
  state = {
    openText: '',
    files: [],
    submitConfirmationDialogOpen: false,
    alertMessage: '',
    audioFiles: [],
    pendingAcceptAudioFile: false
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dialogOpen !== this.props.dialogOpen && !this.props.dialogOpen) {
      this.setState({ openText: '', files: [], audioFiles: [], alertMessage: '' })
    }

    if (prevProps.creatingSubmission && !this.props.creatingSubmission) {
      if (this.props.uploadFail) {
        this.setState({
          alertMessage: 'Something went wrong when uploading your submission. Kindly contact us for further assistance.'
        })
      } else {
        this.props.refreshSubmission()
      }
    }

    if (prevProps.updatingSubmission && !this.props.updatingSubmission) {
      if (this.props.uploadFail) {
        this.setState({
          alertMessage: 'Something went wrong when uploading your submission. Kindly contact us for further assistance.'
        })
      } else {
        this.props.refreshSubmission()
      }
    }
  }

  addFile = e => {
    let { files } = this.state
    let input = e.target
    let selectedFile = files || []
    for (let i = 0; i < input.files.length; i++) {
      selectedFile.push(input.files[i])
    }
    this.setState({
      files,
      alertMessage: ''
    })
  }

  removeFile = index => {
    let { files } = this.state
    files.splice(index, 1)
    this.setState({ files: files })
  }

  handleContentChanges = event => {
    let newContent = event.editor.getData()
    this.setState({ openText: newContent })
  }

  acceptAudioRecording = audioBlob => {
    let { audioFiles } = this.state
    audioFiles.push(audioBlob)
    this.setState({ audioFiles, alertMessage: '', pendingAcceptAudioFile: false })
  }

  removeRecordingFromList = index => () => {
    let { audioFiles } = this.state
    audioFiles.splice(index, 1)
    this.setState({ audioFiles })
  }

  togglePendingAccept = pendingAcceptAudioFile => {
    this.setState({ pendingAcceptAudioFile })
  }

  submit = () => {
    let { homeworkData, submissionData, homeworkFileName } = this.props
    let { files, audioFiles } = this.state

    if (homeworkData.audio_only) {
      if (this.state.pendingAcceptAudioFile) {
        this.setState({ alertMessage: 'You need to click on "Accept Recording" button before submitting' })
        return
      }

      if (audioFiles.length === 0) {
        this.setState({ alertMessage: 'Please record your homework and upload' })
        return
      }
    } else {
      if (files.length === 0) {
        this.setState({ alertMessage: 'Please select your homework file to upload' })
        return
      }
    }

    let homeworkId = homeworkData._id
    let submissionId = submissionData ? submissionData._id : ''
    let timestamp = Math.trunc(moment.utc().valueOf() / 1000)

    // TODO: optimize this part, too many repeating code
    if (submissionId) {
      let patchSubmissionData = new FormData()
      for (let i = 0; i < files.length; i++) {
        let fileType = files[i].name.split('.').pop()
        let metadataFileName = encodeURIComponent(
          files.length > 1
            ? `${homeworkFileName}_${i + 1}_${timestamp}.${fileType}`
            : `${homeworkFileName}_${timestamp}.${fileType}`
        )
        patchSubmissionData.append('attachment', files[i], metadataFileName)
      }
      for (let i = 0; i < audioFiles.length; i++) {
        let metadataFileName = encodeURIComponent(
          audioFiles.length > 1
            ? `${homeworkFileName}_${i + 1}_${timestamp}.wav`
            : `${homeworkFileName}_${timestamp}.wav`
        )
        patchSubmissionData.append('attachment', audioFiles[i], metadataFileName)
      }
      patchSubmissionData.append('status', 'SUBMITTED')
      this.props.updateSubmission(patchSubmissionData, submissionId)
    } else {
      let newSubmission = new FormData()
      for (let i = 0; i < files.length; i++) {
        let fileType = files[i].name.split('.').pop()
        let metadataFileName = encodeURIComponent(
          files.length > 1
            ? `${homeworkFileName}_${i + 1}_${timestamp}.${fileType}`
            : `${homeworkFileName}_${timestamp}.${fileType}`
        )
        newSubmission.append('attachment', files[i], metadataFileName)
      }
      for (let i = 0; i < audioFiles.length; i++) {
        let metadataFileName = encodeURIComponent(
          audioFiles.length > 1
            ? `${homeworkFileName}_${i + 1}_${timestamp}.wav`
            : `${homeworkFileName}_${timestamp}.wav`
        )
        newSubmission.append('attachment', audioFiles[i], metadataFileName)
      }
      newSubmission.append('homework', homeworkId)
      newSubmission.append('status', 'SUBMITTED')
      this.props.submitHomework(newSubmission, homeworkId)
    }
  }

  toggleSubmitConfirmationDialog = abortSubmission => {
    this.setState({ submitConfirmationDialogOpen: !this.state.submitConfirmationDialogOpen })
    if (abortSubmission) {
      this.props.toggleDialog()
    }
  }

  closeSubmitHomeworkDialog = () => {
    let { files, openText } = this.state

    if (!this.props.creatingSubmission) {
      if (files.length > 0 || openText) {
        this.setState({ submitConfirmationDialogOpen: true })
      } else {
        this.props.toggleDialog()
      }
    }
  }

  render() {
    let { dialogOpen, submissionData, creatingSubmission, updatingSubmission, homeworkData } = this.props
    let { files, audioFiles, submitConfirmationDialogOpen, alertMessage } = this.state

    if (!homeworkData) {
      return null
    }

    let dialogTitle = 'Submit Homework/ Revision Notes'
    if (submissionData) {
      if (submissionData.status === 'SUBMITTED') {
        dialogTitle = 'Submit Homework/ Revision Notes'
      } else if (submissionData.status === 'RESUBMISSION_REQUIRE') {
        dialogTitle = 'Resubmit your Homework/ Revision Notes'
      }
    }

    let inProgress = creatingSubmission || updatingSubmission

    return (
      <div>
        <Modal size="lg" centered={true} isOpen={dialogOpen}>
          <ModalHeader toggle={this.closeSubmitHomeworkDialog} className={'border-bottom-0'}>
            <span className="h3 mb-0">{dialogTitle}</span>
          </ModalHeader>
          <Divider />
          <div className="modal-body">
            {submissionData && submissionData.status === 'SUBMITTED' && (
              <Alert severity="warning" className="mb-3">
                Please note that you can only have one submission per lesson. This submission will replace your previous
                submission.
              </Alert>
            )}

            {homeworkData.audio_only ? (
              <React.Fragment>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12">
                      <Alert severity="info" className="mb-3">
                        For your homework, you are required to record your answers and then upload the recording
                      </Alert>
                    </div>
                    <div className="col-12">
                      <AudioRecorder
                        onSave={this.acceptAudioRecording}
                        togglePendingAccept={this.togglePendingAccept}
                      />
                    </div>
                  </div>
                </div>

                {audioFiles.length > 0 && (
                  <div className="form-group mb-0">
                    <h3>Your Submissions</h3>
                    {audioFiles.map((blob, index) => {
                      return (
                        <div key={index} className="border p-2 mb-2">
                          <div className="mb-2">
                            <div className="row">
                              <div className="col">Audio Recording {index + 1}</div>
                              <div className="col-auto d-flex align-items-center">
                                <Clear
                                  onClick={this.removeRecordingFromList(index)}
                                  className="hover--danger pointer"
                                />
                              </div>
                            </div>
                          </div>
                          <Waveform src={blob} isBlob={true} />
                        </div>
                      )
                    })}
                  </div>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="form-group">
                  <div className="row">
                    <div className="col-auto">
                      <label htmlFor={`uploads`}>
                        <Button color={'primary'} variant={'contained'} component={'span'}>
                          Add Photos/Files
                        </Button>
                      </label>
                      <input
                        id={`uploads`}
                        type="file"
                        className="d-none"
                        onChange={this.addFile}
                        accept={'.jpg,.jpeg,.gif,.png,.bmp, .pdf, .doc, .docx'}
                        multiple
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <div className="text-muted">
                        <span className="font-weight-semibold">Note:</span> You can submit multiple files
                        (image/pdf/word) in one submission. <br />
                        Please upload all homework photos / files in one file.
                        <br />
                        Please upload your revision notes in one file.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group mb-0">
                  {files.map((file, index) => {
                    return (
                      <div key={index} className="card shadow mb-3 p-2" style={{ backgroundColor: '#f5f5f5' }}>
                        <div className="row">
                          <div className="col-auto d-flex align-items-center text-muted">
                            {(file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                              file.type === 'application/msword') && <i className="material-icons">description</i>}
                            {file.type === 'application/pdf' && <i className="material-icons">picture_as_pdf</i>}
                            {file.type.split('/')[0] === 'image' && <i className="material-icons">image</i>}
                          </div>
                          <div className="col d-flex align-items-center">{file.name}</div>
                          <div className="col-auto d-flex align-items-center text-muted">
                            <i
                              className="material-icons clickable"
                              onClick={() => {
                                this.removeFile(index)
                              }}>
                              clear
                            </i>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </React.Fragment>
            )}

            {alertMessage && (
              <Alert severity="error" className="mb-2">
                {alertMessage}
              </Alert>
            )}

            <div className="row justify-content-end mt-3">
              <div className="col-auto">
                <Button variant="text" color="primary" onClick={this.props.toggleDialog}>
                  Cancel
                </Button>
              </div>
              <div className="col-md-auto col">
                <Button
                  onClick={this.submit}
                  variant="contained"
                  color="primary"
                  disabled={inProgress}
                  fullWidth={true}>
                  Submit {inProgress && <CircularProgress className="ml-2" size={15} />}
                </Button>
              </div>
            </div>
          </div>
        </Modal>

        <SubmitConfirmationDialog
          dialogOpen={submitConfirmationDialogOpen}
          toggleDialog={() => {
            this.toggleSubmitConfirmationDialog(false)
          }}
          abortSubmission={() => {
            this.toggleSubmitConfirmationDialog(true)
          }}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogSubmitHomework)
