import React from 'react'
import { connect } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import moment from 'moment'
import _ from 'lodash'
import { isMobile } from 'react-device-detect'

import agent from 'agent'
import { TA, TUTOR } from 'constants/userTypes'
import { COUNT_ALL_LESSON_RESOURCES, GET_LESSON_RESOURCES } from 'constants/actionTypes'
import FilePreviewDialog from 'components/dialog/file-preview'
import EditResourceDialog from 'components/online-class/dialog-edit-resource'
import DeleteResourceDialog from 'components/online-class/dialog-delete-resource'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  countingResources: state.resources.countingResources,
  gettingResources: state.resources.gettingResources,
  resources: state.resources.lessonResources
})

const mapDispatchToProps = dispatch => ({
  countResources: (lessonId, type) =>
    dispatch({
      type: COUNT_ALL_LESSON_RESOURCES,
      payload: agent.Lessons.getLessonResources2(lessonId, type),
      lessonId
    }),
  getResources: (lessonId, type, perPage, page) =>
    dispatch({
      type: GET_LESSON_RESOURCES,
      payload: agent.Lessons.getLessonResources2(lessonId, type, perPage, page),
      lessonId
    })
})

class ResourceSection extends React.Component {
  state = {
    resourceTypes: null,
    resources: [],
    filePreviewDialogOpen: false,
    selectedResource: null,
    editResourceDialogOpen: false,
    deleteResourceDialogOpen: false
  }

  componentDidMount() {
    let { currentUser } = this.props
    let resourceTypes = ['notes', 'optional', 'homework']
    if (currentUser._type === TUTOR || currentUser._type === TA) {
      resourceTypes.push('teaching')
    }
    this.setState({ resourceTypes })
  }

  componentDidUpdate(prevProps, prevState) {
    let { resourceTypes } = this.state
    let { lessonId, resources } = this.props

    if (prevState.resourceTypes !== this.state.resourceTypes) {
      if (!resources || !resources[lessonId]) {
        this.props.countResources(lessonId, resourceTypes)
      } else {
        this.setState({ resources: resources[lessonId].data })
      }
    }

    if (
      prevProps.countingResources &&
      prevProps.countingResources.includes(lessonId) &&
      !this.props.countingResources.includes(lessonId) &&
      this.props.resources[lessonId]
    ) {
      let tempResources = this.props.resources[lessonId]
      if (tempResources.total === tempResources.data.length) {
        this.setState({ resources: _.sortBy(tempResources.data, ['type']) })
      } else {
        let remainingPage = Math.ceil((tempResources.total - tempResources.data.length) / 50)
        for (let i = 0; i < remainingPage; i++) {
          this.props.getResources(this.props.lesson._id, resourceTypes, 50, i + 2)
        }
      }
    }

    if (
      prevProps.resources &&
      prevProps.resources[lessonId] &&
      prevProps.resources[lessonId].total > 0 &&
      prevProps.resources[lessonId].data?.length !== this.props.resources[lessonId].data?.length
    ) {
      let tempResources = this.props.resources[lessonId]
      if (tempResources.total === tempResources.data?.length) {
        this.setState({ resources: tempResources.data })
      }
    }
  }

  toggleFilePreviewDialog = () => {
    this.setState({ filePreviewDialogOpen: !this.state.filePreviewDialogOpen })
  }

  previewFile = selectedResource => {
    this.setState({
      selectedResource,
      filePreviewDialogOpen: true
    })
  }

  toggleEditResourceDialog = selectedResource => () => {
    this.setState({
      selectedResource,
      editResourceDialogOpen: !this.state.editResourceDialogOpen
    })
  }

  toggleDeleteResourceDialog = selectedResource => () => {
    this.setState({
      selectedResource,
      deleteResourceDialogOpen: !this.state.deleteResourceDialogOpen
    })
  }

  render() {
    let { countingResources, lessonId, lessonDate, currentUser } = this.props
    let {
      resources,
      resourceTypes,
      filePreviewDialogOpen,
      selectedResource,
      editResourceDialogOpen,
      deleteResourceDialogOpen
    } = this.state

    return (
      <div className="lesson__section w-100">
        <div className="list__resources">
          {countingResources === lessonId ? (
            <CircularProgress size={25} color={'primary'} className="mt-3" />
          ) : resources?.length === 0 ? (
            <div className="text-muted mt-3">
              {moment() > moment(lessonDate)
                ? 'There are no resources for this lesson.'
                : 'Resources for this lesson will be uploaded soon.'}
            </div>
          ) : (
            <div className="row">
              {resourceTypes.map((t, index) => {
                let filteredResources = _.filter(resources, function (o) {
                  return o.type === t
                })

                if (filteredResources.length > 0) {
                  return (
                    <div className="col-md-12 col-12" key={index}>
                      <div className="row">
                        <div className="col-12">
                          <h3 className="text-capitalize my-3">{t}</h3>
                        </div>
                        <div className="col-12">
                          {filteredResources.map((item, index) => (
                            <div key={index} className="card px-3 py-2 mb-2" style={{ backgroundColor: '#f5f5f5' }}>
                              <div className="row align-items-center">
                                <div className="col">
                                  <span className="link" onClick={() => this.previewFile(item)}>
                                    {item.name}
                                  </span>
                                </div>
                                {item.created_by === currentUser._id ? (
                                  <span className="text-blue mr-2 mr-lg-5 ml-2 ml-lg-0">
                                    {' '}
                                    (You uploaded on {moment(item.created).format('LLLL')})
                                  </span>
                                ) : (
                                  <span className="ml-2 ml-lg-0" style={{ marginRight: isMobile ? '0.5rem' : '8rem' }}>
                                    (Teacher uploaded on {moment(item.created).format('LLLL')}){' '}
                                  </span>
                                )}
                                <div className="col-auto">
                                  {item.created_by === currentUser._id && (
                                    <>
                                      <i
                                        className="material-icons text-blue align-self-center clickable mr-2"
                                        onClick={this.toggleEditResourceDialog(item)}>
                                        edit
                                      </i>
                                      <i
                                        className="material-icons text-danger align-self-center clickable mr-5"
                                        onClick={this.toggleDeleteResourceDialog(item)}>
                                        delete
                                      </i>
                                    </>
                                  )}
                                  <a href={item.material_meta.location} download>
                                    {/* <i className="fa fa-download align-self-center" /> */}
                                    <i className="material-icons text-danger align-self-center clickable">download</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )
                }
                return null
              })}
            </div>
          )}
        </div>

        <EditResourceDialog
          isOpen={editResourceDialogOpen}
          close={this.toggleEditResourceDialog()}
          resourceData={selectedResource}
        />

        <DeleteResourceDialog
          isOpen={deleteResourceDialogOpen}
          close={this.toggleDeleteResourceDialog()}
          resourceData={selectedResource}
        />

        <FilePreviewDialog
          isOpen={filePreviewDialogOpen}
          close={this.toggleFilePreviewDialog}
          file={selectedResource}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceSection)
