import React from 'react'
import NumberFormat from 'react-number-format'

export const NumberOnly = props => {
  const { inputRef, onChange, name, ...other } = props
  return (
    <NumberFormat
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      onValueChange={values => {
        onChange({
          target: {
            name: name,
            value: values.value
          }
        })
      }}
    />
  )
}
