import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import Proptypes from 'prop-types'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Slide,
  Select,
  MenuItem,
  TextField
} from '@material-ui/core'
import agent from 'agent'
import { UPDATE_SUBMISSION } from 'constants/actionTypes'

const RESUBMISSION_REASON = ['Pictures are not clear.', 'Missing pages from the homework.', 'Wrong homework uploaded.']

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  updatingSubmission: state.submission.updatingSubmission
})

const mapDispatchToProps = dispatch => ({
  updateSubmission: (data, submissionId) =>
    dispatch({ type: UPDATE_SUBMISSION, payload: agent.Submission.update('classic', submissionId, data), submissionId })
})

class AskResubmissionDialog extends React.Component {
  state = {
    isOpen: false,
    comment: '',
    otherReason: ''
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.updatingSubmission && !this.props.updatingSubmission) {
      this.setState({
        isOpen: false,
        comment: ''
      })
    }
  }

  toggleDialog = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  onChangeFormElement = e => {
    let type = e.target.name
    let value = e.target.value
    this.setState({ [type]: value })
  }

  update = () => {
    let { comment, otherReason } = this.state
    let { submissionData } = this.props
    let resubmissionComment = otherReason ? otherReason : comment
    this.props.updateSubmission({ status: 'RESUBMISSION_REQUIRE', comment: resubmissionComment }, submissionData._id)
  }

  render() {
    let { buttonFullWidth, buttonText, submissionData, updatingSubmission } = this.props
    let { isOpen, comment, otherReason } = this.state

    return (
      <Fragment>
        <Button
          variant="contained"
          color="primary"
          className="mb-1"
          onClick={this.toggleDialog}
          fullWidth={buttonFullWidth}
          disableRipple
          disableFocusRipple
          disableTouchRipple>
          {buttonText || 'Ask For Resubmission'}
        </Button>

        <Dialog open={isOpen} onClose={this.toggleDialog} maxWidth="sm" fullWidth TransitionComponent={Transition}>
          <DialogTitle className="">Ask for Resubmission: ({submissionData.created_by.name})</DialogTitle>
          <DialogContent>
            <FormControl className="mb-3 w-100">
              <InputLabel>Select a reason</InputLabel>
              <Select value={comment} onChange={this.onChangeFormElement} name="comment">
                {RESUBMISSION_REASON.map((reason, index) => {
                  return (
                    <MenuItem key={index} value={reason}>
                      {reason}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>

            <TextField
              label="Other Reason"
              name="otherReason"
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={this.onChangeFormElement}
              value={otherReason}
              helperText="Other reason will overwrite selected reason above. Use this if the reason you need for resubmission is not included"
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={this.update} disabled={updatingSubmission}>
              Submit {updatingSubmission && <CircularProgress className="ml-2" size={15} color="primary" />}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

AskResubmissionDialog.propTypes = {
  buttonText: Proptypes.string,
  buttonFullWidth: Proptypes.bool,
  submissionData: Proptypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(AskResubmissionDialog)
