import React from 'react'
import agent from '../../../agent'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import {
  GET_CLASS_LIST,
  SET_CLASS_PAGE,
  SET_SELECTED_CLASS,
  GET_USER_CLASS,
  GET_CHILD_DETAILS,
  GET_TUTOR_LESSONS
} from '../../../constants/actionTypes'
import _ from 'lodash'
import { LESSON_PAGE } from 'routes/tutorRoutes'

import { MY_CLASS_DETAILS, USER_STUDENT_PROFILE_EDIT } from '../../../routes/routes'
import ClassItem from '../../../components/shared/Classes/ClassItem'

import moment from 'moment'
import { isMobile } from 'react-device-detect'
import {
  TextField,
  Input,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Button,
  CircularProgress,
  CardContent,
  Card,
  Popover
} from '@material-ui/core'

import Pagination from '../../../utils/Pagination'
import { TA, TUTOR, PARENT } from '../../../constants/userTypes'
import LessonItem from 'components/shared/Classes/LessonItem'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  classes: state.classes.classes,
  classCount: state.classes.classCount,
  classPage: state.classes.classPage,
  chosenClass: state.classes.chosenClass
})

const mapDispatchToProps = dispatch => ({
  getClassList: (classType, perPage, page, additional) =>
    dispatch({
      type: GET_CLASS_LIST,
      payload: agent.Classes.getClassList(classType, perPage, page, {
        sortBy: 'lesson_name',
        order: 'desc',
        is_active: true,
        ...additional
        // start_date: {
        //   $gte: moment('2022-09-01T00:00:00Z').toISOString()
        // },
        //hard to use class because it is based on per lesson, and to use next lesson time is difficult.
        //also easier to just use the closest lesson time.
        //might as well just set this to only ended lessons
      })
    }),
  setPage: page => dispatch({ type: SET_CLASS_PAGE, payload: page }),
  getStudentClass: userId => dispatch({ type: GET_USER_CLASS, payload: agent.Classes.getStudentClass(userId) })
})

class AllClasses extends React.Component {
  constructor(props) {
    super()

    this.viewClass = selectedClass => {
      let classDetailUrl = MY_CLASS_DETAILS.replace(':classId', selectedClass._id)
      this.props.history.push(classDetailUrl)
    }

    this.onChangePage = page => {
      this.props.setPage(page)
      this.setState({ currentPage: page })
    }

    // this.changeSubject = e => {
    //   this.setState({ subject: e.target.value })
    // }
    this.changeStudent = e => {
      this.setState({ student: e.target.value })
    }

    this.changeText = e => {
      this.setState({ name: e.target.value })
    }

    this.state = {
      perPage: 18,
      currentPage: props.classPage ? props.classPage : 1,
      searchQuery: null,
      name: '',
      student: ''
    }
  }

  componentDidMount() {
    const { currentUser } = this.props
    if (currentUser._type === PARENT) {
      this.props.getStudentClass(this.props.currentUser.child)
    } else {
      this.props.getClassList('class', this.state.perPage, this.state.currentPage)
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPage !== this.state.currentPage) {
      const { perPage, currentPage, searchQuery } = this.state

      if (searchQuery) {
        this.props.getClassList('class', perPage, currentPage, searchQuery)
      } else {
        this.props.getClassList('class', perPage, currentPage)
      }
    }
  }

  searchClasses = e => {
    e.preventDefault()
    const { name, student, perPage } = this.state
    let query = {}
    if (name) {
      query.name = { $regex: `.*${name}.*`, $options: 'i' }
    }
    if (student) {
      query.student = { $regex: `.*${student}.*`, $options: 'i' }
    }
    this.setState({ searchQuery: query })
    this.props.getClassList('class', perPage, 1, query)
  }

  resetSearch = () => {
    const { perPage, currentPage } = this.state
    this.setState({ name: '', student: '', searchMode: null })
    this.props.getClassList('class', perPage, currentPage)
  }

  render() {
    const { classes, classCount, currentUser, chosenClass } = this.props
    const { currentPage, perPage, name, student } = this.state

    return (
      <div className="app-wrapper">
        {(currentUser._type === TA || currentUser._type === TUTOR) && (
          <div className="my-3">
            <form onSubmit={this.searchClasses}>
              <div className="row">
                <div className="col-10">
                  <TextField label={'Class Name'} value={name} onChange={this.changeText} fullWidth />
                </div>
                <div className="col">
                  {/* <InputLabel htmlFor="student-selector">Student name</InputLabel> */}
                  {/* <TextField label={'Student Name'} value={student} onChange={this.changeStudent} fullWidth /> */}

                  {/* <Select value={subject} onChange={this.changeSubject} input={<Input id="subject-selector" />}>
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={'English'}>English</MenuItem>
                      <MenuItem value={'Mathematics'}>Mathematics</MenuItem>
                      <MenuItem value={'Science'}>Science</MenuItem>
                    </Select> */}
                </div>
                <div className="col-auto d-flex align-items-center">
                  <Button type={'submit'} variant={'contained'} color={'primary'}>
                    Search
                  </Button>
                </div>
                <div className="col-auto d-flex align-items-center">
                  <Button variant={'contained'} color={'default'} type={'button'} onClick={this.resetSearch}>
                    Reset
                  </Button>
                </div>
              </div>
            </form>
          </div>
        )}
        <h3 className="page-title mt-3"> Rest of classes</h3>
        {currentUser._type === PARENT ? (
          chosenClass ? (
            chosenClass.length > 0 ? (
              <div className="grid__renderer mt-3">
                {chosenClass?.map((classData, index) => {
                  return (
                    <ClassItem
                      key={index}
                      className={'grid__item'}
                      classData={classData}
                      viewClass={() => {
                        this.viewClass(classData)
                      }}
                    />
                  )
                })}
              </div>
            ) : (
              <h4 className="mt-2 ml-3">No Classes yet</h4>
            )
          ) : (
            <div className="my-5 text-center">
              <CircularProgress size={40} color={'primary'} />
            </div>
          )
        ) : classes ? (
          classes.length > 0 ? (
            <div className="grid__renderer mt-3">
              {classes?.map((classData, index) => {
                return (
                  <ClassItem
                    key={index}
                    className={'grid__item'}
                    classData={classData}
                    viewClass={() => {
                      this.viewClass(classData)
                    }}
                    isTutor={currentUser._type === TUTOR || currentUser._type === TA}
                  />
                )
              })}
            </div>
          ) : (
            <h4 className="mt-2 ml-3">No Classes yet</h4>
          )
        ) : (
          <div className="my-5 text-center">
            <CircularProgress size={40} color={'primary'} />
          </div>
        )}

        <div className="mt-3">
          {!!classCount && (
            <Pagination
              itemCount={classCount}
              pageSize={perPage}
              initialPage={currentPage}
              onChangePage={this.onChangePage}
            />
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AllClasses))
