import React from 'react'
import { Helmet } from 'react-helmet'

export default function TermsAndConditionsForReferral() {
  return (
    <div className="landing">
      <Helmet>
        <title>Terms and Conditions for Tenopy Referral Programme | Tenopy</title>
      </Helmet>
      <section className="bg-white">
        <div className="section__header">
          <div className="title">Terms and Conditions for Tenopy Referral Programme</div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 section__text text-justify">
              <p>
                The Tenopy Referral Programme (&#8220;Programme&#8221;) allows both existing registered Tenopy users
                (&#8220;Referrer&#8221;) and new Tenopy users (&#8220;Referee&#8221;) to earn free credit when the
                Referee is referred to Tenopy.
              </p>
              <p>
                The following Terms and Conditions applies to the Programme. By signing up an account with Tenopy, both
                the Referrer and Referee agree to be bound by these Terms and Conditions.
              </p>
              <p className="text-underline mb-0">
                <strong>Definition</strong>
              </p>
              <p>
                &#8220;Referrer&#8221; - A registered user of Tenopy.
                <br />
                &#8220;Referee&#8221; - A new user who is referred by a Referrer.
                <br />
                &#8220;Programme&#8221; - Tenopy Referral Programme.
                <br />
              </p>

              <p className="text-underline mb-0">
                <strong>Referral eligibility</strong>
              </p>
              <p>
                For both the Referrer and Referee to be eligible for the referral, they should meet the conditions
                below:
              </p>
              <p>Referrer</p>
              <ol>
                <li>Is an existing user of Tenopy;</li>
                <li>Share his/her invite code/link with the Referee;</li>
                <li>Refers a user who does not own a Tenopy account;</li>
                <li>Receive the referral credits only after the Referee has purchased a lesson with Tenopy.</li>
              </ol>
              <p>Referee</p>
              <ol>
                <li>Does not own a Tenopy account;</li>
                <li>Create a new account using the invite link/code from the Referrer;</li>
                <li>Has to purchase at least 1 lesson for the Referrer to receive credit.</li>
              </ol>

              <p className="text-underline mb-0">
                <strong>Referral rewards and usage</strong>
              </p>
              <ol>
                <li>
                  The Referrer and Referee will each receive a referral reward of $20 credit that can be used to offset
                  the payment for any classes/ courses/ workshops listed on Tenopy website unless otherwise specified.
                </li>
                <li>
                  Credit granted under the Programme is strictly to be used for buying classes/ courses/ workshops on
                  Tenopy.{' '}
                </li>
              </ol>

              <p className="text-underline mb-0">
                <strong>Referral limit</strong>
              </p>
              <p>
                A Referrer may refer an unlimited number of Referees. Similarly, there is no limit to the amount of
                credits that the Referrer can obtain from referring new users to Tenopy, provided the users meet the
                conditions of being a Referee under clause 2.1.2.
              </p>

              <p className="text-underline mb-0">
                <strong>Violation of terms and conditions</strong>
              </p>
              <p>
                Tenopy reserves the right to void any referral or clawback the rewards granted to the user if the user
                is found to have violated any of the terms and conditions; and/or involved in any fraudulent, dishonest
                and inappropriate, as determined solely by Tenopy, activities relating to this Programme.
              </p>

              <p className="text-underline mb-0">
                <strong>Change of terms</strong>
              </p>
              <p>Tenopy reserves the right to change the terms and conditions at any time, without any given notice.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
