import React from 'react'
import Proptypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Alert = props => {
  return (
    <Dialog open={props.isOpen} onClose={props.close} TransitionComponent={Transition}>
      <DialogTitle>
        <span className="h2 mb-0">{props.title}</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span dangerouslySetInnerHTML={{ __html: props.content }} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close} color="primary" variant="contained">
          {props.buttonText ?? 'Close'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

Alert.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired,
  title: Proptypes.string.isRequired,
  content: Proptypes.string.isRequired,
  buttonText: Proptypes.string
}

export default Alert
