import React from 'react'
import { isMobile } from 'react-device-detect'
import { Avatar } from '@material-ui/core'

class DepartmentTeacherCard extends React.Component {
  render() {
    const { name, department, description, image, education, experience, className } = this.props

    return (
      <div className={`col-lg-4 pt-4  ${className && className} `}>
        <div className="col-lg-12" style={{ backgroundColor: '#fff', borderRadius: '8px', minHeight: '17vh' }}>
          <div
            className="row"
            style={{ paddingTop: '2vh', paddingLeft: '1.3vh', paddingRight: '1vh', paddingBottom: '2vh' }}>
            {/* <div className="col-lg-3">
              {isMobile ? (
                <div className="col-12">
                  <div className="d-flex flex-row justify-content-center mb-3">
                    <Avatar className="size-120 avatar-shadow mr-3" alt={name} src={image} />
                  </div>
                </div>
              ) : (
                <div
                  className="col-auto align-self-end"
                  style={{
                    overflow: 'hidden',
                    minHeight: '10.05vh',
                    backgroundImage: `url(${image})`,
                    width: '10.05vh',
                    backgroundSize: 'cover',
                    borderRadius: '4px'
                  }}></div>
              )}
            </div> */}
            <div className="col-lg-12">
              <div className="section__header_landing_title">
                <div
                  className="description mb-2 text-left"
                  style={{
                    fontWeight: '700'
                  }}>
                  {name}
                </div>
                <div className="text-left mb-2 explanation" style={{ color: '#FD6502', fontWeight: '700' }}>
                  {department}
                </div>
                <div className="text-left explanation">{description}</div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div
                        className="col-lg-12 text-left explanation"
                        style={{
                          color: '#00000080'
                        }}>
                        {experience}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <div
                        className="col-lg-12 text-left explanation"
                        style={{
                          color: '#00000080'
                        }}>
                        {education}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DepartmentTeacherCard
