import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ROOT } from 'routes/routes'

export default function TermOfService() {
  return (
    <div className="landing">
      <Helmet>
        <title>Terms of Service | EPA</title>
      </Helmet>
      <section className="bg-white">
        <div className="container">
          <div className="section__header">
            <img
              width="40%"
              src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/logo/EPA+Extended+Logo+Light+Background+(Transparent).png"></img>
            <div className="title mt-3">TERMS AND CONDITIONS</div>
          </div>

          <div className="row">
            <div className="col-12 section__text text-justify info-explanation-black ">
              <p className="text-underline mb-0">
                <strong>BOOKING POLICY</strong>
              </p>
              <p className="ml-3 mt-2">
                1. เมื่อนักเรียนและผู้ปกครองได้รับตารางเรียนให้ตรวจสอบความถูกต้องของตารางเรียน
                หากพบข้อผิดพลาดหรือต้องการเปลี่ยนแปลง กรุณาแจ้งเจ้าหน้าที่ทันที
              </p>
              <p>
                {' '}
                Upon receiving our class schedule, students or parents are responsible for double-checking the accuracy
                of the schedule. Staff must be notified immediately if there are any mistakes or alterations in the
                schedule.
              </p>
              <p className="ml-3">
                2. กรุณาชำระค่าเรียนและส่งหลักฐานยืนยันการจ่ายเงินให้กับทางสถาบัน ภายใน 1 สัปดาห์หลังได้รับตารางเรียน
                เพื่อรักษาสิทธิ์และยืนยันตารางเรียน หากเกินช่วงเวลาที่กำหนด
                ทางระบบคอมพิวเตอร์จะยกเลิกตารางเรียนที่ได้ลงไว้โดยอัตโนมัติและจะไม่สามารถรับรองเวลาเรียนเดิมที่เคยจองไว้
              </p>
              <p>
                After receiving your class schedule, please send in your proof of payment within 1 week to secure your
                scheduled classes. Failure to do so will result in cancellation of your schedule.
              </p>
              <p className="ml-3">
                3. สำหรับนักเรียนที่เรียนเป็นประจำทุกเดือน/นักเรียนโฮมสคูล
                สามารถแจ้งสำรองตารางเรียนในวัน-เวลาเดิมโดยการชำระค่าเรียนล่วงหน้าอย่างน้อย 1 เดือน
              </p>
              <p>
                For homeschool students or students with ongoing monthly schedule, please make the payment a minimum of
                I month in advance to secure the booked time slot.
              </p>
              <p className="ml-3">
                4. นักเรียนที่มียอดค้างค่าเรียนเกิน 5,000 บาท
                ทางสถาบันขอสงวนสิทธิ์ในการยกเลิกตารางเรียนที่ได้จองไว้ทั้งหมด ตามข้อกำหนดnที่กลางไว้ในข้อ (2)
              </p>
              <p>Students who have a deficit more than 5,000 baht will have all schedule removed as stated in (2).</p>
              <p className="text-underline mb-0">
                <strong>CANCELLETION POLICY</strong>
              </p>
              <p className="ml-3 mt-2">
                1. พนักงาน EPA จะไลน์เพื่อแจ้งนักเรียน/ผู้ปกครอง ล่วงหน้า 1 วัน ก่อนเวลา 18.00 น.
                หากมีการยกเลิกการเรียนหลังจากนี้ (ทุกกรณี)หรือขาดเรียน ทาง EPA
                ขอสงวนสิทธิ์ในการคิดค่าเรียนเต็มจำนวนตามเวลาที่ระบุไว้ทุกกรณี
              </p>
              <p>
                EPA Staff will contact either the student / parents /guardian via application LINE, one day in advance
                to confirm the classes. Full amount of fee will be applied in case of cancellation.
              </p>
              <p className="ml-3">
                2. พนักงาน EPA จะไลน์เพื่อแจ้งนักเรียน/ผู้ปกครอง ล่วงหน้า 1 วัน ก่อนเวลา 18.00 น.
                หากมีการยกเลิกการเรียนหลังจากนี้ (ทุกกรณี)หรือขาดเรียน ทาง EPA
                ขอสงวนสิทธิ์ในการคิดค่าเรียนเต็มจำนวนตามเวลาที่ระบุไว้ทุกกรณี
              </p>
              <p>
                In case student cannot attend any classes, please inform EPA at least one day in advance before 6 pm to
                avoid late cancellation fees. EPA reserves the right to charge the class fees at full amount for any
                cancellations after 6pm deadline or no-shows.
              </p>
              <p className="text-underline mb-0">
                <strong>REFUND POLICY</strong>
              </p>
              <p className="ml-3 mt-2">
                1. หากนักเรียน/ผู้ปกครอง ได้ทำการชำระเงินล่วงหน้าเพื่อจองตารางแลrrrt
                ะต้องการยกเลิกตารางเรียนที่ได้ทำการชำระไว้ เมื่อมีการแจ้งความประสงค์ขอ Refund ยอดเงินคงเหลือคืน
                ทางสถาบันจะทำการ Refund เต็มจำนวนของยอดคงเหลือ เมื่อยอดชำระล่วงหน้านั้นเป็นการโอนผ่านบัญชีธนาคาร
                และทางสถาบันขอสงวนสิทธิ์ หัก 3% ของยอดคงเหลือ เมื่อเป็นการชำระโดยบัตรเครดิต
              </p>
              <p>
                Students/Parents can notify EPA for refunding. The credit balance will be fully refunded if the payment
                is done by bank transfer. Three percent reduction (-3%) will be applied if the payment is done by credit
                card.
              </p>
              <p className="text-underline mb-0">
                <strong>REMOVAL POLICY</strong>
              </p>
              <p className="ml-3 mt-2">
                1. หากนักเรียนยกเลิกตารางเรียน ใน วัน-เวลา-วิชา-อาจารย์ ท่านเดิม 2 ครั้งติดต่อกัน หรือมาเข้าเรียนสายเกิน
                30 นาที 2 ครั้งติดต่อกันภายใน 1 เดือน ทางสถาบันจะโทรแจ้งผู้ปกครองเพื่อหาครูใหม่
                หรือเวลาเรียนใหม่ที่เหมาะสมแล้วแต่กรณี
              </p>
              <p>
                Students who miss a class of the same subject, the same teacher and the same time or is late for more 30
                minutes twice in a row within 1 month period will be reported to the pare appropriate schedule will be
                proposed. This might include changing teacher or time slot.
              </p>

              <p className="text-center mt-5">
                *ทางสถาบัน EPA ขอสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไขตามความเหมาะสมโดยไม่ต้องแจ้งล่วงหน้า{' '}
              </p>
              <p className="text-center">
                * EPA reserves the right to change these terms and conditions without notification.
              </p>
            </div>
          </div>
          {/*
         
      
          <div className="row">
            <div className="col-12 section__text text-justify">
              <p>
                Please read through the Terms of Service carefully before purchasing a course from&nbsp;
                <Link to={ROOT}>tenopy.com</Link> operated by Tenopy. By using our website you are agreeing to these
                Terms of Service and that you agree to comply with them. We may modify these Terms from time to time.
              </p>

              <p className="text-underline mb-0">
                <strong>Definition</strong>
              </p>
              <p>
                &#8220;Terms&#8221;, &#8220;Terms of Service&#8221; - Terms of Service
                <br />
                &#8220;us&#8221;, &#8220;we&#8221;, &#8220;our&#8221;, &#8220;company&#8221; - Tenopy
                <br />
                &#8220;User&#8221;, &#8220;you&#8221; - registered users of <Link to={ROOT}>tenopy.com</Link>
                <br />
                &#8220;classes&#8221;, &#8220;class&#8221; - courses/ workshops/ tutoring services offered by&nbsp;
                <Link to={ROOT}>tenopy.com</Link>
                <br />
                &#8220;lesson&#8221; - a particular lesson from a course/ workshop
                <br />
              </p>

              <p className="text-underline mb-0">
                <strong>Prices and payment</strong>
              </p>
              <p>
                The prices of the classes will be as quoted on our site from time to time. We take all reasonable care
                to ensure that the quoted prices are correct at the time when the relevant information was entered onto
                the system. However if we discover an error in a price, we reserve the right not to accept your order at
                the incorrect price.
              </p>

              <p className="text-underline mb-0">
                <strong>Refund of payment</strong>
              </p>
              <p>
                Payment for remaining regular lessons is refundable in Tenopy Credits within 28 days after the first
                lesson. No refund request will be accepted after the 28 days. Refunds will be processed within 5-10
                days.
              </p>
              <p>This policy does not apply to payments for workshops.</p>
              <p>
                If you would like to request for a refund, please drop us an email at&nbsp;
                <a href="mailto: ask@tenopy.com">ask@tenopy.com</a> or contact us at&nbsp;
                <a href="tel:+6594696793">+65 9469 6793</a>
              </p>

              <p className="text-underline mb-0">
                <strong>Lesson resources</strong>
              </p>
              <p>
                All purchased lesson resources, i.e. notes, video recordings will be available to the user until the end
                of the paid subscription period in the same calendar year, unless otherwise specified.
              </p>

              <p className="text-underline mb-0">
                <strong>Changes to our classes</strong>
              </p>
              <p>
                The classes that Tenopy provide are always evolving and the form and nature of the classes may change
                from time to time. Tenopy reserves the right to change the teachers, content or other aspects of the
                class with or without prior notice to you. If the registration for new classes opened within the month
                falls below 5, we also reserve the right to cancel these classes or merge these classes with another
                class with the same teaching content.
              </p>

              <p className="text-underline mb-0">
                <strong>Transfer of classes</strong>
              </p>
              <p>
                No transfer of classes is allowed, whether between users/ third parties or transferring from one
                class/lesson to another.
              </p>

              <p className="text-underline mb-0">
                <strong>Lesson recording</strong>
              </p>
              <p>
                When video recording a lesson, Tenopy is authorised to make recordings of users’ voices and to obtain an
                account of their spoken or written thoughts. Tenopy reserves the rights to use these recordings, spoken
                and written thoughts, as well as likeness, name, voice, identity, in complete or partial form, in
                connection with any works that Tenopy may create in any media format now known or later developed,
                including, without limitation, books, publications, video, the Internet, and the World Wide Web.
              </p>

              <p className="text-underline mb-0">
                <strong>Copyright &amp; Intellectual Property</strong>
              </p>
              <p>
                All contents and graphics of this website remain the property of Tenopy and are subject to Singapore
                Copyright Act. Users cannot copy, reproduce, alter, edit, download, denature, transmit or distribute any
                component of the website for commercial purpose without asking for and receiving written permission from
                an authorised representative from Tenopy.
              </p>
              <p>
                All lesson resources are intellectual property of Tenopy, and are for the sole use of the user and the
                immediate family only. Any infringement thereof, i.e. making copies, misappropriation, selling, or
                distribution, is subject to criminal and/or civil liability.
              </p>

              <p className="text-underline mb-0">
                <strong>Force majeure</strong>
              </p>
              <p>
                Tenopy shall not be liable for any default/cancellation/postponing of class due to any act of God, war,
                strike, lockout, industrial action, fire, flood, drought, tempest, internet/electrical outage or other
                event beyond the Company’s reasonable control.
              </p>

              <p className="text-underline mb-0">
                <strong>Termination of user</strong>
              </p>
              <p>
                Tenopy may prevent a user from continuing with lessons if we determine, in our sole discretion, that the
                user have violated these Terms of Service or other agreements or guidelines which may be associated with
                the use of tenopy.com. Tenopy reserves the right to take any necessary measures to remove or suspend any
                users suspected of misuse, inappropriate activity or impersonation.
              </p>

              <p className="text-underline mb-0">
                <strong>Termination and postponing of class</strong>
              </p>
              <p>
                Tenopy reserves the right to, in its sole discretion, transfer, combine or dissolve a class/lesson,
                with/without any prior notice given.
              </p>

              <p className="text-underline mb-0">
                <strong>Disclaimer of Warranty </strong>
              </p>
              <p>
                EXCEPT AS EXPRESSLY SET FORTH IN THIS TERMS OF SERVICE, THE SERVICES TO BE PURCHASED UNDER THIS TERMS OF
                SERVICE ARE FURNISHED AS IS, WHERE IS, WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
                IMPLIED, INCLUDING ANY WARRANTY OF MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE.
              </p>

              <p className="text-underline mb-0">
                <strong>Limitation of liability</strong>
              </p>
              <p>
                Neither Party shall have any liability under this Terms of Service, for any punitive or exemplary
                damages, or any special, consequential, incidental or indirect damages, whether or not the other Party
                was aware or should have been aware of the possibility of these damages.
              </p>
            </div>
          </div>
        </div>

        <div className="section__header">
          <div className="title">Tenopy&#697;s Terms of Service for trial users</div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 section__text text-justify">
              <p>
                Please read through the Terms of Service carefully before purchasing a course from&nbsp;
                <Link to={ROOT}>tenopy.com</Link> operated by Tenopy. By using our website you are agreeing to these
                Terms of Service and that you agree to comply with them. We may modify these Terms from time to time.
              </p>

              <p className="text-underline mb-0">
                <strong>Definition</strong>
              </p>
              <p>
                &#8220;Terms&#8221;, &#8220;Terms of Service&#8221; - Terms of Service
                <br />
                &#8220;us&#8221;, &#8220;we&#8221;, &#8220;our&#8221;, &#8220;company&#8221; - Tenopy
                <br />
                &#8220;User&#8221;, &#8220;you&#8221; - registered users of <Link to={ROOT}>tenopy.com</Link>
                <br />
                &#8220;classes&#8221;, &#8220;class&#8221; - courses/ workshops/ tutoring services offered by&nbsp;
                <Link to={ROOT}>tenopy.com</Link>
                <br />
                &#8220;lesson&#8221; - a particular lesson from a course/ workshop
                <br />
              </p>

              <p className="text-underline mb-0">
                <strong>Lesson resources</strong>
              </p>
              <p>
                All trial lesson resources, i.e. notes, video recordings will be available to the user for a period of
                not more than 7 days from the date of the trial lesson.
              </p>

              <p className="text-underline mb-0">
                <strong>Signing up for trials</strong>
              </p>
              <p>
                Tenopy offers one free trial lesson per class for each user, unless specified otherwise. Tenopy may
                prevent a user from signing up or attending trials if we determine, in our sole discretion, that the
                user have violated these Terms of Service or other agreements or guidelines which may be associated with
                the use of tenopy.com. Tenopy reserves the right to take any necessary measures to remove or suspend any
                users suspected of misuse, inappropriate activity or impersonation.
              </p>

              <p className="text-underline mb-0">
                <strong>Copyright & Intellectual Property</strong>
              </p>
              <p>
                All contents and graphics of this website remain the property of Tenopy and are subject to Singapore
                Copyright Act. Users cannot copy, reproduce, alter, edit, download, denature, transmit or distribute any
                component of the website for commercial purpose without asking for and receiving written permission from
                an authorised representative from Tenopy.
              </p>
              <p>
                All lesson resources are intellectual property of Tenopy, and are for the sole use of the user and the
                immediate family only. Any infringement thereof, i.e. making copies, misappropriation, selling, or
                distribution, is subject to criminal and/or civil liability.
              </p>

              <p className="text-underline mb-0">
                <strong>Lesson recording</strong>
              </p>
              <p>
                When video recording a lesson, Tenopy is authorised to make recordings of users’ voices and to obtain an
                account of their spoken or written thoughts. Tenopy reserves the rights to use these recordings, spoken
                and written thoughts, as well as likeness, name, voice, identity, in complete or partial form, in
                connection with any works that Tenopy may create in any media format now known or later developed,
                including, without limitation, books, publications, video, the Internet, and the World Wide Web.
              </p>

              <p>
                Last updated: <strong>07 April 2021</strong>
              </p>
            </div>
          </div>*/}
        </div>
      </section>
    </div>
  )
}
