import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import agent from '../../../../agent'
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import {
  ADD_CART_ITEM,
  ADD_CART_ITEM_NOAUTH,
  CALC_PACKAGE_QUOTE,
  GET_PACKAGE_QUOTE,
  CLEAR_PACKAGE_QUOTE,
  CONTACT_US,
  GET_ONLINE_CLASS_LIST,
  GET_CUSTOM_CONFIG
} from '../../../../constants/actionTypes'
import { CLASS_TYPE_REGULAR_CLASSES } from '../../../../constants/generalTypes'
import _ from 'lodash'
import { getClassData } from '../../../../utils/misc'
import { formatCartItem, calculateQuote } from 'utils/cart'
import { roundPrice } from '../../../../utils/merchandise'
import { APP, ALL_CLASSES, CLASS_DETAILS, TRIAL, SHOPPING_CART } from '../../../../routes/routes'
import JoinTrialDialog from 'pages/common/online-class/trial/dialog-join-trial'
import ReactGA from 'react-ga'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import ReactPixel from 'react-facebook-pixel'
import { Helmet } from 'react-helmet'
import GetInTouchDialog from 'components/dialog/get-in-touch'
import VideoPlayerDialog from 'components/dialog/video-player'
import DialogProceedToCheckout from 'components/online-class/dialog-proceed-to-checkout'

const mapStateToProps = state => ({
  token: state.common.token,
  onlineClassList: state.onlineClass.onlineClassList,
  contactSuccess: state.misc.contacted,
  lessonPackages: state.shoppingCart.lessonPackages,
  shoppingCart: state.shoppingCart.shoppingCart,
  userClassWithDetail: state.common.userClassWithDetail,
  customConfig: state.misc.customConfig,
  loadingConfig: state.misc.loadingConfig
})

const mapDispatchToProps = dispatch => ({
  getClassList: () =>
    dispatch({ type: GET_ONLINE_CLASS_LIST, payload: agent.OnlineClass.getList({ is_sold_out: false }) }),
  contactUs: reqPayload => dispatch({ type: CONTACT_US, payload: agent.Misc.contactUs(reqPayload) }),
  addToCart: item => dispatch({ type: ADD_CART_ITEM, payload: agent.ShoppingCart.addItem(item), cartItem: item }),
  addToLocalCart: item => dispatch({ type: ADD_CART_ITEM_NOAUTH, payload: item }),
  calcPackageQuote: (quote, packageItem) => dispatch({ type: CALC_PACKAGE_QUOTE, payload: quote, packageItem }),
  getPackageQuote: (payload, packageItem) =>
    dispatch({ type: GET_PACKAGE_QUOTE, payload: agent.OnlineClass.getQuote(payload), packageItem: packageItem }),
  clearPackageQuote: (class_id, component_id) =>
    dispatch({ type: CLEAR_PACKAGE_QUOTE, class_id: class_id, component: component_id }),
  getConfig: configName =>
    dispatch({
      type: GET_CUSTOM_CONFIG,
      payload: agent.External.getJSON(
        `https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/json/${configName}.json`
      )
    })
})

const LevelFilter = props => {
  let { activeTab, activeTabStyle, levelIncluded } = props

  return (
    <div className="trial__wrapper">
      <nav className="trial__tabs" id="trial__tabs">
        <div className="trial__selector" style={activeTabStyle} />

        <span className={activeTab === 'all' ? 'active' : ''} onClick={props.switchTab('all')} id="all">
          All
        </span>
        {levelIncluded.includes('secondary-1') && (
          <span
            className={activeTab === 'secondary-1' ? 'active' : ''}
            onClick={props.switchTab('secondary-1')}
            id="secondary-1">
            Lower Sec
          </span>
        )}
        {levelIncluded.includes('primary-6') && (
          <span
            className={activeTab === 'primary-6' ? 'active' : ''}
            onClick={props.switchTab('primary-6')}
            id="primary-6">
            Primary 6
          </span>
        )}
        {levelIncluded.includes('primary-5') && (
          <span
            className={activeTab === 'primary-5' ? 'active' : ''}
            onClick={props.switchTab('primary-5')}
            id="primary-5">
            Primary 5
          </span>
        )}
        {levelIncluded.includes('primary-4') && (
          <span
            className={activeTab === 'primary-4' ? 'active' : ''}
            onClick={props.switchTab('primary-4')}
            id="primary-4">
            Primary 4
          </span>
        )}
        {levelIncluded.includes('primary-3') && (
          <span
            className={activeTab === 'primary-3' ? 'active' : ''}
            onClick={props.switchTab('primary-3')}
            id="primary-3">
            Primary 3
          </span>
        )}
      </nav>
    </div>
  )
}

// NOTE: Variant C and Variant D share almost the same feature. D is initially create to handle all classes display and C is for selected class only
// TODO: refactor and merge Variant C & D

class VariantD extends React.Component {
  state = {
    promoClassList: null,
    promoClassId: '',
    showBackIcon: false,
    isGetInTouchDialogOpen: false,
    activeScheduleIndex: 0,
    lessonsIncluded: [],
    isVideoDialogOpen: false,
    isJoinTrialDialogOpen: false,
    selectedClass: null,
    isProceedToCheckoutDialogOpen: false,
    addedToCart: [],
    activeTab: 'all',
    activeTabLeft: 0,
    activeTabWidth: 0,
    firstLoad: true,
    levelIncluded: ['all', 'primary-3', 'primary-4', 'primary-5', 'primary-6', 'secondary-1'],
    activeTerm: '1',
    expandFullClassDesc: false
  }

  componentDidMount() {
    this.props.getConfig(this.props.match.params.config)

    if (!this.props.onlineClassList || this.props.onlineClassList.length === 0) {
      this.props.getClassList()
    } else {
      this.filterOnlineClass()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loadingConfig && !this.props.loadingConfig) {
      let { customConfig } = this.props
      if (customConfig && customConfig.levelIncluded) {
        this.setState({ levelIncluded: customConfig.levelIncluded })
      }
      ReactPixel.trackCustom('ViewTrial')
    }

    if (prevProps.onlineClassList !== this.props.onlineClassList) {
      this.filterOnlineClass()
    }

    if (prevState.promoClassList !== this.state.promoClassList && this.state.promoClassList.length > 0) {
      this.selectClass(0)()
      this.updateCartStatus()
      this.switchTab(this.state.activeTab)()
    }

    if (prevState.activeScheduleIndex !== this.state.activeScheduleIndex && isMobile) {
      this.scrollTo(`class-${this.state.activeScheduleIndex}`)()
    }

    if (
      prevProps.lessonPackages !== this.props.lessonPackages &&
      this.props.lessonPackages[this.state.selectedClass._id]
    ) {
      let lessonPackage = this.props.lessonPackages[this.state.selectedClass._id].item[0]
      this.addToCart(lessonPackage)
    }

    if (prevProps.shoppingCart !== this.props.shoppingCart) {
      this.updateCartStatus()
    }

    if (prevState.activeTab !== this.state.activeTab) {
      this.filterOnlineClass()
      this.setState({ activeScheduleIndex: 0 })
    }
  }

  filterOnlineClass = () => {
    let { onlineClassList, customConfig } = this.props
    let { activeTab } = this.state

    if (!onlineClassList || !customConfig) {
      return
    }

    let promoClassList = onlineClassList
    promoClassList = _.filter(promoClassList, o => {
      return !customConfig.ignoreClass.includes(o._id)
    })
    if (activeTab !== 'all') {
      promoClassList = _.filter(promoClassList, function (o) {
        return o.level.includes(activeTab)
      })
    }
    if (customConfig.activeSubject) {
      promoClassList = _.filter(promoClassList, function (o) {
        return o.subject === customConfig.activeSubject
      })
    }

    promoClassList = _.orderBy(promoClassList, ['level'], ['desc'])

    this.setState({ promoClassList })
  }

  goToClass = classId => () => {
    ReactGA.event({
      category: 'Conversion',
      action: 'View Class Detail',
      label: 'D'
    })
    this.props.history.push(CLASS_DETAILS.replace(':classId', classId))
  }

  scrollToClassSelection = location => () => {
    ReactGA.event({
      category: 'Conversion',
      action: `Get started - ${location}`,
      label: 'D'
    })

    let elmnt = document.getElementById('what-we-cover')
    window.scrollTo({ top: elmnt.offsetTop, behavior: 'smooth' })
  }

  scrollToReadMore = () => {
    ReactGA.event({
      category: 'Conversion',
      action: `Read more`,
      label: 'D'
    })

    let elmnt = document.getElementById('readmore')
    window.scrollTo({ top: elmnt.offsetTop, behavior: 'smooth' })
  }

  scrollTo = (id, offset) => () => {
    let element = document.getElementById(id)
    let defaultOffset = offset || 90
    window.scrollTo({ top: element.offsetTop - defaultOffset, behavior: 'smooth' })
  }

  clickOnTutor = tutorName => () => {
    ReactGA.event({
      category: 'Conversion',
      action: `Click tutor - ${tutorName}`,
      label: 'D'
    })
  }

  changeSchedule = indexChange => () => {
    let { promoClassList } = this.state
    let newScheduleIndex = this.state.activeScheduleIndex + indexChange
    if (newScheduleIndex >= promoClassList.length) {
      newScheduleIndex = 0
    }
    if (newScheduleIndex < 0) {
      newScheduleIndex = promoClassList.length - 1
    }

    this.setState({
      activeScheduleIndex: newScheduleIndex
    })

    ReactGA.event({
      category: 'Conversion',
      action: 'Navigate class schedule',
      label: 'D'
    })
  }

  selectClass = index => () => {
    let { promoClassList, lessonsIncluded, firstLoad } = this.state
    if (lessonsIncluded.length > 0) {
      ReactGA.event({
        category: 'Conversion',
        action: `View ${promoClassList[index].name}`,
        label: 'D'
      })
    }

    let classType = promoClassList[index].class_type
    let updatedLessonsIncluded = promoClassList[index].lesson
    let activeTerm = '1'
    if (classType === CLASS_TYPE_REGULAR_CLASSES) {
      let now = moment()
      let nextLesson = _.find(promoClassList[index].lesson, o => {
        return moment(o.scheduled_on) > now
      })
      if (nextLesson) {
        activeTerm = nextLesson.meta.term.toString()
        updatedLessonsIncluded = _.filter(promoClassList[index].lesson, function (o) {
          return moment(o.scheduled_on) > now && o.meta.term.toString() === activeTerm
        })
      }
    }

    this.setState({
      activeScheduleIndex: index,
      lessonsIncluded: updatedLessonsIncluded,
      activeTerm,
      expandFullClassDesc: false
    })

    if (!firstLoad && !isMobile) {
      let offset = 250
      if (promoClassList.length > 4) {
        offset = 329
      }
      this.scrollTo('selected-class-detail', offset)()
    } else {
      this.setState({ firstLoad: false })
    }
  }

  toggleVideoDialog = () => {
    if (!this.state.isVideoDialogOpen) {
      ReactGA.event({
        category: 'Conversion',
        action: 'Play banner video',
        label: 'D'
      })
    }

    this.setState({
      isVideoDialogOpen: !this.state.isVideoDialogOpen
    })
  }

  joinTrial = index => () => {
    let { promoClassList } = this.state
    if (!this.state.isJoinTrialDialogOpen) {
      ReactGA.event({
        category: 'Conversion',
        action: `Init join trial ${promoClassList[index].name}`,
        label: 'D'
      })
    }

    this.setState({
      isJoinTrialDialogOpen: true,
      selectedClass: promoClassList[index]
    })
  }

  toggleJoinTrialDialog = () => {
    if (!this.state.isJoinTrialDialogOpen) {
      ReactGA.event({
        category: 'Conversion',
        action: this.props.token ? 'JoinTrial' : 'InitRegistration',
        label: 'D'
      })
    }
    this.setState({
      isJoinTrialDialogOpen: !this.state.isJoinTrialDialogOpen
    })
  }

  buyOnlineLessons = (index, lessonCount) => () => {
    let { token, userClassWithDetail } = this.props
    let { promoClassList, activeTerm } = this.state

    if (!promoClassList) {
      return
    }

    this.setState({
      selectedClass: promoClassList[index]
    })

    let now = moment()
    let classData = promoClassList[index]
    let userLessonIds = []
    if (token && userClassWithDetail && userClassWithDetail[classData._id]) {
      userLessonIds = userClassWithDetail[classData._id].lesson
    }

    let lessonsIncluded = classData.lesson
    if (classData.class_type === CLASS_TYPE_REGULAR_CLASSES) {
      lessonsIncluded = _.filter(classData.lesson, function (o) {
        return moment(o.scheduled_on) > now && o.meta.term.toString() === activeTerm && !userLessonIds.includes(o._id)
      })
    }

    if (lessonCount && lessonsIncluded.length > lessonCount) {
      lessonsIncluded = _.slice(lessonsIncluded, 0, lessonCount)
      this.setState({ lessonsIncluded })
    }

    if (lessonsIncluded.length > 0) {
      let packageItem = {
        class_id: classData._id,
        count: lessonsIncluded.length,
        options: {
          start_date: lessonsIncluded[0].scheduled_on,
          end_date: lessonsIncluded[lessonsIncluded.length - 1].scheduled_on
        },
        rate: classData.rate,
        title: classData.name
      }

      if (token) {
        this.props.getPackageQuote(
          {
            class_id: classData._id,
            start_date: lessonsIncluded[0].scheduled_on,
            end_date: lessonsIncluded[lessonsIncluded.length - 1].scheduled_on
          },
          packageItem
        )
      } else {
        let quote = calculateQuote(
          lessonsIncluded,
          classData.rate,
          lessonsIncluded[0].scheduled_on,
          lessonsIncluded[lessonsIncluded.length - 1].scheduled_on
        )
        this.props.calcPackageQuote({ data: quote }, packageItem)
      }
    }
  }

  updateCartStatus = () => {
    if (!this.state.promoClassList) return

    let addedToCart = []
    for (let i = 0; i < this.props.shoppingCart.item.length; i++) {
      addedToCart.push(this.props.shoppingCart.item[i].item_id)
    }

    this.setState({ addedToCart })
  }

  addToCart = lessonPackage => {
    let { selectedClass } = this.state

    let formattedItem = formatCartItem(selectedClass, 'Class', lessonPackage.packageItem.options)
    if (!!this.props.token) {
      this.props.addToCart(formattedItem)
    } else {
      this.props.addToLocalCart(formattedItem)
    }
    this.toggleProceedToCheckoutDialog()
  }

  toggleProceedToCheckoutDialog = () => {
    this.setState({ isProceedToCheckoutDialogOpen: !this.state.isProceedToCheckoutDialogOpen })
  }

  goToCart = () => {
    this.toggleProceedToCheckoutDialog()
    let redirectUrl = this.props.token ? `${APP}${SHOPPING_CART}` : SHOPPING_CART
    this.props.history.push(redirectUrl)
  }

  switchTab = activeTab => () => {
    let dom = document.getElementById(activeTab)
    let tabDOM = document.getElementById('trial__tabs')

    this.setState({
      activeTab,
      activeTabLeft: dom ? dom.getBoundingClientRect().x - tabDOM.getBoundingClientRect().x : 0,
      activeTabWidth: dom ? dom.getBoundingClientRect().width : 0
    })
  }

  toggleExpandFullClassDesc = () => {
    this.setState({ expandFullClassDesc: !this.state.expandFullClassDesc })
  }

  toggleGetInTouchDialog = () => {
    this.setState({ isGetInTouchDialogOpen: !this.state.isGetInTouchDialogOpen })
  }

  render() {
    let { customConfig } = this.props
    let {
      promoClassList,
      activeScheduleIndex,
      lessonsIncluded,
      isVideoDialogOpen,
      isJoinTrialDialogOpen,
      selectedClass,
      isProceedToCheckoutDialogOpen,
      addedToCart,
      activeTab,
      activeTabLeft,
      activeTabWidth,
      levelIncluded,
      expandFullClassDesc,
      isGetInTouchDialogOpen
    } = this.state

    if (!customConfig || !promoClassList) {
      return (
        <div className="manage-margin text-center my-5">
          <CircularProgress size={50} />
        </div>
      )
    }

    let processedData2 = getClassData(
      promoClassList[activeScheduleIndex].class_type,
      promoClassList[activeScheduleIndex].lesson,
      promoClassList[activeScheduleIndex].rate,
      promoClassList[activeScheduleIndex].quote,
      promoClassList[activeScheduleIndex]._id
    )

    let styles = {
      activeTab: {
        left: `${activeTabLeft}px`,
        width: `${activeTabWidth}px`
      }
    }

    return (
      <div className="landing">
        <Helmet>
          <title>{customConfig.name} | Tenopy</title>
          <meta
            name="description"
            content="At Tenopy, top online teachers lead hands-on classes live with our next-generation classroom technology. Achieve results for English, Math and Science at Primary and Secondary levels with our tuition classes. Enrich learning beyond mere grades with our featured learning programmes."
          />
          <meta
            name="keywords"
            content="Online tuition,Math tuition,English tuition,Secondary school tuition,Primary school tuition,Online tuition singapore,Online learning,Online education,Education platform,Online tutor,Home tutor,home tuition,science tuition,sa1,sa2,ca1,ca2,psle,revision,online revision,online study,online learning,psle revision"
          />
        </Helmet>

        <section className={`trial__banner-bg--main ${isMobile ? 'px-0' : ''}`}>
          {!isMobile && <div className="trial__banner-bg--side" />}
          <div className={`container h-100`}>
            <div className="row align-items-center h-100 mb-3 mb-md-0">
              <div className="col-md-6 col-12">
                <span className="tag amber-tag text-capitalize font-weight-semibold " style={{ color: '#29303b' }}>
                  {customConfig.label}
                </span>
                <h1>{customConfig.name}</h1>
                <div dangerouslySetInnerHTML={{ __html: customConfig.summary }} className="text-justify" />
                <Button variant="text" color="primary" onClick={this.scrollToReadMore}>
                  Read more...
                </Button>
              </div>
              {!isMobile && customConfig.introVideo && (
                <div className="col-md-6 text-center" style={{ zIndex: 2 }}>
                  <div className="sonar-wrapper" onClick={this.toggleVideoDialog}>
                    <i className="material-icons sonar-play">play_circle_filled</i>
                    <div className="sonar-emitter">
                      <div className="sonar-wave"></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {isMobile && customConfig.introVideo && (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  backgroundImage:
                    "url('https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/student/main_background.jpg')",
                  backgroundSize: 'cover',
                  backgroundPosition: 'center center',
                  minHeight: '200px'
                }}>
                <div className="sonar-wrapper w-100" onClick={this.toggleVideoDialog}>
                  <i className="material-icons sonar-play">play_circle_filled</i>
                  <div className="sonar-emitter">
                    <div className="sonar-wave"></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>

        {isMobile && (
          <section id="what-we-cover">
            <div className="container px-0">
              <div className="section__header mb-5">
                <div className="title mb-2">Our Online Classes</div>
                <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #8e51f0' }}></div>
              </div>

              <div className="row no-gutters">
                <LevelFilter
                  levelIncluded={levelIncluded}
                  switchTab={this.switchTab}
                  activeTabStyle={styles.activeTab}
                  activeTab={activeTab}
                />

                {promoClassList.map((classData, index) => {
                  let processedData = getClassData(
                    classData.class_type,
                    classData.lesson,
                    classData.rate,
                    classData.quote,
                    classData._id
                  )
                  let now = moment()
                  let remainingLessons = classData.lesson
                  if (classData.class_type === CLASS_TYPE_REGULAR_CLASSES) {
                    let activeTerm = '1'
                    let nextLesson = _.find(classData.lesson, o => {
                      return moment(o.scheduled_on) > moment()
                    })
                    if (nextLesson) {
                      activeTerm = nextLesson.meta.term.toString()
                      remainingLessons = _.filter(classData.lesson, function (o) {
                        return moment(o.scheduled_on) > now && o.meta.term.toString() === activeTerm
                      })
                    }
                  }

                  return (
                    <div
                      className="col-12 clickable border mb-3 p-2"
                      key={index}
                      id={`class-${index}`}
                      style={{
                        boxShadow:
                          '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
                      }}>
                      <div
                        className="d-flex flex-row align-items-center"
                        onClick={() => {
                          this.selectClass(index)()
                        }}>
                        <Avatar
                          className="size-60 avatar-shadow m-2"
                          alt={classData.tutor[0].name}
                          src={classData.tutor[0].profile_pic}
                        />
                        <div className="ml-2">
                          <h3 className="font-weight-semibold mb-1">{classData.name}</h3>
                          <div className="d-flex flex-row">
                            <small className="mr-2">by</small>
                            {classData.tutor[0].name}
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-flex flex-row justify-content-between align-items-center"
                        onClick={() => {
                          this.selectClass(index)()
                        }}>
                        <small className="text-primary">{activeScheduleIndex !== index ? 'Tap to expand' : ''}</small>
                        <div className="text-right">
                          <div className="class__price">
                            <h1 className="text-primary mb-0">$ {roundPrice(processedData.price)}</h1>
                          </div>
                          {classData.class_type === CLASS_TYPE_REGULAR_CLASSES ? (
                            <small>per lesson</small>
                          ) : (
                            <small>{remainingLessons.length} lessons workshop</small>
                          )}
                        </div>
                      </div>

                      {activeScheduleIndex === index && (
                        <React.Fragment>
                          <div className="text-center mt-3">
                            {addedToCart.includes(classData._id) ? (
                              <Button fullWidth variant="outlined" color="primary" onClick={this.goToCart}>
                                Checkout
                              </Button>
                            ) : (
                              <React.Fragment>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  onClick={this.buyOnlineLessons(index)}>
                                  Buy{' '}
                                  {remainingLessons.length > 0 && classData.class_type === CLASS_TYPE_REGULAR_CLASSES
                                    ? `All Term ${remainingLessons[0].meta.term}`
                                    : `All ${remainingLessons.length}`}{' '}
                                  Lessons
                                </Button>
                                {customConfig.allowTrial === 'true' && (
                                  <Button variant="text" color="primary" onClick={this.joinTrial(index)}>
                                    Join a Trial Lesson
                                  </Button>
                                )}
                              </React.Fragment>
                            )}
                          </div>

                          <hr style={{ borderTop: '1px solid rgba(0, 0, 0, 0.4)' }} />
                          <div className="my-3">
                            {/*<small className="text-muted">About {classData.tutor[0].name}</small>
                      <p className="text-justify" dangerouslySetInnerHTML={{__html: classData.tutor[0].desc}}/>*/}
                            <small className="text-muted">About Class</small>
                            <p className="text-justify" dangerouslySetInnerHTML={{ __html: classData.desc }} />
                          </div>
                          <hr style={{ borderTop: '1px solid rgba(0, 0, 0, 0.4)' }} />
                          <div className="my-3">
                            <div className="mb-1">
                              <small className="text-muted">Lessons Schedule</small>
                            </div>
                            {lessonsIncluded &&
                              lessonsIncluded.map((lesson, index) => (
                                <div
                                  key={index}
                                  className="mb-2"
                                  style={{
                                    backgroundColor: '#f9f9f9',
                                    border: '1px solid #dedfe0',
                                    padding: '10px 15px'
                                  }}>
                                  <div className="mb-2">
                                    <i className="fa fa-check mr-2" />
                                    {lesson.name}
                                  </div>
                                  <div
                                    className="d-flex align-items-center  mb-1 text-muted"
                                    style={{ fontSize: '15px' }}>
                                    <i className="material-icons mr-2">event</i>
                                    {moment(lesson.scheduled_on).format('ddd, MMMM Do YYYY')}
                                  </div>
                                  <div className="d-flex align-items-center  text-muted" style={{ fontSize: '15px' }}>
                                    <i className="material-icons mr-2">access_time</i>
                                    {moment(lesson.scheduled_on).format('h:mm a')} to{' '}
                                    {moment(lesson.ended_on).format('h:mm a')}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
        )}

        {!isMobile && (
          <React.Fragment>
            <section
              id="what-we-cover"
              style={{
                backgroundImage:
                  'radial-gradient( circle farthest-corner at 10% 20%,  rgba(215,223,252,1) 0%, rgba(255,255,255,1) 0%, rgba(215,223,252,1) 84% )'
              }}>
              <div className="container">
                <div className="section__header mb-3">
                  <div className="title mb-2">Our Online Classes</div>
                  <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #8e51f0' }}></div>
                </div>
                <div className="container">
                  <div className="row">
                    <LevelFilter
                      levelIncluded={levelIncluded}
                      switchTab={this.switchTab}
                      activeTabStyle={styles.activeTab}
                      activeTab={activeTab}
                    />
                  </div>

                  <div className="row mb-5">
                    {promoClassList.map((classData, index) => {
                      let style = {
                        tab: {
                          border: '1px solid #dee2e6',
                          borderRadius: '10px',
                          boxShadow:
                            '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
                        },
                        activeTab: {
                          borderBottom: '5px solid #8e51f0',
                          borderTop: '1px solid #8e51f0',
                          borderRight: '1px solid #8e51f0',
                          borderLeft: '1px solid #8e51f0',
                          borderRadius: '10px',
                          boxShadow:
                            '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
                        }
                      }

                      return (
                        <div className="col-3" key={index}>
                          <div
                            className="d-flex flex-row align-items-center clickable p-2 mb-2 bg-white"
                            style={activeScheduleIndex === index ? style.activeTab : style.tab}
                            onClick={this.selectClass(index)}>
                            <Avatar
                              className="size-60 avatar-shadow mx-auto my-2"
                              alt={classData.tutor[0].name}
                              src={classData.tutor[0].profile_pic}
                            />
                            <div className="ml-2">
                              <h3 className="mb-0">{classData.name}</h3>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  <div className="row" id="selected-class-detail">
                    <div className="col-5">
                      <div
                        className="py-2 px-4 bg-white"
                        style={{
                          borderRadius: '10px',
                          borderBottom: '10px solid #8e51f0',
                          borderTop: '1px solid #8e51f0',
                          borderRight: '1px solid #8e51f0',
                          borderLeft: '1px solid #8e51f0'
                        }}>
                        <div className="d-flex flex-row align-items-center">
                          <Avatar
                            className="size-60 avatar-shadow m-2"
                            alt={promoClassList[activeScheduleIndex].tutor[0].name}
                            src={promoClassList[activeScheduleIndex].tutor[0].profile_pic}
                          />
                          <div className="ml-2">
                            <h3 className="font-weight-semibold mb-1">{promoClassList[activeScheduleIndex].name}</h3>
                            <div className="d-flex flex-row">
                              <small className="mr-2">by</small>
                              {promoClassList[activeScheduleIndex].tutor[0].name}
                            </div>
                          </div>
                        </div>
                        <div className="text-right">
                          <div className="class__price">
                            <h1 className="text-primary mb-0">$ {roundPrice(processedData2.price)}</h1>
                          </div>
                          {promoClassList[activeScheduleIndex].class_type === CLASS_TYPE_REGULAR_CLASSES ? (
                            <small>per lesson</small>
                          ) : (
                            <small>{lessonsIncluded.length} lessons workshop</small>
                          )}
                        </div>
                        <Divider />
                        <div className="mt-2">
                          <small className="text-muted">
                            About {promoClassList[activeScheduleIndex].tutor[0].name}
                          </small>
                          <p
                            className="text-justify"
                            dangerouslySetInnerHTML={{ __html: promoClassList[activeScheduleIndex].tutor[0].desc }}
                          />
                        </div>
                        <div className="text-center mt-3">
                          {addedToCart.includes(promoClassList[activeScheduleIndex]._id) ? (
                            <Button fullWidth variant="outlined" color="primary" onClick={this.goToCart}>
                              Checkout
                            </Button>
                          ) : (
                            <React.Fragment>
                              <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={this.buyOnlineLessons(activeScheduleIndex)}>
                                Buy{' '}
                                {lessonsIncluded.length > 0 &&
                                promoClassList[activeScheduleIndex].class_type === CLASS_TYPE_REGULAR_CLASSES
                                  ? `All Term ${lessonsIncluded[0].meta.term}`
                                  : `All ${lessonsIncluded.length}`}{' '}
                                Lessons
                              </Button>
                              {customConfig.allowTrial === 'true' && (
                                <Button variant="text" color="primary" onClick={this.joinTrial(activeScheduleIndex)}>
                                  Join a Trial Lesson
                                </Button>
                              )}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-7">
                      <h3>About Class:</h3>
                      <div
                        className="text-justify mb-3"
                        onClick={this.toggleExpandFullClassDesc}
                        style={{ backgroundColor: '#ffffff', border: '1px solid #dedfe0', padding: '10px 15px' }}>
                        {expandFullClassDesc ? (
                          <React.Fragment>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: promoClassList[activeScheduleIndex].desc
                              }}
                            />
                            <p className="text-right">
                              <span className="link">Read less...</span>
                            </p>
                          </React.Fragment>
                        ) : promoClassList[activeScheduleIndex].desc.length > 400 ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: `${promoClassList[activeScheduleIndex].desc.substring(
                                0,
                                400
                              )} <span class="link"> read more...</span>`
                            }}
                          />
                        ) : (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: promoClassList[activeScheduleIndex].desc
                            }}
                          />
                        )}
                      </div>
                      <h3>Lessons Schedule:</h3>
                      {lessonsIncluded &&
                        lessonsIncluded.map((lesson, index) => (
                          <div
                            key={index}
                            className="mb-2"
                            style={{ backgroundColor: '#ffffff', border: '1px solid #dedfe0', padding: '10px 15px' }}>
                            <div className="mb-2">
                              <i className="fa fa-check mr-2" />
                              <span style={{ fontSize: '20px' }}>{lesson.name}</span>
                            </div>
                            <div className="d-flex align-items-center  px-3 mb-1 text-muted">
                              <i className="material-icons mx-2">event</i>
                              {moment(lesson.scheduled_on).format('dddd, MMMM Do YYYY')}
                            </div>
                            <div className="d-flex align-items-center  px-3 text-muted">
                              <i className="material-icons mx-2">access_time</i>
                              {moment(lesson.scheduled_on).format('h:mm a')} to{' '}
                              {moment(lesson.ended_on).format('h:mm a')}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </React.Fragment>
        )}

        <section style={{ backgroundColor: '#efefef' }} id="readmore">
          <div className="section__header mb-5">
            <div className="title mb-2" style={{ fontSize: '2.3rem', fontWeight: '500' }}>
              {customConfig.title}
            </div>
            <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #00b96c' }}></div>
          </div>

          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10 col-12 p-md-5 p-3 border bg-white ">
                <div dangerouslySetInnerHTML={{ __html: customConfig.desc }} className="text-justify" />
                <Button
                  className="my-3"
                  variant="contained"
                  color="primary"
                  fullWidth={true}
                  size="large"
                  onClick={this.scrollToClassSelection('persuasive article')}>
                  Sign up
                </Button>
              </div>
            </div>
          </div>
        </section>

        {customConfig.featuredTutor && (
          <section>
            <div className="section__header mb-5">
              <div className="title mb-2" style={{ fontSize: '2.3rem', fontWeight: '500' }}>
                Our Featured Tutor
              </div>
              <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #4a47fd' }}></div>
            </div>
            <div className="container mb-5">
              <div className="d-md-block d-none">
                <div className="row justify-content-center">
                  {customConfig.featuredTutor.map((tutor, index) => (
                    <div className="col-3 px-2" key={index}>
                      <div
                        className="catalogue__item"
                        style={{ minWidth: '200px' }}
                        onClick={this.clickOnTutor(`${tutor.name}`)}>
                        <div className="catalogue__tutor">
                          <img src={tutor.profliePic} alt={tutor.name} className="img-fluid" />
                          <div className="tutor__name">
                            <span className="mx-auto">{tutor.name}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="d-md-none d-block">
                <Carousel
                  showThumbs={false}
                  showIndicators={false}
                  showArrows={false}
                  showStatus={false}
                  centerMode={true}
                  centerSlidePercentage={90}>
                  {customConfig.featuredTutor.map((tutor, index) => (
                    <div
                      className="catalogue__item mr-4"
                      style={{ minWidth: '200px' }}
                      onClick={this.clickOnTutor(`${tutor.name}`)}
                      key={index}>
                      <div className="catalogue__tutor">
                        <img src={tutor.profliePic} alt={tutor.name} className="img-fluid" />
                        <div className="tutor__name">
                          <span className="mx-auto">{tutor.name}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </section>
        )}

        <section className="bg__dotted">
          <div className="container">
            <div className="section__header mb-5">
              <div className="title mb-2" style={{ fontSize: '2.3rem', fontWeight: '500' }}>
                FAQs
              </div>
              <div className="mx-auto" style={{ maxWidth: '60px', borderBottom: '3px solid #8e51f0' }}></div>
            </div>

            <ExpansionPanel defaultExpanded>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">What is Tenopy?</span>
              </ExpansionPanelSummary>
              <Divider />
              <ExpansionPanelDetails>
                Tenopy is a live online tutoring platform for students to learn from top tutors, through live
                interactive classes, from the comforts of home. Students can also watch the video playback of their
                lessons. It’s flexible, hassle-free and convenient, as students can learn from the comfort of their
                homes, anytime.
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">How do you interact with the tutor during lesson?</span>
              </ExpansionPanelSummary>
              <Divider />
              <ExpansionPanelDetails>
                During a live online class, students can see the tutor teaching, participate in quizzes and ask
                questions in real time via chat box. During a live online class, students can see the tutor teaching,
                participate in quizzes and ask questions in real time via chat box. The tutor will also enable the
                students’ microphone to allow them to speak during class when the student clicks “raise hand”.
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">Do you have your own curriculum?</span>
              </ExpansionPanelSummary>
              <Divider />
              <ExpansionPanelDetails>
                Yes, our curriculum is developed based on Singapore’s syllabus. We are also partnering with several
                established education providers to bring their content and expertise to our platform. Find out about our
                class schedules and details <Link to={ALL_CLASSES}>here</Link>.
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">What classes do you currently offer?</span>
              </ExpansionPanelSummary>
              <Divider />
              <ExpansionPanelDetails>
                Tenopy currently offers English, Mathematics and Science subjects for Primary 3 to Primary 6. Keep a
                lookout for our future announcement for more classes.
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">Do you give lesson notes and homework?</span>
              </ExpansionPanelSummary>
              <Divider />
              <ExpansionPanelDetails>
                Yes, lesson notes and homework will be uploaded by our tutors at least 2 days prior to the scheduled
                lesson. Students can also submit Tenopy homework to get tutors’ feedback on our platform.
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">How do I keep track of my child’s learning progress?</span>
              </ExpansionPanelSummary>
              <Divider />
              <ExpansionPanelDetails>
                <div>
                  <p>
                    We will be giving a weekly Parent Report. This report is meant to provide you with information
                    regarding your child in several ways:
                  </p>
                  <ul>
                    <li>
                      Attendance: The report reflects whether your child has been attending lessons as expected for the
                      full duration of the lesson. You may ignore this if your child is watching the replay of lessons
                      due to his/her schedule.
                    </li>
                    <li>
                      Attention: The report reflects whether your child has been active and responsive during lessons by
                      answering the quiz questions.
                    </li>
                    <li>
                      Understanding: The report reflects areas of understanding that your child may need more guidance
                      or revision in. Please note, however, that some quiz questions are deliberately designed to be
                      especially tricky for students.
                    </li>
                  </ul>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">What is a free trial?</span>
              </ExpansionPanelSummary>
              <Divider />
              <ExpansionPanelDetails>
                Join a free trial lesson to see what online tutoring is like. This is a great way for you to check out a
                class or a tutor you’re keen on. You may join up to one free trial lesson per class, unless otherwise
                specified. Sign up for a free trial <Link to={TRIAL}>here</Link>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">What do I need to attend Tenopy lesson?</span>
              </ExpansionPanelSummary>
              <Divider />
              <ExpansionPanelDetails>
                <div>
                  <p>All you need to join Tenopy lessons is your personal computer and earphones with a microphone.</p>
                  <p>
                    You can also join lessons with your mobile phone, but you will be prompted to download and run a
                    companion app. Please follow the on-screen instructions to install this app. However, we suggest
                    having lessons on computer as students may absorb knowledge better with a bigger screen.
                  </p>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row align-items-center">
              <div className="col">
                <h1 className="text-primary font-weight-normal mb-0">Ready to start tutoring with Tenopy?</h1>
                <span style={{ fontSize: '18px' }}>Choose your lesson or get in touch.</span>
              </div>
              <div className="col-auto my-3 my-md-0">
                <Button
                  variant="contained"
                  color="primary"
                  className="mr-2"
                  onClick={this.scrollToClassSelection('footer')}>
                  Sign up
                </Button>
                <Button variant="outlined" color="primary" onClick={this.toggleGetInTouchDialog}>
                  Get in Touch
                </Button>
              </div>
            </div>
          </div>
        </section>

        <GetInTouchDialog
          isOpen={isGetInTouchDialogOpen}
          close={this.toggleGetInTouchDialog}
          intent="Conversion Page"
        />

        <VideoPlayerDialog
          isOpen={isVideoDialogOpen}
          close={this.toggleVideoDialog}
          videoURL={customConfig.introVideo}
        />

        <JoinTrialDialog
          isOpen={isJoinTrialDialogOpen}
          toggleDialog={this.toggleJoinTrialDialog}
          classData={selectedClass}
        />

        <DialogProceedToCheckout
          isOpen={isProceedToCheckoutDialogOpen}
          toggleDialog={this.toggleProceedToCheckoutDialog}
          proceedToCheckout={this.goToCart}
          classTitle={selectedClass ? selectedClass.name : ''}
          lessonsIncluded={lessonsIncluded}
        />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VariantD))
