import React from 'react'
import { connect } from 'react-redux'
import { CircularProgress, List, Paper } from '@material-ui/core'
import _ from 'lodash'
import Proptypes from 'prop-types'
import agent from 'agent'
import Pagination from 'utils/Pagination'
import { GET_CLASS_LESSONS, GET_FEEDBACK } from 'constants/actionTypes'
import { TUTOR, PARENT } from 'constants/userTypes'

import LessonCard from 'components/shared/Classes/LessonItem/LessonCard'
import NextLessonCard from 'components/shared/Classes/LessonItem/NextLessonCard'

const mapStateToProps = state => ({
  lessons: state.lessons.lessons,
  lessonCount: state.lessons.lessonCount,
  currentPage: state.lessons.currentPage,
  upcoming: state.lessons.upcoming,
  currentUser: state.common.currentUser,
  feedbackList: state.feedback.feedbackList,
  feedbackCount: state.feedback.feedbackCount,
  feedbackCreated: state.feedback.feedbackCreated
})

const mapDispatchToProps = dispatch => ({
  getLessons: (classId, filter, page) =>
    dispatch({ type: GET_CLASS_LESSONS, payload: agent.Lessons.getLessons(classId, filter), page }),
  getFeedback: filter => dispatch({ type: GET_FEEDBACK, payload: agent.UserInput.getFeedback(filter) })
})

class LessonList extends React.Component {
  state = {
    currentPage: 1,
    perPage: 15
  }

  componentDidMount() {
    if (!!this.props.lessons) {
      this.getFeedback()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPage !== this.state.currentPage) {
      let filter = { max: this.state.perPage, offset: (this.state.currentPage - 1) * this.state.perPage }
      this.updateLessonList(filter)
    }

    if (prevProps.lessons === null && !!this.props.lessons) {
      this.getFeedback()
    }

    if (!prevProps.feedbackCreated && this.props.feedbackCreated) {
      this.getFeedback()
    }
  }

  getFeedback = () => {
    const { lessons, currentUser } = this.props

    let lessonsList = lessons.data

    let filter = {
      feedback_target_id: {
        $in: lessonsList.map(l => l._id)
      },
      created_by: currentUser._id
    }

    this.props.getFeedback(filter)
  }

  onChangePage = page => {
    this.setState({ currentPage: page })
  }

  updateLessonList(filter) {
    this.props.getLessons(this.props.classId, filter, this.state.currentPage)
  }

  render() {
    const { lessons, lessonCount, upcoming, currentUser, currentPage } = this.props
    const { perPage } = this.state
    let data = lessons ? lessons.data : null
    let sortedLessons = data ? _.sortBy(data, ['scheduled_on']) : null

    return (
      <div className="container animated slideInUpTiny animation-duration-3">
        {upcoming && upcoming.length > 0 && (
          <div className="row no-gutter">
            <div className="col-12 custom-card">
              <NextLessonCard />
            </div>
          </div>
        )}

        <h2 className="mb-3">{currentUser._type === PARENT ? 'My Child Lessons' : 'My Lessons'}</h2>
        {!!lessonCount && (
          <div className="mt-3">
            <Pagination
              itemCount={lessonCount}
              onChangePage={this.onChangePage}
              pageSize={perPage}
              currentPage={currentPage}
            />
          </div>
        )}
        {sortedLessons ? (
          sortedLessons.length > 0 ? (
            <Paper elevation={2}>
              <List disablePadding={true}>
                {[...sortedLessons].reverse().map(lesson => {
                  return <LessonCard key={lesson._id} lesson={lesson} divider={true} currentUser={currentUser} />
                })}
              </List>
            </Paper>
          ) : currentUser._type === TUTOR ? (
            <h3>No lessons available</h3>
          ) : (
            <div className="row justify-content-center mt-4">
              <div className="col-md-4 col-8 text-center">
                <img
                  src="https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/frontend/illustration-tutor-working-on-lesson.png"
                  alt="tutor is preparing lesson"
                  className="img-fluid"
                />
                <h3 className="mt-4">
                  Welcome to this class!
                  <br />
                  Your tutor is working hard to prepare the lessons and resources for you.
                </h3>
              </div>
            </div>
          )
        ) : (
          <div className="my-5 text-center">
            <CircularProgress size={40} color={'primary'} />
          </div>
        )}
        {!!lessonCount && (
          <div className="mt-3">
            <Pagination
              itemCount={lessonCount}
              onChangePage={this.onChangePage}
              pageSize={perPage}
              currentPage={currentPage}
            />
          </div>
        )}
      </div>
    )
  }
}

LessonList.propTypes = {
  classId: Proptypes.string.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonList)
