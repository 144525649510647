import React from 'react'
import { CircularProgress } from '@material-ui/core'
import _ from 'lodash'

import CartItem from './item-row'
import ClearCartButton from './button-remove-items'
import { calculateQuote } from 'utils/cart'

let ItemList = props => {
  const { token, cartQuote, loading, cartClasses, cartItems } = props

  if (token) {
    if (loading || !cartQuote) {
      return (
        <div className="text-center p-3">
          <CircularProgress color="primary" size={35} />
        </div>
      )
    }

    const { quote_detail } = cartQuote

    if (cartQuote === 'empty_cart' || quote_detail.length === 0) {
      //if (quote_detail.length === 0) {
      return <h3>There is nothing in your cart</h3>
    }

    return (
      <>
        {quote_detail.map((quoteItem, index) => (
          <CartItem key={index} itemData={quoteItem} />
        ))}
        <div className="text-right py-3 px-2">
          <ClearCartButton action="clear" />
        </div>
      </>
    )
  } else {
    if (cartItems.length > 0 && !cartClasses) {
      return (
        <div className="text-center p-3">
          <CircularProgress color="primary" size={35} />
        </div>
      )
    }

    if (cartItems.length === 0) {
      return <h3>There is nothing in your cart</h3>
    }

    return (
      <>
        {cartItems.map((item, index) => {
          let classData = _.find(cartClasses, o => {
            return o._id === item.item_id
          })
          let itemQuote = calculateQuote(classData.lesson, classData.rate, item.start_date, item.end_date, item.item_id)

          return (
            <CartItem
              key={index}
              itemData={{
                class_obj: {
                  id: classData._id,
                  name: classData.name
                },
                ...itemQuote
              }}
            />
          )
        })}
        <div className="text-right py-3 px-2">
          <ClearCartButton action="clear" />
        </div>
      </>
    )
  }
}

export default ItemList
