import React from 'react'
import Proptypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'

import { CLASS_TYPE_REGULAR_CLASSES } from 'constants/generalTypes'
import TimeLineItem from 'components/ThemeComponents/TimeLine/TimeLineItem'

const LessonSchedule = props => {
  let { classType, lessons } = props

  if (classType === CLASS_TYPE_REGULAR_CLASSES) {
    let terms = ['1', '2', '3', '4']
    let termLessons = []
    termLessons = _.groupBy(lessons, o => {
      return o.meta.term
    })

    return (
      <div className={'timeline-section clearfix left'}>
        {terms.map((term, index) => {
          let currentTermLesson = termLessons[term]

          if (!currentTermLesson || currentTermLesson.length === 0) {
            return null
          }

          let item = {
            time: `${currentTermLesson.length} lesson`,
            title: `Term ${term}`
          }

          return (
            <TimeLineItem key={index} timeLine={item}>
              {currentTermLesson.map(lesson => (
                <div key={lesson._id} className={'row border mt-2'}>
                  <div className={'col-md-3 border-right'}>
                    <div className="py-2 px-3">
                      <div>
                        {moment(lesson.scheduled_on).format('h:mm a')} - {moment(lesson.ended_on).format('h:mm a')}
                      </div>
                      <div>
                        <small>{moment(lesson.scheduled_on).format('Do MMM, dddd')}</small>
                      </div>
                    </div>
                  </div>
                  <div className="col align-self-center px-3">
                    <div className="title--rubik font-weight-semibold py-2">{lesson.name}</div>
                    {lesson.desc && <div dangerouslySetInnerHTML={{ __html: lesson.desc }} />}
                  </div>
                </div>
              ))}
            </TimeLineItem>
          )
        })}
      </div>
    )
  }

  let item = {
    time: `${moment(lessons[0].scheduled_on).format('MMM')} - ${moment(lessons[lessons.length - 1].scheduled_on).format(
      'MMM YYYY'
    )}`,
    title: `All Lessons`
  }

  return (
    <div className={'timeline-section clearfix left'}>
      <TimeLineItem timeLine={item}>
        {lessons.map(lesson => (
          <div key={lesson._id} className={'row border mt-2'}>
            <div className={'col-md-3 border-right'}>
              <div className="py-2 px-3">
                <div>
                  {moment(lesson.scheduled_on).format('h:mm a')} - {moment(lesson.ended_on).format('h:mm a')}
                </div>
                <div>
                  <small>{moment(lesson.scheduled_on).format('Do MMM, dddd')}</small>
                </div>
              </div>
            </div>
            <div className="col align-self-center px-3">
              <div className="title--rubik font-weight-semibold py-2">{lesson.name}</div>
              {lesson.desc && <div dangerouslySetInnerHTML={{ __html: lesson.desc }} />}
            </div>
          </div>
        ))}
      </TimeLineItem>
    </div>
  )
}

LessonSchedule.propTypes = {
  classType: Proptypes.string.isRequired,
  lessons: Proptypes.array.isRequired
}

export default LessonSchedule
