export const CLASS_TYPE_WORKSHOP = 'Workshop'
export const CLASS_TYPE_REGULAR_CLASSES = 'Regular Classes'
export const TIMEZONE_ABBR = {
  '-0700': 'PST'
}

export const subjectColors = {
  Mathematics: '#F44336',
  Science: '#00b96c',
  other: '#2196F3'
}

export const BOOK_CLUB_IDS = [
  'oB3aw0o8RP-adEnNHvFH6w',
  '_q3a3QxwRUyN3QkcDbXX0Q',
  'Ml7l3z5NQpCiftZJ21aPjw',
  'TeQyzd6aTuuhbRqdnmNCHA'
]

export const OXKIDS_COLLABORATION = [
  'TeQyzd6aTuuhbRqdnmNCHA',
  '891rmPMdRUy5shYkpyJ1Xw',
  '_ajPacZ7QRyb4A36sTonkA',
  '2GhBe0xAQ5WL50mNJ4pQNQ',
  'mLBnfPNoTaG536CME5FWsw'
]
