import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { CircularProgress, Paper, Tab, Tabs } from '@material-ui/core'
import { ArrowBackIos as BackIcon } from '@material-ui/icons'

import agent from 'agent'
import { LOAD_CLASS } from 'constants/actionTypes'
import { MY_CLASS_LIST } from 'routes/routes'
import LessonTab from './tab-lesson'
import FeedbackTab from './tab-feedback'
import HomeworkTab from './tab-homework'
import DataTab from './tab-data'
import ReportTab from './tab-report-all'

const mapStateToProps = state => ({
  loadingClass: state.classes.loadingClass,
  selectedClass: state.classes.selectedClass
})

const mapDispatchToProps = dispatch => ({
  loadClass: payload => dispatch({ type: LOAD_CLASS, payload })
})

class ClassDetails extends React.Component {
  state = {
    activeTab: 'lesson'
  }
  componentDidMount() {
    const classId = this.props.match.params.classId
    this.props.loadClass(
      Promise.all([
        agent.Lessons.getLessons(classId, { max: 15, offset: 0 }),
        agent.Lessons.getLessons(classId, {
          sortBy: 'ended_on',
          order: 'asc',
          max: 1,
          ended_on: { $gte: new Date().toISOString() }
        }),
        agent.Classes.getById(classId)
      ])
    )
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadingClass && !this.props.loadingClass) {
      if (this.props.selectedClass === 'not_found') {
        this.props.history.push(MY_CLASS_LIST)
      }
    }
  }

  handleChange = (event, value) => {
    this.setState({ activeTab: value })
  }

  render() {
    let { selectedClass, loadingClass } = this.props
    let { activeTab } = this.state

    if (loadingClass || !selectedClass) {
      return (
        <div className="py-5 text-center">
          <div className="manage-margin">
            <CircularProgress size={50} />
          </div>
        </div>
      )
    }

    return (
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <Paper elevation={2} className="app-wrapper pt-3 pb-0 sticky-top" style={{ zIndex: 1201 }}>
          <div className="row pb-1">
            <div className="col-12 text-muted text-small">
              <span
                className="mr-2 clickable"
                onClick={() => {
                  this.props.history.push(MY_CLASS_LIST)
                }}>
                My Classes
              </span>
              /
              <span className="mx-2 font-weight-semibold">
                {' '}
                {`${selectedClass?.student[0]?.name ? selectedClass?.student[0]?.name : ''} ${selectedClass.name.slice(
                  6
                )}`}
              </span>
            </div>
            <div className="col d-flex flex-row align-items-center mt-2">
              <BackIcon
                className="clickable mr-2"
                onClick={() => {
                  this.props.history.goBack()
                }}
              />
              <h1 className="title--rubik mb-0">
                {' '}
                {`${selectedClass?.student[0]?.name ? selectedClass?.student[0]?.name : ''} ${selectedClass.name.slice(
                  6
                )}`}
              </h1>
            </div>
          </div>
          <Tabs value={activeTab} onChange={this.handleChange} scrollButtons="off">
            <Tab className="tab" label="Lesson" value="lesson" />
            {/* <Tab className="tab" label="Feedback" value="feedback" /> */}
            {/* <Tab className="tab" label="Homework" value="homework" /> */}
            {/* <Tab className="tab" label="Attendance Data" value="data" /> */}
            <Tab className="tab" label="Report" value="report" />
          </Tabs>
        </Paper>
        <div className="app-wrapper">
          {activeTab === 'lesson' && <LessonTab classId={selectedClass._id} />}
          {/* {activeTab === 'feedback' && <FeedbackTab classData={selectedClass} />} */}
          {/* {activeTab === 'homework' && <HomeworkTab classData={selectedClass} />} */}
          {/* {activeTab === 'data' && <DataTab classData={selectedClass} />} */}
          {activeTab === 'report' && <ReportTab classData={selectedClass} />}
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClassDetails))
