import * as Type from '../constants/actionTypes'
import { LOGIN, ACCOUNT_ACTIVATION, MY_CLASS_LIST, MY_CLASS_DETAILS, APP } from '../routes/routes'
import { setExternalServiceUserAttributes } from '../utils/helper'
import ReactGA from 'react-ga'
import _ from 'lodash'
import qs from 'qs'
import { STUDENT } from 'constants/userTypes'
import { DASHBOARD } from 'routes/studentRoutes'

const defaultState = {
  redirectTo: null,
  token: null,
  currentUser: null,
  appInitialized: false,
  purchasedClasses: [],
  trialClasses: [],
  utmData: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case Type.UPDATE_STUDENT:
      return {
        ...state,
        isLoading: true,
        userUpdated: false,
        error: false
      }
    case Type.UPDATE_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userUpdated: true,
        studentUpdated: action.payload.data
      }
    case Type.UPDATE_STUDENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true
      }

    case Type.INIT_APP_SUCCESS:
      return {
        ...state,
        redirectTo: null,
        token: action.payload.token,
        appInitialized: true
      }

    case Type.INIT_APP_FAILED:
      return {
        ...state,
        redirectTo: '/',
        token: null,
        appInitialized: false
      }

    case Type.LOGIN_SUCCESS: {
      let currentUser = action.payload.data
      ReactGA.set({ userId: currentUser._id })
      setExternalServiceUserAttributes(currentUser)

      let nextLocation = currentUser._type === STUDENT ? `${APP}${DASHBOARD}` : MY_CLASS_LIST
      if (action.next) {
        nextLocation = action.next
      }

      if (action.disableRedirect) {
        nextLocation = null
      }

      return {
        ...state,
        redirectTo: nextLocation,
        currentUser,
        token: action.payload.token,
        appInitialized: true
      }
    }

    case Type.REDIRECT:
      return {
        ...state,
        redirectTo: action.payload ? action.payload : null
      }

    case Type.GET_USER_DATA: {
      return {
        ...state,
        loadingUserData: true
      }
    }

    case Type.GET_USER_DATA_SUCCESS: {
      let currentUser = action.payload[0].data
      ReactGA.set({ userId: currentUser._id })
      setExternalServiceUserAttributes(currentUser)

      let orderList = action.payload[1].data
      let purchasedClasses = []
      let trialClasses = []
      let userClassWithDetail = {}

      for (let order of orderList) {
        if (order.hasOwnProperty('order_param')) {
          let classId = order.order_param.class_id ? order.order_param.class_id : order.order_param.class
          let lesson = order.detail.lesson
          let type = order.type

          let userLessonId
          if (userClassWithDetail[classId]) {
            userLessonId = userClassWithDetail[classId].lesson.concat(lesson)
            userLessonId = _.uniq(userLessonId)
            let updatedType =
              userClassWithDetail[classId].type === 'TRIAL' && type !== 'TRIAL'
                ? type
                : userClassWithDetail[classId].type
            userClassWithDetail[classId] = { lesson: userLessonId, type: updatedType }
          } else {
            if (_.isArray(lesson)) {
              userLessonId = lesson
            } else {
              userLessonId = []
              userLessonId.push(lesson)
            }
            userClassWithDetail[classId] = { lesson: userLessonId, type }
          }
        }
      }

      if (orderList.length > 0) {
        for (let i = 0; i < orderList.length; i++) {
          if (orderList[i].hasOwnProperty('order_param')) {
            let classId = orderList[i].order_param.class_id
              ? orderList[i].order_param.class_id
              : orderList[i].order_param.class
            if (orderList[i].type === 'TRIAL') {
              trialClasses.push(classId)
            } else if (orderList[i].type === 'PAYMENT') {
              purchasedClasses.push(classId)
            }
          }
        }
      }
      purchasedClasses = _.uniq(purchasedClasses)
      return {
        ...state,
        loadingUserData: false,
        currentUser,
        purchasedClasses,
        trialClasses,
        userClassWithDetail
      }
    }

    case Type.GET_USER_DATA_FAILED: {
      return {
        ...state,
        loadingUserData: false
      }
    }

    case Type.JOIN_TRIAL_SERVICE_SUCCESS: {
      let classId = action.payload.data.order_param.class_id
      let trialClasses = state.trialClasses ? state.trialClasses : []
      trialClasses = _.concat(trialClasses, classId)

      return {
        ...state,
        trialClasses
      }
    }

    case Type.UPDATE_USER:
      return {
        ...state,
        updateUserInProgress: true
      }

    case Type.UPDATE_USER_SUCCESS: {
      const userCredit = state.currentUser.credit
      return {
        ...state,
        updateUserInProgress: false,
        currentUser: {
          ...action.payload.data,
          credit: userCredit
        }
      }
    }

    case Type.UPDATE_USER_FAILED:
      return {
        ...state,
        updateUserInProgress: false
      }

    case Type.UPDATE_PARENT:
      return {
        ...state
      }

    case Type.UPDATE_PARENT_SUCCESS: {
      return {
        ...state
      }
    }

    case Type.UPDATE_PARENT_FAILED:
      return {
        ...state
      }

    case Type.UPDATE_STUDENT_PROFILE:
      return {
        ...state
      }

    case Type.UPDATE_STUDENT_PROFILE_SUCCESS: {
      return {
        ...state
      }
    }

    case Type.UPDATE_STUDENT_PROFILE_FAILED:
      return {
        ...state
      }

    case Type.GET_CHILD_DETAILS:
      return {
        ...state
      }

    case Type.GET_CHILD_DETAILS_SUCCESS: {
      return {
        ...state,
        child: {
          ...action.payload.data
        }
      }
    }

    case Type.GET_CHILD_DETAILS_FAILED:
      return {
        ...state
      }

    case Type.DETECT_USER_SUCCESS: {
      const { class_name, tutor, email, message, type } = action.payload.data
      const classId = action.classId
      const actionToken = action.token

      let redirectTo = '/'
      if (message === 'verified') {
        if (state.token) {
          redirectTo = MY_CLASS_DETAILS.replace(':classId', classId)
        } else {
          redirectTo = LOGIN + `?next=${MY_CLASS_DETAILS.replace(':classId', classId)}`
        }
      } else if (message === 'not_verified') {
        redirectTo =
          ACCOUNT_ACTIVATION +
          `?token=${actionToken}&email=${email}&class_name=${class_name}&tutor=${tutor}&next=${MY_CLASS_DETAILS.replace(
            ':classId',
            classId
          )}&type=${type}`
      }

      return {
        ...state,
        redirectTo
      }
    }

    case Type.STORE_UTM_DATA:
      const params = qs.parse(window.location.search.slice(1))
      const utmData = { meta: {} }
      Object.entries(params).forEach(param => {
        if (param[0].substring(0, 4) === 'utm_') {
          const key = param[0].slice(4)
          if (['source', 'campaign', 'medium'].includes(key)) {
            utmData[key] = param[1]
          } else {
            utmData.meta[key] = param[1]
          }
        }
      })
      if (Object.keys(utmData).length > 1) {
        window.localStorage.setItem('_utm', JSON.stringify(utmData))
        return {
          ...state,
          utmData
        }
      }
      return {
        ...state
      }

    case Type.LOGOUT:
      return {
        redirectTo: '/',
        token: null,
        currentUser: null,
        appInitialized: false,
        purchasedClasses: [],
        utmData: null
      }

    default:
      return state
  }
}
