import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import ProgramsCard from 'components/public/ProgramsCard'
import ServicesCard from 'components/public/ServicesCard'
import CurriculumCard from 'components/public/Curriculum'
import { isMobile } from 'react-device-detect'
import { CONTACT } from 'routes/routes'
import StudentDestinationCard from 'components/public/StudentDestinationCard'
import CTACard from 'components/public/CTACard'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

const styles = () => ({
  container: {
    overflowX: 'auto'
  },
  table: {
    minWidth: 650,
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)'
    }
  },
  cell: {
    fontFamily: "'Lato', sans-serif"
  },
  cellHeader: {
    fontFamily: "'Lato', sans-serif",
    fontSize: '20px',
    fontWeight: 'bold'
  }
})

class Services extends React.Component {
  state = {
    margin: '34.2vh'
  }

  listenScrollEvent = e => {
    if (window.scrollY > 100) {
      this.setState({ margin: '5.3vh' })
    } else {
      this.setState({ margin: '34.2vh' })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  render() {
    let { margin } = this.state
    let { classes } = this.props
    return (
      <div className="landing">
        <Helmet>
          <title>Services | EPA</title>
          <meta
            name="description"
            content="Enabling students to unleash their potential while embracing the future with confidence and wisdom."
          />
          <meta
            name="keywords"
            content="Attaining Excellence, Unleashing your potential,Bringing out the best in you, Your dream, our mission 
        Nurturing uniqueness, Igniting your passion"
          />
        </Helmet>
        <div style={{ marginTop: '13vh', marginBottom: '12.86vh' }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 text-center">
                <div className="row">
                  <div className="col-lg-3"></div>
                  <div className="section__header_landing_title col-lg-6">
                    <div
                      className="title mb-2 h"
                      style={{
                        fontFamily: "'Libre Baskerville', sans-serif",
                        lineHeight: 1.2,
                        color: '#000000'
                      }}>
                      Our solution to your educational needs.
                    </div>
                  </div>
                  <div className="col-lg-3"></div>
                </div>
                <div className="section__header_landing_title">
                  <div className="paragraph mt-2 mb-2 h">No matter what you need help with, we’ve got you covered.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section style={{ backgroundColor: '#F7EFEA' }}>
          <div className="container">
            <div className="row align-items-center justify-content-around mt-4 mb-2">
              <div className="col-12 col-md-12">
                <div className="row">
                  <ProgramsCard
                    title={'Tutoring'}
                    subtitle={'Academic & Test Preparation'}
                    image={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/tutoring.svg'}
                    description={
                      'Whether you need more pointers for school work or to prepare for an upcoming exam, we’ve got you covered.'
                    }
                    link={'tutoring'}
                    color={'#00BF12'}
                    hoverColor={'#008C0D'}
                  />

                  <ProgramsCard
                    title={'CollegeWise'}
                    subtitle={'University Admissions Counseling'}
                    image={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/collegwise.svg'}
                    description={
                      'Our private counseling program is a personalized coaching experience by a team of expert mentors who share your passion and ambition. '
                    }
                    link={'collegewise'}
                    color={'#007AFF'}
                    hoverColor={'#0062CC'}
                  />

                  <ProgramsCard
                    title={'Homeschool'}
                    subtitle={'Your Personalized Curriculum'}
                    image={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/homeschool.svg'}
                    description={
                      'Our personalized Homeschool program is designed by experienced experts, we are ready to help you plan and accomplish your dream curriculum.'
                    }
                    link={'homeschool'}
                    color={'#9B4DFF'}
                    homeschool={true}
                    hoverColor={'#7C3DCC'}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white" id="tutoring">
          <div className="" style={{ marginBottom: margin }}></div>
          <div className="container px-4">
            <ServicesCard
              heading={'Tutoring'}
              subheading={
                'An academic solution for students of all ages and grade levels. Our expert teachers are adept not only in their field but at equipping the students with life-long learning skills.'
              }
              marginToCut={'2.3vh'}
              headingImage={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/EPA_Web_Tutoring.jpg'}
              title1={'Private and Tailored'}
              description1={
                'We provide one-to-one or small group classes to help nurture curiosity and develop independent scholars and critical thinkers.'
              }
              image1={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/green1.svg'}
              title2={'Support for Every Subject'}
              description2={
                'Our vast team of experienced teachers are experts in a wide range of areas and curriculum. We are ready to help you tackle any unique subjects.'
              }
              image2={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/green2.svg'}
              marginForText2={'0.65vh'}
              title3={'Personalized Strategies'}
              description3={
                ' Our tutors are up-to-date on all exam formats and are ready to help you create a personal strategy to achieve your target score and academic goals.'
              }
              image3={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/green3.svg'}
              marginForText3={'1vh'}
              color={'#00BF12'}
            />

            <div
              className="col-12"
              style={{
                marginTop: '9.648vh',
                backgroundColor: '#F6EFEA',
                padding: isMobile ? '3.2vh' : '6.43216vh',
                borderRadius: '8px'
              }}>
              <div className="section__header_landing_title">
                <div className="text-center header--bold">A Holistic Method</div>
                <div className="row justify-content-center">
                  <div className="col-lg-5 col-12" style={{ padding: '3.01vh' }}>
                    <div
                      className="col-lg-12 text-center mt-2"
                      style={{
                        backgroundColor: 'white',
                        paddingTop: '2.68228vh',
                        paddingBottom: '2.0539152vh',
                        borderRadius: '8px'
                      }}>
                      <img
                        src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/idea.svg"
                        width={isMobile ? '24%' : '14%'}></img>
                      <p className="paragraph--bold mt-2">Understanding Concepts</p>
                      <p className="description mt-3 padding--concepts">
                        We emphasize a holistic skill-based learning method to ensure that you understand concepts and
                        are able to apply them to different situations: in the classroom, exams, or beyond their
                        academic journey.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-5 col-12" style={{ padding: '3.01vh' }}>
                    <div
                      className="col-lg-12 text-center mt-2"
                      style={{
                        backgroundColor: 'white',
                        paddingTop: '2.68228vh',
                        paddingBottom: '2.0539152vh',
                        borderRadius: '8px'
                      }}>
                      <img
                        src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/trend.svg"
                        width={isMobile ? '24%' : '14%'}></img>
                      <p className="paragraph--bold mt-2">Up-to-date Exam Analysis</p>
                      <p className="description mt-3 padding--exam-analysis">
                        With over 2 decades of experience analysing exams, EPA has distilled the essence of the
                        ever-changing exam format. Personalized strategies are created for each student, with their
                        strengths and weaknesses in mind.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 section__header_landing_title" style={{ marginTop: '6em' }}>
              <div className="col-lg-6 mb-5" style={{ marginLeft: '-8px' }}>
                <p className="paragraph--bold">We provide support for the following curriculums</p>
                <p className="description" style={{ marginBottom: '1.5em' }}>
                  Our highschool program is the total academic solution for students in the international school system.
                  It is tailored to the American and British educational systems, as well as many other curriculums
                  below.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-4 col-12">
                  <p className="description--bold">UK Curriculum</p>

                  <CurriculumCard
                    title={'IGCSE/GCSE'}
                    description={'UK-based secondary school examination for Year 11 in various subjects'}
                  />
                  <CurriculumCard
                    title={'UKiset'}
                    description={'Assessment designed for entry into British curriculum schools'}
                    marginToCut={'20px'}
                  />
                  <CurriculumCard
                    title={'A-Levels'}
                    description={`World's most recognised qualifications for UK-based university entry`}
                    marginToCut={'20px'}
                  />
                  <CurriculumCard
                    title={' Cambridge Pre-U'}
                    description={'College preparation qualification for students age 16+'}
                  />
                  <CurriculumCard
                    title={'Common Entrance (CE)'}
                    description={'Entrance examinations to independent schools for ages 11+ or 13+'}
                  />
                </div>

                <div className="col-lg-4 col-12">
                  <p className="description--bold mt-4 mt-lg-0">US Curriculum</p>
                  <CurriculumCard
                    title={'Advanced Placement (AP)'}
                    description={'College Board curriculum for American student’s college credit and placement'}
                  />
                  <CurriculumCard
                    title={'High School Diploma'}
                    description={'Tutoring for US-based high school subjects'}
                    marginToCut={'40px'}
                  />
                </div>

                <div className="col-lg-4 col-12">
                  <p className="description--bold mt-4 mt-lg-0">Others</p>
                  <CurriculumCard
                    title={'International Baccalaureate (IB)'}
                    description={
                      'Program of study designed to prepare students aged 16–19 for success at university and life beyond'
                    }
                  />
                  <CurriculumCard title={'TOEFL'} description="US-based English proficiency exam" />
                </div>

                <div className="col-lg-6" style={{ marginTop: '6em' }}>
                  <p className="paragraph--bold">Standardized Tests</p>
                  <p className="description" style={{ marginBottom: '1.5em', marginRight: '69px' }}>
                    With our experience in exam analysis and identifying exam trends, we are experts in teaching
                    valuable strategies to students who may be new to the world of standardised testing. Our expertise
                    covers the tests below.
                  </p>
                </div>
                <div className="row" style={{ marginBottom: '10.20vh' }}>
                  <div className="col-lg-4 col-12 px-4">
                    <CurriculumCard
                      title={'SATs'}
                      description={'Standardized English and Math test for applying to universities in the USA'}
                      extraContent={
                        'Coupled with teaching the core curriculum, our unique strategy helps students target the elemental concepts and logical reasoning they needed to ace the SAT with flying colors.'
                      }
                    />
                  </div>
                  <div className="col-lg-4 col-12 px-4">
                    <CurriculumCard
                      title={'IELTS'}
                      description="English language proficiency test for non-native English speakers"
                      extraContent={
                        'Rather than doing repetitive exercises and memorising patterns, our approach to the IELTS is to teach students English contextually and to build vocabularies centered around everyday use. '
                      }
                    />
                  </div>
                  <div className="col-lg-4 col-12 px-3">
                    <CurriculumCard
                      title={'BMAT (BioMedical Aptitudes Test)'}
                      description={'Aptitude test for admissions in Medicine, Biomedical Sciences and Dentistry'}
                      extraContent={
                        'With decades of experience helping students pass the BMAT, we are skilled in teaching our students to think critically and write precisely, for optimal results. '
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white" id="collegewise">
          <div className="divider-section" style={{ marginBottom: isMobile ? '5vh' : '24.20vh' }}></div>
          <div className="container px-4" style={{ marginBottom: '10.20vh' }}>
            <ServicesCard
              heading={'CollegeWise'}
              subheading={
                'Our private counseling program is a personalized coaching experience by a team of expert mentors who share your passion and ambition. With access to a network of accomplished alumni, you will be prepared for lifelong success.'
              }
              marginToCut={'0.5vh'}
              headingImage={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/EPA_Web_Collegewise.jpg'}
              title1={'Discover Your Career'}
              image1={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/blue1.svg'}
              description1={
                'Our expert counselors will help uncover your passion and talent through personality analysis. With our connection to an extensive network of alumni, you will be able to hear from working professionals about your career  of interest.'
              }
              title2={'All-In-One Solution'}
              description2={
                'Our team is prepared to help students every step of the way. We are here to offer support during every step of this challenging process: from selecting colleges, to writing essays, and submitting applications.'
              }
              image2={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/blue2.svg'}
              marginForText2={'1vh'}
              title3={'Counselor & Expert Team'}
              description3={
                'Our unique program pairs each student with a team of two counselors: an application manager to oversee the student’s application materials, and an expert in your field of interest to offer major-specific insight.'
              }
              marginForText3={'0.5vh'}
              image3={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/blue3.svg'}
              color={'#007AFF'}
            />

            <p className="info-paragraph-black--bold" style={{ marginTop: '3em' }}>
              Student destinations
            </p>
            <StudentDestinationCard type={2} />
          </div>
        </section>
        <section className="bg-white" id="homeschool">
          <div className="divider-section" style={{ marginBottom: isMobile ? '5vh' : '24.20vh' }}></div>
          <div className="container">
            <ServicesCard
              heading={'Homeschool'}
              subheading={
                'We get it. Maybe traditional schooling is not for you. Our Homeschool program will not only help you attain a High School diploma, but will also help you discover your talent and passion. We curate personalised curriculums for each student according to their needs.'
              }
              marginToCut={'1.4vh'}
              headingImage={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/EPA_Web_Homeschool.jpg'}
              title1={'Curriculum Designed by You'}
              description1={
                'We help you select and take charge of your subjects to create an ideal education pathway suited to their individual goals, based on your strengths and interests.'
              }
              image1={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/purple1.svg'}
              marginForText={'10px'}
              marginForText1={'10px'}
              title2={'Take Charge of Your Education'}
              description2={
                'With our recommendation and guidance, you can achieve the qualification of traditional schooling and so much more.'
              }
              image2={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/purple2.svg'}
              title3={'Complete Subject Coverage'}
              description3={
                'We provide a detailed plan and clear set goals to students and parents. The plan is constantly reviewed and revised as the student progresses.'
              }
              image3={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/purple3.svg'}
              color={'#9B4DFF'}
            />
            <div className="col-12 section__header_landing_title" style={{ marginTop: '6em', marginBottom: '10.20vh' }}>
              <p className="paragraph--bold">We provide support for the following curriculums</p>
              <div className="row mt-5">
                <div className="col-lg-4 col-12 text-justify">
                  <CurriculumCard
                    title={'GED'}
                    type={3}
                    description={'Subject-based alternative high school qualification for US and Canada'}
                  />
                </div>
                <div className="col-lg-4 col-12 text-justify">
                  <CurriculumCard
                    title={'IGCSE/GCSE'}
                    type={3}
                    description={'UK-based secondary school examination for Year 11 in various subjects'}
                  />
                </div>
                <div className="col-lg-4 col-12 text-justify">
                  <CurriculumCard
                    title={'A-Levels'}
                    type={3}
                    description="Subject-based qualification for UK-based university placement"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white">
          <div className="divider-section" style={{ marginBottom: '24.20vh' }}></div>
          <div className="container" style={{ marginBottom: '-6em' }}>
            <div className="row align-items-center">
              <div className="col-md-12 text-center">
                <div className="row">
                  <div className="col-lg-3"></div>
                  <div className="section__header_landing_title col-lg-6">
                    <div className="header--bold mb-2 h">Not sure what you're looking for?</div>
                  </div>
                  <div className="col-lg-3"></div>
                </div>
                <p className="mt-2" style={{ color: 'black', fontSize: isMobile ? '20px' : '30px' }}>
                  Give us a call. Our team is here to help you out.
                </p>
                <div className="col-12 row">
                  <div className="col-lg-4 d-none d-lg-block"></div>
                  <div className="col-lg-4 col-12">
                    <p className="mt-2 info-header-black--bold numeric-normal">
                      {isMobile ? (
                        <img
                          width="10%"
                          src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/phone.svg"
                          alt="phone"
                          className="my-2 mx-3"
                        />
                      ) : (
                        <img
                          width="8%"
                          src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/phone.svg"
                          alt="phone"
                          className="my-2 mx-3"
                        />
                      )}
                      02-611-0348
                    </p>
                  </div>
                  <div className="col-lg-4 d-lg-block d-none"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <CTACard />
      </div>
    )
  }
}

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Services)))
