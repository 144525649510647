import React from 'react'
import { Button, Paper, Grid } from '@material-ui/core'
import moment from 'moment'
import { Link } from 'react-router-dom'
import DialogEditLessonMeta from './DialogLessonEditMeta'
import { GET_LESSON_BY_ID, EDIT_LESSON, UPDATE_ACTION_ONLY_LOADING } from 'constants/actionTypes'
import agent from 'agent'
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { TextField, Radio, RadioGroup, FormControl, FormControlLabel, Checkbox } from '@material-ui/core'
import { PARENT } from 'constants/userTypes'
const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  loadingLessons: state.lessons.loadingLessons
})

const mapDispatchToProps = dispatch => ({
  editLessonMeta: lessonData => dispatch({ type: EDIT_LESSON, payload: agent.Lessons.editLessonMeta(lessonData) }),
  getLessonMeta: lessonId => dispatch({ type: GET_LESSON_BY_ID, payload: agent.Lessons.getLessonMeta(lessonId) }),
  consultImmediately: (id, checked) =>
    dispatch({ type: UPDATE_ACTION_ONLY_LOADING, payload: agent.Lessons.consultImmediately(id, { checked }) })
})

const OverallImage = props => {
  return (
    <img
      src={`https://epa-assets.s3.ap-southeast-1.amazonaws.com/lms/${props.props}`}
      width={isMobile ? '300' : '400'}
      height={isMobile ? '200' : '250'}></img>
  )
}

class HomeTab extends React.Component {
  state = {
    isOpen: false,
    lessonDataNew: null
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  saveLesson = lessonData => {
    this.setState({ lessonDataNew: lessonData })
    this.props.editLessonMeta(lessonData)
  }

  handleCheckboxChange = (e, checked) => {
    let id = e.target.name
    this.props.consultImmediately(id, checked)
  }

  componentDidUpdate(prevProps, prevState) {
    const { lessonDataNew } = this.state

    if (prevState.lessonDataNew !== this.state.lessonDataNew) {
      this.props.getLessonMeta(lessonDataNew.lessonId)
    }

    if (prevProps.loadingLessons !== this.props.loadingLessons) {
      this.props.getLessonMeta(this.props.lessonData._id)
    }
  }

  render() {
    const { isOpen } = this.state
    const { lessonData, currentUser } = this.props
    let lessonMeta = lessonData.lesson_meta
    // let lessonStudent = lessonData.student[0]
    let currentTime = moment()
    let lessonScheduledTime = moment(lessonData.scheduled_on)
    let lessonEndTime = moment(lessonData.ended_on)
    let lessonEnded = currentTime > lessonEndTime
    let lessonInSession = currentTime > lessonScheduledTime && currentTime < lessonEndTime
    let lessonInFuture = currentTime < lessonScheduledTime

    let total =
      (parseInt(lessonMeta?.engagement?.engagementParticipation) +
        parseInt(lessonMeta?.engagement?.engagementDistracted) +
        parseInt(lessonMeta?.engagement?.engagementTiredBored) +
        parseInt(lessonMeta?.engagement?.engagementMotivate) +
        parseInt(lessonMeta?.engagement?.engagementAskQuestions)) /
      5
    let overall = Math.round(total)

    let totalMastery =
      (parseInt(lessonMeta?.skill_mastery?.skillMasteryAnswerQuestion) +
        parseInt(lessonMeta?.skill_mastery?.skillMasteryConfused) +
        parseInt(lessonMeta?.skill_mastery?.skillMasteryShowUnderstanding) +
        parseInt(lessonMeta?.skill_mastery?.skillMasteryParticipative) +
        parseInt(lessonMeta?.skill_mastery?.skillMasteryUnderstandConcept)) /
      5
    let overallMastery = Math.round(totalMastery)

    return (
      <>
        <Paper className="text-large p-3">
          <div className="app-wrapper">
            <div className="row align-items-center">
              <h3 className="col-md-6 col-6 mt-4"> Engagement </h3>
              <h3 className="col-md-5 mt-4 d-none d-md-block"> Mastery of skills </h3>

              {currentUser._type !== PARENT && (
                <div className="col-md-1 mt-3 mt-md-0 col-6 mb-3 mb-md-2">
                  <Grid container justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      className="text-white"
                      onClick={() => {
                        this.setState({ isOpen: true })
                      }}>
                      Edit
                    </Button>
                  </Grid>
                </div>
              )}

              <div className="col-md-6 col-12 my-2">
                {overall === 1 && <OverallImage props={'scale1_bad.png'} />}
                {overall === 2 && <OverallImage props={'scale2_notgood.png'} />}
                {overall === 3 && <OverallImage props={'scale3_good.png'} />}
                {overall === 4 && <OverallImage props={'scale4_good.png'} />}
                {overall === 5 && <OverallImage props={'scale5_verygood.png'} />}
                {overall === 6 && <OverallImage props={'scale6_excellent.png'} />}
              </div>

              <h3 className="col-md-6 col-12 mt-4 d-md-none d-block"> Mastery of skills </h3>
              <div className="col-md-6 col-12 my-2">
                {overallMastery === 1 && <OverallImage props={'scale1_bad.png'} />}
                {overallMastery === 2 && <OverallImage props={'scale2_notgood.png'} />}
                {overallMastery === 3 && <OverallImage props={'scale3_good.png'} />}
                {overallMastery === 4 && <OverallImage props={'scale4_good.png'} />}
                {overallMastery === 5 && <OverallImage props={'scale5_verygood.png'} />}
                {overallMastery === 6 && <OverallImage props={'scale6_excellent.png'} />}
              </div>

              <h3 className="col-md-3 d-none d-md-block mt-5"> Punctuality </h3>
              <div className="col-md-8 my-2 d-none d-md-block">{/*Punctuality space block*/}</div>

              <div className="col-md-1 col-12 mb-3 mb-md-2">{/*Punctuality space block*/}</div>
              <h3 className="col-5 d-md-none d-flex"> Punctuality </h3>

              <div className="col-md-4 col-8 my-2"> Late to class? </div>
              <div className="col-md-8 col-4 my-2">
                {lessonMeta?.punctuality === 'y' ? (
                  <span className="danger-chip">Yes</span>
                ) : lessonMeta?.punctuality === 'n' ? (
                  'No'
                ) : (
                  '---'
                )}
              </div>

              <h3 className="col-md-3 d-none d-md-block mt-5"> Cancellation of class </h3>
              <div className="col-md-8 my-2 d-none d-md-block">{/*Punctuality space block*/}</div>

              <div className="col-md-1 col-12 mb-3 mb-md-2">{/*Punctuality space block*/}</div>
              <h3 className="col-5 d-md-none d-flex"> Cancellation of class </h3>

              <div className="col-md-4 col-8 my-2"> Did student cancel class? </div>
              <div className="col-md-8 col-4 my-2">
                {lessonMeta?.cancelledClass === 'y' ? (
                  <span className="danger-chip">Yes</span>
                ) : lessonMeta?.cancelledClass === 'n' ? (
                  'No'
                ) : (
                  '---'
                )}
              </div>

              <h3 className="col-md-3 col-12 mt-5"> Lesson topics / Skills taught </h3>
              <div className="col-md-9 col-0 my-2">{/*Lesson topic space block*/}</div>

              <div className="col-md-4 col-4 my-2">Skills taught in this lesson</div>
              <div className="col-md-8 col-8 my-2">
                {lessonMeta?.lesson_topic === '' ? '---' : lessonMeta?.lesson_topic}
              </div>
              <h3 className="col-md-3 col-12 mt-5"> Homework Assignment </h3>
              <div className="col-md-9 col-0 my-2">{/*Homework Assignment space block*/}</div>
              <div className="col-md-4 col-8 my-2">Any homework submission?</div>
              <div className="col-md-2 col-4 my-2">
                {lessonMeta?.homework?.homeworkAssignment === 'y'
                  ? 'Yes'
                  : lessonMeta?.homework?.homeworkAssignment === 'n'
                  ? 'No'
                  : '---'}
              </div>

              {lessonMeta?.homework?.homeworkAssignment === 'y' && (
                <>
                  <div className="col-md-4 col-8 my-2">Homework Submission</div>
                  <div className="col-md-2 col-4 my-2">
                    {lessonMeta?.homework?.homeworkSubmission === '1' ? (
                      'Yes, on time'
                    ) : lessonMeta?.homework?.homeworkSubmission === '2' ? (
                      <span className="warning-chip">Yes, but late</span>
                    ) : lessonMeta?.homework?.homeworkSubmission === '3' ? (
                      <span className="danger-chip">No</span>
                    ) : (
                      '---'
                    )}
                  </div>

                  <div className="col-md-4 col-8 my-2">Is homework past paper?</div>
                  <div className="col-md-2 col-4 my-2">
                    {lessonMeta?.homework?.homeworkSubmissionPastPaper ? 'Yes' : 'No'}
                  </div>

                  {lessonMeta?.homework?.homeworkSubmissionPastPaper && (
                    <>
                      <div className="col-md-4 col-8 my-2">Result / Comments on past paper</div>
                      <div className="col-md-2 col-4 my-2">{lessonMeta?.homework?.homeworkSubmissionComment}</div>
                    </>
                  )}

                  {!lessonMeta?.homework?.homeworkSubmissionPastPaper && (
                    <>
                      {' '}
                      <div className="col-md-4 col-8 my-2">{/*space here*/}</div>
                      <div className="col-md-2 col-4 my-2">{/*space here*/}</div>
                    </>
                  )}
                </>
              )}

              {lessonMeta?.homework?.homeworkAssignment !== 'y' && (
                <>
                  <div className="col-md-4 col-8 my-2">{/*space here*/}</div>
                  <div className="col-md-2 col-4 my-2">{/*space here*/}</div>
                </>
              )}

              <h3 className="col-md-3 col-12 mt-5"> Revision Notes </h3>
              <div className="col-md-9 col-0 my-2">{/* Revision Notes space block*/}</div>

              <div className="col-md-4 col-8 my-2">Revision Notes Submission</div>
              <div className="col-md-2 col-4 my-2">
                {lessonMeta?.revision_notes === '1' ? (
                  'Yes, on time'
                ) : lessonMeta?.revision_notes === '2' ? (
                  <span className="warning-chip">Yes, but late</span>
                ) : lessonMeta?.revision_notes === '3' ? (
                  <span className="danger-chip">No</span>
                ) : (
                  '---'
                )}
              </div>

              <div className="col-md-4 col-8 my-2">{/*space here*/}</div>
              <div className="col-md-2 col-4 my-2">{/*space here*/}</div>

              <h3 className="col-md-3 col-12 mt-5"> Comment </h3>
              <div className="col-md-9 col-0 my-2">{/* Comment space block*/}</div>

              {currentUser._type !== PARENT ? (
                <>
                  <div className="col-md-4 col-4 my-2">Comments on student for this lesson</div>
                  <div className="col-md-8 col-8 my-2">
                    {lessonMeta?.comment?.comment === '' ? '---' : lessonMeta?.comment?.comment}
                  </div>

                  <div className="col-md-4 col-8 my-2">Can student see this comment?</div>
                  <div className="col-md-8 col-4 my-2">
                    {lessonMeta?.comment?.commentStudentView ? (
                      <span className="danger-chip">Yes</span>
                    ) : (
                      <span className="normal-chip">No</span>
                    )}
                  </div>

                  <div className="col-md-4 col-8 my-2">Can parent see this comment?</div>
                  <div className="col-md-8 col-4 my-2">
                    {lessonMeta?.comment?.commentParentView ? (
                      <span className="danger-chip">Yes</span>
                    ) : (
                      <span className="normal-chip">No</span>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="col-md-4 col-4 my-2">Comments on student for this lesson</div>
                  <div className="col-md-8 col-4 my-2">
                    {lessonMeta?.comment?.commentParentView ? (
                      <span>{lessonMeta?.comment?.comment}</span>
                    ) : (
                      <span>No comments for this lesson</span>
                    )}
                  </div>
                </>
              )}
              <h3 className="col-md-3 col-12 mt-5"> Recommendation </h3>
              <div className="col-md-9 col-0 my-2">{/* Recommendation space block*/}</div>

              <div className="col-md-4 col-4 my-2">More Lessons </div>
              <div className="col-md-8 col-8 my-2">
                {lessonMeta?.recommendation?.recommendationLesson ? 'Yes' : 'No'}
              </div>

              <div className="col-md-4 col-4 my-2">Consult with Kru A? </div>
              <div className="col-md-8 col-8 my-2">
                {lessonMeta?.recommendation?.recommendationConsult ? 'Yes' : 'No'}
              </div>

              <div className="col-md-4 col-4 my-2">Others / Remark</div>
              <div className="col-md-8 col-8 my-2">
                {lessonMeta?.recommendation?.recommendationOthers === ''
                  ? '---'
                  : lessonMeta?.recommendation?.recommendationOthers}
              </div>

              {currentUser._type !== PARENT && (
                <>
                  <div className="col-md-4 col-4 my-2">Kru A to call immediately </div>
                  <div className="row ml-1 col-md-8 col-8 my-2">
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={`${lessonData?._id}`}
                            checked={lessonMeta?.recommendation?.recommendationConsultImmediately}
                            color="primary"
                            onChange={this.handleCheckboxChange}
                          />
                        }
                      />
                    </FormControl>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      {lessonMeta?.recommendation?.recommendationConsultImmediatelyTimeStamp
                        ? `Resolved by ${lessonMeta?.recommendation?.recommendationConsultImmediatelyUser} on ${moment(
                            lessonMeta?.recommendation?.recommendationConsultImmediatelyTimeStamp
                          ).format('llll')}`
                        : ''}
                    </span>
                  </div>
                  {lessonMeta?.notes && (
                    <>
                      <h3 className="col-md-3 col-12 mt-5"> Notes by {lessonMeta?.notes_editor}</h3>
                      <div className="col-md-9 col-0 my-2">{/* Notes space block*/}</div>

                      <div className="col-md-8 col-8 my-2">{lessonMeta?.notes}</div>
                      <div className="col-md-8 col-8 my-2 text-muted text-small">
                        {' '}
                        On {moment(lessonMeta?.notes_timeStamp).format('llll')}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </Paper>

        <Paper className="text-large p-3 mt-2">
          <div className="app-wrapper">
            <div className="row align-items-center">
              <h3 className="col-md-3 col-5">Lesson</h3>
              <div className="col-md-9 col-7 my-2">{/*Lesson space block*/}</div>

              <div className="col-md-4 col-8 my-2"> Lesson Details </div>
              <div className="col-md-8 col-4 my-2">
                {lessonData.name ? `${lessonData.name}` : ''} (
                {lessonData.meta?.index ? `Lesson ${lessonData.meta?.index}` : ''})
              </div>

              <div className="col-md-4 col-8 my-2"> Scheduled on </div>
              <div className="col-md-8 col-4 my-2">
                <span className="mr-2">{moment(lessonData.scheduled_on).format('lll')}</span>
                {lessonInFuture && (
                  <span className="tag info-tag--outline">In {currentTime.to(lessonData.scheduled_on, true)}</span>
                )}
                {lessonInSession && <span className="tag success-tag">Currently in session</span>}
                {lessonEnded && <span className="tag danger-tag--outline">Ended</span>}
              </div>
            </div>
          </div>
        </Paper>

        <DialogEditLessonMeta isOpen={isOpen} toggle={this.toggle} lessonData={lessonData} onSave={this.saveLesson} />
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeTab)
