import React from 'react'
import _ from 'lodash'
import { TextField, Radio, RadioGroup, FormControl, FormControlLabel, Checkbox } from '@material-ui/core'
import Tooltip from '@mui/material/Tooltip'
import { InputText } from 'components/profile/inputText'

class LessonMeta extends React.Component {
  render() {
    const {
      handleChange,
      handleCheckboxChange,
      punctuality,
      cancelledClass,
      engagementAskQuestions,
      engagementDistracted,
      engagementMotivate,
      engagementParticipation,
      engagementTiredBored,
      lesson_topic,
      skillMasteryAnswerQuestion,
      skillMasteryConfused,
      skillMasteryShowUnderstanding,
      skillMasteryParticipative,
      skillMasteryUnderstandConcept,
      homeworkAssignment,
      homeworkSubmission,
      homeworkSubmissionPastPaper,
      homeworkSubmissionComment,
      revision_notes,
      commentStudentView,
      commentParentView,
      comment,
      recommendationLesson,
      recommendationConsult,
      recommendationOthers
    } = this.props
    return (
      <>
        <h2 className="font-weight-semibold border-bottom">Student Information</h2>
        <div className="d-flex mb-3">
          <h3 className="mr-3 mt-4 font-weight-semibold">Late to this class?</h3>
          <FormControl>
            <RadioGroup
              className="d-flex flex-row mt-1"
              aria-label="punctuality"
              name="punctuality"
              value={punctuality}
              onChange={handleChange}>
              <FormControlLabel labelPlacement="bottom" value="y" control={<Radio color="primary" />} label="Yes" />
              <FormControlLabel labelPlacement="bottom" value="n" control={<Radio color="primary" />} label="No" />
            </RadioGroup>
          </FormControl>
        </div>

        <div className="d-flex mb-3">
          <h3 className="mr-3 mt-4 font-weight-semibold">Cancelled this class?</h3>
          <FormControl>
            <RadioGroup
              className="d-flex flex-row mt-1"
              aria-label="cancelledClass"
              name="cancelledClass"
              value={cancelledClass}
              onChange={handleChange}>
              <FormControlLabel labelPlacement="bottom" value="y" control={<Radio color="primary" />} label="Yes" />
              <FormControlLabel labelPlacement="bottom" value="n" control={<Radio color="primary" />} label="No" />
            </RadioGroup>
          </FormControl>
        </div>

        <h3 className="font-weight-semibold">Engagement</h3>

        <FormControl className="w-100 mb-3">
          How often did the student participate in your lesson?
          <RadioGroup
            className="d-flex flex-row"
            aria-label="engagementParticipation"
            name="engagementParticipation"
            value={engagementParticipation}
            onChange={handleChange}>
            <FormControlLabel labelPlacement="bottom" value="1" label="1" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="2" label="2" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="3" label="3" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="4" label="4" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="5" label="5" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="6" label="6" control={<Radio color="primary" />} />
          </RadioGroup>
        </FormControl>
        <hr />
        <FormControl className="w-100 mb-3">
          How often did the student look distracted, as though he/she was clicking on something else?
          <RadioGroup
            className="d-flex flex-row"
            aria-label="engagementDistracted"
            name="engagementDistracted"
            value={engagementDistracted}
            onChange={handleChange}>
            <FormControlLabel labelPlacement="bottom" value="1" label="1" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="2" label="2" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="3" label="3" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="4" label="4" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="5" label="5" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="6" label="6" control={<Radio color="primary" />} />
          </RadioGroup>
        </FormControl>
        <hr />
        <FormControl className="w-100 mb-3">
          How often did the student look tired / bored?
          <RadioGroup
            className="d-flex flex-row"
            aria-label="engagementTiredBored"
            name="engagementTiredBored"
            value={engagementTiredBored}
            onChange={handleChange}>
            <FormControlLabel labelPlacement="bottom" value="1" label="1" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="2" label="2" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="3" label="3" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="4" label="4" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="5" label="5" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="6" label="6" control={<Radio color="primary" />} />
          </RadioGroup>
        </FormControl>
        <hr />
        <FormControl className="w-100 mb-3">
          How often did you have to motivate the student to pay attention?
          <RadioGroup
            className="d-flex flex-row"
            aria-label="engagementMotivate"
            name="engagementMotivate"
            value={engagementMotivate}
            onChange={handleChange}>
            <FormControlLabel labelPlacement="bottom" value="1" label="1" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="2" label="2" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="3" label="3" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="4" label="4" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="5" label="5" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="6" label="6" control={<Radio color="primary" />} />
          </RadioGroup>
        </FormControl>
        <hr />
        <FormControl className="w-100 mb-3">
          How often did the student ask questions voluntarily?
          <RadioGroup
            className="d-flex flex-row"
            aria-label="engagementAskQuestions"
            name="engagementAskQuestions"
            value={engagementAskQuestions}
            onChange={handleChange}>
            <FormControlLabel labelPlacement="bottom" value="1" label="1" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="2" label="2" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="3" label="3" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="4" label="4" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="5" label="5" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="6" label="6" control={<Radio color="primary" />} />
          </RadioGroup>
        </FormControl>

        <h3 className="font-weight-semibold mt-5">Skills taught in this lesson</h3>
        <FormControl className="w-100 mb-5">
          <InputText
            label={'Lesson topics / skill taught'}
            name={'lesson_topic'}
            defaultValue={lesson_topic}
            onBlur={handleChange.bind(this)}
            multiline={true}
          />
        </FormControl>

        <h3 className="font-weight-semibold">Mastery of skills</h3>
        <FormControl className="w-100 mb-3">
          How often was the student unable to answer your questions?
          <RadioGroup
            className="d-flex flex-row"
            aria-label="skillMasteryAnswerQuestion"
            name="skillMasteryAnswerQuestion"
            value={skillMasteryAnswerQuestion}
            onChange={handleChange}>
            <FormControlLabel labelPlacement="bottom" value="1" label="1" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="2" label="2" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="3" label="3" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="4" label="4" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="5" label="5" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="6" label="6" control={<Radio color="primary" />} />
          </RadioGroup>
        </FormControl>
        <hr />
        <FormControl className="w-100 mb-3">
          How often did the student look confused?
          <RadioGroup
            className="d-flex flex-row"
            aria-label="skillMasteryConfused"
            name="skillMasteryConfused"
            value={skillMasteryConfused}
            onChange={handleChange}>
            <FormControlLabel labelPlacement="bottom" value="1" label="1" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="2" label="2" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="3" label="3" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="4" label="4" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="5" label="5" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="6" label="6" control={<Radio color="primary" />} />
          </RadioGroup>
        </FormControl>
        <hr />
        <FormControl className="w-100 mb-3">
          Does the student show understanding of the concepts taught in the previous lesson?{' '}
          <RadioGroup
            className="d-flex flex-row"
            aria-label="skillMasteryShowUnderstanding"
            name="skillMasteryShowUnderstanding"
            value={skillMasteryShowUnderstanding}
            onChange={handleChange}>
            <FormControlLabel labelPlacement="bottom" value="1" label="1" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="2" label="2" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="3" label="3" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="4" label="4" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="5" label="5" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="6" label="6" control={<Radio color="primary" />} />
          </RadioGroup>
        </FormControl>
        <hr />
        <FormControl className="w-100 mb-3">
          Was the student more participative at the end of the lesson?{' '}
          <RadioGroup
            className="d-flex flex-row"
            aria-label="skillMasteryParticipative"
            name="skillMasteryParticipative"
            value={skillMasteryParticipative}
            onChange={handleChange}>
            <FormControlLabel labelPlacement="bottom" value="1" label="1" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="2" label="2" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="3" label="3" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="4" label="4" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="5" label="5" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="6" label="6" control={<Radio color="primary" />} />
          </RadioGroup>
        </FormControl>
        <hr />
        <FormControl className="w-100 mb-3">
          Was the student able to understand the concept taught in this lesson?{' '}
          <RadioGroup
            className="d-flex flex-row"
            aria-label="skillMasteryUnderstandConcept"
            name="skillMasteryUnderstandConcept"
            value={skillMasteryUnderstandConcept}
            onChange={handleChange}>
            <FormControlLabel labelPlacement="bottom" value="1" label="1" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="2" label="2" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="3" label="3" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="4" label="4" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="5" label="5" control={<Radio color="primary" />} />
            <FormControlLabel labelPlacement="bottom" value="6" label="6" control={<Radio color="primary" />} />
          </RadioGroup>
        </FormControl>

        <h3 className="font-weight-semibold mt-5">Homework Assignment</h3>
        <div className="d-flex mb-3">
          <h3 className="mr-3 mt-4">Any homework assignment?</h3>
          <FormControl>
            <RadioGroup
              className="d-flex flex-row mt-1"
              aria-label="homeworkAssignment"
              name="homeworkAssignment"
              value={homeworkAssignment}
              onChange={handleChange}>
              <FormControlLabel labelPlacement="bottom" value="y" control={<Radio color="primary" />} label="Yes" />
              <FormControlLabel labelPlacement="bottom" value="n" control={<Radio color="primary" />} label="No" />
            </RadioGroup>
          </FormControl>
        </div>
        <hr />
        {homeworkAssignment === 'y' && (
          <>
            <div className="d-flex mb-3">
              <h3 className="mr-3 mt-4">Submitted homework?</h3>
              <FormControl>
                <RadioGroup
                  className="d-flex flex-row mt-1"
                  aria-label="homeworkSubmission"
                  name="homeworkSubmission"
                  value={homeworkSubmission}
                  onChange={handleChange}>
                  <FormControlLabel
                    labelPlacement="bottom"
                    value="1"
                    label="Yes, on time"
                    control={<Radio color="primary" />}
                  />
                  <FormControlLabel
                    labelPlacement="bottom"
                    value="2"
                    label="Yes, but late"
                    control={<Radio color="primary" />}
                  />
                  <FormControlLabel labelPlacement="bottom" value="3" label="No" control={<Radio color="primary" />} />
                </RadioGroup>
              </FormControl>
            </div>
            <hr />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name={'homeworkSubmissionPastPaper'}
                  checked={homeworkSubmissionPastPaper}
                  onChange={handleCheckboxChange}
                />
              }
              label="Is this homework past paper?"
            />

            {homeworkSubmissionPastPaper && (
              <FormControl className={'mb-3 w-100'}>
                <InputText
                  label={'Result / comments for past paper'}
                  name={'homeworkSubmissionComment'}
                  defaultValue={homeworkSubmissionComment}
                  onBlur={handleChange.bind(this)}
                  multiline={true}
                />
              </FormControl>
            )}
          </>
        )}

        <h3 className="font-weight-semibold mt-5">Revision Notes</h3>
        <div className="d-flex mb-5">
          <h3 className="mr-3 mt-4">Submitted Revision Notes?</h3>
          <FormControl>
            <RadioGroup
              className="d-flex flex-row mt-1"
              aria-label="revision_notes"
              name="revision_notes"
              value={revision_notes}
              onChange={handleChange}>
              <FormControlLabel
                labelPlacement="bottom"
                value="1"
                label="Yes, on time"
                control={<Radio color="primary" />}
              />
              <FormControlLabel
                labelPlacement="bottom"
                value="2"
                label="Yes, but late"
                control={<Radio color="primary" />}
              />
              <FormControlLabel labelPlacement="bottom" value="3" label="No" control={<Radio color="primary" />} />
            </RadioGroup>
          </FormControl>
        </div>

        <h3 className="font-weight-semibold mt-5">Comments</h3>
        <div className="row ml-md-1">
          <Tooltip title={<h2>If checked, students can see the comment</h2>} followCursor>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name={'commentStudentView'}
                  checked={commentStudentView}
                  onChange={handleCheckboxChange}
                />
              }
              label="Student can view"
            />
          </Tooltip>
          <Tooltip title={<h2>If checked, parents can see the comment</h2>} followCursor>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name={'commentParentView'}
                  checked={commentParentView}
                  onChange={handleCheckboxChange}
                />
              }
              label="Parent can view"
            />
          </Tooltip>
        </div>

        <FormControl className={'mb-3 w-100'}>
          <InputText
            label={'Comment'}
            name={'comment'}
            defaultValue={comment}
            onBlur={handleChange.bind(this)}
            multiline={true}
          />
        </FormControl>

        {commentStudentView && commentParentView ? (
          <h3 className="text-warning"> Student and parent can see this comment!</h3>
        ) : commentStudentView ? (
          <h3 className="text-warning">Student can see this comment!</h3>
        ) : commentParentView ? (
          <h3 className="text-warning">Parent can see this comment!</h3>
        ) : (
          'Only you can see this comment.'
        )}

        <h3 className="font-weight-semibold mt-5">Recommendations</h3>
        <div className="row ml-md-1">
          <Tooltip title={<h2>If checked, students require more lessons</h2>} followCursor>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name={'recommendationLesson'}
                  checked={recommendationLesson}
                  onChange={handleCheckboxChange}
                />
              }
              label="More lessons"
            />
          </Tooltip>
          <Tooltip title={<h2>If checked, Kru A can consult</h2>} followCursor>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name={'recommendationConsult'}
                  checked={recommendationConsult}
                  onChange={handleCheckboxChange}
                />
              }
              label="Consult with Kru A on specific topics"
            />
          </Tooltip>

          <FormControl className={'mb-3 w-100'}>
            <InputText
              label={'Others/Remark'}
              name={'recommendationOthers'}
              defaultValue={recommendationOthers}
              onBlur={handleChange.bind(this)}
              multiline={true}
            />
          </FormControl>
        </div>
      </>
    )
  }
}

export default LessonMeta
