import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { isMobile } from 'react-device-detect'
import NewsAndEventsCard from 'components/public/NewsAndEventsCard'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

class News extends React.Component {
  render() {
    return (
      <div className="landing">
        <div>
          <Helmet>
            <title>News | EPA</title>
            <meta
              name="description"
              content="Enabling students to unleash their potential while embracing the future with confidence and wisdom."
            />
            <meta
              name="keywords"
              content="Attaining Excellence, Unleashing your potential,Bringing out the best in you, Your dream, our mission 
            Nurturing uniqueness, Igniting your passion"
            />
          </Helmet>
        </div>
        <section>
          <div className="container">
            <div className="align-items-center" style={{ marginBottom: '5em' }}>
              <div className="text-center">
                <div className="section__header_landing_title">
                  <div
                    className="title mt-5 text-center"
                    style={{
                      fontSize: '2.3rem',
                      fontWeight: '400',
                      fontFamily: "'Libre Baskerville', sans-serif",
                      color: '#000000'
                    }}>
                    News & Events
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-around mb-5">
              <div className="col-12 col-md-12">
                <div className="row">
                  <NewsAndEventsCard articleSlot={1} />
                  <NewsAndEventsCard articleSlot={2} />
                  <NewsAndEventsCard articleSlot={3} />
                </div>
              </div>
            </div>
          </div>

          <div className="label"></div>
        </section>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(News))
