import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { CONTACT } from 'routes/routes'
import { Paper } from '@material-ui/core'
import { isMobile } from 'react-device-detect'

class CTACard extends React.Component {
  render() {
    return (
      <>
        <section className="bg-white">
          <div className="container-fluid">
            <div className="col-12 mb-3">
              <Paper
                elevation={0}
                className="text-center"
                style={{
                  paddingTop: '18.39vh',
                  paddingBottom: '18.39vh',
                  backgroundColor: '#F7EFEA',
                  marginLeft: isMobile ? '' : '5vw',
                  marginRight: isMobile ? '' : '5vw',
                  borderRadius: '8px'
                }}>
                <div className="row align-items-center justify-content-center">
                  <div className="col-6 d-lg-block">
                    <img
                      src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/CTA.svg"
                      alt="ready"
                      className="mb-5 big-on-mobile"
                      // style={{ width: "50%"  }}
                    />
                  </div>
                  <div className="col-12 col-lg-6 section__header_landing_title margin-left--res">
                    <div
                      className="header mb-2 text-center text-lg-left mx-lg-0 mx-5 mb-4"
                      style={{
                        fontFamily: "'Libre Baskerville', sans-serif",
                        marginBottom: '30px'
                      }}>
                      Ready to get started?
                    </div>
                    <div
                      className="text-center text-lg-left mx-lg-0 mx-2 paragraph mb-5"
                      style={{ fontFamily: "'Alegreya Sans', sans-serif" }}>
                      We're here to support your educational needs.
                    </div>
                    <div className="text-lg-left text-center pt-2 mb-5 mx-lg-0 mx-auto">
                      <Link to={CONTACT}>
                        <span className="info-description-white--bold mx-auto orange-btn">Contact Us</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
          </div>
        </section>
        <div className="divider-section" style={{ marginTop: '3em', marginBottom: '3em' }}></div>
      </>
    )
  }
}

export default CTACard
