import React from 'react'
import PropTypes from 'prop-types'
import Recorder from 'recorder-js'
import WaveStream from 'react-wave-stream'
import { Button } from '@material-ui/core'
import Waveform from './Waveform'
import { Stop, FiberManualRecord, DeleteForever } from '@material-ui/icons'

class AudioRecorder extends React.Component {
  constructor() {
    super()

    this.state = {
      isRecording: false,
      analysedData: null,
      dataBlob: null,
      timerSeconds: 0,
      timerMinutes: 0
    }
  }

  componentDidMount() {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)()

    this.recorder = new Recorder(audioContext, {
      // An array of 255 Numbers
      // You can use this to visualize the audio stream
      // If you use react, check out react-wave-stream
      onAnalysed: data => this.setState({ analysedData: data })
    })
  }

  componentWillUnmount() {
    if (!!this.stream) {
      this.stream.getAudioTracks().forEach(function (track) {
        track.stop()
      })
    }
  }

  startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        this.stream = stream
        this.recorder.init(stream)

        this.recorder.start().then(() => {
          this.setState({ isRecording: true })
          this.startTimer()
        })

        this.props.togglePendingAccept(false)
      })
      .catch(err => console.log('Uh oh... unable to get stream...', err))
  }

  stopRecording = () => {
    this.stopTimer()
    this.recorder.stop().then(({ blob, buffer }) => {
      // buffer is an AudioBuffer

      // stop stream of audio
      if (!this.stream) return

      this.stream.getAudioTracks().forEach(function (track) {
        track.stop()
      })

      this.setState({ dataBlob: blob, isRecording: false })

      this.stream = null

      this.props.togglePendingAccept(true)
    })
  }

  downloadRecording = () => {
    Recorder.download(this.state.dataBlob, 'my-audio-file') // downloads a .wav file
  }

  deleteRecording = () => {
    this.setState({ dataBlob: null })
    this.props.togglePendingAccept(false)
  }

  addTime = () => {
    const { timerSeconds, timerMinutes } = this.state

    let seconds = timerSeconds + 1
    let minutes = timerMinutes

    if (seconds >= 60) {
      seconds = seconds % 60
      minutes++
    }

    this.startTimer()

    this.setState({ timerSeconds: seconds, timerMinutes: minutes })
  }

  startTimer = () => {
    this.timer = setTimeout(this.addTime, 1000)
  }

  stopTimer = () => {
    clearTimeout(this.timer)
    this.setState({ timerSeconds: 0, timerMinutes: 0 })
  }

  saveBlob = () => {
    this.props.onSave(this.state.dataBlob)
    this.setState({ dataBlob: null })
  }

  render() {
    const { isRecording, analysedData, dataBlob, timerSeconds, timerMinutes } = this.state
    const { onSave } = this.props

    return (
      <div className="recorder">
        {!!analysedData && isRecording && (
          <div className="position-relative" style={{ height: '80px', width: '100%' }}>
            <WaveStream data={analysedData.data} lineTo={analysedData.lineTo} />
          </div>
        )}

        {!dataBlob && (
          <React.Fragment>
            {isRecording ? (
              <React.Fragment>
                <Button color="secondary" onClick={this.stopRecording}>
                  <Stop className="mr-2" />
                  Stop Recording
                </Button>
                <span>
                  {timerMinutes}:{timerSeconds < 10 ? `0${timerSeconds}` : timerSeconds}
                </span>
              </React.Fragment>
            ) : (
              <div className="text-center">
                <Button color={'primary'} variant="outlined" size="large" onClick={this.startRecording}>
                  <FiberManualRecord className="text-danger mr-2" />
                  <span className="mr-2">Start Recording</span>
                </Button>
              </div>
            )}
          </React.Fragment>
        )}

        {dataBlob && (
          <React.Fragment>
            <h4>Review your recording</h4>
            <Waveform src={dataBlob} isBlob={true} />
            <div className="border px-3 py-2">
              <div className="row">
                <div className="col">
                  {!!onSave && (
                    <Button variant="outlined" color="primary" onClick={this.saveBlob}>
                      Accept Recording
                    </Button>
                  )}
                </div>
                <div className="col-auto d-flex align-items-center">
                  <DeleteForever className="text-danger pointer align" onClick={this.deleteRecording} />
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

AudioRecorder.propTypes = {
  onSave: PropTypes.func
}

export default AudioRecorder
