import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  Button,
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon, ArrowBackIos as BackIcon } from '@material-ui/icons'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { GET_SUBMISSION_BY_HOMEWORK, UPDATE_SUBMISSION } from 'constants/actionTypes'
import _ from 'lodash'
import moment from 'moment'
import agent from 'agent'
import { MY_CLASS_DETAILS } from 'routes/routes'
import { LESSON_PAGE } from 'routes/tutorRoutes'
import FileWidget from 'components/misc/widget-file'
import SubmissionHistoryDialog from './DialogSubmissionHistory'
import AskResubmissionDialog from './DialogAskResubmission'
import MarkSubmissionDialog from './DialogMarkSubmission'
import DeleteSubmissionDialog from './DialogDeleteSubmission'

const mapStateToProps = state => ({
  loadingSubmissionList: state.submission.loadingSubmissionList,
  submissionList: state.submission.submissionList,
  updatingSubmission: state.submission.updatingSubmission
})

const mapDispatchToProps = dispatch => ({
  getSubmissionByHomework: (filter, homeworkId, statusFilter) =>
    dispatch({ type: GET_SUBMISSION_BY_HOMEWORK, payload: agent.Submission.getList(filter), homeworkId, statusFilter }),
  updateSubmission: (data, submissionId) =>
    dispatch({ type: UPDATE_SUBMISSION, payload: agent.Submission.update('classic', submissionId, data), submissionId })
})

class SubmissionList extends React.Component {
  state = {
    breadcrumbData: null,
    tableData: [],
    totalCount: 0,
    orderBy: '',
    order: 'asc',
    selectedSubmission: null,
    isDeleteSubmissionDialogOpen: false
  }

  componentDidMount() {
    let { submissionList } = this.props
    let homeworkId = this.props.match.params.homeworkId

    if (submissionList.homeworkId !== homeworkId) {
      this.getSubmission(true, submissionList.statusFilter)
    } else {
      this.setState({
        tableData: submissionList.data,
        totalCount: submissionList.count,
        visibleStatus: submissionList.statusFilter
      })
      this.populateBreadcrumbData()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadingSubmissionList !== this.props.loadingSubmissionList) {
      this.setState({
        tableData: this.props.submissionList.data,
        totalCount: this.props.submissionList.count
      })
      this.populateBreadcrumbData()
    }

    if (prevProps.updatingSubmission && !this.props.updatingSubmission) {
      this.setState({
        tableData: this.props.submissionList.data,
        totalCount: this.props.submissionList.count
      })
    }
  }

  getSubmission = (reload, visibleStatus) => {
    let { submissionList } = this.props
    let homeworkId = this.props.match.params.homeworkId
    let perPage = 30
    let offset = submissionList.data.length

    if (reload || visibleStatus !== submissionList.statusFilter) {
      offset = 0
    }

    this.props.getSubmissionByHomework(
      {
        homework: homeworkId,
        status: { $in: visibleStatus },
        max: perPage,
        offset
      },
      homeworkId,
      visibleStatus
    )
  }

  populateBreadcrumbData = () => {
    let { submissionList } = this.props
    if (submissionList.count > 0) {
      let homeworkData = submissionList.data[0].homework
      let breadcrumbData = {
        class: {
          name: homeworkData.lesson.class.name,
          id: homeworkData.lesson.class._id
        },
        lesson: {
          name: homeworkData.lesson.name,
          id: homeworkData.lesson._id
        },
        homework: {
          name: homeworkData.name
        }
      }
      this.setState({ breadcrumbData })
    }
  }

  askForSubmission = submissionId => () => {
    this.props.updateSubmission({ status: 'RESUBMISSION_REQUIRE' }, submissionId)
  }

  handleVisibleStatusChange = (event, value) => {
    let { loadingSubmissionList, submissionList } = this.props

    if (loadingSubmissionList) return

    let visibleStatus = []
    let existIndex = _.findIndex(submissionList.statusFilter, o => {
      return o === value
    })
    for (let i = 0; i < submissionList.statusFilter.length; i++) {
      if (i !== existIndex) {
        visibleStatus.push(submissionList.statusFilter[i])
      }
    }
    if (existIndex === -1) {
      visibleStatus.push(value)
    }

    this.getSubmission(false, visibleStatus)
  }

  handleSort = orderBy => () => {
    let { order, tableData } = this.state
    order = order === 'asc' ? 'desc' : 'asc'
    if (orderBy === 'student-name') {
      tableData = _.orderBy(tableData, ['created_by.name'], [order])
    } else {
      tableData = _.orderBy(tableData, [orderBy], [order])
    }
    this.setState({ orderBy, order, tableData })
  }

  loadMore = () => {
    this.getSubmission(false, this.props.submissionList.statusFilter)
  }

  formatSubmissionStatus = status => {
    let formatted = status
    if (status === 'RESUBMISSION_REQUIRE') {
      formatted = 'RESUBMISSION<br/>REQUIRED'
    } else if (status === 'MARKING_IN_PROGRESS') {
      formatted = 'MARKING'
    }

    return formatted
  }

  goToPage = location => () => {
    this.props.history.push(location)
  }

  toggleDeleteSubmissionDialog = selectedSubmission => () => {
    this.setState({
      isDeleteSubmissionDialogOpen: !_.isEmpty(selectedSubmission),
      selectedSubmission
    })
  }

  render() {
    const { loadingSubmissionList, submissionList } = this.props
    const { breadcrumbData, tableData, totalCount, orderBy, order, selectedSubmission, isDeleteSubmissionDialogOpen } =
      this.state
    const visibleStatus = submissionList.statusFilter

    return (
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <Paper elevation={2} className="app-wrapper pt-3 pb-0 sticky-top" style={{ zIndex: 1201 }}>
          <div className="row pb-3">
            {breadcrumbData && (
              <div className="col-12 text-muted text-small">
                <span
                  className="mr-2 clickable"
                  onClick={this.goToPage(MY_CLASS_DETAILS.replace(':classId', breadcrumbData.class.id))}>
                  {breadcrumbData.class.name}
                </span>
                /
                <span
                  className="mx-2 clickable"
                  onClick={this.goToPage(
                    LESSON_PAGE.replace(':classId', breadcrumbData.class.id).replace(
                      ':lessonId',
                      breadcrumbData.lesson.id
                    )
                  )}>
                  {breadcrumbData.lesson.name}
                </span>
                /<span className="mx-2 font-weight-semibold">{breadcrumbData.homework.name}</span>
              </div>
            )}
            <div className="col d-flex flex-row align-items-center mt-1">
              <BackIcon
                className="clickable mr-2"
                onClick={() => {
                  this.props.history.goBack()
                }}
              />
              <h1 className="title--rubik mb-0">Homework Submissions</h1>
            </div>
          </div>
        </Paper>
        <div className="app-wrapper container-fluid">
          <Paper className="p-2 mb-3">
            <div className="d-flex flex-row align-items-center">
              <span className="mr-2">Show: </span>
              <ToggleButtonGroup value={visibleStatus} exclusive onChange={this.handleVisibleStatusChange}>
                <ToggleButton value="SUBMITTED" disableRipple disableFocusRipple>
                  New Submission
                </ToggleButton>
                <ToggleButton value="RESUBMISSION_REQUIRE" disableRipple disableFocusRipple>
                  Require Resubmit
                </ToggleButton>
                <ToggleButton value="RESUBMITTED" disableRipple disableFocusRipple>
                  Resubmission
                </ToggleButton>
                <ToggleButton value="MARKING_IN_PROGRESS" disableRipple disableFocusRipple>
                  Marking In Progress
                </ToggleButton>
                <ToggleButton value="MARKED" disableRipple disableFocusRipple>
                  Marking Done
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </Paper>

          <div className="text-right text-muted text-small my-2">
            * Sort by detail will sort submission list by student name
          </div>

          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel active={orderBy === 'status'} direction={order} onClick={this.handleSort('status')}>
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sortDirection={order}>
                    <TableSortLabel
                      active={orderBy === 'student-name'}
                      direction={order}
                      onClick={this.handleSort('student-name')}>
                      Detail
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Files</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map(submission => (
                  <TableRow key={submission._id}>
                    <TableCell>
                      <span
                        className="text-small"
                        dangerouslySetInnerHTML={{ __html: this.formatSubmissionStatus(submission.status) }}
                      />
                    </TableCell>
                    <TableCell>
                      <span className="text-large font-weight-semibold">{submission.created_by.name}</span>
                      <br />
                      <span className="text-muted">Submitted on: </span>
                      <span
                        className={
                          moment(submission.created) > moment(submission.homework.due_on) ? 'text-danger' : 'text-muted'
                        }>
                        {moment(submission.created).format('lll')}
                      </span>
                      <br />
                      {submission.history.length > 0 && submission.status === 'RESUBMISSION' && (
                        <React.Fragment>
                          <span>ReSubmitted on: {moment(submission.updated).format('lll')}</span>
                          <br />
                        </React.Fragment>
                      )}
                      <span className="text-muted">History: </span>
                      {submission.history.length > 0 ? (
                        <SubmissionHistoryDialog history={submission.history} />
                      ) : (
                        <span>N/A</span>
                      )}
                    </TableCell>

                    <TableCell>
                      {submission.marked_attachment.length > 0 && (
                        <ExpansionPanel defaultExpanded={false} style={{ width: '280px' }}>
                          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>Marked Files</ExpansionPanelSummary>
                          <ExpansionPanelDetails style={{ padding: '8px 24px 8px 24px' }}>
                            <div>
                              {submission.marked_attachment.map((file, index) => (
                                <FileWidget fileUrl={file.url} fileName={`Marked File ${index + 1}`} key={index} />
                              ))}
                            </div>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      )}

                      {submission.pdf_attachment.length > 0 && (
                        <ExpansionPanel
                          defaultExpanded={
                            submission.marked_attachment.length === 0 && submission.status !== 'RESUBMISSION_REQUIRE'
                          }
                          style={{ width: '280px' }}>
                          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            Submitted Files (PDF)
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails style={{ padding: '8px 24px 8px 24px' }}>
                            <div>
                              {submission.pdf_attachment.map((file, index) => (
                                <FileWidget
                                  fileUrl={file.url}
                                  fileName={`Submitted File (PDF) ${index + 1}`}
                                  key={index}
                                />
                              ))}
                            </div>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      )}

                      <ExpansionPanel
                        defaultExpanded={
                          submission.pdf_attachment.length === 0 &&
                          submission.marked_attachment.length === 0 &&
                          submission.status !== 'RESUBMISSION_REQUIRE'
                        }
                        style={{ width: '280px' }}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                          {submission.pdf_attachment.length > 0 ? 'Submitted Files (Original)' : 'Submitted Files'}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{ padding: '8px 24px 8px 24px' }}>
                          <div>
                            {submission.attachment.map((file, index) => (
                              <FileWidget fileUrl={file.url} fileName={`Submitted File ${index + 1}`} key={index} />
                            ))}
                          </div>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </TableCell>
                    <TableCell>
                      {['SUBMITTED', 'RESUBMITTED'].includes(submission.status) && (
                        <AskResubmissionDialog submissionData={submission} buttonFullWidth={true} />
                      )}
                      {['MARKED', 'SUBMITTED', 'RESUBMITTED', 'MARKING_IN_PROGRESS'].includes(submission.status) && (
                        <MarkSubmissionDialog
                          submissionData={submission}
                          buttonFullWidth={true}
                          buttonText={submission.status === 'MARKED' ? 'Edit Marking' : 'Mark'}
                        />
                      )}
                      <Button
                        className="text-danger mt-1"
                        variant="text"
                        fullWidth
                        disableFocusRipple
                        onClick={this.toggleDeleteSubmissionDialog(submission)}>
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {tableData.length < totalCount && (
              <div className="d-flex justify-content-end p-3">
                <Button variant="outlined" color="primary" onClick={this.loadMore}>
                  Load more...
                </Button>
              </div>
            )}
            {loadingSubmissionList && <CircularProgress size={20} className="m-4" />}
          </Paper>
        </div>

        <DeleteSubmissionDialog
          isOpen={isDeleteSubmissionDialogOpen}
          toggleDialog={this.toggleDeleteSubmissionDialog}
          submissionData={selectedSubmission}
        />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubmissionList))
