import React from 'react'
import { connect } from 'react-redux'
import { Dialog, DialogContent, Slide } from '@material-ui/core'
import { CloudDownload, Event } from '@material-ui/icons'
import _ from 'lodash'
import moment from 'moment'
import Proptypes from 'prop-types'

import { subjectColors } from 'constants/generalTypes'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const mapStateToProps = state => ({
  lessonMaterial: state.material.lessonMaterial
})

class LessonDetailDialog extends React.Component {
  state = {
    materialTypes: [],
    materialList: []
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { lessonData, lessonMaterial } = this.props
      const materialList = lessonMaterial[lessonData?._id] ?? []
      this.setState({
        materialTypes: _.uniq(_.map(materialList, 'type')),
        materialList
      })
    }
  }

  closeDialog = () => {
    this.props.close()
    this.setState({
      materialTypes: [],
      materialList: []
    })
  }

  render() {
    const { isOpen, lessonData } = this.props
    const { materialTypes, materialList } = this.state
    const subjectColor = subjectColors[lessonData?.class?.subject] ?? subjectColors.other

    return (
      <Dialog open={isOpen} onClose={this.props.close} TransitionComponent={Transition} maxWidth="md">
        <DialogContent className="p-0">
          {lessonData ? (
            <div className="row no-gutters mb-4">
              <div className="col-12 px-3 py-2" style={{ backgroundColor: subjectColor }}>
                <span className="title--rubik font-weight-light text-white">{lessonData.class.name}</span>
              </div>
              <div className="col-12 px-3 mb-4">
                <h2 className="title--rubik my-2">{lessonData.name}</h2>
                <div className="d-flex align-items-center">
                  <Event className="text-muted mr-2" />
                  <span>
                    {moment(lessonData.scheduled_on).format('lll')}
                    &nbsp;-&nbsp;
                    {moment(lessonData.ended_on).format('h:mm A')}
                  </span>
                </div>
              </div>

              <div className="col-12 px-3">
                <h3 className="title--rubik">Lesson Resources</h3>
                {materialTypes.length === 0 && <span>No resource uploaded yet</span>}
              </div>

              {materialTypes.map((type, index) => {
                return (
                  <div className="col-12 px-3" key={index}>
                    <div className="label text-muted text-small text-capitalize mb-2">{type}</div>
                    {materialList.map((material, index) => {
                      if (material.type !== type) {
                        return null
                      }

                      return (
                        <div className="card px-3 py-2 mb-2" key={index}>
                          <a href={material.material_meta.location} download>
                            <div className="row align-items-center ">
                              <div className="col">
                                <span className="link">{material.name}</span>
                              </div>
                              <div className="col-auto">
                                <CloudDownload />
                              </div>
                            </div>
                          </a>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          ) : (
            <div className="text-center p-5">Something wrong. Kindly refresh or contact us.</div>
          )}
        </DialogContent>
      </Dialog>
    )
  }
}

LessonDetailDialog.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired
}

export default connect(mapStateToProps)(LessonDetailDialog)
