import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import ReactPixel from 'react-facebook-pixel'
import { Button, CircularProgress, Paper } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import _ from 'lodash'
import moment from 'moment'
import qs from 'qs'
import agent from 'agent'
import { GET_ONLINE_CLASS_LIST, SET_ONLINE_CLASS_FILTER } from 'constants/actionTypes'
import { ONLINE_CLASS_DETAILS, CLASS_DETAILS, TRIAL } from 'routes/routes'
import CatalogueItemCard from './item-card'
import TrialBanner from 'components/misc/trial-banner'

const mapStateToProps = state => ({
  ...state.onlineClass,
  token: state.common.token,
  purchasedClasses: state.common.purchasedClasses
})

const mapDispatchToProps = dispatch => ({
  getClassList: () =>
    dispatch({
      type: GET_ONLINE_CLASS_LIST,
      payload: agent.OnlineClass.getList()
    }),
  setOnlineClassFilter: filters => dispatch({ type: SET_ONLINE_CLASS_FILTER, payload: filters })
})

const AVAILABLE_LEVEL = [
  //'secondary-3',
  'secondary-2',
  'secondary-1',
  'primary-6',
  'primary-5',
  'primary-4',
  'primary-3'
  //'primary-2'
  //'primary-1'
]

class OnlineClass extends React.Component {
  constructor(props) {
    super()

    let parsed = qs.parse(window.location.search.substr(1))
    const { level, subject, status, type } = props
    let filterSubject = parsed.subject ? parsed.subject : subject
    let filterStatus = parsed.status ? parsed.status : status
    let filterLevel = parsed.level ? parsed.level : level
    let filterType = parsed.t ? parsed.t : type

    this.state = {
      filteredOnlineClassList: [],
      filterSubject: filterSubject ? filterSubject : 'All',
      filterStatus: filterStatus ? filterStatus : 'Ongoing',
      filterLevel: filterLevel ? filterLevel : 'All',
      filterType: filterType ? filterType : '2022',
      levelNSubject: '',
      optionalPreferences: '',
      formError: {},
      availableSubjectFilter: null
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    let { onlineClassList } = this.props

    if (!onlineClassList || onlineClassList.length === 0) {
      this.props.getClassList()
    } else {
      this.getAvailableSubjectForLevel()
      this.filterOnlineClass()
    }
    ReactPixel.trackCustom('ViewCatalogue')
  }

  componentDidUpdate(prevProps, prevState) {
    // NOTE: to handle change of level prop in class catalogue page
    if (prevProps.level !== this.props.level) {
      window.scrollTo(0, 0)
      this.setState({ filterLevel: this.props.level })
    }

    // NOTE: to handle change of type prop in class catalogue page
    if (prevProps.type !== this.props.type) {
      window.scrollTo(0, 0)
      this.setState({ filterType: this.props.type })
    }

    if (
      prevProps.onlineClassList !== this.props.onlineClassList ||
      prevState.filterStatus !== this.state.filterStatus
    ) {
      this.getAvailableSubjectForLevel()
      this.filterOnlineClass()
    }

    if (prevState.filterType !== this.state.filterType) {
      this.getAvailableSubjectForLevel()
    }

    if (
      prevState.filterSubject !== this.state.filterSubject ||
      prevState.filterStatus !== this.state.filterStatus ||
      prevState.filterLevel !== this.state.filterLevel ||
      prevState.filterType !== this.state.filterType
    ) {
      let { filterSubject, filterLevel, availableSubjectFilter } = this.state
      if (
        availableSubjectFilter &&
        !availableSubjectFilter[filterLevel].includes(filterSubject) &&
        filterSubject !== 'All'
      ) {
        this.setState({ filterSubject: 'All' })
      }
      this.filterOnlineClass()
    }
  }

  viewOnlineClass = onlineClass => {
    if (this.props.token) {
      this.props.history.push(ONLINE_CLASS_DETAILS.replace(':classId', onlineClass._id))
    } else {
      this.props.history.push(CLASS_DETAILS.replace(':classId', onlineClass._id))
    }
  }

  toggleFilterOption = (type, value) => {
    this.setState({
      [type]: value
    })
  }

  getClassesByStatus = classList => {
    let { filterStatus } = this.state

    let filteredOnlineClassList = []

    if (!_.isArray(classList)) {
      return filteredOnlineClassList
    }

    if (filterStatus === 'All') {
      filteredOnlineClassList = classList
    } else {
      for (let i = 0; i < classList.length; i++) {
        let { lesson } = classList[i]
        if (lesson.length > 0) {
          let sortedLessons = []
          sortedLessons = _.sortBy(lesson, function (l) {
            return new Date(l.scheduled_on)
          })
          let lastLessonDate = sortedLessons[sortedLessons.length - 1].scheduled_on
          let currentDate = new Date()
          if (filterStatus === 'Past') {
            if (moment(currentDate) > moment(lastLessonDate)) {
              filteredOnlineClassList.push(classList[i])
            }
          } else {
            if (moment(currentDate) < moment(lastLessonDate)) {
              filteredOnlineClassList.push(classList[i])
            }
          }
        }
      }
    }

    return filteredOnlineClassList
  }

  getClassesByType = classList => {
    let { filterType } = this.state
    let filteredOnlineClassList = []
    switch (filterType) {
      case '2021':
      case '2022':
        const selectedYear = moment(`${filterType}-01-15T00:00:00Z`)
        filteredOnlineClassList = _.filter(classList, o => {
          return moment(o.start_date).isSame(selectedYear, 'year')
        })
        break

      case '2021-eoy':
        filteredOnlineClassList = _.filter(classList, o => {
          return (
            moment(o.start_date) > moment('2021-10-01T00:00:00Z') &&
            moment(o.start_date) < moment('2021-12-25T00:00:00Z')
          )
        })
        break

      case 'featured':
        filteredOnlineClassList = _.filter(classList, o => {
          return o.is_featured
        })
        break

      default:
        break
    }

    /*if (filterType === 'all') {
      filteredOnlineClassList = classList
    } else if (filterType === 'featured') {
      filteredOnlineClassList = _.filter(classList, o => {
        return o.is_featured
      })
    } else {
      let selectedClassType = filterType === 'workshop' ? 'Workshop' : 'Regular Classes'
      filteredOnlineClassList = _.filter(classList, o => {
        return o.class_type === selectedClassType
      })
    }*/

    return filteredOnlineClassList
  }

  // TODO: refactor
  getAvailableSubjectForLevel = () => {
    let { onlineClassList } = this.props

    let filterByStatusList = this.getClassesByStatus(onlineClassList)
    let filteredOnlineClassList = this.getClassesByType(filterByStatusList)

    let all = [],
      //pri1 = [],
      //pri2 = [],
      pri3 = [],
      pri4 = [],
      pri5 = [],
      pri6 = [],
      sec1 = [],
      sec2 = []
    //sec3 = [],
    //sec4 = []

    for (let i = 0; i < filteredOnlineClassList.length; i++) {
      if (filteredOnlineClassList[i].level) {
        all.push(filteredOnlineClassList[i].subject)
        //if (_.includes(filteredOnlineClassList[i].level, 'primary-1')) {
        //  pri1.push(filteredOnlineClassList[i].subject)
        //}
        //if (_.includes(filteredOnlineClassList[i].level, 'primary-2')) {
        //  pri2.push(filteredOnlineClassList[i].subject)
        //}
        if (_.includes(filteredOnlineClassList[i].level, 'primary-3')) {
          pri3.push(filteredOnlineClassList[i].subject)
        }
        if (_.includes(filteredOnlineClassList[i].level, 'primary-4')) {
          pri4.push(filteredOnlineClassList[i].subject)
        }
        if (_.includes(filteredOnlineClassList[i].level, 'primary-5')) {
          pri5.push(filteredOnlineClassList[i].subject)
        }
        if (_.includes(filteredOnlineClassList[i].level, 'primary-6')) {
          pri6.push(filteredOnlineClassList[i].subject)
        }
        if (_.includes(filteredOnlineClassList[i].level, 'secondary-1')) {
          sec1.push(filteredOnlineClassList[i].subject)
        }
        if (_.includes(filteredOnlineClassList[i].level, 'secondary-2')) {
          sec2.push(filteredOnlineClassList[i].subject)
        }
        //if (_.includes(filteredOnlineClassList[i].level, 'secondary-3')) {
        //  sec3.push(filteredOnlineClassList[i].subject)
        //}
        //if (_.includes(filteredOnlineClassList[i].level, 'secondary-4')) {
        //  sec4.push(filteredOnlineClassList[i].subject)
        //}
      }
    }
    this.setState({
      availableSubjectFilter: {
        All: _.sortBy(_.uniq(all)),
        //'primary-1': _.sortBy(_.uniq(pri1)),
        //'primary-2': _.sortBy(_.uniq(pri2)),
        'primary-3': _.sortBy(_.uniq(pri3)),
        'primary-4': _.sortBy(_.uniq(pri4)),
        'primary-5': _.sortBy(_.uniq(pri5)),
        'primary-6': _.sortBy(_.uniq(pri6)),
        'secondary-1': _.sortBy(_.uniq(sec1)),
        'secondary-2': _.sortBy(_.uniq(sec2))
        //'secondary-3': _.sortBy(_.uniq(sec3)),
        //'secondary-4': _.sortBy(_.uniq(sec4))
      }
    })
  }

  filterOnlineClass = () => {
    let { onlineClassList } = this.props

    if (process.env.REACT_APP_REGION === 'SG') {
      let { filterStatus, filterSubject, filterLevel, filterType } = this.state
      let filterByStatusList = this.getClassesByStatus(onlineClassList)
      let filteredOnlineClassList = this.getClassesByType(filterByStatusList)

      if (filteredOnlineClassList.length > 0) {
        if (filterSubject !== 'All') {
          filteredOnlineClassList = _.filter(filteredOnlineClassList, function (onlineClass) {
            return onlineClass.subject === filterSubject
          })
        }

        if (filterLevel !== 'All') {
          filteredOnlineClassList = _.filter(filteredOnlineClassList, function (onlineClass) {
            if (onlineClass.level) {
              return _.includes(onlineClass.level, filterLevel)
            } else {
              return null
            }
          })
        }
      }

      this.props.setOnlineClassFilter({
        subject: filterSubject,
        level: filterLevel,
        status: filterStatus,
        type: filterType
      })
      filteredOnlineClassList = _.orderBy(
        filteredOnlineClassList,
        ['subject', 'class_type', 'level', 'max_cap'],
        ['asc', 'asc', 'desc', 'asc']
      )
      this.setState({ filteredOnlineClassList })
    } else {
      this.setState({ filteredOnlineClassList: onlineClassList })
    }
  }

  render() {
    let { filteredOnlineClassList, filterSubject, filterLevel, availableSubjectFilter, filterType } = this.state
    let { loadingOnlineClassList } = this.props

    if (loadingOnlineClassList) {
      return (
        <div className="text-center py-5">
          <CircularProgress size={50} />
        </div>
      )
    }

    return (
      <>
        <Helmet>
          <title>Class Catalogue | Tenopy</title>
          <meta
            name="description"
            content="Tenopy offers classes and workshops that are affordable, they range from English, Mathematics and Science subjects. Classes are available for primary and secondary school students with different learning abilities."
          />
          <meta
            name="keywords"
            content="Online tuition, Online tuition Singapore, affordable tuition, affordable online tuition Singapore, English tuition, Math tuition, Mathematics tuition, science tuition, primary student, secondary student, high learning ability, workshops, Online english tuition, online math tuition, online science tuition, primary school, secondary school, online primary school tuition, online secondary school tuition, online english tutor, online math tutor, online science tutor, home tuition, online tutoring, online learning, online tutoring, tuition assignment, tuition assignments singapore, online tuition portal singapore"
          />
        </Helmet>

        <div className="mt-3">
          <div className="container">
            <TrialBanner />
            <Paper elevation={4} className="row bg-white no-gutters mb-4 p-3">
              <div className="col-12 d-flex flex-wrap flex-row">
                <div className="filter__label">Type&nbsp;&nbsp;&nbsp;</div>
                {/*<div
                  className={`filter__option filter__option--${filterType === 'all' ? 'active' : 'inactive'} mb-2`}
                  onClick={() => this.toggleFilterOption('filterType', 'all')}>
                  All
                </div>*/}
                <div
                  className={`filter__option filter__option--${filterType === '2022' ? 'active' : 'inactive'} mb-2`}
                  onClick={() => this.toggleFilterOption('filterType', '2022')}>
                  2022 Classes
                </div>
                {/*<div
                  className={`filter__option filter__option--${filterType === '2021-eoy' ? 'active' : 'inactive'} mb-2`}
                  onClick={() => this.toggleFilterOption('filterType', '2021-eoy')}>
                  2021 Holiday Workshops
                </div>*/}
                {/*<div
                  className={`filter__option filter__option--${filterType === 'workshop' ? 'active' : 'inactive'} mb-2`}
                  onClick={() => this.toggleFilterOption('filterType', 'workshop')}>
                  Workshops
                </div>*/}
                <div
                  className={`filter__option filter__option--${filterType === 'featured' ? 'active' : 'inactive'} mb-2`}
                  onClick={() => this.toggleFilterOption('filterType', 'featured')}>
                  Featured Learning
                </div>
              </div>

              <div className="col-12 d-flex flex-wrap flex-row">
                <div className="filter__label">Level&nbsp;&nbsp;&nbsp;</div>
                <div
                  className={`filter__option filter__option--${filterLevel === 'All' ? 'active' : 'inactive'} mb-2`}
                  onClick={() => this.toggleFilterOption('filterLevel', 'All')}>
                  All
                </div>
                {AVAILABLE_LEVEL.map((level, index) => {
                  let levelName = level.replace('secondary-', 'Sec ').replace('primary-', 'Pri ')
                  if (level === 'secondary-4') {
                    levelName = 'O Level'
                  }
                  if (level === 'primary-2') {
                    levelName = 'Lower Primary'
                  }

                  return (
                    <div
                      key={index}
                      className={`filter__option filter__option--${filterLevel === level ? 'active' : 'inactive'} mb-2`}
                      onClick={() => this.toggleFilterOption('filterLevel', level)}>
                      {levelName}
                    </div>
                  )
                })}
              </div>

              {availableSubjectFilter && availableSubjectFilter[filterLevel] && (
                <div className="col-12 d-flex flex-wrap flex-row">
                  <div className="filter__label">Subject</div>
                  <div
                    className={`filter__option filter__option--${filterSubject === 'All' ? 'active' : 'inactive'}`}
                    onClick={() => this.toggleFilterOption('filterSubject', 'All')}>
                    All
                  </div>
                  {availableSubjectFilter[filterLevel].map((subject, index) => (
                    <div
                      key={index}
                      className={`filter__option filter__option--${filterSubject === subject ? 'active' : 'inactive'}`}
                      onClick={() => this.toggleFilterOption('filterSubject', subject)}>
                      {subject}
                    </div>
                  ))}
                </div>
              )}
            </Paper>
          </div>
        </div>

        <div className="container">
          <div className="row">
            {filteredOnlineClassList.length > 0 ? (
              filteredOnlineClassList.map((classData, index) => {
                return (
                  <CatalogueItemCard
                    key={index}
                    classData={classData}
                    viewOnlineClass={() => this.viewOnlineClass(classData)}
                  />
                )
              })
            ) : (
              <Alert severity="warning">No class found</Alert>
            )}
          </div>
        </div>

        <div className="bg-white">
          <section className="py-5 mt-3">
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <span style={{ fontSize: '20px', color: '#3C4858' }}>New to online tutoring?</span>
                  <h1 className="text-primary font-weight-normal mb-0" style={{ lineHeight: '1.3' }}>
                    Sign up for free trial lessons and experience the difference!
                  </h1>
                </div>
                <div className="col-auto my-3">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.props.history.push(TRIAL)
                    }}>
                    View Trial
                  </Button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnlineClass))
