import * as Type from 'constants/actionTypes'
import _ from 'lodash'

const initialState = {
  loadingEventList: false,
  eventList: { data: [], count: 0, filter: { offset: 0 }, levelFilter: 'all' },
  loadingEventData: false,
  eventData: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.GET_EVENT_LIST: {
      let previousLvlFilter = state.eventList.levelFilter
      let currentLvlFilter = action.levelFilter
      let updatedEventList = state.eventList
      if (previousLvlFilter !== currentLvlFilter) {
        updatedEventList.data = []
        updatedEventList.count = 0
        updatedEventList.filter = action.filter
        updatedEventList.levelFilter = action.levelFilter
      }

      return {
        ...state,
        loadingEventList: true,
        eventList: updatedEventList
      }
    }

    case Type.GET_EVENT_LIST_SUCCESS: {
      let updatedEventList = state.eventList
      if (action.filter.offset === 0) {
        updatedEventList.data = action.payload.data
      } else {
        updatedEventList.data = _.concat(updatedEventList.data, action.payload.data)
      }
      updatedEventList.count = action.payload.count

      return {
        ...state,
        loadingEventList: false,
        eventList: updatedEventList
      }
    }

    case Type.GET_EVENT_BY_ID:
      return {
        ...state,
        loadingEventData: true
      }

    case Type.GET_EVENT_BY_ID_SUCCESS: {
      // NOTE: to handle get tls page using info event list endpoint for public access without token
      let eventData = _.isArray(action.payload.data) ? action.payload.data[0] : action.payload.data
      return {
        ...state,
        loadingEventData: false,
        eventData
      }
    }

    case Type.GET_EVENT_BY_ID_FAILED:
      return {
        ...state,
        loadingEventData: false
      }

    case Type.LOGOUT:
      return initialState

    default:
      return state
  }
}
