import React from 'react'
import { isMobile } from 'react-device-detect'
class StudentDestinationCard extends React.Component {
  render() {
    const { type } = this.props
    return type === 1 ? (
      <div className="row align-items-center">
        <div className="col-6 pt-5 col-lg-3 text-center p-lg-1">
          <img
            width={isMobile ? '90%' : '45%'}
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/penn.png"
            alt="penn"
            className="my-2"
          />
        </div>
        <div className="col-6 pt-5 col-lg-2 text-center p-lg-1">
          <img
            width={isMobile ? '90%' : '50%'}
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/cambridge.png"
            alt="cambridge"
            className="my-2"
          />
        </div>
        <div className="col-6 pt-5 col-lg-2 text-center pl-lg-5 pb-lg-5">
          <img
            width={isMobile ? '90%' : '110%'}
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/ucberkeley.png"
            alt="ucberkeley"
            className="my-2"
          />
        </div>
        <div className="col-6 pt-5 col-lg-2 text-center pl-lg-5 pb-lg-5">
          <img
            width={isMobile ? '60%' : '60%'}
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/brown.png"
            alt="brown"
            className="my-2"
          />
        </div>
        <div className="col-6 pt-5 col-lg-3 text-center p-lg-1">
          <img
            width={isMobile ? '90%' : '60%'}
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/mit.png"
            alt="mit"
            className="my-2"
          />
        </div>
        <div className="col-6 pt-5 col-lg-3 text-center p-lg-1" style={{ marginTop: isMobile ? '' : '-25px' }}>
          <img
            width={isMobile ? '60%' : '35%'}
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/ucyale.png"
            alt="yale"
            className="my-2"
          />
        </div>
        <div className="col-6 pt-5 col-lg-2 text-center p-lg-1" style={{ marginTop: isMobile ? '' : '-25px' }}>
          <img
            width={isMobile ? '90%' : '85%'}
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/stanford.png"
            alt="stanford"
            className="my-2"
          />
        </div>
        <div className="col-6 pt-5 col-lg-2 text-center pl-lg-5 pb-lg-5" style={{ marginTop: isMobile ? '' : '-25px' }}>
          <img
            width={isMobile ? '60%' : '65%'}
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/dartmouth.png"
            alt="dartmouth"
            className="my-2"
          />
        </div>
        <div className="col-6 pt-5 col-lg-2 text-center pl-lg-5 pb-lg-5" style={{ marginTop: isMobile ? '' : '-25px' }}>
          <img
            width={isMobile ? '90%' : '75%'}
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/columbia.png"
            alt="columbiau"
            className="my-2"
          />
        </div>
        <div className="col-6 pt-5 col-lg-3 text-center p-lg-1" style={{ marginTop: isMobile ? '' : '-25px' }}>
          <img
            width={isMobile ? '90%' : '65%'}
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/oxfordu.png"
            alt="oxford"
            className="my-2"
          />
        </div>
        <div className="col-6 pt-5 col-lg-3 text-center p-lg-1" style={{ marginTop: isMobile ? '' : '-25px' }}>
          <img
            width={isMobile ? '90%' : '80%'}
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/cornell.png"
            alt="cornell"
            className="my-2"
          />
        </div>
        <div className="col-6 pt-5 col-lg-2 text-center p-lg-1" style={{ marginTop: isMobile ? '' : '-25px' }}>
          <img
            width={isMobile ? '90%' : '75%'}
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/northwestern.svg"
            alt="northwestern"
            className="my-2"
          />
        </div>
        <div className="col-6 pt-5 col-lg-2 text-center pl-lg-5 pb-lg-5" style={{ marginTop: isMobile ? '' : '-25px' }}>
          <img
            width={isMobile ? '90%' : '110%'}
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/princeton.png"
            alt="princeton"
            className="my-2"
          />
        </div>
        <div className="col-6 pt-5 col-lg-2 text-center pl-lg-5 pb-lg-5" style={{ marginTop: isMobile ? '' : '-25px' }}>
          <img
            width={isMobile ? '60%' : '55%'}
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/ucrisd.png"
            alt="risd"
            className="my-2"
          />
        </div>
        <div className="col-3 d-lg-none text-center p-lg-1"></div>
        <div className="col-6 pt-5 col-lg-3 text-center p-lg-1" style={{ marginTop: isMobile ? '' : '-40px' }}>
          <img
            width={isMobile ? '90%' : '65%'}
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/chigago.png"
            alt="chigago"
            className="my-2"
          />
        </div>
      </div>
    ) : (
      <div className="row align-items-center justify-content-around">
        <div className="col-6 col-lg-3 text-lg-left text-center">
          <img
            width="65%"
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/penn.png"
            alt="penn"
            className="my-4"
          />
        </div>
        <div className="col-6 col-lg-2">
          <img
            width="105%"
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/ucberkeley.png"
            alt="ucberkeley"
            className="my-4"
            style={{ marginLeft: isMobile ? '' : '-6vh' }}
          />
        </div>
        <div className="col-6 text-center col-lg-2">
          <img
            width="60%"
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/brown.png"
            alt="brown"
            className="my-4"
          />
        </div>
        <div className="col-6 col-lg-2 text-right">
          <img
            width="100%"
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/northwestern.svg"
            alt="northwestern"
            className="my-4"
            style={{ marginLeft: isMobile ? '' : '6vh' }}
          />
        </div>
        <div className="col-6 col-lg-3 text-lg-right text-center">
          <img
            width="50%"
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/ucyale.png"
            alt="yale"
            className="my-4 pr-lg-5"
          />
        </div>
        <div className="col-6 col-lg-3 text-lg-left text-center">
          <img
            width="70%"
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/princeton.png"
            alt="princeton"
            className="my-4"
          />
        </div>
        <div className="col-6 col-lg-2">
          <img
            width="95%"
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/stanford.png"
            alt="stanford"
            className="my-4"
            style={{ marginLeft: isMobile ? '' : '-4vh' }}
          />
        </div>
        <div className="col-6 text-center col-lg-2">
          <img
            width="95%"
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/columbiauny.png"
            alt="columbia"
            className="my-4"
          />
        </div>{' '}
        <div className="col-6 text-lg-right text-center col-lg-2">
          <img
            width="95%"
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/darthmouthu.png"
            alt="dartmouth"
            className="my-4"
            style={{ marginLeft: isMobile ? '' : '7vh' }}
          />
        </div>{' '}
        <div className="col-6 col-lg-3 text-lg-right text-center">
          <img
            width="70%"
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/cornell.png"
            alt="cornell"
            className="my-4"
          />
        </div>
        <div className="col-6 col-lg-3 text-lg-left text-center">
          <img
            width="65%"
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/cambridge.png"
            alt="cambridge"
            className="my-4 pl-lg-5"
          />
        </div>
        <div className="col-6 col-lg-2">
          <img
            width="95%"
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/oxfordu.png"
            alt="oxford"
            className="my-4"
            style={{ marginLeft: isMobile ? '' : '-5vh' }}
          />
        </div>
        <div className="col-6 text-center col-lg-2">
          <img
            width="95%"
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/imperialcol.png"
            alt="imperialcol"
            className="my-4"
          />
        </div>
        <div className="col-6 text-right col-lg-2">
          <img
            width="95%"
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/ucl.png"
            alt="ucl"
            className="my-4"
            style={{ marginLeft: isMobile ? '' : '8vh' }}
          />
        </div>
        <div className="col-12 col-lg-3 text-lg-right text-center">
          <img
            width="60%"
            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/kingscol.png"
            alt="kingscol"
            className="my-4"
          />
        </div>
      </div>
    )
  }
}

export default StudentDestinationCard
