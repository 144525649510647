import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { ABOUT_US } from 'routes/routes'
import { DropdownSection } from './components'

const AboutDropdownEl = styled.div`
  width: 15rem;
  background-color: white;
`

const AboutDropdown = props => {
  return (
    <AboutDropdownEl onMouseLeave={props.onMouseLeave}>
      <DropdownSection>
        <div className="bg-white my-2">
          <div className="d-flex align-items-center ml-3 mr-4">
            <img
              src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/logo/EPA+Standard+Logo+Dark+Background+(Transparent).png"
              alt="tenopy"
              width="30"
              height="30"
            />
            <NavLink to={ABOUT_US} className="navbar-link" onClick={props.exitDropdown}>
              About Us
            </NavLink>
          </div>

          <hr />

          <div className="text-muted text-small ml-3 mr-4 mb-2">About Our Programmes</div>

          <div className="d-flex align-items-center ml-3 mr-4">
            <img
              src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/icon/subject-english-gradient.png"
              alt="english"
              width="30"
              height="30"
            />
          </div>

          {/* <div className="d-flex align-items-center ml-3 mr-4">
            <img
              src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/icon/subject-science-gradient.png"
              alt="science"
              width="30"
              height="30"
            />
            <NavLink to={TENOPY_SCIENCE} className="navbar-link" onClick={props.exitDropdown}>
              Science
            </NavLink>
          </div> */}
        </div>
      </DropdownSection>
    </AboutDropdownEl>
  )
}

export default AboutDropdown
