import React from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { APP, TRIAL } from 'routes/routes'

const TrialBanner = props => {
  let ended = false

  if (moment() > moment('2021-12-19T00:00:00Z')) {
    ended = true
  }

  if (ended) {
    return null
  }

  const goToTrialPage = () => {
    props.history.push(`${APP}${TRIAL}`)
  }

  return (
    <div className="row mb-3" onClick={goToTrialPage}>
      <div className="col-12 text-center">
        <div className="d-block d-md-none">
          <img
            src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/misc/banner-2022-trial-mobile.png"
            alt="Teacher day"
            className="img-fluid pointer"
          />
        </div>

        <div className="d-md-block d-none">
          <img
            src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/misc/banner-2022-trial-desktop.png"
            alt="Teacher day"
            className="img-fluid pointer"
          />
        </div>
      </div>
    </div>
  )
}

export default withRouter(TrialBanner)
