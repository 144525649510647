import React from 'react'
import { connect } from 'react-redux'
import { CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements'
import { Button } from '@material-ui/core'
import _ from 'lodash'

import agent from 'agent'
import { CHECKOUT_CART } from 'constants/actionTypes'
import { TERM_OF_SERVICE } from 'routes/routes'

import { Alert } from 'reactstrap'

const mapStateToProps = state => ({
  ...state.shoppingCart,
  purchasedClasses: state.common.purchasedClasses
})

const mapDispatchToProps = dispatch => ({
  checkout: (reqPayload, newClasses, existingClasses) =>
    dispatch({ type: CHECKOUT_CART, payload: agent.ShoppingCart.checkout(reqPayload), newClasses, existingClasses })
})

const createOptions = fontSize => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#dc3545'
      }
    }
  }
}

class StripeForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isPaymentInProgress: false,
      cardNumberElementReady: false,
      cardNumberValid: false,
      cardNumberInvalid: null,
      cardNumberInvalidMsg: null,
      expirationDateElementReady: false,
      expirationDateValid: false,
      expirationDateInvalid: null,
      expirationDateInvalidMsg: null,
      cvcNumberElementReady: false,
      cvcNumberValid: false,
      cvcNumberInvalid: null,
      cvcNumberInvalidMsg: null,
      paymentTermsInvalidMsg: null
    }

    this.useNewCard = () => {
      this.setState({ useNewCard: true })
    }

    //NOTE: need more complicated and troblesome scenario to validate form here. Review later
    this.handleCardNumberChange = change => {
      if (change.complete) {
        this.setState({
          cardNumberValid: true,
          cardNumberInvalid: null
        })
      } else {
        if (change.error) {
          this.setState({
            cardNumberValid: false,
            cardNumberInvalid: change.error.message
          })
        } else {
          this.setState({
            cardNumberValid: false,
            cardNumberInvalid: 'Please fill in valid card number'
          })
        }
      }
      if (change.empty) {
        this.setState({
          cardNumberValid: false,
          cardNumberInvalid: null,
          cardNumberInvalidMsg: null
        })
      }
    }

    this.handleCardNumberReady = () => {
      this.setState({
        cardNumberElementReady: true
      })
    }

    this.handleCardNumberBlur = () => {
      this.setState({
        cardNumberInvalidMsg: this.state.cardNumberInvalid
      })
    }

    this.handleExpirationDateChange = change => {
      if (change.complete) {
        this.setState({
          expirationDateValid: true,
          expirationDateInvalid: null
        })
      } else {
        if (change.error) {
          this.setState({
            expirationDateValid: false,
            expirationDateInvalid: change.error.message
          })
        } else {
          this.setState({
            expirationDateValid: false,
            expirationDateInvalid: 'Please fill in expiration date'
          })
        }
      }
      if (change.empty) {
        this.setState({
          expirationDateValid: false,
          expirationDateInvalid: null,
          expirationDateInvalidMsg: null
        })
      }
    }

    this.handleExpirationDateReady = () => {
      this.setState({
        expirationDateElementReady: true
      })
    }

    this.handleExpirationDateBlur = () => {
      this.setState({
        expirationDateInvalidMsg: this.state.expirationDateInvalid
      })
    }

    this.handleCvcNumberChange = change => {
      if (change.complete) {
        this.setState({
          cvcNumberValid: true,
          cvcNumberInvalid: null
        })
      } else {
        if (change.error) {
          this.setState({
            cvcNumberValid: false,
            cvcNumberInvalid: change.error.message
          })
        } else {
          this.setState({
            cvcNumberValid: false,
            cvcNumberInvalid: 'Please fill in CVC number'
          })
        }
      }
      if (change.empty) {
        this.setState({
          cvcNumberValid: false,
          cvcNumberInvalid: null,
          cvcNumberInvalidMsg: null
        })
      }
    }

    this.handleCvcNumberReady = () => {
      this.setState({
        cvcNumberElementReady: true
      })
    }

    this.handleCvcNumberBlur = () => {
      this.setState({
        cvcNumberInvalidMsg: this.state.cvcNumberInvalid
      })
    }

    //NOTE: adapted from previous code, will need some changes to use it on current process
    this.handleSubmit = event => {
      event.preventDefault()
      if (this.props.isPaymentInProgress) {
        return
      }

      if (!this.state.cardNumberValid && !this.state.cardNumberInvalidMsg) {
        this.setState({ cardNumberInvalidMsg: 'Please fill in valid card number' })
      }
      if (!this.state.expirationDateValid && !this.state.expirationDateInvalidMsg) {
        this.setState({ expirationDateInvalidMsg: 'Please fill in expiration date' })
      }
      if (!this.state.cvcNumberValid && !this.state.cvcNumberInvalidMsg) {
        this.setState({ cvcNumberInvalidMsg: 'Please fill in CVC number' })
      }

      if (!this.state.cardNumberValid || !this.state.expirationDateValid || !this.state.cvcNumberValid) {
        return
      }

      let component = this
      this.setState({
        isPaymentInProgress: true
      })

      //cross check with purchased classes to see if it is a new purchase or renewal
      this.props.stripe.createToken().then(function (payload) {
        let checkoutReq = {}
        checkoutReq.stripe_token = payload.token.id
        checkoutReq.credit = component.props.creditToUse
        checkoutReq.shopping_cart = component.props.shoppingCart._id

        let newClasses = component.props.shoppingCart.item.map(cartItem => {
          return cartItem.item_id
        })
        let existingClasses = _.remove(newClasses, n => {
          return component.props.purchasedClasses.includes(n)
        })

        if (component.props.discountCode) {
          checkoutReq.discount_code = component.props.discountCode
        }

        component.props.checkout(checkoutReq, newClasses, existingClasses)
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.isPaymentInProgress) {
      if (prevProps.checkoutInProgress && !this.props.checkoutInProgress) {
        this.setState({ isPaymentInProgress: false })
      }
    }
  }

  goToTnCPage = () => {
    window.open(`${process.env.REACT_APP_HOSTNAME}${TERM_OF_SERVICE}`)
  }

  render() {
    let showLoadingOverlay = true
    if (
      this.state.cardNumberElementReady &&
      this.state.expirationDateElementReady &&
      this.state.cvcNumberElementReady
    ) {
      showLoadingOverlay = false
    }
    let formInvalid = !this.state.cardNumberValid || !this.state.expirationDateValid || !this.state.cvcNumberValid
    let { price, checkoutError } = this.props

    return (
      <div className="payment__stripe">
        {showLoadingOverlay && (
          <div className="row justify-content-md-center align-items-center loading-overlay">
            <div className="col text-center">
              <i className="fa fa-circle-o-notch fa-spin fa-3x" />
            </div>
          </div>
        )}

        {checkoutError && (
          <Alert className="shadow-lg" color="danger">
            {checkoutError}
          </Alert>
        )}

        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col">
              <label className="mt-0">
                <span className="input-label">Card number</span>
                <CardNumberElement
                  onBlur={this.handleCardNumberBlur}
                  onChange={this.handleCardNumberChange}
                  onReady={this.handleCardNumberReady}
                  {...createOptions('17px')}
                />
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="invalid-input">{this.state.cardNumberInvalidMsg}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-7">
              <label>
                Expiration date
                <CardExpiryElement
                  onBlur={this.handleExpirationDateBlur}
                  onChange={this.handleExpirationDateChange}
                  onReady={this.handleExpirationDateReady}
                  {...createOptions('17px')}
                />
              </label>
            </div>
            <div className="col-md-5">
              <label>
                CVC
                <CardCVCElement
                  onBlur={this.handleCvcNumberBlur}
                  onChange={this.handleCvcNumberChange}
                  onReady={this.handleCvcNumberReady}
                  {...createOptions('17px')}
                />
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-7">
              <div className="invalid-input">{this.state.expirationDateInvalidMsg}</div>
            </div>
            <div className="col-md-5">
              <div className="invalid-input">{this.state.cvcNumberInvalidMsg}</div>
            </div>
          </div>

          <div className="row align-items-center mt-md-3 mt-0">
            <div className="col-12">
              <Button
                variant="contained"
                className={this.state.isPaymentInProgress || formInvalid ? 'px-5' : 'bg-success text-white px-5'}
                disabled={this.state.isPaymentInProgress || formInvalid}
                type="submit"
                fullWidth>
                Pay {price > 0 && `${Number(price).toFixed(2)}`}
                {this.state.isPaymentInProgress && <i className="fa fa-circle-o-notch fa-spin ml-2"></i>}
              </Button>
              <div className="text-muted text-small text-center">
                By clicking on "Pay" button, I agree to the purchase&nbsp;
                <span className="link" onClick={this.goToTnCPage}>
                  terms & conditions
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StripeForm)
