import styled from 'styled-components'

export const Heading = styled.h3`
  font-size: 17px;
  margin-top: 0;
  margin-bottom: ${props => (props.noMarginBottom ? 0 : '1rem')};
  color: #6772e5;
`

export const HeadingLink = Heading.withComponent('li')

export const LinkList = styled.ul`
  li {
    margin-bottom: 1rem;
  }
  li:last-of-type {
    margin-bottom: 0;
  }

  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)};
`

export const Icon = styled.div`
  width: 13px;
  height: 13px;
  margin-right: 13px;
  background-color: #6772e5;
  opacity: 0.8;
  display: inline-block;
`

export const DropdownSection = styled.div`
  position: relative;
  z-index: 1;
`
