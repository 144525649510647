import React from 'react'
import { connect } from 'react-redux'
import agent from '../../agent'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Button, FormControlLabel, Checkbox } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { CHECK_FEEDBACK_EXISTS, CREATE_FEEDBACK } from '../../constants/actionTypes'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

const mapStateToProps = state => ({
  ...state.feedback,
  currentUser: state.common.currentUser
  // inProgress: state.feedback.inProgress
})

const mapDispatchToProps = dispatch => ({
  createFeedback: feedbackObj =>
    dispatch({ type: CREATE_FEEDBACK, payload: agent.UserInput.createFeedback(feedbackObj) }),
  checkFeedback: filter => dispatch({ type: CHECK_FEEDBACK_EXISTS, payload: agent.UserInput.getFeedback(filter) })
})

class DialogCreateFeedback extends React.Component {
  constructor(props) {
    super()

    this.handleChange = e => {
      this.setState({ [e.target.name]: e.target.value })
    }

    this.closeFeedbackDialog = () => {
      this.props.toggle()
    }

    this.state = {
      hoverRating: 0,
      rating: 0,
      content: '',
      feedbackDialogOpen: props.isOpen,
      badDialogOpen: false,
      successDialogOpen: false,
      alreadyDoneDialogOpen: false,
      allow_anonymous: false
    }
  }

  changeHoverRating = value => () => {
    this.setState({ hoverRating: value })
  }

  changeRating = value => () => {
    this.setState({ rating: value })
  }

  handleCheckboxChange = (e, checked) => {
    let name = e.target.name
    this.setState({ [name]: checked })
  }

  saveFeedback = () => {
    const { rating, content, allow_anonymous } = this.state
    const { lesson } = this.props

    let feedbackObj = {
      rating,
      content,
      rating_system: '5_star',
      feedback_target_type: 'lesson',
      feedback_target_id: lesson._id,
      allow_anonymous
    }

    this.props.createFeedback(feedbackObj)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.feedbackCreated && this.props.feedbackCreated) {
      if (this.state.rating >= 4) {
        this.setState({ feedbackDialogOpen: false, successDialogOpen: true })
      } else {
        this.setState({ feedbackDialogOpen: false, badDialogOpen: true })
      }
    }

    if (prevProps.checkingFeedback && !this.props.checkingFeedback && !this.props.error) {
      if (!this.props.feedbackExists) {
        this.saveFeedback()
      } else {
        this.setState({
          feedbackDialogOpen: false,
          alreadyDoneDialogOpen: true
        })
      }
    }
  }

  presaveCheck = () => {
    this.props.checkFeedback({
      created_by: this.props.currentUser._id,
      feedback_target_id: this.props.lesson._id
    })
  }

  render() {
    const { toggle, lesson, inProgress, checkingFeedback } = this.props
    const {
      hoverRating,
      rating,
      content,
      feedbackDialogOpen,
      badDialogOpen,
      successDialogOpen,
      alreadyDoneDialogOpen,
      allow_anonymous
    } = this.state

    let ratingText = ''

    if (hoverRating !== 0) {
      switch (hoverRating) {
        case 1:
          ratingText = 'Very Bad'
          break
        case 2:
          ratingText = 'Poor'
          break
        case 3:
          ratingText = 'Average'
          break
        case 4:
          ratingText = 'Good'
          break
        case 5:
          ratingText = 'Excellent'
          break
        default:
          ratingText = ''
      }
    } else {
      switch (rating) {
        case 1:
          ratingText = 'Very Bad'
          break
        case 2:
          ratingText = 'Poor'
          break
        case 3:
          ratingText = 'Average'
          break
        case 4:
          ratingText = 'Good'
          break
        case 5:
          ratingText = 'Excellent'
          break
        default:
          ratingText = ''
      }
    }

    return (
      <div>
        <Modal isOpen={feedbackDialogOpen} toggle={toggle} centered={true}>
          <ModalHeader toggle={toggle}>
            <div className={'row'}>
              <div className="col-12 font-weight-semibold">How was {lesson.tutor[0].name}'s teaching?</div>
              <div className="col-12">
                <small>E.g. pace, difficulty, content</small>
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="app-wrapper rounded border border-warning lighten-4">
              <div className="row">
                <div className="col-2">
                  <img src={lesson.tutor[0].profile_pic} alt="" className={'img-fluid rounded-circle'} />
                </div>
                <div className="col d-flex align-items-center">
                  <div>
                    <h3 className="font-weight-semibold mb-1">{lesson.name}</h3>
                    <h5 className={'mb-0'}>
                      {moment(lesson.scheduled_on).format('ddd D MMM, h:mma')} -{' '}
                      {moment(lesson.ended_on).format('h:mma')}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-3 d-flex align-items-center">
              <span style={{ fontSize: '55px' }}>
                <i
                  className={
                    hoverRating >= 1
                      ? 'zmdi zmdi-star text-warning pr-1'
                      : rating >= 1 && hoverRating === 0
                      ? 'zmdi zmdi-star text-warning pr-1'
                      : 'zmdi zmdi-star-outline pr-1'
                  }
                  onMouseOver={this.changeHoverRating(1)}
                  onMouseOut={this.changeHoverRating(0)}
                  onClick={this.changeRating(1)}
                />
                <i
                  className={
                    hoverRating >= 2
                      ? 'zmdi zmdi-star text-warning pr-1'
                      : rating >= 2 && hoverRating === 0
                      ? 'zmdi zmdi-star text-warning pr-1'
                      : 'zmdi zmdi-star-outline pr-1'
                  }
                  onMouseOver={this.changeHoverRating(2)}
                  onMouseOut={this.changeHoverRating(0)}
                  onClick={this.changeRating(2)}
                />
                <i
                  className={
                    hoverRating >= 3
                      ? 'zmdi zmdi-star text-warning pr-1'
                      : rating >= 3 && hoverRating === 0
                      ? 'zmdi zmdi-star text-warning pr-1'
                      : 'zmdi zmdi-star-outline pr-1'
                  }
                  onMouseOver={this.changeHoverRating(3)}
                  onMouseOut={this.changeHoverRating(0)}
                  onClick={this.changeRating(3)}
                />
                <i
                  className={
                    hoverRating >= 4
                      ? 'zmdi zmdi-star text-warning pr-1'
                      : rating >= 4 && hoverRating === 0
                      ? 'zmdi zmdi-star text-warning pr-1'
                      : 'zmdi zmdi-star-outline pr-1'
                  }
                  onMouseOver={this.changeHoverRating(4)}
                  onMouseOut={this.changeHoverRating(0)}
                  onClick={this.changeRating(4)}
                />
                <i
                  className={
                    hoverRating >= 5
                      ? 'zmdi zmdi-star text-warning pr-1'
                      : rating >= 5 && hoverRating === 0
                      ? 'zmdi zmdi-star text-warning pr-1'
                      : 'zmdi zmdi-star-outline pr-1'
                  }
                  onMouseOver={this.changeHoverRating(5)}
                  onMouseOut={this.changeHoverRating(0)}
                  onClick={this.changeRating(5)}
                />
              </span>
              {!!rating || !!hoverRating ? <strong className={'ml-3'}>{ratingText}</strong> : null}
            </div>
            <div className="text-left">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allow_anonymous}
                    onChange={this.handleCheckboxChange}
                    name="allow_anonymous"
                    color="primary"
                  />
                }
                label="Submit this rating anonymously"
              />
            </div>
            <div className={'mb-3'}>
              <div className="mb-1">
                Share more details with us. <span className="text-muted">(Optional)</span>
              </div>
              <textarea
                name={'content'}
                value={content}
                onChange={this.handleChange}
                className={'form-control'}
                rows={3}
              />
            </div>

            <div className="text-center">
              <Button
                disabled={rating === 0 || inProgress || checkingFeedback}
                variant={'contained'}
                color={'primary'}
                className={'text-capitalize'}
                onClick={this.presaveCheck}>
                Submit{' '}
                {(inProgress || checkingFeedback) && (
                  <CircularProgress size={20} color={'primary'} className={'ml-2'} />
                )}
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={badDialogOpen} toggle={toggle} centered={true} size={'sm'}>
          <ModalHeader toggle={toggle} className={'border-bottom-0'} />
          <ModalBody className={'px-5 pt-0'}>
            <div className="row">
              <div className="col-4 text-center">
                <img
                  src="//s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/frontend/feedback.svg"
                  alt=""
                  className={'img-fluid'}
                />
              </div>
              <div className="col-8 d-flex align-items-center">
                <h2 className="font-weight-semibold mb-0 text-center">Thank you for your feedback!</h2>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={successDialogOpen} toggle={toggle} centered={true} size={'sm'}>
          <ModalHeader toggle={toggle} className={'border-bottom-0'} />
          <ModalBody className={'px-5 py-0'}>
            <div className="row">
              <div className="col-3 text-center">
                <img
                  src="//s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/frontend/boy_hi.svg"
                  alt=""
                  className={'img-fluid'}
                />
              </div>
              <div className="col-9 d-flex align-items-center">
                <div>
                  <h2>Thank you for your feedback!</h2>
                  <p>Help us improve by rating all your other lessons!</p>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={alreadyDoneDialogOpen} toggle={toggle} centered={true} size={'sm'}>
          <ModalHeader toggle={toggle} className={'border-bottom-0'} />
          <ModalBody className={'px-5 pt-0'}>
            <div className="row">
              <div className="col-4 text-center">
                <img
                  src="//s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/frontend/feedback.svg"
                  alt=""
                  className={'img-fluid'}
                />
              </div>
              <div className="col-8 d-flex align-items-center">
                <div className={'text-center'}>
                  <h2 className="font-weight-semibold mb-0">It seems that you have already rated this lesson!</h2>
                  <p>Help us improve by rating your other lessons!</p>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

DialogCreateFeedback.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  lesson: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogCreateFeedback)
