import React from 'react'
import { connect } from 'react-redux'
import { Paper, CircularProgress } from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'

import agent from 'agent'
import { GET_MY_PAID_CLASSES, GET_TUTOR_LESSONS } from 'constants/actionTypes'
import Pagination from 'utils/Pagination'
import UpdateProfileCTA from 'components/misc/cta-update-profile'
import ClassListing from '../my-class-list'
import LessonItem from 'components/shared/Classes/LessonItem'
import { LESSON_PAGE } from 'routes/tutorRoutes'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  allLessons: state.lessons.allLessons,
  loadingLessons: state.lessons.loadingLessons,
  loadingPaidClass: state.classes.loadingPaidClass,
  paidClasses: state.classes.paidClass
})

const mapDispatchToProps = dispatch => ({
  getOngoingClasses: filter =>
    dispatch({ type: GET_MY_PAID_CLASSES, payload: agent.Classes.getMyClassList('ongoing', filter) }),
  getAllLessons: studentId => dispatch({ type: GET_TUTOR_LESSONS, payload: agent.Lessons.getAllLessons(studentId) })
})

const PER_PAGE = 12

class OngoingClasses extends React.Component {
  constructor(props) {
    super()

    this.viewLessons = selectedClass => {
      //this.props.setClass(selectedClass)
      let classDetailUrl =
        LESSON_PAGE.replace(':classId', selectedClass.class._id).replace(':lessonId', selectedClass._id) + '?tf=home'
      this.props.history.push(classDetailUrl)
    }

    this.state = {
      currentLessons: [],
      todayLessons: [],
      tomorrowLessons: [],
      upcomingLessons: []
    }
  }
  componentDidMount() {
    // this.getOngoingClasses(0)
    this.props.getAllLessons(this.props.currentUser._id)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.loadingLessons !== prevProps.loadingLessons) {
      let now = moment()
      let closest = []
      let todayLessons = []
      let tomorrowLessons = []
      let upcomingLessons = []

      _.forEach(this.props.allLessons, ll => {
        if (moment(ll.scheduled_on) >= now) {
          closest.push(ll)
        } else if (moment(ll.scheduled_on) >= moment().startOf('day')) {
          closest.push(ll)
        } else if (moment(ll.scheduled_on) < moment(closest.scheduled_on) && moment(ll.scheduled_on) >= now) {
          closest.push(ll)
        }
      })

      let sortedClosest = _.orderBy(closest, ['scheduled_on', 'asc'])
      this.setState({
        currentLessons: sortedClosest
      })

      _.forEach(sortedClosest, ll => {
        if (moment(ll.scheduled_on).endOf('day').isSame(moment().endOf('day'))) {
          todayLessons.push(ll)
        }
      })
      this.setState({
        todayLessons: todayLessons
      })

      _.forEach(sortedClosest, ll => {
        if (moment(ll.scheduled_on).endOf('day').isSame(moment().add(1, 'day').endOf('day'))) {
          tomorrowLessons.push(ll)
        }
      })
      this.setState({
        tomorrowLessons: tomorrowLessons
      })

      _.forEach(sortedClosest, ll => {
        if (moment(ll.scheduled_on).endOf('day').isSameOrAfter(moment().add(2, 'day').endOf('day'))) {
          upcomingLessons.push(ll)
        }
      })
      this.setState({
        upcomingLessons: upcomingLessons
      })
    }
  }

  getOngoingClasses = page => {
    this.props.getOngoingClasses({
      max: PER_PAGE,
      offset: page * PER_PAGE
    })
  }

  onChangePage = nextPage => {
    this.getOngoingClasses(nextPage - 1)
  }

  render() {
    const { loadingPaidClass, paidClasses, allLessons, loadingLessons } = this.props
    const { todayLessons, tomorrowLessons, upcomingLessons, currentLessons } = this.state

    return (
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <Paper elevation={2} className="app-wrapper">
          <div className="row align-items-center">
            <div className="col">
              <h2 className="mb-0">My Classes</h2>
            </div>
          </div>
        </Paper>

        <div className="app-wrapper">
          <UpdateProfileCTA />

          {/* <ClassListing loading={loadingPaidClass} classList={paidClasses.data} classCount={paidClasses.count} /> */}

          {/* <ClassListing loading={loadingLessons} classList={allLessons} classCount={paidClasses.count} /> */}

          {loadingLessons ? (
            currentLessons.length > 0 ? (
              <div className="grid__renderer">
                <div className="flex col-12 mb-4">
                  <h3> {moment().format('LLLL').slice(0, -8)} (Today)</h3>
                  <div className="row">
                    {todayLessons.length > 0 ? (
                      todayLessons.map((classData, index) => {
                        return (
                          <LessonItem
                            key={index}
                            className={'grid__item'}
                            classData={classData}
                            viewClass={() => {
                              this.viewLessons(classData)
                            }}
                          />
                        )
                      })
                    ) : (
                      <h4 className="mt-2 ml-3">No Lessons for today</h4>
                    )}
                  </div>
                </div>

                <div className="flex col-12 mb-4">
                  <h3> {moment().add(1, 'day').format('LLLL').slice(0, -8)} (Tomorrow)</h3>
                  <div className="row">
                    {tomorrowLessons.length > 0 ? (
                      tomorrowLessons.map((classData, index) => {
                        return (
                          <LessonItem
                            key={index}
                            className={'grid__item'}
                            classData={classData}
                            viewClass={() => {
                              this.viewLessons(classData)
                            }}
                          />
                        )
                      })
                    ) : (
                      <h4 className="mt-2 ml-3">No Lessons for tomorrow</h4>
                    )}
                  </div>{' '}
                </div>

                <div className="flex col-12">
                  <h3>Upcoming Lessons</h3>{' '}
                  <div className="row">
                    {upcomingLessons.map((classData, index) => {
                      return (
                        <LessonItem
                          key={index}
                          className={'grid__item'}
                          classData={classData}
                          viewClass={() => {
                            this.viewLessons(classData)
                          }}
                        />
                      )
                    })}
                  </div>{' '}
                </div>
              </div>
            ) : (
              <h2>You are not enrolled in any classes</h2>
            )
          ) : (
            <div className="my-5 text-center">
              <CircularProgress size={40} color={'primary'} />
            </div>
          )}

          <Pagination itemCount={paidClasses.count} pageSize={PER_PAGE} onChangePage={this.onChangePage} />
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OngoingClasses)
