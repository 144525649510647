import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Slide
} from '@material-ui/core'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Proptypes from 'prop-types'
import agent from 'agent'
import { UPDATE_LESSON } from 'constants/actionTypes'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const mapStateToProps = state => ({
  updatingLesson: state.lessons.updatingLesson
})

const mapDispatchToProps = dispatch => ({
  updateActivityOrder: (lessonId, reqPayload) =>
    dispatch({ type: UPDATE_LESSON, payload: agent.Lessons.update(lessonId, reqPayload) })
})

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the sortedActivity look a bit nicer
  userSelect: 'none',
  padding: '10px 15px',
  margin: `0 0 10px 0`,
  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',
  borderadius: '8px',
  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  // styles we need to apply on draggables
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'white',
  padding: '8px',
  width: '100%'
})

class SortActivityDialog extends React.Component {
  state = {
    sortedActivity: []
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({ sortedActivity: this.props.activityList })
    }

    if (prevProps.updatingLesson && !this.props.updatingLesson) {
      this.props.close()
    }
  }

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const sortedActivity = reorder(this.state.sortedActivity, result.source.index, result.destination.index)
    this.setState({
      sortedActivity
    })
  }

  handleSave = () => {
    const { lessonId } = this.props
    const { sortedActivity } = this.state

    let formattedActivity = []
    for (let i = 0; i < sortedActivity.length; i++) {
      formattedActivity.push({
        order: i + 1,
        content: sortedActivity[i].content._id
      })
    }
    this.props.updateActivityOrder(lessonId, { activity: formattedActivity })
  }

  render() {
    const { isOpen, updatingLesson } = this.props
    const { sortedActivity } = this.state

    return (
      <Dialog open={isOpen} maxWidth="md" fullWidth TransitionComponent={Transition}>
        <DialogTitle>
          <span className="h3 tittle--rubik">Sort Activity</span>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                  {sortedActivity.map((item, index) => (
                    <Draggable key={item.content._id} draggableId={item.content._id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                          <div className="d-flex" style={{ marginTop: '1rem' }}>
                            <span className="mr-2">{item.order}.</span>
                            <span dangerouslySetInnerHTML={{ __html: item.content.question }} />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="default" onClick={this.props.close} disabled={updatingLesson}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={this.handleSave} disabled={updatingLesson}>
            Save & Upload {updatingLesson && <CircularProgress className="ml-2" size={15} color="primary" />}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

SortActivityDialog.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired,
  lessonId: Proptypes.string,
  activityList: Proptypes.array
}

export default connect(mapStateToProps, mapDispatchToProps)(SortActivityDialog)
