import React from 'react'
import { connect } from 'react-redux'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import _ from 'lodash'
import moment from 'moment'

import agent from 'agent'
import { GET_ONLINE_CLASS_BY_ID, GET_PACKAGE_QUOTE, CALC_PACKAGE_QUOTE } from 'constants/actionTypes'
import { CLASS_TYPE_REGULAR_CLASSES, CLASS_TYPE_WORKSHOP } from 'constants/generalTypes'
import { getPackageForOnlineClass } from 'utils/merchandise'
import ClassPackageSelector from 'components/online-class/select-class-package'

const mapStateToProps = state => ({
  loadingOnlineClass: state.onlineClass.loadingOnlineClass,
  classData: state.onlineClass.classData,
  userClassWithDetail: state.common.userClassWithDetail
})

const mapDispatchToProps = dispatch => ({
  getClassDetails: classId =>
    dispatch({ type: GET_ONLINE_CLASS_BY_ID, payload: agent.OnlineClass.getClassById(classId) }),
  getPackageQuote: (payload, packageItem) =>
    dispatch({ type: GET_PACKAGE_QUOTE, payload: agent.OnlineClass.getQuote(payload), packageItem: packageItem }),
  calcPackageQuote: (quote, packageItem) => dispatch({ type: CALC_PACKAGE_QUOTE, payload: quote, packageItem })
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

class PurchaseClassDialog extends React.Component {
  state = {
    activeTab: 'overview',
    availableTerms: [],
    lessonsByTerm: {}
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { classId, classData } = this.props

      if (!classData || classData?._id !== classId) {
        this.props.getClassDetails(classId)
      }
    }

    if (prevProps.loadingOnlineClass && !this.props.loadingOnlineClass) {
      const { classData, userClassWithDetail } = this.props

      if (classData.class_type === CLASS_TYPE_REGULAR_CLASSES) {
        let availableTerms = _.uniq(_.map(classData.lesson, 'meta.term'))

        let lessonsByTerm = _.groupBy(classData.lesson, o => {
          return o.meta.term
        })

        this.setState({ availableTerms, lessonsByTerm })
      }

      let lessonPackages = getPackageForOnlineClass(userClassWithDetail, classData)
      if (lessonPackages.length > 0) {
        if (lessonPackages === 'no_more_lesson_to_purchase') {
          this.props.calcPackageQuote({ data: lessonPackages }, { class_id: classData._id })
        } else {
          for (let i = 0; i < lessonPackages.length; i++) {
            this.props.getPackageQuote(
              {
                class_id: classData._id,
                start_date: lessonPackages[i].start_date,
                end_date: lessonPackages[i].end_date
              },
              lessonPackages[i].packageItem
            )
          }
        }
      }
    }
  }

  handleTabChange = tab => () => {
    this.setState({ activeTab: tab })
  }

  render() {
    const { activeTab, lessonsByTerm } = this.state
    const { isOpen, classData } = this.props

    return (
      <Dialog open={isOpen} onClose={this.props.close} TransitionComponent={Transition} maxWidth="md">
        <DialogTitle>Purchase Class</DialogTitle>
        <DialogContent>
          {!classData ? (
            <div className="text-center my-5">
              <CircularProgress size={30} color="primary" />
            </div>
          ) : (
            <div className="intro mb-3">
              <div className="row">
                <div className="col-md-3">
                  <div className="intro__image">
                    {classData.tutor.map((tutorObj, index) => {
                      return (
                        <div key={index} className={'row'}>
                          <div className="col-4 col-md-12">
                            <img src={tutorObj.profile_pic} alt="" className="img-fluid rounded" />
                          </div>
                          <div className="col-8 col-md-12 d-flex align-items-center">
                            <div className="mt-2 mx-auto text-center">
                              <h3 className="mb-0">{tutorObj.name}</h3>
                              <h3 className="text-muted">{classData.subject}</h3>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="intro__text">
                    <h2 className="intro__title">{classData.name}</h2>

                    <div className="tab__component mb-3">
                      <input
                        id="overview"
                        type="radio"
                        name="tabs_dp"
                        className={'tab__input'}
                        checked={activeTab === 'overview'}
                        onChange={this.handleTabChange('overview')}
                      />
                      <label htmlFor={'overview'}>
                        <i className="fa fa-info-circle" />
                        <span className="text">Overview</span>
                      </label>

                      <input
                        id="schedule"
                        type="radio"
                        name="tabs_dp"
                        className={'tab__input'}
                        checked={activeTab === 'schedule'}
                        onChange={this.handleTabChange('schedule')}
                      />
                      <label htmlFor={'schedule'}>
                        <i className="fa fa-calendar" />
                        <span className="text">Lesson Schedule</span>
                      </label>

                      <section className={activeTab === 'overview' ? 'tab__content tab--active' : 'tab__content'}>
                        <div className="intro__description" dangerouslySetInnerHTML={{ __html: classData.desc }} />
                      </section>

                      <section className={activeTab === 'schedule' ? 'tab__content tab--active' : 'tab__content'}>
                        {classData.class_type === CLASS_TYPE_REGULAR_CLASSES && (
                          <>
                            {Object.keys(lessonsByTerm).map(key => {
                              if (lessonsByTerm.hasOwnProperty(key)) {
                                return (
                                  <Accordion key={key}>
                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                      <div className="h3 title--rubik mb-0">
                                        Term {key} ({lessonsByTerm[key].length} lessons)
                                      </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <div className="w-100">
                                        {lessonsByTerm[key].map(gl => {
                                          return (
                                            <div
                                              className="d-flex justify-content-between align-items-center border-bottom py-2"
                                              key={gl._id}>
                                              <div className="text-large">
                                                <span className="text-muted">{gl.meta.index}:</span> {gl.name}
                                              </div>
                                              <div className="text-muted">
                                                {moment(gl.scheduled_on).format('Do MMM YYYY, dddd')}
                                              </div>
                                            </div>
                                          )
                                        })}
                                      </div>
                                    </AccordionDetails>
                                  </Accordion>
                                )
                              }

                              return null
                            })}
                          </>
                        )}

                        {classData.class_type === CLASS_TYPE_WORKSHOP && (
                          <>
                            {classData.lesson.map(lesson => (
                              <div className="border-bottom mb-3" key={lesson._id}>
                                <div>
                                  <small>{moment(lesson.scheduled_on).format('Do MMM YYYY, dddd')}</small>
                                </div>
                                <h3 className="font-weight-semibold">{lesson.name}</h3>
                              </div>
                            ))}
                          </>
                        )}
                      </section>
                    </div>
                    <ClassPackageSelector onlineClassItem={classData} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseClassDialog)
