import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import { Avatar, Button, Card, CardContent, CircularProgress, Paper } from '@material-ui/core'

import agent from 'agent'
import { GET_EVENT_LIST } from 'constants/actionTypes'
import { APP, TRIAL, FREE_SHARING_VIDEO } from 'routes/routes'
import CTADivider from 'components/misc/cta-divider'
import RegisterToWatchDialog from 'components/tls/dialog-register-to-watch'

const mapStateToProps = state => ({
  token: state.common.token,
  loadingEventList: state.event.loadingEventList,
  eventList: state.event.eventList,
  loadingConfig: state.misc.loadingConfig,
  customConfig: state.misc.customConfig
})

const mapDispatchToProps = dispatch => ({
  getEventList: (filter, levelFilter) =>
    dispatch({ type: GET_EVENT_LIST, payload: agent.Event.getList(filter), filter, levelFilter })
})

const TrialCard = props => {
  return (
    <></>
    // <Card className="bg-light-gray">
    //   <CardContent>
    //     <h2 className="title--rubik text-center">Enjoying the sharing by experts?</h2>
    //     <p className="tls__desc">
    //       Join a free trial lesson for Tenopy's LIVE online tutoring programmes to get the full Tenopy experience!
    //     </p>
    //     <Button
    //       variant="outlined"
    //       size="large"
    //       color="primary"
    //       fullWidth
    //       className="btn-white-bg"
    //       onClick={props.goToTrialPage}>
    //       Get A Free Trial
    //     </Button>
    //   </CardContent>
    // </Card>
  )
}

class TenopyLiveSharing extends React.Component {
  state = {
    activeFilter: 'all',
    selectedEvent: null,
    isJoinSessionDialogOpen: false,
    isVideoDialogOpen: false,
    subjectFilter: '',
    isRegisterToWatchDialogOpen: false
  }

  componentDidMount() {
    this.getTenobitesList('all', true)()
  }

  getTenobitesList = (target, newLoad) => () => {
    let { eventList } = this.props
    let { activeFilter } = this.state
    let offset = activeFilter === target ? eventList.data.length : 0
    if (newLoad) {
      offset = 0
    }
    let targetArray = []
    if (target === 'all') {
      targetArray = ['student', 'parent']
    } else {
      targetArray = [target]
    }

    let filter = {
      target_audience: {
        $in: targetArray
      },
      'playback_url.0': {
        $exists: true
      },
      sortBy: 'scheduled_on',
      order: 'desc',
      max: 15,
      offset
    }
    this.props.getEventList(filter, target)
    this.setState({ activeFilter: target })
  }

  clickWatch = eventData => () => {
    const { token } = this.props

    if (token) {
      this.props.history.push(`${APP}${FREE_SHARING_VIDEO.replace(':eventId', eventData._id)}`)
    } else {
      this.toggleRegisterToWatchDialog(eventData)()
    }
  }

  toggleRegisterToWatchDialog = selectedEvent => () => {
    this.setState({
      selectedEvent,
      isRegisterToWatchDialogOpen: !this.state.isRegisterToWatchDialogOpen
    })
  }

  goToTrialPage = () => {
    this.props.history.push(TRIAL)
  }

  render() {
    let { loadingEventList, eventList } = this.props
    let { activeFilter, selectedEvent, isRegisterToWatchDialogOpen } = this.state

    return (
      <div className="landing bg-white">
        <Helmet>
          <title>Sharing By Experts | Tenopy</title>
          <meta
            name="description"
            content="Enhance your child's reading and writing skills for English, Mathematics & Science at each level with Tenopy free live-sharing sessions by our top experienced teachers. Simply register a Tenopy account to attend and access past sessions in playback videos."
          />
          <meta
            name="keywords"
            content="Free expert's sharing session, free english classes, free online lessons, Online tuition, Online tuition Singapore, Singapore online tuition, exam techniques, exam tips, important subject topics, tough exam questions, playback videos, improve reading skills, improve writting skills, English Paper 1, English Paper 2, Primary school English, experienced online tutors, experienced teachers, playback videos"
          />
        </Helmet>
        <section className="pt-4">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-9">
                <div className="section__header mb-3 text-left">
                  <div
                    className="title title--rubik mb-2"
                    style={{
                      fontSize: '2rem',
                      lineHeight: 1.2,
                      borderLeft: '8px solid #00b96c',
                      paddingLeft: '10px'
                    }}>
                    Sharing on insights & tips by expert educators
                  </div>
                </div>

                <div className="d-md-none d-block mt-3">
                  <div className="label text-small text-muted mb-2">Description</div>
                  <p className="tls__desc">
                    Educators share on how students can improve in their school subjects and prepare for their exams
                    while informing parents how to help their child and be involved in their learning.
                  </p>
                </div>

                <div className="row my-3">
                  <div className="col-12 my-3">
                    <span
                      className={`tenobites__nav-item ${activeFilter === 'all' ? 'active' : ''}`}
                      onClick={this.getTenobitesList('all')}>
                      All
                    </span>
                    <span
                      className={`tenobites__nav-item ${activeFilter === 'student' ? 'active' : ''}`}
                      onClick={this.getTenobitesList('student')}>
                      For Students
                    </span>
                    <span
                      className={`tenobites__nav-item ${activeFilter === 'parent' ? 'active' : ''}`}
                      onClick={this.getTenobitesList('parent')}>
                      For Parents
                    </span>
                  </div>
                  <div className="col-12">
                    {loadingEventList && (
                      <div className="text-center my-2">
                        <CircularProgress size={50} />
                      </div>
                    )}
                    {eventList.data.map((eventData, index) => {
                      let eventName = eventData.name
                      if (eventData.meta?.series?.name) {
                        eventName = `${eventData.meta?.series?.name} Ep ${eventData.meta?.series?.order}: ${eventData.name}`
                      }

                      return (
                        <Paper elevation={3} className="px-3 mb-3" key={index}>
                          <div className="row">
                            <div className={`col-md-auto col-12 py-3 ${isMobile ? 'bg-light-gray' : ''}`}>
                              <Avatar src={eventData.cover_photo} className="size-150 avatar-shadow mx-auto" />
                            </div>
                            <div className="col py-3">
                              <div className="d-flex flex-wrap manage-margin">
                                {eventData.meta.keywords.map((keyword, index) => (
                                  <span className="tag info-tag--outline text-capitalize" key={index}>
                                    {keyword}
                                  </span>
                                ))}
                              </div>

                              <h2 className="title--rubik mb-0">{eventName}</h2>
                              <div className="text-muted text-small mb-3">
                                by&nbsp;
                                {eventData.host_by.salute ?? 'Teacher'}&nbsp;
                                {eventData.host_by.name}
                              </div>
                              <div className="label text-small text-muted mb-2">Description</div>
                              <p className="tls__desc" dangerouslySetInnerHTML={{ __html: eventData.desc }} />
                              <div className="text-right">
                                <Button variant="contained" color="primary" onClick={this.clickWatch(eventData)}>
                                  Watch Video
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Paper>
                      )
                    })}

                    {eventList.count > eventList.data.length && (
                      <div className="text-right">
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={loadingEventList}
                          onClick={this.getTenobitesList(activeFilter, eventList.filter.offset)}>
                          Load more {loadingEventList && <CircularProgress size={20} className="ml-2" />}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-12">
                <div className="d-md-block d-none">
                  <div className="label text-small text-muted mb-2">Description</div>
                  <p className="tls__desc">
                    Educators share on how students can improve in their school subjects and prepare for their exams
                    while informing parents how to help their child and be involved in their learning.
                  </p>
                </div>
                {/* <TrialCard goToTrialPage={this.goToTrialPage} /> */}
              </div>
            </div>
          </div>

          <RegisterToWatchDialog
            isOpen={isRegisterToWatchDialogOpen}
            close={this.toggleRegisterToWatchDialog()}
            eventData={selectedEvent}
          />
        </section>

        {/* {!isMobile && <CTADivider styleOpt="plain" />} */}
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TenopyLiveSharing))
