import React from 'react'
import { connect } from 'react-redux'
import { deviceType } from 'react-device-detect'
import { Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import moment from 'moment'

import agent from 'agent'
import { GET_LESSON_LINK, RECORD_USER_ACTION } from 'constants/actionTypes'
import CountdownDialog from './dialog-countdown'
import LessonStartingDialog from './dialog-lesson-starting'

const mapStateToProps = state => ({
  loadingLessonLink: state.lessons.loadingLessonLink,
  lessonLink: state.lessons.lessonLink,
  token: state.common.token
})

const mapDispatchToProps = dispatch => ({
  getLessonLink: lessonId =>
    dispatch({ type: GET_LESSON_LINK, payload: agent.Lessons.getLiveLink(lessonId), lessonId }),
  trackAction: reqPayload => dispatch({ type: RECORD_USER_ACTION, payload: agent.Misc.recordAction(reqPayload) })
})

class JoinLessonButton extends React.Component {
  state = {
    isCountdownDialogOpen: false,
    isLessonStartingDialogOpen: false,
    joinLessonUrl: ''
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadingLessonLink && !this.props.loadingLessonLink) {
      const { lessonLink } = this.props
      if (lessonLink?.error?.message) {
        this.setState({ isLessonStartingDialogOpen: false })
      } else {
        this.setState({ joinLessonUrl: lessonLink.url })
        window.open(lessonLink.url)
      }
    }
  }

  joinLesson = () => {
    const { lessonData, bypassCountdown } = this.props
    this.props.trackAction({
      type: 'join_lesson',
      device_type: deviceType
    })
    const proceed =
      ['local', 'staging'].includes(process.env.REACT_APP_ENVIRONMENT) ||
      bypassCountdown ||
      moment() > moment(lessonData.scheduled_on).subtract(5, 'minutes')
    if (proceed) {
      this.proceedToLesson()
    } else {
      this.toggleCountdownDialog()
    }
  }

  proceedToLesson = () => {
    const { lessonData, token } = this.props
    switch (lessonData.hosting_platform) {
      case 'Zoom':
        this.props.getLessonLink(this.props.lessonData._id)
        break

      case 'Tenopy Classroom':
        const joinLessonUrl = `${process.env.REACT_APP_TENOPY_CLASSROOM_URL}/preload?lesson=${lessonData._id}&token=${token}`
        this.setState({ joinLessonUrl })
        window.open(joinLessonUrl)
        break

      default:
        break
    }
    this.setState({ isLessonStartingDialogOpen: true })
  }

  toggleCountdownDialog = () => {
    this.setState({
      isCountdownDialogOpen: !this.state.isCountdownDialogOpen
    })
  }

  toggleLessonStartingDialog = () => {
    this.setState({
      isLessonStartingDialogOpen: !this.state.isLessonStartingDialogOpen
    })
  }

  render() {
    const { lessonData, loadingLessonLink, lessonLink } = this.props
    const { isCountdownDialogOpen, isLessonStartingDialogOpen, joinLessonUrl } = this.state

    return (
      <div className="my-3">
        <div className="d-flex align-items-center manage-margin">
          <Button
            variant="contained"
            color="primary"
            onClick={this.joinLesson}
            disabled={false}
            style={{ minWidth: '130px' }}>
            Join Lesson
          </Button>
          {lessonLink?.id === lessonData._id && lessonLink?.error?.message && (
            <Alert severity="error" className="mb-0">
              {lessonLink?.error?.message}
            </Alert>
          )}
        </div>

        <CountdownDialog
          isOpen={isCountdownDialogOpen}
          close={this.toggleCountdownDialog}
          proceedToLesson={this.proceedToLesson}
          startTime={lessonData?.scheduled_on}
          title="Lesson starts in"
        />

        <LessonStartingDialog
          isOpen={isLessonStartingDialogOpen}
          close={this.toggleLessonStartingDialog}
          loading={loadingLessonLink}
          lessonUrl={joinLessonUrl}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(JoinLessonButton)
