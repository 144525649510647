import React from 'react'
import { CircularProgress } from '@material-ui/core'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { calculateQuote } from 'utils/cart'

let CartBreakdown = props => {
  const { token, loading, cartQuote, cartItems, cartClasses } = props

  if (token) {
    if (loading || !cartQuote) {
      return (
        <div className="p-3">
          <div className="text-center">
            <CircularProgress color={'primary'} size={20} />
          </div>
        </div>
      )
    }

    if (cartQuote === 'empty_cart') {
      return null
    }

    const { quote, original_price, promo_code_discount, payment_rule_discount, credit_redeem, quote_detail } = cartQuote
    let discountCodeApplied
    if (promo_code_discount > 0) {
      let discountCodeArr = _.compact(_.map(quote_detail, 'discount.code'))
      if (discountCodeArr.length > 0) {
        discountCodeApplied = discountCodeArr[0]
      }
    }

    return (
      <div className="p-3 text-right">
        {quote_detail.map((item, index) => {
          return (
            <div key={index}>
              <div className="row">
                <div className="col-8">{item.class_obj.name}</div>
                <div className="col-4">${parseFloat(item.original_price).toFixed(2)}</div>
              </div>
            </div>
          )
        })}

        {original_price > quote && (
          <div className="row border-top">
            <div className="col-8">Subtotal:</div>
            <div className="col-4">${parseFloat(original_price).toFixed(2)}</div>
          </div>
        )}

        {promo_code_discount > 0 && (
          <div className="row">
            <div className="col-8">Discount Code ({discountCodeApplied}):</div>
            <div className="col-4">-${parseFloat(promo_code_discount).toFixed(2)}</div>
          </div>
        )}

        {payment_rule_discount > 0 && (
          <div className="row">
            <div className="col-8">Discount:</div>
            <div className="col-4">-${parseFloat(payment_rule_discount).toFixed(2)}</div>
          </div>
        )}

        {credit_redeem > 0 && (
          <div className="row">
            <div className="col-8">Credit:</div>
            <div className="col-4">- ${parseFloat(credit_redeem).toFixed(2)}</div>
          </div>
        )}

        <div className="row border-top pt-2">
          <div className="col-8">
            <div className="font-weight-semibold">Total:</div>
          </div>
          <div className="col-4">
            <h2 className="mb-0">${parseFloat(quote).toFixed(2)}</h2>
          </div>
        </div>
      </div>
    )
  } else {
    if (cartItems.length > 0 && !cartClasses) {
      return (
        <div className="p-3">
          <div className="text-center">
            <CircularProgress color={'primary'} size={20} />
          </div>
        </div>
      )
    }

    let total = 0
    cartItems.forEach(item => {
      let classData = _.find(cartClasses, o => {
        return o._id === item.item_id
      })
      let itemQuote = calculateQuote(classData.lesson, classData.rate, item.start_date, item.end_date, item.item_id)
      total += itemQuote.quote
    })

    return (
      <div className="p-3 text-right">
        <div className="row">
          <div className="col-8">
            <div className="font-weight-semibold">Total:</div>
          </div>
          <div className="col-4">
            <h2 className="mb-0">${parseFloat(total).toFixed(2)}</h2>
          </div>
        </div>
      </div>
    )
  }
}

CartBreakdown.propTypes = {
  calculatedPrice: PropTypes.number,
  loading: PropTypes.bool
}

export default CartBreakdown
