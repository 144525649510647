import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Avatar, Menu, MenuItem } from '@material-ui/core'

import { LOGOUT } from 'constants/actionTypes'
import { STUDENT } from 'constants/userTypes'
import { USER_PROFILE } from 'routes/routes'
import { getInitials } from 'utils/misc'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser
})

const mapDispatchToProps = dispatch => ({
  userSignOut: () => dispatch({ type: LOGOUT })
})

class UserInfo extends React.Component {
  state = {
    anchorEl: null,
    open: false
  }

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget })
  }

  handleRequestClose = () => {
    this.setState({ open: false })
  }

  render() {
    const { currentUser } = this.props

    if (currentUser) {
      return (
        <div className="user-profile d-flex flex-row align-items-center">
          <Avatar className="user-avatar bg-amber text-uppercase">
            {getInitials(currentUser.name ? currentUser.name : currentUser.email)}
          </Avatar>

          <div className="user-detail">
            <h4 className="user-name" onClick={this.handleClick}>
              {currentUser.name ? currentUser.name : currentUser.email}
              <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
            </h4>
          </div>

          <Menu
            className="user-info"
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onClose={this.handleRequestClose}
            PaperProps={{
              style: {
                width: 120,
                paddingTop: 0,
                paddingBottom: 0
              }
            }}>
            {currentUser && currentUser._type === STUDENT && (
              <MenuItem
                onClick={() => {
                  this.handleRequestClose()
                  this.props.history.push(USER_PROFILE)
                }}>
                <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
                Profile
              </MenuItem>
            )}

            <MenuItem
              onClick={() => {
                this.handleRequestClose()
                this.props.userSignOut()
              }}>
              <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
              Logout
            </MenuItem>
          </Menu>
        </div>
      )
    }

    return null
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserInfo))
