import React from 'react'
import { isMobile } from 'react-device-detect'
import StudyWithUsCard from './StudyWithUsCard'
class ServicesCard extends React.Component {
  render() {
    const {
      heading,
      subheading,
      headingImage,
      marginToCut,
      title1,
      title2,
      title3,
      description1,
      description2,
      description3,
      image1,
      image2,
      image3,
      color,
      marginForText,
      marginForText1,
      marginForText2,
      marginForText3
    } = this.props

    return (
      <>
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-12 col-lg-6">
              <div className="align-self-end">
                <div className="section__header_landing_title">
                  <div
                    className="header mb-4 text-left"
                    style={{
                      fontFamily: "'Libre Baskerville', sans-serif"
                    }}>
                    {heading}
                  </div>
                  <div
                    className="paragraph mb-4 text-left"
                    style={{
                      fontFamily: "'Alegreya Sans', sans-serif",
                      paddingRight: isMobile ? '0px' : marginToCut
                    }}>
                    {subheading}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-md-1"></div> */}
            <div className="col-12 col-md-6 justify-content-center">
              {/* {isMobile ? (
              <div className="col-12">
                <div className="d-flex flex-row justify-content-center mb-3">
                  <img width="45%" alt={heading} src={headingImage} />
                </div>
              </div>
            ) : ( */}
              <div className="col-auto align-self-end">
                <img
                  style={{ boxShadow: `7px 10px ${color}`, borderRadius: '8px', marginLeft: isMobile ? '' : '6.55vh' }}
                  src={headingImage}
                  alt={heading}
                  width={isMobile ? '100%' : '90%'}
                />
              </div>
              {/* )} */}
            </div>
          </div>
          <div
            className="row align-items-center justify-content-around"
            style={{ marginTop: '7em', marginLeft: '-25px' }}>
            <div className="container">
              <div className="row">
                <StudyWithUsCard
                  title={title1}
                  description={description1}
                  image={image1}
                  marginForText1={marginForText1}
                />
                <StudyWithUsCard
                  title={title2}
                  description={description2}
                  image={image2}
                  marginForText2={marginForText2}
                />
                <StudyWithUsCard
                  title={title3}
                  description={description3}
                  image={image3}
                  marginForText3={marginForText3}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default ServicesCard
