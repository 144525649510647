import React from 'react'
import { Button, Grid, Dialog, DialogContent, DialogActions } from '@material-ui/core'
import LessonMeta from 'components/shared/Classes/LessonMeta/lesson-meta'
import PropTypes from 'prop-types'

class DialogEditLessonMeta extends React.Component {
  constructor(props) {
    super()

    this.handleChange = e => {
      this.setState({ [e.target.name]: e.target.value })
    }

    this.handleCheckboxChange = (e, checked) => {
      this.setState({ [e.target.name]: checked })
    }
    let { lessonData } = props
    let lesson = lessonData
    let lessonId = lesson._id

    this.state = {
      lessonId,
      punctuality: lesson.lesson_meta?.punctuality ?? '---',
      cancelledClass: lesson.lesson_meta?.cancelledClass ?? '---',
      engagementParticipation: lesson.lesson_meta?.engagement?.engagementParticipation ?? '---',
      engagementDistracted: lesson.lesson_meta?.engagement?.engagementDistracted ?? '---',
      engagementTiredBored: lesson.lesson_meta?.engagement?.engagementTiredBored ?? '---',
      engagementMotivate: lesson.lesson_meta?.engagement?.engagementMotivate ?? '---',
      engagementAskQuestions: lesson.lesson_meta?.engagement?.engagementAskQuestions ?? '---',
      lesson_topic: lesson.lesson_meta?.lesson_topic ?? '',
      skillMasteryAnswerQuestion: lesson.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion ?? '---',
      skillMasteryConfused: lesson.lesson_meta?.skill_mastery?.skillMasteryConfused ?? '---',
      skillMasteryShowUnderstanding: lesson.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding ?? '---',
      skillMasteryParticipative: lesson.lesson_meta?.skill_mastery?.skillMasteryParticipative ?? '---',
      skillMasteryUnderstandConcept: lesson.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept ?? '---',
      homeworkAssignment: lesson.lesson_meta?.homework?.homeworkAssignment ?? '---',
      homeworkSubmission: lesson.lesson_meta?.homework?.homeworkSubmission ?? '---',
      homeworkSubmissionPastPaper: lesson.lesson_meta?.homework?.homeworkSubmissionPastPaper ?? false,
      homeworkSubmissionComment: lesson.lesson_meta?.homework?.homeworkSubmissionComment ?? '',
      revision_notes: lesson.lesson_meta?.revision_notes ?? '---',
      commentStudentView: lesson.lesson_meta?.comment?.commentStudentView ?? false,
      commentParentView: lesson.lesson_meta?.comment?.commentParentView ?? false,
      comment: lesson.lesson_meta?.comment?.comment ?? '',
      recommendationLesson: lesson.lesson_meta?.recommendation?.recommendationLesson ?? false,
      recommendationConsult: lesson.lesson_meta?.recommendation?.recommendationConsult ?? false,
      recommendationOthers: lesson.lesson_meta?.recommendation?.recommendationOthers ?? ''
    }
  }

  saveLesson = () => {
    const {
      lessonId,
      punctuality,
      cancelledClass,
      engagementParticipation,
      engagementDistracted,
      engagementTiredBored,
      engagementMotivate,
      engagementAskQuestions,
      lesson_topic,
      skillMasteryAnswerQuestion,
      skillMasteryConfused,
      skillMasteryShowUnderstanding,
      skillMasteryParticipative,
      skillMasteryUnderstandConcept,
      homeworkAssignment,
      homeworkSubmission,
      homeworkSubmissionPastPaper,
      homeworkSubmissionComment,
      revision_notes,
      commentStudentView,
      commentParentView,
      comment,
      recommendationLesson,
      recommendationConsult,
      recommendationOthers
    } = this.state

    let lessonData = {
      lessonId,
      lesson_meta: {
        punctuality,
        cancelledClass,
        engagement: {
          engagementParticipation,
          engagementDistracted,
          engagementTiredBored,
          engagementMotivate,
          engagementAskQuestions
        },
        lesson_topic,
        skill_mastery: {
          skillMasteryAnswerQuestion,
          skillMasteryConfused,
          skillMasteryShowUnderstanding,
          skillMasteryParticipative,
          skillMasteryUnderstandConcept
        },
        homework: {
          homeworkAssignment,
          homeworkSubmission,
          homeworkSubmissionPastPaper,
          homeworkSubmissionComment
        },
        revision_notes,
        comment: {
          commentStudentView,
          commentParentView,
          comment
        },
        recommendation: {
          recommendationLesson,
          recommendationConsult,
          recommendationOthers
        }
      }
    }
    this.props.onSave(lessonData)
    this.props.toggle()
  }

  render() {
    const { isOpen, toggle, lessonData } = this.props

    const {
      punctuality,
      cancelledClass,
      engagementParticipation,
      engagementDistracted,
      engagementTiredBored,
      engagementMotivate,
      engagementAskQuestions,
      lesson_topic,
      skillMasteryAnswerQuestion,
      skillMasteryConfused,
      skillMasteryShowUnderstanding,
      skillMasteryParticipative,
      skillMasteryUnderstandConcept,
      homeworkAssignment,
      homeworkSubmission,
      homeworkSubmissionPastPaper,
      homeworkSubmissionComment,
      revision_notes,
      commentStudentView,
      commentParentView,
      comment,
      recommendationLesson,
      recommendationConsult,
      recommendationOthers
    } = this.state

    return (
      <Dialog open={isOpen} maxWidth="md" fullWidth>
        <DialogContent>
          <LessonMeta
            handleChange={this.handleChange}
            handleCheckboxChange={this.handleCheckboxChange}
            punctuality={punctuality}
            cancelledClass={cancelledClass}
            engagementAskQuestions={engagementAskQuestions}
            engagementDistracted={engagementDistracted}
            engagementMotivate={engagementMotivate}
            engagementParticipation={engagementParticipation}
            engagementTiredBored={engagementTiredBored}
            lesson_topic={lesson_topic}
            skillMasteryAnswerQuestion={skillMasteryAnswerQuestion}
            skillMasteryConfused={skillMasteryConfused}
            skillMasteryShowUnderstanding={skillMasteryShowUnderstanding}
            skillMasteryParticipative={skillMasteryParticipative}
            skillMasteryUnderstandConcept={skillMasteryUnderstandConcept}
            homeworkAssignment={homeworkAssignment}
            homeworkSubmission={homeworkSubmission}
            homeworkSubmissionPastPaper={homeworkSubmissionPastPaper}
            homeworkSubmissionComment={homeworkSubmissionComment}
            commentStudentView={commentStudentView}
            revision_notes={revision_notes}
            commentParentView={commentParentView}
            comment={comment}
            recommendationLesson={recommendationLesson}
            recommendationConsult={recommendationConsult}
            recommendationOthers={recommendationOthers}
          />
        </DialogContent>

        <DialogActions>
          <Grid container justifyContent="space-between">
            <Button variant="contained" onClick={toggle}>
              Close without saving
            </Button>

            <Button variant="contained" color="primary" onClick={this.saveLesson}>
              Save Information
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}

DialogEditLessonMeta.propTypes = {
  onSave: PropTypes.func
}

export default DialogEditLessonMeta
