import React, { Component } from 'react'
import { Link, NavLink, withRouter } from 'react-router-dom'
import { Flipper } from 'react-flip-toolkit'
import { AccountCircle } from '@material-ui/icons'
import { isMobile } from 'react-device-detect'

import {
  APP,
  ROOT,
  LOGIN,
  SIGNUP,
  ALL_CLASSES,
  CLASS_DETAILS,
  ACCOUNT_ACTIVATION,
  TEAM,
  CONTACT,
  SERVICES
} from 'routes/routes'
import Navbar from './navbar'
import NavbarItem from './navbar/navbar-item'
import DropdownContainer from './dropdown-container'
import CatalogueDropdown from './dropdown-contents/catalogue'
import AboutDropdown from './dropdown-contents/about'

const navbarConfig = [
  { title: '', dropdown: null },
  { title: 'Class Catalogue', dropdown: CatalogueDropdown },
  { title: 'About', dropdown: AboutDropdown }
]

class AnimatedNavbar extends Component {
  state = {
    activeIndices: [],
    color: 'transparent',
    isHover: false,
    isServicesColorHover: false
  }

  handleMouseEnter = () => {
    this.setState({ isHover: true })
  }

  handleMouseLeave = () => {
    this.setState({ isHover: false, isServicesColorHover: false })
  }

  handleMouseServiceEnter = i => {
    if (this.animatingOutTimeout) {
      clearTimeout(this.animatingOutTimeout)
      this.resetDropdownState(i)
      return
    }
    if (this.state.activeIndices[this.state.activeIndices.length - 1] === i) return

    this.setState(prevState => ({
      activeIndices: prevState.activeIndices.concat(i),
      animatingOut: false,
      isServicesColorHover: true
    }))
  }

  listenScrollEvent = e => {
    if (window.scrollY > 100) {
      this.setState({ color: 'white' })
    } else {
      this.setState({ color: 'transparent' })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }
  // https://stackoverflow.com/questions/48458832/reactjs-change-color-of-element-on-scroll-and-when-certain-position-is-reached

  resetDropdownState = i => {
    this.setState({
      activeIndices: typeof i === 'number' ? [i] : [],
      animatingOut: false
    })
    delete this.animatingOutTimeout
  }

  onMouseEnter = i => {
    if (this.animatingOutTimeout) {
      clearTimeout(this.animatingOutTimeout)
      this.resetDropdownState(i)
      return
    }
    if (this.state.activeIndices[this.state.activeIndices.length - 1] === i) return

    this.setState(prevState => ({
      activeIndices: prevState.activeIndices.concat(i),
      animatingOut: false
    }))
  }

  onMouseLeave = () => {
    this.setState({
      animatingOut: true,
      isServicesColorHover: false
    })
    this.animatingOutTimeout = setTimeout(this.resetDropdownState, this.props.duration)
  }

  goToPage = dest => () => {
    this.props.history.push(dest)
  }

  redirectToLoginRegisterPage = intent => {
    let redirectURL = intent === 'Login' ? LOGIN : SIGNUP
    let currentLocation = this.props.location.pathname
    let currentQuery = this.props.location.search
    if (currentLocation.startsWith(ALL_CLASSES) || currentLocation.startsWith(CLASS_DETAILS)) {
      redirectURL = `${redirectURL}?next=${APP}${currentLocation}`
    } else if (currentQuery) {
      if (currentLocation !== ACCOUNT_ACTIVATION) {
        redirectURL = `${redirectURL}${currentQuery}`
      }
    }
    this.props.history.push(redirectURL)
  }

  render() {
    const { duration, token, pathname, classCategories } = this.props
    const { color, isHover, isServicesColorHover } = this.state
    let CurrentDropdown
    let PrevDropdown
    let direction

    const currentIndex = this.state.activeIndices[this.state.activeIndices.length - 1]
    const prevIndex =
      this.state.activeIndices.length > 1 && this.state.activeIndices[this.state.activeIndices.length - 2]

    if (typeof currentIndex === 'number') {
      if (navbarConfig[currentIndex].dropdown) {
        CurrentDropdown = navbarConfig[currentIndex].dropdown
      }
    }
    if (typeof prevIndex === 'number') {
      PrevDropdown = navbarConfig[prevIndex].dropdown
      direction = currentIndex > prevIndex ? 'right' : 'left'
    }

    // const userType = currentUser ? currentUser._type : ''
    // let userAccess = userType ? USER_NAVIGATION_ACCESS[userType] : []

    return (
      <header style={{ backgroundColor: '#fff' }}>
        {/* if not fixed-top, wont show in homepage, if fixed top, other page announcement hidden */}
        {/*  <nav className="navbar fixed-top navbar-expand-md" */}
        <nav
          className={`${window.scrollY > 100 ? 'fixed-top' : 'mt-4'} navbar navbar-expand-md nav-color-half`}
          style={{
            backgroundColor: this.props.location.pathname === '/' && window.scrollY < 100 ? color : 'white',
            zIndex: 99999
          }}>
          <div className="d-flex align-items-center" style={{ marginLeft: isMobile ? '0px' : '32px' }}>
            <Link to={ROOT} className="navbar-brand">
              <img
                src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/logo/epa_logo.svg"
                height={window.scrollY > 100 ? '50' : '70'}
                alt="EPA logo"
              />
            </Link>

            <Flipper flipKey={currentIndex} spring={duration === 300 ? 'noWobble' : { stiffness: 10, damping: 10 }}>
              <Navbar onMouseLeave={this.onMouseLeave}>
                {/* <NavbarItem
                  title="Class Catalogue"
                  index={1}
                  active={pathname.startsWith('/online-class')}
                  expanded={currentIndex === 1}
                  onMouseEnter={this.onMouseEnter}
                  noLeftPadding={true}>
                  {currentIndex === 1 && (
                    <DropdownContainer
                      direction={direction}
                      animatingOut={this.state.animatingOut}
                      duration={duration}
                      additionalPixel={24}>
                      <CurrentDropdown
                        setOnlineClassFilter={this.props.setOnlineClassFilter}
                        goToPage={this.goToPage}
                        exitDropdown={this.onMouseLeave}
                        classCategories={classCategories}
                        onMouseLeave={this.onMouseLeave}
                      />
                      {PrevDropdown && <PrevDropdown />}
                    </DropdownContainer>
                  )}
                </NavbarItem> */}

                {/* <NavbarItem
                  title="Home"
                  index={0}
                  active={pathname.startsWith('/home')}
                  onMouseEnter={this.onMouseEnter}
                  linkOnly={true}
                  goToPage={this.goToPage(ROOT)}
                /> */}

                <NavbarItem
                  title="Our Services"
                  index={1}
                  goToPage={this.goToPage(SERVICES)}
                  active={pathname.startsWith('/services')}
                  expanded={currentIndex === 1}
                  onMouseEnter={this.handleMouseServiceEnter}
                  onMouseLeave={this.handleMouseLeave}
                  isServicesColorHover={isServicesColorHover}
                  noLeftPadding={true}>
                  {currentIndex === 1 && (
                    <DropdownContainer
                      direction={direction}
                      animatingOut={this.state.animatingOut}
                      duration={duration}
                      additionalPixel={24}>
                      <CurrentDropdown
                        setOnlineClassFilter={this.props.setOnlineClassFilter}
                        goToPage={this.goToPage}
                        exitDropdown={this.onMouseLeave}
                        classCategories={classCategories}
                        onMouseLeave={this.onMouseLeave}
                      />
                      {PrevDropdown && <PrevDropdown />}
                    </DropdownContainer>
                  )}
                </NavbarItem>

                <NavbarItem
                  title="Our Team"
                  index={0}
                  active={pathname.startsWith('/team')}
                  onMouseEnter={this.onMouseEnter}
                  linkOnly={true}
                  goToPage={this.goToPage(TEAM)}
                />

                {/* <NavbarItem
                  title="Our mission"
                  index={0}
                  active={pathname.startsWith('/our-mission')}
                  onMouseEnter={this.onMouseEnter}
                  linkOnly={true}
                  goToPage={this.goToPage(OUR_MISSION)}
                /> */}

                {/* <NavbarItem
                  title="News"
                  index={0}
                  active={pathname.startsWith('/news')}
                  onMouseEnter={this.onMouseEnter}
                  linkOnly={true}
                  goToPage={this.goToPage(NEWS)}
                /> */}

                <NavbarItem
                  title="Contact Us"
                  index={0}
                  active={pathname.startsWith('/contact')}
                  onMouseEnter={this.onMouseEnter}
                  linkOnly={true}
                  goToPage={this.goToPage(CONTACT)}
                />
              </Navbar>
            </Flipper>
          </div>

          <div className="d-flex align-items-center">
            {!!token ? (
              <NavLink to={APP} className={'px-3'}>
                <AccountCircle />
              </NavLink>
            ) : (
              <>
                {/* <div
                  className={`navbar-toplink px-3 mr-0 ${pathname.startsWith('/signup') ? 'is_active' : ''}`}
                  onClick={() => {
                    this.redirectToLoginRegisterPage('Signup')
                  }}>
                  Sign Up
                </div> */}
                {/* <div
                  className={`info-description-black navbar-toplink px-3 mr-0`}
                  style={{ fontWeight: 700 }}
                  onClick={() => {}}>
                  EPA Portal
                </div> */}
                <div
                  className={`info-description-white navbar-toplink px-4 mr-5`}
                  onMouseEnter={this.handleMouseEnter}
                  onMouseLeave={this.handleMouseLeave}
                  style={{
                    zIndex: 'auto',
                    backgroundColor: isHover ? '#CC5102' : '#FD6502',
                    borderRadius: '4px',
                    fontWeight: 700
                  }}
                  onClick={() => {
                    this.redirectToLoginRegisterPage('Login')
                  }}>
                  Log In
                </div>
              </>
            )}
            {/* {(!token || userAccess.includes('cart')) && (
              <div className="border-left px-3 pointer">
                <NavLink to={SHOPPING_CART}>
                  <Badge badgeContent={shoppingCart.item.length} color="primary">
                    <ShoppingCart className={`${pathname.startsWith('/cart') ? 'text-primary' : 'text-grey'}`} />
                  </Badge>
                </NavLink>
              </div>
            )} */}
          </div>
        </nav>
      </header>
    )
  }
}

export default withRouter(AnimatedNavbar)
