import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Popper, MenuItem, Grow, Paper, ClickAwayListener, MenuList, CircularProgress } from '@material-ui/core'
import { AddShoppingCart, KeyboardArrowDown } from '@material-ui/icons'
import _ from 'lodash'

import agent from 'agent'
import {
  ADD_CART_ITEM,
  ADD_CART_ITEM_NOAUTH,
  CLEAR_PACKAGE_QUOTE,
  REMOVE_CART_ITEM,
  REMOVE_CART_ITEM_NOAUTH
} from 'constants/actionTypes'
import { APP, SHOPPING_CART } from 'routes/routes'
import { roundPrice } from 'utils/merchandise'
import { formatCartItem } from 'utils/cart'

import { Badge } from 'reactstrap'

const mapStateToProps = state => ({
  lessonPackages: state.shoppingCart.lessonPackages,
  shoppingCart: state.shoppingCart.shoppingCart,
  token: state.common.token,
  addingCartItem: state.shoppingCart.addingCartItem,
  removingCartItem: state.shoppingCart.removingCartItem
})

const mapDispatchToProps = dispatch => ({
  addToCart: item => dispatch({ type: ADD_CART_ITEM, payload: agent.ShoppingCart.addItem(item), cartItem: item }),
  addToLocalCart: item => dispatch({ type: ADD_CART_ITEM_NOAUTH, payload: item }),
  removeFromCart: item_id => dispatch({ type: REMOVE_CART_ITEM, payload: agent.ShoppingCart.removeItem(item_id) }),
  removeFromLocalCart: class_id => dispatch({ type: REMOVE_CART_ITEM_NOAUTH, payload: class_id }),
  clearPackageQuote: class_id => dispatch({ type: CLEAR_PACKAGE_QUOTE, class_id: class_id })
})

class ClassPackageSelector extends React.Component {
  state = {
    expandPackageSelection: false
  }

  componentDidUpdate(prevProps) {
    let { onlineClassItem } = this.props

    if (!_.isEqual(prevProps.onlineClassItem._id, onlineClassItem._id)) {
      this.props.clearPackageQuote(prevProps.onlineClassItem._id)
    }
  }

  togglePackageSelection = () => {
    this.setState(state => ({ expandPackageSelection: !state.expandPackageSelection }))
  }

  handleClose = lessonPackage => event => {
    if (this.anchorEl.contains(event.target)) {
      return
    }

    if (!!lessonPackage) {
      this.setState({ expandPackageSelection: false })
      this.addToCart(lessonPackage)
    } else {
      this.setState({ expandPackageSelection: false })
    }
  }

  addToCart = lessonPackage => {
    let { onlineClassItem } = this.props

    let formattedItem = formatCartItem(onlineClassItem, 'Class', lessonPackage.packageItem.options)
    if (!!this.props.token) {
      this.props.addToCart(formattedItem)
    } else {
      this.props.addToLocalCart(formattedItem)
    }
  }

  removeItem = (item, cartItem) => () => {
    if (!!this.props.token) {
      this.props.removeFromCart(cartItem._id)
    } else {
      this.props.removeFromLocalCart(item._id)
    }
  }

  render() {
    const { expandPackageSelection } = this.state
    const { onlineClassItem, shoppingCart, token, variant, lessonPackages, addingCartItem, removingCartItem } =
      this.props

    if (addingCartItem || removingCartItem) {
      return (
        <div className="manage-margin text-center">
          <CircularProgress size={25} />
        </div>
      )
    }

    if (!!onlineClassItem) {
      let inCart = _.find(shoppingCart.item, ci => {
        return ci.item_id === onlineClassItem._id
      })

      if (!!inCart) {
        return (
          <div className="d-flex align-items-center w-100">
            <Button
              color="primary"
              variant="contained"
              className="px-5"
              fullWidth
              onClick={() => {
                this.props.history.push(token ? `${APP}${SHOPPING_CART}` : SHOPPING_CART)
              }}>
              See it in your shopping cart
            </Button>
            <Button variant="text" className="text-danger ml-3" onClick={this.removeItem(onlineClassItem, inCart)}>
              Remove
            </Button>
          </div>
        )
      } else {
        if (!!lessonPackages && lessonPackages.hasOwnProperty(onlineClassItem._id)) {
          if (lessonPackages[onlineClassItem._id] === 'no_more_lesson_to_purchase') {
            return (
              <h4 className="text-center bg-success py-2 px-4 font-weight-semibold text-white">
                You have purchased all available lessons
              </h4>
            )
          } else {
            let packages = lessonPackages[onlineClassItem._id].item
            return (
              <div className={variant === 'button' ? '' : 'text-right'}>
                {variant !== 'button' && <span className="mr-2 font-weight-semibold">Add To Cart:</span>}
                <Button
                  ref={node => {
                    this.anchorEl = node
                  }}
                  aria-owns={expandPackageSelection ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={this.togglePackageSelection}
                  variant="contained"
                  color="primary"
                  fullWidth={variant === 'button'}>
                  <AddShoppingCart className="mr-2" />
                  {packages[0].packageItem.title} : &nbsp;&nbsp;&nbsp; ${roundPrice(packages[0].quote.quote)}
                  {packages[0].quote.original_price > packages[0].quote.quote && (
                    <del className="text-danger ml-2">
                      <small className="text-white">${roundPrice(packages[0].quote.original_price)}</small>
                    </del>
                  )}
                  <KeyboardArrowDown className="ml-2" />
                </Button>
                <Popper
                  open={expandPackageSelection}
                  anchorEl={this.anchorEl}
                  transition
                  disablePortal
                  style={{ zIndex: 1 }}>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      id="menu-list-grow"
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                      <Paper>
                        <ClickAwayListener onClickAway={this.handleClose()}>
                          <MenuList>
                            {packages.map((l, index) => {
                              return (
                                <MenuItem key={index} onClick={this.handleClose(l)}>
                                  <div className="row align-items-center">
                                    <div className="col">
                                      <div className="font-weight-semibold">{l.packageItem.title}</div>
                                      <div>
                                        {l.packageItem.dateRange} ({l.packageItem.count} lessons)
                                      </div>
                                    </div>
                                    <div className="col text-right">
                                      ${roundPrice(l.quote.quote)}
                                      {l.quote.original_price > l.quote.quote && (
                                        <>
                                          <del className="text-danger ml-1">
                                            <small className="text-muted">${roundPrice(l.quote.original_price)}</small>
                                          </del>
                                          <Badge className="ml-3 mb-0 text-white" color="warning">
                                            {Math.round(
                                              ((l.quote.original_price - l.quote.quote) / l.quote.original_price) * 100
                                            )}
                                            % OFF
                                          </Badge>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </MenuItem>
                              )
                            })}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            )
          }
        } else {
          return (
            <div className="my-5 text-center">
              <CircularProgress size={40} color="primary" />
            </div>
          )
        }
      }
    } else {
      return (
        <div className="my-5 text-center">
          <CircularProgress size={40} color="primary" />
        </div>
      )
    }
  }
}

ClassPackageSelector.propTypes = {
  onlineClassItem: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['default', 'button'])
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClassPackageSelector))
