import React from 'react'
import moment from 'moment'
import { CircularProgress } from '@material-ui/core'

const LessonGroupingNav = props => {
  let { showByTerm, activeTerm, activeMonth, nextMonth, prevMonth, switchingMonth, availableTerm } = props

  let minTerm = Math.min(...availableTerm)
  let maxTerm = Math.max(...availableTerm)

  if (showByTerm) {
    return (
      <div className="d-flex justify-content-between align-items-center mb-3">
        {activeTerm > minTerm ? (
          <div
            className="back-link text-muted"
            onClick={() => {
              props.changeActiveMonth(parseInt(activeTerm) - 1)
            }}>
            <div className="row">
              <div className="col-auto d-flex align-items-center">
                <i className="material-icons mr-1" style={{ fontSize: '10px' }}>
                  arrow_back_ios
                </i>
              </div>
              <div className="col">Term {activeTerm - 1}</div>
            </div>
          </div>
        ) : (
          <div style={{ width: '80px' }} />
        )}

        <div id="monthSelection" className="month-selector text-center" onClick={props.toggleSelectMonthDialog}>
          {switchingMonth ? <CircularProgress size={35} /> : `Term ${activeTerm}`}
          <i className="fa fa-chevron-down ml-2"></i>
        </div>

        {activeTerm < maxTerm ? (
          <div
            className="back-link text-muted"
            onClick={() => {
              props.changeActiveMonth(parseInt(activeTerm) + 1)
            }}>
            <div className="row">
              <div className="col text-right">Term {activeTerm + 1}</div>
              <div className="col-auto d-flex align-items-center">
                <i className="material-icons ml-1" style={{ fontSize: '10px' }}>
                  arrow_forward_ios
                </i>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ width: '80px' }} />
        )}
      </div>
    )
  }

  return (
    <div className="d-flex justify-content-between align-items-center mb-3">
      {prevMonth && !switchingMonth ? (
        <div
          className="back-link text-muted"
          onClick={() => {
            props.changeActiveMonth(prevMonth)
          }}>
          <div className="row">
            <div className="col-auto d-flex align-items-center">
              <i className="material-icons mr-1" style={{ fontSize: '10px' }}>
                arrow_back_ios
              </i>
            </div>
            <div className="col">{moment(prevMonth).format('MMM, YYYY')}</div>
          </div>
        </div>
      ) : (
        <div style={{ width: '80px' }} />
      )}

      <div id="monthSelection" className="month-selector" onClick={props.toggleSelectMonthDialog}>
        {switchingMonth ? <CircularProgress size={35} /> : moment(activeMonth).format('MMMM, YYYY')}
        <i className="fa fa-chevron-down ml-2"></i>
      </div>

      {nextMonth && !switchingMonth ? (
        <div
          className="forward-link text-muted"
          onClick={() => {
            props.changeActiveMonth(nextMonth)
          }}>
          <div className="row">
            <div className="col text-right">{moment(nextMonth).format('MMM, YYYY')}</div>
            <div className="col-auto d-flex align-items-center">
              <i className="material-icons ml-1" style={{ fontSize: '10px' }}>
                arrow_forward_ios
              </i>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ width: '80px' }} />
      )}
    </div>
  )
}

export default LessonGroupingNav
