import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Button, Checkbox, FormControlLabel, Input } from '@material-ui/core'
import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import qs from 'qs'

import agent from 'agent'
import {
  CLEAR_CART_CLASSES,
  GET_CART_QUOTE,
  POPULATE_CART,
  EMPTY_CART,
  GET_POST_PAYMENT_STAT
} from 'constants/actionTypes'
import { COLLAPSED_DRAWER, DRAWER_TYPE, FIXED_DRAWER } from 'constants/settingsTypes'

import CartBreakdown from './section-breakdown'
import CartItemList from './item-list'
import PaymentGatewaySelection from './payment-gateway-selection'
import PaymentSuccessDialog from './dialog-payment-success'
import LoginRegister from 'components/shared/LoginRegister/LoginRegister'
import AlertDialog from 'components/dialog/alert'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  token: state.common.token,
  shoppingCart: state.shoppingCart.shoppingCart,
  updatingCartItem: state.shoppingCart.updatingCartItem,
  gettingCartQuote: state.shoppingCart.gettingCartQuote,
  cartQuote: state.shoppingCart.cartQuote,
  cartClasses: state.shoppingCart.cartClasses,
  cartQuoteError: state.shoppingCart.cartQuoteError,
  gettingPostPaymentStat: state.shoppingCart.gettingPostPaymentStat,
  postPaymentStat: state.shoppingCart.postPaymentStat,
  checkoutInProgress: state.shoppingCart.checkoutInProgress,
  orderList: state.shoppingCart.orderList
})

const mapDispatchToProps = dispatch => ({
  changeDrawerType: drawerType => dispatch({ type: DRAWER_TYPE, drawerType: drawerType }),
  getCartClasses: query => dispatch({ type: POPULATE_CART, payload: agent.OnlineClass.getList(query, true) }),
  clearCartClasses: () => dispatch({ type: CLEAR_CART_CLASSES }), //*
  getQuote: payload => dispatch({ type: GET_CART_QUOTE, payload: agent.ShoppingCart.getCartQuote(payload) }),
  emptyCart: () => dispatch({ type: EMPTY_CART, payload: agent.ShoppingCart.clearCart() }),
  getPaymentStat: reqPayload =>
    dispatch({ type: GET_POST_PAYMENT_STAT, payload: agent.ShoppingCart.getPostPaymentStat(reqPayload) })
})

class ShoppingCart extends React.Component {
  state = {
    useCredit: false,
    availableCredit: 0,
    creditToUse: 0,
    maxCredit: 0,
    discountCode: '',
    invalidDiscountCode: false,
    validDiscountCode: false,
    applyingDiscountCode: false,
    isAlertDialogOpen: false,
    alertDetail: { title: '', content: '' },
    isPaymentSuccessDialogOpen: false,
    paidList: []
  }

  componentDidMount() {
    this.props.changeDrawerType(COLLAPSED_DRAWER)
    let parsed = qs.parse(this.props.location.search.slice(1))
    if (parsed.reference && parsed.status === 'completed') {
      this.props.getPaymentStat(parsed.reference)
    } else {
      this.populateCart()
    }
    this.getCartQuote()
    this.getUserCredit(this.props.currentUser)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.shoppingCart, this.props.shoppingCart)) {
      const { shoppingCart } = this.props
      this.populateCart()
      if (shoppingCart._id) {
        this.getCartQuote()
      }
    }

    if (!prevProps.token && this.props.token) {
      this.getCartQuote()
    }

    if (!prevProps.updatingCartItem && this.props.updatingCartItem) {
      this.getCartQuote()
    }

    if (!_.isEqual(prevProps.currentUser, this.props.currentUser)) {
      this.getUserCredit(this.props.currentUser)
    }

    if (prevState.useCredit !== this.state.useCredit || prevState.creditToUse !== this.state.creditToUse) {
      this.getCartQuote()
    }

    if (prevProps.gettingCartQuote && !this.props.gettingCartQuote) {
      const { cartQuote, cartQuoteError } = this.props

      if (cartQuote) {
        const { availableCredit, discountCode } = this.state
        let maxCredit = Math.min(availableCredit, Math.floor(cartQuote.quote))
        this.setState({ maxCredit })

        if (discountCode) {
          if (cartQuote.promo_code_discount > 0) {
            this.setState({ validDiscountCode: true, invalidDiscountCode: false })
          } else {
            this.setState({ invalidDiscountCode: true, validDiscountCode: false })
          }
        }
      }

      if (cartQuoteError) {
        // NOTE: if there is error return from get cart quotation, jus clear cart for user.
        this.props.emptyCart()
        if (cartQuoteError.message === 'err_cal_quote_zero_amount') {
          this.setState({
            isAlertDialogOpen: true,
            alertDetail: {
              title: 'Something wrong',
              content:
                '<p>We have encountered a problem while processing items in your cart. Your cart will be cleared, please add back the item you wish to buy.</p><p>Please do not hestitate to contact us for help</p>'
            }
          })
        } else {
          // NOTE: capture a sentry event for unexpected error
          Sentry.captureEvent({
            message: 'GET_CART_QUOTE',
            level: 'error',
            extra: { err: cartQuoteError }
          })
        }
      }
    }

    if (prevProps.gettingPostPaymentStat && !this.props.gettingPostPaymentStat) {
      const { postPaymentStat } = this.props
      if (postPaymentStat.error) {
        this.setState({
          isAlertDialogOpen: true,
          alertDetail: {
            title: 'Something went wrong',
            content: postPaymentStat.message
          }
        })
      } else {
        this.setState({
          isPaymentSuccessDialogOpen: true,
          paidList: postPaymentStat.order
        })
      }
    }

    if (prevProps.checkoutInProgress && !this.props.checkoutInProgress && !!this.props.orderList) {
      this.setState({
        isPaymentSuccessDialogOpen: true,
        paidList: this.props.orderList
      })
    }
  }

  componentWillUnmount() {
    this.props.clearCartClasses()
    this.props.changeDrawerType(FIXED_DRAWER)
  }

  getUserCredit = userObject => {
    let creditBalance = 0
    if (userObject?.credit) {
      let userCredit = userObject.credit.credit
      if (userCredit) {
        let userCountry = userObject.country
        if (userCountry === 'SG') {
          if (userCredit.SGD && userCredit.SGD.balance > 0) {
            creditBalance = userCredit.SGD.balance
          }
        } else if (userCountry === 'US') {
          if (userCredit.USD && userCredit.USD.balance > 0) {
            creditBalance = userCredit.USD.balance
          }
        }
      }
    }
    this.setState({ availableCredit: creditBalance })
  }

  getCartQuote = () => {
    const { token, shoppingCart } = this.props
    const { creditToUse, discountCode } = this.state

    if (!token || !shoppingCart._id) return

    let payload = {
      shopping_cart: shoppingCart._id,
      discount_code: discountCode,
      credit: creditToUse
    }
    this.props.getQuote(payload)
  }

  populateCart() {
    let items = this.props.shoppingCart.item
    let idList = items.map(item => {
      return item.item_id
    })

    if (items.length > 0) {
      let query = {
        _id: {
          $in: idList
        }
      }
      this.props.getCartClasses(query)
    } else {
      this.props.clearCartClasses()
    }
  }

  toggleUseCredit = (event, checked) => {
    const { maxCredit } = this.state
    this.setState({ useCredit: checked, creditToUse: checked ? maxCredit : 0 })
  }

  changeCredit = e => {
    const { maxCredit } = this.state
    let value = e.target.value
    let credits = value

    if (value > 0) {
      credits = Math.floor(credits)
    }

    if (credits > maxCredit) {
      credits = maxCredit
    }

    this.setState({ creditToUse: credits })
  }

  onChangeDiscountCode = e => {
    let value = e.target.value.trim().toUpperCase()
    this.setState({ discountCode: value })
  }

  attemptToDiscountCode = () => {
    this.setState(
      {
        validDiscountCode: false,
        discountCode: ''
      },
      () => {
        this.getCartQuote()
      }
    )
  }

  closeAlertDialog = () => {
    this.setState({
      isAlertDialogOpen: false,
      alertDetail: { title: '', content: '' }
    })
  }

  closePaymentSuccessDialog = () => {
    this.setState({
      isPaymentSuccessDialogOpen: false,
      paidList: []
    })
  }

  render() {
    const { token, currentUser, shoppingCart, gettingCartQuote, cartQuote, cartClasses } = this.props
    const {
      useCredit,
      availableCredit,
      creditToUse,
      maxCredit,
      discountCode,
      invalidDiscountCode,
      validDiscountCode,
      applyingDiscountCode,
      isAlertDialogOpen,
      alertDetail,
      isPaymentSuccessDialogOpen,
      paidList
    } = this.state

    return (
      <div className="container-fluid my-4">
        <Helmet>
          <title>Shopping Cart | Tenopy</title>
          <meta
            name="description"
            content="At Tenopy, top online teachers lead hands-on classes live with our next-generation classroom technology. Achieve results for English, Math and Science at Primary and Secondary levels with our tuition classes. Enrich learning beyond mere grades with our featured learning programmes."
          />
          <meta
            name="keywords"
            content="Online tuition,Math tuition,English tuition,Secondary school tuition,Primary school tuition,Online tuition singapore,Online learning,Online education,Education platform,Online tutor,Home tutor,home tuition,science tuition,sa1,sa2,ca1,ca2,psle,revision,online revision,online study,online learning,psle revision"
          />
        </Helmet>
        <div className="row">
          <div className="col-md-8">
            <div className="p-3 bg-white border-top border-info">
              <h3>Shopping Cart</h3>
              <CartItemList
                token={token}
                loading={gettingCartQuote}
                cartQuote={cartQuote}
                cartClasses={cartClasses}
                cartItems={shoppingCart.item}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="p-3 bg-white border-top border-warning mb-3">
              {!!token && (
                <React.Fragment>
                  {availableCredit > 0 && (
                    <div>
                      <h3>Use Credits</h3>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={useCredit}
                            onChange={this.toggleUseCredit}
                            disabled={gettingCartQuote}
                          />
                        }
                        label={`Use your credits? You currently have $${availableCredit}.`}
                      />
                      {useCredit && (
                        <div className="row align-items-center bg-light p-1">
                          <div className="col-8">How many credits would you like to use?</div>
                          <div className="col-4">
                            <Input
                              type="number"
                              value={creditToUse}
                              onChange={this.changeCredit}
                              inputProps={{ step: 1, min: 0, max: maxCredit }}
                              fullWidth
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="row my-4">
                    <div className="col-12 align-self-center">
                      <h3>Have a promo code? </h3>
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        className={`form-control
                          ${invalidDiscountCode && 'is-invalid'}
                          ${validDiscountCode && 'is-valid'}`}
                        value={discountCode || ''}
                        onChange={this.onChangeDiscountCode}
                        disabled={validDiscountCode}
                        style={{ marginTop: '1px' }}
                      />
                      <div className="invalid-feedback">Invalid promo code</div>
                      <div className="valid-feedback">Enjoy your discount</div>
                    </div>
                    <div className="col-auto">
                      {validDiscountCode ? (
                        <Button variant="contained" color="secondary" onClick={this.attemptToDiscountCode}>
                          Change
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={applyingDiscountCode}
                          onClick={this.getCartQuote}>
                          Apply {gettingCartQuote && <i className="fa fa-circle-o-notch fa-spin ml-2"></i>}
                        </Button>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              )}

              <CartBreakdown
                token={token}
                cartQuote={cartQuote}
                loading={gettingCartQuote}
                cartClasses={cartClasses}
                cartItems={shoppingCart.item}
              />
            </div>

            <div className="p-3 bg-white border-top border-success sticky-top" style={{ top: '86px' }}>
              <h3>Payment</h3>
              {currentUser ? (
                <PaymentGatewaySelection
                  price={cartQuote?.quote}
                  creditToUse={useCredit ? creditToUse : 0}
                  discountCode={discountCode}
                />
              ) : (
                <LoginRegister />
              )}
            </div>
          </div>
        </div>

        <PaymentSuccessDialog
          isOpen={isPaymentSuccessDialogOpen}
          close={this.closePaymentSuccessDialog}
          paidList={paidList}
        />

        <AlertDialog
          isOpen={isAlertDialogOpen}
          close={this.closeAlertDialog}
          title={alertDetail.title}
          content={alertDetail.content}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart)
