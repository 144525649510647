import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga'
import HeroBanner from 'components/public/us/_HeroBanner'
import { isMobile } from 'react-device-detect'

import { REDIRECT } from 'constants/actionTypes'
import { Routes, APP, LOGIN } from 'routes/routes'
import NavBar from 'components/nav/landing-page/navbar'
import Footer from 'components/nav/landing-page/footer'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  redirectToApp: path => dispatch({ type: REDIRECT, payload: path ? path : '/app' })
})

class Landing extends React.Component {
  state = {
    hideFooter: false
  }

  componentDidMount() {
    if (window.localStorage.getItem('jwt') || this.props.token) {
      let redirectURL = this.props.location.pathname
      if (!redirectURL.startsWith(APP)) {
        redirectURL = `${APP}${redirectURL}${this.props.location.hash && this.props.location.hash}${
          this.props.location.search && this.props.location.search
        }`
      }
      this.props.redirectToApp(redirectURL)
    }

    if (this.props.location.pathname === LOGIN) {
      this.setState({ hideFooter: true })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      const page = this.props.location.pathname + this.props.location.search
      ReactGA.set({ page })
      ReactGA.pageview(page)
      ReactPixel.pageView()

      if (this.props.location.pathname === LOGIN) {
        this.setState({ hideFooter: true })
      } else {
        this.setState({ hideFooter: false })
      }
      window.scrollTo(0, 0)
    }
  }

  render() {
    let { hideFooter } = this.state

    return (
      <div className="app-main-container">
        <HeroBanner />
        <NavBar />
        {isMobile ? <div></div> : this.props.location.pathname === '/app' && <div className="app-header"></div>}
        <main className="app-main-content-wrapper">
          <div className="app-main-content">
            <Routes />
          </div>
          {!hideFooter && <Footer />}
        </main>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Landing))
