import React from 'react'

class StudyWithUsCard extends React.Component {
  render() {
    const { title, description, image, textStyle, marginForText, marginForText1, marginForText2, marginForText3 } =
      this.props

    return (
      <div className="col-lg-4 col-12">
        <div className="col-lg-12 text-left section__header_landing_title">
          <img src={image} width="100%" alt={title}></img>
          <p className={`paragraph--bold mt-5 ${textStyle === 'text-left' ? 'text-left' : 'text-center'}`}>{title}</p>
          <div
            className={`description text-justify mb-4 ${textStyle === 'text-left' ? 'text-left' : 'text-center'}`}
            style={{
              // marginLeft: title === 'Curriculum Designed by You' ? marginForText : '',
              // marginRight: title === 'Curriculum Designed by You' ? marginForText : ''
              marginLeft: marginForText1
                ? marginForText1
                : marginForText2
                ? marginForText2
                : marginForText3
                ? marginForText3
                : '',
              marginRight: marginForText1
                ? marginForText1
                : marginForText2
                ? marginForText2
                : marginForText3
                ? marginForText3
                : ''
            }}>
            {description}
          </div>
        </div>
      </div>
    )
  }
}

export default StudyWithUsCard
