import React from 'react'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'

import faqs from 'assets/new_faq.json'
import { Collapse } from 'reactstrap'

class FAQs extends React.Component {
  state = {
    activeSection: 9999,
    activeAccordion: 9999
  }

  componentDidMount() {
    this.expandQuesion(this.props.location.hash)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      this.expandQuesion(this.props.location.hash)
    }
  }

  expandQuesion = hash => {
    if (hash) {
      let locationHash = hash.slice(1).split('-')
      this.setState({
        activeSection: Number(locationHash[0]) - 1,
        activeAccordion: Number(locationHash[1]) - 1
      })
    }
  }

  toggleFAQ = index => {
    this.setState({ activeAccordion: this.state.activeAccordion === index ? 9999 : index })
  }

  toggleFAQSection = index => {
    this.setState({
      activeSection: this.state.activeSection === index ? 9999 : index,
      activeAccordion: isMobile ? 9999 : 0
    })
  }

  render() {
    let { activeSection, activeAccordion } = this.state
    activeSection = activeSection === 9999 ? 0 : activeSection
    activeAccordion = activeAccordion === 9999 ? 0 : activeAccordion

    if (isMobile) {
      return (
        <div className="landing">
          <Helmet>
            <title>FAQ | Tenopy</title>
            <meta
              name="description"
              content="More information on Tenopy's free trial, payment, site features, lesson scheduling, technology issues, referral programme and parent report"
            />
            <meta
              name="keywords"
              content="Online tuition free trial, Tenopy free trial, free online lesson trial, tenopy payment, payment, Tenopy features, Tenopy lesson scheduling, Tenopy referral programme, Tenopy referral, Tenopy parent report, technology issue, online tuition referral"
            />
          </Helmet>

          <section className="faq">
            {/* <ReferralBanner /> */}
            <div className="section__header mx-2 py-md-5 py-0">
              <div className="title">Tenopy FAQs</div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {faqs.map((section, sectionIndex) => (
                    <div key={sectionIndex}>
                      <div
                        className={`row faq__menu ${activeSection === sectionIndex ? 'active' : ''}`}
                        onClick={() => {
                          this.toggleFAQSection(sectionIndex)
                        }}>
                        <div className="col">{section.section}</div>
                        <div className="col-auto text-right align-self-center">
                          <i
                            className={`material-icons accordion ${
                              activeSection === sectionIndex ? 'open' : ''
                            }  align-middle mr-2`}>
                            &#xE313;
                          </i>
                        </div>
                      </div>

                      <Collapse isOpen={activeSection === sectionIndex}>
                        {activeSection === sectionIndex &&
                          faqs[activeSection].content.map((faq, index) => (
                            <div className="faq__section" key={index}>
                              <div
                                className="row faq__question"
                                onClick={() => {
                                  this.toggleFAQ(index)
                                }}>
                                <div className="col">{faq.question}</div>
                                <div className="col-auto text-right">
                                  <i
                                    className={`material-icons accordion ${
                                      activeAccordion === index ? 'open' : ''
                                    }  align-middle mr-2`}>
                                    &#xE313;
                                  </i>
                                </div>
                              </div>
                              <Collapse isOpen={activeAccordion === index}>
                                <div className="faq__answer" dangerouslySetInnerHTML={{ __html: faq.answer }} />
                              </Collapse>
                            </div>
                          ))}
                      </Collapse>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>

          {/* <CTADivider /> */}
        </div>
      )
    } else {
      return (
        <div className="landing">
          <Helmet>
            <title>FAQ | Tenopy</title>
            <meta
              name="description"
              content="At Tenopy, top online teachers lead hands-on classes live with our next-generation classroom technology. Achieve results for English, Math and Science at Primary and Secondary levels with our tuition classes. Enrich learning beyond mere grades with our featured learning programmes."
            />
            <meta
              name="keywords"
              content="Online tuition,Math tuition,English tuition,Secondary school tuition,Primary school tuition,Online tuition singapore,Online learning,Online education,Education platform,Online tutor,Home tutor,home tuition,science tuition,sa1,sa2,ca1,ca2,psle,revision,online revision,online study,online learning,psle revision,edutech,education tech,education technology,faq,faqs,frequently asked questions"
            />
          </Helmet>

          <section className="faq">
            {/* <ReferralBanner /> */}
            <div className="container">
              <div className="row">
                <div className="col-md-auto">
                  {faqs.map((section, index) => (
                    <div
                      className={`faq__menu ${activeSection === index ? 'active' : ''}`}
                      key={index}
                      onClick={() => {
                        this.toggleFAQSection(index)
                      }}>
                      {section.section}
                    </div>
                  ))}
                  <div className="faq__extra-info">Feel free to get in touch with us through our live chat.</div>
                </div>
                <div className="col-md col-12 px-md-5 px-0">
                  <div className="faq__title">{faqs[activeSection].section}</div>
                  {faqs[activeSection].content.map((faq, index) => (
                    <div className="faq__section" key={index}>
                      <div
                        className="row faq__question"
                        onClick={() => {
                          this.toggleFAQ(index)
                        }}>
                        <div className="col">{faq.question}</div>
                        <div className="col-auto text-right">
                          <i
                            className={`material-icons accordion ${
                              activeAccordion === index ? 'open' : ''
                            }  align-middle mr-2`}>
                            &#xE313;
                          </i>
                        </div>
                      </div>
                      <Collapse isOpen={activeAccordion === index}>
                        <div className="faq__answer" dangerouslySetInnerHTML={{ __html: faq.answer }} />
                      </Collapse>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>

          {/* <CTADivider /> */}
        </div>
      )
    }
  }
}

export default FAQs
