import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { Button, Menu } from '@material-ui/core'
import { AccountCircle, Menu as MenuIcon } from '@material-ui/icons'
import EPABrand from 'assets/epa-brand.svg'
import {
  ROOT,
  LOGIN,
  ABOUT_US,
  ALL_CLASSES,
  SIGNUP,
  CLASS_DETAILS,
  APP,
  SERVICES,
  CONTACT,
  TEAM,
  NEWS
} from 'routes/routes'
import { HashLink as Link } from 'react-router-hash-link'

function NavMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const openMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  return (
    <div className={props.className}>
      {props.renderButton ? (
        props.renderButton(openMenu)
      ) : (
        <div className={`navbar-toplink ${props.isActive ? 'is_active' : ''}`} onClick={openMenu}>
          {props.title}
        </div>
      )}
      <Menu
        className="navbar-dropdown"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: -27,
          horizontal: 'left'
        }}
        style={{ transform: 'translate(0 20px)', maxWidth: 'calc(100% - 29px)' }}
        disableScrollLock={true}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}>
        {props.renderChildren(closeMenu)}
      </Menu>
    </div>
  )
}

class MobileNavbar extends React.Component {
  redirectToLoginRegisterPage = intent => {
    let redirectURL = intent === 'Login' ? LOGIN : SIGNUP
    let currentLocation = this.props.location.pathname
    let currentQuery = this.props.location.search
    if (currentLocation.startsWith(ALL_CLASSES) || currentLocation.startsWith(CLASS_DETAILS)) {
      redirectURL = `${redirectURL}?next=${APP}${currentLocation}`
    } else if (currentQuery) {
      redirectURL = `${redirectURL}${currentQuery}`
    }
    this.props.history.push(redirectURL)
  }

  render() {
    const { token, pathname } = this.props

    return (
      <header>
        <nav className="navbar fixed-top navbar-expand-md d-flex justify-content-between">
          <div className="ml-3">
            <Link to={!!token ? APP : ROOT} className="navbar-brand">
              <img
                src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/logo/epa_logo.svg"
                height="50"
                alt="EPA logo"
              />
            </Link>
          </div>
          <NavMenu
            className="navbar-mobile-navmenu"
            renderButton={openMenu => (
              <button type="button" className="navbar-toggler-menu" onClick={openMenu}>
                <MenuIcon />
              </button>
            )}
            renderChildren={closeMenu => (
              <div>
                <Link to={SERVICES} className="navbar-menu-toplink-mobile mt-3" onClick={closeMenu}>
                  <span style={{ color: 'black', fontWeight: '700' }}> Our Services</span>
                </Link>
                <div className="ml-3 navbar-subtitle">
                  <Link to={`${SERVICES}#tutoring`} className="navbar-link" onClick={closeMenu}>
                    <span style={{ color: 'black', fontWeight: '700' }}>Tutoring</span>
                    <br></br>
                    Academic & Test Preparation
                  </Link>
                  {/* <div className="label"></div> */}
                </div>

                <div className="ml-3 navbar-subtitle">
                  <Link to={`${SERVICES}#collegewise`} className="navbar-link" onClick={closeMenu}>
                    <span style={{ color: 'black', fontWeight: '700' }}>CollegeWise</span>
                    <br></br>
                    University Admissions Counseling
                  </Link>
                  {/* <div className="label"></div> */}
                </div>

                <div className="ml-3 navbar-subtitle">
                  <Link to={`${SERVICES}#homeschool`} className="navbar-link" onClick={closeMenu}>
                    <span style={{ color: 'black', fontWeight: '700' }}>Homeschool</span>
                    <br></br>
                    Your Personalized Curriculum
                  </Link>
                </div>
                <NavLink to={TEAM} className="navbar-menu-toplink-mobile" onClick={closeMenu}>
                  <span style={{ color: 'black', fontWeight: '700' }}>Our Team</span>
                </NavLink>
                {/* <NavLink to={NEWS} className="navbar-menu-toplink-mobile" onClick={closeMenu}>
                  News
                </NavLink> */}
                <NavLink to={CONTACT} className="navbar-menu-toplink-mobile" onClick={closeMenu}>
                  <span style={{ color: 'black', fontWeight: '700' }}> Contact</span>
                </NavLink>
                <div className="d-flex flex-row" tabIndex="-1">
                  <div className="w-100">
                    {/* <div
                      className="navbar-toplink"
                      onClick={() => {
                        this.redirectToLoginRegisterPage('Login')
                      }}>
                      Log In
                    </div> */}
                    <div
                      className={`navbar-toplink px-3 mr-0`}
                      style={{ color: 'black', fontWeight: 700, position: 'relative' }}
                      onClick={() => {}}>
                      EPA Portal
                    </div>
                    <div
                      className={`navbar-toplink border-left mb-4`}
                      style={{
                        zIndex: 'auto',
                        backgroundColor: '#FD6502',
                        color: 'white',
                        borderRadius: '4px',
                        marginTop: '20px',
                        fontWeight: 700
                      }}>
                      <NavLink to={LOGIN} style={{ color: 'white', padding: '3px' }} onClick={closeMenu}>
                        Log In
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            )}
          />

          {/* <div className="ml-auto">
           <div className="d-flex align-items-center">
              {!!token ? (
                <NavLink to={APP} className={'px-3'}>
                  <AccountCircle />
                </NavLink>
              ) : pathname.startsWith('/login') || pathname.startsWith('/signup') ? (
                <>
                  {pathname.startsWith('/login') && (
                    <div
                      className="navbar-toplink"
                      onClick={() => {
                        this.redirectToLoginRegisterPage('Signup')
                      }}>
                      Sign Up
                    </div>
                  )}
                  {pathname.startsWith('/signup') && (
                    <div
                      className="navbar-toplink"
                      onClick={() => {
                        this.redirectToLoginRegisterPage('Login')
                      }}>
                      Log In
                    </div>
                  )}
                </>
              ) : (
                <div
                  className="navbar-toplink"
                  onClick={() => {
                    this.redirectToLoginRegisterPage('Login')
                  }}>
                  Log In
                </div>
              )}
            </div> 
          </div> */}
        </nav>
      </header>
    )
  }
}

export default withRouter(MobileNavbar)
