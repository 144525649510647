import React from 'react'
import agent from '../../../agent'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { validateEmail, validatePassword, validateTextEntry, validatePhoneNumber } from '../../../utils/formValidation'
import { TERM_OF_SERVICE, PRIVACY_POLICY } from '../../../routes/routes'
import {
  LOGIN,
  REGISTER,
  REGISTER_UNLOAD,
  VALIDATE_REFERRAL_CODE,
  REQUEST_PHONE_VERIFICATION,
  VERIFY_PHONE_NUMBER,
  RESET_PHONE_VERIFICATION
} from '../../../constants/actionTypes'
import { STUDENT_MODEL } from '../../../constants/userTypes'
import countrycodes from '../../../assets/country_code.json'
import _ from 'lodash'
import qs from 'qs'
import {
  Button,
  CircularProgress,
  IconButton,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core'
import { Visibility, VisibilityOff, Check, Error } from '@material-ui/icons'
import { NumberOnly } from '../../../utils/format'
import Countdown from 'react-countdown'

const mapStateToProps = state => ({
  ...state.auth,
  utmData: state.common.utmData
})

const mapDispatchToProps = dispatch => ({
  register: signupObj => dispatch({ type: REGISTER, payload: agent.Auth.register(signupObj, STUDENT_MODEL) }),
  login: (loginObj, next) =>
    dispatch({ type: LOGIN, payload: agent.Auth.login(loginObj), next, disableRedirect: true }),
  onUnload: () => dispatch({ type: REGISTER_UNLOAD }),
  validateReferralCode: referralCode =>
    dispatch({ type: VALIDATE_REFERRAL_CODE, payload: agent.Auth.validateReferralCode(referralCode) }),
  requestPhoneVerification: (phoneCountryCode, phone) =>
    dispatch({ type: REQUEST_PHONE_VERIFICATION, payload: agent.Auth.requestPhoneValidation(phoneCountryCode, phone) }),
  verifyPhoneNumber: (verificationId, token) =>
    dispatch({ type: VERIFY_PHONE_NUMBER, payload: agent.Auth.verifyPhoneNumber(verificationId, token) }),
  resetVerification: () => dispatch({ type: RESET_PHONE_VERIFICATION })
})

class Register extends React.Component {
  state = {
    name: '',
    email: '',
    countryCode: process.env.REACT_APP_REGION === 'US' ? 1 : 65,
    phone: '',
    password: '',
    formError: {},
    next: '',
    showOthersLevel: false,
    isPasswordVisible: false,
    showReferralCode: false,
    referralCode: '',
    referralCodeHelperText: 'Enter the invite code to receive $20 credit!',
    verifyPhoneNumberFlow: {
      action: 'Request Verification',
      message: '',
      status: ''
    },
    verificationCode: '',
    needPhoneNumberValidation: process.env.REACT_APP_REGION === 'SG'
  }

  componentDidMount() {
    let parsed = qs.parse(window.location.search.slice(1))
    if (parsed.next) {
      this.setState({ next: parsed.next })
    }
    if (parsed.referral) {
      this.setState({
        referralCode: parsed.referral,
        showReferralCode: true
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.errors && this.props.errors) {
      if (this.props.errors.errors[0].type === 'duplicate') {
        if (!this.state.formError.email && !prevState.formError.email) {
          let tempFormError = this.state.formError
          tempFormError['email'] = 'This email address is already registered'
          this.setState({ formError: tempFormError })
        }
      }
    }

    if (!prevProps.loginUser && this.props.loginUser) {
      let parsed = qs.parse(window.location.search.slice(1))
      this.props.login(
        {
          email: this.state.email,
          password: this.state.password
        },
        parsed.next ? parsed.next : ''
      )
    }

    if (prevState.referralCode !== this.state.referralCode) {
      if (this.state.referralCode.length === 6 && !this.props.validatingReferralCode) {
        this.props.validateReferralCode(this.state.referralCode)
      }
    }

    if (prevProps.validatingReferralCode && !this.props.validatingReferralCode) {
      if (this.props.validatedReferralCode === this.state.referralCode) {
        if (this.props.isValidReferralCode) {
          this.setState({ referralCodeHelperText: 'Enjoy $20 of credit!' })
        } else {
          this.setState({
            referralCodeHelperText: 'This invite code doesn’t look correct. Please try again.'
          })
        }
      } else {
        this.setState({
          referralCodeHelperText: 'This invite code doesn’t look correct. Please try again.'
        })
      }
    }

    if (prevProps.requestingPhoneVerification && !this.props.requestingPhoneVerification) {
      let { verificationId } = this.props
      let { verifyPhoneNumberFlow } = this.state
      if (verificationId) {
        verifyPhoneNumberFlow.action = 'Verify'
        verifyPhoneNumberFlow.status = 'pending'
        this.setState({ verifyPhoneNumberFlow })
      }
    }

    if (prevState.verificationCode !== this.state.verificationCode) {
      if (this.state.verificationCode.length === 6 && this.state.phone) {
        this.verifyPhoneNumber()
      }
    }

    if (prevProps.verifyingPhone && !this.props.verifyingPhone) {
      let { verificationId } = this.props
      let { verifyPhoneNumberFlow } = this.state
      if (verificationId) {
        verifyPhoneNumberFlow.message = 'Phone number verified'
        verifyPhoneNumberFlow.status = 'success'
        verifyPhoneNumberFlow.action = 'Verified'
      } else {
        verifyPhoneNumberFlow.message = 'You entered wrong verification code. Please request again.'
        verifyPhoneNumberFlow.status = 'error'
        verifyPhoneNumberFlow.action = 'Resend Verification Code'
      }
      this.setState({ verifyPhoneNumberFlow })
    }
  }

  onChangeReferralCode = event => {
    let referralCode = event.target.value.toUpperCase()
    if (referralCode.length <= 6) {
      this.setState({
        referralCode,
        referralCodeHelperText: 'Enter the invite code to receive $20 credit!'
      })
    }
  }

  validateFormElement = (type, customMsg) => {
    let errorMsg
    if (type === 'password') {
      errorMsg = validatePassword(this.state[type])
    } else if (type === 'email') {
      errorMsg = validateEmail(this.state[type])
    } else if (type === 'phone') {
      if (this.state.countryCode === 65) {
        errorMsg = validatePhoneNumber(this.state[type], this.state.countryCode)
      } else {
        errorMsg = validateTextEntry(this.state[type], type)
      }
    } else {
      if (customMsg) {
        errorMsg = validateTextEntry(this.state[type], type, customMsg)
      } else {
        errorMsg = validateTextEntry(this.state[type], type)
      }
    }

    let tempFormError = this.state.formError
    if (errorMsg) {
      tempFormError[type] = errorMsg
    } else {
      delete tempFormError[type]
    }
    this.setState({ formError: tempFormError })
  }

  onChangeFormElement = e => {
    let type = e.target.name
    let value = e.target.value
    this.setState({ [type]: value })

    if (type === 'level') {
      let tempFormError = this.state.formError
      delete tempFormError[type]
      this.setState({ formError: tempFormError })
      if (value === 'others') {
        this.setState({ showOthersLevel: true })
      } else {
        this.setState({ showOthersLevel: false })
      }
    }
    if (type === 'countryCode') {
      this.setState({ needPhoneNumberValidation: value === 65 })
    }
  }

  onFocusFormElement = e => {
    let type = e.target.name
    let tempFormError = this.state.formError
    delete tempFormError[type]
    this.setState({ formError: tempFormError })
  }

  handleVerifyButton = () => {
    let { verificationId } = this.props
    if (verificationId) {
      this.verifyPhoneNumber()
    } else {
      this.requestPhoneVerification()
    }
  }

  requestPhoneVerification = () => {
    this.validateFormElement('phone')

    if (_.isEmpty(this.state.formError)) {
      let { countryCode, phone, verifyPhoneNumberFlow } = this.state
      this.props.requestPhoneVerification(countryCode, phone)

      verifyPhoneNumberFlow.message = ''
      verifyPhoneNumberFlow.status = ''
      this.setState({ verifyPhoneNumberFlow })
    }
  }

  verifyPhoneNumber = () => {
    let { verificationId } = this.props
    let { verificationCode, verifyPhoneNumberFlow } = this.state

    if (verificationCode) {
      if (verificationCode.length === 6) {
        if (verificationId) {
          this.props.verifyPhoneNumber(verificationId, verificationCode)
        } else {
          verifyPhoneNumberFlow.message = 'Something wrong with your verification process. Please request again.'
          verifyPhoneNumberFlow.status = 'error'
          verifyPhoneNumberFlow.action = 'Request Verification'
        }
      } else {
        verifyPhoneNumberFlow.message = 'Verification code shall 6 digit'
        verifyPhoneNumberFlow.status = 'error'
      }
    } else {
      verifyPhoneNumberFlow.message = 'Please enter verification code you received on your phone'
      verifyPhoneNumberFlow.status = 'error'
    }
    this.setState({ verifyPhoneNumberFlow })
  }

  handleExpiredVerification = () => {
    let { verifyPhoneNumberFlow } = this.state
    if (verifyPhoneNumberFlow.status === 'pending') {
      verifyPhoneNumberFlow.action = 'Resend Verification Code'
      verifyPhoneNumberFlow.status = 'error'
      verifyPhoneNumberFlow.message = 'Your verification code expired. Please request again.'
      this.props.resetVerification()
      this.setState({ verifyPhoneNumberFlow })
    }
  }

  register = e => {
    e.preventDefault()
    let {
      name,
      email,
      countryCode,
      phone,
      password,
      referralCode,
      formError,
      verifyPhoneNumberFlow,
      needPhoneNumberValidation
    } = this.state

    this.validateFormElement('name')
    this.validateFormElement('email')
    this.validateFormElement('phone')
    this.validateFormElement('password')

    if (_.isEmpty(formError)) {
      if (verifyPhoneNumberFlow.status === 'success' || !needPhoneNumberValidation) {
        let reqPayload = {
          name,
          email,
          phone_country_code: countryCode,
          phone,
          password,
          referral_code: referralCode,
          country: process.env.REACT_APP_REGION
        }

        let { utmData, verificationId } = this.props
        if (!utmData) {
          let localStorageUTMData = window.localStorage.getItem('_utm')
          if (localStorageUTMData) {
            utmData = JSON.parse(localStorageUTMData)
          }
        }
        if (utmData) {
          reqPayload['lead_source'] = utmData
        }

        if (verificationId) {
          reqPayload['verificationId'] = verificationId
        }
        this.props.register(reqPayload)
      }
    } else {
      verifyPhoneNumberFlow.message = 'Please verify your phone number to complete registration'
      verifyPhoneNumberFlow.status = 'error'
      this.setState({ verifyPhoneNumberFlow })
    }
  }

  togglePasswordVisibility = () => {
    this.setState({
      isPasswordVisible: !this.state.isPasswordVisible
    })
  }

  toggleShowReferralCode = () => {
    this.setState({
      showReferralCode: !this.state.showReferralCode
    })
  }

  render() {
    let {
      inProgress,
      validatingReferralCode,
      validatedReferralCode,
      isValidReferralCode,
      buttonText,
      requestingPhoneVerification,
      verifyingPhone
    } = this.props
    let {
      name,
      email,
      countryCode,
      phone,
      password,
      formError,
      referralCode,
      referralCodeHelperText,
      isPasswordVisible,
      verifyPhoneNumberFlow,
      showReferralCode,
      needPhoneNumberValidation
    } = this.state

    return (
      <form onSubmit={this.register} noValidate>
        <TextField
          type="text"
          label="Student's Name"
          name="name"
          onChange={this.onChangeFormElement}
          error={!!this.state.formError.name}
          helperText={this.state.formError.name}
          onFocus={this.onFocusFormElement}
          fullWidth
          defaultValue={name}
          margin="normal"
          className="mt-0 mb-2"
        />

        <TextField
          type="email"
          onChange={this.onChangeFormElement}
          error={!!this.state.formError.email}
          helperText={this.state.formError.email}
          onFocus={this.onFocusFormElement}
          label="Email"
          name="email"
          fullWidth
          defaultValue={email}
          margin="normal"
          className="mt-0 mb-2"
        />

        <div className="row align-items-end no-gutters mb-3">
          <div className="col-auto">
            <Select
              value={countryCode}
              onChange={this.onChangeFormElement}
              disabled={verifyPhoneNumberFlow.status === 'success'}
              className="mt-0"
              name="countryCode">
              {countrycodes.map(cc => {
                return (
                  <MenuItem key={cc.code} value={cc.code}>
                    <span className={`flag ${cc.flag} mr-1`} /> +{cc.code}{' '}
                  </MenuItem>
                )
              })}
            </Select>
          </div>
          <div className="col">
            <TextField
              type="text"
              label="Phone Number"
              name="phone"
              onChange={this.onChangeFormElement}
              error={!!this.state.formError.phone}
              onFocus={this.onFocusFormElement}
              InputProps={{ inputComponent: NumberOnly }}
              disabled={verifyPhoneNumberFlow.status === 'success'}
              fullWidth
              value={phone}
              margin="normal"
              className="mt-0 mb-0"
            />
          </div>
          <div className="col-12">
            <FormHelperText error={!!this.state.formError.phone} className="text-small">
              {this.state.formError.phone
                ? this.state.formError.phone
                : "We'll provide customer support over the phone in case you face any issues for your classes"}
            </FormHelperText>
          </div>
        </div>

        {needPhoneNumberValidation && (
          <div className="row align-items-end no-gutters mb-3">
            <div className="col">
              <TextField
                type="text"
                onChange={this.onChangeFormElement}
                helperText={this.state.formError.verificationCode}
                onFocus={this.onFocusFormElement}
                error={verifyPhoneNumberFlow.status === 'error'}
                disabled={verifyingPhone || verifyPhoneNumberFlow.status === 'success'}
                label="Verification Code"
                name="verificationCode"
                fullWidth
                margin="normal"
                className="mt-0 mb-0"
                inputProps={{
                  maxLength: 6
                }}
                InputProps={{
                  inputComponent: NumberOnly,
                  endAdornment: (
                    <InputAdornment position="end">
                      {verifyPhoneNumberFlow.status === 'pending' && (
                        <Countdown
                          date={Date.now() + 60000}
                          renderer={props => (
                            <div className="text-muted text-small mr-2">Expire in {props.total / 1000} s</div>
                          )}
                          onComplete={this.handleExpiredVerification}
                        />
                      )}
                      {verifyPhoneNumberFlow.status === 'success' && <Check className="text-success mr-2" />}
                    </InputAdornment>
                  )
                }}
              />
            </div>
            <div className="col-md-auto col-12 mt-md-0 mt-2">
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleVerifyButton}
                disabled={verifyPhoneNumberFlow.status === 'success' || requestingPhoneVerification || verifyingPhone}>
                {verifyPhoneNumberFlow.action}
                {(requestingPhoneVerification || verifyingPhone) && <CircularProgress size={24} className="ml-2" />}
              </Button>
            </div>
            {verifyPhoneNumberFlow.message && (
              <div className="col-12">
                <FormHelperText className="text-small" error={verifyPhoneNumberFlow.status === 'error'}>
                  {verifyPhoneNumberFlow.message}
                </FormHelperText>
              </div>
            )}
          </div>
        )}

        <FormControl className="mb-3" fullWidth>
          <InputLabel>Password</InputLabel>
          <Input
            type={isPasswordVisible ? 'text' : 'password'}
            name="password"
            value={password}
            onChange={this.onChangeFormElement}
            error={!!formError.password}
            onFocus={this.onFocusFormElement}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="Toggle password visibility" onClick={this.togglePasswordVisibility}>
                  {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {formError.password && <FormHelperText error={true}>{formError.password}</FormHelperText>}
        </FormControl>

        {showReferralCode ? (
          <FormControl fullWidth>
            <div className="d-flex flex-row align-items-center">
              <TextField
                type="text"
                label="Invited to join Tenopy? (optional)"
                name="referralCode"
                onChange={this.onChangeReferralCode}
                fullWidth
                value={referralCode}
                margin="normal"
                className="mt-0 mb-0"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {validatedReferralCode === referralCode ? (
                        <div>
                          {isValidReferralCode ? (
                            <Check className="text-success" />
                          ) : (
                            <Error className=" text-danger" />
                          )}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </InputAdornment>
                  )
                }}
              />
              {validatingReferralCode && <CircularProgress size={24} className="ml-2" />}
            </div>
            <FormHelperText
              error={!isValidReferralCode && validatedReferralCode === referralCode}
              className="text-small mb-4">
              {referralCodeHelperText}
            </FormHelperText>
          </FormControl>
        ) : (
          <div className="link text-center mb-5" onClick={this.toggleShowReferralCode}>
            I have referral code{' '}
          </div>
        )}

        <Button
          variant="contained"
          color="primary"
          className="jr-btn text-white btn-block"
          type="submit"
          disabled={inProgress || validatingReferralCode}>
          {!!buttonText ? buttonText : 'Sign Up'} {inProgress && <CircularProgress size={24} className="ml-2" />}
        </Button>
        <div className="text-center my-2">
          By signing up, I agree with Tenopy&#697;s&nbsp;
          <Link to={TERM_OF_SERVICE} target="_blank">
            terms of service
          </Link>
          &nbsp;&&nbsp;
          <Link to={PRIVACY_POLICY} target={'_blank'}>
            privacy policy
          </Link>
        </div>
      </form>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Register))
