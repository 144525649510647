import React from 'react'
import { connect } from 'react-redux'

import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'

import agent from 'agent'
import { GET_LESSON_LIST } from 'constants/actionTypes'
import { CLASS_TYPE_REGULAR_CLASSES } from 'constants/generalTypes'
import PollResult from 'components/online-class/span-poll-result'
import PollDetailDialog from 'components/online-class/dialog-poll-detail'

const mapStateToProps = state => ({
  loadingLessonList: state.lessons.loadingLessonList,
  lessonList: state.lessons.lessonList
})

const mapDispatchToProps = dispatch => ({
  getLessonList: filter => dispatch({ type: GET_LESSON_LIST, payload: agent.Lessons.getList('class-data', filter) })
})

class DataTab extends React.Component {
  state = {
    lessonGroup: [],
    selectedGroup: '',
    alertMessage: '',
    lessonTableHead: [],
    attendanceData: null,
    pollData: null,
    lessonPollQuestion: {},
    isPollDetailDialogOpen: false,
    pollDetailDialogTitle: '',
    selectedPollQuestionData: null,
    selectedPollAnswerData: null
  }

  componentDidMount() {
    this.groupLessons()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedGroup !== this.state.selectedGroup && this.state.selectedGroup) {
      this.setState({ alertMessage: '' })
    }

    if (prevProps.loadingLessonList && !this.props.loadingLessonList) {
      this.processData()
    }
  }

  groupLessons = () => {
    const { class_type, lesson } = this.props.classData

    let lessonGroup = []
    if (class_type === CLASS_TYPE_REGULAR_CLASSES) {
      let allTerms = _.uniq(_.map(lesson, 'meta.term'))
      for (let i = 0; i < allTerms.length; i++) {
        let termLessons = _.filter(lesson, o => {
          return Number(o.meta.term) === Number(allTerms[i])
        })
        let sortedTermLessons = _.orderBy(termLessons, ['scheduled_on'])
        lessonGroup.push({
          title: `Term ${allTerms[i]}`,
          term: allTerms[i],
          lessonIds: _.map(sortedTermLessons, '_id'),
          termStart: moment(sortedTermLessons[0].scheduled_on).format('ll'),
          termEnd: moment(sortedTermLessons[sortedTermLessons.length - 1].scheduled_on).format('ll')
        })
      }
    } else {
      let sortedLesson = _.orderBy(lesson, ['scheduled_on'])
      lessonGroup.push({
        title: 'All Lesson',
        term: 'all',
        lessonIds: _.map(sortedLesson, '_id'),
        termStart: moment(sortedLesson[0].scheduled_on).format('ll'),
        termEnd: moment(sortedLesson[sortedLesson.length - 1].scheduled_on).format('ll')
      })
    }

    this.setState({ lessonGroup })
  }

  processData = () => {
    const { lessonList } = this.props

    if (lessonList.data.length === 0) {
      return
    }

    let attendanceData = [],
      pollData = [],
      lessonPollQuestion = {}
    let lessonTableHead = _.map(lessonList.data, o => {
      return 'lesson_' + o.meta.index
    })

    for (let i = 0; i < lessonTableHead.length; i++) {
      lessonPollQuestion[lessonTableHead[i]] = []
    }

    for (let i = 0; i < lessonList.data.length; i++) {
      const lessonData = lessonList.data[i]
      const lessonName = `lesson_${lessonData.meta.index}`
      const allStudent = _.concat(lessonData.student, lessonData.trial_student)

      if (lessonData.poll_stat) {
        for (const [key, value] of Object.entries(lessonData.poll_stat)) {
          if (value.participant_count > 0) {
            let questionData = _.find(lessonData.poll_setup, o => {
              return o.question === key
            })
            if (questionData) {
              lessonPollQuestion[lessonName].push(questionData)
            }
          }
        }
      }

      for (let j = 0; j < allStudent.length; j++) {
        const studentEmail = allStudent[j].email

        let attended = ''
        let purchased =
          _.findIndex(lessonData.student, o => {
            return o.email === studentEmail
          }) > -1
        let isTrial =
          _.findIndex(lessonData.trial_student, o => {
            return o.email === studentEmail
          }) > -1
        if (lessonData.attendance?.length > 0) {
          let attendanceRecord = _.filter(lessonData.attendance, o => {
            return o.user_email === studentEmail
          })
          if (attendanceRecord.length > 0) {
            attended = 'Yes'
          } else {
            attended = 'No'
          }
        }

        let existingAttendanceIndex = _.findIndex(attendanceData, o => {
          return o.email === studentEmail
        })
        if (existingAttendanceIndex > -1) {
          attendanceData[existingAttendanceIndex][lessonName] = attended
          attendanceData[existingAttendanceIndex][`purchased_${lessonName}`] = purchased
          attendanceData[existingAttendanceIndex][`trial_${lessonName}`] = isTrial
        } else {
          let tempObj = {
            email: studentEmail,
            name: allStudent[j].name
          }
          for (let k = 0; k < lessonTableHead.length; k++) {
            let objName = lessonTableHead[k]
            tempObj[objName] = ''
            tempObj[`purchased_${objName}`] = false
            tempObj[`trial_${objName}`] = false
          }
          tempObj[lessonName] = attended
          tempObj[`purchased_${lessonName}`] = purchased
          tempObj[`trial_${lessonName}`] = isTrial
          attendanceData.push(tempObj)
        }

        let userPollResult = ''
        if (lessonData.poll?.length > 0) {
          let pollRecord = _.find(lessonData.poll[0].questions, o => {
            return o.email === studentEmail
          })
          userPollResult = pollRecord?.question_details ?? 'N/A'
        }

        let existingPollIndex = _.findIndex(pollData, o => {
          return o.email === studentEmail
        })
        if (existingPollIndex > -1) {
          pollData[existingPollIndex][lessonName] = userPollResult
          pollData[existingPollIndex][`purchased_${lessonName}`] = purchased
          pollData[existingPollIndex][`trial_${lessonName}`] = isTrial
        } else {
          let tempObj = {
            email: studentEmail,
            name: allStudent[j].name
          }
          for (let k = 0; k < lessonTableHead.length; k++) {
            let objName = lessonTableHead[k]
            tempObj[objName] = ''
            tempObj[`purchased_${objName}`] = false
            tempObj[`trial_${objName}`] = false
          }
          tempObj[lessonName] = userPollResult
          tempObj[`purchased_${lessonName}`] = purchased
          tempObj[`trial_${lessonName}`] = isTrial
          pollData.push(tempObj)
        }
      }
    }

    this.setState({ lessonTableHead, attendanceData, pollData, lessonPollQuestion })
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  checkData = () => {
    const { lessonGroup, selectedGroup } = this.state

    if (!selectedGroup) {
      this.setState({ alertMessage: 'Please select a lesson group to proceed' })
      return
    }

    let selectedLessonGroup = _.find(lessonGroup, o => {
      return o.term === selectedGroup
    })

    let filter = {
      _id: { $in: selectedLessonGroup.lessonIds },
      offset: 0,
      max: selectedLessonGroup.lessonIds.length
    }
    this.props.getLessonList(filter)
  }

  togglePollDetailDialog = (questionData, answerData, studentName, lesson) => () => {
    this.setState({
      isPollDetailDialogOpen: !this.state.isPollDetailDialogOpen,
      selectedPollQuestionData: questionData,
      selectedPollAnswerData: answerData,
      pollDetailDialogTitle: `${lesson} Poll (${studentName})`
    })
  }

  render() {
    const {
      lessonGroup,
      selectedGroup,
      alertMessage,
      lessonTableHead,
      attendanceData,
      pollData,
      lessonPollQuestion,
      isPollDetailDialogOpen,
      selectedPollQuestionData,
      selectedPollAnswerData,
      pollDetailDialogTitle
    } = this.state

    return (
      <div>
        <Paper elevation={2} className="p-3 mb-3">
          <div className="d-flex align-items-center">
            <FormControl variant="outlined" style={{ minWidth: '250px' }} error={alertMessage ? true : false}>
              <InputLabel>Lessons Grouping</InputLabel>
              <Select name="selectedGroup" value={selectedGroup} onChange={this.handleChange} label="Lesson Grouping">
                {lessonGroup.map((group, index) => (
                  <MenuItem key={index} value={group.term}>
                    {group.title} ({group.termStart} - {group.termEnd})
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{alertMessage}</FormHelperText>
            </FormControl>

            <Button variant="contained" color="primary" className="ml-2" onClick={this.checkData}>
              Check
            </Button>
          </div>
        </Paper>

        {attendanceData && (
          <>
            <div className="d-flex align-items-center justify-content-between my-3">
              <h3 className="mb-0">Attendance Data</h3>
              <div>
                {/* <span className="border bg-white p-2 mr-2"></span>
                <span className="border bg-grey lighten-4 p-2 mr-2"></span>
                <span className="border bg-blue lighten-4 p-2 mr-2"></span> */}
              </div>
            </div>

            <Paper elevation={2} className="p-3">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    {lessonTableHead.map((row, index) => (
                      <TableCell key={index}>{row.replace('lesson_', 'L')}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attendanceData.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        {lessonTableHead.map((rowName, rowIndex) => {
                          let cellBg = 'bg-grey lighten-4'
                          if (row[`purchased_${rowName}`]) {
                            cellBg = 'bg-white'
                          }
                          if (row[`trial_${rowName}`]) {
                            cellBg = 'bg-blue lighten-4'
                          }

                          return (
                            <TableCell
                              key={rowIndex}
                              className={cellBg}
                              style={{
                                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                                borderRight: '1px solid rgba(224, 224, 224, 1)'
                              }}>
                              {row[rowName]}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Paper>
          </>
        )}

        {/* {pollData && (
          <>
            <div className="d-flex align-items-center justify-content-between my-3">
              <h3 className="mb-0">Poll Data</h3>
              <div>
                <span className="border bg-white p-2 mr-2">Purchased</span>
                <span className="border bg-grey lighten-4 p-2 mr-2">Not Purchased</span>
                <span className="border bg-blue lighten-4 p-2 mr-2">Trial</span>
              </div>
            </div>
            <Paper elevation={2} className="p-3">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    {lessonTableHead.map((row, index) => (
                      <TableCell key={index}>{row.replace('lesson_', 'L')}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pollData.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        {lessonTableHead.map((rowName, rowIndex) => {
                          let cellBg = 'bg-grey lighten-4'
                          let lessonAccess = false
                          if (row[`purchased_${rowName}`]) {
                            cellBg = 'bg-white'
                            lessonAccess = true
                          }
                          if (row[`trial_${rowName}`]) {
                            cellBg = 'bg-blue lighten-4'
                            lessonAccess = true
                          }

                          return (
                            <TableCell
                              key={rowIndex}
                              className={cellBg}
                              style={{
                                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                                borderRight: '1px solid rgba(224, 224, 224, 1)'
                              }}>
                              <PollResult
                                questionData={lessonPollQuestion[rowName]}
                                answerData={row[rowName]}
                                lessonAccess={lessonAccess}
                                viewPollDetail={this.togglePollDetailDialog(
                                  lessonPollQuestion[rowName],
                                  row[rowName],
                                  row.name,
                                  rowName.replace('lesson_', 'Lesson ')
                                )}
                              />
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Paper>
          </>
        )} */}

        <PollDetailDialog
          isOpen={isPollDetailDialogOpen}
          close={this.togglePollDetailDialog()}
          questionData={selectedPollQuestionData}
          answerData={selectedPollAnswerData}
          title={pollDetailDialogTitle}
        />
      </div>
    )
  }
}

DataTab.propTypes = {
  classData: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(DataTab)
