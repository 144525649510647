import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import {
  Button,
  CircularProgress,
  IconButton,
  FormControl,
  InputAdornment,
  InputLabel,
  TextField,
  OutlinedInput,
  Divider
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import * as Sentry from '@sentry/browser'
import qs from 'qs'
import { withStyles } from '@material-ui/styles'

import agent from 'agent'
import { FORGOT_PASSWORD, SIGNUP } from 'routes/routes'
import { LOGIN } from 'constants/actionTypes'

import { UncontrolledAlert } from 'reactstrap'

const styles = () => ({
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#F7EFEA'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#3E68A8'
    }
  }
})

const mapStateToProps = state => ({
  token: state.common.token,
  inProgress: state.auth.inProgress,
  errors: state.auth.errors
})

const mapDispatchToProps = dispatch => ({
  login: (loginObj, next) => dispatch({ type: LOGIN, payload: agent.Auth.login(loginObj), next })
})

class Login extends React.Component {
  constructor() {
    super()

    this.login = e => {
      e.preventDefault()
      this.setState({ errorMsg: '' })
      let loginObj = {
        email: this.state.email,
        password: this.state.password
      }
      this.props.login(loginObj, this.state.next)
    }

    this.state = {
      email: '',
      password: '',
      errorMsg: '',
      next: '',
      isPasswordVisible: false
    }
  }

  componentDidMount() {
    let parsed = qs.parse(window.location.search.slice(1))
    this.setState({
      next: parsed.next ? parsed.next : '',
      email: parsed.email ? parsed.email : ''
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inProgress && !this.props.inProgress) {
      let { errors } = this.props
      if (errors && errors.errors && errors.errors.length > 0) {
        if (errors.errors[0].message === 'Invalid login credential') {
          this.setState({
            errorMsg: 'You have entered the wrong email or password'
          })
        } else {
          //NOTE: This line shall handle all kind of general login issue, include "Account locked".
          //      Add in more specific errorMsg handling if necessary
          this.setState({
            errorMsg: 'There seems to be an issue logging in. Contact us for help.'
          })
          Sentry.captureEvent({
            message: 'LOGIN_FAILED',
            level: 'error',
            extra: errors
          })
        }
      }
    }
  }

  togglePasswordVisibility = () => {
    this.setState({
      isPasswordVisible: !this.state.isPasswordVisible
    })
  }

  render() {
    let { inProgress, classes } = this.props
    let { email, password, next, errorMsg, isPasswordVisible } = this.state

    return (
      <div className="app-login-container d-flex justify-content-center align-items-sm-center align-items-start animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>Log In | EPA</title>
          <meta
            name="description"
            content="Sign up for online tutoring classes with EPA to prepare for Mathematics, Science and English exams"
          />
          <meta
            name="keywords"
            content="Online tuition,Math tuition,English tuition,Secondary school tuition,Primary school tuition,Online tuition singapore,Online learning,Online education,Education platform,Online tutor,Home tutor,home tuition,science tuition,sa1,sa2,ca1,ca2,psle,revision,online revision,online study,online learning,psle revision"
          />
        </Helmet>

        <div className="app-login-main-content" style={{ marginTop: isMobile ? '0em' : '-2em' }}>
          <div className="justify-content-center align-items-sm-center d-flex login-header-black-bold">
            <p>EPA Portal</p>
          </div>

          <div
            style={{ marginTop: '-10px' }}
            className="mb-3 justify-content-center align-items-sm-center d-flex login-description-black">
            <p>For students, parents, and teachers</p>
          </div>
          {errorMsg && <UncontrolledAlert className="bg-danger text-white shadow-lg">{errorMsg}</UncontrolledAlert>}

          <form onSubmit={this.login}>
            <TextField
              label="Email"
              fullWidth
              variant="outlined"
              onChange={event => this.setState({ email: event.target.value })}
              value={email}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              margin="normal"
              className="bg-light-gray mb-3"
            />

            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                className="bg-light-gray mb-3"
                type={isPasswordVisible ? 'text' : 'password'}
                value={password}
                onChange={event => this.setState({ password: event.target.value })}
                classes={{ notchedOutline: classes.notchedOutline }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.togglePasswordVisibility}
                      edge="end">
                      {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={80}
              />
            </FormControl>

            <Link to={FORGOT_PASSWORD} className="text-black mb-1">
              Forgot your password?
            </Link>

            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              onClick={this.login}
              disabled={inProgress}
              fullWidth={true}
              style={{ paddingTop: '12px', paddingBottom: '12px' }}
              className="mt-5">
              <span style={{ fontFamily: "'Alegreya Sans', sans-serif" }} className="info-description-white--bold">
                Log In{' '}
              </span>
              {inProgress && <CircularProgress size={24} className="ml-2" />}
            </Button>

            {/* <div className="text-center">
              {isMobile ? (
                <>
                  <Divider className="mb-4" />
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={() => {
                      this.props.history.push(`${SIGNUP}${next ? `?next=${next}` : ''}`)
                    }}
                    fullWidth={true}>
                    Create New Account
                  </Button>
                </>
              ) : (
                <Link
                  to={`${SIGNUP}${next ? `?next=${next}` : ''}`}
                  className="font-weight-semibold"
                  style={{ fontSize: '16px' }}>
                  Sign Up
                </Link>
              )}
            </div> */}
          </form>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Login)))
