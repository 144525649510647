import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  Paper,
  List,
  Button,
  CircularProgress,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import { Done as CorrectIcon } from '@material-ui/icons'
import _, { toPlainObject } from 'lodash'
import agent from 'agent'
import { GET_LESSON_BY_ID, GET_LESSON_ACTIVITY_ANSWER } from 'constants/actionTypes'
import { LESSON_PAGE } from 'routes/tutorRoutes'
import LessonCard from 'components/shared/Classes/LessonItem/LessonCard'

const mapStateToProps = state => ({
  loadingLessonActivityAnswers: state.activity.loadingLessonActivityAnswers,
  lessonActivityAnswers: state.activity.lessonActivityAnswers,
  lessons: state.lessons.lessons
})

const mapDispatchToProps = dispatch => ({
  getAnswers: reqPayload => dispatch({ type: GET_LESSON_ACTIVITY_ANSWER, payload: agent.Activity.read(reqPayload) })
})

const MCQ = 'MultipleChoice'
const OpenEnded = 'OpenEnded'
class ActivityRecordTab extends React.Component {
  state = {
    sortedActivity: []
  }

  componentDidMount() {
    this.sortActivity()
    const { activities, lessonId } = this.props
    const reqPayload = {
      lesson: {
        id: lessonId
      },
      activity: {
        id: activities.map(content => content.content._id),
        type: activities.map(content => content._type)
      }
    }
    this.props.getAnswers(reqPayload)
  }

  sortActivity = () => {
    const { activities } = this.props
    if (!activities) return

    const sortedActivity = _.orderBy(activities, ['order'])
    this.setState({ sortedActivity })
  }

  render() {
    const { lessonActivityAnswers, lessons } = this.props
    let data = lessons ? lessons.data : null
    let sortedLessons = data ? _.sortBy(data, ['scheduled_on']) : null

    const { sortedActivity } = this.state

    if (!lessonActivityAnswers) {
      return (
        <div className="manage-margin text-center py-5">
          <CircularProgress size={50} />
        </div>
      )
    }

    return (
      <div className="container-fluid animated slideInUpTiny animation-duration-3">
        <div className="row">
          <div className="col-12 mb-3">
            <div className="d-flex justify-content-between">
              <div className="manage-margin">
                {/* {sortedLessons && sortedLessons.length > 0 && (
                  <Paper elevation={2}>
                    <List disablePadding={true}>
                      {sortedLessons.map(lesson => {
                        return <LessonCard key={lesson._id} lesson={lesson} divider={true} />
                      })}
                    </List>
                  </Paper>
                )} */}

                {/* <Button variant="contained" color="secondary" onClick={this.props.history.push(LESSON_PAGE.replace(':lessonId', lessons[0]._id))}>
                  Previous Lesson Record
                </Button> */}
              </div>
              <div className="manage-margin">
                {/* <Button variant="contained" color="secondary" onClick={this.props.history.push(LESSON_PAGE.replace(':classId', 'FAGKph65RW-wFG8jWfSu1Q').replace(':lessonId', 'wHvrxPpxT2q-c2OUnTXU-A') + '?tf=activityrecords')}>
                  Next Lesson Record
                </Button> */}
              </div>
            </div>
          </div>

          <div className="col-12 bg-white">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Question</TableCell>
                  <TableCell>Answer</TableCell>
                  <TableCell>Student Answer</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedActivity.map(activity => (
                  <TableRow key={activity.content._id}>
                    <TableCell>{activity.order}</TableCell>
                    <TableCell>
                      <div className="manage-margin">
                        <Chip label={activity.content._type} />
                        {activity.content.mode && <Chip label={activity.content.mode} />}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div dangerouslySetInnerHTML={{ __html: activity.content.question }} />
                    </TableCell>
                    <TableCell>
                      {activity.content._type === MCQ && (
                        <>
                          {activity.content.option.map((o, index) => (
                            <div key={index} className="d-flex align-items-center">
                              {o.label}. {o.content}
                              {activity.content.answer_index.includes(index) && (
                                <CorrectIcon
                                  className="ml-1 text-success"
                                  style={{ width: '0.8em', height: '0.8em' }}
                                />
                              )}
                            </div>
                          ))}
                          {activity.content.answer_index.length > 1 && (
                            <span>
                              {/* if end of index, stop giving comma  */}
                              Answer:
                              <span>
                                {activity.content.answer_index.map((ans, index) =>
                                  activity.content.answer_index.length === index + 1 ? ` ${ans + 1} ` : ` ${ans + 1}, `
                                )}
                              </span>
                            </span>
                          )}
                        </>
                      )}
                      {activity.content._type === OpenEnded && (
                        <div className="d-flex flex-wrap manage-margin">
                          {activity.content.answer.map((o, index) =>
                            o === '-' ? (
                              ''
                            ) : o.length < 30 ? (
                              <Chip key={index} label={o} />
                            ) : (
                              <span key={index}>- {o}</span>
                            )
                          )}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      <div className="d-flex flex-wrap manage-margin">
                        {lessonActivityAnswers
                          .filter(activities => activities.activity === activity.content._id)
                          .map((answer, index) => {
                            {
                              typeof answer.answer === 'object' &&
                                answer.answer.sort(function (a, b) {
                                  return a - b
                                })
                            }
                            return (
                              <ul key={index}>
                                <Chip style={{ color: '#000000' }} color="secondary" label={answer.userName}></Chip>{' '}
                                {/* if string, display answer as is, else if multiple choice, display answer as multiple answers  */}
                                {typeof answer.answer === 'string'
                                  ? answer.answer
                                  : JSON.stringify(answer.answer) === '[0,1]'
                                  ? '1, 2'
                                  : JSON.stringify(answer.answer) === '[0,2]'
                                  ? '1, 3'
                                  : JSON.stringify(answer.answer) === '[0,3]'
                                  ? '1, 4'
                                  : JSON.stringify(answer.answer) === '[1,2]'
                                  ? '2, 3'
                                  : JSON.stringify(answer.answer) === '[1,3]'
                                  ? '2, 4'
                                  : JSON.stringify(answer.answer) === '[2,3]'
                                  ? '3, 4'
                                  : JSON.stringify(answer.answer) === '[0,1,2]'
                                  ? '1, 2, 3'
                                  : JSON.stringify(answer.answer) === '[0,1,3]'
                                  ? '1, 2, 4'
                                  : JSON.stringify(answer.answer) === '[0,2,3]'
                                  ? '1, 3, 4'
                                  : JSON.stringify(answer.answer) === '[1,2,3]'
                                  ? '2, 3, 4'
                                  : JSON.stringify(answer.answer) === '[0,1,2,3]'
                                  ? '1, 2, 3, 4'
                                  : ''}
                                {/* if user choice matches multiple MCQ answers, give a tick */}
                                {activity.content.answer_index &&
                                  JSON.stringify(activity.content.answer_index.map(index => index)) ===
                                    JSON.stringify(answer.answer) && (
                                    <CorrectIcon
                                      className="ml-1 text-success"
                                      style={{ width: '0.8em', height: '0.8em' }}
                                    />
                                  )}
                                {/* openEnded answers match array of answers, give a tick */}
                                {activity.content.answer &&
                                  activity.content.answer.map(
                                    (arrayAnswers, i) =>
                                      arrayAnswers === answer.answer && (
                                        <CorrectIcon
                                          key={i}
                                          className="ml-1 text-success"
                                          style={{ width: '0.8em', height: '0.8em' }}
                                        />
                                      )
                                  )}
                                {/* single choice label and answer */}
                                {activity.content.answer_index && answer.label && `${answer.label}. ${answer.content}`}
                                {/* single choice matches MCQ answer, give a tick */}
                                {answer.correctAnsLabel && answer.label === answer.correctAnsLabel && (
                                  <CorrectIcon
                                    className="ml-1 text-success"
                                    style={{ width: '0.8em', height: '0.8em' }}
                                  />
                                )}
                              </ul>
                            )
                          })}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivityRecordTab))
