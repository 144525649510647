import React from 'react'
import { isMobile } from 'react-device-detect'
class CurriculumCard extends React.Component {
  render() {
    const { title, description, width, marginLeft, extraContent, type, marginToCut } = this.props

    const checkImageUrl =
      type === 3
        ? 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/purpletick.svg'
        : 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/greentick.svg'
    return (
      <div className="row mt-4">
        <div className="col-lg-2 col-md-1 col-2">
          <img
            src={checkImageUrl}
            width={isMobile ? '100%' : '70%'}
            alt={title}
            style={{ marginLeft: !isMobile && marginLeft ? marginLeft : '' }}></img>
        </div>
        <div className="col-lg-10 col-md-11 col-10 section__header_landing_title">
          <div
            className={width ? `col-lg-12` : `col-lg-13`}
            style={{ marginLeft: isMobile ? '' : '-15px', paddingRight: '25px' }}>
            <p className="description--bold mb-2">{title}</p>
            <p className="description text-left" style={{ marginRight: marginToCut ? marginToCut : '' }}>
              {description}
            </p>
            {extraContent && (
              <>
                <div className="divider-thick mt-4 mb-4"></div>
                <p className="description">{extraContent}</p>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default CurriculumCard
