import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  Slide,
  Stepper,
  Step,
  StepLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core'
import { Done as CorrectIcon } from '@material-ui/icons'
import _ from 'lodash'
import moment from 'moment'
import Proptypes from 'prop-types'
import agent from 'agent'
import { GET_LESSON_ACTIVITY, SEARCH_CLASS, UPDATE_LESSON } from 'constants/actionTypes'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const mapStateToProps = state => ({
  searching: state.classes.searching,
  searchList: state.classes.searchList,
  loadingLessonActivity: state.activity.loadingLessonActivity,
  lessonActivity: state.activity.lessonActivity
})

const mapDispatchToProps = dispatch => ({
  searchClass: keyword => dispatch({ type: SEARCH_CLASS, payload: agent.Classes.search(keyword) }),
  getLesson: lessonId => dispatch({ type: GET_LESSON_ACTIVITY, payload: agent.Lessons.getById2(lessonId) }),
  importActivity: (lessonId, reqPayload) =>
    dispatch({ type: UPDATE_LESSON, payload: agent.Lessons.update(lessonId, reqPayload) })
})

const STEPS = ['Search & Select Class', 'Select Lesson', 'Review & Import Activity']

class ImportActivityDialog extends React.Component {
  state = {
    activeStep: 0,
    classTitle: '',
    selectedClassId: '',
    lessonList: [],
    selectedLessonId: ''
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
    }
  }

  handleChange = evt => {
    const name = evt.target.name
    const value = evt.target.value
    this.setState({ [name]: value })
  }

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.handleSearch()
    }
  }

  handleSearch = () => {
    const { classTitle } = this.state
    this.props.searchClass(classTitle.trim())
  }

  handleClassSelection = classData => () => {
    this.setState({
      selectedClassId: classData._id,
      lessonList: classData.lesson,
      activeStep: 1
    })
  }

  handleLessonSelection = lessonId => () => {
    this.setState({
      selectedLessonId: lessonId,
      activeStep: 2
    })
    this.props.getLesson(lessonId)
  }

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 })
  }

  handleSave = () => {
    const { lessonActivity, lessonId } = this.props
    let formattedActivity = []
    for (let i = 0; i < lessonActivity.activity.length; i++) {
      formattedActivity.push({
        order: i + 1,
        content: lessonActivity.activity[i].content._id
      })
    }
    this.props.importActivity(lessonId, { activity: formattedActivity })
  }

  render() {
    const { isOpen, searching, searchList, loadingLessonActivity, lessonActivity } = this.props
    const { activeStep, classTitle, lessonList, selectedLessonId } = this.state

    return (
      <Dialog open={isOpen} maxWidth="md" fullWidth TransitionComponent={Transition}>
        <DialogTitle onClose={this.props.close}>
          <span className="h3 tittle--rubik">Import Activity</span>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <div>
            <Stepper activeStep={activeStep} className="mb-3">
              {STEPS.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {activeStep === 0 && (
              <>
                <div className="d-flex align-items-center">
                  <TextField
                    type="text"
                    label="Class Title (keywords)"
                    name="classTitle"
                    onChange={this.handleChange}
                    fullWidth
                    value={classTitle}
                    onKeyPress={this.handleKeyPress}
                    className="mr-2"
                  />
                  <Button variant="text" color="primary" onClick={this.handleSearch}>
                    Search
                  </Button>
                </div>
                {searching && (
                  <div className="text-center my-4">
                    <CircularProgress size={30} color="primary" className="text-center" />
                  </div>
                )}

                <div className="text-muted mt-3">Result: {searchList.length}</div>
                <List dense>
                  {searchList.map(classData => (
                    <ListItem key={classData._id} button divider onClick={this.handleClassSelection(classData)}>
                      <ListItemText
                        primary={`${classData.name} (Year: ${moment(classData.start_date).format('YYYY')})`}
                        secondary={`by ${_.map(classData.tutor, 'name').join(', ')}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </>
            )}

            {activeStep === 1 && (
              <>
                <div className="text-muted mt-3">Lessons: {lessonList.length}</div>
                <List dense>
                  {lessonList.map(lessonData => (
                    <ListItem key={lessonData._id} button divider onClick={this.handleLessonSelection(lessonData._id)}>
                      <ListItemText
                        primary={`${lessonData.meta?.term && `[Term ${lessonData.meta.term}] `}
                          ${lessonData.meta?.index && `Lesson ${lessonData.meta.index}:`} ${lessonData.name}`}
                        secondary={`on ${moment(lessonData.scheduled_on).format('ll')}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </>
            )}

            {activeStep === 2 && (
              <>
                <div className="text-muted mt-3">Lesson's activity</div>
                {loadingLessonActivity && (
                  <div className="text-center my-4">
                    <CircularProgress size={30} color="primary" className="text-center" />
                  </div>
                )}
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Question</TableCell>
                      <TableCell>Answer</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lessonActivity._id === selectedLessonId &&
                      lessonActivity.activity.map(activityData => (
                        <TableRow key={activityData.content._id}>
                          <TableCell>{activityData.order}</TableCell>
                          <TableCell>
                            <div className="manage-margin">
                              <Chip label={activityData.content._type} />
                              {activityData.content.mode && <Chip label={activityData.content.mode} />}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div dangerouslySetInnerHTML={{ __html: activityData.content.question }} />
                          </TableCell>
                          <TableCell>
                            {activityData.content._type === 'MultipleChoice' && (
                              <>
                                {activityData.content.option.map((o, index) => (
                                  <div key={index} className="d-flex align-items-center">
                                    {o.label}. {o.content}
                                    {activityData.content.answer_index.includes(index) && (
                                      <CorrectIcon
                                        className="ml-1 text-success"
                                        style={{ width: '0.8em', height: '0.8em' }}
                                      />
                                    )}
                                  </div>
                                ))}
                              </>
                            )}
                            {activityData.content._type === 'OpenEnded' && (
                              <div className="d-flex flex-wrap align-items-center manage-margin">
                                {activityData.content.answer.map((o, index) => (
                                  <Chip key={index} label={o} />
                                ))}
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <div className="d-flex justify-content-between w-100">
            <Button variant="outlined" color="primary" onClick={this.props.close}>
              Cancel
            </Button>
            <div>
              <Button variant="text" color="default" disabled={activeStep === 0} onClick={this.handleBack}>
                Back
              </Button>
              {activeStep === 2 && (
                <Button variant="contained" color="primary" onClick={this.handleSave}>
                  Confirm Add Activity
                </Button>
              )}
            </div>
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}

ImportActivityDialog.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired,
  activityData: Proptypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportActivityDialog)
