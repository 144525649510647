import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Accordion, AccordionSummary, AccordionActions, Button, Divider, Grid, Badge } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import _ from 'lodash'
import moment from 'moment'
import qs from 'qs'
import agent from 'agent'
import { GET_FEEDBACK, GET_LESSON_BY_ID, RECORD_USER_ACTION } from 'constants/actionTypes'
import JoinLessonButton from 'components/online-class/button-join-lesson'
import DialogCreateFeedback from 'components/student/DialogCreateFeedback'
import PlayRecordingDialog from 'components/online-class/dialog-play-recording'
import { isMobile } from 'react-device-detect'

const mapStateToProps = state => ({
  token: state.common.token,
  selectedClass: state.classes.selectedClass,
  currentUser: state.common.currentUser,
  feedbackList: state.feedback.feedbackList,
  feedbackCount: state.feedback.feedbackCount,
  feedbackCreated: state.feedback.feedbackCreated,
  feedbackInProgress: state.feedback.inProgress
})

const mapDispatchToProps = dispatch => ({
  getLessonById: lessonId => dispatch({ type: GET_LESSON_BY_ID, payload: agent.Lessons.getById(lessonId) }),
  getFeedback: filter => dispatch({ type: GET_FEEDBACK, payload: agent.UserInput.getFeedback(filter) }),
  trackAction: reqPayload => dispatch({ type: RECORD_USER_ACTION, payload: agent.Misc.recordAction(reqPayload) })
})

const FeedbackButton = props => {
  const { lesson, toggleFeedback, feedbackList, inProgress } = props

  let exists =
    _.findIndex(feedbackList, f => {
      return f.feedback_target_id === lesson._id
    }) !== -1
  let isPast = moment() <= moment(lesson.ended_on)

  if (isPast || exists) {
    return null
  } else {
    return (
      <Button variant={'outlined'} color={'primary'} onClick={toggleFeedback} disabled={inProgress}>
        Rate Lesson
      </Button>
    )
  }
}

const OverallImage = props => {
  return (
    <img
      src={`https://epa-assets.s3.ap-southeast-1.amazonaws.com/lms/${props.props}`}
      width={isMobile ? '300' : '400'}
      height={isMobile ? '200' : '250'}></img>
  )
}

class TabLiveLesson extends React.Component {
  state = {
    activeMonth: this.props.activeMonth,
    activeMonthLessons: [],
    activeLessonId: '',
    recordingDialogOpen: false,
    lessonData: '',
    activeTerm: this.props.activeTerm,
    feedbackOpen: false,
    feedbackLesson: null,
    expandAll: false
  }

  expandAllAccordion = () => {
    this.setState({ expandAll: !this.state.expandAll })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeMonth !== this.props.activeMonth) {
      this.setState({ activeMonth: this.props.activeMonth })
    }

    if (prevProps.activeTerm !== this.props.activeTerm) {
      this.setState({ activeTerm: this.props.activeTerm })
    }

    if (prevState.activeMonth !== this.state.activeMonth || prevState.activeTerm !== this.state.activeTerm) {
      this.processLessonData()
    }

    if (prevState.activeMonthLessons !== this.state.activeMonthLessons) {
      this.getFeedback(this.state.activeMonthLessons)
    }

    if (!prevProps.feedbackCreated && this.props.feedbackCreated) {
      this.getFeedback(this.state.activeMonthLessons)
    }
  }

  //NOTE: Tab LiveLesson, Tab Resource is having same process lesson data, try to optimize it
  processLessonData = () => {
    let { selectedClass, firstLoad, showByTerm } = this.props
    let { activeMonth, activeTerm } = this.state
    let activeMonthLessons = []
    let sortedActiveMonthLessons = []
    let activeLessonId

    if (showByTerm) {
      activeMonthLessons = _.filter(selectedClass.lesson, o => {
        return parseInt(o.meta.term) === activeTerm
      })
      sortedActiveMonthLessons = _.sortBy(activeMonthLessons, ['scheduled_on'])
      let parsed = qs.parse(this.props.location.search.slice(1))

      if (firstLoad && parsed.tab && parsed.tab === 'live-lesson' && parsed.lid) {
        activeLessonId = parsed.lid
      } else {
        let activeLesson = _.find(sortedActiveMonthLessons, function (o) {
          return moment(o.ended_on).add(20, 'minutes') > moment()
        })
        activeLessonId = activeLesson ? activeLesson._id : ''
      }
    } else {
      if (activeMonth === 'All') {
        activeMonthLessons = selectedClass.lesson
      } else {
        activeMonthLessons = _.filter(selectedClass.lesson, function (o) {
          return moment(o.scheduled_on).isSame(moment(activeMonth), 'month')
        })
      }

      sortedActiveMonthLessons = _.sortBy(activeMonthLessons, ['scheduled_on'])
      let parsed = qs.parse(this.props.location.search.slice(1))

      if (firstLoad && parsed.tab && parsed.tab === 'live-lesson' && parsed.lid) {
        activeLessonId = parsed.lid
      } else {
        let activeLesson = _.find(sortedActiveMonthLessons, function (o) {
          if (activeMonth === 'All') {
            return moment(o.ended_on).add(20, 'minutes') > moment()
          } else {
            return moment().isSame(moment(activeMonth), 'month') && moment(o.ended_on).add(20, 'minutes') > moment()
          }
        })
        activeLessonId = activeLesson ? activeLesson._id : ''
      }
    }

    this.setState({
      activeMonthLessons: sortedActiveMonthLessons,
      activeLessonId
    })
  }

  toggleRecordingDialog = lesson => () => {
    this.setState({ recordingDialogOpen: !this.state.recordingDialogOpen, lessonData: lesson })
  }

  // handleOpen = lessonId => () => {
  //   if(activeLessonId === lessonId) {
  //     this.setState({ })
  //   }
  //   if(openDay !== day) setOpenDay(day)
  // }

  getFeedback = lessonArray => {
    const { currentUser } = this.props

    if (!!lessonArray && lessonArray.length > 0) {
      let filter = {
        created_by: currentUser._id
      }

      filter.feedback_target_id = {
        $in: lessonArray.map(l => {
          return l._id
        })
      }
      this.props.getFeedback(filter)
    }
  }

  toggleCreateFeedback = lesson => () => {
    this.setState({ feedbackLesson: lesson, feedbackOpen: true })
  }

  render() {
    const { currentUser, feedbackList, selectedClass } = this.props
    const {
      activeMonthLessons,
      activeLessonId,
      feedbackOpen,
      feedbackLesson,
      recordingDialogOpen,
      lessonData,
      expandAll
    } = this.state

    return (
      <div className="animated slideInUpTiny animation-duration-3">
        {/* <Button variant="outlined" onClick={this.expandAllAccordion}> {`${expandAll ? 'Close' : 'Open'}`} All</Button> */}
        {[...activeMonthLessons].reverse().map(lesson => {
          if (!lesson.is_active) {
            return null
          }

          let total =
            (parseInt(lesson?.lesson_meta?.engagement?.engagementParticipation) +
              parseInt(lesson?.lesson_meta?.engagement?.engagementDistracted) +
              parseInt(lesson?.lesson_meta?.engagement?.engagementTiredBored) +
              parseInt(lesson?.lesson_meta?.engagement?.engagementMotivate) +
              parseInt(lesson?.lesson_meta?.engagement?.engagementAskQuestions)) /
            5
          let overall = Math.round(total)

          let totalMastery =
            (parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion) +
              parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryConfused) +
              parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding) +
              parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryParticipative) +
              parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept)) /
            5
          let overallMastery = Math.round(totalMastery)

          const isActiveLesson = activeLessonId === lesson._id

          let isUserLesson = false
          if (lesson.student.includes(currentUser._id) || lesson.trial_student.includes(currentUser._id)) {
            isUserLesson = true
          }
          let recordingStatus = ''
          let lessonEnded = false
          if (moment(lesson.ended_on).add(30, 'minutes') < moment()) {
            lessonEnded = true
            // recordingStatus = 'Recording available soon...'
            recordingStatus = ''
            if (lesson.playback_url && lesson.playback_url.length > 0) {
              lessonEnded = true
              recordingStatus = 'Recording available'
            }
          }

          return (
            <Accordion
              // retarded expanded thing
              // expanded={expandAll && !isActiveLesson}
              defaultExpanded={isUserLesson && isActiveLesson}
              // onClick={this.handleOpen(lesson._id)}
              key={lesson._id}
              disabled={!isUserLesson}
              style={{ zIndex: '0' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className="row align-items-center w-100">
                  <div className="col">
                    {lesson.meta && <div className="text-small text-muted">Lesson {lesson.meta.index}</div>}
                    <h3 className="title--rubik mb-0">{lesson.name.slice(6)}</h3>
                    {/* id+level+subject IN CASE NAME IS NOT THE SAME, CHANGE HERE */}
                  </div>

                  {(!lessonEnded || isActiveLesson) && (
                    <div className="col-auto">
                      {lessonEnded && <div className="tag danger-tag">Ended</div>}
                      {isActiveLesson &&
                        (moment() > moment(lesson.scheduled_on) && moment() < moment(lesson.ended_on) ? (
                          <div className="tag success-tag">Ongoing</div>
                        ) : (
                          <div className="tag success-tag">Next</div>
                        ))}
                    </div>
                  )}

                  {isUserLesson && lesson.lesson_meta?.comment?.commentStudentView && (
                    <span className="warning-chip">1 comment</span>
                  )}

                  <div className="col-auto text-md-right">
                    {isUserLesson ? (
                      recordingStatus ? (
                        <span>
                          {recordingStatus === 'Recording available' ? '' : recordingStatus}{' '}
                          {recordingStatus === 'Recording available' && (
                            <Button variant="contained" color="primary" onClick={this.toggleRecordingDialog(lesson)}>
                              Watch Recording
                            </Button>
                          )}
                        </span>
                      ) : (
                        <div id={`${isActiveLesson ? 'nextLiveLesssonTime' : ''}`}>
                          {isActiveLesson && moment(lesson.scheduled_on).diff(moment(), 'minutes') < 30 ? (
                            <span className="blink text-danger">
                              {moment(lesson.scheduled_on).format('ddd D MMMM, h:mma')}
                            </span>
                          ) : (
                            moment(lesson.scheduled_on).format('ddd D MMMM, h:mma')
                          )}
                        </div>
                      )
                    ) : (
                      'Not assigned yet'
                    )}
                  </div>
                  <div className="col-auto">
                    {isUserLesson && !lessonEnded && lesson.hosting_platform === 'Zoom' && (
                      <JoinLessonButton lessonData={lesson} />
                    )}

                    <FeedbackButton
                      lesson={lesson}
                      toggleFeedback={this.toggleCreateFeedback(lesson)}
                      feedbackList={feedbackList}
                      inProgress={this.props.feedbackInProgress}
                    />
                  </div>
                </div>
              </AccordionSummary>
              <Divider />
              <AccordionActions>
                <div className="app-wrapper">
                  <div className="row align-items-center">
                    <h3 className="col-md-6 col-6 mt-4"> Engagement </h3>
                    <h3 className="col-md-5 mt-4 d-none d-md-block"> Mastery of skills </h3>
                    <div className="col-md-6 col-12 my-2">
                      {overall === 1 && <OverallImage props={'scale1_bad.png'} />}
                      {overall === 2 && <OverallImage props={'scale2_notgood.png'} />}
                      {overall === 3 && <OverallImage props={'scale3_good.png'} />}
                      {overall === 4 && <OverallImage props={'scale4_good.png'} />}
                      {overall === 5 && <OverallImage props={'scale5_verygood.png'} />}
                      {overall === 6 && <OverallImage props={'scale6_excellent.png'} />}
                    </div>

                    <h3 className="col-md-6 col-12 mt-4 d-md-none d-block"> Mastery of skills </h3>
                    <div className="col-md-6 col-12 my-2">
                      {overallMastery === 1 && <OverallImage props={'scale1_bad.png'} />}
                      {overallMastery === 2 && <OverallImage props={'scale2_notgood.png'} />}
                      {overallMastery === 3 && <OverallImage props={'scale3_good.png'} />}
                      {overallMastery === 4 && <OverallImage props={'scale4_good.png'} />}
                      {overallMastery === 5 && <OverallImage props={'scale5_verygood.png'} />}
                      {overallMastery === 6 && <OverallImage props={'scale6_excellent.png'} />}
                    </div>

                    <h3 className="col-md-3 d-none d-md-block mt-5"> Punctuality </h3>
                    <div className="col-md-8 my-2 d-none d-md-block">{/*Punctuality space block*/}</div>

                    <div className="col-md-1 col-12 mb-3 mb-md-2">{/*Punctuality space block*/}</div>
                    <h3 className="col-5 d-md-none d-flex"> Punctuality </h3>

                    <div className="col-md-4 col-8 my-2"> Late to class? </div>
                    <div className="col-md-8 col-4 my-2">
                      {lesson?.lesson_meta?.punctuality === 'y' ? (
                        <span className="danger-chip">Yes</span>
                      ) : lesson?.lesson_meta?.punctuality === 'n' ? (
                        'No'
                      ) : (
                        '---'
                      )}
                    </div>

                    <h3 className="col-md-3 col-12 mt-5"> Lesson topics / Skills taught </h3>
                    <div className="col-md-9 col-0 my-2">{/*Lesson topic space block*/}</div>

                    <div className="col-md-4 col-4 my-2">Skills taught in this lesson</div>
                    <div className="col-md-8 col-8 my-2">
                      {lesson?.lesson_meta?.lesson_topic === '' ? '---' : lesson?.lesson_meta?.lesson_topic}
                    </div>
                    <h3 className="col-md-3 col-12 mt-5"> Homework Assignment </h3>
                    <div className="col-md-9 col-0 my-2">{/*Homework Assignment space block*/}</div>
                    <div className="col-md-4 col-8 my-2">Any homework submission?</div>
                    <div className="col-md-2 col-4 my-2">
                      {lesson?.lesson_meta?.homework?.homeworkAssignment === 'y'
                        ? 'Yes'
                        : lesson?.lesson_meta?.homework?.homeworkAssignment === 'n'
                        ? 'No'
                        : '---'}
                    </div>

                    {lesson?.lesson_meta?.homework?.homeworkAssignment === 'y' && (
                      <>
                        <div className="col-md-4 col-8 my-2">Homework Submission</div>
                        <div className="col-md-2 col-4 my-2">
                          {lesson?.lesson_meta?.homework?.homeworkSubmission === '1' ? (
                            'Yes, on time'
                          ) : lesson?.lesson_meta?.homework?.homeworkSubmission === '2' ? (
                            <span className="warning-chip">Yes, but late</span>
                          ) : lesson?.lesson_meta?.homework?.homeworkSubmission === '3' ? (
                            <span className="danger-chip">No</span>
                          ) : (
                            '---'
                          )}
                        </div>

                        <div className="col-md-4 col-8 my-2">Is homework past paper?</div>
                        <div className="col-md-2 col-4 my-2">
                          {lesson?.lesson_meta?.homework?.homeworkSubmissionPastPaper ? 'Yes' : 'No'}
                        </div>

                        {lesson?.lesson_meta?.homework?.homeworkSubmissionPastPaper && (
                          <>
                            <div className="col-md-4 col-8 my-2">Result / Comments on past paper</div>
                            <div className="col-md-2 col-4 my-2">
                              {lesson?.lesson_meta?.homework?.homeworkSubmissionComment}
                            </div>
                          </>
                        )}

                        {!lesson?.lesson_meta?.homework?.homeworkSubmissionPastPaper && (
                          <>
                            {' '}
                            <div className="col-md-4 col-8 my-2">{/*space here*/}</div>
                            <div className="col-md-2 col-4 my-2">{/*space here*/}</div>
                          </>
                        )}
                      </>
                    )}

                    {lesson?.lesson_meta?.homework?.homeworkAssignment !== 'y' && (
                      <>
                        <div className="col-md-4 col-8 my-2">{/*space here*/}</div>
                        <div className="col-md-2 col-4 my-2">{/*space here*/}</div>
                      </>
                    )}

                    <h3 className="col-md-3 col-12 mt-5"> Revision Notes </h3>
                    <div className="col-md-9 col-0 my-2">{/* Revision Notes space block*/}</div>

                    <div className="col-md-4 col-8 my-2">Revision Notes Submission</div>
                    <div className="col-md-2 col-4 my-2">
                      {lesson?.lesson_meta?.revision_notes === '1' ? (
                        'Yes, on time'
                      ) : lesson?.lesson_meta?.revision_notes === '2' ? (
                        <span className="warning-chip">Yes, but late</span>
                      ) : lesson?.lesson_meta?.revision_notes === '3' ? (
                        <span className="danger-chip">No</span>
                      ) : (
                        '---'
                      )}
                    </div>

                    <div className="col-md-4 col-8 my-2">{/*space here*/}</div>
                    <div className="col-md-2 col-4 my-2">{/*space here*/}</div>
                    {lesson.lesson_meta?.comment?.commentStudentView && (
                      <>
                        <h3 className="col-md-3 col-12 mt-5"> Comment </h3>
                        <div className="col-md-9 col-0 my-2">{/* Comment space block*/}</div>

                        <div className="col-md-4 col-4 my-2">Comments on student for this lesson</div>
                        <div className="col-md-8 col-8 my-2">{lesson.lesson_meta?.comment?.comment}</div>
                      </>
                    )}

                    <h3 className="col-md-3 col-12 mt-5"> Recommendation </h3>
                    <div className="col-md-9 col-0 my-2">{/* Recommendation space block*/}</div>

                    <div className="col-md-4 col-4 my-2">More Lessons </div>
                    <div className="col-md-8 col-8 my-2">
                      {lesson?.lesson_meta?.recommendation?.recommendationLesson ? 'Yes' : 'No'}
                    </div>

                    <div className="col-md-4 col-4 my-2">Consult with Kru A? </div>
                    <div className="col-md-8 col-8 my-2">
                      {lesson?.lesson_meta?.recommendation?.recommendationConsult ? 'Yes' : 'No'}
                    </div>

                    <div className="col-md-4 col-4 my-2">Others / Remark</div>
                    <div className="col-md-8 col-8 my-2">
                      {lesson?.lesson_meta?.recommendation?.recommendationOthers === ''
                        ? '---'
                        : lesson?.lesson_meta?.recommendation?.recommendationOthers}
                    </div>

                    {/* <div className="col-md-4 col-4 my-2">Kru A to call immediately </div>
                    <div className="col-md-8 col-8 my-2">
                      {lesson?.lesson_meta?.recommendation?.recommendationConsultImmediately ? 'Yes' : 'No'}
                    </div> */}
                  </div>
                </div>
              </AccordionActions>
            </Accordion>
          )
        })}

        <PlayRecordingDialog
          isOpen={recordingDialogOpen}
          close={this.toggleRecordingDialog()}
          lessonId={lessonData ? lessonData._id : ''}
          originalVideoUrl={lessonData ? lessonData.playback_url : ''}
        />

        {feedbackOpen && !!feedbackLesson && (
          <DialogCreateFeedback isOpen={feedbackOpen} lesson={feedbackLesson} toggle={this.toggleCreateFeedback()} />
        )}
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TabLiveLesson))
