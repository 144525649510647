import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import {
  USER_PROFILE,
  USER_PROFILE_EDIT,
  USER_PASSWORD_EDIT,
  ONLINE_CLASS_LIST,
  ONLINE_CLASS_DETAILS,
  MY_CLASS_LIST,
  MY_CLASS_DETAILS,
  FAQ,
  APP,
  TERMS_AND_CONDITION_FOR_REFERRAL,
  PRIVACY_POLICY,
  TERM_OF_SERVICE,
  SHOPPING_CART,
  TRIAL,
  CONVERSION_VARIANT_A,
  CONVERSION_VARIANT_B,
  CONVERSION_VARIANT_B_WITH_LESSON_COUNT,
  CONVERSION_VARIANT_C,
  CONVERSION_VARIANT_D,
  BLOG_POST,
  FREE_SHARING,
  FREE_SHARING_VIDEO
} from './routes'

import ClassListing from '../components/student/Classes'
import BlogPostPage from '../components/shared/Blog/Post'
import ConversionVariantA from '../components/shared/onlineClass/conversion/variantA'
import ConversionVariantB from '../components/shared/onlineClass/conversion/variantB'
import ConversionVariantC from '../components/shared/onlineClass/conversion/variantC'
import ConversionVariantD from '../components/shared/onlineClass/conversion/variantD'
import HomeworkDetailPage from '../components/student/Homework'

import FAQPage from 'pages/public/faq'
import PrivacyPolicyPage from 'pages/public/privacy-policy'
import TermOfServicePage from 'pages/public/term-of-service'
import TermsAndConditionsForReferral from 'pages/public/tnc-referral'

import ClassCataloguePage from 'pages/common/online-class/catalogue'
import ClassInfoPage from 'pages/common/online-class/info'
import TrialPage from 'pages/common/online-class/trial'
import FreeSharingPage from 'pages/common/free-sharing/list'
import FreeSharingPlaybackPage from 'pages/common/free-sharing/playback'
import CartPage from 'pages/common/cart'
import PostPaymentPage from 'pages/common/cart/post-payment.js'

import DashboardPage from 'pages/app/dashboard/student'
import MyOngoingClassesPage from 'pages/app/my-class/list/student/ongoing'
import MyTrialClassesPage from 'pages/app/my-class/list/student/trial'
import MyCompletedClassesPage from 'pages/app/my-class/list/student/completed'
import MyClassDetailsPage from 'pages/app/my-class/detail/student'
import MyLessonDetailsPage from 'pages/app/my-lesson/student'
import StudentProfilePage from 'pages/app/profile/student'
import StudentProfileEditPage from 'pages/app/profile/student/edit'
import EditPasswordPage from 'pages/app/profile/edit-password'
import TransactionHistoryPage from 'pages/app/profile/student/transaction-history'

export const TRANSACTION_HISTORY = '/transaction'
export const HOMEWORK_DETAIL = '/hw/:homeworkId'
export const POST_PAYMENT = '/cart/post-payment'
export const DASHBOARD = '/dashboard'
export const MY_PAID_CLASSES = '/my-class/paid'
export const MY_TRIAL_CLASSES = '/my-class/trial'
export const MY_ARCHIVED_CLASSES = '/my-class/archived'
export const MY_LESSON_DETAILS = '/my-lesson/:lessonId'

/*App Route*/
export const StudentRoutes = () => (
  <Switch>
    <Route exact path={USER_PROFILE} component={StudentProfilePage} />
    <Route exact path={USER_PROFILE_EDIT} component={StudentProfileEditPage} />
    <Route exact path={USER_PASSWORD_EDIT} component={EditPasswordPage} />
    <Route path={MY_CLASS_DETAILS} component={MyClassDetailsPage} />
    <Route path={MY_CLASS_LIST} component={ClassListing} />
    <Route exact path={ONLINE_CLASS_LIST} component={ClassCataloguePage} />
    <Route path={ONLINE_CLASS_DETAILS} component={ClassInfoPage} />
    <Route path={`${APP}${TRANSACTION_HISTORY}`} component={TransactionHistoryPage} />
    <Route path={`${APP}${TERMS_AND_CONDITION_FOR_REFERRAL}`} component={TermsAndConditionsForReferral} />
    <Route path={`${APP}${FAQ}`} component={FAQPage} />
    <Route path={`${APP}${PRIVACY_POLICY}`} component={PrivacyPolicyPage} />
    <Route path={`${APP}${TERM_OF_SERVICE}`} component={TermOfServicePage} />
    <Route path={`${APP}${POST_PAYMENT}`} component={PostPaymentPage} />
    <Route path={`${APP}${SHOPPING_CART}`} component={CartPage} />
    <Route path={`${APP}${CONVERSION_VARIANT_A}`} component={ConversionVariantA} />
    <Route path={`${APP}${CONVERSION_VARIANT_B_WITH_LESSON_COUNT}`} component={ConversionVariantB} />
    <Route path={`${APP}${CONVERSION_VARIANT_B}`} component={ConversionVariantB} />
    <Route path={`${APP}${CONVERSION_VARIANT_C}`} component={ConversionVariantC} />
    <Route path={`${APP}${CONVERSION_VARIANT_D}`} component={ConversionVariantD} />
    <Route path={`${APP}${TRIAL}`} component={TrialPage} />
    <Route path={`${APP}${BLOG_POST}`} component={BlogPostPage} />
    <Route path={`${APP}${FREE_SHARING_VIDEO}`} component={FreeSharingPlaybackPage} />
    <Route path={`${APP}${FREE_SHARING}`} component={FreeSharingPage} />
    <Route path={`${APP}${HOMEWORK_DETAIL}`} component={HomeworkDetailPage} />
    <Route path={`${APP}${DASHBOARD}`} component={DashboardPage} />
    <Route path={`${APP}${MY_PAID_CLASSES}`} component={MyOngoingClassesPage} />
    <Route path={`${APP}${MY_TRIAL_CLASSES}`} component={MyTrialClassesPage} />
    <Route path={`${APP}${MY_ARCHIVED_CLASSES}`} component={MyCompletedClassesPage} />
    <Route path={`${APP}${MY_LESSON_DETAILS}`} component={MyLessonDetailsPage} />
    <Redirect path="*" to={`${APP}${DASHBOARD}`} />
  </Switch>
)
