import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const NavbarItemTitle = styled.button`
  background: transparent;
  border: 0;
  font-family: inherit;
  font-size: 15px;
  color: #000;
  display: flex;
  align-self: center;
  padding: 0 15px;
  transition: opacity 250ms;
  cursor: pointer;
  /* position above the dropdown, otherwise the dropdown will cover up the bottom sliver of the buttons */
  position: relative;
  z-index: 2;
  &:hover,
  &:focus {
    color: #fd6502;
    outline: none;
  }
`

const NavbarItemEl = styled.li`
  position: relative;
  align-self: center;
`

const DropdownSlot = styled.div`
  position: absolute;
  left: 40%;
  top: 40%;
  transform: translateX(-50%);
  perspective: 1500px;
  z-index: 1;
`

const Item = props => {
  let { badge, title, active, isServicesColorHover } = props

  if (badge) {
    return (
      <div className="d-flex" style={{ height: '35px' }}>
        <span className="align-self-center">{title}</span>
        <span className="link-tag align-self-start">{badge}</span>
      </div>
    )
  }

  return (
    <>
      {title === 'Our Services' ? (
        <span className="info-description-black">
          <Link
            to={'/services'}
            style={{ color: isServicesColorHover || active ? '#fd6502' : 'black', textDecoration: 'none' }}>
            {title}
          </Link>
        </span>
      ) : (
        <span className="info-description-black">{title}</span>
      )}
    </>
  )
}

export default class NavbarItem extends Component {
  static propTypes = {
    onMouseEnter: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    children: PropTypes.node
  }

  onMouseEnter = () => {
    this.props.onMouseEnter(this.props.index)
  }

  render() {
    const { title, badge, children, linkOnly, active, expanded, noLeftPadding, isServicesColorHover } = this.props

    if (linkOnly) {
      return (
        <NavbarItemEl onClick={this.props.goToPage} onMouseEnter={this.onMouseEnter}>
          <NavbarItemTitle className={active ? 'text-primary' : ''}>
            <Item title={title} badge={badge} />
          </NavbarItemTitle>
        </NavbarItemEl>
      )
    }

    return (
      <NavbarItemEl onMouseEnter={this.onMouseEnter} onFocus={this.onMouseEnter}>
        <NavbarItemTitle className={`${active ? 'text-primary' : ''} ${noLeftPadding ? 'pl-0' : ''}`}>
          <div className="d-flex align-items-center">
            <Item title={title} badge={badge} active={active} isServicesColorHover={isServicesColorHover} />
            {expanded ? (
              <span className="material-icons" style={{ color: '#FD6502' }}>
                expand_less
              </span>
            ) : (
              <span className="material-icons" style={{ color: '#FD6502' }}>
                expand_more
              </span>
            )}
          </div>
        </NavbarItemTitle>
        <DropdownSlot id="dps" style={{ marginTop: `${badge ? '-6px' : '0'}` }}>
          {children}
        </DropdownSlot>
      </NavbarItemEl>
    )
  }
}
