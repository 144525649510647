import React from 'react'
import Proptypes from 'prop-types'
import { Button, Dialog, DialogTitle, DialogContent, Slide } from '@material-ui/core'

import Waveform from 'components/shared/Media/Waveform'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

class FilePreviewDialog extends React.Component {
  state = {
    file: null,
    fileType: ''
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.file !== this.props.file && this.props.file) {
      this.setState({ file: this.props.file })
    }

    if (prevState.file !== this.state.file) {
      let mimetype = this.state.file.meta ? this.state.file.meta.mimetype : this.state.file.mimetype
      let fileType = ''

      if (mimetype) {
        if (
          mimetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          mimetype === 'application/msword'
        ) {
          fileType = 'doc'
        } else if (mimetype === 'application/pdf') {
          fileType = 'pdf'
        } else if (mimetype.split('/')[0] === 'image') {
          fileType = 'image'
        } else if (mimetype === 'audio/wav') {
          fileType = 'audio'
        }
      } else {
        let fileUrl = this.state.file.url
        let splitFileUrl = fileUrl.split('.')
        let fileExtension = splitFileUrl[splitFileUrl.length - 1]
        fileExtension = fileExtension.toLowerCase()

        if (fileExtension === 'doc' || fileExtension === 'docx') {
          fileType = 'doc'
        } else if (fileExtension === 'pdf') {
          fileType = 'pdf'
        } else if (['jpg', 'jpeg', 'png', 'bmp'].includes(fileExtension)) {
          fileType = 'image'
        } else if (fileExtension === 'wav') {
          fileType = 'audio'
        }
      }

      this.setState({ fileType })
    }
  }

  render() {
    let { file, fileType } = this.state
    let { isOpen } = this.props

    return (
      <Dialog open={isOpen} onClose={this.props.close} TransitionComponent={Transition} maxWidth="md" fullWidth>
        <DialogTitle>
          <div className="d-flex align-items-center justify-content-between">
            <div className="h2 title--rubik mb-0">{file?.name}</div>
            <span className="d-flex align-items-center justify-content-end">
              <Button variant="outlined" color="primary" size="small" onClick={this.props.close} className="mr-3">
                Close
              </Button>
              <Button variant="outlined" color="primary" href={file?.url} target="_blank" size="small" download>
                Download
              </Button>
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          {file && (
            <>
              {['doc', 'pdf'].includes(fileType) && (
                <iframe
                  src={`https://docs.google.com/gview?url=${file.url}&embedded=true`}
                  frameBorder="0"
                  style={{ height: '70vh', width: '100%' }}
                  title="Resource"
                />
              )}
              {fileType === 'image' && (
                <div className="manage-margin text-center">
                  <img src={file.url} alt={file.name} className="img-fluid shadow" />
                </div>
              )}
              {fileType === 'audio' && <Waveform src={file.url} isBlob={false} />}
            </>
          )}
        </DialogContent>
      </Dialog>
    )
  }
}

FilePreviewDialog.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired,
  file: Proptypes.object
}

export default FilePreviewDialog
