import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Clipboard from 'react-clipboard.js'
import { Button, Card, CardContent, CircularProgress, Paper, Switch, Popover, MenuItem } from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import agent from 'agent'
import educationLevels from 'assets/education_level.json'
import { GET_CLASS_BY_ID, SUBSCRIBE_TO_SERVICE, UPDATE_USER, GET_USER_CLASS } from 'constants/actionTypes'
import { ALL_CLASSES, USER_PROFILE_EDIT, USER_PASSWORD_EDIT, APP, FAQ, SIGNUP } from 'routes/routes'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import Chart from 'react-apexcharts'
import ClassReportToolTip from 'components/shared/Report/reportTooltip'

const OverallImage = props => {
  return (
    <img
      src={`https://epa-assets.s3.ap-southeast-1.amazonaws.com/lms/${props.props}`}
      width={isMobile ? '300' : '400'}
      height={isMobile ? '200' : '250'}></img>
  )
}

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  updateUserInProgress: state.common.updateUserInProgress,
  chosenClass: state.classes.chosenClass
})

const mapDispatchToProps = dispatch => ({
  updateUser: (user, updatedUserObj) =>
    dispatch({ type: UPDATE_USER, payload: agent.Auth.updateUser(user), user: updatedUserObj }),
  subscribeService: (service, subscribe) =>
    dispatch({ type: SUBSCRIBE_TO_SERVICE, payload: agent.Misc.subscribe(service, subscribe) }),
  getStudentClass: userId => dispatch({ type: GET_USER_CLASS, payload: agent.Classes.getStudentClass(userId) })
})

class Profile extends React.Component {
  state = {
    showInviteLinkCopied: false,
    parent_report: false,
    news_letter: false,
    ontime: 0,
    late: 0,
    no: 0,
    totRNS: 0,
    totlateRNS: 0,
    totnoRNS: 0,
    options: {},
    series: [],
    optionsMastery: {},
    seriesMastery: [],
    indexClass: 0,
    classNow: [],
    classNowForMeta: []
  }

  handleShowClass = index => {
    this.setState({
      indexClass: index
    })
  }

  // to move over popover things also

  componentDidMount() {
    const { currentUser, chosenClass } = this.props
    this.setState({ classNow: chosenClass && chosenClass[0]?.lesson })

    // this.props.getClassById('FDYHrA4GR1Cb6yA8JHcsaQ')
    this.props.getStudentClass(currentUser._id)
  }

  componentDidUpdate(prevProps, prevState) {
    const { indexClass } = this.state
    const { chosenClass } = this.props
    if (prevProps.chosenClass !== chosenClass || prevState.indexClass !== this.state.indexClass) {
      let chosenLessons = chosenClass[indexClass]?.lesson
      let chosenClassNow = chosenClass[indexClass]

      let filteredHomeworkSubmission = chosenLessons?.filter(
        lessons => lessons?.lesson_meta?.homework?.homeworkAssignment === 'y'
      )

      let filteredOnTimeHWS = filteredHomeworkSubmission?.filter(
        lessons => lessons?.lesson_meta?.homework?.homeworkSubmission === '1'
      )

      let filteredLateHWS = filteredHomeworkSubmission?.filter(
        lessons => lessons?.lesson_meta?.homework?.homeworkSubmission === '2'
      )

      let filteredNoHWS = filteredHomeworkSubmission?.filter(
        lessons => lessons?.lesson_meta?.homework?.homeworkSubmission === '3'
      )

      let lateHWSLength = filteredLateHWS?.length
      let noHWSLength = filteredNoHWS?.length
      let onTimeHWSLength = filteredOnTimeHWS?.length

      let filteredHWSTotalLength = onTimeHWSLength + lateHWSLength + noHWSLength

      let tot = (onTimeHWSLength / filteredHWSTotalLength) * 100
      let totlate = (lateHWSLength / filteredHWSTotalLength) * 100
      let totno = (noHWSLength / filteredHWSTotalLength) * 100

      let filteredOnTimeRNS = chosenLessons?.filter(lessons => lessons?.lesson_meta?.revision_notes === '1')

      let filteredLateRNS = chosenLessons?.filter(lessons => lessons?.lesson_meta?.revision_notes === '2')

      let filteredNoRNS = chosenLessons?.filter(lessons => lessons?.lesson_meta?.revision_notes === '3')

      let totalRNS = filteredOnTimeRNS?.length + filteredLateRNS?.length + filteredNoRNS?.length

      let totRNS = (filteredOnTimeRNS?.length / totalRNS) * 100
      let totlateRNS = (filteredLateRNS?.length / totalRNS) * 100
      let totnoRNS = (filteredNoRNS?.length / totalRNS) * 100

      let filteredEngagementLessons = chosenLessons?.filter(
        lessons =>
          lessons?.lesson_meta?.engagement?.engagementParticipation > 0 &&
          lessons?.lesson_meta?.engagement?.engagementDistracted > 0 &&
          lessons?.lesson_meta?.engagement?.engagementTiredBored > 0 &&
          lessons?.lesson_meta?.engagement?.engagementMotivate > 0 &&
          lessons?.lesson_meta?.engagement?.engagementAskQuestions > 0
      )

      let filteredMasteryLessons = chosenLessons?.filter(
        lessons =>
          lessons?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion > 0 &&
          lessons?.lesson_meta?.skill_mastery?.skillMasteryConfused > 0 &&
          lessons?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding > 0 &&
          lessons?.lesson_meta?.skill_mastery?.skillMasteryParticipative > 0 &&
          lessons?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept > 0
      )

      const generateColors = data => {
        return data?.map((d, idx) => {
          let color = d >= 1 && d <= 2 ? '#ef4544' : d >= 3 && d <= 4 ? '#F28C28' : d >= 5 && d <= 6 ? '#50C878' : ''

          return {
            offset: (idx / data?.length) * 100,
            color,
            opacity: 1
          }
        })
      }

      this.setState({
        classNowForMeta: chosenClassNow,
        classNow: chosenLessons,
        ontime: tot,
        late: totlate,
        no: totno,
        totRNS: totRNS,
        totlateRNS: totlateRNS,
        totnoRNS: totnoRNS,
        options: {
          chart: {
            id: 'engagement'
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              colorStops: generateColors(
                filteredEngagementLessons?.map(lesson =>
                  Math.round(
                    (parseInt(lesson?.lesson_meta?.engagement?.engagementParticipation) +
                      parseInt(lesson?.lesson_meta?.engagement?.engagementDistracted) +
                      parseInt(lesson?.lesson_meta?.engagement?.engagementTiredBored) +
                      parseInt(lesson?.lesson_meta?.engagement?.engagementMotivate) +
                      parseInt(lesson?.lesson_meta?.engagement?.engagementAskQuestions)) /
                      5
                  )
                )
              )
            }
          },
          xaxis: {
            categories: filteredEngagementLessons?.map(lessons => lessons.name.slice(-15))
          },
          stroke: {
            curve: 'smooth'
          },
          title: {
            text: `Engagement level over ${filteredEngagementLessons?.length} lessons`,
            align: 'left'
          }
        },
        series: [
          {
            name: 'Engagement',
            data: filteredEngagementLessons?.map(lesson =>
              Math.round(
                (parseInt(lesson?.lesson_meta?.engagement?.engagementParticipation) +
                  parseInt(lesson?.lesson_meta?.engagement?.engagementDistracted) +
                  parseInt(lesson?.lesson_meta?.engagement?.engagementTiredBored) +
                  parseInt(lesson?.lesson_meta?.engagement?.engagementMotivate) +
                  parseInt(lesson?.lesson_meta?.engagement?.engagementAskQuestions)) /
                  5
              )
            )
          }
        ],
        optionsMastery: {
          chart: {
            id: 'mastery'
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              colorStops: generateColors(
                filteredMasteryLessons?.map(lesson =>
                  Math.round(
                    (parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion) +
                      parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryConfused) +
                      parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding) +
                      parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryParticipative) +
                      parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept)) /
                      5
                  )
                )
              )
            }
          },
          xaxis: {
            categories: filteredMasteryLessons?.map(lessons => lessons.name.slice(-15))
          },
          stroke: {
            curve: 'smooth'
          },
          title: {
            text: `Mastery of skills over ${filteredMasteryLessons?.length} lessons`,
            align: 'left'
          }
        },
        seriesMastery: [
          {
            name: 'Mastery',
            data: filteredMasteryLessons?.map(lesson =>
              Math.round(
                (parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion) +
                  parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryConfused) +
                  parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding) +
                  parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryParticipative) +
                  parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept)) /
                  5
              )
            )
          }
        ]
      })
    }
  }

  getInviteLink = () => {
    return `${window.location.origin}${SIGNUP}?referral=${this.props.currentUser.referral_code}`
  }

  toggleInviteLinkCopied = () => {
    this.setState({ showInviteLinkCopied: true })
  }

  handleSwitchChange = event => {
    this.setState({ [event.target.name]: event.target.checked })
  }

  render() {
    const openDialog = Boolean(this.state.anchorEl)
    const { currentUser, updateUserInProgress, chosenClass } = this.props
    const {
      email,
      name,
      gender,
      date_of_birth,
      phone_country_code,
      phone,
      level,
      school,
      first_language,
      parent,
      referral_code,
      credit
    } = currentUser
    let {
      showInviteLinkCopied,
      parent_report,
      news_letter,
      ontime,
      late,
      no,
      totRNS,
      totlateRNS,
      totnoRNS,
      punctualityDialog,
      cancellationDialog,
      homeworkDialog,
      revisionNotesDialog,
      engagementDialog,
      masteryDialog,
      classNow,
      classNowForMeta,
      indexClass
    } = this.state
    let displayLevel = '---'
    if (level) {
      let levelObj = _.find(educationLevels, function (o) {
        return o.code === level
      })
      if (levelObj) {
        displayLevel = levelObj.name
      } else {
        displayLevel = level
      }
    }
    let creditBalance = 0
    let userCredit = credit?.credit
    if (userCredit) {
      let userCountry = currentUser.country
      if (userCountry === 'SG') {
        if (userCredit.SGD && userCredit.SGD.balance > 0) {
          creditBalance = userCredit.SGD.balance
        }
      } else if (userCountry === 'US') {
        if (userCredit.USD && userCredit.USD.balance > 0) {
          creditBalance = userCredit.USD.balance
        }
      }
    }

    let genderText = '---'
    if (gender === 'f') {
      genderText = 'Female'
    } else if (gender === 'm') {
      genderText = 'Male'
    }

    let chosenLessons = classNow

    let filteredAttendanceEarly = chosenLessons?.filter(lessons => lessons?.lesson_meta?.punctuality === 'n')
    let filteredAttendanceLate = chosenLessons?.filter(lessons => lessons?.lesson_meta?.punctuality === 'y')

    let filteredAttendanceTotal = chosenLessons?.filter(
      lessons => lessons?.lesson_meta?.punctuality === 'y' || lessons?.lesson_meta?.punctuality === 'n'
    )
    let attendanceEarly = (filteredAttendanceEarly?.length / filteredAttendanceTotal?.length) * 100
    let attendanceLate = (filteredAttendanceLate?.length / filteredAttendanceTotal?.length) * 100

    let filteredCancelledTotal = chosenLessons?.filter(
      lessons => lessons?.lesson_meta?.cancelledClass === 'y' || lessons?.lesson_meta?.cancelledClass === 'n'
    )

    let filteredCancelledNo = chosenLessons?.filter(lessons => lessons?.lesson_meta?.cancelledClass === 'n')
    let filteredCancelledActual = chosenLessons?.filter(lessons => lessons?.lesson_meta?.cancelledClass === 'y')

    let cancelledNo = (filteredCancelledNo?.length / filteredCancelledTotal?.length) * 100
    let cancelledActual = (filteredCancelledActual?.length / filteredCancelledTotal?.length) * 100

    let sumEngagement = 0
    let sumMastery = 0
    let totalSumEngagement = 0
    let totalSumMastery = 0

    let punctualityColor = '#000'
    let cancellationColor = '#000'
    let engagementColor = '#000'
    let engagementPercentage
    let masteryColor = '#000'
    let masteryPercentage
    let homeworkColor = '#000'
    let revisionNotesColor = '#000'

    if (attendanceEarly >= 0 && attendanceEarly <= 49) {
      punctualityColor = '#F44336'
    }
    if (attendanceEarly >= 50 && attendanceEarly <= 80) {
      punctualityColor = '#F28C28'
    }
    if (attendanceEarly >= 81 && attendanceEarly <= 100) {
      punctualityColor = '#50C878'
    }

    if (cancelledActual >= 0 && cancelledActual <= 49) {
      cancellationColor = '#50C878'
    }
    if (cancelledActual >= 50 && cancelledActual <= 80) {
      cancellationColor = '#F28C28'
    }
    if (cancelledActual >= 81 && cancelledActual <= 100) {
      cancellationColor = '#F44336'
    }

    let homeworkSubmissionTotal = ontime + late

    if (homeworkSubmissionTotal >= 0 && homeworkSubmissionTotal <= 49) {
      homeworkColor = '#F44336'
    }
    if (homeworkSubmissionTotal >= 50 && homeworkSubmissionTotal <= 80) {
      homeworkColor = '#F28C28'
    }
    if (homeworkSubmissionTotal >= 81 && homeworkSubmissionTotal <= 100) {
      homeworkColor = '#50C878'
    }

    let revisionSubmissionTotal = totRNS + totlateRNS

    if (revisionSubmissionTotal >= 0 && revisionSubmissionTotal <= 49) {
      revisionNotesColor = '#F44336'
    }
    if (revisionSubmissionTotal >= 50 && revisionSubmissionTotal <= 80) {
      revisionNotesColor = '#F28C28'
    }
    if (revisionSubmissionTotal >= 81 && revisionSubmissionTotal <= 100) {
      revisionNotesColor = '#50C878'
    }

    let filteredHomeworkSubmission = chosenLessons?.filter(
      lessons => lessons?.lesson_meta?.homework?.homeworkAssignment === 'y'
    )

    let filteredOnTimeHWS = filteredHomeworkSubmission?.filter(
      lessons => lessons?.lesson_meta?.homework?.homeworkSubmission === '1'
    )

    let filteredOnTimeRNS = chosenLessons?.filter(lessons => lessons?.lesson_meta?.revision_notes === '1')

    let filteredEngagementLessons = chosenLessons?.filter(
      lessons =>
        lessons?.lesson_meta?.engagement?.engagementParticipation > 0 &&
        lessons?.lesson_meta?.engagement?.engagementDistracted > 0 &&
        lessons?.lesson_meta?.engagement?.engagementTiredBored > 0 &&
        lessons?.lesson_meta?.engagement?.engagementMotivate > 0 &&
        lessons?.lesson_meta?.engagement?.engagementAskQuestions > 0
    )

    let filteredMasteryLessons = chosenLessons?.filter(
      lessons =>
        lessons?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion > 0 &&
        lessons?.lesson_meta?.skill_mastery?.skillMasteryConfused > 0 &&
        lessons?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding > 0 &&
        lessons?.lesson_meta?.skill_mastery?.skillMasteryParticipative > 0 &&
        lessons?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept > 0
    )

    let filteredLateHWS = filteredHomeworkSubmission?.filter(
      lessons => lessons?.lesson_meta?.homework?.homeworkSubmission === '2'
    )
    let filteredNoHWS = filteredHomeworkSubmission?.filter(
      lessons => lessons?.lesson_meta?.homework?.homeworkSubmission === '3'
    )
    let filteredLateRNS = chosenLessons?.filter(lessons => lessons?.lesson_meta?.revision_notes === '2')

    let filteredNoRNS = chosenLessons?.filter(lessons => lessons?.lesson_meta?.revision_notes === '3')

    let filteredLessonTopics = chosenLessons?.filter(
      lessons => lessons?.lesson_meta?.lesson_topic !== '' || lessons?.lesson_meta?.lesson_topic !== undefined
    )

    let filteredLessonComments = chosenLessons?.filter(
      lessons => lessons?.lesson_meta?.comment?.commentStudentView && lessons?.lesson_meta?.comment.comment !== ''
    )

    let filteredRecommendations = chosenLessons?.filter(
      lessons => lessons?.lesson_meta?.recommendation?.recommendationOthers !== ''
    )

    return (
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <Paper elevation={2} className="app-wrapper">
          <h2 className="mb-0">Profile</h2>
        </Paper>

        <div className="col-12 mt-3">
          <Card className="mb-3">
            <CardContent>
              <div className="row">
                <div className="col-12 row justify-content-around">
                  {chosenClass ? (
                    chosenClass?.map((classes, index) => (
                      <div key={index} className="col-md-3 col-12">
                        <div
                          style={{
                            fontSize: 25,
                            minHeight: '120px',
                            backgroundColor: index === indexClass ? 'lightgreen' : '',
                            textAlign: 'center'
                          }}
                          className="col-md-12 col-12 my-2 py-4 font-weight-medium box-dashboard"
                          onClick={() => this.handleShowClass(index)}>
                          {classes?.name.slice(6)}
                          {' ('}
                          {classes?.tutor[0]?.name}
                          {') '}
                        </div>
                      </div>
                    ))
                  ) : (
                    <CircularProgress />
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="col-12 mt-3">
          <ClassReportToolTip
            currentUser={currentUser}
            options={this.state.options}
            series={this.state.series}
            optionsMastery={this.state.optionsMastery}
            seriesMastery={this.state.seriesMastery}
            punctualityColor={punctualityColor}
            attendanceEarly={attendanceEarly}
            filteredAttendanceLate={filteredAttendanceLate}
            filteredAttendanceTotal={filteredAttendanceTotal}
            cancellationColor={cancellationColor}
            cancelledActual={cancelledActual}
            filteredCancelledActual={filteredCancelledActual}
            homeworkColor={homeworkColor}
            homeworkSubmissionTotal={homeworkSubmissionTotal}
            filteredOnTimeHWS={filteredOnTimeHWS}
            filteredLateHWS={filteredLateHWS}
            filteredNoHWS={filteredNoHWS}
            filteredLateRNS={filteredLateRNS}
            filteredNoRNS={filteredNoRNS}
            revisionNotesColor={revisionNotesColor}
            revisionSubmissionTotal={revisionSubmissionTotal}
            filteredOnTimeRNS={filteredOnTimeRNS}
            filteredEngagementLessons={filteredEngagementLessons}
            engagementColor={engagementColor}
            filteredMasteryLessons={filteredMasteryLessons}
            masteryColor={masteryColor}
            filteredLessonComments={filteredLessonComments}
            filteredCancelledTotal={filteredCancelledTotal}
            filteredLessonTopics={filteredLessonTopics}
          />
        </div>

        <div className="app-wrapper">
          <div className="">
            <div className="row">
              <div className="col-lg-4 col-12" style={{ fontSize: isMobile ? '' : '20px' }}>
                <Card className="mb-3" style={{ minHeight: '470px' }}>
                  <CardContent>
                    <h2>My Information</h2>
                    <div className="row align-items-center">
                      <div className="col-md-5 col-5 my-2 font-weight-medium">Email</div>
                      <div className="col-md-7 col-7 my-2">{email}</div>

                      <div className="col-md-5 col-5 my-2 font-weight-medium">Name</div>
                      <div className="col-md-7 col-7 my-2">{name}</div>

                      <div className="col-md-5 col-5 my-2 font-weight-medium">Date of birth</div>
                      <div className="col-md-7 col-7 my-2">
                        {date_of_birth ? moment(date_of_birth).format('ll') : '---'}
                      </div>

                      <div className="col-md-5 col-5 my-2 font-weight-medium">Gender</div>
                      <div className="col-md-7 col-7 my-2">{genderText}</div>

                      <div className="col-md-5 col-5 my-2 font-weight-medium">Phone number</div>
                      <div className="col-md-7 col-7 my-2">
                        {phone ? (
                          <span>
                            (+{phone_country_code}) {phone}
                          </span>
                        ) : (
                          '---'
                        )}
                      </div>

                      <div className="col-md-5 col-5 my-2 font-weight-medium">Social Media</div>
                      <div className="col-md-7 col-7 my-2">
                        {currentUser.facebook ?? '---'} / {currentUser.line ?? '---'}
                      </div>

                      <div className="col-md-5 col-5 my-2 font-weight-medium">Contact Preference</div>
                      <div className="col-md-7 col-7 my-2">
                        {currentUser.contact_preference?.contact === 'nil'
                          ? '---'
                          : currentUser.contact_preference?.contact === 'others'
                          ? currentUser.contact_preference?.others_type
                          : currentUser.contact_preference?.contact === 'line'
                          ? 'Line'
                          : currentUser.contact_preference?.contact === 'whatsapp'
                          ? 'Whatsapp'
                          : ''}
                      </div>
                    </div>

                    <div className="d-flex flex-wrap mt-3">
                      <Button
                        variant="contained"
                        color="primary"
                        className="text-white mr-2 mb-2"
                        component={Link}
                        to={USER_PROFILE_EDIT}>
                        Update my details
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        className="text-white mr-2 mb-2"
                        component={Link}
                        to={USER_PASSWORD_EDIT}>
                        Change my password
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              </div>

              <div className="col-lg-4 col-12" style={{ fontSize: isMobile ? '' : '20px' }}>
                <Card className="mb-3" style={{ minHeight: '470px' }}>
                  <CardContent>
                    <h2>Class</h2>
                    <div className="row align-items-center">
                      <div className="col-md-5 col-5 my-2 font-weight-medium">Currently enrolled class</div>
                      {/* <div className="col-md-7 col-7 my-2">{chosenClass?.map(classes => <li style={{ listStyle: 'none'}}>{classes.name.slice(6)}</li>)}</div> */}
                      <div className="col-md-7 col-7 my-2">{classNowForMeta && classNowForMeta?.name?.slice(6)}</div>

                      <div className="col-md-5 col-5 my-2 font-weight-medium">Date of upcoming exams</div>
                      <div className="col-md-7 col-7 my-2">
                        {classNowForMeta && classNowForMeta?.meta?.dayOfExam
                          ? moment(classNowForMeta?.meta?.dayOfExam).format('llll').slice(0, -8)
                          : '---'}
                      </div>

                      {/* <div className="col-md-5 col-5 my-2 font-weight-medium">Exam target score</div>
                      <div className="col-md-7 col-7 my-2">{currentUser.tbd ?? '---'}</div> */}

                      <div className="col-md-5 col-5 my-2 font-weight-medium">Exam target score (tutor)</div>
                      <div className="col-md-7 col-7 my-2">
                        {classNowForMeta && classNowForMeta?.meta?.examTargetScore
                          ? `${classNowForMeta?.meta?.examTargetScore}%`
                          : '---'}{' '}
                      </div>

                      <div className="col-md-5 col-5 my-2 font-weight-medium">Exam result</div>
                      <div className="col-md-7 col-7 my-2">
                        {' '}
                        {classNowForMeta && classNowForMeta?.meta?.examResult
                          ? `${classNowForMeta?.meta?.examResult}%`
                          : '---'}{' '}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>

              <div className="col-lg-4 col-12" style={{ fontSize: isMobile ? '' : '20px' }}>
                <Card className="mb-3" style={{ minHeight: '470px' }}>
                  <CardContent>
                    <h2>Academic & Career Goal</h2>
                    <div className="row align-items-center">
                      <div className="col-md-5 col-5 my-2 font-weight-medium">Career Goal</div>
                      <div className="col-md-7 col-7 my-2">{currentUser.careerGoals?.student ?? '---'}</div>

                      <div className="col-md-5 col-5 my-2 font-weight-medium">Study Goal</div>
                      <div className="row col-md-7 col-12">
                        {currentUser.studyGoals?.student?.entrance && (
                          <div className="col-md-1 col-auto my-2">{currentUser.studyGoals?.student?.entrance_type}</div>
                        )}
                        {currentUser.studyGoals?.student?.gmat && <div className="col-md-1 col-auto my-2">GMAT</div>}
                        {currentUser.studyGoals?.student?.gre && <div className="col-md-1 col-auto my-2">GRE</div>}
                        {currentUser.studyGoals?.student?.ietls && <div className="col-md-1 col-auto my-2">IELTS</div>}
                        {currentUser.studyGoals?.student?.sat && <div className="col-md-1 col-auto my-2">SAT</div>}
                        {currentUser.studyGoals?.student?.toefl && <div className="col-md-1 col-auto my-2">TOEFL</div>}
                        {currentUser.studyGoals?.student?.others && (
                          <div className="col-md-1 col-auto my-2">{currentUser.studyGoals?.student?.others_type} </div>
                        )}
                      </div>

                      <div className="col-md-5 col-5 my-2 font-weight-medium">Country of target university</div>
                      <div className="row col-md-7 col-12">
                        {currentUser.targetCountry?.student?.us && <div className="col-md-1 col-auto my-2">US</div>}
                        {currentUser.targetCountry?.student?.uk && <div className="col-md-1 col-auto my-2">UK</div>}
                        {currentUser.targetCountry?.student?.canada && (
                          <div className="col-md-1 col-auto my-2">Canada</div>
                        )}
                        {currentUser.targetCountry?.student?.others &&
                          currentUser.targetCountry?.student?.others_type && (
                            <div className="col-md-1 col-auto my-2">
                              {currentUser.targetCountry?.student?.others_type}
                            </div>
                          )}
                      </div>

                      <div className="col-md-5 col-5 my-2 font-weight-medium">Tentative area of study</div>
                      <div className="row col-md-7 col-12">
                        {currentUser.areaOfStudy?.area && (
                          <div className="col-md-12 col-auto my-2">
                            {currentUser.areaOfStudy?.area} - {currentUser.areaOfStudy?.specific}
                          </div>
                        )}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>

              <div className="col-lg-12 col-12" style={{ fontSize: isMobile ? '' : '20px' }}>
                <Card className="mb-3">
                  <CardContent>
                    <h2>School & Academic</h2>
                    <div className="row align-items-center">
                      <div className="col-md-2 col-5 my-2 font-weight-medium">Current School</div>
                      <div className="col-md-10 col-7 my-2">{currentUser.school ?? '---'}</div>

                      {currentUser.typeOfSchool && (
                        <>
                          <div className="col-md-2 col-5 my-2 font-weight-medium">Type of School</div>
                          <div className="col-md-10 col-7 my-2">
                            {currentUser.typeOfSchool === 'international'
                              ? 'International'
                              : currentUser.typeOfSchool === 'government'
                              ? 'Government'
                              : currentUser.typeOfSchool}
                          </div>
                        </>
                      )}

                      <div className="col-md-2 col-5 my-2 font-weight-medium">Level: Year / Grade</div>
                      <div className="col-md-10 col-7 mt-2">
                        {currentUser.level === 'grade' ? 'Grade' : currentUser.level === 'year' ? 'Year' : 'Others: '}{' '}
                        {currentUser.level === 'grade'
                          ? currentUser.gradeYearLevel?.grade
                          : currentUser.level === 'year'
                          ? currentUser.gradeYearLevel?.year
                          : currentUser.level}
                      </div>

                      <div className="col-md-2 col-5 my-2 font-weight-medium">Medium of Study</div>
                      <div className="col-md-10 col-7 my-2">
                        {`${currentUser.studyMediumEng === true ? 'English' : '---'} ${
                          currentUser.studyMediumThai === true ? 'Thai' : ''
                        }`}
                      </div>

                      <div className="col-md-1 col-5 mt-md-5 my-2 font-weight-medium">Grades (GPA)</div>

                      {/* mobile view */}
                      <div className="row col-md-11 col-12 d-md-none d-flex">
                        {currentUser?.grade?.gpa?.art && (
                          <div className="col-md-1 col-auto my-2">
                            Art
                            <br /> {currentUser?.grade?.gpa?.art}
                          </div>
                        )}
                        {currentUser?.grade?.gpa?.biology && (
                          <div className="col-md-1 col-auto my-2">
                            Biology <br />
                            {currentUser?.grade?.gpa?.biology}
                          </div>
                        )}
                        {currentUser?.grade?.gpa?.chemistry && (
                          <div className="col-md-1 col-auto my-2">
                            Chemistry <br />
                            {currentUser?.grade?.gpa?.chemistry}
                          </div>
                        )}
                        {currentUser?.grade?.gpa?.economics && (
                          <div className="col-md-1 col-auto my-2">
                            Economics <br />
                            {currentUser?.grade?.gpa?.economics}
                          </div>
                        )}
                        {currentUser?.grade?.gpa?.english && (
                          <div className="col-md-1 col-auto my-2">
                            English <br />
                            {currentUser?.grade?.gpa?.english}
                          </div>
                        )}
                        {currentUser?.grade?.gpa?.foreign_language && (
                          <div className="col-md-1 col-auto my-2">
                            Foreign Language
                            <br />
                            {currentUser?.grade?.gpa?.foreign_language}
                          </div>
                        )}
                        {currentUser?.grade?.gpa?.geography && (
                          <div className="col-md-1 col-auto my-2">
                            Geography <br />
                            {currentUser?.grade?.gpa?.geography}
                          </div>
                        )}
                        {currentUser?.grade?.gpa?.history && (
                          <div className="col-md-1 col-auto my-2">
                            History <br />
                            {currentUser?.grade?.gpa?.history}
                          </div>
                        )}
                        {currentUser?.grade?.gpa?.literature && (
                          <div className="col-md-1 col-auto my-2">
                            Literature <br />
                            {currentUser?.grade?.gpa?.literature}
                          </div>
                        )}
                        {currentUser?.grade?.gpa?.math && (
                          <div className="col-md-1 col-auto my-2">
                            Math <br />
                            {currentUser?.grade?.gpa?.math}
                          </div>
                        )}
                        {currentUser?.grade?.gpa?.physics && (
                          <div className="col-md-1 col-auto my-2">
                            Physics <br />
                            {currentUser?.grade?.gpa?.physics}
                          </div>
                        )}
                        {currentUser?.grade?.gpa?.politics && (
                          <div className="col-md-1 col-auto my-2">
                            Politics <br />
                            {currentUser?.grade?.gpa?.politics}
                          </div>
                        )}
                        {currentUser?.grade?.gpa?.social_studies && (
                          <div className="col-md-1 col-auto my-2">
                            Social studies <br />
                            {currentUser?.grade?.gpa?.social_studies}
                          </div>
                        )}
                        {currentUser?.grade?.gpa?.thai && (
                          <div className="col-md-1 col-auto my-2">
                            Thai <br />
                            {currentUser?.grade?.gpa?.thai}
                          </div>
                        )}
                        {currentUser?.grade?.gpa?.others && currentUser?.grade?.gpa?.others_type && (
                          <div className="col-md-1 col-auto my-2">
                            Others <br />
                            {currentUser?.grade?.gpa?.others_type}
                          </div>
                        )}
                      </div>

                      {/* desktop view */}
                      <div className="row col-md-11 col-12 d-md-flex d-none">
                        {currentUser?.grade?.art && (
                          <div className="col-md-1 col-auto my-2 bt-grades bl-grades br-grades bb-grades">
                            Art <br /> <br />
                            {currentUser?.grade?.gpa?.art}
                          </div>
                        )}
                        {currentUser?.grade?.biology && (
                          <div className="col-md-1 col-auto my-2 bt-grades br-grades bb-grades">
                            Biology <br />
                            <br /> {currentUser?.grade?.gpa?.biology}
                          </div>
                        )}
                        {currentUser?.grade?.chemistry && (
                          <div className="col-md-1 col-auto my-2 bt-grades br-grades bb-grades">
                            Chemistry <br /> <br />
                            {currentUser?.grade?.gpa?.chemistry}
                          </div>
                        )}
                        {currentUser?.grade?.economics && (
                          <div className="col-md-1 col-auto my-2 bt-grades br-grades bb-grades">
                            Economics
                            <br /> <br />
                            {currentUser?.grade?.gpa?.economics}
                          </div>
                        )}
                        {currentUser?.grade?.english && (
                          <div className="col-md-1 col-auto my-2 bt-grades br-grades bb-grades">
                            English
                            <br /> <br />
                            {currentUser?.grade?.gpa?.english}
                          </div>
                        )}
                        {currentUser?.grade?.foreign_language && (
                          <div className="col-md-1 col-auto my-2 bt-grades br-grades bb-grades">
                            Foreign Language {currentUser?.grade?.gpa?.foreign_language}
                          </div>
                        )}
                        {currentUser?.grade?.geography && (
                          <div className="col-md-1 col-auto my-2 bt-grades br-grades bb-grades">
                            Geography <br /> <br />
                            {currentUser?.grade?.gpa?.geography}
                          </div>
                        )}
                        {currentUser?.grade?.history && (
                          <div className="col-md-1 col-auto my-2 bt-grades br-grades bb-grades">
                            History
                            <br /> <br /> {currentUser?.grade?.gpa?.history}
                          </div>
                        )}
                        {currentUser?.grade?.literature && (
                          <div className="col-md-1 col-auto my-2 bt-grades br-grades bb-grades">
                            Literature
                            <br /> <br />
                            {currentUser?.grade?.gpa?.literature}{' '}
                          </div>
                        )}
                        {currentUser?.grade?.math && (
                          <div className="col-md-1 col-auto my-2 bt-grades br-grades bb-grades">
                            Math <br /> <br />
                            {currentUser?.grade?.gpa?.math}
                          </div>
                        )}
                        {currentUser?.grade?.physics && (
                          <div className="col-md-1 col-auto my-2 bt-grades br-grades bb-grades">
                            Physics
                            <br /> <br />
                            {currentUser?.grade?.gpa?.physics}
                          </div>
                        )}
                        {currentUser?.grade?.politics && (
                          <div className="col-md-1 col-auto my-2 bt-grades br-grades bb-grades">
                            Politics
                            <br /> <br />
                            {currentUser?.grade?.gpa?.politics}
                          </div>
                        )}
                        {currentUser?.grade?.social_studies && (
                          <div className="col-md-1 col-auto my-2 bl-grades bt-grades br-grades bb-grades">
                            Social studies <br /> {currentUser?.grade?.gpa?.social_studies}
                          </div>
                        )}
                        {currentUser?.grade?.thai && (
                          <div className="col-md-1 col-auto my-2 bl-grades bt-grades br-grades bb-grades">
                            Thai <br /> <br /> {currentUser?.grade?.gpa?.thai}
                          </div>
                        )}
                        {currentUser?.grade?.others && (
                          <div className="col-md-1 col-auto my-2 bl-grades bt-grades br-grades bb-grades">
                            Others <br /> <br />
                            {currentUser?.grade?.gpa?.others_type}
                          </div>
                        )}
                      </div>
                      <div className="col-md-2 col-5 mt-5 mb-2 font-weight-medium">Favorite Subject(s)</div>
                      <div className="row col-md-10 col-12">
                        {currentUser.favoriteSubjects?.art && <div className="col-md-1 col-auto mt-5 mb-2">Art </div>}
                        {currentUser.favoriteSubjects?.biology && (
                          <div className="col-md-1 col-auto mt-5 mb-2">Biology </div>
                        )}
                        {currentUser.favoriteSubjects?.chemistry && (
                          <div className="col-md-1 col-auto mt-5 mb-2">Chemistry</div>
                        )}
                        {currentUser.favoriteSubjects?.economics && (
                          <div className="col-md-1 col-auto mt-5 mb-2">Economics</div>
                        )}
                        {currentUser.favoriteSubjects?.english && (
                          <div className="col-md-1 col-auto mt-5 mb-2">English</div>
                        )}
                        {currentUser.favoriteSubjects?.history && (
                          <div className="col-md-1 col-auto mt-5 mb-2">History</div>
                        )}
                        {currentUser.favoriteSubjects?.foreign_language && (
                          <div className="col-md-1 col-auto mt-5 mb-2">Foreign Language</div>
                        )}
                        {currentUser.favoriteSubjects?.geography && (
                          <div className="col-md-1 col-auto mt-5 mb-2">Geography</div>
                        )}
                        {currentUser.favoriteSubjects?.physics && (
                          <div className="col-md-1 col-auto mt-5 mb-2">Physics</div>
                        )}
                        {currentUser.favoriteSubjects?.politics && (
                          <div className="col-md-1 col-auto mt-5 mb-2">Politics</div>
                        )}
                        {currentUser.favoriteSubjects?.social_studies && (
                          <div className="col-md-1 col-auto mt-5 mb-2">Social studies</div>
                        )}
                        {currentUser.favoriteSubjects?.thai && <div className="col-md-1 col-auto mt-5 mb-2">Thai</div>}
                        {currentUser.favoriteSubjects?.others_type && (
                          <div className="col-md-1 col-auto mt-5 mb-2">
                            {currentUser.favoriteSubjects?.others_type}{' '}
                          </div>
                        )}
                      </div>
                      <div className="col-md-2 col-5 my-2 font-weight-medium">Weakest Subject(s)</div>
                      <div className="row col-md-10 col-12">
                        {currentUser.weakestSubjects?.art && <div className="col-md-1 col-auto my-2">Art </div>}
                        {currentUser.weakestSubjects?.biology && <div className="col-md-1 col-auto my-2">Biology </div>}
                        {currentUser.weakestSubjects?.chemistry && (
                          <div className="col-md-1 col-auto my-2">Chemistry </div>
                        )}
                        {currentUser.weakestSubjects?.economics && (
                          <div className="col-md-1 col-auto my-2">Economics</div>
                        )}
                        {currentUser.weakestSubjects?.english && <div className="col-md-1 col-auto my-2">English</div>}
                        {currentUser.weakestSubjects?.history && <div className="col-md-1 col-auto my-2">History</div>}
                        {currentUser.weakestSubjects?.foreign_language && (
                          <div className="col-md-1 col-auto my-2">Foreign Language</div>
                        )}
                        {currentUser.weakestSubjects?.geography && (
                          <div className="col-md-1 col-auto my-2">Geography</div>
                        )}
                        {currentUser.weakestSubjects?.physics && <div className="col-md-1 col-auto my-2">Physics </div>}
                        {currentUser.weakestSubjects?.politics && (
                          <div className="col-md-1 col-auto my-2">Politics </div>
                        )}
                        {currentUser.weakestSubjects?.social_studies && (
                          <div className="col-md-1 col-auto my-2">Social studies</div>
                        )}
                        {currentUser.weakestSubjects?.thai && <div className="col-md-1 col-auto my-2">Thai</div>}
                        {currentUser.weakestSubjects?.others_type && (
                          <div className="col-md-1 col-auto my-2">{currentUser.weakestSubjects?.others_type} </div>
                        )}
                      </div>
                      <div className="col-md-2 col-5 my-2 font-weight-medium">Type of external test taken</div>
                      <div className="row col-md-10 col-12">
                        {currentUser.externalExams?.gmat && <div className="col-md-1 col-auto my-2">GMAT</div>}
                        {currentUser.externalExams?.gre && <div className="col-md-1 col-auto my-2">GRE</div>}
                        {currentUser.externalExams?.ietls && <div className="col-md-1 col-auto my-2">IELTS</div>}
                        {currentUser.externalExams?.sat && <div className="col-md-1 col-auto my-2">SAT</div>}
                        {currentUser.externalExams?.toefl && <div className="col-md-1 col-auto my-2">TOEFL</div>}
                        {currentUser.externalExams?.others_type && (
                          <div className="col-md-1 col-auto my-2">{currentUser.externalExams?.others_type} </div>
                        )}
                      </div>
                      <div className="col-md-2 col-5 my-2 font-weight-medium">
                        Type of course taken at other tuition centre
                      </div>
                      <div className="row col-md-10 col-12">
                        {currentUser.coursesTuition?.art && (
                          <div className="col-md-1 col-auto my-2">
                            Art <br /> {currentUser.coursesTuition?.art}
                          </div>
                        )}
                        {currentUser.coursesTuition?.biology && (
                          <div className="col-md-1 col-auto my-2">
                            Biology <br />
                            {currentUser.coursesTuition?.biology}
                          </div>
                        )}
                        {currentUser.coursesTuition?.chemistry && (
                          <div className="col-md-1 col-auto my-2">
                            Chemistry <br />
                            {currentUser.coursesTuition?.chemistry}
                          </div>
                        )}
                        {currentUser.coursesTuition?.economics && (
                          <div className="col-md-1 col-auto my-2">
                            Economics <br />
                            {currentUser.coursesTuition?.economics}
                          </div>
                        )}
                        {currentUser.coursesTuition?.english && (
                          <div className="col-md-1 col-auto my-2">English {currentUser.coursesTuition?.english}</div>
                        )}
                        {currentUser.coursesTuition?.history && (
                          <div className="col-md-1 col-auto my-2">History {currentUser.coursesTuition?.history}</div>
                        )}
                        {currentUser.coursesTuition?.foreign_language && (
                          <div className="col-md-1 col-auto my-2">
                            Foreign Language <br />
                            {currentUser.coursesTuition?.foreign_language}
                          </div>
                        )}
                        {currentUser.coursesTuition?.geography && (
                          <div className="col-md-1 col-auto my-2">
                            Geography <br />
                            {currentUser.coursesTuition?.geography}
                          </div>
                        )}
                        {currentUser.coursesTuition?.physics && (
                          <div className="col-md-1 col-auto my-2">
                            Physics <br />
                            {currentUser.coursesTuition?.physics}
                          </div>
                        )}
                        {currentUser.coursesTuition?.politics && (
                          <div className="col-md-1 col-auto my-2">
                            Politics <br />
                            {currentUser.coursesTuition?.politics}
                          </div>
                        )}
                        {currentUser.coursesTuition?.social_studies && (
                          <div className="col-md-1 col-auto my-2">
                            Social studies <br />
                            {currentUser.coursesTuition?.social_studies}
                          </div>
                        )}
                        {currentUser.coursesTuition?.thai && (
                          <div className="col-md-1 col-auto my-2">Thai {currentUser.coursesTuition?.thai}</div>
                        )}
                        {currentUser.coursesTuition?.others_type && (
                          <div className="col-md-1 col-auto my-2">
                            Others <br />
                            {currentUser.coursesTuition?.others_type}
                          </div>
                        )}
                      </div>
                      <div className="col-md-2 col-5 my-2 font-weight-medium">Type of course taken at EPA</div>
                      <div className="row col-md-10 col-12">
                        {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.art && (
                          <div className="col-md-1 col-auto my-2">
                            Art <br /> {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.art}
                          </div>
                        )}
                        {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.biology && (
                          <div className="col-md-1 col-auto my-2">
                            Biology <br />
                            {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.biology}
                          </div>
                        )}
                        {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.chemistry && (
                          <div className="col-md-1 col-auto my-2">
                            Chemistry <br />
                            {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.chemistry}
                          </div>
                        )}
                        {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.economics && (
                          <div className="col-md-1 col-auto my-2">
                            Economics <br />
                            {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.economics}
                          </div>
                        )}
                        {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.english && (
                          <div className="col-md-1 col-auto my-2">
                            English {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.english}
                          </div>
                        )}
                        {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.history && (
                          <div className="col-md-1 col-auto my-2">
                            History {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.history}
                          </div>
                        )}
                        {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.foreign_language && (
                          <div className="col-md-1 col-auto my-2">
                            Foreign Language <br />
                            {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.foreign_language}
                          </div>
                        )}
                        {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.geography && (
                          <div className="col-md-1 col-auto my-2">
                            Geography <br />
                            {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.geography}
                          </div>
                        )}
                        {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.physics && (
                          <div className="col-md-1 col-auto my-2">
                            Physics <br />
                            {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.physics}
                          </div>
                        )}
                        {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.politics && (
                          <div className="col-md-1 col-auto my-2">
                            Politics <br />
                            {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.politics}
                          </div>
                        )}
                        {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.social_studies && (
                          <div className="col-md-1 col-auto my-2">
                            Social studies <br />
                            {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.social_studies}
                          </div>
                        )}
                        {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.thai && (
                          <div className="col-md-1 col-auto my-2">
                            Thai {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.thai}
                          </div>
                        )}
                        {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.others_type && (
                          <div className="col-md-1 col-auto my-2">
                            Others <br />
                            {currentUser.currentlyAtEPA?.coursesTakenAtEPA?.others_type}
                          </div>
                        )}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
