import React from 'react'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import { Avatar } from '@material-ui/core'
import TeacherCard from 'components/public/TeacherCard'
import DepartmentTeacherCard from 'components/public/DepartmentTeacherCard'
import { CarouselData } from 'assets/top_teacher'
import { DepartmentTeacher } from 'assets/department_teacher'
import CTACard from 'components/public/CTACard'
import FeaturedCard from 'components/public/FeaturedCard'
class AboutUs extends React.Component {
  constructor(props) {
    super(props)
    this.sectionTop = React.createRef()
    this.section = React.createRef()
    this.scrollToContent = this.scrollToContent.bind(this)
  }
  state = {
    currentCarouselIndex: 0,
    currentDepartmentTeacherIndex: 0,
    pages: 4,
    tagLanguage: true,
    tagScience: true,
    tagMathematics: true,
    tagCollegeAdvisors: true,
    tagOthers: true,
    previousNextButton: true,
    pseudoFalse: true,
    pseudoWhite: 'white-bg'
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.pseudoFalse !== this.state.pseudoFalse) {
      this.setState({ pseudoWhite: 'orange-bg' })
    }
  }

  scrollToContent = () => {
    this.section.current.scrollIntoView({ behavior: 'smooth' })
  }
  scrollToContentTop = () => {
    this.sectionTop.current.scrollIntoView({ behavior: 'smooth' })
  }

  goToPrevious = () => {
    // let newSlide = this.state.currentCarouselIndex === 0 ? CarouselData.length - 1 : this.state.currentCarouselIndex - 1
    // https://www.section.io/engineering-education/how-to-create-a-carousel-using-react-class-components-and-tailwind-css/
    let newSlide =
      this.state.currentCarouselIndex === 0 ? CarouselData[0].pages - 1 : this.state.currentCarouselIndex - 1
    this.setState({ currentCarouselIndex: newSlide })
    // window.scrollTo({ top: 1200, behavior: 'smooth' })
    this.scrollToContentTop()
  }

  goToNext = () => {
    let newSlide =
      this.state.currentCarouselIndex === CarouselData[0].pages - 1 ? 0 : this.state.currentCarouselIndex + 1
    this.setState({ currentCarouselIndex: newSlide })
    // window.scrollTo({ top: 2000, behavior: 'smooth' })
    this.scrollToContentTop()
  }

  goToPreviousDepartment = () => {
    let newSlide =
      this.state.currentDepartmentTeacherIndex === 0
        ? this.state.pages - 1
        : this.state.currentDepartmentTeacherIndex - 1
    this.setState({ currentDepartmentTeacherIndex: newSlide })
    // https://stackoverflow.com/questions/49831378/react-scroll-to-component
    this.scrollToContent()
  }
  goToNextDepartment = () => {
    let newSlide =
      this.state.currentDepartmentTeacherIndex === this.state.pages - 1
        ? 0
        : this.state.currentDepartmentTeacherIndex + 1
    this.setState({ currentDepartmentTeacherIndex: newSlide })
    this.scrollToContent()
  }

  reset = () => {
    this.setState({
      currentDepartmentTeacherIndex: 0,
      pages: 4,
      tagLanguage: true,
      tagScience: true,
      tagMathematics: true,
      tagCollegeAdvisors: true,
      tagOthers: true,
      previousNextButton: true,
      pseudoFalse: true
      // pseudoWhite: 'white-bg'
    })
  }

  // filterTags = filterType => {

  //   switch (filterType) {
  //     case 'language':
  //       this.setState({ tagLanguage: true, currentDepartmentTeacherIndex: 0, previousNextButton: false })
  //       break

  //   //   default:
  //   //     this.setState({
  //   //       tagLanguage: false,
  //   //       tagScience: false,
  //   //       tagMathematics: false,
  //   //       tagCollegeAdvisors: false,
  //   //       tagOthers: false,
  //   //       currentDepartmentTeacherIndex: 0
  //   //     })
  //   }
  // }

  filterTagLanguage = () => {
    // 7
    this.setState({
      tagLanguage: true,
      tagScience: false,
      tagMathematics: false,
      tagCollegeAdvisors: false,
      tagOthers: false,
      currentDepartmentTeacherIndex: 0,
      previousNextButton: false,
      pseudoFalse: false
    })
    this.scrollToContent()
  }

  filterTagScience = () => {
    // 17
    this.setState({
      tagLanguage: false,
      tagScience: true,
      tagMathematics: false,
      tagCollegeAdvisors: false,
      tagOthers: false,
      currentDepartmentTeacherIndex: 0,
      pages: 2,
      previousNextButton: true,
      pseudoFalse: false
    })
    this.scrollToContent()
  }

  filterTagMathematics = () => {
    // 11
    this.setState({
      tagLanguage: false,
      tagScience: false,
      tagMathematics: true,
      tagCollegeAdvisors: false,
      tagOthers: false,
      currentDepartmentTeacherIndex: 0,
      previousNextButton: true,
      pages: 2,
      pseudoFalse: false
    })
    this.scrollToContent()
  }

  filterTagCollegeAdvisors = () => {
    this.setState({
      tagLanguage: false,
      tagScience: false,
      tagMathematics: false,
      tagCollegeAdvisors: true,
      tagOthers: false,
      currentDepartmentTeacherIndex: 0,
      previousNextButton: false,
      pseudoFalse: false
    })
    this.scrollToContent()
  }

  filterTagOthers = () => {
    this.setState({
      tagLanguage: false,
      tagScience: false,
      tagMathematics: false,
      tagCollegeAdvisors: false,
      tagOthers: true,
      currentDepartmentTeacherIndex: 0,
      previousNextButton: false,
      pseudoFalse: false
    })
    this.scrollToContent()
  }

  render() {
    const {
      currentCarouselIndex,
      currentDepartmentTeacherIndex,
      tagLanguage,
      tagScience,
      tagMathematics,
      tagCollegeAdvisors,
      tagOthers,
      previousNextButton,
      pseudoWhite
    } = this.state
    return (
      <div className="landing">
        <Helmet>
          <title>About Us | EPA</title>
          <meta
            name="description"
            content="Enabling students to unleash their potential while embracing the future with confidence and wisdom."
          />
          <meta
            name="keywords"
            content="Attaining Excellence, Unleashing your potential,Bringing out the best in you, Your dream, our mission 
            Nurturing uniqueness, Igniting your passion"
          />
        </Helmet>
        <div style={{ marginTop: '14vh', marginBottom: '12.86vh' }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="row">
                  <div className="col-lg-3"></div>
                  <div className="section__header_landing_title col-lg-6">
                    <div
                      className="title mb-2 h"
                      style={{
                        fontFamily: "'Libre Baskerville', sans-serif"
                      }}>
                      Meet our team.
                    </div>
                  </div>
                  <div className="col-lg-3"></div>
                </div>
                <div className="row">
                  <div className="col-lg-3"></div>
                  <div className="section__header_landing_title col-lg-6">
                    <div className="paragraph mt-2 mb-2 h">
                      Our tutors prepare students for their academic career and a life-long journey of success and
                      self-discovery.
                    </div>
                  </div>
                  <div className="col-lg-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section style={{ backgroundColor: '#F7EFEA' }}>
          <div className="container">
            <div className="row justify-content-around" style={{ marginTop: '6.8vh' }}>
              <div className="col-12 col-lg-6">
                <div className="section__header_landing_title">
                  <div
                    className="header mb-3 text-left"
                    style={{
                      fontFamily: "'Libre Baskerville', sans-serif"
                    }}>
                    Kru A
                  </div>
                  <div
                    className="paragraph--bold text-left"
                    style={{
                      marginBottom: '32px'
                    }}>
                    Founder
                  </div>

                  {isMobile && (
                    <div className="col-12">
                      <div className="d-flex flex-row justify-content-center mb-3">
                        <img
                          src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/image7c.jpg"
                          alt="Kru A"
                          width="85%"
                          height
                          style={{ borderRadius: '200px', objectFit: 'cover', objectPosition: 'top', height: '282px' }}
                        />
                      </div>
                    </div>
                  )}
                  <div>
                    <p
                      className="description"
                      style={{
                        marginBottom: '32px'
                      }}>
                      With over 25 years of teaching, mentoring, and advising, Kru A is recognized in Thailand's
                      educational community as one of its most experienced educators.
                    </p>
                    <p
                      className="description"
                      style={{
                        marginBottom: '32px'
                      }}>
                      Kru A is dedicated to advising students beyond the traditional classroom and across their
                      educational journey. With a personalized teaching method to best suit each student’s learning
                      style, he aims to uncover talents and help students achieve their aspirations.
                    </p>
                    <p className="description">
                      As a testament to his dedication, Kru A has over 10,000 alumni, including graduates of premiere
                      Ivy League and OxBridge Universities and many major business and industry leaders in Thailand.
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-none d-lg-block">
                <div className="col-auto team-leader"></div>
              </div>
            </div>

            <div
              className="divider-section--shorter"
              style={{ marginTop: '8.21566vh', marginBottom: '8.21566vh' }}></div>

            <div className="row col-12 section__header_landing_title" style={{ marginLeft: isMobile ? '' : '20px' }}>
              <div className="col-12 col-lg-12">
                <p className="paragraph--bold" style={{ marginBottom: '-20px' }}>
                  EPA has been featured in...
                </p>
              </div>
              <div className="col-lg-4 col-12 ml-lg-0 ml-2 p-md-4">
                <FeaturedCard
                  title={'Hello! Magazine'}
                  link={
                    'https://th.hellomagazine.com/education/courses/epa-the-inspiring-tutor/?fbclid=IwAR3aineo4qUlgLJ7PLkEBznAoBpP-FWk60mu_LM8vvWPbdgvayPYWtEUI7o'
                  }
                  fontFamilyDesc={'Noto Sans Thai'}
                  description={`"EPA มองการศึกษาแบบองค์รวมสู่ติวเตอร์ผู้สร้างแรงบันดาลใจ"`}
                />
                <FeaturedCard
                  title={'Erawan Magazine: Exclusive Interview'}
                  date={'Fall 2021 | Vol 1, Issue 1'}
                  description={'“The Most Effective Way To Maximize Learning from Online Class”'}
                  link={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Erawan.jpg'}
                  long={true}
                />
                <FeaturedCard
                  title={'“เลือกสายการเรียนที่ใช่ เพื่ออาชีพที่ชอบ”'}
                  fontFamily={'Noto Sans Thai'}
                  description={'EPA Live Talk'}
                  date={'September 4th, 2022 '}
                  link={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/EPALiveTalk.jpg'}
                />
              </div>

              <div className="col-lg-4 col-12 ml-lg-0 ml-2 p-md-4">
                <FeaturedCard
                  title={'P-PAC'}
                  description={'“ช่วยลูกพัฒนา การวางแผนชีวิต สู่โลกอนาคต”'}
                  fontFamilyDesc={'Noto Sans Thai'}
                  date={'May 29th, 2020'}
                  type={2}
                  link={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/P-PAC_29May.jpg'}
                />
                <FeaturedCard
                  title={'P-PAC'}
                  date={'July 12th, 2022'}
                  type={2}
                  fontFamilyDesc={'Noto Sans Thai'}
                  description={'“อาชีพแห่งอนาคต”'}
                  link={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/P-PAC_12July_FutureOfSkills.jpg'}
                />
                <FeaturedCard
                  title={'สมาคมไทยแนะแนวการศึกษาต่อต่างประเทศ (สนต.)'}
                  fontFamily={'Noto Sans Thai'}
                  description={'“Practical Approach and Technique to Guide Both Students and Parents”'}
                  date={'August 5th, 2022'}
                  long={true}
                  type={2}
                  link={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/PracticalApproach.jpg'}
                />
              </div>
              <div className="col-lg-4 col-12 ml-lg-0 ml-2 p-md-4">
                <FeaturedCard
                  title={'The Explorer: Day Camp'}
                  link={
                    'https://www.facebook.com/TheExplorerFanpage/posts/pfbid024694zryKnVQ4FJqXafBKFnAd9RzjevVHrFGjTMRAzKZV7gUN1ExDiewF1RDyDQzLl'
                  }
                  description={'Start Engine Road to Engineer'}
                  date={'November 19th, 2022 '}
                />
                <FeaturedCard
                  title={`TIECA Study Abroad Fair 2022`}
                  fontFamilyDesc={'Noto Sans Thai'}
                  description={'“เริ่มต้นก้าวแรกอย่างมั่นใจ”'}
                  date={'October 29th, 2022'}
                  link={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/TIECA.jpg'}
                />
                {/* <FeaturedCard
                  title={'One More...'}
                  link={'https://hellomagazine.com'}
                  fontFamilyDesc={'Noto Sans Thai'}
                  description={'“เริ่มต้นก้าวแรกอย่างมั่นใจ”'}
                  date={'October 29th, 2022'}
                /> */}
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white">
          <div className="container">
            <div className="section__header_landing_title" style={{ marginTop: '6.803594vh' }}>
              <div
                className="header mb-4 text-center"
                style={{
                  fontFamily: "'Libre Baskerville', sans-serif"
                }}>
                Hear from our top teachers and counselors
              </div>
              <div className="row">
                <div className="col-lg-2"> </div>
                <div className="col-lg-8">
                  <p
                    className="description text-center"
                    style={{ paddingLeft: isMobile ? '' : '9.5vh', paddingRight: isMobile ? '' : '9.5vh' }}>
                    Kru A personally interviews and recruits each teacher into the EPA family. With exceptional academic
                    backgrounds, our teachers are well-trained on our curriculum and methodology, but we encourage their
                    unique teaching styles. Many of our teachers become life-long mentors for our students.
                  </p>
                </div>{' '}
                <div className="col-lg-2"> </div>
              </div>
            </div>
            <div ref={this.sectionTop}> </div>

            <div>
              {CarouselData.map((slide, index) => {
                return (
                  <TeacherCard
                    key={index}
                    page={slide.page}
                    className={slide.page === currentCarouselIndex ? 'block' : 'd-none'}
                    name={slide.name}
                    department={slide.department}
                    description={slide.description}
                    bgColor={slide.bgColor}
                    image={slide.image}
                    education={slide.education}
                    experience={slide.experience}
                    expThai={slide.expThai}
                    eduThai={slide.eduThai}
                  />
                )
              })}
            </div>

            <div className="row mt-4" style={{ marginBottom: '5.9vh' }}>
              <div className="col-lg-2"></div>
              <div className="col-lg-8" style={{ marginLeft: isMobile ? '0px' : '10vh' }}>
                <div className="row justify-content-end">
                  <div className="clickable" style={{ marginRight: '1vh' }}>
                    <img
                      src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/caret_left.svg"
                      width="90%"
                      onClick={this.goToPrevious}></img>
                  </div>
                  <div className="clickable">
                    <img
                      src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/caret_right.svg"
                      width="90%"
                      onClick={this.goToNext}></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{ backgroundColor: '#F7EFEA', marginLeft: isMobile ? '' : '1vh' }}>
          <div className="container">
            <div className="row" ref={this.section}>
              <div className="col-12 col-lg-12">
                <div className="align-self-end">
                  <div className="section__header_landing_title">
                    <div
                      className="header text-left"
                      style={{
                        fontFamily: "'Libre Baskerville', sans-serif",
                        marginTop: '5.3vh',
                        marginBottom: '4.01vh'
                      }}>
                      Our teachers
                    </div>
                    <div className="row">
                      <div
                        className="tags text-left mx-3 mt-2"
                        style={{
                          fontWeight: '700',
                          marginBottom: isMobile ? '' : '6.4vh'
                        }}
                        onClick={this.reset}>
                        Departments
                      </div>
                      <div className="tags text-left mx-3">
                        <div
                          className={`py-2 px-3 ${tagLanguage ? pseudoWhite : 'white-bg'}`}
                          onClick={this.filterTagLanguage}>
                          Language
                        </div>
                      </div>
                      <div className="tags text-left mx-3 my-3 my-md-0">
                        <div
                          className={`py-2 px-3 ${tagScience ? pseudoWhite : 'white-bg'}`}
                          onClick={this.filterTagScience}>
                          Science
                        </div>
                      </div>
                      <div className="tags text-left mx-3 my-3 my-md-0">
                        <div
                          className={`py-2 px-3 ${tagMathematics ? pseudoWhite : 'white-bg'}`}
                          onClick={this.filterTagMathematics}>
                          Mathematics
                        </div>
                      </div>
                      <div className="tags text-left mx-3 mb-3 mb-md-0">
                        <div
                          className={`py-2 px-3 ${tagCollegeAdvisors ? pseudoWhite : 'white-bg'}`}
                          onClick={this.filterTagCollegeAdvisors}>
                          College Advisors
                        </div>
                      </div>
                      <div className="tags text-left ml-3 mb-3 mb-md-0">
                        <div
                          className={`py-2 px-3 ${tagOthers ? pseudoWhite : 'white-bg'}`}
                          onClick={this.filterTagOthers}>
                          Others
                        </div>
                      </div>
                    </div>

                    {/* {currentDepartmentTeacherIndex} */}

                    <div className="row">
                      {
                        // DepartmentTeacher
                        // .filter(allTeachers =>
                        //   allTeachers.tags === 'language' && tagLanguage
                        //     ? tagLanguage
                        //     : allTeachers.tags === 'science' && tagScience
                        //     ? tagScience
                        //     : allTeachers.tags === 'mathematics' && tagMathematics
                        //     ? tagMathematics
                        //     : allTeachers.tagCA === 'yes' && tagCollegeAdvisors
                        //     ? tagCollegeAdvisors
                        //     : allTeachers.tagOthers === 'yes' && tagOthers
                        //     ? tagOthers
                        //     : ''
                        // )
                        // .map((slide, index) => {
                        // // if (slide.languageDepartmentPage === 0){
                        // //   slide.page = 0
                        // // }
                        // // if (slide.scienceDepartmentPage === 0) {
                        // //   slide.page = 0
                        // // }
                        // // if (slide.scienceDepartmentPage === 1){
                        // //   slide.page = 1
                        // // }
                        // // if (slide.mathematicsDepartmentPage === 0) {
                        // //   slide.page = 2
                        // // }
                        // // if (slide.mathematicsDepartmentPage === 1){
                        // //   slide.page = 3
                        // // }
                        //     return (
                        //      <DepartmentTeacherCard
                        //        key={index}
                        //        className={slide.page === currentDepartmentTeacherIndex ? 'd-block' : 'd-none'}
                        //        name={slide.name}
                        //        department={slide.department}
                        //        description={slide.description}
                        //        image={slide.image}
                        //        education={slide.education}
                        //        experience={slide.experience}
                        //      />
                        //    )
                        //  })}
                        DepartmentTeacher.map((slide, index) => {
                          return (
                            !tagLanguage ||
                            !tagScience ||
                            !tagMathematics ||
                            !tagCollegeAdvisors ||
                            !tagOthers || (
                              <DepartmentTeacherCard
                                key={index}
                                className={slide.page === currentDepartmentTeacherIndex ? 'd-block' : 'd-none'}
                                name={slide.name}
                                department={slide.department}
                                description={slide.description}
                                image={slide.image}
                                education={slide.education}
                                experience={slide.experience}
                              />
                            )
                          )
                        })
                      }

                      {tagLanguage &&
                        !tagOthers &&
                        DepartmentTeacher.filter(language => language.tags === 'language').map((slide, index) => {
                          return (
                            <DepartmentTeacherCard
                              key={index}
                              className={slide.page === currentDepartmentTeacherIndex ? 'd-block' : 'd-none'}
                              name={slide.name}
                              department={slide.department}
                              description={slide.description}
                              image={slide.image}
                              education={slide.education}
                              experience={slide.experience}
                            />
                          )
                        })}

                      {tagScience &&
                        !tagOthers &&
                        DepartmentTeacher.filter(language => language.tags === 'science').map((slide, index) => {
                          return (
                            <DepartmentTeacherCard
                              key={index}
                              className={
                                slide.scienceDepartmentPage === currentDepartmentTeacherIndex ? 'd-block' : 'd-none'
                              }
                              name={slide.name}
                              department={slide.department}
                              description={slide.description}
                              image={slide.image}
                              education={slide.education}
                              experience={slide.experience}
                            />
                          )
                        })}

                      {tagMathematics &&
                        !tagOthers &&
                        DepartmentTeacher.filter(language => language.tags === 'mathematics').map((slide, index) => {
                          return (
                            <DepartmentTeacherCard
                              key={index}
                              className={
                                slide.mathematicsDepartmentPage === currentDepartmentTeacherIndex ? 'd-block' : 'd-none'
                              }
                              name={slide.name}
                              department={slide.department}
                              description={slide.description}
                              image={slide.image}
                              education={slide.education}
                              experience={slide.experience}
                            />
                          )
                        })}

                      {tagCollegeAdvisors &&
                        !tagOthers &&
                        DepartmentTeacher.filter(language => language.tagOthers === 'yes').map((slide, index) => {
                          return (
                            <DepartmentTeacherCard
                              key={index}
                              className={slide.page === currentDepartmentTeacherIndex ? 'd-block' : 'd-none'}
                              name={slide.name}
                              department={slide.department}
                              description={slide.description}
                              image={slide.image}
                              education={slide.education}
                              experience={slide.experience}
                            />
                          )
                        })}

                      {tagOthers &&
                        !tagLanguage &&
                        DepartmentTeacher.filter(language => language.tagCA === 'yes').map((slide, index) => {
                          return (
                            <DepartmentTeacherCard
                              key={index}
                              className={slide.page === currentDepartmentTeacherIndex ? 'd-block' : 'd-none'}
                              name={slide.name}
                              department={slide.department}
                              description={slide.description}
                              image={slide.image}
                              education={slide.education}
                              experience={slide.experience}
                            />
                          )
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {previousNextButton && (
              <div className="row mt-4">
                <div className="col-lg-12">
                  <div className="row justify-content-end">
                    <div className="clickable" style={{ marginRight: '1vh' }}>
                      <img
                        src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/caret_left.svg"
                        width="90%"
                        onClick={this.goToPreviousDepartment}></img>
                    </div>
                    <div className="clickable">
                      <img
                        src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/caret_right.svg"
                        width="90%"
                        onClick={this.goToNextDepartment}></img>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>

        <CTACard />
      </div>
    )
  }
}

export default AboutUs
