import React from 'react'
import { connect } from 'react-redux'
import Pluralize from 'react-pluralize'
import {
  Chip,
  CircularProgress,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import moment from 'moment'

import agent from 'agent'
import { GET_ORDER } from 'constants/actionTypes'
import Pagination from 'utils/Pagination'

import { ErrorOutline } from '@material-ui/icons'

const mapStateToProps = state => ({
  orders: state.order.orders,
  orderCount: state.order.orderCount,
  getOrderInProgress: state.order.getOrderInProgress
})

const mapDispatchToProps = dispatch => ({
  getOrder: (filter, page) => dispatch({ type: GET_ORDER, payload: agent.Order.getList(filter), page })
})

class TransactionHistory extends React.Component {
  state = {
    orders: [],
    currentPage: 1,
    perPage: 15,
    isPopperOpen: false,
    anchorEl: null,
    valueDetail: {}
  }

  componentDidMount() {
    this.updateOrderList()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPage !== this.state.currentPage) {
      this.updateOrderList()
    }

    if (prevProps.orders !== this.props.orders) {
      this.setState({ orders: this.props.orders })
    }
  }

  onChangePage = page => {
    this.setState({ currentPage: page })
  }

  updateOrderList() {
    let filter = {
      max: this.state.perPage,
      offset: (this.state.currentPage - 1) * this.state.perPage,
      type: 'PAYMENT',
      sortBy: 'created',
      order: 'desc',
      status: { $in: ['PROCESSED', 'PAID', 'INIT'] }
    }
    this.props.getOrder(filter, this.state.currentPage)
  }

  toggleValueDetailPopper = (classPrice, creditAmount) => event => {
    let popperState = !this.state.isPopperOpen && classPrice ? true : false
    this.setState({
      isPopperOpen: popperState,
      valueDetail: {
        classPrice,
        creditAmount
      },
      anchorEl: event.currentTarget
    })
  }

  render() {
    const { orders, perPage, currentPage, isPopperOpen, anchorEl, valueDetail } = this.state
    const { getOrderInProgress, orderCount } = this.props

    return (
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <Paper elevation={2} className="app-wrapper">
          <h2 className="mb-0">Transaction History</h2>
        </Paper>
        <div className="app-wrapper">
          {getOrderInProgress && (
            <div className="my-5 text-center">
              <CircularProgress size={40} color={'primary'} />
            </div>
          )}
          {orders.length > 0 ? (
            <Table className="bg-white">
              <TableHead style={{ backgroundColor: 'rgba(60, 72, 88, 0.1)' }}>
                <TableRow>
                  <TableCell>Reference number</TableCell>
                  <TableCell>Class</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map(order => {
                  let classPrice = order.actual_price ? order.actual_price : order.original_price
                  let stripeReceivedAmount = 0
                  let paidPrice = classPrice
                  let creditAmount = 0
                  if (order?.payment_history?.stripe_snapshot) {
                    let stripeSnapshot = JSON.parse(order?.payment_history?.stripe_snapshot)
                    stripeReceivedAmount = stripeSnapshot.amount / 100
                  }

                  if (stripeReceivedAmount > 0) {
                    creditAmount = classPrice - stripeReceivedAmount
                    paidPrice = stripeReceivedAmount
                  }

                  return (
                    <TableRow key={order._id}>
                      <TableCell>{order.ref_no}</TableCell>
                      <TableCell>
                        {order.detail.class_obj && order.detail.class_obj.name} (
                        <Pluralize singular="lesson" count={order.detail.lesson.length} />)
                      </TableCell>
                      <TableCell>
                        <div className="d-flex align-items-center">
                          <div style={{ whiteSpace: 'nowrap' }}>$ {paidPrice.toFixed(2)}</div>
                          {creditAmount ? (
                            <ErrorOutline
                              className="ml-1 text-muted clickable"
                              style={{ fontSize: '17px', marginBottom: '3px' }}
                              onClick={this.toggleValueDetailPopper(classPrice, creditAmount)}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>
                        {moment(order.payment_history ? order.payment_history.created : order.created).format(
                          'ddd DD MMM YYYY, h:mm a'
                        )}
                      </TableCell>
                      <TableCell>
                        {order.status === 'INIT' && (
                          <Chip className="bg-warning text-white" label="Incomplete" size="small" />
                        )}
                        {order.status === 'PAID' && (
                          <Chip className="bg-info text-white" label="Processing" size="small" />
                        )}
                        {order.status === 'PROCESSED' && (
                          <Chip className="bg-success text-white" label="Success" size="small" />
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          ) : (
            <div className="my-5 text-center">There is no any transaction history.</div>
          )}

          {!!orderCount && (
            <div className="mt-3">
              <Pagination
                itemCount={orderCount}
                onChangePage={this.onChangePage}
                pageSize={perPage}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>

        <Popper
          className="clickable"
          open={isPopperOpen}
          anchorEl={anchorEl}
          placement="right"
          onClick={this.toggleValueDetailPopper()}>
          <Paper className="p-3 ml-2" elevation={10}>
            <span className="text-muted">Class Price:</span> $ {valueDetail.classPrice}
            <br />
            <span className="text-muted">Credit Used:</span> {valueDetail.creditAmount}
          </Paper>
        </Popper>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionHistory)
