import React from 'react'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Checkbox,
  Button
} from '@material-ui/core'
import Tooltip from '@mui/material/Tooltip'
import _, { truncate } from 'lodash'
import personalityTest from 'assets/personality-test.json'
import { OthersField } from 'components/profile/others'
import { InputText } from 'components/profile/inputText'

import { Link } from 'react-router-dom'
import { USER_PROFILE } from 'routes/routes'

class PersonalityPreference extends React.Component {
  render() {
    const {
      currentUser,
      handleChange,
      handleCheckboxChange,
      handleBlurInput,
      learningTypeVisual,
      learningTypeKinaesthetic,
      learningTypeAural,
      learningTypeSocial,
      learningTypeSolitary,
      learningTypeVerbal,
      learningTypeLogical,
      learningTypeOthersBox,
      learningTypeOthersField,
      personalityTestCheck,
      personalityTestResult,
      personalityTestResultField,
      preferredTeacherStudentPerspectiveMale,
      preferredTeacherStudentPerspectiveFemale,
      preferredTeacherStudentPerspectiveNameBox,
      preferredTeacherStudentPerspectiveNameField,
      preferredTeacherStudentPerspectiveAuthority,
      preferredTeacherStudentPerspectiveDelegator,
      preferredTeacherStudentPerspectiveFacilitator,
      preferredTeacherStudentPerspectiveDemonstrator,
      preferredTeacherStudentPerspectiveHybrid,
      preferredTeacherStudentPerspectiveOthersBox,
      preferredTeacherStudentPerspectiveOthersField,
      preferredTeacherParentPerspectiveMale,
      preferredTeacherParentPerspectiveFemale,
      preferredTeacherParentPerspectiveNameBox,
      preferredTeacherParentPerspectiveNameField,
      preferredTeacherParentPerspectiveAuthority,
      preferredTeacherParentPerspectiveDelegator,
      preferredTeacherParentPerspectiveFacilitator,
      preferredTeacherParentPerspectiveDemonstrator,
      preferredTeacherParentPerspectiveHybrid,
      preferredTeacherParentPerspectiveOthersBox,
      preferredTeacherParentPerspectiveOthersField,

      interestedTopicsPublicSpeaking,
      interestedTopicsDebate,
      interestedTopicsCoding,
      interestedTopicsLeadership,
      interestedTopicsWriting,
      interestedTopicsCreativeWriting,
      interestedTopicsFormalWriting,
      interestedTopicsReadingComprehension,
      interestedTopicsLiterature,
      interestedTopicsOthersBox,
      interestedTopicsOthersField,
      submitChanges
    } = this.props

    const learningType = [
      {
        check: learningTypeVisual,
        nameBox: 'learningTypeVisual',
        labelBox: 'Visual',
        description:
          'Visual or spatial learners retain information best by viewing pictures or images and respond well to colours and mind maps.'
      },
      {
        check: learningTypeKinaesthetic,
        nameBox: 'learningTypeKinaesthetic',
        labelBox: 'Kinaesthetic',
        description:
          'Kinaesthetic learners are all about doing things physically. Role playing, using things like flashcards or carrying out the action physically can help them learn things better.'
      },
      {
        check: learningTypeAural,
        nameBox: 'learningTypeAural',
        labelBox: 'Aural',
        description: 'Aural or auditory-musical learners should retain the most information after hearing it.'
      },
      {
        check: learningTypeSocial,
        nameBox: 'learningTypeSocial',
        labelBox: 'Social',
        description:
          'Social, or interpersonal learners are meant to work best when they participate in study activities with other people such as quizzing each other or having a study group.'
      },
      {
        check: learningTypeSolitary,
        nameBox: 'learningTypeSolitary',
        labelBox: 'Solitary',
        description:
          'Solitary, or intrapersonal learners supposedly work best alone. Making notes and reciting them back are useful activities when studying by yourself.'
      },
      {
        check: learningTypeVerbal,
        nameBox: 'learningTypeVerbal',
        labelBox: 'Verbal',
        description:
          ' Verbal, or linguistic learners are supposed to respond well to written or spoken words, using tools like rhymes and acronyms.'
      },
      {
        check: learningTypeLogical,
        nameBox: 'learningTypeLogical',
        labelBox: 'Logical',
        description: 'Logical, or mathematical learners use logic and structures in order to learn effectively'
      }
    ]

    const preferredTeacherStudentPerspective = [
      {
        check: preferredTeacherStudentPerspectiveMale,
        name: 'preferredTeacherStudentPerspectiveMale',
        label: 'Male',
        description: 'Prefer Male Teacher?'
      },
      {
        check: preferredTeacherStudentPerspectiveFemale,
        name: 'preferredTeacherStudentPerspectiveFemale',
        label: 'Female',
        description: 'Prefer Female Teacher?'
      },
      {
        check: preferredTeacherStudentPerspectiveAuthority,
        name: 'preferredTeacherStudentPerspectiveAuthority',
        label: 'Authority Style',
        description:
          'The authority style is primarily used in a lecture or auditorium setting, whereby the teacher will give a lengthy, one-way discussion on a pre-assigned topic whilst students take notes and memorise key pieces of information.'
      },
      {
        check: preferredTeacherStudentPerspectiveDelegator,
        name: 'preferredTeacherStudentPerspectiveDelegator',
        label: 'Delegator Style',
        description:
          'As a delegator, the teacher may take an observer role to promote collaboration and encourage peer-to-peer learning.'
      },
      {
        check: preferredTeacherStudentPerspectiveFacilitator,
        name: 'preferredTeacherStudentPerspectiveFacilitator',
        label: 'Facilitator Style',
        description:
          'Teachers who adopt a facilitator or activity-based style encourage self-learning in the classroom through increased peer to teacher learning.'
      },
      {
        check: preferredTeacherStudentPerspectiveDemonstrator,
        name: 'preferredTeacherStudentPerspectiveDemonstrator',
        label: 'Demonstrator Style',
        description:
          'The demonstrator style combines lectures with other teaching forms, including multimedia presentations, demonstrations and class activities.'
      },
      {
        check: preferredTeacherStudentPerspectiveHybrid,
        name: 'preferredTeacherStudentPerspectiveHybrid',
        label: 'Hybrid Style',
        description:
          'Some teachers adopt an integrated teaching style that incorporates their personality, preferences and interests into their teaching. Teachers who use the hybrid style are able to tailor their tutoring for different pupils, incorporating extra-curricular knowledge to develop a deeper knowledge of a particular topic.'
      }
    ]

    const preferredTeacherParentPerspective = [
      {
        check: preferredTeacherParentPerspectiveMale,
        name: 'preferredTeacherParentPerspectiveMale',
        label: 'Male',
        description: 'Prefer Male Teacher?'
      },
      {
        check: preferredTeacherParentPerspectiveFemale,
        name: 'preferredTeacherParentPerspectiveFemale',
        label: 'Female',
        description: 'Prefer Female Teacher?'
      },
      {
        check: preferredTeacherParentPerspectiveAuthority,
        name: 'preferredTeacherParentPerspectiveAuthority',
        label: 'Authority Style',
        description:
          'The authority style is primarily used in a lecture or auditorium setting, whereby the teacher will give a lengthy, one-way discussion on a pre-assigned topic whilst students take notes and memorise key pieces of information.'
      },
      {
        check: preferredTeacherParentPerspectiveDelegator,
        name: 'preferredTeacherParentPerspectiveDelegator',
        label: 'Delegator Style',
        description:
          'As a delegator, the teacher may take an observer role to promote collaboration and encourage peer-to-peer learning.'
      },
      {
        check: preferredTeacherParentPerspectiveFacilitator,
        name: 'preferredTeacherParentPerspectiveFacilitator',
        label: 'Facilitator Style',
        description:
          'Teachers who adopt a facilitator or activity-based style encourage self-learning in the classroom through increased peer to teacher learning.'
      },
      {
        check: preferredTeacherParentPerspectiveDemonstrator,
        name: 'preferredTeacherParentPerspectiveDemonstrator',
        label: 'Demonstrator Style',
        description:
          'The demonstrator style combines lectures with other teaching forms, including multimedia presentations, demonstrations and class activities.'
      },
      {
        check: preferredTeacherParentPerspectiveHybrid,
        name: 'preferredTeacherParentPerspectiveHybrid',
        label: 'Hybrid Style',
        description:
          'Some teachers adopt an integrated teaching style that incorporates their personality, preferences and interests into their teaching. Teachers who use the hybrid style are able to tailor their tutoring for different pupils, incorporating extra-curricular knowledge to develop a deeper knowledge of a particular topic.'
      }
    ]

    const interestedTopicsLifeSkills = [
      { check: interestedTopicsPublicSpeaking, name: 'interestedTopicsPublicSpeaking', label: 'Public Speaking' },
      { check: interestedTopicsDebate, name: 'interestedTopicsDebate', label: 'Debate' },
      { check: interestedTopicsCoding, name: 'interestedTopicsCoding', label: 'Coding' }
      // { check: interestedTopicsLeadership, name: 'interestedTopicsLeadership', label: 'Leadership' }
    ]

    const interestedTopicsAcademicSkills = [
      {
        check: interestedTopicsCreativeWriting,
        name: 'interestedTopicsCreativeWriting',
        label: 'Creative Writing'
      },
      { check: interestedTopicsFormalWriting, name: 'interestedTopicsFormalWriting', label: 'Formal Writing' },
      {
        check: interestedTopicsReadingComprehension,
        name: 'interestedTopicsReadingComprehension',
        label: 'Reading Comprehension'
      },
      {
        check: interestedTopicsLiterature,
        name: 'interestedTopicsLiterature',
        label: 'Literature'
      }
    ]
    return (
      <Paper elevation={2} className="app-wrapper mb-3">
        <h1 id="personality-preference">Personality & Preference</h1>

        <div className="row">
          <div className="col-6">
            <div className="row">
              <FormLabel className="ml-2 mt-3" component="legend">
                Learning Type/Style
              </FormLabel>

              {learningType.map((type, index) => (
                <Paper key={index} className="m-1">
                  <Tooltip
                    title={<h2>{type.description}</h2>}
                    followCursor
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: 'orange',
                          '& .MuiTooltip-arrow': {
                            color: 'common.black'
                          }
                        }
                      }
                    }}>
                    <div className="col-auto">
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={type.check}
                              name={type.nameBox}
                              color="primary"
                              onChange={handleCheckboxChange}
                            />
                          }
                          label={`${type.labelBox}`}
                        />
                      </FormControl>
                    </div>
                  </Tooltip>

                  <div className="col-auto" style={{ marginBottom: '-30px' }}>
                    <TextField disabled={true} InputProps={{ disableUnderline: true }} />
                  </div>
                </Paper>
              ))}
              <OthersField
                check={learningTypeOthersBox}
                nameBox={'learningTypeOthersBox'}
                placeholderField={'Please specify other type'}
                nameField={'learningTypeOthersField'}
                valueField={learningTypeOthersField}
                checkBoxChange={handleCheckboxChange}
                onBlur={handleBlurInput.bind(this)}
              />
            </div>
          </div>

          <div className="col-6">
            <div className="row">
              <div className="col-auto">
                <FormLabel className="mt-3" component="legend">
                  Attempted Personality Test Before?
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="personalityTestCheck"
                  name="personalityTestCheck"
                  value={personalityTestCheck}
                  onChange={handleChange}>
                  <FormControlLabel value="y" control={<Radio color="primary" />} label="Yes" />
                  <FormControlLabel value="n" control={<Radio color="primary" />} label="No" />
                </RadioGroup>
              </div>
              <div className="col-6 mt-3">
                {personalityTestCheck === 'y' && (
                  <>
                    <FormLabel component="legend">Personality test type</FormLabel>
                    <Select
                      fullWidth
                      value={personalityTestResult}
                      onChange={handleChange}
                      name="personalityTestResult">
                      {personalityTest.map(personalityTestResult => {
                        return (
                          <MenuItem key={personalityTestResult.name} value={personalityTestResult.name}>
                            {personalityTestResult.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                    <FormHelperText>Please specify Type</FormHelperText>
                  </>
                )}
              </div>
              <div className="col-10 mt-3">
                {personalityTestCheck === 'y' && personalityTestResult && (
                  <>
                    <FormLabel component="legend">{personalityTestResult} personality result </FormLabel>

                    <InputText
                      name="personalityTestResultField"
                      defaultValue={personalityTestResultField}
                      onBlur={handleBlurInput.bind(this)}
                    />

                    <FormHelperText>Please specify the result for {personalityTestResult}</FormHelperText>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className={`${currentUser._type === 'Student' ? 'col-md-12' : 'col-md-6'} row`}>
            <FormLabel className="ml-2 mt-5" component="legend">
              Preferred Teacher
            </FormLabel>
            <div className="col-md-12 mt-2">
              <div className="row">
                <FormLabel className="ml-2" component="legend">
                  Choose Teacher Type
                </FormLabel>
                <OthersField
                  label={'Name of teacher'}
                  check={preferredTeacherStudentPerspectiveNameBox}
                  nameBox={'preferredTeacherStudentPerspectiveNameBox'}
                  placeholderField={'Please specify name of teacher'}
                  nameField={'preferredTeacherStudentPerspectiveNameField'}
                  valueField={preferredTeacherStudentPerspectiveNameField}
                  checkBoxChange={handleCheckboxChange}
                  marginR={'20px'}
                  onBlur={handleBlurInput.bind(this)}
                />
                {preferredTeacherStudentPerspective.map((teacherType, index) => (
                  <Paper key={index} className="m-1">
                    <Tooltip
                      title={<h2>{teacherType.description}</h2>}
                      followCursor
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: 'orange',
                            '& .MuiTooltip-arrow': {
                              color: 'common.black'
                            }
                          }
                        }
                      }}>
                      <div className="col-auto">
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={teacherType.check}
                                name={teacherType.name}
                                color="primary"
                                onChange={handleCheckboxChange}
                              />
                            }
                            label={`${teacherType.label}`}
                          />
                        </FormControl>
                      </div>
                    </Tooltip>

                    <div className="" style={{ marginBottom: '-30px', marginRight: '45px' }}>
                      <TextField disabled={true} InputProps={{ disableUnderline: true }} />
                    </div>
                  </Paper>
                ))}
                <OthersField
                  check={preferredTeacherStudentPerspectiveOthersBox}
                  nameBox={'preferredTeacherStudentPerspectiveOthersBox'}
                  placeholderField={'Please specify others'}
                  nameField={'preferredTeacherStudentPerspectiveOthersField'}
                  valueField={preferredTeacherStudentPerspectiveOthersField}
                  checkBoxChange={handleCheckboxChange}
                  marginR={'20px'}
                  onBlur={handleBlurInput.bind(this)}
                />
              </div>
            </div>
          </div>
          {currentUser._type === 'Parent' && (
            <div className="row col-md-6">
              Parent Input
              <FormLabel style={{ color: 'blue' }} className="col-md-12">
                Your preferred teacher for your child (Your child will not see this)
              </FormLabel>
              <div className="col-md-12">
                <div className="row">
                  <FormLabel className="ml-2" component="legend">
                    Choose Teacher Type
                  </FormLabel>
                  <OthersField
                    style={'box-parent'}
                    label={'Name of teacher'}
                    check={preferredTeacherParentPerspectiveNameBox}
                    nameBox={'preferredTeacherParentPerspectiveNameBox'}
                    placeholderField={'Please specify name of teacher'}
                    nameField={'preferredTeacherParentPerspectiveNameField'}
                    valueField={preferredTeacherParentPerspectiveNameField}
                    checkBoxChange={handleCheckboxChange}
                    onBlur={handleBlurInput.bind(this)}
                  />
                  {preferredTeacherParentPerspective.map((teacherType, index) => (
                    <Paper key={index} className="m-1 box-parent">
                      <Tooltip title={<h2>{teacherType.description}</h2>} followCursor>
                        <div className="col-auto">
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={teacherType.check}
                                  name={teacherType.name}
                                  color="primary"
                                  onChange={handleCheckboxChange}
                                />
                              }
                              label={`${teacherType.label}`}
                            />
                          </FormControl>
                        </div>
                      </Tooltip>

                      <div className="col-auto" style={{ marginBottom: '-30px' }}>
                        <TextField disabled={true} InputProps={{ disableUnderline: true }} />
                      </div>
                    </Paper>
                  ))}
                  <OthersField
                    style={'box-parent'}
                    check={preferredTeacherParentPerspectiveOthersBox}
                    nameBox={'preferredTeacherParentPerspectiveOthersBox'}
                    placeholderField={'Please specify others'}
                    nameField={'preferredTeacherParentPerspectiveOthersField'}
                    valueField={preferredTeacherParentPerspectiveOthersField}
                    checkBoxChange={handleCheckboxChange}
                    onBlur={handleBlurInput.bind(this)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="row">
          <div className="row col-md-12">
            <FormLabel className="ml-2 mt-5" component="legend">
              Interested Topics / Events / Courses
            </FormLabel>
            <div className="row col-md-12 mt-2">
              <div className="col-auto">
                <FormLabel className="ml-2" component="legend">
                  Life Skills
                </FormLabel>
                {interestedTopicsLifeSkills.map((lifeSkills, index) => (
                  <Paper key={index} className="m-1">
                    <div className="col-auto">
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={lifeSkills.check}
                              name={lifeSkills.name}
                              color="primary"
                              onChange={handleCheckboxChange}
                            />
                          }
                          label={`${lifeSkills.label}`}
                        />
                      </FormControl>
                    </div>
                  </Paper>
                ))}
              </div>

              <div className="col-auto">
                <FormLabel className="ml-2" component="legend">
                  Academic Skills
                </FormLabel>
                {interestedTopicsAcademicSkills.map((academicSkills, index) => (
                  <Paper key={index} className="m-1">
                    <div className="col-auto">
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={academicSkills.check}
                              name={academicSkills.name}
                              color="primary"
                              onChange={handleCheckboxChange}
                            />
                          }
                          label={`${academicSkills.label}`}
                        />
                      </FormControl>
                    </div>
                  </Paper>
                ))}
              </div>

              <div className="col-auto">
                <FormLabel className="ml-2" component="legend">
                  Other Interested Skills
                </FormLabel>
                <OthersField
                  check={interestedTopicsOthersBox}
                  removeField={true}
                  nameBox={'interestedTopicsOthersBox'}
                  placeholderField={'Please specify others'}
                  nameField={'interestedTopicsOthersField'}
                  valueField={interestedTopicsOthersField}
                  checkBoxChange={handleCheckboxChange}
                  onBlur={handleBlurInput.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="d-flex flex-row flex-wrap justify-content-between align-items-center mt-3 mr-3">
          {currentUser._type === 'Parent' ? (
            <Link to={`/app`}>Go Back to Profile</Link>
          ) : (
            <Link to={USER_PROFILE}>Go Back to Profile</Link>
          )}
          <Button variant="contained" color="primary" className="ml-3" onClick={submitChanges}>
            Save Changes
          </Button>
        </div>
      </Paper>
    )
  }
}

export default PersonalityPreference
