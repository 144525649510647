import React from 'react'
import { connect } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import Proptypes from 'prop-types'
import agent from 'agent'
import { DELETE_LESSON_RESOURCE, GET_LESSON_RESOURCES_2 } from 'constants/actionTypes'

const mapStateToProps = state => ({
  deleting: state.resources.deleting,
  deleted: state.resources.deleted
})

const mapDispatchToProps = dispatch => ({
  deleteResource: resourceId =>
    dispatch({ type: DELETE_LESSON_RESOURCE, payload: agent.Lessons.deleteLessonResource(resourceId) }),
  getLessonResource: lessonId =>
    dispatch({ type: GET_LESSON_RESOURCES_2, payload: agent.Lessons.getMaterial(lessonId), lessonId })
})

class DeleteResourceDialog extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.deleting && !this.props.deleting) {
      if (this.props.deleted) {
        this.props.getLessonResource(this.props.resourceData?.lesson)
      }
      this.props.close()
    }
  }

  clickDelete = () => {
    const { resourceData } = this.props
    this.props.deleteResource(resourceData._id)
  }

  render() {
    const { isOpen, resourceData, deleting } = this.props

    return (
      <Dialog open={isOpen} onClose={this.props.close}>
        <DialogTitle>Delete a resource?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete [<strong>{resourceData?.name}</strong>]
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="default" disabled={deleting} onClick={this.props.close}>
            Cancel
          </Button>
          <Button variant="contained" className="bg-danger text-white" disabled={deleting} onClick={this.clickDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DeleteResourceDialog.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired,
  resourceData: Proptypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteResourceDialog)
