import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { TERM_OF_SERVICE } from 'routes/routes'
import moment from 'moment'
export default function PrivacyPolicy() {
  return (
    <div className="landing">
      <Helmet>
        <title>Privacy Policy | EPA</title>
        <meta
          name="description"
          content="Sign up for online tutoring classes with Tenopy to prepare for Mathematics, Science and English exams"
        />
        <meta
          name="keywords"
          content="Online tuition,Math tuition,English tuition,Secondary school tuition,Primary school tuition,Online tuition singapore,Online learning,Online education,Education platform,Online tutor,Home tutor,home tuition,science tuition,sa1,sa2,ca1,ca2,psle,revision,online revision,online study,online learning,psle revision"
        />
      </Helmet>

      <section className="bg-white">
        <div className="section__header">
          <img width="20%" src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/logo/epa_logo_full.svg"></img>
          <div className="title mt-5">Privacy Policy</div>

          <div className="cta">
            {moment().subtract(1, 'year').format('Y')} / {moment().format('Y')}
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12 section__text text-justify info-explanation-black">
              <p className="mb-0">
                <strong>Privacy Policy</strong>
              </p>
              <p className="ml-3 mt-3">
                {' '}
                <strong>
                  1.<span className="ml-3"> Definitions</span>
                </strong>
              </p>
              <p>
                The term <strong>“Personal Data”</strong> refers to any information which identifies you or can be used
                to identify a data subject when used in conjunction with other information.
              </p>
              <p>
                The term <strong>“Data Subject”</strong> describes the person about whom the personal data is about. The
                term “PDPA” describes Personal Data Protection Act B.E. 2562 of Thailand.
              </p>
              <p>
                The term <strong>“Data Controller”</strong> will be regarded as EPA Academic Center.
              </p>
              <p>
                The term <strong>“Data Protection Officer”</strong> or <strong>“DPO”</strong> refers to the assigned
                person in the school whose responsibility is to ensure processes and procedures are in compliance with
                PDPA.
              </p>
              <p>
                The term <strong>“Process”</strong> describes how we collect, use, store or disclose personal data
                directly from the data subject concerned (students, students parents, staff). In some cases, we collect
                data from third parties (e.g. referees/references, previous schools) or from publicly available
                resources. When we process any personal data (sensitive/special category or otherwise), we do so in
                accordance with applicable law and regulations (including with respect to safeguarding or employment).
                Personal data held by the school is processed by appropriate members of staff for the purposes for which
                the data was provided.
              </p>
              <p>
                The term <strong>“Parent”</strong> describes students’ parents.
              </p>
              <p>
                The term <strong>“Guardian” </strong>describes students’ guardian.
              </p>
              <p className="ml-3 mt-3">
                {' '}
                <strong>
                  2. <span className="ml-3"> Purpose</span>
                </strong>
              </p>
              <p>
                EPA Academic Center cares about the data privacy of all members of our community, staff, students, and
                parents. We therefore provide this data privacy notice to inform our policy in relation to the
                individual (<strong>“you”</strong> or <strong>“Data Subject”</strong>) in accordance with the PDPA.
              </p>
              <p>
                The purpose of this data privacy notice is to provide detailed information about how we process personal
                data. The personal data we process takes different forms as described in item 5) of this document. For
                example, we use the data:
              </p>
              <ul>
                <li>To assess and manage applications for students’ admission.</li>
                <li>
                  To facilitate provision of education and enrichment to our students, including the administration of
                  our curriculum; monitoring student academic progress and educational needs, reporting on the same
                  internally and to parents; administration of students’ entries to public examinations, and providing
                  references for students (including after a student had left the school).
                </li>
                <li>
                  To contact parents/guardians/employers (as applicable) for billing and other finance-related purposes.
                </li>
                <li>To share school newsletters, updates, and other marketing-related information.</li>
                <li>To facilitate parents’ participation, we share data with the parent-teacher association.</li>
                <li>To assess and improve the quality of our educational services.</li>
                <li>To meet the compliance with legal and regulatory requirements.</li>

                <li>
                  To meet the school’s operational management, including the compilation of student records; the
                  administration of invoices, fees and accounts; the management of school property; the management of
                  security and safety arrangements and monitoring of the school’s IT and communication systems; the
                  administration and implementation of our school’s rules and policies for students and staff.
                </li>
                <li>To analyse website traffic, demographic, and behaviour using analytical tools and cookies.</li>
                <li>
                  To promote the school through our website, our prospectus and other publications and communications,
                  including through our social media accounts.
                </li>
                <li>To maintain relationships with our alumni and former employees.</li>
                <li>
                  For keeping a record of historical and memorable events relevant to the maintenance of historical
                  records.
                </li>
              </ul>
              <p>
                On a regular basis we take photographs, video, and audio recordings (digital media) of our students’
                learning. Our lawful basis for processing this information is consent and/or legitimate interests. Our
                legitimate interest in using this digital media is for classroom displays, to celebrate student
                achievement and to promote the school through our school publications and media channels. We follow our
                Safeguarding Policy regarding media comprising students that is shared on school media channels.
              </p>
              <p>
                We take appropriate technical steps to ensure the security and integrity of personal data about
                individuals, including policies around use of technology, security appliances and devices, with
                authorised account management for users to access our school’s information system.
              </p>
              <p>
                Additionally, the school’s information systems can be managed and operated by third party cloud-based
                providers.
              </p>
              <p className="ml-3 mt-3">
                {' '}
                <strong>
                  3.<span className="ml-3"> Scope</span>
                </strong>
              </p>
              <p>This data privacy notice applies to:</p>
              <ul>
                <li>
                  Staff (Academic and Non academic) or individuals employed by EPA in any capacity, including full-time
                  and part-time employees.
                </li>
                <li>Students that are current, prospective, or former students enrolled at the school.</li>
                <li>
                  Parents that are current, prospective, or former parents, and/or legal guardians, of a student(s) at
                  EPA.
                </li>
                <li>
                  {' '}
                  Third parties that are referred as individuals or organisations that are not affiliated with or
                  employed by the school.{' '}
                </li>
              </ul>
              <p>
                Please note that some of the web links on our platforms may lead to third party platforms. If you access
                these platforms your personal data will then be processed under the third party’s terms & condition
                policy. Please make sure that you have read those related data privacy notices when accessing such
                platforms.
              </p>
              <p className="ml-3 mt-3">
                {' '}
                <strong>
                  4. <span className="ml-3"> Policy</span>
                </strong>
              </p>
              <p>
                This data privacy policy informs you of how we collect, use, store or disclose your personal data, what
                and why we collect, use, or disclose your personal data, how long we retain it, who we disclose it to,
                your rights, what steps we will take to make sure that your personal data stays private and secure, and
                how you can contact us regarding to questions that you may have about your data.
              </p>
              <p className="ml-5 mt-3">
                {' '}
                <strong>
                  4.1 <span className="ml-3"> How we collect, use, or disclose your personal data</span>
                </strong>
              </p>
              <p>
                We process your personal data where it is necessary and there is a lawful basis for collecting or
                disclosing it. This includes where we collect, use, or disclose your personal data based on the
                legitimate grounds of our legal obligations, performance of a contract you have with us, our legitimate
                interests, performance under your consent and other lawful basis. Reasons for collecting, using, or
                disclosing are provided below:
              </p>
              <p className="ml-5 mt-3">
                {' '}
                <strong>
                  4.1.1 <span className="ml-3"> Our legal obligation</span>
                </strong>
              </p>
              <p>
                We are regulated by laws, rules, regulations, and government regulatory authorities. To fulfil our legal
                and regulatory requirements with these authorities it is necessary to collect, use or disclose your
                personal data for the following purposes, which include but are not limited to:
              </p>
              <ol type="a">
                <li>Compliance with the PDPA and any amendment to the law thereafter;</li>
                <li>Compliance with laws and ongoing monitoring that may be required under any applicable laws;</li>
                <li>
                  Compliance with regulatory obligations and/or orders of authorised persons (e.g. orders by any court
                  of competent jurisdiction or of governmental, supervisory or regulatory authorities or authorised
                  officers).
                </li>
              </ol>
              <p className="ml-5 mt-3">
                {' '}
                <strong>
                  4.1.2 <span className="ml-3"> Contract made by you with us</span>
                </strong>
              </p>
              <p>
                We will process personal data with the request and/or agreement made by you with us, for the following
                purposes, which include but not limited to:
              </p>
              <ul>
                <li>
                  Process your request prior to entering into an agreement, consider for approval in relation to the
                  provision of our services, and deliver products, including any activities that if we do not proceed,
                  then our operations or our services may be affected or may not be able to provide you with fair and
                  ongoing services.
                </li>
                <li>Authenticate when entering or executing any transactions.</li>
                <li>
                  Carry out your instructions (e.g. to debit amounts from bank accounts, or respond to your enquiries);
                  provide online training, and other online learning platforms.
                </li>
                <li>Track or record your transactions.</li>
                <li>Produce transaction reports requested by you or for our internal usage reports.</li>
                <li>Notify you with transaction alerts and notify the due date of the school’s fees and services.</li>
                <li>
                  Proceed with any acts relating to insurance policy or claim for compensation (e.g. proceed with or
                  monitor any claim under your insurance policy, claim against third party).
                </li>
              </ul>
              <p className="ml-5 mt-3">
                {' '}
                <strong>
                  4.1.3 <span className="ml-3"> Our legitimate interests</span>
                </strong>
              </p>
              <p>
                We rely on our legitimate interests by considering our benefits or third party’s benefits with your
                fundamental rights in personal data in which we will collect, use, or disclose for the following
                purposes, which include but are not limited to:
              </p>
              <ul>
                <li>
                  Conduct our school operations (e.g. to audit, to conduct risk management, to monitor, prevent, and
                  investigate misconduct, or other crimes, including but not limited to carrying out the criminal record
                  checks of any persons related to our school).
                </li>
                <li>
                  Conduct our management relationships (e.g. to serve parents and students, to conduct parent/student
                  surveys, to handle complaints).
                </li>
                <li>Ensure our standard security services.</li>
                <li>
                  Develop and improve our school communication, services, and systems to enhance our service standards.
                </li>
                <li>
                  Use your personal data for the greatest benefits in fulfilling your needs, including to conduct
                  research, analyse data and benefits suitable to you by considering the fundamental rights of your
                  personal data.
                </li>
                <li>
                  Record images and/or voices or videos in relation to meetings, teaching, training, seminars, or
                  marketing activities.
                </li>
              </ul>
              <p className="ml-5 mt-3">
                {' '}
                <strong>
                  4.1.4 <span className="ml-3">Your consent</span>
                </strong>
              </p>
              Under PDPA, the rights belong to the individual to whom the data relates (”Data subject”). However, where
              consent is required as the lawful basis for processing personal data relating to students, we often rely
              on parental consent. Unless, given the nature of the processing in question, and the student’s age and
              level of understanding, it is more appropriate to use student consent. Parents should be aware that in
              such situations, they may not be consulted, depending on the interests of the child, the parent’s rights
              at law or under their contract, and considering all the relevant circumstances.
              <p>
                In general, we will assume that student consent is not required (and that other lawful bases are more
                appropriate, as described above) for ordinary disclosure of their personal data to their parents, e.g.
                for the purposes of keeping parents informed about the student’s activities, progress and behaviour, and
                in the interest of the student’s welfare, unless in the school’s opinion, there is a good reason to do
                otherwise.
              </p>
              <p>
                However, where a student seeks to raise concerns confidentially with a member of staff and expressly
                withholds their agreement to their personal data being disclosed to their parents, we may be under
                obligation to maintain confidentiality unless, in our opinion, there is a good reason to do otherwise;
                for example, where the school believes disclosure will be in the best interests of the student or other
                students or is required by law.
              </p>
              <p>
                In certain cases, we may ask for your consent to collect, use or disclose your personal data to maximise
                your benefits and/or to enable us to provide services to fulfil your needs for the following purposes,
                which include but is not limited to:
              </p>
              <ul>
                <li>
                  Collect and use your sensitive personal data as necessary (e.g. to use face recognition, fingerprint
                  or your identification card photo (which contains your sensitive personal data, namely religion and/or
                  blood type) for verification of your identity before continuing a transaction).
                </li>
                <li>
                  Collect and use your personal data and any other data to conduct research and analyse for the greatest
                  benefits in developing products and services to truly fulfil your needs and/or to contact you for
                  offering products, services, and benefits exclusively suitable to you.
                </li>
                <li>
                  Send or transfer your personal data overseas, to entities that have adequate personal data protection
                  standards (unless the PDPA specifies that we may proceed without obtaining consent).
                </li>
                <li>
                  Disclose your personal data and any other data as shown on the school’s website and/or our trusted
                  business partners for the following purposes: (1) conducting research and analysing your web
                  application access and other personal data and any other data for the greatest benefits in developing
                  products and services to truly fulfil your needs; and (2) contacting you for offering products,
                  services, and benefits exclusively suitable to our students.
                </li>
              </ul>
              <p className="ml-5 mt-3">
                {' '}
                <strong>
                  4.1.5 <span className="ml-3">Other lawful basis</span>
                </strong>
              </p>
              <p>
                Apart from the lawful basis mentioned above, we may collect, use, or disclose your personal data based
                on the following lawful basis:
              </p>
              <ul>
                <li>
                  Prepare historical documents or archives for the public interest, or for purposes relating to research
                  statistics.
                </li>
                <li>
                  Prevent or suppress a danger to you or another person’s life, bodily harm, or physical/mental health.
                </li>
                <li>Necessary to carry out a public task, or for exercising official authority.</li>
              </ul>
              <p>
                If the personal data we collect from you is required to meet our legal obligations or to enter into an
                agreement with you, we may not be able to provide (or continue to provide) some or all the school’s
                products or services to you if you do not provide such personal data when requested.
              </p>
              <p className="ml-3 mt-3">
                {' '}
                <strong>
                  5. <span className="ml-3">What personal data we collect, use, or disclose</span>
                </strong>
              </p>
              <p>
                The type of personal data, namely personal data, and sensitive personal data, in which we collect, use,
                or disclose, varies on the scope of products and/or services that you may have used or had an interest
                in. The type of personal data shall include but is not limited to:
              </p>
              <table>
                <tr>
                  <th style={{ border: '1px solid #00000e', padding: '8px' }}>Category</th>
                  <th style={{ border: '1px solid #00000e', padding: '8px' }}>Example of personal data</th>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #00000e', padding: '8px' }}>Personal details</td>
                  <td style={{ border: '1px solid #00000e', padding: '8px' }}>
                    Given name, middle name, surname, nickname (if any) Gender, Date of birth, Age, Educational
                    background, Nationality
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #00000e', padding: '8px' }}>Contact details</td>
                  <td style={{ border: '1px solid #00000e', padding: '8px' }}>
                    Mailing address, E-mail address, Phone number, Name of representatives or authorised persons acting
                    on your behalf, Social media accounts
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #00000e', padding: '8px' }}>
                    Identification and authentication details
                  </td>
                  <td style={{ border: '1px solid #00000e', padding: '8px' }}>
                    ID card photo, Identification number, Passport information, Passport photo, Birth Certificate/Alien
                    ID information, Driving licence, Signatures, and Military Certificate
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #00000e', padding: '8px' }}>
                    Financial details and information about your relationship with us
                  </td>
                  <td style={{ border: '1px solid #00000e', padding: '8px' }}>
                    Information about your Banking transactions, financial status
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #00000e', padding: '8px' }}>
                    Geographic IT information and information about your device and software
                  </td>
                  <td style={{ border: '1px solid #00000e', padding: '8px' }}>
                    Your GPS location, IP address, Computer Name, Hostname, MAC Address, Other IT Technical details that
                    are uniquely identifying data
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #00000e', padding: '8px' }}>Investigation data</td>
                  <td style={{ border: '1px solid #00000e', padding: '8px' }}>Data for due diligence checks</td>
                </tr>

                <tr>
                  <td style={{ border: '1px solid #00000e', padding: '8px' }}>
                    Survey research, marketing research information
                  </td>
                  <td style={{ border: '1px solid #00000e', padding: '8px' }}>
                    Parents, Student, Health & Safety survey, Information and opinions expressed when participating in
                    the school’s market research, Details of services you receive and your preferences
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #00000e', padding: '8px' }}>User login and subscription data</td>
                  <td style={{ border: '1px solid #00000e', padding: '8px' }}>
                    Login information for using the school website, Other applications used by the school. Other
                    subscriptions used by the school.
                  </td>
                </tr>

                <tr>
                  <td style={{ border: '1px solid #00000e', padding: '8px' }}>Information concerning security</td>
                  <td style={{ border: '1px solid #00000e', padding: '8px' }}>
                    CCTV images, Video or Audio recordings, Visual images, Personal appearance, Body Temperature Sensor
                    with Face capture, Finger print. Detection of any suspicious and unusual activity via Drone
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #00000e', padding: '8px' }}>Sensitive Personal Data</td>
                  <td style={{ border: '1px solid #00000e', padding: '8px' }}>
                    Racial or Ethnic Origin, Political Opinions, Cult Religious or Philosophical Beliefs, Sexual
                    Behaviour, Health Data, Disability, Trade Union Information, Genetic Data, Biometric Data, Child
                    Safeguarding Records, Criminal Records
                  </td>
                </tr>

                <tr>
                  <td style={{ border: '1px solid #00000e', padding: '8px' }}>Other information</td>
                  <td style={{ border: '1px solid #00000e', padding: '8px' }}>
                    Records of correspondence and other communications between you and us, Information that you provide
                    us through any other channels, Information about insurance policy and claim for your compensation
                  </td>
                </tr>
              </table>
              <p className="ml-3 mt-3">
                {' '}
                <strong>
                  6. <span className="ml-3">Sources of your personal data</span>
                </strong>
              </p>
              <p>
                Normally, we will collect your personal data directly from you, but sometimes we may get it from other
                sources, in such cases we will ensure the compliance with the PDPA. Personal data we collect from other
                sources may include but is not limited to:
              </p>
              <ul>
                <li>
                  Information obtained by us from other school, financial institution, business partners, and/or any
                  other persons who we have relationship with;
                </li>
                <li>Information obtained by us from persons related to you (e.g. your family, friends, referees);</li>
                <li>
                  Information obtained by us from corporate customers as you are a director, authorised person,
                  attorney, representative or contact person;
                </li>
                <li>
                  Information obtained by us from governmental authorities, regulatory authorities, financial
                  institutions, credit bureau and/or third-party service providers;
                </li>
                <li>Information obtained by us from publicly available resources.</li>
              </ul>
              <p className="ml-3 mt-3">
                {' '}
                <strong>
                  7. <span className="ml-3">Your rights</span>
                </strong>
              </p>
              <p>
                You can exercise your rights under the PDPA as specified below, through the channels prescribed by us at
                our contact details (see Section 14).
              </p>
              <p className="ml-5 mt-3">
                {' '}
                <strong>
                  7.1 <span className="ml-3">Right to access and obtain copy</span>
                </strong>
              </p>
              <p>
                You have the right to access and obtain a copy of your personal data held by us, unless we are entitled
                to reject your request under the law or a court order, or if such request will adversely affect the
                rights and freedoms of EPA or other individuals.
              </p>
              <p className="ml-5 mt-3">
                {' '}
                <strong>
                  7.2 <span className="ml-3">Right to rectification</span>
                </strong>
              </p>
              <p>
                You have the right to rectify your inaccurate personal data and to update incomplete personal data
                related to you.
              </p>
              <p className="ml-5 mt-3">
                {' '}
                <strong>
                  7.3 <span className="ml-3">Right to erasure</span>
                </strong>
              </p>
              <p>
                You have the right to request us to delete, destroy or anonymise your personal data, unless there are
                circumstances where we have the legal grounds to reject your request.
              </p>
              <p className="ml-5 mt-3">
                {' '}
                <strong>
                  7.4 <span className="ml-3">Right to restrict</span>
                </strong>
              </p>
              <p>
                You have the right to request us to restrict the use of your personal data under certain circumstances.
                For example, during the investigation of your request to rectify your personal data; or to object the
                collection, use or disclosure of your personal data, or you request to restrict the use of personal data
                instead of the deletion or destruction of personal data which is no longer necessary as you have
                necessity to retain it for the purposes of establishment, compliance, exercise of protection of legal
                claims.
              </p>
              <p className="ml-5 mt-3">
                {' '}
                <strong>
                  7.5 <span className="ml-3">Right to object</span>
                </strong>
              </p>
              <p>
                You have the right to object to the collection, use or disclosure of your personal data in case we
                proceed with legitimate interests’ basis or for the purpose of direct marketing, or for the purpose of
                scientific, historical or statistical research, unless we have legitimate grounds to reject your
                request. For example, we have compelling legitimate grounds to collect, use or disclose your personal
                data, or the collection, use or disclosure of your personal data is carried out for the establishment,
                compliance, or exercise of legal claims, or for the reason of our public interests.
              </p>
              <p className="ml-5 mt-3">
                {' '}
                <strong>
                  7.6 <span className="ml-3">Right to data portability</span>
                </strong>
              </p>
              <p>
                You have the right to receive your personal data in a format which is readable or commonly used by ways
                of automatic tools or equipment and can be used or disclosed by automated means. Additionally, you have
                the right to request us to send or transfer your personal data to a third party, or to receive your
                personal data which we sent or transferred to a third party, unless it is impossible to do so because of
                the technical circumstances, or we are entitled to legally reject your request.
              </p>
              <p className="ml-5 mt-3">
                {' '}
                <strong>
                  7.7 <span className="ml-3">Right to withdraw consent</span>
                </strong>
              </p>
              <p>
                You have the right to withdraw your consent that has been given to us at any time pursuant to the
                methods and means prescribed by us unless the nature of consent does not allow such withdrawal. The
                withdrawal of consent will not affect the lawfulness of the collection, use, or disclosure of your
                personal data based on your consent before it was withdrawn. You can review and change your consent to
                use or disclose your personal data for marketing purposes through channels as specified in Section 14
                below.
              </p>
              <p className="ml-5 mt-3">
                {' '}
                <strong>
                  7.8 <span className="ml-3">Right to lodge a complaint</span>
                </strong>
              </p>
              <p>
                You have the right to make a complaint to the competent authority where you believe that the collection,
                use and disclosure of your personal data is unlawful or non-compliant with the PDPA.
              </p>
              <p className="ml-3 mt-3">
                {' '}
                <strong>
                  8. <span className="ml-3">How we share your personal data</span>
                </strong>
              </p>
              <p>We may disclose your personal data to the following parties under the provisions of the PDPA:</p>
              <ul>
                <li>
                  Our EPA business partners and/or other persons that we have a legal relationship with, including our
                  directors, executives, staff, contractors, representatives, advisors.
                </li>
                <li>Government authorities and/or supervisory or regulatory authorities.</li>
                <li>
                  Suppliers, agents and other entities (e.g. professional associations to which we belong, external
                  auditors, depositories, document warehouses, overseas financial institutions) where the disclosure of
                  your personal data has a specific purpose and under lawful basis, as well as having appropriate IT
                  security measures.
                </li>
                <li>
                  Special requests from legal authorities such police, lawyers, courts, authorities or any persons whom
                  we are required or permitted by law, regulations, or orders to share such personal data.
                </li>
                <li>
                  Social media service providers (in a secure format) or so they can display relevant messages to you
                  and others on our behalf about our products and/or services.
                </li>
                <li>Third-party security service providers.</li>
                <li>
                  Other persons that provide you with benefits or services associated with your services. For example,
                  insurance agents or insurance companies who provide insurance coverage for the school.
                </li>
                <li>
                  Our attorney, sub-attorney, your authorised persons, or legal representatives who have lawfully
                  authorised power.
                </li>
                <li>Financial institutions on payment details to facilitate payment transactions.</li>
                <li>External health or medical providers on health data.</li>
                <li>
                  Safeguarding information can be shared with external safeguarding professionals where necessary.
                </li>
                <li>
                  Parental requests to provide references, recommendations, reports or transcripts to a new school or
                  university.
                </li>
                <li>Enabling the performance of the contract between parents and the school.</li>
                <li>
                  Data Processors such as Education technologies providers and other parties assisting with the
                  provision of education and support services.
                </li>
                <li>Other schools or organisations for references or educational information.</li>
              </ul>
              <p className="ml-3 mt-3">
                {' '}
                <strong>
                  9. <span className="ml-3">International transfer of personal data</span>
                </strong>
              </p>
              <p>
                When it is necessary for us to send or transfer your personal data internationally, we will always
                exercise our best effort to have your personal data transferred to our reliable business partners,
                service providers or other recipients by the safest method to maintain and protect the security of your
                personal data, which includes the following circumstances:
              </p>
              <ul>
                <li>Comply with our legal obligation.</li>
                <li>
                  Inform you of the inadequate personal data protection standards of the destination country and obtain
                  your consent in compliance with the PDPA.
                </li>
                <li>Perform the agreement made by you with us or your request before entering into an agreement.</li>
                <li>Comply with an agreement between us and other parties for your own interest.</li>
                <li>
                  Prevent or suppress a danger to your or other persons’ life, bodily harm or your health if you are
                  incapable of giving consent at such time.
                </li>
                <li>Carry out activities relating to the substantial public interest in compliance with the PDPA.</li>
              </ul>
              <p className="ml-3 mt-3">
                {' '}
                <strong>
                  10. <span className="ml-3">Retention period of personal data</span>
                </strong>
              </p>
              <p>
                All personal data is securely stored in accordance with the PDPA requirements. We retain your personal
                data only for legitimate purposes, relying on one or more of the lawful bases as set out above, and only
                for so long as necessary for those purposes, or as required by law.
              </p>
              <p>
                The period we keep your personal data will be linked to the prescription period or the period under the
                relevant laws and regulations (e.g. Accounting Laws, Tax Laws, Labour Laws and other laws to which we
                are subject both in Thailand and in other countries). In addition, we may need to retain records of CCTV
                surveillance in our school campus to ensure security, including investigating suspicious activities of
                which related persons may inform us in the future.
              </p>
              <p className="ml-3 mt-3">
                {' '}
                <strong>
                  11. <span className="ml-3">Use of Cookies</span>
                </strong>
              </p>
              <p>
                We may collect and use cookies and similar technologies when you use our products and/or services. This
                includes when you use our websites, and other school applications. The collection of such cookies and
                similar technologies helps us recognise you, remember your preferences and customise how we provide our
                products and/or services to you. We may use cookies for several purposes. For example, enabling and
                operating basic functions, helping us understand how you interact with our websites or emails, or
                enabling us to improve your online experiences or our communications with you. For more detailed
                information, please refer to the school’s cookie policy. Here is our school’s cookie policy.{' '}
              </p>
              <p className="ml-3 mt-3">
                {' '}
                <strong>
                  12. <span className="ml-3">Use of personal data for original purposes</span>
                </strong>
              </p>
              <p>
                We are entitled to continue collecting and using your personal data, which has previously been collected
                by us before the enactment of the PDPA in relation to the collection, use and disclosure of personal
                data, in accordance with the original purposes.
              </p>
              <p className="ml-3 mt-3">
                {' '}
                <strong>
                  13. <span className="ml-3">Security</span>
                </strong>
              </p>
              <p>
                We endeavour to ensure the security of your personal data through our internal IT security measures and
                strict policy enforcement. The measures extend from data encryption to firewalls. We also require our
                staff and third-party contractors to follow our applicable IT security standards and policies and to
                exercise due care and measures when using, sending, or transferring your personal data.
              </p>
              <p className="ml-3 mt-3">
                {' '}
                <strong>
                  14. <span className="ml-3">How to contact us</span>
                </strong>
              </p>
              <p>
                If you wish to exercise any of your rights under the PDPA for which we are the data controller, please
                make your request by emailing our Data Protection Officer and follow-up with written request with your
                identification documents at the school as detailed below:
              </p>
              <p>
                <mark>
                  The Data Protection Officer (Mr. Pornthep Srisuttyan) lEPA 294/1 Asia Building, 11B Floor 11,
                  Phayathai Road, Petchaburi, Ratchathewi, Bangkok 10400 Telephone:+02-611-0348 Email: info@epa.ac.th
                </mark>
              </p>
              <p>
                Please note that these rights are not absolute, and we may be entitled to or required additional
                personal identification as required by the school. We will respond to any such written requests as soon
                as is reasonably practicable and within statutory time limits.
              </p>
              <p className="ml-3 mt-3">
                {' '}
                <strong>
                  15. <span className="ml-3">Changes to this Data Privacy Notice</span>
                </strong>
              </p>
              <p>
                We will update this Data Privacy Notice from time to time. Any substantial changes that affect how we
                process your personal data will be displayed on our website and sent to you directly when deemed
                necessary.
              </p>
            </div>
          </div>
        </div>

        {/* <div className="container">
          <div className="row">
            <div className="col-12 section__text text-justify">
              <p>Tenopy is committed to protecting and respecting your privacy.</p>
              <p>
                This policy, together with our <Link to={TERM_OF_SERVICE}>Terms of Service</Link> and any other
                documents referred to in it, sets out the basis on which any personal data we collect from you, or that
                you provide to us, will be processed by us. Please read the following carefully to understand our views
                and practices regarding your personal data and how we will treat it.
              </p>
              <p>If you are under 18, please do not send any personal data about yourself to us.</p>

              <p className="text-underline mb-0">
                <strong>Information collection</strong>
              </p>
              <p>
                In order to provide a better service to you, we may collect and process the following data about you:
              </p>
              <ul>
                <li>
                  information that you provide by filling in forms on our site www.tenopy.com ("our site"). This
                  includes information provided at the time of registering to use our site, subscribing to our service,
                  posting material or requesting further services. We may also ask you for information at other times,
                  for example in connection with a promotion or when you report a problem with our site
                </li>
                <li>if you contact us, we may keep a record of that correspondence</li>
                <li>
                  we may also ask you to complete surveys that we use for research purposes, although you do not have to
                  respond to them
                </li>
                <li>details of transactions you carry out through our site and of the fulfilment of your orders</li>
                <li>details of your visits to our site and the resources that you access</li>
              </ul>
              <p>
                We only retain personal data for so long as it is necessary. Data may be archived as long as the purpose
                for which the data was used still exists.
              </p>

              <p className="text-underline mb-0">
                <strong>Uses made of the information</strong>
              </p>
              <p>The purposes for which information may be used by us in and outside Singapore include:</p>
              <ul>
                <li>
                  ensuring that content from our site is presented in the most effective manner for you and for your
                  computer
                </li>
                <li>
                  providing you with alerts, newsletter, education materials or information that you requested or signed
                  up to
                </li>
                <li>carrying out our obligations arising from any contracts entered into between you and us</li>
                <li>allowing you to participate in interactive features of our service, when you choose to do so</li>
                <li>
                  designing and conducting surveys/questionnaires for client profiling/segmentation, statistical
                  analysis, improving and furthering the provision our products and services
                </li>
                <li>
                  complying with laws and regulations applicable to us or any of our affiliates in or outside Singapore
                </li>
                <li>legal proceedings, including collecting overdue amounts and seeking professional advices</li>
                <li>researching, designing and launching services or products including seminars/events/forums</li>
                <li>promoting and marketing services and products subject to your exercise of the opt-out right or</li>
                <li>purposes directly related or incidental to the above</li>
              </ul>
              <p>
                We intend to use your data in direct marketing and we require your consent (which includes an indication
                of no objection) for that purpose. In this connection, please note that:
              </p>
              <ul>
                <li>
                  your name, contact details (including address, contact number, email address), products and services
                  information, transaction pattern and behaviour, background and demographic data held by us from time
                  to time may be used by us in direct marketing;
                </li>
                <li>
                  the following classes of services, products and subjects may be marketed in direct marketing:
                  <ol>
                    <li>
                      services and products related to our site and/or our affiliates (including marketing affiliates
                      programs we are a part of)
                    </li>
                    <li>reward, loyalty or privileges programmes, promotional offers and related services and</li>
                    <li>
                      invitations to events such as seminars/webinars/tele-seminars, conferences, live programs or
                      events
                    </li>
                  </ol>
                </li>
                <li>
                  We may conduct direct marketing via fax, email, direct mail, telephone and other means of
                  communication or send e-newsletters to you. You may choose not to receive promotional materials, by
                  simply telling us (see below for contact details), and we will cease to do so, without charge
                </li>
              </ul>

              <p className="text-underline mb-0">
                <strong>Disclosure of your information</strong>
              </p>
              <p>We will keep the personal data we hold confidential but may provide information to:</p>
              <ul>
                <li>
                  personnel, agents, advisers, auditors, contractors, financial institutions, and service providers in
                  connection with our operations or services
                </li>
                <li>our overseas offices, affiliates, business partners and counterparts (if any)</li>
                <li>persons under a duty of confidentiality to us</li>
                <li>
                  persons to whom we are required to make disclosure under applicable laws and regulations in or outside
                  Singapore or
                </li>
                <li>actual or proposed transferees or participants of our services in or outside Singapore</li>
              </ul>

              <p className="text-underline mb-0">
                <strong>Cookies</strong>
              </p>
              <p>
                Our website uses cookies to distinguish you from other users of our website. This helps us to provide
                you with a good experience when you browse our website and also allows us to improve our site. By
                continuing to browse the site, you are agreeing to our use of cookies.
              </p>
              <p>
                A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your
                computer if you agree. Cookies contain information that is transferred to your computer's hard drive.
              </p>
              <p>We use the following cookies:</p>
              <ul>
                <li>
                  <strong>Strictly necessary cookies.</strong> These are cookies that are required for the operation of
                  our website. They include, for example, cookies that enable you to log into secure areas of our
                  website, use a shopping cart or make use of ebilling services.
                </li>
                <li>
                  <strong>Analytical/performance cookies.</strong> They allow us to recognise and count the number of
                  visitors and to see how visitors move around our website when they are using it. This helps us to
                  improve the way our website works, for example, by ensuring that users are finding what they are
                  looking for easily.
                </li>
                <li>
                  <strong>Functionality cookies.</strong> These are used to recognise you when you return to our
                  website. This enables us to personalise our content for you, greet you by name and remember your
                  preferences (for example, your choice of language or region).
                </li>
                <li>
                  <strong>Targeting cookies.</strong> These cookies record your visit to our website, the pages you have
                  visited and the links you have followed. We will use this information to make our website and the
                  advertising displayed on it more relevant to your interests. We may also share this information with
                  third parties for this purpose.
                </li>
              </ul>
              <p>
                Please note that third parties (including, for example, advertising networks and providers of external
                services like web traffic analysis services) may also use cookies, over which we have no control. These
                cookies are likely to be analytical/performance cookies or targeting cookies.
              </p>
              <p>
                Our website uses Google Analytics, a web traffic analysis service provided by Google Inc. ("Google").
                Please refer to http://www.google.com/policies/privacy/partners to find out more about how Google uses
                data when you use our website and how to control the information sent to Google.
              </p>
              <p>
                You block cookies by activating the setting on your browser that allows you to refuse the setting of all
                or some cookies. However, if you use your browser settings to block all cookies (including essential
                cookies) you may not be able to access all or parts of our site.
              </p>
              <p>
                Furthermore, you can prevent Google's collection and processing of data by using the Google Ads Settings
                page or downloading and installing their browser plug-in (https://tools.google.com/dlpage/gaoptout).
              </p>

              <p className="text-underline mb-0">
                <strong>Security</strong>
              </p>
              <p>
                All information you provide to us is stored on our secure servers. Where we have given you (or where you
                have chosen) a password which enables you to access certain parts of our site, you are responsible for
                keeping this password confidential. We ask you not to share a password with anyone.
              </p>
              <p>
                Unfortunately, the transmission of information via the internet is not completely secure. Although we
                will do our best to protect your personal data, we cannot guarantee the security of your data
                transmitted to our site; any transmission is at your own risk and you agree not to hold us responsible
                for any breach of security while accessing the internet that is out of our control. Once we have
                received your information, we will use strict procedures and security features to try to prevent
                unauthorised access.
              </p>

              <p className="text-underline mb-0">
                <strong>Changes to our privacy policy</strong>
              </p>
              <p>
                Any changes we may make to our Privacy Policy in the future will be posted on this page. Your continued
                use of our services constitutes your agreement to this Privacy Policy and any updates.
              </p>

              <p className="text-underline mb-0">
                <strong>Data transfers</strong>
              </p>
              <p>
                We may hold your data on servers in Singapore and any other territories as we see fit from time to time.
                We may also transfer your data to our overseas offices or to any people listed at&nbsp;
                <strong>Disclosure of your information</strong> above, who may be located in or outside of Singapore.
              </p>

              <p className="text-underline mb-0">
                <strong>Your consent and rights</strong>
              </p>
              <p>
                By using our service, making an application or visiting our website, you consent to the collection and
                use of your information and other activities as outlined in this policy.
              </p>
              <p>Under the Personal Data Protection Act (the "PDPA"), individuals have the right:</p>
              <ul>
                <li>to check whether we hold personal data about you and to access such data</li>
                <li>
                  to require us to correct as soon as reasonably practicable any data relating to you that is inaccurate
                </li>
                <li>
                  to ascertain our policies and practices in relation to personal data and the kind of personal data
                  held by us and
                </li>
                <li>
                  to object to the use of your personal data for marketing purposes and we shall not use your personal
                  data for marketing purposes after you communicate your objection to us
                </li>
              </ul>
              <p>
                You may exercise your opt-out right by notifying us if you wish to object to the use of your ersonal
                data for direct marketing purposes. Please send requests for such objections, access to data, correction
                of data, information regarding policies and practices and kinds of data held, questions or complaints
                to: ask@tenopy.com
              </p>
              <p>
                In accordance with the terms of the PDPA, we have the right to and may charge a minimum fee for
                processing any data access request.
              </p>

              <p className="text-underline mb-0">
                <strong>Governing law and jurisdiction</strong>
              </p>
              <p>
                Nothing in this Privacy Policy shall limit the rights of the data subject under the PDPA. This Privacy
                Policy shall be governed by the laws of Singapore. You agree to submit to the exclusive jurisdiction of
                the Singapore courts.
              </p>

              <p>
                Last updated: <strong>16 June 2017</strong>
              </p>
            </div>
          </div> 
        </div>*/}
      </section>
    </div>
  )
}
