import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Avatar, Button, CircularProgress, Dialog, DialogContent, Slide } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import _ from 'lodash'
import moment from 'moment'
import Proptypes from 'prop-types'

import agent from 'agent'
import { GET_TRIAL_AVAILABILITY, JOIN_TRIAL_SERVICE, REQUEST_TRIAL_SERVICE } from 'constants/actionTypes'
import { APP } from 'routes/routes'
import { MY_TRIAL_CLASSES } from 'routes/studentRoutes'
import LoginRegister from 'components/shared/LoginRegister/LoginRegister'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const mapStateToProps = state => ({
  token: state.common.token,
  currentUser: state.common.currentUser,
  trials: state.auth.trials,
  requestingTrialService: state.auth.requestingTrialService,
  joiningTrialService: state.auth.joiningTrialService,
  joinTrialStatus: state.auth.joinTrialStatus,
  checkingTrialAvailability: state.auth.checkingTrialAvailability,
  trialAvailable: state.auth.trialAvailable
})

const mapDispatchToProps = dispatch => ({
  checkTrialAvailability: class_id =>
    dispatch({ type: GET_TRIAL_AVAILABILITY, payload: agent.Auth.checkTrialAvailability('online_tutoring', class_id) }),
  requestTrialService: (serviceType, classId) =>
    dispatch({ type: REQUEST_TRIAL_SERVICE, payload: agent.Auth.requestTrialService(serviceType, classId) }),
  joinTrialLesson: (serviceType, trialItemIndex, classId) =>
    dispatch({ type: JOIN_TRIAL_SERVICE, payload: agent.Auth.joinTrialService(serviceType, trialItemIndex, classId) })
})

class JoinTrialDialog extends React.Component {
  state = {
    loading: false,
    errorMsg: '',
    successMsg: ''
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentUser === null && !!this.props.currentUser && this.props.classData) {
      this.props.checkTrialAvailability(this.props.classData._id)
      this.setState({ loading: true })
    }

    if (prevProps.checkingTrialAvailability && !this.props.checkingTrialAvailability) {
      if (this.props.trialAvailable) {
        let { classData } = this.props
        this.props.requestTrialService('online_tutoring', classData._id)
      } else {
        this.setState({
          loading: false,
          errorMsg: 'You are only allowed 1 free trial per class. Please contact us for further inquiries.'
        })
      }
    }

    if (prevProps.requestingTrialService && !this.props.requestingTrialService && this.props.trials) {
      this.props.joinTrialLesson('online_tutoring', 0, this.props.classData._id)
    }

    if (prevProps.joiningTrialService && !this.props.joiningTrialService) {
      if (this.props.joinTrialStatus === 'Success') {
        this.setState({
          loading: false,
          errorMsg: '',
          successMsg: 'Join lesson successfully. Enjoy your lesson.'
        })
      } else {
        this.setState({
          loading: false,
          successMsg: '',
          errorMsg: 'Join lesson failed. Please contact us.'
        })
      }
    }

    if (this.props.isOpen && !prevProps.isOpen) {
      if (this.props.token) {
        this.props.checkTrialAvailability(this.props.classData._id)
        this.setState({ loading: true, errorMsg: '', successMsg: '' })
      }
    }
  }

  continue = () => {
    if (this.state.successMsg) {
      this.props.history.push(`${APP}${MY_TRIAL_CLASSES}`)
    }
    this.props.toggleDialog()
  }

  render() {
    let { classData, isOpen, token, requestingTrialService, joiningTrialService } = this.props
    let { loading, errorMsg, successMsg } = this.state

    if (!classData) {
      return null
    }

    let nextLesson = _.find(classData.lesson, function (o) {
      return moment(o.scheduled_on) > moment()
    })
    let showLoginRegister = errorMsg || successMsg || token ? false : true

    return (
      <Dialog open={isOpen} onClose={this.props.toggleDialog} TransitionComponent={Transition} maxWidth="md">
        <DialogContent>
          <Close
            className="position-absolute clickable m-2"
            onClick={this.props.toggleDialog}
            style={{ right: 0, top: 0, zIndex: 1 }}
          />

          <div className="row align-items-center" style={{ overflow: 'hidden' }}>
            <div
              className={`
                ${!showLoginRegister ? 'col-md-12 border-0' : 'col-md-5'} col-12
                ${isMobile ? 'border-bottom' : 'border-right'}
              `}>
              <div className="row align-items-center no-gutters">
                <div className="col-auto">
                  <Avatar
                    className="size-60 avatar-shadow mr-2"
                    alt={classData.tutor[0].name}
                    src={classData.tutor[0].profile_pic}
                  />
                </div>
                <div className="col">
                  <h3 className="mb-0">{classData.name}</h3>
                  <span>by {classData.tutor[0].name}</span>
                </div>
                <div className="col-12 my-3 p-2" style={{ backgroundColor: '#d4fed3', borderRadius: '10px' }}>
                  {nextLesson && (
                    <div className="text-large text-center">
                      Get your <span className="font-weight-medium text-underline text-red">FREE</span> trial lesson
                      (worth&nbsp;
                      <span className="font-weight-medium text-underline text-red">${classData.rate.toFixed(2)}</span>)
                      with us today!
                    </div>
                  )}
                </div>

                <div className="col-12 mb-3">
                  <h3 className="mb-2">What will you get: </h3>
                  <div className="d-flex flex-row align-items-start pl-2">
                    <i className="material-icons text-primary mr-2">check_circle_outline</i>
                    <span>
                      1 LIVE online lesson on&nbsp;
                      <span className="text-underline">
                        {moment(nextLesson.scheduled_on).format('Do MMM, YYYY [at] h:mma')}
                      </span>
                    </span>
                  </div>
                  {/*<div className="d-flex flex-row align-items-start align-items-md-center pl-2">
                    <i className="material-icons text-primary mr-2">check_circle_outline</i>
                    Homework marking & personal feedback
                  </div>*/}
                  <div className="d-flex flex-row align-items-start align-items-md-center pl-2">
                    <i className="material-icons text-primary mr-2">check_circle_outline</i>
                    Unlimited class video playback
                  </div>
                  {/*<div className="d-flex flex-row align-items-start align-items-md-center pl-2">
                    <i className="material-icons text-primary mr-2">check_circle_outline</i>
                    Weekly reports on student progress
                  </div>*/}
                </div>

                {(requestingTrialService || joiningTrialService) && (
                  <div className="d-flex justify-content-center w-100 my-3">
                    <CircularProgress size={30} color="primary" />
                  </div>
                )}

                {(errorMsg || successMsg) && (
                  <div className="col-12">
                    {errorMsg && (
                      <div className="d-flex flex-row justify-content-center">
                        <i className="material-icons text-warning mr-2">warning</i>
                        <span className="h3 font-weight-normal text-warning">{errorMsg}</span>
                      </div>
                    )}
                    {successMsg && (
                      <div className="d-flex flex-row justify-content-center">
                        <i className="material-icons text-info mr-2">today</i>
                        <span className="h3 font-weight-normal text-info">{successMsg}</span>
                      </div>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      className="mt-3"
                      fullWidth={true}
                      onClick={this.continue}>
                      Continue
                    </Button>
                  </div>
                )}
              </div>
            </div>

            {showLoginRegister && (
              <div className="col-md-7 col-12 px-4" style={{ position: 'relative' }}>
                {loading && (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      position: 'absolute',
                      backgroundColor: 'rgba(255, 255, 255, .7)',
                      width: '100%',
                      height: '100%',
                      zIndex: '100'
                    }}>
                    <CircularProgress size={20} color="primary" />
                  </div>
                )}
                <LoginRegister
                  header="Get your free trial"
                  nextLocation="none"
                  customAction="JoinTrial"
                  registerButtonText="Register for Free Trial"
                  loginButtonText="login"
                />
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

JoinTrialDialog.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  toggleDialog: Proptypes.func.isRequired
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JoinTrialDialog))
