import * as Type from '../constants/actionTypes'

const initialState = {
  loadingTutorData: false,
  tutors: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.GET_TUTOR_INFO:
      return {
        ...state,
        loadingTutorData: true
      }

    case Type.GET_TUTOR_INFO_SUCCESS: {
      let updatedTutors = state.tutors
      updatedTutors[action.slug] = action.payload.data

      return {
        ...state,
        loadingTutorData: false,
        tutors: updatedTutors
      }
    }

    default:
      return state
  }
}
