import React from 'react'
import { isMobile } from 'react-device-detect'
import { Avatar } from '@material-ui/core'

class TeacherCard extends React.Component {
  render() {
    const { name, department, description, image, education, experience, bgColor, className, eduThai, expThai } =
      this.props

    return (
      <div className={`row mt-5 justify-content-center ${className && className}`}>
        <div
          className="col-lg-9"
          style={{ backgroundColor: `${bgColor}`, marginLeft: isMobile ? '0px' : '50px', borderRadius: '8px' }}>
          <div
            className="row"
            style={{ paddingTop: '64px', paddingBottom: '64px', paddingLeft: '50px', paddingRight: '38px' }}>
            <div className="col-lg-4 pr-2">
              {isMobile ? (
                <div className="col-12">
                  <div className="d-flex flex-row justify-content-center mb-3">
                    <Avatar className="size-120 avatar-shadow mr-3" alt={name} src={image} />
                  </div>
                </div>
              ) : (
                <div
                  className="col-auto align-self-end"
                  style={{
                    overflow: 'hidden',
                    minHeight: '30.15vh',
                    backgroundImage: `url(${image})`,
                    width: '100%',
                    backgroundSize: 'cover',
                    borderRadius: '4px'
                  }}></div>
              )}
            </div>
            <div className="col-lg-8">
              <div className="section__header_landing_title">
                <div className="paragraph--bold mb-3 text-left">{name}</div>
                <div className="description--bold text-left mb-3" style={{ color: '#FD6502' }}>
                  {department}
                </div>
                <div className="description text-left">{description}</div>
                <div className="row mt-4">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-2 col-2 col-md-1">
                        <img
                          src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/mortarboard.svg"
                          width="70%"
                          style={{ marginTop: '-10px' }}></img>
                      </div>
                      <div
                        className="col-lg-10 text-left col-10 col-md-10 explanation--bold"
                        style={{
                          marginLeft: '-20px'
                        }}>
                        Education
                      </div>
                      <div
                        className="col-lg-12 text-left explanation"
                        style={{ fontFamily: eduThai === 'yes' && 'Noto Sans Thai' }}>
                        {education}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-2 col-2 col-md-1 mt-lg-0 mt-4">
                        <img
                          src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/shield.svg"
                          width="50%"
                          style={{ marginTop: '-10px' }}></img>
                      </div>
                      <div
                        className="col-lg-10 text-left col-10 col-md-10 explanation--bold mt-lg-0 mt-4"
                        style={{
                          marginLeft: '-30px'
                        }}>
                        Experience
                      </div>
                      <div
                        className="col-lg-12 text-left explanation"
                        style={{ fontFamily: expThai === 'yes' && 'Noto Sans Thai' }}>
                        {experience}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TeacherCard
