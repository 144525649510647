import React from 'react'
import Proptypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core'
import moment from 'moment'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ProceedToCheckout = props => {
  return (
    <Dialog open={props.isOpen} onClose={props.toggleDialog} TransitionComponent={Transition}>
      <DialogTitle>
        <span className="h2">Added to Cart</span>
      </DialogTitle>
      {props.classTitle && props.lessonsIncluded.length > 0 && (
        <DialogContent>
          <span className="text-large">
            You have added&nbsp;
            <span className="font-weight-semibold">
              {props.classTitle} ({props.lessonsIncluded.length} lessons)
            </span>
            &nbsp;to cart.
          </span>
          <br />
          {!props.hideTime && (
            <>
              <div className="d-flex align-items-center text-muted my-2">
                <i className="material-icons mr-2">event</i>From&nbsp;
                {moment(props.lessonsIncluded[0].scheduled_on).format('ll')} to&nbsp;
                {moment(props.lessonsIncluded[props.lessonsIncluded.length - 1].scheduled_on).format('ll')}
              </div>
              <div className="d-flex align-items-center text-muted">
                <i className="material-icons mr-2">access_time</i>
                {moment(props.lessonsIncluded[0].scheduled_on).format('h:mm a')} to&nbsp;
                {moment(props.lessonsIncluded[0].ended_on).format('h:mm a')}
              </div>
            </>
          )}
        </DialogContent>
      )}
      <DialogActions>
        {!props.hideContinueBrowsing && (
          <Button onClick={props.toggleDialog} color="primary" variant="outlined">
            Continue Browsing
          </Button>
        )}
        <Button onClick={props.proceedToCheckout} color="primary" variant="contained">
          Proceed to Checkout
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ProceedToCheckout.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  toggleDialog: Proptypes.func.isRequired
}

export default ProceedToCheckout
