import * as Type from '../constants/actionTypes'

const initialState = {
  classCategories: {
    classes: {
      Primary: {
        'Primary 3': 'primary-3',
        'Primary 4': 'primary-4',
        'Primary 5': 'primary-5',
        'Primary 6': 'primary-6'
      },
      Secondary: {
        'Secondary 1': 'secondary-1',
        'Secondary 2': 'secondary-2'
      }
    }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.CONTACT_US:
      return {
        ...state,
        contactInProgress: true,
        contacted: false,
        contactError: null
      }

    case Type.CONTACT_US_SUCCESS:
      return {
        ...state,
        contactInProgress: false,
        contacted: true
      }

    case Type.CONTACT_US_FAILED:
      return {
        ...state,
        contactProgress: false,
        contactError: true
      }

    case Type.SUBSCRIBE_TO_SERVICE:
      return {
        ...state,
        inProgress: true,
        subscribed: false,
        error: false
      }

    case Type.SUBSCRIBE_TO_SERVICE_SUCCESS:
      return {
        ...state,
        inProgress: false,
        subscribed: true
      }

    case Type.SUBSCRIBE_TO_SERVICE_FAILED:
      return {
        ...state,
        inProgress: false,
        subscribed: false,
        error: true
      }

    case Type.GET_TRIAL_PAGE_VARIANT:
      return {
        ...state,
        gettingTrialPageVariant: true
      }

    case Type.GET_TRIAL_PAGE_VARIANT_SUCCESS:
      return {
        ...state,
        gettingTrialPageVariant: false,
        trialPageVariant: action.payload
      }

    case Type.GET_TRIAL_PAGE_VARIANT_FAILED:
      return {
        ...state,
        gettingTrialPageVariant: false
      }

    case Type.GET_CUSTOM_OPTION:
      return {
        ...state,
        gettingOption: true
      }

    case Type.GET_CUSTOM_OPTION_SUCCESS:
      return {
        ...state,
        gettingOption: false,
        option: action.payload
      }

    case Type.GET_CUSTOM_OPTION_FAILED:
      return {
        gettingCon: false,
        option: []
      }

    case Type.GET_CUSTOM_CLASS_CATEGORIES:
      return {
        ...state,
        gettingClassCategories: true
      }

    case Type.GET_CUSTOM_CLASS_CATEGORIES_SUCCESS:
      return {
        ...state,
        gettingClassCategories: false,
        classCategories: action.payload
      }

    case Type.GET_CUSTOM_CONFIG:
      return {
        ...state,
        loadingConfig: true,
        customConfig: null
      }

    case Type.GET_CUSTOM_CONFIG_SUCCESS:
      return {
        ...state,
        loadingConfig: false,
        customConfig: action.payload
      }

    case Type.GET_CUSTOM_CONFIG_FAILED:
      return {
        ...state,
        loadingConfig: false,
        customConfig: ''
      }

    case Type.GET_BLOG_POST:
      return {
        ...state,
        loadingBlogPost: true,
        blogPost: null
      }

    case Type.GET_BLOG_POST_SUCCESS:
      return {
        ...state,
        loadingBlogPost: false,
        blogPost: action.payload
      }

    case Type.GET_BLOG_POST_FAILED:
      return {
        ...state,
        loadingBlogPost: false,
        blogPost: null
      }

    default:
      return state
  }
}
