import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'
import agent from 'agent'
import { UPDATE_STUDENT, GET_LESSON_BY_ID } from 'constants/actionTypes'
import { USER_PROFILE } from 'routes/routes'
import { DataGrid } from '@mui/x-data-grid'
import { Button } from '@material-ui/core'
import DialogUserEdit from './DialogUserEdit'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  updateUserInProgress: state.common.updateUserInProgress,
  studentUpdated: state.common.studentUpdated
})

const mapDispatchToProps = dispatch => ({
  getLesson: lessonId => dispatch({ type: GET_LESSON_BY_ID, payload: agent.Lessons.getById2(lessonId) }),
  updateStudent: (userId, userObj) =>
    dispatch({ type: UPDATE_STUDENT, payload: agent.Auth.updateStudent(userId, userObj) })
})

const StudentTable = props => {
  const { studentList, renderEditButton } = props

  if (studentList.length > 0) {
    return (
      <div className="table-responsive-material bg-white">
        <Table>
          <TableHead style={{ backgroundColor: 'rgba(60, 72, 88, 0.1)' }}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>School</TableCell>
              <TableCell>Birthday</TableCell>
              <TableCell>Gender</TableCell>

              {/* <TableCell>First Language</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {studentList.map(studentData => {
              let studentGender = 'N/A'
              if (studentData.gender === 'm') {
                studentGender = 'male'
              } else if (studentData.gender === 'f') {
                studentGender = 'female'
              }

              return (
                <TableRow key={studentData._id}>
                  <TableCell>
                    <div className="d-flex align-items-center">
                      <span>{studentData.name}</span>
                    </div>
                  </TableCell>
                  <TableCell>{studentData.phone}</TableCell>
                  <TableCell>{studentData.email}</TableCell>
                  <TableCell>{studentData.school ?? 'N/A'}</TableCell>
                  <TableCell>
                    {studentData.date_of_birth ? moment(studentData.date_of_birth).format('ll') : 'N/A'}
                  </TableCell>
                  <TableCell>{studentGender}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }

  // if (studentList.length > 0) {
  //   const columns = [
  //     { field: 'name', headerName: 'Name', width: 180, editable: true },
  // {
  //   field: 'school',
  //   headerName: 'School',
  //   width: 220,
  //   editable: true
  // },
  // {
  //   field: 'examDate',
  //   headerName: 'Next exam date',
  //   width: 120,
  //   editable: false
  // },
  // {
  //   field: 'punctual',
  //   headerName: 'Late to class?',
  //   width: 120,
  //   editable: false
  // },
  // {
  //   field: 'cancelClass',
  //   headerName: 'Cancel classes often?',
  //   width: 120,
  //   editable: false
  // },
  // {
  //   field: 'questionEngagement',
  //   headerName: 'Does student answer questions?',
  //   width: 120,
  //   editable: false
  // },
  // {
  //   field: 'grades',
  //   headerName: 'GPA',
  //   flex: 1,
  //   minWidth: 70,
  //   editable: false
  // },
  // {
  //   field: 'medium',
  //   headerName: 'Medium of study',
  //   width: 130,
  //   editable: false
  // },
  // {
  //   field: 'courseType',
  //   headerName: 'Courses taken',
  //   width: 120,
  //   editable: false
  // },
  // {
  //   field: 'examType',
  //   headerName: 'Exams taken',
  //   editable: false
  // },
  // {
  //   field: 'otherSchool',
  //   headerName: 'Currently studying with another school?',
  //   width: 150,
  //   editable: false
  // },
  // { field: 'gender', headerName: 'Gender', width: 100, editable: false },
  // {
  //   field: 'birthday',
  //   headerName: 'Birthday',
  //   width: 130,
  //   type: 'date',
  //   editable: false
  // },
  //   {
  //     field: 'edit',
  //     headerName: 'Edit',
  //     renderCell: renderEditButton
  //   }
  // ]
  //   const rows = []

  //   {
  //     studentList.map(studentData => {
  //       let studentGender = 'N/A'
  //       if (studentData.gender === 'm') {
  //         studentGender = 'male'
  //       } else if (studentData.gender === 'f') {
  //         studentGender = 'female'
  //       }

  //       const rowsData = {
  //         id: studentData._id,
  //         name: studentData.name,
  //         school: studentData.school ?? 'N/A',
  //         // firstLanguage: studentData.first_language ?? 'N/A',
  //         examDate: studentData.examDate ? moment(studentData.examDate).format('ll') : '',
  //         punctual: studentData.punctual ?? '',
  //         cancelClass: studentData.cancelClass ?? '',
  //         questionEngagement: studentData.questionEngagement ?? '',
  //         grades: studentData.grades ?? '',
  //         medium:
  //           studentData.studyMediumEng === true && studentData.studyMediumThai === true
  //             ? 'English, Thai'
  //             : studentData.studyMediumEng === true
  //             ? 'English'
  //             : studentData.studyMediumThai === true
  //             ? 'Thai'
  //             : '',
  //         courseType: studentData.courseType ?? '',
  //         examType: studentData.examType ?? '',
  //         otherSchool: studentData.otherSchoolYes === true ? 'Yes' : studentData.otherSchoolNo === true ? 'No' : '',
  //         gender: studentGender,
  //         birthday: studentData.date_of_birth ? moment(studentData.date_of_birth).format('ll') : 'N/A'
  //       }

  //       rows.push(rowsData)
  //     })
  //   }

  //   return (
  //     <div className="table-responsive-material bg-white">
  //       <div style={{ height: 700, width: '100%' }}>
  //         <DataGrid rows={rows} columns={columns} />
  //       </div>
  //     </div>
  //   )
  // }

  return null
}

class StudentTab extends React.Component {
  state = {
    selectedStudent: '',
    userDialogOpen: false,
    isUpdateTicketDialogOpen: false
  }

  toggleUserDialog = user => () => {
    this.setState({ userDialogOpen: !this.state.userDialogOpen, selectedStudent: user })
  }

  onSave = () => {
    this.toggleUserDialog(null)()
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.updateUserInProgress && !this.props.updateUserInProgress) {
      this.props.history.push(USER_PROFILE)
    }

    if (this.props.studentUpdated !== prevProps.studentUpdated) {
      this.props.getLesson(this.props.lessonId)
    }
  }

  renderEditButton = params => {
    const { paidStudent } = this.props

    return (
      <strong>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={this.toggleUserDialog(paidStudent.filter(p => p._id === params.row.id)[0])}>
          Edit
        </Button>
      </strong>
    )
  }

  render() {
    const { paidStudent } = this.props
    const { selectedStudent, userDialogOpen } = this.state

    return (
      <div className="container-fluid animated slideInUpTiny animation-duration-3">
        <div className="row">
          <div className="col-12 mb-3">
            <div className="d-flex flex-row align-items-center mb-2">
              <Tooltip id="tooltip-bottom-start" title="Students in this lesson" placement="bottom-start">
                <i className="fa fa-info-circle mr-1" />
              </Tooltip>
              <h3 className="mb-0">Student ({paidStudent.length})</h3>
            </div>
            <StudentTable studentList={paidStudent} />
            {/* <StudentTable studentList={paidStudent} renderEditButton={this.renderEditButton} /> */}
          </div>
        </div>

        {userDialogOpen && (
          <DialogUserEdit
            toggle={this.toggleUserDialog()}
            isOpen={userDialogOpen}
            userData={selectedStudent}
            onSave={this.onSave}
          />
        )}
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentTab))
