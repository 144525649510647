import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'

export function InputText(props) {
  const [value, setValue] = useState('')
  const {
    onBlur,
    name,
    defaultValue,
    type,
    label,
    className,
    multiline,
    minRows,
    variant,
    color,
    InputProps,
    placeholder,
    helperText,
    disabled,
    inputProps
  } = props

  useEffect(() => {
    // console.log(value);
  })

  return (
    <TextField
      className={className}
      placeholder={placeholder}
      fullWidth
      name={name}
      label={label}
      variant={variant}
      color={color}
      InputProps={InputProps}
      onBlur={e => onBlur(e)}
      type={type ? type : 'text'}
      defaultValue={defaultValue}
      helperText={helperText}
      disabled={disabled}
      inputProps={inputProps}
      onChange={e => setValue(e.target.value)}
      multiline={multiline}
      minRows={minRows}
    />
  )
}
