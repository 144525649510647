import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ROOT } from 'routes/routes'

export default function CookiesPolicy() {
  return (
    <div className="landing">
      <Helmet>
        <title>Cookies Policy | EPA</title>
      </Helmet>
      <section className="bg-white">
        <div className="container">
          <div className="section__header">
            <img
              width="40%"
              src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/logo/EPA+Extended+Logo+Light+Background+(Transparent).png"></img>
            <div className="title mt-3">Cookie Policy for EPA</div>
          </div>

          <div className="row">
            <div className="col-12 section__text text-justify info-explanation-black ">
              <p className="text-underline mb-0">
                <strong>What Are Cookies</strong>
              </p>
              <p className="mt-2">
                As is common practice with almost all professional websites this site uses cookies, which are tiny files
                that are downloaded to your computer, to improve your experience. This page describes what information
                they gather, how we use it and why we sometimes need to store these cookies. We will also share how you
                can prevent these cookies from being stored however this may downgrade or 'break' certain elements of
                the sites functionality.
              </p>
              <p>For more general information on cookies see the Wikipedia article on HTTP Cookies.</p>
              <p className="text-underline mb-0">
                <strong>How We Use Cookies</strong>
              </p>
              <p>
                We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no
                industry standard options for disabling cookies without completely disabling the functionality and
                features they add to this site. It is recommended that you leave on all cookies if you are not sure
                whether you need them or not in case they are used to provide a service that you use.
              </p>
              <p className="text-underline mb-0">
                <strong>Disabling Cookies</strong>
              </p>
              <p>
                You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help
                for how to do this). Be aware that disabling cookies will affect the functionality of this and many
                other websites that you visit. Disabling cookies will usually result in also disabling certain
                functionality and features of the this site. Therefore it is recommended that you do not disable
                cookies. This Cookies Policy was created with the help of the{' '}
                <a href="https://www.cookiepolicygenerator.com/cookie-policy-generator/">Cookies Policy Generator.</a>
              </p>
              <p className="text-underline mb-0">
                <strong>The Cookies We Set</strong>
              </p>
              <ul>
                <li className="mb-3">
                  Account related cookies
                  <br></br> <br></br>
                  If you create an account with us then we will use cookies for the management of the signup process and
                  general administration. These cookies will usually be deleted when you log out however in some cases
                  they may remain afterwards to remember your site preferences when logged out.
                </li>
                <li className="mb-3">
                  Login related cookies
                  <br></br> <br></br>
                  We use cookies when you are logged in so that we can remember this fact. This prevents you from having
                  to log in every single time you visit a new page. These cookies are typically removed or cleared when
                  you log out to ensure that you can only access restricted features and areas when logged in.
                </li>
                <li className="mb-3">
                  Forms related cookies
                  <br></br> <br></br>
                  When you submit data to through a form such as those found on contact pages or comment forms cookies
                  may be set to remember your user details for future correspondence.
                </li>
                <li>
                  Site preferences cookies
                  <br></br> <br></br>
                  In order to provide you with a great experience on this site we provide the functionality to set your
                  preferences for how this site runs when you use it. In order to remember your preferences we need to
                  set cookies so that this information can be called whenever you interact with a page is affected by
                  your preferences.
                </li>
              </ul>
              <p></p>
              <p></p>
              <p className="text-underline mb-0">
                <strong>Third Party Cookies</strong>
              </p>
              <p>
                In some special cases we also use cookies provided by trusted third parties. The following section
                details which third party cookies you might encounter through this site.
              </p>
              <ul>
                <li>
                  This site uses Google Analytics which is one of the most widespread and trusted analytics solution on
                  the web for helping us to understand how you use the site and ways that we can improve your
                  experience. These cookies may track things such as how long you spend on the site and the pages that
                  you visit so we can continue to produce engaging content. For more information on Google Analytics
                  cookies, see the official Google Analytics page.
                </li>
                <li>
                  Third party analytics are used to track and measure usage of this site so that we can continue to
                  produce engaging content. These cookies may track things such as how long you spend on the site or
                  pages you visit which helps us to understand how we can improve the site for you.
                </li>
                <li>
                  From time to time we test new features and make subtle changes to the way that the site is delivered.
                  When we are still testing new features these cookies may be used to ensure that you receive a
                  consistent experience whilst on the site whilst ensuring we understand which optimisations our users
                  appreciate the most.
                </li>
                <li>
                  We also use social media buttons and/or plugins on this site that allow you to connect with your
                  social network in various ways. For these to work the following social media sites including; It will
                  set cookies through our site which may be used to enhance your profile on their site or contribute to
                  the data they hold for various purposes outlined in their respective privacy policies.
                </li>
              </ul>
              <p className="text-underline mb-0">
                <strong>More Information</strong>
              </p>
              <p>
                Hopefully that has clarified things for you and as was previously mentioned if there is something that
                you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it does
                interact with one of the features you use on our site.
              </p>
              <p>
                For more general information on cookies, please read{' '}
                <a href="https://www.cookiepolicygenerator.com/sample-cookies-policy/">the Cookies Policy article</a>
              </p>
              However if you are still looking for more information then you can contact us through one of our preferred
              contact methods:
              <ul>
                <li>Email: info@epa.ac.th</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
