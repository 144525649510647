import * as Type from 'constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case Type.GET_NEWS:
      return {
        ...state,
        inProgress: true,
        newsUpdated: false
      }
    case Type.GET_NEWS_SUCCESS:
      return {
        ...state,
        inProgress: false,
        announcements: action.payload.data,
        newsUpdated: true
      }
    case Type.GET_NEWS_FAILED:
      return {
        ...state,
        inProgress: false,
        newsUpdated: false
      }
    default:
      return state
  }
}
