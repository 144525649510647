import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Pluralize from 'react-pluralize'
import { Person as PersonIcon } from '@material-ui/icons'
import moment from 'moment'
import { LESSON_PAGE } from 'routes/tutorRoutes'
import JoinLessonButton from 'components/online-class/button-join-lesson'
import LessonResources from 'pages/app/my-lesson/tutor/tab-resource'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  upcoming: state.lessons.upcoming,
  token: state.common.token
})

class NextLessonCard extends React.Component {
  render() {
    const { upcoming } = this.props

    if (upcoming.length === 0) {
      return null
    }

    const nextLesson = upcoming[0]

    return (
      <div className="jr-card p-0">
        <div className="jr-card-header mb-0 px-4 py-3 bg-amber">
          <h3 className="card-heading text-white mb-0">Next Lesson</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div
              className="col"
              onClick={() => {
                this.props.history.push(
                  LESSON_PAGE.replace(':classId', nextLesson.class._id).replace(':lessonId', nextLesson._id) +
                    '?tf=resources'
                )
              }}>
              <h2 className="clickable mb-1">{nextLesson.name}</h2>
              <div className="text-faded">
                {moment(nextLesson.scheduled_on).format('dddd Do MMM YYYY, h:mm a')} -{' '}
                {moment(nextLesson.ended_on).format('h:mm a')}
              </div>
            </div>
            <div className="col-auto">
              {nextLesson.hosting_platform === 'Zoom' && (
                <JoinLessonButton lessonData={nextLesson} bypassCountdown={true} />
              )}
            </div>
          </div>
          <hr />
          <LessonResources lessonId={nextLesson._id} />
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, {})(NextLessonCard))
