import React from 'react'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Checkbox,
  Button
} from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete'
import _, { truncate } from 'lodash'
import { OthersField } from 'components/profile/others'
import { InputText } from 'components/profile/inputText'
import studyArea from 'assets/area-of-study.json'
import studyAreaSpecific from 'assets/area-of-study-specific.json'

import { Link } from 'react-router-dom'
import { USER_PROFILE } from 'routes/routes'

class PreferencePayment extends React.Component {
  render() {
    const {
      currentUser,
      handleChange,
      submitChanges,
      handleCheckboxChange,
      handleBlurInput,
      handleAutoCompleteOccupationChange,
      handleAutoCompleteOccupationSpecificChange,
      parentOccupation,
      parentOccupationSpecific,
      parentFamilyBackground,
      parentNetworkingFB,
      parentNetworkingWebsite,
      parentNetworkingFriends,
      parentNetworkingOthersBox,
      parentNetworkingOthersField,
      paymentMode,
      parentInterestedTopicsPublicSpeaking,
      parentInterestedTopicsDebate,
      parentInterestedTopicsCoding,
      parentInterestedTopicsCreativeWriting,
      parentInterestedTopicsFormalWriting,
      parentInterestedTopicsReadingComprehension,
      parentInterestedTopicsLiterature,
      parentInterestedTopicsOthersBox,
      parentInterestedTopicsOthersField
    } = this.props

    const networkingCircle = [
      { check: parentNetworkingFB, name: 'parentNetworkingFB', label: 'Facebook' },
      { check: parentNetworkingWebsite, name: 'parentNetworkingWebsite', label: 'Website' },
      { check: parentNetworkingFriends, name: 'parentNetworkingFriends', label: 'Friends' }
    ]

    const parentInterestedTopicsLifeSkills = [
      {
        check: parentInterestedTopicsPublicSpeaking,
        name: 'parentInterestedTopicsPublicSpeaking',
        label: 'Public Speaking'
      },
      { check: parentInterestedTopicsDebate, name: 'parentInterestedTopicsDebate', label: 'Debate' },
      { check: parentInterestedTopicsCoding, name: 'parentInterestedTopicsCoding', label: 'Coding' }
    ]

    const parentInterestedTopicsAcademicSkills = [
      {
        check: parentInterestedTopicsCreativeWriting,
        name: 'parentInterestedTopicsCreativeWriting',
        label: 'Creative Writing'
      },
      {
        check: parentInterestedTopicsFormalWriting,
        name: 'parentInterestedTopicsFormalWriting',
        label: 'Formal Writing'
      },
      {
        check: parentInterestedTopicsReadingComprehension,
        name: 'parentInterestedTopicsReadingComprehension',
        label: 'Reading Comprehension'
      },
      {
        check: parentInterestedTopicsLiterature,
        name: 'parentInterestedTopicsLiterature',
        label: 'Literature'
      }
    ]

    return (
      <Paper elevation={2} className="app-wrapper mb-3">
        <h1 id="personality-preference">Preference & Payment</h1>

        <div className="row">
          <div className="row col-12">
            <FormLabel legend="component" className="mt-4 ml-md-4">
              Occupation (Optional)
            </FormLabel>
            <div className="row col-12">
              <div className="col-6">
                <div className="col-12 col-md-12 mb-3 mt-1">
                  <Autocomplete
                    id="combo-box-area"
                    name="parentOccupation"
                    options={studyArea.map(options => options.name)}
                    value={parentOccupation}
                    onChange={(event, value) => {
                      if (value !== null) handleAutoCompleteOccupationChange(event, value)
                    }}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    renderInput={params => <TextField {...params} label="Choose an occupation" />}
                  />
                </div>
              </div>
              {parentOccupation && (
                <div className="col-6">
                  <div className="col-12 col-md-12 mb-3 mt-1">
                    <Autocomplete
                      id="combo-box--specific-area"
                      name="parentOccupationSpecific"
                      value={parentOccupationSpecific}
                      options={studyAreaSpecific
                        .filter(options => options.nameAS === parentOccupation)
                        .map(options => options.name)}
                      onChange={(event, value) => {
                        if (value !== null) handleAutoCompleteOccupationSpecificChange(event, value)
                      }}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      renderInput={params => <TextField {...params} label="Choose a specific occupation" />}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 ml-md-4">
              <div className="row">
                <InputText
                  name="parentFamilyBackground"
                  label="Family Background"
                  defaultValue={parentFamilyBackground}
                  onBlur={handleBlurInput.bind(this)}
                />
              </div>
            </div>
            <div className="row ml-md-2">
              <FormLabel className="ml-3 mt-4" component="legend">
                Networking Circle
              </FormLabel>
              <div className="col-md-12 col-6 mt-2">
                <div className="row">
                  {networkingCircle.map((type, index) => (
                    <Paper key={index} className="m-1">
                      <div className="col-auto">
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={type.check}
                                name={type.name}
                                color="primary"
                                onChange={handleCheckboxChange}
                              />
                            }
                            label={`${type.label}`}
                          />
                        </FormControl>
                      </div>

                      <div className="col-auto" style={{ marginBottom: '-30px' }}>
                        <TextField disabled={true} InputProps={{ disableUnderline: true }} />
                      </div>
                    </Paper>
                  ))}
                  <OthersField
                    check={parentNetworkingOthersBox}
                    nameBox={'parentNetworkingOthersBox'}
                    placeholderField={'Please specify other favorite subject'}
                    nameField={'parentNetworkingOthersField'}
                    valueField={parentNetworkingOthersField}
                    checkBoxChange={handleCheckboxChange}
                    onBlur={handleBlurInput.bind(this)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-md-4">
          <div className="col-sm-auto">
            <div className="row">
              <FormLabel className="ml-md-3 mt-3" component="legend">
                Interested Topics / Events / Courses
              </FormLabel>
              <div className="row col-md-12 mt-2">
                <div className="col-auto col-md-4">
                  <FormLabel className="ml-md-2" component="legend">
                    Life Skills
                  </FormLabel>
                  {parentInterestedTopicsLifeSkills.map((lifeSkills, index) => (
                    <Paper key={index} className="m-1">
                      <div className="col-auto">
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={lifeSkills.check}
                                name={lifeSkills.name}
                                color="primary"
                                onChange={handleCheckboxChange}
                              />
                            }
                            label={`${lifeSkills.label}`}
                          />
                        </FormControl>
                      </div>
                    </Paper>
                  ))}
                </div>

                <div className="col-auto col-md-4">
                  <FormLabel className="ml-md-2" component="legend">
                    Academic Skills
                  </FormLabel>
                  {parentInterestedTopicsAcademicSkills.map((academicSkills, index) => (
                    <Paper key={index} className="m-1">
                      <div className="col-auto">
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={academicSkills.check}
                                name={academicSkills.name}
                                color="primary"
                                onChange={handleCheckboxChange}
                              />
                            }
                            label={`${academicSkills.label}`}
                          />
                        </FormControl>
                      </div>
                    </Paper>
                  ))}
                </div>

                <div className="col-auto col-md-4">
                  <FormLabel className="ml-md-2" component="legend">
                    Other Interested Skills
                  </FormLabel>
                  <OthersField
                    check={parentInterestedTopicsOthersBox}
                    removeField={true}
                    nameBox={'parentInterestedTopicsOthersBox'}
                    placeholderField={'Please specify others'}
                    nameField={'parentInterestedTopicsOthersField'}
                    valueField={parentInterestedTopicsOthersField}
                    checkBoxChange={handleCheckboxChange}
                    onBlur={handleBlurInput.bind(this)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-auto">
            <div className="row">
              <div className="col-auto">
                <FormLabel className="mt-5 ml-md-2" component="legend">
                  Preferred mode of payment
                </FormLabel>

                <RadioGroup
                  aria-label="paymentMode"
                  name="paymentMode"
                  className="ml-md-2"
                  value={paymentMode}
                  onChange={handleChange}>
                  <FormControlLabel value="cash" control={<Radio color="primary" />} label="Cash" />
                  <FormControlLabel value="credit" control={<Radio color="primary" />} label="Credit Card" />
                  <FormControlLabel value="bank" control={<Radio color="primary" />} label="Bank Transfer" />
                </RadioGroup>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="d-flex flex-row flex-wrap justify-content-between align-items-center mt-3 mr-3">
          {currentUser._type === 'Parent' ? (
            <Link to={`/app`}>Go Back to Profile</Link>
          ) : (
            <Link to={USER_PROFILE}>Go Back to Profile</Link>
          )}
          <Button variant="contained" color="primary" className="ml-3" onClick={submitChanges}>
            Save Changes
          </Button>
        </div>
      </Paper>
    )
  }
}

export default PreferencePayment
