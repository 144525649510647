import React from 'react'
import { Elements, StripeProvider, injectStripe } from 'react-stripe-elements'
import { CheckCircle } from '@material-ui/icons'

import HitPayButton from './payment-hitpay'
import _StripeForm from './payment-stripe'

const StripeForm = injectStripe(_StripeForm)

class PaymentGatewaySelection extends React.Component {
  state = {
    selectedGateway: 'hitpay'
  }

  selectPaymentGateway = selectedGateway => () => {
    this.setState({ selectedGateway })
  }

  render() {
    const { price, creditToUse, discountCode } = this.props
    const { selectedGateway } = this.state

    return (
      <>
        <div className="d-flex align-items-center manage-margin">
          <div className="gateway-option mr-2">
            <img
              height="40"
              width="auto"
              onClick={this.selectPaymentGateway('hitpay')}
              src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/general/payment-paynow.png"
              alt="paynow"
            />
            {selectedGateway === 'hitpay' && <CheckCircle className="gateway-option-selected-icon" />}
          </div>
          <div className="gateway-option">
            <img
              height="40"
              width="auto"
              onClick={this.selectPaymentGateway('stripe')}
              src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/general/payment-credit-card.png"
              alt="credit card"
            />
            {selectedGateway === 'stripe' && <CheckCircle className="gateway-option-selected-icon" />}
          </div>
        </div>

        {selectedGateway === 'hitpay' && (
          <HitPayButton price={price} creditToUse={creditToUse} discountCode={discountCode} />
        )}

        {selectedGateway === 'stripe' && (
          <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
            <Elements>
              <StripeForm price={price} creditToUse={creditToUse} discountCode={discountCode} />
            </Elements>
          </StripeProvider>
        )}
      </>
    )
  }
}

export default PaymentGatewaySelection
