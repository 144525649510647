import React from 'react'
import { connect } from 'react-redux'
import Lightbox from 'react-images'
import Proptypes from 'prop-types'
import { Button, CircularProgress } from '@material-ui/core'
import _ from 'lodash'
import agent from 'agent'
import { GET_LESSON_RESOURCES_2 } from 'constants/actionTypes'
import { TA, TUTOR } from 'constants/userTypes'
import EditResourceDialog from 'components/online-class/dialog-edit-resource'
import DeleteResourceDialog from 'components/online-class/dialog-delete-resource'
import FilePreviewDialog from 'components/dialog/file-preview'
import moment from 'moment'
const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  loading: state.resources.loading,
  lessonResources: state.resources.lessonResources,
  lessonData: state.lessons.selectedLesson
})

const mapDispatchToProps = dispatch => ({
  getLessonResource: lessonId =>
    dispatch({ type: GET_LESSON_RESOURCES_2, payload: agent.Lessons.getMaterial(lessonId), lessonId })
})

class ResourceList extends React.Component {
  state = {
    resourceTypes: [],
    resourceList: [],
    selectedResource: null,
    isTutor: false,
    filePreviewDialogOpen: false,
    editResourceDialogOpen: false,
    deleteResourceDialogOpen: false,
    imgList: [],
    isLightBoxOpen: false,
    currentImageIndex: 0
  }

  componentDidMount() {
    const { currentUser, lessonId } = this.props
    this.props.getLessonResource(lessonId)

    if ([TUTOR, TA].includes(currentUser._type)) {
      this.setState({
        isTutor: true
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { lessonId } = this.props
    if (prevProps.loading && !this.props.loading) {
      const { lessonResources } = this.props
      const resourceList = lessonResources[lessonId] ?? []
      this.setState({
        resourceTypes: _.uniq(_.map(resourceList, 'type')),
        resourceList
      })

      // alex: if complain for optional to move down then i will change
      // let resourceTypes = ['notes', 'homework', 'optional', 'teaching']
      // this.setState({ resourceTypes, resourceList });
    }
  }

  previewFile = selectedResource => {
    this.setState({
      selectedResource,
      filePreviewDialogOpen: true
    })
  }

  closeFilePreviewDialog = () => {
    this.setState({ filePreviewDialogOpen: false })
  }

  toggleEditResourceDialog = selectedResource => () => {
    this.setState({
      selectedResource,
      editResourceDialogOpen: !this.state.editResourceDialogOpen
    })
  }

  toggleDeleteResourceDialog = selectedResource => () => {
    this.setState({
      selectedResource,
      deleteResourceDialogOpen: !this.state.deleteResourceDialogOpen
    })
  }

  openLightbox = images => () => {
    this.setState({
      isLightBoxOpen: true,
      imgList: images,
      currentImageIndex: 0
    })
  }

  closeLightbox = () => {
    this.setState({ isLightBoxOpen: false })
  }

  goToPrevious = () => {
    this.setState({ currentImageIndex: this.state.currentImageIndex - 1 })
  }

  goToNext = () => {
    this.setState({ currentImageIndex: this.state.currentImageIndex + 1 })
  }

  render() {
    const { loading, lessonData, currentUser } = this.props
    const {
      resourceTypes,
      resourceList,
      isTutor,
      selectedResource,
      filePreviewDialogOpen,
      editResourceDialogOpen,
      deleteResourceDialogOpen,
      imgList,
      isLightBoxOpen,
      currentImageIndex
    } = this.state

    if (loading) {
      return <CircularProgress size={30} className="my-5" />
    }

    return (
      <>
        {resourceTypes.map((type, index) => (
          <div key={index}>
            <div className="text-muted text-small text-capitalize mb-2">{type}</div>
            {resourceList.map((material, index) => {
              if (material.type !== type) {
                return null
              }

              let whiteboardConversionStatus = ''
              let whiteboardConversionStatusColor = ''
              let whiteboardImages = []
              if (material.type === 'teaching' && lessonData?.hosting_platform === 'Tenopy Classroom') {
                const whiteboardConversionTasks = lessonData.platform_integration?.whiteboard_conversion ?? []
                if (whiteboardConversionTasks.length > 0) {
                  const curMaterialTask = _.find(whiteboardConversionTasks, o => {
                    return o.material_id === material._id
                  })
                  if (curMaterialTask) {
                    whiteboardConversionStatus = 'Pending Conversion'
                    whiteboardConversionStatusColor = 'amber'
                    const conversionId = curMaterialTask.uuid
                    const whiteboardResources = lessonData.platform_integration?.whiteboard_resource ?? []
                    if (whiteboardResources.length > 0) {
                      const curMaterialConversion = _.find(whiteboardResources, o => {
                        return o.uuid === conversionId
                      })
                      if (curMaterialConversion) {
                        if (curMaterialConversion.status === 'Finished') {
                          whiteboardConversionStatus = 'Successful Conversion'
                          whiteboardConversionStatusColor = 'success'
                          for (let i = 0; i < curMaterialConversion.progress.convertedFileList.length; i++) {
                            const imgURL = curMaterialConversion.progress.convertedFileList[i].conversionFileUrl
                            whiteboardImages.push({
                              src: imgURL
                            })
                          }
                        } else if (curMaterialConversion.status === 'Fail') {
                          whiteboardConversionStatus = 'Conversion Failed'
                          whiteboardConversionStatusColor = 'danger'
                        }
                      }
                    }
                  }
                }
              }

              return (
                <div key={index} className="card p-3 mb-2">
                  <div className="row">
                    <div className="col-auto d-flex">
                      {isTutor && material.created_by === currentUser._id ? (
                        <>
                          <i
                            className="material-icons text-blue align-self-center clickable ml-2 mr-2"
                            onClick={this.toggleEditResourceDialog(material)}>
                            edit
                          </i>
                          <i
                            className="material-icons text-danger align-self-center clickable mr-4"
                            onClick={this.toggleDeleteResourceDialog(material)}>
                            delete
                          </i>
                        </>
                      ) : (
                        <i className="fa fa-file align-self-center mb-md-0 mb-3"> Student file </i>
                      )}
                    </div>
                    <div className="col d-flex align-items-center">
                      <span className="mb-md-0 mb-3">{material.name}</span>
                      {whiteboardConversionStatus && (
                        <div className={`tag ${whiteboardConversionStatusColor}-tag--outline ml-3`}>
                          {whiteboardConversionStatus}
                        </div>
                      )}
                    </div>
                    <div className="col-md-auto col-12">
                      {whiteboardConversionStatus === 'Successful Conversion' && (
                        <Button
                          variant="text"
                          color="primary"
                          className="ml-2"
                          onClick={this.openLightbox(whiteboardImages)}>
                          Preview Whiteboard Resources
                        </Button>
                      )}
                      {material.created_by === currentUser._id ? (
                        <span className="text-blue"> (You uploaded on {moment(material.created).format('LLLL')})</span>
                      ) : (
                        ` (Student uploaded on ${moment(material.created).format('LLLL')})`
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        className="ml-2"
                        onClick={() => this.previewFile(material)}>
                        View
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        className="ml-2"
                        href={material.material_meta.location}>
                        Download
                      </Button>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        ))}

        <EditResourceDialog
          isOpen={editResourceDialogOpen}
          close={this.toggleEditResourceDialog()}
          resourceData={selectedResource}
        />

        <DeleteResourceDialog
          isOpen={deleteResourceDialogOpen}
          close={this.toggleDeleteResourceDialog()}
          resourceData={selectedResource}
        />

        <FilePreviewDialog isOpen={filePreviewDialogOpen} close={this.closeFilePreviewDialog} file={selectedResource} />

        <Lightbox
          images={imgList}
          isOpen={isLightBoxOpen}
          onClickPrev={this.goToPrevious}
          onClickNext={this.goToNext}
          onClose={this.closeLightbox}
          currentImage={currentImageIndex}
        />
      </>
    )
  }
}

ResourceList.propTypes = {
  lessonId: Proptypes.string.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceList)
