import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'
import { checkWebpSupport } from 'utils/misc'
import { Helmet } from 'react-helmet'
import { isMobile } from 'react-device-detect'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

class Services extends React.Component {
  state = {
    webpSupport: true
  }
  componentDidMount() {
    let component = this
    checkWebpSupport('lossy', function (feature, result) {
      component.setState({ webpSupport: result })
    })
  }

  render() {
    const { webpSupport } = this.state
    return (
      <div className="landing">
        <div>
          <Helmet>
            <title>Contact Us | EPA</title>
            <meta
              name="description"
              content="Enabling students to unleash their potential while embracing the future with confidence and wisdom."
            />
            <meta
              name="keywords"
              content="Attaining Excellence, Unleashing your potential,Bringing out the best in you, Your dream, our mission 
            Nurturing uniqueness, Igniting your passion"
            />
          </Helmet>
        </div>

        <div className="align-items-center" style={{ marginTop: '13vh', marginBottom: '5.628vh' }}>
          <div className="text-center">
            <div className="section__header_landing_title">
              <div
                className="title mt-5 text-center"
                style={{
                  fontFamily: "'Libre Baskerville', sans-serif"
                }}>
                Contact us.
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-12 text-center">
              <div className="col-lg-11 phone-icon" style={{ marginLeft: isMobile ? '' : '20px' }}>
                <img
                  src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/phone.svg"
                  alt="phone"
                  width="10%"
                  // className="feature-icon mb-2"
                  className="mb-4"
                />
                <div className="section__header_landing_title">
                  <div className="paragraph mb-4 text-center">
                    Ready to get started? <br />
                    Give us a call.
                  </div>
                </div>

                <div className="section__header_landing_title">
                  <div className="header--bold mt-3 mb-2 text-center numeric-normal">02-611-0348</div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-12 mt-5 mt-lg-0 text-center">
              <div className="col-lg-11 socmed-icon">
                <img
                  src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/contact/chat.png"
                  alt="chat"
                  width="10%"
                  // className="feature-icon mb-2"
                  className="mb-4"
                />
                <div className="section__header_landing_title">
                  <div className="paragraph mb-4 text-center">
                    Reach out to us through <br />
                    Line or Facebook.
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="d-flex flex-wrap manage-margin justify-content-center">
                      <a href="https://www.facebook.com/EPAschool" target="_blank" rel="noopener noreferrer">
                        <img
                          src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/line_icon_orange.svg"
                          width="46"
                          height="46"
                          alt="line"
                        />
                      </a>
                      <a href="https://www.facebook.com/EPAschool" target="_blank" rel="noopener noreferrer">
                        <img
                          src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/facebook_icon_orange.svg"
                          width="48"
                          height="47"
                          alt="facebook"
                          className="ml-5"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div
              className="col-lg-4 col-12 text-center"
              style={{
                paddingTop: isMobile ? '3em' : '3em',
                paddingBottom: isMobile ? '0em' : '6em'
              }}>
              <div className="col-lg-12">
                <div className="section__header_landing_title">
                  <div className="paragraph--bold mb-4 text-center">We're located</div>
                  <p className="description">Next to BTS Ratchathewi Station</p>
                  <p className="description">
                    294/1 Asia Building, 11B Floor 11, Phayathai Road, <br />
                    Petchaburi, Ratchathewi, Bangkok 10400
                  </p>
                  <a href="" target="_blank">
                    <p className="description--bold" style={{ color: '#FD6502' }}>
                      View on Google Maps
                    </p>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-12 text-center"
              style={{
                paddingTop: isMobile ? '0em' : '3em',
                marginTop: isMobile ? '3em' : '0em',
                paddingBottom: '6em'
              }}>
              {' '}
              <div className="col-lg-11">
                <div className="section__header_landing_title">
                  <div className="paragraph--bold mb-4 text-center">Our social media</div>

                  <div className="row">
                    <div className="col-12 mb-4">
                      <div className="d-flex flex-wrap manage-margin justify-content-center">
                        <a href="https://www.facebook.com/EPAschool" target="_blank" rel="noopener noreferrer">
                          <img
                            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/instagram_icon_orange.svg"
                            width="48"
                            height="48"
                            alt="instagram"
                          />
                        </a>
                        <a href="https://www.facebook.com/EPAschool" target="_blank" rel="noopener noreferrer">
                          <img
                            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/youtube_icon_orange.svg"
                            width="48"
                            height="48"
                            alt="youtube"
                            className="ml-5"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="description mt-2">
                    Have a question? <br /> Email us at{' '}
                    <a href="mailto:info@epa.ac.th" style={{ color: '#FD6502', fontWeight: 700 }}>
                      info@epa.ac.th
                    </a>
                  </div>
                </div>{' '}
              </div>
            </div>
          </div>
        </div>

        <div className="divider-section"></div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Services))
