/*User type*/
export const STUDENT = 'Student'
export const TUTOR = 'Tutor'
export const TA = 'TA'
export const PARENT = 'Parent'

/*REGISTRATION MODEL*/
export const STUDENT_MODEL = 'student'
export const TUTOR_MODEL = 'tutor'

let userNavigationAccess = {}
userNavigationAccess[STUDENT] = ['dashboard', 'online-class', 'my-class', 'transaction', 'profile', 'cart']
userNavigationAccess[TUTOR] = ['my-class', 'profile', 'all-classes']
userNavigationAccess[TA] = ['my-class', 'profile']
userNavigationAccess[PARENT] = ['dashboard', 'profile']
export const USER_NAVIGATION_ACCESS = userNavigationAccess
