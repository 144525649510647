import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const mapStateToProps = state => ({
  announcements: state.newsandevents.announcements
})

class NewsAndEventsCard extends React.Component {
  render() {
    const { announcements, articleSlot } = this.props

    return (
      <div className="col-12 col-md-4 col-lg-4 text-left p-4">
        <div className="card p-4" style={{ borderColor: 'white', backgroundColor: 'white', borderRadius: '8px' }}>
          {announcements && announcements[articleSlot].detail.youtube_link !== '' ? (
            <div className="news-border">
              <iframe
                id="player"
                width="100%"
                height="270px"
                src={`https://www.youtube.com/embed/${announcements && announcements[articleSlot].detail.youtube_link}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </div>
          ) : (
            ''
          )}
          {announcements && announcements[articleSlot].detail.image !== '' ? (
            <div className="news-border">
              <img
                src={announcements && announcements[articleSlot].detail.image}
                alt={announcements && announcements[articleSlot].detail.title}
                width={'100%'}
              />
            </div>
          ) : (
            ''
          )}
          <div className="section__header_landing_title my-3">
            <div className="paragraph--bold fixed-height-title">
              {announcements && announcements[articleSlot].detail.title}
            </div>
          </div>

          <div className="info-description-black text-left fixed-height-summary">
            {announcements && announcements[articleSlot].detail.summary.slice(0, 250)}
            {announcements && announcements[articleSlot].detail.summary === '' ? '' : '...'}
          </div>

          {announcements && announcements[articleSlot].detail.url !== '' ? (
            <div className="info-description-black--bold text-left mt-2">
              <a
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                href={announcements && announcements[articleSlot].detail.url}
                target="_blank"
                style={{
                  color: '#FD6502',
                  textDecorationColor: '#FD6502',
                  textDecorationThickness: '2px'
                }}
                rel="noreferrer">
                Read More
              </a>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, {})(NewsAndEventsCard))
