import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Paper, Button, TextField } from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'

import agent from 'agent'
import { UPDATE_USER, UPDATE_PARENT } from 'constants/actionTypes'
import { validateTextEntry, validatePassword } from 'utils/formValidation'
import ParentStepperProfile from 'components/nav/app/profile/parent-profile-progress'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  updateUserInProgress: state.common.updateUserInProgress
})

const mapDispatchToProps = dispatch => ({
  updateUser: user => dispatch({ type: UPDATE_USER, payload: agent.Auth.updateUser(user), user }),
  updateStudentMeta: (parent, userObj) =>
    dispatch({ type: UPDATE_PARENT, payload: agent.Auth.updateStudentMeta(parent, userObj) })
})

class ParentEdit extends React.Component {
  state = {
    email: '',
    name: '',
    dateOfBirth: '',
    gender: '',
    phoneCountryCode: 65,
    phone: '',
    level: '',
    othersLevel: '',
    showOthersLevel: false,
    school: '',
    othersSchool: '',
    showOthersSchool: false,
    parentSalute: '',
    parentName: '',
    parentEmail: '',
    parentPhoneCountryCode: 65,
    parentPhone: '',
    useStudentPhone: false,
    useStudentEmail: false,
    contactPreference: 'nil',
    contactPreferenceOther: '',
    facebook: '',
    line: '',
    formError: {},
    isSaved: false,
    parentOccupation: '',
    parentOccupationSpecific: '',
    parentFamilyBackground: '',
    parentNetworkingFB: false,
    parentNetworkingWebsite: false,
    parentNetworkingFriends: false,
    parentNetworkingOthersBox: false,
    parentNetworkingOthersField: '',
    paymentMode: 'nil',
    parentInterestedTopicsPublicSpeaking: false,
    parentInterestedTopicsDebate: false,
    parentInterestedTopicsCoding: false,
    parentInterestedTopicsLeadership: false,
    parentInterestedTopicsWriting: false,
    parentInterestedTopicsCreativeWriting: false,
    parentInterestedTopicsFormalWriting: false,
    parentInterestedTopicsReadingComprehension: false,
    parentInterestedTopicsLiterature: false,
    parentInterestedTopicsOthersBox: false,
    parentInterestedTopicsOthersField: '',

    //needed for meta to be preserved
    pcDominanceDirect: false,
    pcDominanceResultsOriented: false,
    pcDominanceFirm: false,
    pcDominanceStrongWilled: false,
    pcDominanceForceful: false,
    pcInfluenceOutgoing: false,
    pcInfluenceEnthusiastic: false,
    pcInfluenceOptimistic: false,
    pcInfluenceHighSpirited: false,
    pcInfluenceLively: false,
    pcConscientiousnessAnalytical: false,
    pcConscientiousnessReserved: false,
    pcConscientiousnessPrecise: false,
    pcConscientiousnessPrivate: false,
    pcConscientiousnessSystematic: false,
    pcSteadinessEvenTempered: false,
    pcSteadinessAccommodating: false,
    pcSteadinessPatient: false,
    pcSteadinessHumble: false,
    pcSteadinessTactful: false,
    parentConflict: false,
    parentDecisionFather: false,
    parentDecisionMother: false,
    parentDateOfRenewal: ''
  }

  componentDidMount() {
    const { currentUser } = this.props
    const { parent } = currentUser

    let parentPhoneCountryCode = 65
    if (parent?.phone_country_code) {
      parentPhoneCountryCode = parent.phone_country_code
    } else {
      parentPhoneCountryCode = currentUser.phone_country_code
    }

    console.log('🚀 alex: ~ file: parent-edit.js ~ line 116 ~ componentDidMount ~ currentUser', currentUser)

    this.setState({
      email: currentUser.email ?? '',
      name: currentUser.name ?? '',
      dateOfBirth: currentUser.dob ? moment(currentUser.dob).startOf('day').format('YYYY-MM-DD') : '',
      gender: currentUser.gender ?? '',
      phoneCountryCode: currentUser.phone_country_code,
      phone: currentUser.phone ?? '',
      contactPreference: currentUser.contact_preference?.contact ?? 'nil',
      contactPreferenceOther: currentUser.contact_preference?.others_type ?? '',
      facebook: currentUser.facebook ?? '',
      line: currentUser.line ?? '',
      parentOccupation: currentUser?.meta?.occupation?.area ?? '',
      parentOccupationSpecific: currentUser?.meta?.occupation?.specific ?? '',
      parentFamilyBackground: currentUser?.meta?.family_background ?? '',
      parentNetworkingFB: currentUser?.meta?.networking_circle?.fb ?? false,
      parentNetworkingWebsite: currentUser?.meta?.networking_circle?.website ?? false,
      parentNetworkingFriends: currentUser?.meta?.networking_circle?.friends ?? false,
      parentNetworkingOthersBox: currentUser?.meta?.networking_circle?.others ?? false,
      parentNetworkingOthersField: currentUser?.meta?.networking_circle?.others_type ?? '',

      paymentMode: currentUser?.meta?.payment_mode ?? 'nil',
      parentInterestedTopicsPublicSpeaking: currentUser?.meta?.interested_topics?.public_speaking ?? false,
      parentInterestedTopicsDebate: currentUser?.meta?.interested_topics?.debate ?? false,
      parentInterestedTopicsCoding: currentUser?.meta?.interested_topics?.coding ?? false,
      parentInterestedTopicsLeadership: currentUser?.meta?.interested_topics?.leadership ?? false,
      parentInterestedTopicsWriting: currentUser?.meta?.interested_topics?.writing ?? false,
      parentInterestedTopicsCreativeWriting: currentUser?.meta?.interested_topics?.creative_writing ?? false,
      parentInterestedTopicsFormalWriting: currentUser?.meta?.interested_topics?.formal_writing ?? false,
      parentInterestedTopicsReadingComprehension: currentUser?.meta?.interested_topics?.reading_comprehension ?? false,
      parentInterestedTopicsLiterature: currentUser?.meta?.interested_topics?.literature ?? false,
      parentInterestedTopicsOthersBox: currentUser?.meta?.interested_topics?.others ?? false,
      parentInterestedTopicsOthersField: currentUser?.meta?.interested_topics?.others_type ?? '',

      pcDominanceDirect: currentUser?.meta?.parent_character?.dominance?.direct ?? false,
      pcDominanceResultsOriented: currentUser?.meta?.parent_character?.dominance?.result_oriented ?? false,
      pcDominanceFirm: currentUser?.meta?.parent_character?.dominance?.firm ?? false,
      pcDominanceStrongWilled: currentUser?.meta?.parent_character?.dominance?.strong_willed ?? false,
      pcDominanceForceful: currentUser?.meta?.parent_character?.dominance?.forceful ?? false,
      pcInfluenceOutgoing: currentUser?.meta?.parent_character?.influence?.outgoing ?? false,
      pcInfluenceEnthusiastic: currentUser?.meta?.parent_character?.influence?.enthusiastic ?? false,
      pcInfluenceOptimistic: currentUser?.meta?.parent_character?.influence?.optimistic ?? false,
      pcInfluenceHighSpirited: currentUser?.meta?.parent_character?.influence?.high_spirited ?? false,
      pcInfluenceLively: currentUser?.meta?.parent_character?.influence?.lively ?? false,
      pcConscientiousnessAnalytical: currentUser?.meta?.parent_character?.conscientiousness?.analytical ?? false,
      pcConscientiousnessReserved: currentUser?.meta?.parent_character?.conscientiousness?.reserved ?? false,
      pcConscientiousnessPrecise: currentUser?.meta?.parent_character?.conscientiousness?.precise ?? false,
      pcConscientiousnessPrivate: currentUser?.meta?.parent_character?.conscientiousness?.private ?? false,
      pcConscientiousnessSystematic: currentUser?.meta?.parent_character?.conscientiousness?.systematic ?? false,
      pcSteadinessEvenTempered: currentUser?.meta?.parent_character?.steadiness?.even_tempered ?? false,
      pcSteadinessAccommodating: currentUser?.meta?.parent_character?.steadiness?.accommodating ?? false,
      pcSteadinessPatient: currentUser?.meta?.parent_character?.steadiness?.patient ?? false,
      pcSteadinessHumble: currentUser?.meta?.parent_character?.steadiness?.humble ?? false,
      pcSteadinessTactful: currentUser?.meta?.parent_character?.steadiness?.tactful ?? false,
      parentConflict: currentUser?.meta?.conflict ?? '',
      parentDecisionFather: currentUser?.meta?.decision_maker?.father ?? false,
      parentDecisionMother: currentUser?.meta?.decision_maker?.mother ?? false,
      parentDateOfRenewal: currentUser?.meta?.date_of_renewal
        ? moment(parent?.meta?.date_of_renewal).startOf('day').format('YYYY-MM-DD')
        : ''
    })
  }

  validateFormElement = (type, customMsg) => {
    let errorMsg

    if (type === 'password') {
      errorMsg = validatePassword(this.state.password)
    } else {
      if (customMsg) {
        errorMsg = validateTextEntry(this.state[type], type, customMsg)
      } else {
        errorMsg = validateTextEntry(this.state[type], type)
      }
    }

    let tempFormError = this.state.formError
    if (errorMsg) {
      tempFormError[type] = errorMsg
    } else {
      delete tempFormError[type]
    }
    this.setState({ formError: tempFormError })
  }

  onFocusFormElement = e => {
    let type = e.target.name
    let tempFormError = this.state.formError
    delete tempFormError[type]
    this.setState({ formError: tempFormError })
  }

  handleChange = e => {
    let type = e.target.name
    let value = e.target.value
    this.setState({ [type]: value })

    if (type === 'level') {
      let tempFormError = this.state.formError
      delete tempFormError[type]
      this.setState({ formError: tempFormError })
      if (value === 'others') {
        this.setState({ showOthersLevel: true })
      } else {
        this.setState({ showOthersLevel: false })
      }
    }

    if (type === 'typeOfSchool') {
      if (value === 'others') {
        this.setState({ showOthersTypeOfSchool: true })
      } else {
        this.setState({ showOthersTypeOfSchool: false })
      }
    }
  }

  handleClose = (event, reason) => {
    const { isSaved } = this.state
    if (reason === 'clickaway') {
      return
    }

    this.setState({ isSaved: !isSaved })
  }

  handleBlurInput = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleAutoCompleteChange = (e, value) => {
    this.setState({ school: value.name })

    if (value.code === 'others') {
      this.setState({ showOthersSchool: true })
    } else {
      this.setState({ showOthersSchool: false })
    }
  }

  handleAutoCompleteOccupationChange = (e, value) => {
    this.setState({ parentOccupation: value })
  }

  handleAutoCompleteOccupationSpecificChange = (e, value) => {
    this.setState({ parentOccupationSpecific: value })
  }

  handleCheckboxChange = (e, checked) => {
    this.setState({ [e.target.name]: checked })
  }

  submitChanges = () => {
    const { currentUser } = this.props
    this.validateFormElement('name')
    this.validateFormElement('phone')
    const {
      name,
      email,
      dateOfBirth,
      gender,
      phoneCountryCode,
      phone,
      contactPreference,
      contactPreferenceOther,
      facebook,
      line,
      isSaved,
      parentOccupation,
      parentOccupationSpecific,
      parentFamilyBackground,
      parentNetworkingFB,
      parentNetworkingWebsite,
      parentNetworkingFriends,
      parentNetworkingOthersBox,
      parentNetworkingOthersField,

      paymentMode,
      parentInterestedTopicsPublicSpeaking,
      parentInterestedTopicsDebate,
      parentInterestedTopicsCoding,
      parentInterestedTopicsLeadership,
      parentInterestedTopicsWriting,
      parentInterestedTopicsCreativeWriting,
      parentInterestedTopicsFormalWriting,
      parentInterestedTopicsReadingComprehension,
      parentInterestedTopicsLiterature,
      parentInterestedTopicsOthersBox,
      parentInterestedTopicsOthersField,

      //needed to preserve meta
      pcDominanceDirect,
      pcDominanceResultsOriented,
      pcDominanceFirm,
      pcDominanceStrongWilled,
      pcDominanceForceful,
      pcInfluenceOutgoing,
      pcInfluenceEnthusiastic,
      pcInfluenceOptimistic,
      pcInfluenceHighSpirited,
      pcInfluenceLively,
      pcConscientiousnessAnalytical,
      pcConscientiousnessReserved,
      pcConscientiousnessPrecise,
      pcConscientiousnessPrivate,
      pcConscientiousnessSystematic,
      pcSteadinessEvenTempered,
      pcSteadinessAccommodating,
      pcSteadinessPatient,
      pcSteadinessHumble,
      pcSteadinessTactful,
      parentConflict,
      parentDecisionFather,
      parentDecisionMother,
      parentDateOfRenewal
    } = this.state

    this.setState({ isSaved: !isSaved })

    let details = {
      name,
      email,
      phone,
      gender,
      dob: moment(dateOfBirth).startOf('day').toISOString(),
      phone_country_code: phoneCountryCode,
      contact_preference: {
        contact: contactPreference,
        others_type: contactPreferenceOther
      },
      facebook: facebook,
      line: line,
      meta: {
        occupation: {
          area: parentOccupation,
          specific: parentOccupationSpecific
        },
        family_background: parentFamilyBackground,
        networking_circle: {
          fb: parentNetworkingFB,
          website: parentNetworkingWebsite,
          friends: parentNetworkingFriends,
          others: parentNetworkingOthersBox,
          others_type: parentNetworkingOthersField
        },
        interested_topics: {
          public_speaking: parentInterestedTopicsPublicSpeaking,
          debate: parentInterestedTopicsDebate,
          coding: parentInterestedTopicsCoding,
          writing: parentInterestedTopicsWriting,
          leadership: parentInterestedTopicsLeadership,
          creative_writing: parentInterestedTopicsCreativeWriting,
          formal_writing: parentInterestedTopicsFormalWriting,
          reading_comprehension: parentInterestedTopicsReadingComprehension,
          literature: parentInterestedTopicsLiterature,
          others: parentInterestedTopicsOthersBox,
          others_type: parentInterestedTopicsOthersField
        },
        conflict: parentConflict,
        decision_maker: {
          father: parentDecisionFather,
          mother: parentDecisionMother
        },
        parent_character: {
          dominance: {
            direct: pcDominanceDirect,
            result_oriented: pcDominanceResultsOriented,
            firm: pcDominanceFirm,
            strong_willed: pcDominanceStrongWilled,
            forceful: pcDominanceForceful
          },
          influence: {
            outgoing: pcInfluenceOutgoing,
            enthusiastic: pcInfluenceEnthusiastic,
            optimistic: pcInfluenceOptimistic,
            high_spirited: pcInfluenceHighSpirited,
            lively: pcInfluenceLively
          },
          conscientiousness: {
            analytical: pcConscientiousnessAnalytical,
            reserved: pcConscientiousnessReserved,
            precise: pcConscientiousnessPrecise,
            private: pcConscientiousnessPrivate,
            systematic: pcConscientiousnessSystematic
          },
          steadiness: {
            even_tempered: pcSteadinessEvenTempered,
            accommodating: pcSteadinessAccommodating,
            patient: pcSteadinessPatient,
            humble: pcSteadinessHumble,
            tactful: pcSteadinessTactful
          }
        },
        payment_mode: paymentMode,
        date_of_renewal: parentDateOfRenewal
      }
    }
    this.props.updateUser(details)
    this.props.updateStudentMeta(currentUser?.child, details)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.phoneCountryCode !== this.state.phoneCountryCode || prevState.phone !== this.state.phone) {
      if (this.state.useStudentPhone) {
        this.setState({
          parentPhoneCountryCode: this.state.phoneCountryCode,
          parentPhone: this.state.phone
        })
      }
    }
    // if (prevProps.updateUserInProgress && !this.props.updateUserInProgress) {
    //   this.props.history.push(USER_PROFILE)
    // }
  }

  render() {
    const { updateUserInProgress, currentUser } = this.props
    const {
      email,
      name,
      dateOfBirth,
      gender,
      phoneCountryCode,
      phone,
      formError,

      useStudentPhone,
      contactPreference,
      contactPreferenceOther,
      facebook,
      line,
      isSaved,

      parentOccupation,
      parentOccupationSpecific,
      parentFamilyBackground,
      parentNetworkingFB,
      parentNetworkingWebsite,
      parentNetworkingFriends,
      parentNetworkingOthersBox,
      parentNetworkingOthersField,

      paymentMode,
      parentInterestedTopicsPublicSpeaking,
      parentInterestedTopicsDebate,
      parentInterestedTopicsCoding,
      parentInterestedTopicsLeadership,
      parentInterestedTopicsWriting,
      parentInterestedTopicsCreativeWriting,
      parentInterestedTopicsFormalWriting,
      parentInterestedTopicsReadingComprehension,
      parentInterestedTopicsLiterature,
      parentInterestedTopicsOthersBox,
      parentInterestedTopicsOthersField
    } = this.state

    return (
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <Paper elevation={2} className="app-wrapper">
          <h2 className="mb-0">Update Details</h2>
        </Paper>
        <div className="app-wrapper">
          <div className="container">
            <ParentStepperProfile
              handleChange={this.handleChange}
              validateFormElement={this.validateFormElement}
              onFocusFormElement={this.onFocusFormElement}
              submitChanges={this.submitChanges}
              email={email}
              name={name}
              dateOfBirth={dateOfBirth}
              gender={gender}
              phoneCountryCode={phoneCountryCode}
              phone={phone}
              formError={formError}
              useStudentPhone={useStudentPhone}
              contactPreference={contactPreference}
              contactPreferenceOther={contactPreferenceOther}
              facebook={facebook}
              line={line}
              isSaved={isSaved}
              handleClose={this.handleClose}
              currentUser={currentUser}
              handleCheckboxChange={this.handleCheckboxChange}
              handleAutoCompleteChange={this.handleAutoCompleteChange}
              handleBlurInput={this.handleBlurInput}
              handleAutoCompleteOccupationChange={this.handleAutoCompleteOccupationChange}
              handleAutoCompleteOccupationSpecificChange={this.handleAutoCompleteOccupationSpecificChange}
              parentOccupation={parentOccupation}
              parentOccupationSpecific={parentOccupationSpecific}
              parentFamilyBackground={parentFamilyBackground}
              parentNetworkingFB={parentNetworkingFB}
              parentNetworkingWebsite={parentNetworkingWebsite}
              parentNetworkingFriends={parentNetworkingFriends}
              parentNetworkingOthersBox={parentNetworkingOthersBox}
              parentNetworkingOthersField={parentNetworkingOthersField}
              paymentMode={paymentMode}
              parentInterestedTopicsPublicSpeaking={parentInterestedTopicsPublicSpeaking}
              parentInterestedTopicsDebate={parentInterestedTopicsDebate}
              parentInterestedTopicsCoding={parentInterestedTopicsCoding}
              parentInterestedTopicsLeadership={parentInterestedTopicsLeadership}
              parentInterestedTopicsWriting={parentInterestedTopicsWriting}
              parentInterestedTopicsCreativeWriting={parentInterestedTopicsCreativeWriting}
              parentInterestedTopicsFormalWriting={parentInterestedTopicsFormalWriting}
              parentInterestedTopicsReadingComprehension={parentInterestedTopicsReadingComprehension}
              parentInterestedTopicsLiterature={parentInterestedTopicsLiterature}
              parentInterestedTopicsOthersBox={parentInterestedTopicsOthersBox}
              parentInterestedTopicsOthersField={parentInterestedTopicsOthersField}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ParentEdit))
