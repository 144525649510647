import React from 'react'

import { FormControl, FormControlLabel, FormLabel, Paper, TextField, Checkbox, Button } from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete'
import _, { truncate } from 'lodash'
import studyArea from 'assets/area-of-study.json'
import studyAreaSpecific from 'assets/area-of-study-specific.json'
import { withStyles } from '@material-ui/styles'

import { Link } from 'react-router-dom'
import { USER_PROFILE } from 'routes/routes'

import { OthersField } from 'components/profile/others'
import { InputText } from 'components/profile/inputText'

const styles = () => ({
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'blue !important'
  }
})

class AcademicCareerGoal extends React.Component {
  constructor(props) {
    super()

    this.state = {
      showareaOfStudy1: false, //true
      showareaOfStudy2: false,
      showareaOfStudy3: false,
      showareaOfStudy4: false,
      showareaOfStudyParent1: false,
      showareaOfStudyParent2: false,
      showareaOfStudyParent3: false,
      showareaOfStudyParent4: false
    }

    this.addAreaOfStudy1 = () => {
      this.setState({ showareaOfStudy1: true })
    }

    this.addAreaOfStudy2 = () => {
      this.setState({ showareaOfStudy2: true })
    }

    this.addAreaOfStudy3 = () => {
      this.setState({ showareaOfStudy3: true })
    }

    this.addAreaOfStudy4 = () => {
      this.setState({ showareaOfStudy4: true })
    }

    this.addAreaOfStudyParent1 = () => {
      this.setState({ showareaOfStudyParent1: true })
    }

    this.addAreaOfStudyParent2 = () => {
      this.setState({ showareaOfStudyParent2: true })
    }

    this.addAreaOfStudyParent3 = () => {
      this.setState({ showareaOfStudyParent3: true })
    }

    this.addAreaOfStudyParent4 = () => {
      this.setState({ showareaOfStudyParent4: true })
    }
  }

  componentDidMount() {
    if (this.props.areaOfStudySpecific1 !== '') {
      this.setState({ showareaOfStudy1: true })
    }
    if (this.props.areaOfStudySpecific2 !== '') {
      this.setState({ showareaOfStudy2: true })
    }
    if (this.props.areaOfStudySpecific3 !== '') {
      this.setState({ showareaOfStudy3: true })
    }
    if (this.props.areaOfStudySpecific4 !== '') {
      this.setState({ showareaOfStudy4: true })
    }

    if (this.props.areaOfStudyParentSpecific1 !== '') {
      this.setState({ showareaOfStudyParent1: true })
    }
    if (this.props.areaOfStudyParentSpecific2 !== '') {
      this.setState({ showareaOfStudyParent2: true })
    }
    if (this.props.areaOfStudyParentSpecific3 !== '') {
      this.setState({ showareaOfStudyParent3: true })
    }
    if (this.props.areaOfStudyParentSpecific4 !== '') {
      this.setState({ showareaOfStudyParent4: true })
    }
  }

  render() {
    const {
      showareaOfStudy1,
      showareaOfStudy2,
      showareaOfStudy3,
      showareaOfStudy4,
      showareaOfStudyParent1,
      showareaOfStudyParent2,
      showareaOfStudyParent3,
      showareaOfStudyParent4
    } = this.state
    const {
      classes,
      currentUser,
      handleCheckboxChange,
      handleBlurInput,
      handleAutoCompleteStudyAreaChange,
      handleAutoCompleteStudyAreaSpecificChange,
      handleAutoCompleteStudyAreaChange1,
      handleAutoCompleteStudyAreaSpecificChange1,
      handleAutoCompleteStudyAreaChange2,
      handleAutoCompleteStudyAreaSpecificChange2,
      handleAutoCompleteStudyAreaChange3,
      handleAutoCompleteStudyAreaSpecificChange3,
      handleAutoCompleteStudyAreaChange4,
      handleAutoCompleteStudyAreaSpecificChange4,
      handleAutoCompleteParentStudyAreaChange,
      handleAutoCompleteParentStudyAreaSpecificChange,
      handleAutoCompleteParentStudyAreaChange1,
      handleAutoCompleteParentStudyAreaSpecificChange1,
      handleAutoCompleteParentStudyAreaChange2,
      handleAutoCompleteParentStudyAreaSpecificChange2,
      handleAutoCompleteParentStudyAreaChange3,
      handleAutoCompleteParentStudyAreaSpecificChange3,
      handleAutoCompleteParentStudyAreaChange4,
      handleAutoCompleteParentStudyAreaSpecificChange4,
      careerGoalsStudentPerspective,
      careerGoalsParentPerspective,
      areaOfStudy,
      areaOfStudySpecific,
      areaOfStudy1,
      areaOfStudySpecific1,
      areaOfStudy2,
      areaOfStudySpecific2,
      areaOfStudy3,
      areaOfStudySpecific3,
      areaOfStudy4,
      areaOfStudySpecific4,
      areaOfStudyParent,
      areaOfStudyParentSpecific,
      areaOfStudyParent1,
      areaOfStudyParentSpecific1,
      areaOfStudyParent2,
      areaOfStudyParentSpecific2,
      areaOfStudyParent3,
      areaOfStudyParentSpecific3,
      areaOfStudyParent4,
      areaOfStudyParentSpecific4,
      studyGoalsStudentPerspectiveEntranceExamBox,
      studyGoalsStudentPerspectiveEntranceExamField,
      studyGoalsStudentPerspectiveSchoolExamBox,
      studyGoalsStudentPerspectiveSATBox,
      studyGoalsStudentPerspectiveGREBox,
      studyGoalsStudentPerspectiveIELTSBox,
      studyGoalsStudentPerspectiveTOEFLBox,
      studyGoalsStudentPerspectiveGMATBox,
      studyGoalsStudentPerspectiveOthersBox,
      studyGoalsStudentPerspectiveOthersField,
      studyGoalsParentPerspectiveEntranceExamBox,
      studyGoalsParentPerspectiveEntranceExamField,
      studyGoalsParentPerspectiveSchoolExamBox,
      studyGoalsParentPerspectiveSATBox,
      studyGoalsParentPerspectiveGREBox,
      studyGoalsParentPerspectiveIELTSBox,
      studyGoalsParentPerspectiveTOEFLBox,
      studyGoalsParentPerspectiveGMATBox,
      studyGoalsParentPerspectiveOthersBox,
      studyGoalsParentPerspectiveOthersField,
      targetCountryStudentUS,
      targetCountryStudentCanada,
      targetCountryStudentUK,
      targetCountryStudentOthersBox,
      targetCountryStudentOthersField,
      targetCountryParentUS,
      targetCountryParentCanada,
      targetCountryParentUK,
      targetCountryParentOthersBox,
      targetCountryParentOthersField,
      specialRequests,
      submitChanges
    } = this.props

    const studyGoalsStudentPerspective = [
      {
        check: studyGoalsStudentPerspectiveSchoolExamBox,
        nameBox: 'studyGoalsStudentPerspectiveSchoolExamBox',
        labelBox: 'School Exam'
      },
      { check: studyGoalsStudentPerspectiveSATBox, nameBox: 'studyGoalsStudentPerspectiveSATBox', labelBox: 'SAT' },
      { check: studyGoalsStudentPerspectiveGREBox, nameBox: 'studyGoalsStudentPerspectiveGREBox', labelBox: 'GRE' },
      {
        check: studyGoalsStudentPerspectiveIELTSBox,
        nameBox: 'studyGoalsStudentPerspectiveIELTSBox',
        labelBox: 'IELTS'
      },
      {
        check: studyGoalsStudentPerspectiveTOEFLBox,
        nameBox: 'studyGoalsStudentPerspectiveTOEFLBox',
        labelBox: 'TOEFL'
      },
      { check: studyGoalsStudentPerspectiveGMATBox, nameBox: 'studyGoalsStudentPerspectiveGMATBox', labelBox: 'GMAT' }
    ]

    const targetCountryStudent = [
      { check: targetCountryStudentUS, nameBox: 'targetCountryStudentUS', labelBox: 'US' },
      { check: targetCountryStudentCanada, nameBox: 'targetCountryStudentCanada', labelBox: 'Canada' },
      { check: targetCountryStudentUK, nameBox: 'targetCountryStudentUK', labelBox: 'UK' }
    ]

    const targetCountryParent = [
      { check: targetCountryParentUS, nameBox: 'targetCountryParentUS', labelBox: 'US' },
      { check: targetCountryParentCanada, nameBox: 'targetCountryParentCanada', labelBox: 'Canada' },
      { check: targetCountryParentUK, nameBox: 'targetCountryParentUK', labelBox: 'UK' }
    ]

    const studyGoalsParentPerspective = [
      {
        check: studyGoalsParentPerspectiveSchoolExamBox,
        nameBox: 'studyGoalsParentPerspectiveSchoolExamBox',
        labelBox: 'School Exam'
      },
      { check: studyGoalsParentPerspectiveSATBox, nameBox: 'studyGoalsParentPerspectiveSATBox', labelBox: 'SAT' },
      { check: studyGoalsParentPerspectiveGREBox, nameBox: 'studyGoalsParentPerspectiveGREBox', labelBox: 'GRE' },
      { check: studyGoalsParentPerspectiveIELTSBox, nameBox: 'studyGoalsParentPerspectiveIELTSBox', labelBox: 'IELTS' },
      { check: studyGoalsParentPerspectiveTOEFLBox, nameBox: 'studyGoalsParentPerspectiveTOEFLBox', labelBox: 'TOEFL' },
      { check: studyGoalsParentPerspectiveGMATBox, nameBox: 'studyGoalsParentPerspectiveGMATBox', labelBox: 'GMAT' }
    ]

    return (
      <Paper elevation={2} className={'app-wrapper mb-3'}>
        <h1 id="academic-career">Academic & Career Goal</h1>
        <div>
          <FormControl className={'mb-3 w-100'}>
            <InputText
              label={`Career Goals`}
              name="careerGoalsStudentPerspective"
              defaultValue={careerGoalsStudentPerspective}
              onBlur={handleBlurInput.bind(this)}
            />
          </FormControl>
        </div>
        {currentUser._type === 'Parent' && (
          <div>
            <FormLabel style={{ color: 'blue' }}>
              Your career goal for your child (Your child will not see this)
            </FormLabel>
            <FormControl className={'mb-3 w-100 mt-1'}>
              <InputText
                label={`Parent Input - Career goals`}
                variant="outlined"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                name="careerGoalsParentPerspective"
                defaultValue={careerGoalsParentPerspective}
                onBlur={handleBlurInput.bind(this)}
              />
            </FormControl>
          </div>
        )}
        <div className="row">
          <div className={`row mb-3 ${currentUser._type === 'Parent' ? 'col-md-6' : 'col-md-12'}`}>
            <FormLabel component="legend" className="mt-3 ml-3">
              Study Goals
            </FormLabel>
            <OthersField
              label={'Entrance Exam'}
              check={studyGoalsStudentPerspectiveEntranceExamBox}
              nameBox={'studyGoalsStudentPerspectiveEntranceExamBox'}
              placeholderField={'Please specify entrance exam'}
              nameField={'studyGoalsStudentPerspectiveEntranceExamField'}
              valueField={studyGoalsStudentPerspectiveEntranceExamField}
              checkBoxChange={handleCheckboxChange}
              onBlur={handleBlurInput.bind(this)}
            />
            {studyGoalsStudentPerspective.map((studentPerspective, index) => {
              return (
                <Paper elevation={1} key={index} className="mx-1 my-1">
                  <div className="col-auto">
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={studentPerspective.nameBox}
                            checked={studentPerspective.check}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label={studentPerspective.labelBox}
                      />
                    </FormControl>
                  </div>
                  <div className="col-auto" style={{ marginBottom: '-30px', marginRight: '5px' }}>
                    <TextField disabled={true} InputProps={{ disableUnderline: true }} />
                  </div>
                </Paper>
              )
            })}
            <OthersField
              check={studyGoalsStudentPerspectiveOthersBox}
              nameBox={'studyGoalsStudentPerspectiveOthersBox'}
              placeholderField={'Please specify other'}
              nameField={'studyGoalsStudentPerspectiveOthersField'}
              valueField={studyGoalsStudentPerspectiveOthersField}
              checkBoxChange={handleCheckboxChange}
              onBlur={handleBlurInput.bind(this)}
            />
          </div>
          {currentUser._type === 'Parent' && (
            <div className="row mb-3 col-md-6">
              Parent Input
              <FormLabel className="my-2 col-md-12" style={{ color: 'blue' }}>
                Your Study Goals for your child (Your child will not see this)
              </FormLabel>
              <OthersField
                style={'box-parent'}
                label={'Entrance Exam'}
                check={studyGoalsParentPerspectiveEntranceExamBox}
                nameBox={'studyGoalsParentPerspectiveEntranceExamBox'}
                placeholderField={'Please specify entrance exam'}
                nameField={'studyGoalsParentPerspectiveEntranceExamField'}
                valueField={studyGoalsParentPerspectiveEntranceExamField}
                checkBoxChange={handleCheckboxChange}
                onBlur={handleBlurInput.bind(this)}
              />
              {studyGoalsParentPerspective.map((parentPerspective, index) => {
                return (
                  <Paper elevation={1} key={index} className="mx-1 my-1 box-parent">
                    <div className="col-auto">
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              name={parentPerspective.nameBox}
                              checked={parentPerspective.check}
                              onChange={handleCheckboxChange}
                            />
                          }
                          label={parentPerspective.labelBox}
                        />
                      </FormControl>
                    </div>
                    <div className="col-auto" style={{ marginBottom: '-30px' }}>
                      <TextField disabled={true} InputProps={{ disableUnderline: true }} />
                    </div>
                  </Paper>
                )
              })}
              <OthersField
                style={'box-parent'}
                check={studyGoalsParentPerspectiveOthersBox}
                nameBox={'studyGoalsParentPerspectiveOthersBox'}
                placeholderField={'Please specify other'}
                nameField={'studyGoalsParentPerspectiveOthersField'}
                valueField={studyGoalsParentPerspectiveOthersField}
                checkBoxChange={handleCheckboxChange}
                onBlur={handleBlurInput.bind(this)}
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className={`row mb-3 ${currentUser._type === 'Parent' ? 'col-md-6' : 'col-md-12'}`}>
            <FormLabel component="legend" className="mt-3 ml-3">
              Country of Target University
            </FormLabel>
            {targetCountryStudent.map((country, index) => {
              return (
                <Paper elevation={1} key={index} className="mx-1 my-1">
                  <div className="col-auto">
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={country.nameBox}
                            checked={country.check}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label={country.labelBox}
                      />
                    </FormControl>
                  </div>
                  <div className="col-auto" style={{ marginBottom: '-30px' }}>
                    <TextField disabled={true} InputProps={{ disableUnderline: true }} />
                  </div>
                </Paper>
              )
            })}
            <OthersField
              check={targetCountryStudentOthersBox}
              nameBox={'targetCountryStudentOthersBox'}
              placeholderField={'Please specify other'}
              nameField={'targetCountryStudentOthersField'}
              valueField={targetCountryStudentOthersField}
              checkBoxChange={handleCheckboxChange}
              onBlur={handleBlurInput.bind(this)}
            />
          </div>
          {currentUser._type === 'Parent' && (
            <div className="row mb-3 col-md-6">
              Parent Input
              <FormLabel className="col-md-12 mt-3" style={{ color: 'blue' }}>
                Your Target University Country for your child (Your child will not see this)
              </FormLabel>
              {targetCountryParent.map((country, index) => {
                return (
                  <Paper elevation={1} key={index} className="mx-1 my-1 box-parent">
                    <div className="col-auto">
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              name={country.nameBox}
                              checked={country.check}
                              onChange={handleCheckboxChange}
                            />
                          }
                          label={country.labelBox}
                        />
                      </FormControl>
                    </div>
                    <div className="col-auto" style={{ marginBottom: '-30px' }}>
                      <TextField disabled={true} InputProps={{ disableUnderline: true }} />
                    </div>
                  </Paper>
                )
              })}
              <OthersField
                style={'box-parent'}
                check={targetCountryParentOthersBox}
                nameBox={'targetCountryParentOthersBox'}
                placeholderField={'Please specify other'}
                nameField={'targetCountryParentOthersField'}
                valueField={targetCountryParentOthersField}
                checkBoxChange={handleCheckboxChange}
                onBlur={handleBlurInput.bind(this)}
              />
            </div>
          )}
        </div>
        <FormLabel legend="component" className="mt-4">
          {' '}
          Tentative area of Study
        </FormLabel>
        <div className="row">
          <div className="row col-6">
            <div className="col-12 col-md-12 mb-3 mt-1">
              <Autocomplete
                id="combo-box-area"
                name="areaOfStudy"
                options={studyArea.map(options => options.name)}
                value={areaOfStudy}
                onChange={(event, value) => {
                  if (value !== null) handleAutoCompleteStudyAreaChange(event, value)
                }}
                // getOptionLabel={option => option?.name || ''}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                renderInput={params => <TextField {...params} label="Choose an area of study" />}
              />
            </div>
          </div>

          {areaOfStudy && (
            <div className="row col-6">
              <div className="col-12 col-md-12 mb-3 mt-1">
                <Autocomplete
                  id="combo-box--specific-area"
                  name="areaOfStudySpecific"
                  value={areaOfStudySpecific}
                  options={studyAreaSpecific
                    .filter(options => options.nameAS === areaOfStudy)
                    .map(options => options.name)}
                  onChange={(event, value) => {
                    if (value !== null) handleAutoCompleteStudyAreaSpecificChange(event, value)
                  }}
                  // getOptionLabel={option => (option?.nameAS === areaOfStudy ? option?.name : '')}
                  // filterOptions={(options) =>
                  // options.filter(options => options.nameAS === areaOfStudy)
                  // }
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  renderInput={params => <TextField {...params} label="Choose a specific area of study" />}
                />
              </div>
            </div>
          )}
        </div>
        {/* https://stackoverflow.com/questions/59947210/material-ui-autocomplete-component-not-showing-values-from-react-state */}

        {(areaOfStudySpecific1 !== '' || showareaOfStudy1) && (
          <div className="row">
            <div className="row col-6">
              <div className="col-12 col-md-12 mb-3 mt-1">
                <Autocomplete
                  id="combo-box-area-1"
                  name="areaOfStudy1"
                  options={studyArea.map(options => options.name)}
                  value={areaOfStudy1}
                  onChange={(event, value) => {
                    if (value !== null) handleAutoCompleteStudyAreaChange1(event, value)
                  }}
                  // getOptionLabel={option => option?.name || ''}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  renderInput={params => <TextField {...params} label="Choose an area of study" />}
                />
              </div>
            </div>

            {areaOfStudy1 && (
              <div className="row col-6">
                <div className="col-12 col-md-12 mb-3 mt-1">
                  <Autocomplete
                    id="combo-box--specific-area-1"
                    name="areaOfStudySpecific1"
                    value={areaOfStudySpecific1}
                    options={studyAreaSpecific
                      .filter(options => options.nameAS === areaOfStudy1)
                      .map(options => options.name)}
                    onChange={(event, value) => {
                      if (value !== null) handleAutoCompleteStudyAreaSpecificChange1(event, value)
                    }}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    renderInput={params => <TextField {...params} label="Choose a specific area of study" />}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {(areaOfStudySpecific2 !== '' || showareaOfStudy2) && (
          <div className="row">
            <div className="row col-6">
              <div className="col-12 col-md-12 mb-3 mt-1">
                <Autocomplete
                  id="combo-box-area-2"
                  name="areaOfStudy2"
                  options={studyArea.map(options => options.name)}
                  value={areaOfStudy2}
                  onChange={(event, value) => {
                    if (value !== null) handleAutoCompleteStudyAreaChange2(event, value)
                  }}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  renderInput={params => <TextField {...params} label="Choose an area of study" />}
                />
              </div>
            </div>

            {areaOfStudy2 && (
              <div className="row col-6">
                <div className="col-12 col-md-12 mb-3 mt-1">
                  <Autocomplete
                    id="combo-box--specific-area-2"
                    name="areaOfStudySpecific2"
                    value={areaOfStudySpecific2}
                    options={studyAreaSpecific
                      .filter(options => options.nameAS === areaOfStudy2)
                      .map(options => options.name)}
                    onChange={(event, value) => {
                      if (value !== null) handleAutoCompleteStudyAreaSpecificChange2(event, value)
                    }}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    renderInput={params => <TextField {...params} label="Choose a specific area of study" />}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {(areaOfStudySpecific3 !== '' || showareaOfStudy3) && (
          <div className="row">
            <div className="row col-6">
              <div className="col-12 col-md-12 mb-3 mt-1">
                <Autocomplete
                  id="combo-box-area-3"
                  name="areaOfStudy3"
                  options={studyArea.map(options => options.name)}
                  value={areaOfStudy3}
                  onChange={(event, value) => {
                    if (value !== null) handleAutoCompleteStudyAreaChange3(event, value)
                  }}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  renderInput={params => <TextField {...params} label="Choose an area of study" />}
                />
              </div>
            </div>

            {areaOfStudy3 && (
              <div className="row col-6">
                <div className="col-12 col-md-12 mb-3 mt-1">
                  <Autocomplete
                    id="combo-box--specific-area-3"
                    name="areaOfStudySpecific3"
                    value={areaOfStudySpecific3}
                    options={studyAreaSpecific
                      .filter(options => options.nameAS === areaOfStudy3)
                      .map(options => options.name)}
                    onChange={(event, value) => {
                      if (value !== null) handleAutoCompleteStudyAreaSpecificChange3(event, value)
                    }}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    renderInput={params => <TextField {...params} label="Choose a specific area of study" />}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {(areaOfStudySpecific4 !== '' || showareaOfStudy4) && (
          <div className="row">
            <div className="row col-6">
              <div className="col-12 col-md-12 mb-3 mt-1">
                <Autocomplete
                  id="combo-box-area-4"
                  name="areaOfStudy4"
                  options={studyArea.map(options => options.name)}
                  value={areaOfStudy4}
                  onChange={(event, value) => {
                    if (value !== null) handleAutoCompleteStudyAreaChange4(event, value)
                  }}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  renderInput={params => <TextField {...params} label="Choose an area of study" />}
                />
              </div>
            </div>

            {areaOfStudy4 && (
              <div className="row col-6">
                <div className="col-12 col-md-12 mb-3 mt-1">
                  <Autocomplete
                    id="combo-box--specific-area-4"
                    name="areaOfStudySpecific4"
                    value={areaOfStudySpecific4}
                    options={studyAreaSpecific
                      .filter(options => options.nameAS === areaOfStudy4)
                      .map(options => options.name)}
                    onChange={(event, value) => {
                      if (value !== null) handleAutoCompleteStudyAreaSpecificChange4(event, value)
                    }}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    renderInput={params => <TextField {...params} label="Choose a specific area of study" />}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        {areaOfStudySpecific1 === '' && !showareaOfStudy1 && (
          <Button
            style={{ color: 'black' }}
            color={'secondary'}
            variant={'contained'}
            fullWidth
            onClick={this.addAreaOfStudy1}>
            Add another tentative area of study
          </Button>
        )}
        {areaOfStudySpecific2 === '' && !showareaOfStudy2 && showareaOfStudy1 && (
          <Button
            style={{ color: 'black' }}
            color={'secondary'}
            variant={'contained'}
            fullWidth
            onClick={this.addAreaOfStudy2}>
            Add another tentative area of study
          </Button>
        )}
        {areaOfStudySpecific3 === '' && !showareaOfStudy3 && showareaOfStudy2 && (
          <Button
            style={{ color: 'black' }}
            color={'secondary'}
            variant={'contained'}
            fullWidth
            onClick={this.addAreaOfStudy3}>
            Add another tentative area of study
          </Button>
        )}
        {areaOfStudySpecific4 === '' && !showareaOfStudy4 && showareaOfStudy3 && (
          <Button
            style={{ color: 'black' }}
            color={'secondary'}
            variant={'contained'}
            fullWidth
            onClick={this.addAreaOfStudy4}>
            Add another tentative area of study
          </Button>
        )}
        {currentUser._type === 'Parent' && (
          <>
            <div className="row mt-4">
              <div className="col-12">
                <FormLabel style={{ color: 'blue' }}>
                  Your tentative area of study for your child (Your child will not see this)
                </FormLabel>
              </div>

              <div className="row col-6">
                <div className="col-12 col-md-12 mb-3 mt-1">
                  <Autocomplete
                    id="combo-box-area"
                    name="areaOfStudyParent"
                    options={studyArea.map(options => options.name)}
                    value={areaOfStudyParent}
                    onChange={(event, value) => {
                      if (value !== null) handleAutoCompleteParentStudyAreaChange(event, value)
                    }}
                    // getOptionLabel={option => option?.name || ''}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    renderInput={params => <TextField {...params} label="Choose an area of study" />}
                  />
                </div>
              </div>

              {areaOfStudyParent && (
                <div className="row col-6">
                  <div className="col-12 col-md-12 mb-3 mt-1">
                    <Autocomplete
                      id="combo-box--specific-area"
                      name="areaOfStudyParentSpecific"
                      value={areaOfStudyParentSpecific}
                      options={studyAreaSpecific
                        .filter(options => options.nameAS === areaOfStudyParent)
                        .map(options => options.name)}
                      onChange={(event, value) => {
                        if (value !== null) handleAutoCompleteParentStudyAreaSpecificChange(event, value)
                      }}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      renderInput={params => <TextField {...params} label="Choose a specific area of study" />}
                    />
                  </div>
                </div>
              )}
            </div>
            {(areaOfStudyParentSpecific1 !== '' || showareaOfStudyParent1) && (
              <div className="row">
                <div className="row col-6">
                  <div className="col-12 col-md-12 mb-3 mt-1">
                    <Autocomplete
                      id="combo-box-area-p-1"
                      name="areaOfStudyParent1"
                      options={studyArea.map(options => options.name)}
                      value={areaOfStudyParent1}
                      onChange={(event, value) => {
                        if (value !== null) handleAutoCompleteParentStudyAreaChange1(event, value)
                      }}
                      // getOptionLabel={option => option?.name || ''}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      renderInput={params => <TextField {...params} label="Choose an area of study" />}
                    />
                  </div>
                </div>

                {areaOfStudyParent1 && (
                  <div className="row col-6">
                    <div className="col-12 col-md-12 mb-3 mt-1">
                      <Autocomplete
                        id="combo-box--specific-area-p-1"
                        name="areaOfStudyParentSpecific1"
                        value={areaOfStudyParentSpecific1}
                        options={studyAreaSpecific
                          .filter(options => options.nameAS === areaOfStudyParent1)
                          .map(options => options.name)}
                        onChange={(event, value) => {
                          if (value !== null) handleAutoCompleteParentStudyAreaSpecificChange1(event, value)
                        }}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        renderInput={params => <TextField {...params} label="Choose a specific area of study" />}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            {(areaOfStudyParentSpecific2 !== '' || showareaOfStudyParent2) && (
              <div className="row">
                <div className="row col-6">
                  <div className="col-12 col-md-12 mb-3 mt-1">
                    <Autocomplete
                      id="combo-box-area-p-2"
                      name="areaOfStudyParent2"
                      options={studyArea.map(options => options.name)}
                      value={areaOfStudyParent2}
                      onChange={(event, value) => {
                        if (value !== null) handleAutoCompleteParentStudyAreaChange2(event, value)
                      }}
                      // getOptionLabel={option => option?.name || ''}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      renderInput={params => <TextField {...params} label="Choose an area of study" />}
                    />
                  </div>
                </div>

                {areaOfStudyParent2 && (
                  <div className="row col-6">
                    <div className="col-12 col-md-12 mb-3 mt-1">
                      <Autocomplete
                        id="combo-box--specific-area-p-2"
                        name="areaOfStudyParentSpecific2"
                        value={areaOfStudyParentSpecific2}
                        options={studyAreaSpecific
                          .filter(options => options.nameAS === areaOfStudyParent2)
                          .map(options => options.name)}
                        onChange={(event, value) => {
                          if (value !== null) handleAutoCompleteParentStudyAreaSpecificChange2(event, value)
                        }}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        renderInput={params => <TextField {...params} label="Choose a specific area of study" />}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            {(areaOfStudyParentSpecific3 !== '' || showareaOfStudyParent3) && (
              <div className="row">
                <div className="row col-6">
                  <div className="col-12 col-md-12 mb-3 mt-1">
                    <Autocomplete
                      id="combo-box-area-p-3"
                      name="areaOfStudyParent3"
                      options={studyArea.map(options => options.name)}
                      value={areaOfStudyParent3}
                      onChange={(event, value) => {
                        if (value !== null) handleAutoCompleteParentStudyAreaChange3(event, value)
                      }}
                      // getOptionLabel={option => option?.name || ''}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      renderInput={params => <TextField {...params} label="Choose an area of study" />}
                    />
                  </div>
                </div>

                {areaOfStudyParent3 && (
                  <div className="row col-6">
                    <div className="col-12 col-md-12 mb-3 mt-1">
                      <Autocomplete
                        id="combo-box--specific-area-p-3"
                        name="areaOfStudyParentSpecific3"
                        value={areaOfStudyParentSpecific3}
                        options={studyAreaSpecific
                          .filter(options => options.nameAS === areaOfStudyParent3)
                          .map(options => options.name)}
                        onChange={(event, value) => {
                          if (value !== null) handleAutoCompleteParentStudyAreaSpecificChange3(event, value)
                        }}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        renderInput={params => <TextField {...params} label="Choose a specific area of study" />}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            {(areaOfStudyParentSpecific4 !== '' || showareaOfStudyParent4) && (
              <div className="row">
                <div className="row col-6">
                  <div className="col-12 col-md-12 mb-3 mt-1">
                    <Autocomplete
                      id="combo-box-area-p-4"
                      name="areaOfStudyParent4"
                      options={studyArea.map(options => options.name)}
                      value={areaOfStudyParent4}
                      onChange={(event, value) => {
                        if (value !== null) handleAutoCompleteParentStudyAreaChange4(event, value)
                      }}
                      // getOptionLabel={option => option?.name || ''}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      renderInput={params => <TextField {...params} label="Choose an area of study" />}
                    />
                  </div>
                </div>

                {areaOfStudyParent4 && (
                  <div className="row col-6">
                    <div className="col-12 col-md-12 mb-3 mt-1">
                      <Autocomplete
                        id="combo-box--specific-area-p-4"
                        name="areaOfStudyParentSpecific4"
                        value={areaOfStudyParentSpecific4}
                        options={studyAreaSpecific
                          .filter(options => options.nameAS === areaOfStudyParent4)
                          .map(options => options.name)}
                        onChange={(event, value) => {
                          if (value !== null) handleAutoCompleteParentStudyAreaSpecificChange4(event, value)
                        }}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        renderInput={params => <TextField {...params} label="Choose a specific area of study" />}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            {areaOfStudyParentSpecific1 === '' && !showareaOfStudyParent1 && (
              <Button
                style={{ color: 'black' }}
                color={'secondary'}
                variant={'contained'}
                fullWidth
                onClick={this.addAreaOfStudyParent1}>
                Add another tentative area of study
              </Button>
            )}
            {areaOfStudyParentSpecific2 === '' && !showareaOfStudyParent2 && showareaOfStudyParent1 && (
              <Button
                style={{ color: 'black' }}
                color={'secondary'}
                variant={'contained'}
                fullWidth
                onClick={this.addAreaOfStudyParent2}>
                Add another tentative area of study
              </Button>
            )}
            {areaOfStudyParentSpecific3 === '' && !showareaOfStudyParent3 && showareaOfStudyParent2 && (
              <Button
                style={{ color: 'black' }}
                color={'secondary'}
                variant={'contained'}
                fullWidth
                onClick={this.addAreaOfStudyParent3}>
                Add another tentative area of study
              </Button>
            )}
            {areaOfStudyParentSpecific4 === '' && !showareaOfStudyParent4 && showareaOfStudyParent3 && (
              <Button
                style={{ color: 'black' }}
                color={'secondary'}
                variant={'contained'}
                fullWidth
                onClick={this.addAreaOfStudyParent4}>
                Add another tentative area of study
              </Button>
            )}
          </>
        )}
        <FormControl className={'mb-3 w-100 mt-3'}>
          <InputText
            label={'Special Requests'}
            name={'specialRequests'}
            defaultValue={specialRequests}
            onBlur={handleBlurInput.bind(this)}
          />
        </FormControl>
        <hr />
        <div className="d-flex flex-row flex-wrap justify-content-between align-items-center mt-3 mr-3">
          {currentUser._type === 'Parent' ? (
            <Link to={`/app`}>Go Back to Profile</Link>
          ) : (
            <Link to={USER_PROFILE}>Go Back to Profile</Link>
          )}
          <Button variant="contained" color="primary" className="ml-3" onClick={submitChanges}>
            Save Changes
          </Button>
        </div>
      </Paper>
    )
  }
}

export default withStyles(styles)(AcademicCareerGoal)
