import React from 'react'
import { ALL_CLASSES } from '../../../routes/routes'
import ReactGA from 'react-ga'
import { connect } from 'react-redux'
import { GET_NEWS } from 'constants/actionTypes'
import agent from 'agent'
import { withRouter } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
const mapStateToProps = state => ({
  announcements: state.newsandevents.announcements
})

const mapDispatchToProps = dispatch => ({
  getNews: () => dispatch({ type: GET_NEWS, payload: agent.NewsAndEvents.getNews() })
})

class HeroBanner extends React.Component {
  componentDidMount() {
    this.props.getNews()
  }

  seeAllClasses = () => {
    this.props.history.push(ALL_CLASSES)
    ReactGA.event({
      category: 'LP',
      action: 'SeeAllClasses',
      label: 'Banner'
    })
  }

  scrollToFeaturedClass = () => {
    let element = document.getElementById('trial')
    window.scrollTo({ top: element.offsetTop, behavior: 'smooth' })
  }

  render() {
    const { announcements } = this.props
    return (
      <>
        {announcements && announcements[0].detail.title !== '' ? (
          <section className={'pt-md-0 pb-2 app-zIndex'} style={{ backgroundColor: '#F7EFEA' }}>
            <div
              className={
                // this.props.location.pathname === '/'
                //   ? 'hero-banner__slogan pt-md-3 pt-0'
                // :
                'hero-banner__other_page_slogan pt-lg-3 pt-0 pt-md-5'
              }
              style={{ marginTop: isMobile ? '2em' : '0em', marginLeft: isMobile ? '-8px' : '32px' }}>
              <div className="text-lg-left">
                <div className={'info-announcement mb-1'}>
                  <span className="mx-3 mx-lg-0" style={{ fontWeight: 'bold' }}>
                    Announcement{' '}
                  </span>{' '}
                  &nbsp; &nbsp;
                  <div className="d-lg-none mx-3 info-announcement">
                    {announcements && announcements[0].detail.title}
                  </div>
                  <span className="d-none d-lg-inline info-announcement">
                    {announcements && announcements[0].detail.title}
                  </span>
                </div>
              </div>
              {/* <button className="tnp__button d-none d-md-block" onClick={this.seeAllClasses}>
            Get Started
          </button> */}
            </div>
          </section>
        ) : (
          ''
        )}
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeroBanner))
