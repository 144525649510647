import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer'

import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants/settingsTypes'
import { TOGGLE_COLLAPSED_NAV, UPDATE_WINDOW_WIDTH } from 'constants/actionTypes'
import SidebarContent from './section-menu'
import UserInfo from './section-user'

const mapStateToProps = state => ({
  navCollapsed: state.settings.navCollapsed,
  drawerType: state.settings.drawerType,
  width: state.settings.width
})

const mapDispatchToProps = dispatch => ({
  toggleCollapsedNav: isNavCollapsed => dispatch({ type: TOGGLE_COLLAPSED_NAV, isNavCollapsed }),
  updateWindowWidth: width => dispatch({ type: UPDATE_WINDOW_WIDTH, width })
})

class SideNav extends React.PureComponent {
  onToggleCollapsedNav = e => {
    const val = !this.props.navCollapsed
    this.props.toggleCollapsedNav(val)
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.props.updateWindowWidth(window.innerWidth)
    })
  }

  render() {
    const { navCollapsed, drawerType, width } = this.props
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'd-xl-flex'
      : drawerType.includes(COLLAPSED_DRAWER)
      ? ''
      : 'd-flex'
    let type = 'permanent'
    if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1919)) {
      type = 'temporary'
    }

    return (
      <div className={`app-sidebar d-none ${drawerStyle}`}>
        <Drawer
          className="app-sidebar-content"
          variant={type}
          open={type.includes('temporary') ? navCollapsed : true}
          onClose={this.onToggleCollapsedNav}
          classes={{
            paper: 'side-nav'
          }}>
          <UserInfo />
          <SidebarContent />
        </Drawer>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideNav))
