import * as Type from '../constants/actionTypes'
import _ from 'lodash'

//NOTE: have more state to use for start, shall review later
const initialState = {
  loadingHomeworkByLesson: [], // TODO: convert to object
  homeworksByLesson: {}, //only for student view usage
  creatingHomework: false,
  createSuccess: null,
  loadingHomeworkById: false,
  homework: null,
  updatingHomework: false,
  updateStatus: '',
  deletingHomework: false,
  deleteStatus: {},
  loadingHomeworkList: false,
  homeworkList: { data: [], count: 0, filter: {} },
  loadingPendingSubmission: false,
  pendingSubmissionList: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.GET_HOMEWORK_BY_LESSON: {
      let loadingHomeworkByLesson = state.loadingHomeworkByLesson
      loadingHomeworkByLesson.push(action.lessonId)

      return {
        ...state,
        loadingHomeworkByLesson
      }
    }

    case Type.GET_HOMEWORK_BY_LESSON_SUCCESS: {
      let loadingHomeworkByLesson = state.loadingHomeworkByLesson
      loadingHomeworkByLesson = _.remove(loadingHomeworkByLesson, o => {
        return o !== action.lessonId
      })

      let homeworksByLesson = state.homeworksByLesson
      let homeworkData = {
        data: action.payload.data,
        count: action.payload.count
      }
      homeworksByLesson[action.lessonId] = homeworkData

      return {
        ...state,
        loadingHomeworkByLesson,
        homeworksByLesson
      }
    }

    case Type.GET_HOMEWORK_BY_LESSON_FAILED: {
      let loadingHomeworkByLesson = state.loadingHomeworkByLesson
      loadingHomeworkByLesson = _.remove(loadingHomeworkByLesson, o => {
        return o !== action.lessonId
      })

      return {
        ...state,
        loadingHomeworkByLesson
      }
    }

    case Type.GET_HOMEWORK: {
      let updatedHomeworkList = state.homeworkList
      let formattedFilter = action.filter
      delete formattedFilter.max
      delete formattedFilter.offset

      let resetHomeworkList =
        !_.isEqual(updatedHomeworkList.filter, formattedFilter) ||
        updatedHomeworkList.count === updatedHomeworkList.data.length
      if (resetHomeworkList) {
        updatedHomeworkList.data = []
        updatedHomeworkList.count = 0
        updatedHomeworkList.filter = action.filter
      }

      return {
        ...state,
        loadingHomeworkList: true,
        homeworkList: updatedHomeworkList
      }
    }

    case Type.GET_HOMEWORK_SUCCESS: {
      let updatedHomeworkList = state.homeworkList
      updatedHomeworkList.data = _.concat(updatedHomeworkList.data, action.payload.data)
      updatedHomeworkList.count = action.payload.count

      return {
        ...state,
        loadingHomeworkList: false,
        homeworkList: updatedHomeworkList
      }
    }

    case Type.GET_HOMEWORK_FAILED:
      return {
        ...state,
        loadingHomework: false
      }

    case Type.GET_HOMEWORK_BY_ID:
      return {
        ...state,
        loadingHomeworkById: true,
        homework: null
      }

    case Type.GET_HOMEWORK_BY_ID_SUCCESS:
      return {
        ...state,
        loadingHomeworkById: false,
        homework: action.payload.data
      }

    case Type.GET_HOMEWORK_BY_ID_FAILED:
      return {
        ...state,
        loadingHomeworkById: false
      }

    case Type.GET_PENDING_SUBMISSION_HOMEWORK:
      return {
        ...state,
        loadingPendingSubmission: true,
        pendingSubmissionList: []
      }

    case Type.GET_PENDING_SUBMISSION_HOMEWORK_SUCCESS:
      return {
        ...state,
        loadingPendingSubmission: false,
        pendingSubmissionList: action.payload.data
      }

    case Type.GET_PENDING_SUBMISSION_HOMEWORK_FAILED:
      return {
        ...state,
        loadingPendingSubmission: false
      }

    case Type.CREATE_SUBMISSION_SUCCESS: {
      const updatedHomeworkIndex = _.findIndex(state.pendingSubmissionList, o => {
        return o._id === action.homeworkId
      })
      let updatedPendingSubmissionList = state.pendingSubmissionList
      if (updatedHomeworkIndex > -1) {
        updatedPendingSubmissionList[updatedHomeworkIndex]['submitted'] = true
        updatedPendingSubmissionList[updatedHomeworkIndex]['submitted_files'] = action.payload.data.attachment
      }

      return {
        ...state,
        pendingSubmissionList: updatedPendingSubmissionList
      }
    }

    case Type.GET_HOMEWORK_LESSON_DETAIL:
      return {
        ...state,
        loadingLessonDetail: true
      }

    case Type.GET_HOMEWORK_LESSON_DETAIL_SUCCESS: {
      let updatedHomeworkList = state.homeworkList
      let updateIndex = _.findIndex(updatedHomeworkList.data, o => {
        return o._id === action.homeworkId
      })
      if (updateIndex !== -1) {
        updatedHomeworkList.data[updateIndex].lesson = action.payload.data
      }

      return {
        ...state,
        loadingLessonDetail: false,
        homeworkList: updatedHomeworkList
      }
    }

    case Type.GET_HOMEWORK_LESSON_DETAIL_FAILED: {
      return {
        ...state,
        loadingLessonDetail: false
      }
    }

    case Type.CREATE_HOMEWORK:
      return {
        ...state,
        creatingHomework: true,
        createSuccess: null
      }

    case Type.CREATE_HOMEWORK_SUCCESS: {
      let newCreatedHomework = action.payload.data
      let updatedHomeworkList = state.homeworkList
      updatedHomeworkList.data.push(newCreatedHomework)
      updatedHomeworkList.count++

      return {
        ...state,
        creatingHomework: false,
        createSuccess: true,
        homeworkList: updatedHomeworkList
      }
    }

    case Type.CREATE_HOMEWORK_FAILED:
      return {
        ...state,
        creatingHomework: false,
        createSuccess: false
      }

    case Type.UPDATE_HOMEWORK:
      return {
        ...state,
        updatingHomework: true,
        updateStatus: ''
      }

    case Type.UPDATE_HOMEWORK_SUCCESS: {
      let updatedHomeworkList = state.homeworkList
      let homeworkIndex = _.findIndex(updatedHomeworkList.data, o => {
        return o._id === action.homeworkId
      })
      updatedHomeworkList.data[homeworkIndex] = action.payload.data

      return {
        ...state,
        updatingHomework: false,
        homeworkList: updatedHomeworkList,
        updateStatus: 'success'
      }
    }

    case Type.UPDATE_HOMEWORK_FAILED:
      return {
        ...state,
        updatingHomework: false,
        updateStatus: 'fail'
      }

    case Type.DELETE_HOMEWORK:
      return {
        ...state,
        deletingHomework: true,
        deleteStatus: {}
      }

    case Type.DELETE_HOMEWORK_SUCCESS: {
      let updatedHomeworkList = state.homeworkList
      let deletedHomeworkId = action.payload.data._id
      _.remove(updatedHomeworkList.data, o => {
        return o._id === deletedHomeworkId
      })
      updatedHomeworkList.count--

      return {
        ...state,
        deletingHomework: false,
        deleteStatus: { status: 'success', id: action.payload.data._id },
        homeworkList: updatedHomeworkList
      }
    }

    case Type.DELETE_HOMEWORK_FAILED: {
      let errorReason = action.payload.error.message
      let errorMessage = 'Something went wrong'
      if (errorReason === 'submission-exists') {
        errorMessage = "You can't delete homework with submissions"
      } else if (errorReason === 'homework-released') {
        errorMessage = "You can't delete homework after it released"
      }

      return {
        ...state,
        deletingHomework: false,
        deleteStatus: {
          status: 'failed',
          message: errorMessage,
          id: action.homeworkId
        }
      }
    }

    case Type.LOGOUT:
      return initialState

    default:
      return state
  }
}
