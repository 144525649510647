import React from 'react'
export const CarouselData = [
  {
    pages: 2,
    page: 0,
    // name: 'Phuwadej Pornaroontham (Kru Team)',
    name: 'Kru Team',
    department: 'Chemistry (IGCSE, A Level, IB, SAT, BMAT, IELTS, Undergrads)',
    description:
      'Phuwadej has been a chemistry tutor for more than 10 years and had 2 years of experience as an assistant professor at Nagoya University in the Department of Chemical Systems Engineering. Additionally, he holds a Ph.D in Science (Chemical Technology) from Chulalongkorn University. With his vast experience and background in Chemistry, Kru Team believes that learning Chemistry should not be memorising for exams but he provides students with sustainable learning skills that woud be beneficial for lifelong learning. Kru Team also possesses current knowledge in the field as he is working as a researcher in the integration of machine learning with chemical engineering.',
    bgColor: '#FCF1F0',
    image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+Team.jpg',
    education: 'Ph.D in Science (Chemical Technology) at Chulalongkorn University',
    experience: '10+ years teaching experience. 2 years as assistant professor at Nagoya University.'
  },
  {
    page: 0,
    // name: 'Jittichai Lertphinyovong (Kru Jeep)',
    name: 'Kru Jeep',
    department:
      'Math [Key-stage (Yr. 6-8), IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, Standardized exam (SAT, BMAT, IELTS), University level and Contest]',
    description: `Kru Jeep holds a Bachelor’s and Master's Engineering (Electrical engineering) from Chulalongkorn University. With almost 20 years of tutoring experience, Kru Jeep specialises in finding ingenious solutions to solving mathematical problems. He then inspires his students to do the same and excite them to find innovative solutions. Kru Jeep has been participating in the International Mathematical Olympiad Bootcamp for the top 25 finalists, also was awarded by The Mathematical association of Thailand.`,
    bgColor: '#EFF5EA',
    image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+Jeep+portrait.jpg',
    education: 'BEng and MEng Electrical engineering, Chulalongkorn University.',
    experience:
      'เคยผ่านการเข้าค่ายอบรมคณิตศาสตร์โอลิมปิก สสวท รอบ 25 คนสุดท้าย และได้รางวัลจากการแข่งขันสมาคณิตศาสตร์แห่งประเทศไทย (ไม่แน่ใจว่าเข้าข่ายหรือเปล่านะครับ)',
    expThai: 'yes'
  },
  {
    page: 0,
    // name: 'Itthi Chatnuntawech (Kru Kanoon)',
    name: 'Kru Kanoon',
    department:
      'Mathematics, Computer Science, Artificial Intelligence (IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, Standardized exam (SAT, BMAT, IELTS), Signal Processing, Linear Algebra, Optimization, Artificial Intelligence)',
    description: `Kru Kanoon received his Bachelor’s of Science in Electrical and Computer Engineering and Biomedical Engineering from Carnegie Mellon University and furthered his education in MIT attaining his Master’s, and Doctoral degrees in Electrical Engineering and Computer Science. With over 5 years of experience working as a research scientist and data scientist, Kru Kanoon is not only competent in teaching Mathematics, he is also proficient also in teaching Signal Processing and Image Processing`,
    bgColor: '#EFF5FB',
    image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+Kanoon.png',
    education: (
      <div>
        S.M. in Electrical Engineering and Computer Science, MIT; <br />
        Ph.D. in Electrical Engineering and Computer Science, MIT
      </div>
    ),
    experience: '5+ years of teaching in Mathematics and 3+ years in Signal Processing and Image Processing'
  },
  {
    page: 1,
    // name: 'Kriengkamol Teerachanan (Kru Tee) ',
    name: 'Kru Tee',
    department: 'Chemistry [IGCSE (Yr. 9-11);A-level (Yr. 12-13);IB;Standardized exam (SAT, BMAT, IELTS)]',
    description: `With over 9 years of experience in teaching Chemistry to students of diverse backgrounds and academic levels, Kru Tee has the unique ability to relate to his students’ varied learning styles and capabilities and explain scientific concepts in much clarity such that his students love coming to his classes. Kru Tee holds a Master’s of Science in  Material Science and Engineering (Inorganic Chemistry) from Mahidol University. He has specialised knowledge in this field and imparts it to his students.`,
    bgColor: '#FCF1F0',
    image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+Tee.jpg',
    education: (
      <div>
        M.Sc Material Science and Engineering (Inorganic Chemistry), Mahidol University; <br />
        B.Sc Chemistry, Mahidol University
      </div>
    ),
    experience: '2 years Teacher Assistant in Organic Laboratory at Mahidol University'
  },
  {
    page: 1,
    // name: 'Krit Chusanapak (Kru Krit)',
    name: 'Kru Krit',
    department: 'Economics (IGCSE (Yr. 9-11);A-level (Yr. 12-13);IB;AP,  Selected university courses)',
    description: `Kru Krit obtained her BA in Economics from Vancouver School of Economics, University of British Columbia, and pursued her MBA at Sasin Graduate Institute of Business Administration, Chulalongkorn University. Kru Krit believes that in order for students to appreciate economics, it has to be fun and easy and she had found strategies to ensure that. Using her experience working in financial public policies in governmental agencies, she gives her students further insights.`,
    bgColor: '#EFF5EA',
    image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+Krit.jpeg',
    education: (
      <div>
        BA in Economics, Vancouver School of Economics, University of British Columbia; <br />
        MBA, Sasin Graduate Institute of Business Administration, Chulalongkorn University
      </div>
    ),
    experience: '10+ years teaching Economics'
  },
  {
    page: 1,
    // name: 'Chawinya Trakoolsoontorn (Kru Eve)',
    name: 'Kru Eve',
    department: 'Biology [IGCSE (Yr. 9-11)]',
    description: `Currently pursuing her PhD in Microbiology and Immunology, Mahidol University, Kru Eve has specific insights to Biology that sparks excitement in her students as they approach Biology. Kru Eve believes that Biology is an amazing jigsaw puzzle waiting for us to solve, although it sounds challenging. With over 9 years of studying Biology and 6-year teaching experience, Kru Eve helps students have fun in the fascinating world of Biology step by step.`,
    bgColor: '#EFF5FB',
    image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+Eve.jpg',
    education: (
      <div>
        BA in Biology, Chulalongkorn University; <br />
        PhD in Microbiology and Immunology, Mahidol University (in progress)
      </div>
    ),
    experience: '6+ Years teaching Biology (Thai curriculum students)'
  }
  // {
  //   page: 0,
  //   name: 'Jirachote Karunyakorn (Kru Sun)',
  //   department: 'Biology (IGSCE)',
  //   description: `Kru Sun graduated from Mahidol University with First Class Honors in Biology and worked in bioinformatics research for 3 years in the sequencing technology field. Currently, a Masters's student at Chulalongkorn University, Kru Sun enjoys helping students reach their potential. She is especially passionate about listening to her students' aspirations and helping them overcome their fears to achieve their goals.`,
  //   bgColor: '#EFF5EA',
  //   image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+.jpg',
  //   education: 'Biology degree from Mahidol University (First Class Honors) and Master’s from Chulalongkorn University',
  //   experience: '3+ years experience in bioinformatics research, sequencing technology field'
  // },
  // {
  //   page: 0,
  //   name: 'Phantira Waranukrohchok (Kru Jip)',
  //   department: 'English',
  //   description: `While on her 2-year stint in New York, Phantira found her passion for teaching English to immigrants at a public library and the college she attended. She earned her teaching certificate in New York and later pursued a Master’s degree, with her research on teaching theories and learning behaviours of students in Thailand. Even with her extensive teaching experience, she continues to improve her teaching skills and has acquired more teaching certificates over the years to keep on improving her ability to help her students.`,
  //   bgColor: '#EFF5FB',
  //   image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+.jpg',
  //   education: (
  //     <div>
  //       Teaching Certificate <br />
  //       Master’s in Education and Technology
  //     </div>
  //   ),
  //   experience: '13+ years teaching experience. Published 6 books and translated 2 IELTS textbooks.'
  // },
  // {
  //   page: 1,
  //   name: 'Phantira Waranukrohchok (Kru Jip)',
  //   department: 'English',
  //   description: `While on her 2-year stint in New York, Phantira found her passion for teaching English to immigrants at a public library and the college she attended. She earned her teaching certificate in New York and later pursued a Master’s degree, with her research on teaching theories and learning behaviours of students in Thailand. Even with her extensive teaching experience, she continues to improve her teaching skills and has acquired more teaching certificates over the years to keep on improving her ability to help her students.`,
  //   bgColor: '#EFF5FB',
  //   image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+.jpg',
  //   education: (
  //     <div>
  //       Teaching Certificate <br />
  //       Master’s in Education and Technology
  //     </div>
  //   ),
  //   experience: '13+ years teaching experience. Published 6 books and translated 2 IELTS textbooks.'
  // },
  // {
  //   page: 1,
  //   name: 'Phantira Waranukrohchok (Kru Jip)',
  //   department: 'English',
  //   description: `While on her 2-year stint in New York, Phantira found her passion for teaching English to immigrants at a public library and the college she attended. She earned her teaching certificate in New York and later pursued a Master’s degree, with her research on teaching theories and learning behaviours of students in Thailand. Even with her extensive teaching experience, she continues to improve her teaching skills and has acquired more teaching certificates over the years to keep on improving her ability to help her students.`,
  //   bgColor: '#EFF5FB',
  //   image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+.jpg',
  //   education: (
  //     <div>
  //       Teaching Certificate <br />
  //       Master’s in Education and Technology
  //     </div>
  //   ),
  //   experience: '13+ years teaching experience. Published 6 books and translated 2 IELTS textbooks.'
  // },
  // {
  //   page: 1,
  //   name: 'Phantira Waranukrohchok (Kru Jip)',
  //   department: 'English',
  //   description: `While on her 2-year stint in New York, Phantira found her passion for teaching English to immigrants at a public library and the college she attended. She earned her teaching certificate in New York and later pursued a Master’s degree, with her research on teaching theories and learning behaviours of students in Thailand. Even with her extensive teaching experience, she continues to improve her teaching skills and has acquired more teaching certificates over the years to keep on improving her ability to help her students.`,
  //   bgColor: '#EFF5FB',
  //   image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+.jpg',
  //   education: (
  //     <div>
  //       Teaching Certificate <br />
  //       Master’s in Education and Technology
  //     </div>
  //   ),
  //   experience: '13+ years teaching experience. Published 6 books and translated 2 IELTS textbooks.'
  // }
]

const TeamGallery = [
  { src: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/team-gallery-1.jpg' },
  { src: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/team-gallery-2.jpg' },
  { src: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/team-gallery-3.jpg' },
  { src: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/team-gallery-4.jpg' }
]
