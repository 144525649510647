import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { isMobileOnly } from 'react-device-detect'
import { Carousel } from 'react-responsive-carousel'
import { CircularProgress } from '@material-ui/core'
import agent from 'agent'
import { GET_MATCHING_CLASSES } from 'constants/actionTypes'
import { ONLINE_CLASS_DETAILS, CLASS_DETAILS } from 'routes/routes'
import CatalogueItemCard from 'pages/common/online-class/catalogue/item-card'

const mapStateToProps = state => ({
  matchingClasses: state.onlineClass.matchingClasses
})

const mapDispatchToProps = dispatch => ({
  getFeaturedClasses: query => dispatch({ type: GET_MATCHING_CLASSES, payload: agent.OnlineClass.getList(query) })
})

class FeaturedClassesCarousel extends React.Component {
  componentDidMount() {
    this.props.getFeaturedClasses({
      is_featured: true
    })
  }

  scrollNext = () => {
    let elmnt = document.getElementById('featured-classes-carousel')
    if (elmnt) {
      elmnt.scrollBy(300, 0)
    }
  }

  scrollBack = () => {
    let elmnt = document.getElementById('featured-classes-carousel')
    if (elmnt) {
      elmnt.scrollBy(-300, 0)
    }
  }

  viewOnlineClass = onlineClass => {
    if (this.props.token) {
      this.props.history.push(ONLINE_CLASS_DETAILS.replace(':classId', onlineClass._id))
    } else {
      this.props.history.push(CLASS_DETAILS.replace(':classId', onlineClass._id))
    }
  }

  render() {
    const { loadingEventList, matchingClasses } = this.props

    if (loadingEventList) {
      return (
        <div className="text-center my-3">
          <CircularProgress size={25} />
        </div>
      )
    }

    if (isMobileOnly) {
      return (
        <Carousel
          showThumbs={false}
          showIndicators={false}
          showArrows={false}
          showStatus={false}
          centerMode={true}
          centerSlidePercentage={90}>
          {matchingClasses?.map((classData, index) => (
            <CatalogueItemCard
              key={index}
              classData={classData}
              viewOnlineClass={() => this.viewOnlineClass(classData)}
            />
          ))}
        </Carousel>
      )
    }

    return (
      <div className="position-relative">
        <div className="carousel-container">
          <div className="carousel-slides" id="featured-classes-carousel">
            {matchingClasses?.map((classData, index) => (
              <CatalogueItemCard
                key={index}
                classData={classData}
                viewOnlineClass={() => this.viewOnlineClass(classData)}
              />
            ))}
          </div>
        </div>

        <div className="carousel-nav next">
          <img
            className="carousel-nav-icon"
            src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/carousel-next.png"
            alt="next"
            onClick={this.scrollNext}
          />
        </div>
        <div className="carousel-nav prev">
          <img
            className="carousel-nav-icon"
            src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/carousel-back.png"
            alt="prev"
            onClick={this.scrollBack}
          />
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FeaturedClassesCarousel))
