import React from 'react'
import Button from '@material-ui/core/Button'

import { Modal, ModalHeader, ModalBody } from 'reactstrap'

class DialogSubmitConfirmation extends React.Component {
  render() {
    let { dialogOpen } = this.props

    return (
      <Modal size="sm" centered={true} isOpen={dialogOpen} toggle={this.props.toggleDialog}>
        <ModalHeader toggle={this.props.toggleDialog} className={'border-bottom-0'}>
          Discard homework submission?
        </ModalHeader>
        <ModalBody>
          <div className="text-muted">
            You haven't complete your homework submission yet. Are you sure want to stop submitting?
          </div>
          <div className="mt-3 text-right">
            <Button variant="text" color="primary" onClick={this.props.toggleDialog}>
              No
            </Button>
            <Button variant="contained" className="bg-danger text-white ml-3" onClick={this.props.abortSubmission}>
              {' '}
              Yes
            </Button>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

export default DialogSubmitConfirmation
