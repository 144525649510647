import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars'
import { LOGOUT } from 'constants/actionTypes'
import { USER_NAVIGATION_ACCESS, STUDENT, TUTOR, PARENT } from 'constants/userTypes'
import { APP, REST_OF_CLASSES, ONLINE_CLASS_LIST, FAQ, USER_PROFILE, FREE_SHARING, TRIAL } from 'routes/routes'
import {
  TRANSACTION_HISTORY,
  MY_PAID_CLASSES,
  MY_TRIAL_CLASSES,
  MY_ARCHIVED_CLASSES,
  DASHBOARD
} from 'routes/studentRoutes'
import { NavItem, NavSubMenu, NavSubmenuItem } from './menu-components'
import ReferFriendDialog from 'components/dialog/refer-friend'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser
})

const mapDispatchToProps = dispatch => ({
  userSignOut: () => dispatch({ type: LOGOUT })
})

class SidenavContent extends Component {
  goToPage = location => () => {
    this.props.history.push(location)
  }

  render() {
    const { currentUser } = this.props
    const userType = currentUser?._type ?? ''
    const userAccess = userType ? USER_NAVIGATION_ACCESS[userType] : []

    return (
      <Scrollbars style={{ height: '100%' }}>
        <ul className="nav-menu">
          {/* {userAccess.includes('dashboard') && (
            <NavItem to={`${APP}${DASHBOARD}`}>
              <i className="zmdi zmdi-home zmdi-hc-fw mr-2" />
              <span className="nav-text">Dashboard</span>
            </NavItem>
          )} */}

          {userAccess.includes('my-class') && (
            <NavSubMenu
              title="My Classes"
              icon="zmdi zmdi-graduation-cap zmdi-hc-fw mr-2"
              keyword="my-class"
              location={this.props.location.pathname}
              goToPage={this.goToPage(`${APP}${MY_PAID_CLASSES}`)}>
              <NavSubmenuItem to={`${APP}${MY_PAID_CLASSES}`}>
                <span className="nav-text">Ongoing</span>
              </NavSubmenuItem>

              <NavSubmenuItem to={`${APP}${MY_ARCHIVED_CLASSES}`}>
                <span className="nav-text">All Classes</span>
              </NavSubmenuItem>
            </NavSubMenu>
          )}

          {userType === TUTOR && (
            <NavItem to={REST_OF_CLASSES}>
              <i className="zmdi zmdi-local-mall zmdi-hc-fw mr-2" />
              <span className="nav-text">Previous classes</span>
            </NavItem>
          )}

          {userType === PARENT && (
            <>
              <NavItem to={REST_OF_CLASSES}>
                <i className="zmdi zmdi-home zmdi-hc-fw mr-2" />
                <span className="nav-text">Child Class</span>
              </NavItem>

              <NavItem to={`${APP}`}>
                <i className="zmdi zmdi-home zmdi-hc-fw mr-2" />
                <span className="nav-text">Child Profile</span>
              </NavItem>
            </>
          )}

          {/* {userAccess.includes('online-class') && (
            <NavItem to={ONLINE_CLASS_LIST}>
              <i className="zmdi zmdi-local-mall zmdi-hc-fw mr-2" />
              <span className="nav-text">Class Catalogue</span>
            </NavItem>
          )} */}

          {userType === STUDENT && (
            <>
              {/* <NavItem to={`${APP}${TRIAL}`}>
                <i className="zmdi zmdi-star zmdi-hc-fw mr-2" />
                <span className="nav-text">2022 Trial</span>
              </NavItem>  */}
              {/* <NavItem to={`${APP}${BOOK_CLUB}`}>
                <i className="zmdi zmdi-book zmdi-hc-fw mr-2" />
                <span className="nav-text">Book Club</span>
              </NavItem> */}
              {/* <NavItem to={`${APP}${FREE_SHARING}`}>
                <i className="zmdi zmdi-local-mall zmdi-hc-fw mr-2" />
                <span className="nav-text">Sharing By Experts</span>
              </NavItem> */}
            </>
          )}

          {/* <li className="p-3">
            <ReferFriendDialog />
          </li> */}

          <li className="nav-header">System</li>
          {/* {process.env.REACT_APP_REGION === 'SG' && (
            <NavItem to={`${APP}${FAQ}`}>
              <i className="zmdi zmdi-help zmdi-hc-fw mr-2" />
              <span className="nav-text">FAQ</span>
            </NavItem>
          )} */}
          {/* 
          {userAccess.includes('transaction') && (
            <NavItem to={TRANSACTION_HISTORY}>
              <i className="zmdi zmdi-money zmdi-hc-fw mr-2" />
              <span className="nav-text">Transaction history</span>
            </NavItem>
          )} */}

          {userAccess.includes('profile') && (
            <NavItem to={USER_PROFILE}>
              <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
              <span className="nav-text">Profile</span>
            </NavItem>
          )}

          <NavItem
            to="#"
            onClick={() => {
              this.props.userSignOut()
            }}>
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
            <span className="nav-text">Logout</span>
          </NavItem>
        </ul>
      </Scrollbars>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidenavContent))
