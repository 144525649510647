import React from 'react'
import { CloudDownload as CloudDownloadIcon } from '@material-ui/icons'

const download = url => e => {
  e.stopPropagation()
  const link = document.createElement('a')
  link.href = url
  link.click()
}

const FileWidget = props => {
  return (
    <div
      className="row align-items-center clickable mb-1"
      onClick={download(props.fileUrl)}
      style={{
        border: '1px solid #9b51e0',
        borderRadius: '5px',
        padding: '2px 7px',
        color: '#9b51e0',
        maxWidth: '500px'
      }}>
      <div className="col" style={{ overflowWrap: 'break-word' }}>
        {props.fileName}
      </div>
      <div className="col-auto">
        <CloudDownloadIcon />
      </div>
    </div>
  )
}

export default FileWidget
