import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import common from 'reducers/common'
import auth from 'reducers/auth'
import settings from 'reducers/settings'
import classes from 'reducers/classes'
import lessons from 'reducers/lessons'
import videos from 'reducers/videos'
import resources from 'reducers/resources'
import misc from 'reducers/misc'
import onlineClass from 'reducers/onlineClass'
import feedback from 'reducers/feedback'
import order from 'reducers/order'
import shoppingCart from 'reducers/cart'
import event from 'reducers/event'
import homework from 'reducers/homework'
import submission from 'reducers/submission'
import info from 'reducers/info'
import material from 'reducers/material'
import ticket from 'reducers/ticket'
import report from 'reducers/report'
import activity from 'reducers/activity'
import newsandevents from 'reducers/newsandevents'

export default combineReducers({
  common,
  auth,
  settings,
  classes,
  lessons,
  videos,
  resources,
  misc,
  onlineClass,
  feedback,
  order,
  shoppingCart,
  event,
  homework,
  submission,
  info,
  material,
  ticket,
  report,
  activity,
  newsandevents,
  router: routerReducer
})
