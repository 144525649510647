import * as Type from 'constants/actionTypes'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'

const defaultState = {
  requestingPasswordReset: false,
  resettingPassword: false,
  resetStatus: ''
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case Type.REGISTER_UNLOAD:
      return {
        ...state,
        errors: null,
        loginUser: false,
        submitted: false
      }

    case Type.REGISTER:
    case Type.LOGIN:
      return {
        ...state,
        inProgress: true,
        error: false,
        errors: null,
        loginUser: false,
        payload: null
      }

    case Type.REGISTER_SUCCESS: {
      let userdata = action.payload.data
      ReactPixel.track('CompleteRegistration', { email: userdata.email, name: userdata.name })
      ReactGA.event({
        category: 'User',
        action: 'CompleteRegistration'
      })
      window.localStorage.removeItem('_utm')

      return {
        ...state,
        loginUser: true,
        inProgress: false,
        payload: action.payload
      }
    }

    case Type.LOGIN_FAILED:
    case Type.REGISTER_FAILED:
      return {
        ...state,
        inProgress: false,
        errors: action.payload,
        loginUser: false
      }

    case Type.LOGIN_SUCCESS:
      return {
        ...state,
        inProgress: false
      }

    case Type.REQUEST_PASSWORD_RESET:
      return {
        ...state,
        requestingPasswordReset: true
      }

    case Type.REQUEST_PASSWORD_RESET_SUCCESS:
    case Type.REQUEST_PASSWORD_RESET_FAILED:
      return {
        ...state,
        requestingPasswordReset: false
      }

    case Type.RESET_PASSWORD:
      return {
        ...state,
        resettingPassword: true,
        resetStatus: ''
      }

    case Type.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resettingPassword: false,
        resetStatus: 'success'
      }

    case Type.RESET_PASSWORD_FAILED:
      return {
        ...state,
        resettingPassword: false,
        resetStatus: 'failed'
      }

    case Type.CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordInProgress: true
      }

    case Type.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordInProgress: false
      }

    case Type.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        changePasswordInProgress: false
      }

    case Type.DETECT_USER:
      return {
        ...state,
        detectingUser: true,
        userVerified: false
      }

    case Type.DETECT_USER_SUCCESS:
    case Type.DETECT_USER_FAILED:
      return {
        ...state,
        detectingUser: false
      }

    case Type.GET_TRIAL_AVAILABILITY:
      return {
        ...state,
        checkingTrialAvailability: true,
        serviceType: action.serviceType,
        trialAvailable: null
      }

    case Type.GET_TRIAL_AVAILABILITY_SUCCESS:
      return {
        ...state,
        checkingTrialAvailability: false,
        trialAvailable: true
      }

    case Type.GET_TRIAL_AVAILABILITY_FAILED:
      return {
        ...state,
        checkingTrialAvailability: false,
        trialAvailable: false
      }

    case Type.REQUEST_TRIAL_SERVICE:
      return {
        ...state,
        requestingTrialService: true,
        trials: null,
        joinTrialStatus: null
      }

    case Type.REQUEST_TRIAL_SERVICE_SUCCESS:
      return {
        ...state,
        requestingTrialService: false,
        trials: action.payload.data
      }

    case Type.JOIN_TRIAL_SERVICE:
      return {
        ...state,
        joiningTrialService: true,
        joinTrialStatus: null
      }

    case Type.JOIN_TRIAL_SERVICE_SUCCESS: {
      ReactGA.event({
        category: 'Trial',
        action: 'Join Trial'
      })
      ReactPixel.track('StartTrial', { value: '0.00', currency: 'SGD' })

      return {
        ...state,
        joiningTrialService: false,
        joinTrialStatus: 'Success'
      }
    }

    case Type.JOIN_TRIAL_SERVICE_FAILED:
      return {
        ...state,
        joiningTrialService: false,
        joiningTrialStatus: 'Failed'
      }

    case Type.VALIDATE_REFERRAL_CODE:
      return {
        ...state,
        validatingReferralCode: true,
        validatedReferralCode: '',
        isValidReferralCode: false
      }

    case Type.VALIDATE_REFERRAL_CODE_SUCCESS:
      return {
        ...state,
        validatingReferralCode: false,
        validatedReferralCode: action.payload.data.referral_code,
        isValidReferralCode: true
      }

    case Type.VALIDATE_REFERRAL_CODE_FAILED:
      return {
        ...state,
        validatingReferralCode: false,
        validatedReferralCode: action.payload.referral_code,
        isValidReferralCode: false
      }

    case Type.REQUEST_PHONE_VERIFICATION:
      return {
        ...state,
        requestingPhoneVerification: true,
        verificationId: ''
      }

    case Type.REQUEST_PHONE_VERIFICATION_SUCCESS:
      return {
        ...state,
        requestingPhoneVerification: false,
        verificationId: action.payload.data.id
      }

    case Type.REQUEST_PHONE_VERIFICATION_FAILED:
      return {
        ...state,
        requestingPhoneVerification: false,
        verificationId: ''
      }

    case Type.VERIFY_PHONE_NUMBER:
      return {
        ...state,
        verifyingPhone: true
      }

    case Type.VERIFY_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        verifyingPhone: false
      }

    case Type.VERIFY_PHONE_NUMBER_FAILED:
      return {
        ...state,
        verifyingPhone: false,
        verificationId: ''
      }

    case Type.RESET_PHONE_VERIFICATION:
      return {
        ...state,
        verifyingPhone: false,
        requestingPhoneVerification: false,
        verificationId: ''
      }

    default:
      return state
  }
}
