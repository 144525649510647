import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import agent from '../../../../agent'
import { Avatar } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ONLINE_CLASS_LIST, TRIAL, APP, MY_CLASS_DETAILS, SHOPPING_CART } from '../../../../routes/routes'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {
  ADD_CART_ITEM,
  ADD_CART_ITEM_NOAUTH,
  CALC_PACKAGE_QUOTE,
  GET_PACKAGE_QUOTE,
  CLEAR_PACKAGE_QUOTE,
  GET_ONLINE_CLASS_LIST
} from '../../../../constants/actionTypes'
import _ from 'lodash'
import moment from 'moment'
import { OnlineClassSummary, getClassData } from '../../../../utils/misc'
import { roundPrice } from '../../../../utils/merchandise'
import { formatCartItem, calculateQuote } from 'utils/cart'
import { CLASS_TYPE_REGULAR_CLASSES, CLASS_TYPE_WORKSHOP } from '../../../../constants/generalTypes'
import VisibilitySensor from 'react-visibility-sensor'
import ClassExperience from 'components/misc/info-class-experience'
import SuperiorMethodology from 'components/misc/info-superior-methodology'
import Testimonials from 'components/misc/testimonial-carousel'
import DialogProceedToCheckout from 'components/online-class/dialog-proceed-to-checkout'

const mapStateToProps = state => ({
  token: state.common.token,
  onlineClassList: state.onlineClass.onlineClassList,
  contactSuccess: state.misc.contacted,
  lessonPackages: state.shoppingCart.lessonPackages,
  shoppingCart: state.shoppingCart.shoppingCart,
  userClassWithDetail: state.common.userClassWithDetail
})

const mapDispatchToProps = dispatch => ({
  getClassList: () => dispatch({ type: GET_ONLINE_CLASS_LIST, payload: agent.OnlineClass.getList() }),
  addToCart: item => dispatch({ type: ADD_CART_ITEM, payload: agent.ShoppingCart.addItem(item), cartItem: item }),
  addToLocalCart: item => dispatch({ type: ADD_CART_ITEM_NOAUTH, payload: item }),
  calcPackageQuote: (quote, packageItem) => dispatch({ type: CALC_PACKAGE_QUOTE, payload: quote, packageItem }),
  getPackageQuote: (payload, packageItem) =>
    dispatch({ type: GET_PACKAGE_QUOTE, payload: agent.OnlineClass.getQuote(payload), packageItem: packageItem }),
  clearPackageQuote: (class_id, component_id) =>
    dispatch({ type: CLEAR_PACKAGE_QUOTE, class_id: class_id, component: component_id })
})

class VariantA extends React.Component {
  state = {
    classData: null,
    lessonsIncluded: [],
    lessonPackage: null,
    addedToCart: false,
    isProceedToCheckoutDialogOpen: false,
    bannerVisible: true
  }

  componentDidMount() {
    if (!this.props.onlineClassList || this.props.onlineClassList.length === 0) {
      this.props.getClassList()
    } else {
      this.filterOnlineClass()
    }
  }

  componentWillUnmount() {
    this.props.clearPackageQuote(this.state.promoClassId)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.onlineClassList !== this.props.onlineClassList) {
      this.filterOnlineClass()
    }

    if (prevProps.match.params.classId !== this.props.match.params.classId) {
      this.filterOnlineClass()
      this.checkCartStatus()
    }

    if (prevProps.shoppingCart !== this.props.shoppingCart) {
      this.checkCartStatus()
    }

    if (
      prevProps.lessonPackages !== this.props.lessonPackages &&
      this.props.lessonPackages[this.props.match.params.classId]
    ) {
      let lessonPackage = this.props.lessonPackages[this.props.match.params.classId].item[0]
      this.setState({ lessonPackage })
    }
  }

  filterOnlineClass = () => {
    let { onlineClassList, token, userClassWithDetail } = this.props
    let classId = this.props.match.params.classId

    if (!onlineClassList || !classId) {
      return
    }

    let classData = _.find(onlineClassList, function (o) {
      return o._id === classId
    })
    let sortedLessons = _.sortBy(classData.lesson, ['scheduled_on'])
    let lessonsIncluded = []
    let now = moment()
    let promoLessonCount = 4
    let regularClassTerm = 1
    if (classData.class_type === CLASS_TYPE_WORKSHOP) {
      promoLessonCount = sortedLessons.length
    } else {
      let nextLesson = _.find(sortedLessons, o => {
        return moment(o.scheduled_on) > now
      })
      regularClassTerm = nextLesson ? nextLesson.meta.term : sortedLessons[sortedLessons.length - 1].meta.term
      promoLessonCount = _.filter(sortedLessons, o => {
        return o.meta.term === regularClassTerm
      }).length
    }

    let userLessonIds = []
    if (token && userClassWithDetail && userClassWithDetail[classId]) {
      userLessonIds = userClassWithDetail[classId].lesson
    }

    for (let i = 0; i < sortedLessons.length; i++) {
      if (lessonsIncluded.length < promoLessonCount && !userLessonIds.includes(sortedLessons[i]._id)) {
        if (classData.class_type === CLASS_TYPE_REGULAR_CLASSES) {
          if (moment(sortedLessons[i].scheduled_on) > now) {
            lessonsIncluded.push(sortedLessons[i])
          }
        } else {
          lessonsIncluded.push(sortedLessons[i])
        }
      }
    }

    if (lessonsIncluded.length > 0) {
      let packageItem = {
        class_id: classData._id,
        count: lessonsIncluded.length,
        options: {
          start_date: lessonsIncluded[0].scheduled_on,
          end_date: lessonsIncluded[lessonsIncluded.length - 1].scheduled_on
        },
        rate: classData.rate,
        title: classData.name
      }

      if (token) {
        this.props.getPackageQuote(
          {
            class_id: classData._id,
            start_date: lessonsIncluded[0].scheduled_on,
            end_date: lessonsIncluded[lessonsIncluded.length - 1].scheduled_on
          },
          packageItem
        )
      } else {
        let quote = calculateQuote(
          lessonsIncluded,
          classData.rate,
          lessonsIncluded[0].scheduled_on,
          lessonsIncluded[lessonsIncluded.length - 1].scheduled_on
        )
        this.props.calcPackageQuote({ data: quote }, packageItem)
      }
    }

    this.setState({ classData, lessonsIncluded })
  }

  addToCart = () => {
    let { classData, lessonPackage } = this.state

    let formattedItem = formatCartItem(classData, 'Class', lessonPackage.packageItem.options)
    if (!!this.props.token) {
      this.props.addToCart(formattedItem)
    } else {
      this.props.addToLocalCart(formattedItem)
    }
    this.toggleProceedToCheckoutDialog()
  }

  checkCartStatus = () => {
    if (!this.state.classData) return

    let addedToCart = _.find(this.props.shoppingCart.item, ci => {
      return ci.item_id === this.state.classData._id
    })
    this.setState({ addedToCart })
  }

  goToCart = () => {
    this.toggleProceedToCheckoutDialog()
    let redirectUrl = this.props.token ? `${APP}${SHOPPING_CART}` : SHOPPING_CART
    this.props.history.push(redirectUrl)
  }

  goToClass = () => {
    this.props.history.push(MY_CLASS_DETAILS.replace(':classId', this.state.classData._id))
  }

  toggleProceedToCheckoutDialog = () => {
    this.setState({ isProceedToCheckoutDialogOpen: !this.state.isProceedToCheckoutDialogOpen })
  }

  bannerVisibilityChange = isVisible => {
    this.setState({ bannerVisible: isVisible })
  }

  goToTrialPage = () => {
    this.props.history.push(`${TRIAL}?level=secondary-1`)
  }

  render() {
    let { token } = this.props
    let { classData, lessonsIncluded, addedToCart, isProceedToCheckoutDialogOpen, bannerVisible } = this.state
    let processedData
    if (classData) {
      processedData = getClassData(
        classData.class_type,
        classData.lesson,
        classData.rate,
        classData.quote,
        classData._id
      )
    }

    if (!classData) {
      return (
        <div className="manage-margin text-center my-5">
          <CircularProgress size={50} />
        </div>
      )
    }

    return (
      <div className="landing">
        <Helmet>
          <title>{classData.name} | Tenopy</title>
          <meta name="description" content={`Sign up for ${classData.name} class with Tenopy`} />
          <meta
            name="keywords"
            content="Online tuition,Math tuition,English tuition,Secondary school tuition,Primary school tuition,Online tuition singapore,Online learning,Online education,Education platform,Online tutor,Home tutor,home tuition,science tuition,sa1,sa2,ca1,ca2,psle,revision,online revision,online study,online learning,psle revision"
          />
        </Helmet>

        {!bannerVisible && (
          <div className="promo__CTA--sticky">
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <div className="d-flex flex-row align-items-center">
                    <Avatar
                      className="size-60 avatar-shadow mx-auto my-2 pointer"
                      alt={classData.tutor[0].name}
                      src={classData.tutor[0].profile_pic}
                    />
                    <div className="ml-2">
                      <h3 className="font-weight-semibold mb-1">{classData.name}</h3>
                      <div className="d-flex flex-row">
                        <small className="mr-2">by</small>
                        {classData.tutor[0].name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  {lessonsIncluded.length > 0 ? (
                    addedToCart ? (
                      <Button className="my-3" variant="outlined" color="primary" onClick={this.goToCart}>
                        Checkout
                      </Button>
                    ) : (
                      <div className="text-center">
                        <Button className="mt-3 mb-2" variant="contained" color="primary" onClick={this.addToCart}>
                          Add to Cart
                        </Button>
                        {classData.is_trial && (
                          <div className="link mb-2" onClick={this.goToTrialPage}>
                            Join Trial
                          </div>
                        )}
                      </div>
                    )
                  ) : (
                    token && (
                      <Button className="my-3" variant="contained" color="primary" onClick={this.goToClass}>
                        View Class
                      </Button>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <VisibilitySensor onChange={this.bannerVisibilityChange} partialVisibility={true}>
          <section className="variant-A">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-12">
                  <p className="d-flex align-items-center mb-0" style={{ color: '#8e51f0' }}>
                    <i className="material-icons mr-2">star</i>{' '}
                    <span className="font-weight-semibold" style={{ paddingTop: '4px' }}>
                      Recommended
                    </span>
                  </p>
                  <h1 className="title--rubik font-weight-normal">{classData.name}</h1>
                  <div dangerouslySetInnerHTML={{ __html: classData.desc }} className="text-justify" />
                </div>
                <div className="col-md-4 col-12 mobile-view" style={{ zIndex: '10' }}>
                  <div className="catalogue__item">
                    <div className="catalogue__tutor">
                      <img src={classData.tutor[0].profile_pic} alt={classData.tutor[0].name} className="img-fluid" />
                    </div>
                    <div className="catalogue__text pointer">
                      <div className="class__title">
                        <h2 className="mb-1">{classData.name}</h2>
                        <div className="d-flex flex-row justify-content-end">
                          <small className="mr-2">by</small>
                          <h3 className="mb-0">{classData.tutor[0].name}</h3>
                        </div>
                      </div>
                      <div className="mt-auto class__summary">
                        <OnlineClassSummary
                          classType={classData.class_type}
                          startDate={classData.lesson[0].scheduled_on}
                          endDate={classData.lesson[classData.lesson.length - 1].scheduled_on}
                          lessonStartTime={classData.schedule.start_time}
                          lessonEndTime={classData.schedule.end_time}
                          tutor={classData.tutor[0]}
                        />
                      </div>
                    </div>
                    <div className="catalogue__footer pointer pt-3 px-3">
                      <div className="row justify-content-end align-items-center">
                        <div className="col">
                          {lessonsIncluded.length > 0 ? (
                            addedToCart ? (
                              <Button
                                className="my-3"
                                variant="outlined"
                                color="primary"
                                fullWidth={true}
                                size="large"
                                onClick={this.goToCart}>
                                Checkout
                              </Button>
                            ) : (
                              <div className="text-center">
                                <Button
                                  className="mt-3 mb-2"
                                  variant="contained"
                                  color="primary"
                                  fullWidth={true}
                                  size="large"
                                  onClick={this.addToCart}>
                                  Add to Cart
                                </Button>
                                {classData.is_trial && (
                                  <span className="link" onClick={this.goToTrialPage}>
                                    Join Trial
                                  </span>
                                )}
                              </div>
                            )
                          ) : (
                            token && (
                              <Button
                                className="my-3"
                                variant="contained"
                                color="primary"
                                fullWidth={true}
                                size="large"
                                onClick={this.goToClass}>
                                View Class
                              </Button>
                            )
                          )}
                        </div>
                        <div className="col-auto text-center">
                          <div className="class__price d-flex align-items-center">
                            <h1 className="text-primary mb-0">$ {roundPrice(processedData.price)}</h1>
                            {processedData.discount > 0 && (
                              <del className="text-danger ml-1">
                                <small className="text-muted">
                                  ${roundPrice(processedData.price + processedData.discount)}
                                </small>
                              </del>
                            )}
                          </div>
                          <small>
                            {classData.class_type === CLASS_TYPE_WORKSHOP ? (
                              <React.Fragment>
                                for <span className="font-weight-semibold">{processedData.lessonCount}</span> lessons
                              </React.Fragment>
                            ) : (
                              <React.Fragment>per lesson</React.Fragment>
                            )}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </VisibilitySensor>

        <section style={{ backgroundColor: '#ffffff', paddingTop: '30px' }}>
          <div className="container">
            <div className="section__header mb-5">
              <div
                className="title mb-2"
                style={{ fontSize: '2.3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif", lineHeight: 1.2 }}>
                Lessons Schedule
              </div>
              <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #3f84f2' }}></div>
            </div>

            {lessonsIncluded.map((lesson, index) => (
              <div
                key={index}
                className="mb-2"
                style={{ backgroundColor: '#f9f9f9', border: '1px solid #dedfe0', padding: '10px 15px' }}>
                <div className="mb-2">
                  <i className="fa fa-check mr-2" />
                  <span style={{ fontSize: '20px' }}>{lesson.name}</span>
                </div>
                <div className="d-flex align-items-center  px-3 mb-1 text-muted">
                  <i className="material-icons mx-2">event</i>
                  {moment(lesson.scheduled_on).format('dddd, MMMM Do YYYY')}
                </div>
                <div className="d-flex align-items-center  px-3 text-muted">
                  <i className="material-icons mx-2">access_time</i>
                  {moment(lesson.scheduled_on).format('h:mm a')} to {moment(lesson.ended_on).format('h:mm a')}
                </div>
              </div>
            ))}
          </div>
        </section>

        <ClassExperience />
        <hr />
        <SuperiorMethodology />

        <section className="bg__dotted">
          <div className="container">
            <div className="section__header mb-5">
              <div
                className="title mb-2"
                style={{ fontSize: '2.3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif" }}>
                What they said
              </div>
              <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #8089ff' }}></div>
            </div>
            <Testimonials />
          </div>
        </section>

        <section className="bg-light-gray">
          <div className="container">
            <div className="section__header mb-5">
              <div
                className="title mb-2"
                style={{ fontSize: '2.3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif", lineHeight: 1.2 }}>
                FAQs
              </div>
              <div className="mx-auto" style={{ maxWidth: '60px', borderBottom: '3px solid #8e51f0' }}></div>
            </div>

            <ExpansionPanel defaultExpanded>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">What is Tenopy?</span>
              </ExpansionPanelSummary>
              <Divider />
              <ExpansionPanelDetails>
                Tenopy is a live online tutoring platform for students to learn from top tutors, through live
                interactive classes, from the comforts of home. Students can also watch the video playback of their
                lessons. It’s flexible, hassle-free and convenient, as students can learn from the comfort of their
                homes, anytime.
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">How do you interact with the tutor during lesson?</span>
              </ExpansionPanelSummary>
              <Divider />
              <ExpansionPanelDetails>
                During a live online class, students can see the tutor teaching, participate in quizzes and ask
                questions in real time via chat box. During a live online class, students can see the tutor teaching,
                participate in quizzes and ask questions in real time via chat box. The tutor will also enable the
                students’ microphone to allow them to speak during class when the student clicks “raise hand”.
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">Do you have your own curriculum?</span>
              </ExpansionPanelSummary>
              <Divider />
              <ExpansionPanelDetails>
                Yes, our curriculum is developed based on Singapore’s syllabus. We are also partnering with several
                established education providers to bring their content and expertise to our platform. Find out about our
                class schedules and details <Link to={ONLINE_CLASS_LIST}>here</Link>.
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">What classes do you currently offer?</span>
              </ExpansionPanelSummary>
              <Divider />
              <ExpansionPanelDetails>
                Tenopy currently offers English, Mathematics and Science subjects for Primary 3 to Primary 6. Keep a
                lookout for our future announcement for more classes.
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">Do you give lesson notes and homework?</span>
              </ExpansionPanelSummary>
              <Divider />
              <ExpansionPanelDetails>
                Yes, lesson notes and homework will be uploaded by our tutors at least 2 days prior to the scheduled
                lesson. Students can also submit Tenopy homework to get tutors’ feedback on our platform.
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">How do I keep track of my child’s learning progress?</span>
              </ExpansionPanelSummary>
              <Divider />
              <ExpansionPanelDetails>
                <div>
                  <p>
                    We will be giving a weekly Parent Report. This report is meant to provide you with information
                    regarding your child in several ways:
                  </p>
                  <ul>
                    <li>
                      Attendance: The report reflects whether your child has been attending lessons as expected for the
                      full duration of the lesson. You may ignore this if your child is watching the replay of lessons
                      due to his/her schedule.
                    </li>
                    <li>
                      Attention: The report reflects whether your child has been active and responsive during lessons by
                      answering the quiz questions.
                    </li>
                    <li>
                      Understanding: The report reflects areas of understanding that your child may need more guidance
                      or revision in. Please note, however, that some quiz questions are deliberately designed to be
                      especially tricky for students.
                    </li>
                  </ul>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">What is a free trial?</span>
              </ExpansionPanelSummary>
              <Divider />
              <ExpansionPanelDetails>
                Join a free trial lesson to see what online tutoring is like. This is a great way for you to check out a
                class or a tutor you’re keen on. You may join up to one free trial lesson per class, unless otherwise
                specified. Sign up for a free trial <Link to={TRIAL}>here</Link>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">What do I need to attend Tenopy lesson?</span>
              </ExpansionPanelSummary>
              <Divider />
              <ExpansionPanelDetails>
                <div>
                  <p>All you need to join Tenopy lessons is your personal computer and earphones with a microphone.</p>
                  <p>
                    You can also join lessons with your mobile phone, but you will be prompted to download and run a
                    companion app. Please follow the on-screen instructions to install this app. However, we suggest
                    having lessons on computer as students may absorb knowledge better with a bigger screen.
                  </p>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </section>

        {/*<section style={{paddingTop: "40px", paddingBottom: "50px"}}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col">
                <h1 className="text-primary font-weight-normal mb-0">Ready to start tutoring with Tenopy?</h1>
                <span style={{fontSize: "18px"}}>Create a free account or get in touch.</span>
              </div>
              <div className="col-auto">
                <Button variant="contained" color="primary" className="mr-2">Get Started</Button>
                <Button variant="outlined" color="primary">Get in Touch</Button>
              </div>
            </div>
          </div>
        </section>*/}

        <DialogProceedToCheckout
          isOpen={isProceedToCheckoutDialogOpen}
          toggleDialog={this.toggleProceedToCheckoutDialog}
          proceedToCheckout={this.goToCart}
          classTitle={classData.name || ''}
          lessonsIncluded={lessonsIncluded}
        />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VariantA))
