import React from 'react'
import Proptypes from 'prop-types'
import { OXKIDS_COLLABORATION } from 'constants/generalTypes'

const OxkidsCollaborated = props => {
  const { classId } = props

  if (OXKIDS_COLLABORATION.includes(classId)) {
    return (
      <div className="d-flex align-items-center">
        <div className="text-muted">Jointly presented with</div>
        <img
          src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/partner/oxkids-logo.png"
          alt="oxkids"
          height="30px"
          width="auto"
          style={{ marginBottom: '4px' }}
        />
      </div>
    )
  }

  return null
}

OxkidsCollaborated.propTypes = {
  classId: Proptypes.string
}

export default OxkidsCollaborated
