import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { CONTACT } from 'routes/routes'
import { Paper } from '@material-ui/core'

class FeaturedCard extends React.Component {
  render() {
    const { title, description, date, link, type, fontFamily, fontFamilyDesc, long } = this.props
    return (
      <>
        <div className="col-lg-12 white-bg mt-4 py-3 pl-2 pr-4 row justify-content-around">
          <div className={'col-lg-11 col-10 col-md-11'}>
            <div className="description" style={{ fontFamily: fontFamily ? fontFamily : '' }}>
              {title}
            </div>
            {!long && (
              <>
                <div
                  className="explanation"
                  style={{ color: '#00000080', fontFamily: fontFamilyDesc ? fontFamilyDesc : '' }}>
                  {description && description}
                </div>
                <div className="explanation" style={{ color: '#FD6502' }}>
                  {date && date}
                </div>
              </>
            )}
          </div>

          <div className="col-lg-1 col-2 col-md-1">
            {link && (
              <a href={link} target="_blank" rel="noreferrer">
                <img
                  src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/arrow_up_right.svg"
                  style={{ marginRight: '20px' }}></img>
              </a>
            )}
          </div>
          {long && (
            <div className={type === 2 ? 'ml-2' : 'ml-md-0 ml-2'}>
              <div
                className="explanation"
                style={{ color: '#00000080', fontFamily: fontFamilyDesc ? fontFamilyDesc : '' }}>
                {description && description}
              </div>
              <div className="explanation" style={{ color: '#FD6502' }}>
                {date && date}
              </div>
            </div>
          )}
        </div>
      </>
    )
  }
}

export default FeaturedCard
