import React from 'react'
import { CloudDownload as CloudDownloadIcon } from '@material-ui/icons'

const FileWidget = props => {
  const { activeTerm, classId } = props

  if (activeTerm?.toString() !== '1') {
    return null
  }

  let downloadURL = ''
  if (classId === 'YmD2KZdMTEeGGr0WoJeZxw') {
    downloadURL =
      'https://tenopy-lesson-resource.s3.ap-southeast-1.amazonaws.com/term-resources/2022/P3+English+HA+Term+1+Lesson+1+-+10.pdf'
  } else if (classId === 'bDKmxC0pQpynJ45HguK_GQ') {
    downloadURL =
      'https://tenopy-lesson-resource.s3.ap-southeast-1.amazonaws.com/term-resources/2022/P3+English+Paper+1+Term+1+Lesson+1+-++10.pdf'
  } else if (classId === 'iL9T-xCfQpGU_sSr-QqErg' || classId === '3OGbSnlWRA2IJEdLbcc5Yw') {
    downloadURL =
      'https://tenopy-lesson-resource.s3.ap-southeast-1.amazonaws.com/term-resources/2022/P3+Science+Term+1+Lesson+1+-+10.pdf'
  } else if (classId === 'I2tsFWGlSmqbuxE-vdXQaw') {
    downloadURL =
      'https://tenopy-lesson-resource.s3.ap-southeast-1.amazonaws.com/term-resources/2022/P4+English+HA+Term+1+Lesson+1+-+10.pdf'
  } else if (classId === 'b2F-t7xoSaW1Oc5nlayE_w' || classId === 'spKy-_GmRi-5ifvf1Bcz8w') {
    downloadURL =
      'https://tenopy-lesson-resource.s3.ap-southeast-1.amazonaws.com/term-resources/2022/P4+English+Paper+1+Term+1+Lesson+1+-+10.pdf'
  } else if (
    classId === 'igYioJp3RmeX5qOSRkCbLA' ||
    classId === 'ZsM6PYVMS9y8K27PsnWZiw' ||
    classId === 'Dw3ELL6mSeyIxD9d_mVNiA'
  ) {
    downloadURL =
      'https://tenopy-lesson-resource.s3.ap-southeast-1.amazonaws.com/term-resources/2022/P4+Science+Term+1+Lesson+1+-+10.pdf'
  } else if (classId === 'q3Q8MAEdROCLEY1OhkpS8g') {
    downloadURL =
      'https://tenopy-lesson-resource.s3.ap-southeast-1.amazonaws.com/term-resources/2022/P5+English+HA+Term+1+Lesson+1+-+10.pdf'
  } else if (classId === 'o3yYYIsdTHOnHV-yg9sWVA' || classId === 'pUvqVXmLS2yJYxGULxwZHA') {
    downloadURL =
      'https://tenopy-lesson-resource.s3.ap-southeast-1.amazonaws.com/term-resources/2022/P5+English+Paper+1+Term+1+Lesson+1+-+10.pdf'
  } else if (classId === 'EltegP8yTSmkb5QeLNM4Pw') {
    downloadURL =
      'https://tenopy-lesson-resource.s3.ap-southeast-1.amazonaws.com/term-resources/2022/P5+English+Paper+2+Term+1+Lesson+1+-+10.pdf'
  } else if (
    classId === 'K7ULWZVkQOCBwjdbpe7L3A' ||
    classId === 'UVFGGhzkSpGJx5niJIckgQ' ||
    classId === 'meFTpS_lS92bGyFsKlh8sA'
  ) {
    downloadURL =
      'https://tenopy-lesson-resource.s3.ap-southeast-1.amazonaws.com/term-resources/2022/P5+Science+Term+1+Lesson+1+-+10.pdf'
  } else if (classId === 'KlBHvpmrTPG_m8vnwRClSQ' || classId === 'qv6VQL5sQ26lAGy5ZDDkhA') {
    downloadURL =
      'https://tenopy-lesson-resource.s3.ap-southeast-1.amazonaws.com/term-resources/2022/P6+English+Paper+1+Term+1+Lesson+1+-+10.pdf'
  } else if (classId === 'yDvTEbEJR4uVsmlPcImKhQ' || classId === 'MNbFDH-kS_inObJI0emeNQ') {
    downloadURL =
      'https://tenopy-lesson-resource.s3.ap-southeast-1.amazonaws.com/term-resources/2022/P6+English+Paper+2+Term+1+Lesson+1+-+10.pdf'
  } else if (
    classId === 'GtjK_SQxT4yP0qZy-rixZw' ||
    classId === 'mHkwzeq4SNqEy3lej_M5aw' ||
    classId === 'gHnU8N2sQlOo3WV9OTDpTQ' ||
    classId === '7YwsJs_pSX2JVRMekbE0nw'
  ) {
    downloadURL =
      'https://tenopy-lesson-resource.s3.ap-southeast-1.amazonaws.com/term-resources/2022/P6+Science+Term+1+Lesson+1+-+10.pdf'
  } else if (classId === 'G28WLK45S1C-CX-DZBi40w') {
    downloadURL =
      'https://tenopy-lesson-resource.s3.ap-southeast-1.amazonaws.com/term-resources/2022/Sec+1+English+Paper+1+Term+1+Lesson+1+-+10.pdf'
  } else if (classId === 'Fy0wwIlcQ068f5ZeZbNZTQ') {
    downloadURL =
      'https://s3.console.aws.amazon.com/s3/object/tenopy-lesson-resource?region=ap-southeast-1&prefix=term-resources/2022/Sec+1+English+Paper+2+Term+1+Lesson+1+-+10.pdf'
  } else if (classId === 'm-m6P6joTQuuKhuiweAoWQ') {
    downloadURL =
      'https://tenopy-lesson-resource.s3.ap-southeast-1.amazonaws.com/term-resources/2022/Sec+1+Mathematics+Term+1+Lesson+1+-+10.pdf'
  }

  if (!downloadURL) {
    return null
  }

  return (
    <div
      className="row align-items-center clickable mx-1 mb-4"
      onClick={() => {
        window.open(downloadURL, '_blank')
      }}
      style={{
        border: '1px solid #2196f3',
        borderRadius: '5px',
        padding: '2px 7px',
        color: 'rgb(13, 60, 97)',
        backgroundColor: 'rgb(232, 244, 253)'
      }}>
      <div className="col">Click here to download the resources for all 10 lessons of Term 1 in one go.</div>
      <div className="col-auto">
        <CloudDownloadIcon style={{ color: '#2196f3', marginTop: '5px' }} />
      </div>
    </div>
  )
}

export default FileWidget
