import React from 'react'
import { Link } from 'react-router-dom'
import { MailOutline as EmailIcon, Phone as PhoneIcon, Business as AddressIcon } from '@material-ui/icons'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import { PRIVACY_POLICY, TERM_OF_SERVICE, SERVICES, CONTACT, TEAM } from 'routes/routes'

function MainFooter() {
  return (
    <>
      <div className="footer" id="footer">
        <div className="container-fluid">
          <div className="row" style={{ marginLeft: isMobile ? '' : '5vw' }}>
            <div className="col-lg-5 col-12">
              <img
                src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/logo/epa_logo_full.svg"
                alt="footer logo"
                height={isMobile ? '90px' : '140px'}
                className="mb-3"
              />

              <p
                className="info-description-black mb-5"
                style={{ marginTop: isMobile ? '0px' : '4em', marginRight: isMobile ? '0px' : '155px' }}>
                We are committed to empowering students to develop skills that would last a lifetime. Beyond tests,
                grades, and admissions, our purpose is to cultivate self-sufficient learners, future thinkers, and
                conscientious leaders. At EPA, we believe in learning not just for the purpose of education but as a
                crucial and life-enriching experience.
              </p>
            </div>

            <div className="col-lg-3 d-lg-block d-none">&nbsp;</div>

            <div className="col-lg-4 col-12 justify-content-end">
              <div className="row">
                <div className="col-lg-12 col-4">
                  <div className="row">
                    <div className="col-lg-4 col-12">
                      <div className="row" style={{ height: '200px' }}>
                        <div className="col-lg-12 col-12">
                          <div className="menu__header">Explore</div>
                          <div className="mb-1 mt-4">
                            <Link to={SERVICES}>
                              <div
                                className={`${
                                  isMobile ? '' : 'menu__item'
                                } d-flex flex-nowrap align-items-center mb-2`}>
                                Our Services
                              </div>
                            </Link>
                            <Link to={TEAM}>
                              <div
                                className={`${
                                  isMobile ? '' : 'menu__item'
                                } d-flex flex-nowrap align-items-center mb-2 mt-lg-3`}>
                                About Us
                              </div>
                            </Link>
                            <Link to={CONTACT}>
                              <div
                                className={`${
                                  isMobile ? '' : 'menu__item'
                                } d-flex flex-nowrap align-items-center mb-2 mt-lg-3`}>
                                Contact
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* desktop */}
                    <div className="col-lg-8 d-none d-lg-block">
                      <div className="row">
                        <div className="col-lg-11 mb-4">
                          <div className="menu__header">Contact</div>
                          <div
                            className="menu__item d-flex flex-row align-items-center my-4"
                            style={{ marginRight: '70px' }}>
                            294/1 Asia Building, 11B Floor 11, Phayathai Road, Petchaburi, Ratchathewi, Bangkok 10400
                          </div>
                          <div className="menu__item d-flex flex-row">
                            Call us: &nbsp;<a href="tel:+02-611-0348">+02-611-0348</a>
                          </div>
                          <div className="menu__item d-flex flex-row mb-2">
                            Email us: &nbsp;<a href="mailto:info@epa.ac.th">info@epa.ac.th</a>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* mobile */}
                    <div className="col-12 d-lg-none d-block">
                      <div className="row">
                        <div className="col-6">
                          <div className="menu__header">Connect</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* desktop */}
                <div className="col-lg-12 mb-4 d-none d-lg-block" style={{ marginTop: '30px' }}>
                  <div className="menu__header">Connect</div>
                  <div className="d-flex flex-wrap manage-margin mt-4">
                    <a href="https://www.facebook.com/EPAschool" target="_blank" rel="noopener noreferrer">
                      <img
                        src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/line_icon_orange.svg"
                        width="46"
                        height="46"
                        alt="line"
                      />
                    </a>
                    <a href="https://www.facebook.com/EPAschool" target="_blank" rel="noopener noreferrer">
                      <img
                        src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/facebook_icon_orange.svg"
                        width="46"
                        height="46"
                        alt="facebook"
                        style={{ marginLeft: '4.8vh' }}
                      />
                    </a>
                    <a href="https://www.facebook.com/EPAschool" target="_blank" rel="noopener noreferrer">
                      <img
                        src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/instagram_icon_orange.svg"
                        width="46"
                        height="46"
                        alt="instagram"
                        style={{ marginLeft: '4.8vh' }}
                      />
                    </a>
                    <a href="https://www.facebook.com/EPAschool" target="_blank" rel="noopener noreferrer">
                      <img
                        src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/youtube_icon_orange.svg"
                        width="46"
                        height="46"
                        alt="youtube"
                        style={{ marginLeft: '4.8vh' }}
                      />
                    </a>
                  </div>
                </div>

                {/* mobile */}
                <div className="col-6 d-lg-none col-md-4 d-block">
                  <div className="row" style={{ height: '200px' }}>
                    <div className="menu__header">Contact</div>
                    <div className="d-flex flex-row justify-content-center mb-3 ">
                      {/* <PhoneIcon className="text-white mr-2" /> */}
                      294/1 Asia Building, 11B Floor 11, Phayathai Road, Petchaburi, Ratchathewi, Bangkok 10400
                    </div>
                    <div className="d-flex flex-row numeric-normal">
                      {/* <EmailIcon className="text-white mr-2" /> */}
                      Call us: <a href="tel:+02-611-0348">+02-611-0348</a>
                      <div>{/* <a href="mailto:careers@tenopy.com">@epa.com</a> */}</div>
                    </div>
                    <div className="d-flex flex-row mb-5">
                      {/* <AddressIcon className="text-white mr-2" /> */}
                      Email us: <a href="mailto:info@epa.ac.th">info@epa.ac.th</a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mb-4" style={{ marginLeft: '-7%' }}>
                      <div className="d-flex flex-wrap manage-margin">
                        <a href="https://www.facebook.com/EPAschool" target="_blank" rel="noopener noreferrer">
                          <img
                            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/facebook_icon.svg"
                            width="25"
                            height="25"
                            alt="facebook"
                          />
                        </a>
                        <a href="https://www.facebook.com/EPAschool" target="_blank" rel="noopener noreferrer">
                          <img
                            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/instagram_icon.svg"
                            width="25"
                            height="25"
                            alt="instagram"
                            className="ml-2"
                          />
                        </a>
                        <a href="https://www.facebook.com/EPAschool" target="_blank" rel="noopener noreferrer">
                          <img
                            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/youtube_icon.svg"
                            width="25"
                            height="25"
                            alt="youtube"
                            className="ml-2"
                          />
                        </a>
                        <a href="https://www.facebook.com/EPAschool" target="_blank" rel="noopener noreferrer">
                          <img
                            src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/line_icon.svg"
                            width="25"
                            height="25"
                            alt="line"
                            className="ml-2"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-lg-none d-block bg-white" style={{ paddingBottom: '10vh', backgroundColor: '#fff' }}></div>
        {/* mobile */}
        <div className="d-lg-none d-flex col-lg-6 col-12 order-lg-2 order-1 text-lg-right text-center mb-lg-0">
          <span className="mr-3">
            <Link to={PRIVACY_POLICY} className="footer__link" style={{ color: '#000' }}>
              Privacy Policy
            </Link>
          </span>
          <span>
            <Link to={TERM_OF_SERVICE} className="footer__link" style={{ color: '#000' }}>
              Terms of Services
            </Link>
          </span>
        </div>
      </div>

      <section className="pt-md-0 py-4" style={{ backgroundColor: '#F7EFEA' }}>
        <div className="pt-md-3 pt-0 container-fluid ">
          <div className="row mt-2 col-12 justify-content-between">
            <div className="footer__copyright" style={{ marginLeft: isMobile ? '' : '6vw' }}>
              © Copyright {new Date().getFullYear()} EPA Pte Ltd. All rights reserved.
            </div>
            {/* desktop */}
            <div className="d-lg-block d-none text-lg-right mb-4 mb-lg-0" style={{ marginRight: '4vw' }}>
              <span className="mr-3">
                <Link to={PRIVACY_POLICY} className="footer__link mr-5">
                  Privacy Policy
                </Link>
              </span>
              <span>
                <Link to={TERM_OF_SERVICE} className="footer__link" style={{ color: '#000' }}>
                  Terms of Services
                </Link>
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MainFooter
