import React from 'react'
import styled from 'styled-components'
import { DropdownSection } from './components'

import { NavLink } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { SERVICES } from 'routes/routes'
import { HashLink as Link } from 'react-router-hash-link'

const DevelopersDropdownEl = styled.div`
  width: 100%;
`

const CatalogueDropdown = props => {
  let { classCategories } = props
  //let promoSection = classCategories?.promo

  return (
    <DevelopersDropdownEl onMouseLeave={props.onMouseLeave}>
      <DropdownSection data-first-dropdown-section>
        <div className="d-flex" tabIndex="-1">
          <div>
            {classCategories ? (
              <div className="d-flex" tabIndex="-1">
                {/* {Object.keys(classCategories.classes).map((categoryLabel, key) => (
                  <div className="navbar-classes-menu-column" key={key} style={{ borderRight: '1px solid #eaeaea' }}>
                    <div className="navbar-subtitle" style={{ borderBottom: '1px solid #eaeaea' }}>
                      {categoryLabel}
                    </div>
                    {Object.keys(classCategories.classes[categoryLabel]).map((classLabel, key) => (
                      <NavLink
                        to={ALL_CLASSES}
                        key={key}
                        className="navbar-link"
                        onClick={() => {
                          props.setOnlineClassFilter({
                            level: classCategories.classes[categoryLabel][classLabel],
                            status: 'Ongoing',
                            subject: 'All',
                            type: '2022'
                          })
                          props.exitDropdown()
                        }}>
                        {classLabel}
                      </NavLink>
                    ))}
                  </div>
                ))} */}

                {/* just for fun, for a side bar */}
                {/* <Paper elevation={5}> 
                <div className="navbar-subtitle my-2">
                    <Link
                      to={`${SERVICES}#tutoring`}
                      className="navbar-link"
                      onClick={() => {
                        props.exitDropdown()
                      }}>
                      <span className="info-description-black--bold">
                        Tutoring
                      </span>
                      <br></br>
                      <span className="info-description-black"> Academic & Test Preparation</span>
                    </Link>
                  </div>
                </Paper> */}

                <div className="navbar-classes-menu-column-bigger">
                  <div className="navbar-subtitle my-2">
                    <Link
                      to={`${SERVICES}#tutoring`}
                      className="navbar-link"
                      onClick={() => {
                        props.exitDropdown()
                      }}>
                      <span className="info-description-black--bold">Tutoring</span>
                      <br></br>
                      <span className="info-description-black">Academic & Test Preparation</span>
                    </Link>
                  </div>
                  <div className="divider-catalogue"></div>
                  <div className="navbar-subtitle my-2 ">
                    <Link
                      to={`${SERVICES}#collegewise`}
                      className="navbar-link"
                      onClick={() => {
                        props.exitDropdown()
                      }}>
                      <span className="info-description-black--bold">CollegeWise</span>
                      <br></br>
                      <span className="info-description-black">University Admissions Counseling </span>
                    </Link>
                  </div>
                  <div className="divider-catalogue"></div>

                  <div className="navbar-subtitle my-2">
                    <Link
                      to={`${SERVICES}#homeschool`}
                      className="navbar-link"
                      onClick={() => {
                        props.exitDropdown()
                      }}>
                      <span className="info-description-black--bold">Homeschool</span>
                      <br></br>
                      <span className="info-description-black">Your Personalized Curriculum</span>
                    </Link>
                  </div>
                </div>

                {/* <div className="navbar-classes-menu-column" style={{ borderRight: '1px solid #eaeaea' }}>
                  <div className="navbar-subtitle" style={{ borderBottom: '1px solid #eaeaea' }}>
                    Homeschool
                  </div>
                  <Link
                    to={`${SERVICES}#homeschool`}
                    className="navbar-link"
                    onClick={() => {
                      props.exitDropdown()
                    }}>
                    GED, IGSCE, A-Level
                  </Link>
                </div>

                <div className="navbar-classes-menu-column-bigger" style={{ borderRight: '1px solid #eaeaea' }}>
                  <div className="navbar-subtitle" style={{ borderBottom: '1px solid #eaeaea' }}>
                    Career and College wise
                  </div>
                  <Link
                    to={`${SERVICES}#career`}
                    className="navbar-link"
                    onClick={() => {
                      props.exitDropdown()
                    }}>
                    University and career preparation
                  </Link>
                </div> */}
              </div>
            ) : null}
            {/* <div className="navbar-explore-all-container">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  props.setOnlineClassFilter({
                    level: 'All',
                    status: 'Ongoing',
                    subject: 'All',
                    type: '2022'
                  })
                  props.goToPage(SERVICES)()
                  props.exitDropdown()
                }}>
                Explore All Services
              </Button>
            </div> */}
          </div>
          {/*promoSection?.title && (
            <div
              className="navbar-classes-menu-column navbar-classes-menu-ad align-items-center"
              style={{ backgroundImage: promoSection.background_css }}>
              <span dangerouslySetInnerHTML={{ __html: promoSection.title }} />
              {promoSection.button_text && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    props.goToPage(promoSection.button_action)()
                    props.exitDropdown()
                  }}>
                  {promoSection.button_text}
                </Button>
              )}
              {promoSection.decoration_img && (
                <img src={promoSection.decoration_img} className="img-fluid" alt="promo" />
              )}
            </div>
          )*/}
          {/* <div
            className="navbar-classes-menu-column navbar-classes-menu-ad align-items-center justify-content-between"
            style={{
              backgroundImage:
                'linear-gradient( 109.8deg, rgba(62,5,116,1) -5.2%, rgba(41,14,151,1) -5.2%, rgba(216,68,148,1) 103.3% )'
            }}>
            <div className="my-2">All Our Services</div>
            <img
              src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/nav-online-learning.png"
              className="img-fluid"
              alt="workshop"
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                props.goToPage(SERVICES)()
                props.exitDropdown()
              }}>
              View
            </Button> 
          </div>*/}
        </div>
      </DropdownSection>
    </DevelopersDropdownEl>
  )
}

export default CatalogueDropdown
