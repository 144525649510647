import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { SERVICES } from 'routes/routes'
import { isMobile } from 'react-device-detect'

class ProgramsCard extends React.Component {
  state = {
    isHover: false
  }

  handleMouseEnter = () => {
    this.setState({ isHover: true })
  }

  handleMouseLeave = () => {
    this.setState({ isHover: false })
  }

  render() {
    const { title, subtitle, image, description, link, color, hoverColor, homeschool } = this.props
    const { isHover } = this.state

    return (
      <div className="col-lg-4">
        <div className="col-12 col-lg-12">
          <div
            className={'card p-4'} // ${isMobile ? '0' : 'mx-3'} to make the cards thinner w/o sacrificng margin
            style={{ borderColor: 'white', backgroundColor: 'white', borderRadius: '4px' }}>
            <div className="d-flex justify-content-center">
              <div className="section__header_landing_title">
                <div className="header--bold">{title}</div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="info-description-black mt-2">{subtitle}</div>
            </div>
            <div className="d-flex justify-content-center my-4">
              <div
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  height: '16.9vh',
                  width: homeschool ? '18.09vh' : '23.09vh'
                }}
              />
            </div>
            <div className="section__header_landing_title" style={{ height: '10.76893vh' }}>
              <div className="d-flex justify-content-center text-center">
                <p className="description pt-2 pb-5" style={{ marginLeft: '1.7vh', marginRight: '1.7vh' }}>
                  {description}
                </p>
              </div>
            </div>

            <Link to={`${SERVICES}#${link}`} rel="noreferrer" style={{ textDecoration: 'none' }}>
              <div
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                className="info-description-white--bold text-center p-3 mt-5"
                style={{ backgroundColor: `${isHover ? hoverColor : color}`, borderRadius: '4px' }}>
                Learn More
              </div>
            </Link>
          </div>

          {/* <div
            className={'card p-4'} // ${isMobile ? '0' : 'mx-3'} to make the cards thinner w/o sacrificng margin
            style={{ borderColor: 'white', backgroundColor: 'white', borderRadius: '4px' }}>
            <div className="d-flex justify-content-between">
              <div className="section__header_landing_title">
                <div className="header--bold ml-1">{title}</div>
              </div>

              <img src={image} width="45px" height="45px" alt={title} />
            </div>

            <div className="info-description-black ml-2 mt-2">{subtitle}</div>

            <div className="divider-card"></div>

            <div className="section__header_landing_title" style={{ height: '19.76893vh' }}>
              <p className="description pt-2 ml-2 pb-5">{description}</p>
            </div>
            //  have to change to Link as it is interna
            <Link to={`${SERVICES}#${link}`} rel="noreferrer" style={{ textDecoration: 'none' }}>
              <div
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                className="info-description-white--bold text-center p-3 mt-5"
                style={{ backgroundColor: `${isHover ? hoverColor : color}`, borderRadius: '4px' }}>
                Learn More
              </div>
            </Link>
          </div> */}
        </div>
      </div>
    )
  }
}

export default ProgramsCard
