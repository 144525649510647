import React from 'react'
import { isMobile } from 'react-device-detect'
import { Avatar, Button, CircularProgress, Dialog, DialogContent, Slide } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Proptypes from 'prop-types'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ClassDetail = props => {
  const { isOpen, close, classData } = props

  return (
    <Dialog open={isOpen} onClose={close} TransitionComponent={Transition} maxWidth="md">
      <DialogContent>
        {classData ? (
          <div className="row">
            <div className="col-md-auto col-12 pb-2">
              <Avatar
                src={classData.tutor[0].profile_pic}
                alt={classData.tutor[0].name}
                className={`avatar-shadow mx-auto ${isMobile ? 'size-100' : 'size-180'}`}
              />
              {isMobile && (
                <Close
                  className="position-absolute clickable m-2"
                  onClick={close}
                  style={{ right: '-5px', top: '-15px' }}
                />
              )}
            </div>
            <div className="col align-self-end mt-2 mt-md-0" id="profile-desc">
              <h2 className="title--rubik text-center text-md-left mb-2">{classData.name}</h2>
              <div className="text-justify" dangerouslySetInnerHTML={{ __html: classData.desc }} />
              <hr />
              <h2 className="title--rubik font-weight-normal my-2">About Teacher {classData.tutor[0].name}</h2>
              <p className="text-justify" dangerouslySetInnerHTML={{ __html: classData.tutor[0].desc }} />
              <div className="text-right">
                <Button variant="outlined" color="primary" onClick={close}>
                  Close
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="m-5">
            <CircularProgress color="primary" size={35} />
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

ClassDetail.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired,
  classData: Proptypes.object
}

export default ClassDetail
