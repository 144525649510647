import React from 'react'
import { connect } from 'react-redux'
import { PARENT } from 'constants/userTypes'
import {
  MenuItem,
  Button,
  CircularProgress,
  CardContent,
  Card,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Snackbar
} from '@material-ui/core'
import MuiAlert from '@mui/material/Alert'

import { EDIT_CLASS_META } from 'constants/actionTypes'
import { Clear as CancelIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import Chart from 'react-apexcharts'
import { isMobile } from 'react-device-detect'
import ClassReportToolTip from 'components/shared/Report/reportTooltip'
import agent from 'agent'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  classMeta: state.classes.classMeta
})

const mapDispatchToProps = dispatch => ({
  onSaveClassMeta: (payload, keyword, classId) =>
    dispatch({ type: EDIT_CLASS_META, payload: agent.Classes.editClassMeta({ payload, keyword }, classId) })
})

class ReportTab extends React.Component {
  state = {
    anchorEl: null,
    open: false,
    options: {},
    series: [],
    optionsMastery: {},
    seriesMastery: [],
    openDateDialog: false,
    openTargetScoreDialog: false,
    openExamResultDialog: false,
    dayOfExam: '',
    examTargetScore: '',
    examResult: '',
    isSaved: false,
    prevE: '',
    prevTS: '',
    prevR: ''
  }

  handleDateChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleOpen = name => {
    if (name === 'examDate') {
      this.setState({
        openDateDialog: true
      })
    }

    if (name === 'targetScore') {
      this.setState({
        openTargetScoreDialog: true
      })
    }

    if (name === 'examResult') {
      this.setState({
        openExamResultDialog: true
      })
    }
  }

  handleClose = (event, reason) => {
    const { isSaved } = this.state
    if (reason === 'clickaway') {
      return
    }

    this.setState({ isSaved: !isSaved })
  }

  handleSave = (payload, name) => {
    const { classData } = this.props
    this.props.onSaveClassMeta(payload, name, classData._id)
    this.setState({ isSaved: true, openDateDialog: false, openTargetScoreDialog: false, openExamResultDialog: false })
  }

  componentDidMount() {
    const { classData } = this.props

    this.setState({
      dayOfExam: classData?.meta?.dayOfExam ?? '',
      examTargetScore: classData?.meta?.examTargetScore ?? '',
      examResult: classData?.meta?.examResult ?? '',
      prevE: classData?.meta?.dayOfExam,
      prevTS: classData?.meta?.examTargetScore,
      prevR: classData?.meta?.examResult
    })

    let chosenClass = classData?.lesson

    let filteredHomeworkSubmission = chosenClass?.filter(
      lessons => lessons?.lesson_meta?.homework.homeworkAssignment === 'y'
    )

    let filteredOnTimeHWS = filteredHomeworkSubmission?.filter(
      lessons => lessons?.lesson_meta?.homework.homeworkSubmission === '1'
    )

    let filteredLateHWS = filteredHomeworkSubmission?.filter(
      lessons => lessons?.lesson_meta?.homework.homeworkSubmission === '2'
    )

    let filteredNoHWS = filteredHomeworkSubmission?.filter(
      lessons => lessons?.lesson_meta?.homework.homeworkSubmission === '3'
    )

    let lateHWSLength = filteredLateHWS?.length
    let noHWSLength = filteredNoHWS?.length
    let onTimeHWSLength = filteredOnTimeHWS?.length

    let filteredHWSTotalLength = onTimeHWSLength + lateHWSLength + noHWSLength

    let tot = (onTimeHWSLength / filteredHWSTotalLength) * 100
    let totlate = (lateHWSLength / filteredHWSTotalLength) * 100
    let totno = (noHWSLength / filteredHWSTotalLength) * 100

    let filteredOnTimeRNS = chosenClass?.filter(lessons => lessons?.lesson_meta?.revision_notes === '1')

    let filteredLateRNS = chosenClass?.filter(lessons => lessons?.lesson_meta?.revision_notes === '2')

    let filteredNoRNS = chosenClass?.filter(lessons => lessons?.lesson_meta?.revision_notes === '3')

    let totalRNS = filteredOnTimeRNS?.length + filteredLateRNS?.length + filteredNoRNS?.length

    let totRNS = (filteredOnTimeRNS?.length / totalRNS) * 100
    let totlateRNS = (filteredLateRNS?.length / totalRNS) * 100
    let totnoRNS = (filteredNoRNS?.length / totalRNS) * 100

    let filteredEngagementLessons = chosenClass?.filter(
      lessons =>
        lessons?.lesson_meta?.engagement?.engagementParticipation > 0 &&
        lessons?.lesson_meta?.engagement?.engagementDistracted > 0 &&
        lessons?.lesson_meta?.engagement?.engagementTiredBored > 0 &&
        lessons?.lesson_meta?.engagement?.engagementMotivate > 0 &&
        lessons?.lesson_meta?.engagement?.engagementAskQuestions > 0
    )

    let filteredMasteryLessons = chosenClass?.filter(
      lessons =>
        lessons?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion > 0 &&
        lessons?.lesson_meta?.skill_mastery?.skillMasteryConfused > 0 &&
        lessons?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding > 0 &&
        lessons?.lesson_meta?.skill_mastery?.skillMasteryParticipative > 0 &&
        lessons?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept > 0
    )

    const generateColors = data => {
      return data?.map((d, idx) => {
        let color = d >= 1 && d <= 2 ? '#ef4544' : d >= 3 && d <= 4 ? '#F28C28' : d >= 5 && d <= 6 ? '#50C878' : ''

        return {
          offset: (idx / data?.length) * 100,
          color,
          opacity: 1
        }
      })
    }

    this.setState({
      ontime: tot,
      late: totlate,
      no: totno,
      totRNS: totRNS,
      totlateRNS: totlateRNS,
      totnoRNS: totnoRNS,
      options: {
        chart: {
          id: 'engagement'
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            colorStops: generateColors(
              filteredEngagementLessons?.map(lesson =>
                Math.round(
                  (parseInt(lesson?.lesson_meta?.engagement?.engagementParticipation) +
                    parseInt(lesson?.lesson_meta?.engagement?.engagementDistracted) +
                    parseInt(lesson?.lesson_meta?.engagement?.engagementTiredBored) +
                    parseInt(lesson?.lesson_meta?.engagement?.engagementMotivate) +
                    parseInt(lesson?.lesson_meta?.engagement?.engagementAskQuestions)) /
                    5
                )
              )
            )
          }
        },
        xaxis: {
          categories: filteredEngagementLessons?.map(lessons => lessons.name)
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: `Engagement level over ${filteredEngagementLessons?.length} lessons`,
          align: 'left'
        }
      },
      series: [
        {
          name: 'Engagement',
          data: filteredEngagementLessons?.map(lesson =>
            Math.round(
              (parseInt(lesson?.lesson_meta?.engagement?.engagementParticipation) +
                parseInt(lesson?.lesson_meta?.engagement?.engagementDistracted) +
                parseInt(lesson?.lesson_meta?.engagement?.engagementTiredBored) +
                parseInt(lesson?.lesson_meta?.engagement?.engagementMotivate) +
                parseInt(lesson?.lesson_meta?.engagement?.engagementAskQuestions)) /
                5
            )
          )
        }
      ],
      optionsMastery: {
        chart: {
          id: 'mastery'
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            colorStops: generateColors(
              filteredMasteryLessons?.map(lesson =>
                Math.round(
                  (parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion) +
                    parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryConfused) +
                    parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding) +
                    parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryParticipative) +
                    parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept)) /
                    5
                )
              )
            )
          }
        },
        xaxis: {
          categories: filteredMasteryLessons?.map(lessons => lessons.name)
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: `Mastery of skills over ${filteredMasteryLessons?.length} lessons`,
          align: 'left'
        }
      },
      seriesMastery: [
        {
          name: 'Mastery',
          data: filteredMasteryLessons?.map(lesson =>
            Math.round(
              (parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion) +
                parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryConfused) +
                parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding) +
                parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryParticipative) +
                parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept)) /
                5
            )
          )
        }
      ]
    })
  }

  render() {
    const { classData, currentUser } = this.props
    const {
      ontime,
      late,
      totRNS,
      totlateRNS,
      openDateDialog,
      openExamResultDialog,
      openTargetScoreDialog,
      dayOfExam,
      examTargetScore,
      examResult,
      isSaved,
      prevE,
      prevTS,
      prevR
    } = this.state

    let chosenClass = classData?.lesson

    let filteredAttendanceEarly = chosenClass?.filter(lessons => lessons?.lesson_meta?.punctuality === 'n')
    let filteredAttendanceLate = chosenClass?.filter(lessons => lessons?.lesson_meta?.punctuality === 'y')

    let filteredAttendanceTotal = chosenClass?.filter(
      lessons => lessons?.lesson_meta?.punctuality === 'y' || lessons?.lesson_meta?.punctuality === 'n'
    )
    let attendanceEarly = (filteredAttendanceEarly?.length / filteredAttendanceTotal?.length) * 100
    let attendanceLate = (filteredAttendanceLate?.length / filteredAttendanceTotal?.length) * 100

    let filteredCancelledTotal = chosenClass?.filter(
      lessons => lessons?.lesson_meta?.cancelledClass === 'y' || lessons?.lesson_meta?.cancelledClass === 'n'
    )

    let filteredCancelledNo = chosenClass?.filter(lessons => lessons?.lesson_meta?.cancelledClass === 'n')
    let filteredCancelledActual = chosenClass?.filter(lessons => lessons?.lesson_meta?.cancelledClass === 'y')

    let cancelledNo = (filteredCancelledNo?.length / filteredCancelledTotal?.length) * 100
    let cancelledActual = (filteredCancelledActual?.length / filteredCancelledTotal?.length) * 100

    let filteredHomeworkSubmission = chosenClass?.filter(
      lessons => lessons?.lesson_meta?.homework.homeworkAssignment === 'y'
    )

    let filteredOnTimeHWS = filteredHomeworkSubmission?.filter(
      lessons => lessons?.lesson_meta?.homework.homeworkSubmission === '1'
    )

    let filteredLateHWS = filteredHomeworkSubmission?.filter(
      lessons => lessons?.lesson_meta?.homework.homeworkSubmission === '2'
    )

    let filteredNoHWS = filteredHomeworkSubmission?.filter(
      lessons => lessons?.lesson_meta?.homework.homeworkSubmission === '3'
    )

    let filteredOnTimeRNS = chosenClass?.filter(lessons => lessons?.lesson_meta?.revision_notes === '1')
    let filteredLateRNS = chosenClass?.filter(lessons => lessons?.lesson_meta?.revision_notes === '2')
    let filteredNoRNS = chosenClass?.filter(lessons => lessons?.lesson_meta?.revision_notes === '3')
    let totalRNS = filteredOnTimeRNS?.length + filteredLateRNS?.length + filteredNoRNS?.length

    let punctualityColor = '#000'
    let cancellationColor = '#000'
    let engagementColor = '#000'
    let masteryColor = '#000'
    let homeworkColor = '#000'
    let revisionNotesColor = '#000'

    if (attendanceEarly >= 0 && attendanceEarly <= 49) {
      punctualityColor = '#F44336'
    }
    if (attendanceEarly >= 50 && attendanceEarly <= 80) {
      punctualityColor = '#F28C28'
    }
    if (attendanceEarly >= 81 && attendanceEarly <= 100) {
      punctualityColor = '#50C878'
    }

    if (cancelledActual >= 0 && cancelledActual <= 49) {
      cancellationColor = '#50C878'
    }
    if (cancelledActual >= 50 && cancelledActual <= 80) {
      cancellationColor = '#F28C28'
    }
    if (cancelledActual >= 81 && cancelledActual <= 100) {
      cancellationColor = '#F44336'
    }

    let homeworkSubmissionTotal = ontime + late

    if (homeworkSubmissionTotal >= 0 && homeworkSubmissionTotal <= 49) {
      homeworkColor = '#F44336'
    }
    if (homeworkSubmissionTotal >= 50 && homeworkSubmissionTotal <= 80) {
      homeworkColor = '#F28C28'
    }
    if (homeworkSubmissionTotal >= 81 && homeworkSubmissionTotal <= 100) {
      homeworkColor = '#50C878'
    }

    let revisionSubmissionTotal = totRNS + totlateRNS

    if (revisionSubmissionTotal >= 0 && revisionSubmissionTotal <= 49) {
      revisionNotesColor = '#F44336'
    }
    if (revisionSubmissionTotal >= 50 && revisionSubmissionTotal <= 80) {
      revisionNotesColor = '#F28C28'
    }
    if (revisionSubmissionTotal >= 81 && revisionSubmissionTotal <= 100) {
      revisionNotesColor = '#50C878'
    }

    let filteredEngagementLessons = chosenClass?.filter(
      lessons =>
        lessons?.lesson_meta?.engagement?.engagementParticipation > 0 &&
        lessons?.lesson_meta?.engagement?.engagementDistracted > 0 &&
        lessons?.lesson_meta?.engagement?.engagementTiredBored > 0 &&
        lessons?.lesson_meta?.engagement?.engagementMotivate > 0 &&
        lessons?.lesson_meta?.engagement?.engagementAskQuestions > 0
    )

    let filteredMasteryLessons = chosenClass?.filter(
      lessons =>
        lessons?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion > 0 &&
        lessons?.lesson_meta?.skill_mastery?.skillMasteryConfused > 0 &&
        lessons?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding > 0 &&
        lessons?.lesson_meta?.skill_mastery?.skillMasteryParticipative > 0 &&
        lessons?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept > 0
    )

    let filteredLessonTopics = chosenClass?.filter(
      lessons => lessons?.lesson_meta?.lesson_topic !== '' || lessons?.lesson_meta?.lesson_topic !== undefined
    )

    let filteredLessonComments = chosenClass?.filter(
      lessons => lessons?.lesson_meta?.comment?.comment && lessons?.lesson_meta?.comment?.comment !== ''
    )

    let filteredLessonParentComments = chosenClass?.filter(
      lessons => lessons?.lesson_meta?.comment?.commentParentView && lessons?.lesson_meta?.comment?.comment !== ''
    )

    let filteredRecommendations = chosenClass?.filter(
      lessons => lessons?.lesson_meta?.recommendation.recommendationOthers !== ''
    )

    return (
      <div className="app-wrapper">
        <div className="dashboard animated slideInUpTiny animation-duration-3">
          <div className="dashboard animated slideInUpTiny animation-duration-3">
            <div className="row">
              <div className="col-12">
                <Card className="mb-3">
                  <CardContent>
                    <div className="align-items-center">
                      <div className="row justify-content-around">
                        <div className="col-md-4 col-12">
                          <div
                            className="col-md-12 col-12 my-2 py-4 font-weight-medium box-dashboard"
                            style={{ alignItems: 'center', alignContent: 'center' }}>
                            <div style={{ fontSize: 25, minHeight: '200px', alignContent: 'center', display: 'grid' }}>
                              <div style={{ display: 'grid', justifyContent: 'center', textAlign: 'center' }}>
                                <strong style={{ fontSize: 50 }}>
                                  {moment(dayOfExam).format('llll').slice(0, -8)}
                                </strong>
                              </div>
                              <div style={{ display: 'grid', justifyContent: 'center', textAlign: 'center' }}>
                                <span>
                                  Date of upcoming exams{' '}
                                  <i
                                    className="zmdi zmdi-edit zmdi-hc-fw mr-2"
                                    onClick={() => this.handleOpen('examDate')}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-12">
                          <div className="col-md-12 col-12 my-2 py-4 font-weight-medium box-dashboard">
                            <div style={{ fontSize: 25, minHeight: '200px', alignContent: 'center', display: 'grid' }}>
                              <div style={{ display: 'grid', justifyContent: 'center', textAlign: 'center' }}>
                                <strong style={{ fontSize: 50 }}>
                                  {examTargetScore === '' ? 'N/A' : `${examTargetScore}%`}{' '}
                                </strong>
                              </div>
                              <div style={{ display: 'grid', justifyContent: 'center', textAlign: 'center' }}>
                                <span>
                                  Exam target score (tutor){' '}
                                  <i
                                    className="zmdi zmdi-edit zmdi-hc-fw mr-2"
                                    onClick={() => this.handleOpen('targetScore')}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-12">
                          <div className="col-md-12 col-12 my-2 py-4 font-weight-medium box-dashboard">
                            <div style={{ fontSize: 25, minHeight: '200px', alignContent: 'center', display: 'grid' }}>
                              <div style={{ display: 'grid', justifyContent: 'center', textAlign: 'center' }}>
                                <strong style={{ fontSize: 50 }}>{examResult === '' ? 'N/A' : `${examResult}%`}</strong>
                              </div>
                              <div style={{ display: 'grid', justifyContent: 'center', textAlign: 'center' }}>
                                <span>
                                  Exam result{' '}
                                  <i
                                    className="zmdi zmdi-edit zmdi-hc-fw mr-2"
                                    onClick={() => this.handleOpen('examResult')}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>

                <ClassReportToolTip
                  currentUser={currentUser}
                  options={this.state.options}
                  series={this.state.series}
                  optionsMastery={this.state.optionsMastery}
                  seriesMastery={this.state.seriesMastery}
                  punctualityColor={punctualityColor}
                  attendanceEarly={attendanceEarly}
                  filteredAttendanceLate={filteredAttendanceLate}
                  filteredAttendanceTotal={filteredAttendanceTotal}
                  cancellationColor={cancellationColor}
                  cancelledActual={cancelledActual}
                  filteredCancelledActual={filteredCancelledActual}
                  homeworkColor={homeworkColor}
                  homeworkSubmissionTotal={homeworkSubmissionTotal}
                  filteredOnTimeHWS={filteredOnTimeHWS}
                  filteredLateHWS={filteredLateHWS}
                  filteredNoHWS={filteredNoHWS}
                  filteredLateRNS={filteredLateRNS}
                  filteredNoRNS={filteredNoRNS}
                  revisionNotesColor={revisionNotesColor}
                  revisionSubmissionTotal={revisionSubmissionTotal}
                  filteredOnTimeRNS={filteredOnTimeRNS}
                  filteredEngagementLessons={filteredEngagementLessons}
                  engagementColor={engagementColor}
                  filteredMasteryLessons={filteredMasteryLessons}
                  masteryColor={masteryColor}
                  filteredLessonParentComments={filteredLessonParentComments}
                  filteredLessonComments={filteredLessonComments}
                  filteredCancelledTotal={filteredCancelledTotal}
                  filteredLessonTopics={filteredLessonTopics}
                />

                <Dialog open={openDateDialog || openTargetScoreDialog || openExamResultDialog}>
                  <DialogTitle>
                    {openDateDialog && <span className="h2 mb-0"> Change Date of upcoming exam</span>}
                    {openTargetScoreDialog && <span className="h2 mb-0"> Change exam target score</span>}
                    {openExamResultDialog && <span className="h2 mb-0"> Change student exam result</span>}
                  </DialogTitle>
                  <DialogContent>
                    {openDateDialog && (
                      <div className="justify-content-center d-flex">
                        <TextField
                          type={'date'}
                          label={'Date of exam'}
                          name={'dayOfExam'}
                          value={dayOfExam ? dayOfExam : moment().format('YYYY-MM-DD')}
                          onChange={this.handleDateChange}
                          inputProps={{ style: { fontSize: 35 } }} // font size of input text
                          InputLabelProps={{ style: { fontSize: 35 } }} // font size of input label
                        />
                      </div>
                    )}

                    {openTargetScoreDialog && (
                      <div className="justify-content-center d-flex">
                        <TextField
                          type={'number'}
                          label={'Target score (0-100)%'}
                          name={'examTargetScore'}
                          value={examTargetScore > 100 ? '100' : examTargetScore ? examTargetScore : 'N/A'}
                          onChange={this.handleDateChange}
                          onInput={e => {
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                          }}
                          inputProps={{ style: { fontSize: 35 } }}
                          InputLabelProps={{ style: { fontSize: 35 } }} // font size of input label
                        />{' '}
                      </div>
                    )}

                    {openExamResultDialog && (
                      <div className="justify-content-center d-flex">
                        <TextField
                          type={'number'}
                          label={'Target score (0-100)%'}
                          name={'examResult'}
                          value={examResult > 100 ? '100' : examResult ? examResult : 'N/A'}
                          onChange={this.handleDateChange}
                          onInput={e => {
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                          }}
                          inputProps={{ style: { fontSize: 35 } }}
                          InputLabelProps={{ style: { fontSize: 35 } }} // font size of input label
                        />{' '}
                      </div>
                    )}
                  </DialogContent>
                  <DialogActions>
                    {/* SAVE CHANGES */}
                    {openDateDialog && (
                      <Button
                        onClick={() =>
                          this.handleSave(dayOfExam ? dayOfExam : moment().format('YYYY-MM-DD'), 'dayOfExam')
                        }
                        color="primary"
                        variant="contained">
                        {'Save Changes'}
                      </Button>
                    )}
                    {openTargetScoreDialog && (
                      <Button
                        onClick={() => this.handleSave(examTargetScore ? examTargetScore : 'N/A', 'examTargetScore')}
                        color="primary"
                        variant="contained">
                        {'Save Changes'}
                      </Button>
                    )}
                    {openExamResultDialog && (
                      <Button
                        onClick={() => this.handleSave(examResult ? examResult : 'N/A', 'examResult')}
                        color="primary"
                        variant="contained">
                        {'Save Changes'}
                      </Button>
                    )}

                    {/* CLOSE WITHOUT SAVING */}
                    {openDateDialog && (
                      <Button
                        onClick={() =>
                          this.setState({
                            openDateDialog: false,
                            dayOfExam: prevE
                          })
                        }
                        color="secondary"
                        variant="contained">
                        {'Close'}
                      </Button>
                    )}

                    {openTargetScoreDialog && (
                      <Button
                        onClick={() =>
                          this.setState({
                            openTargetScoreDialog: false,
                            examTargetScore: prevTS
                          })
                        }
                        color="secondary"
                        variant="contained">
                        {'Close'}
                      </Button>
                    )}

                    {openExamResultDialog && (
                      <Button
                        onClick={() =>
                          this.setState({
                            openExamResultDialog: false,
                            examResult: prevR
                          })
                        }
                        color="secondary"
                        variant="contained">
                        {'Close'}
                      </Button>
                    )}

                    {/* <Button
                      onClick={() =>
                        this.setState({
                          openDateDialog: false,
                          dayOfExam: prevE,
                          openTargetScoreDialog: false,
                          openExamResultDialog: false
                        })
                      }
                      color="secondary"
                      variant="contained">
                      {'Close'}
                    </Button> */}
                  </DialogActions>
                </Dialog>

                <Snackbar open={isSaved} autoHideDuration={3000} onClose={this.handleClose}>
                  <Alert onClose={this.handleClose} severity="success" sx={{ width: '100%' }}>
                    Changes has been successfully saved.
                  </Alert>
                </Snackbar>
                {/* <Dialog open={openTargetScoreDialog}>
                  <DialogTitle>
                    <span className="h2 mb-0">{'props.title'}</span>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      <span dangerouslySetInnerHTML={{ __html: 'props.content' }} />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => this.setState({ openTargetScoreDialog: false })}
                      color="primary"
                      variant="contained">
                      {'Close'}
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog open={openExamResultDialog}>
                  <DialogTitle>
                    <span className="h2 mb-0">{'props.title'}</span>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      <span dangerouslySetInnerHTML={{ __html: 'props.content' }} />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => this.setState({ openExamResultDialog: false })}
                      color="primary"
                      variant="contained">
                      {'Close'}
                    </Button>
                  </DialogActions>
                </Dialog> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ReportTab.propTypes = {
  classData: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportTab)
