import * as Type from '../constants/actionTypes'
import _ from 'lodash'

// TODO: shall consolidate all kind of submission list into filtered list
//       *** really need review this part of work, studpidly messy, need to consolidate both tutor & student params
const initialState = {
  creatingSubmission: false,
  updatingSubmission: false,
  // NOTE: for tutor usage
  loadingSubmissionList: false,
  submissionList: {
    data: [],
    couht: 0,
    homeworkId: '',
    statusFilter: ['SUBMITTED', 'RESUBMISSION_REQUIRE', 'RESUBMITTED', 'MARKING_IN_PROGRESS', 'MARKED']
  },
  loadingSubmissionStat: [],
  submissionStat: {},
  // NOTE: for student usage
  loadingMySubmission: [],
  mySubmissions: {},
  deletingSubmission: false,
  deleteStatus: {},
  uploadFail: false,
  // NOTE: created this for student dashboard (view marked submission that not viewed yet)
  loadingFilteredList: false,
  filteredList: {
    data: [],
    count: 0,
    max: 20,
    offset: 0,
    filter: {}
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.CREATE_SUBMISSION:
      return {
        ...state,
        creatingSubmission: true,
        uploadFail: false
      }

    case Type.CREATE_SUBMISSION_SUCCESS: {
      return {
        ...state,
        creatingSubmission: false,
        uploadFail: false
      }
    }

    case Type.CREATE_SUBMISSION_FAILED:
      return {
        ...state,
        creatingSubmission: false,
        uploadFail: true
      }

    case Type.UPDATE_SUBMISSION:
      return {
        ...state,
        updatingSubmission: true,
        uploadFail: false
      }

    case Type.UPDATE_SUBMISSION_SUCCESS: {
      let updatedSubmissionList = state.submissionList
      let updatedSubmissionIndex = _.findIndex(updatedSubmissionList.data, o => {
        return o._id === action.submissionId
      })
      if (updatedSubmissionIndex !== -1) {
        updatedSubmissionList['data'][updatedSubmissionIndex] = action.payload.data
      }

      return {
        ...state,
        updatingSubmission: false,
        submissionList: updatedSubmissionList,
        uploadFail: false
      }
    }

    case Type.UPDATE_SUBMISSION_FAILED:
      return {
        ...state,
        updatingSubmission: false,
        uploadFail: true
      }

    case Type.GET_MY_SUBMISSION: {
      let loadingMySubmission = state.loadingMySubmission
      loadingMySubmission.push(action.homeworkId)

      return {
        ...state,
        loadingMySubmission
      }
    }

    case Type.GET_MY_SUBMISSION_SUCCESS: {
      let loadingMySubmission = state.loadingMySubmission
      loadingMySubmission = _.remove(loadingMySubmission, o => {
        return o !== action.homeworkId
      })

      let mySubmissions = state.mySubmissions
      mySubmissions[action.homeworkId] = action.payload.data

      return {
        ...state,
        loadingMySubmission,
        mySubmissions
      }
    }

    case Type.GET_MY_SUBMISSION_FAILED: {
      let loadingMySubmission = state.loadingMySubmission
      loadingMySubmission = _.remove(loadingMySubmission, o => {
        return o !== action.homeworkId
      })

      let mySubmissions = state.mySubmissions
      mySubmissions[action.homeworkId] = 'not-found'

      return {
        ...state,
        loadingMySubmission,
        mySubmissions
      }
    }

    case Type.GET_FILTERED_SUBMISSION_LIST:
      return {
        ...state,
        loadingFilteredList: true,
        filteredList: {
          ...state.filteredList,
          filter: action.filter
        }
      }

    case Type.GET_FILTERED_SUBMISSION_LIST_SUCCESS:
      return {
        ...state,
        loadingFilteredList: false,
        filteredList: {
          data: action.payload.data,
          count: action.payload.count
        }
      }

    case Type.GET_FILTERED_SUBMISSION_LIST_FAILED:
      return {
        ...state,
        loadingFilteredList: false
      }

    case Type.GET_SUBMISSION_STAT: {
      let loadingSubmissionStat = []
      loadingSubmissionStat.push(action.homeworkId)

      return {
        ...state,
        loadingSubmissionStat
      }
    }

    case Type.GET_SUBMISSION_STAT_SUCCESS: {
      let loadingSubmissionStat = state.loadingSubmissionStat
      loadingSubmissionStat = _.remove(loadingSubmissionStat, o => {
        return o !== action.homeworkId
      })

      let submissionStat = state.submissionStat
      submissionStat[action.homeworkId] = action.payload.data

      return {
        ...state,
        loadingSubmissionStat,
        submissionStat
      }
    }

    case Type.GET_SUBMISSION_STAT_FAILED: {
      let loadingSubmissionStat = state.loadingSubmissionStat
      loadingSubmissionStat = _.remove(loadingSubmissionStat, o => {
        return o !== action.homeworkId
      })

      return {
        ...state,
        loadingSubmissionStat
      }
    }

    case Type.GET_SUBMISSION_BY_HOMEWORK: {
      let updatedSubmissionList = state.submissionList
      if (
        updatedSubmissionList.homeworkId !== action.homeworkId ||
        updatedSubmissionList.statusFilter !== action.statusFilter
      ) {
        updatedSubmissionList.homeworkId = action.homeworkId
        updatedSubmissionList.data = []
        updatedSubmissionList.count = 0
        updatedSubmissionList.statusFilter = action.statusFilter
      }

      return {
        ...state,
        loadingSubmissionList: true,
        submissionList: updatedSubmissionList
      }
    }

    case Type.GET_SUBMISSION_BY_HOMEWORK_SUCCESS: {
      let updatedSubmissionList = state.submissionList
      updatedSubmissionList.data = _.concat(updatedSubmissionList.data, action.payload.data)
      updatedSubmissionList.count = action.payload.count

      return {
        ...state,
        loadingSubmissionList: false,
        submissionList: updatedSubmissionList
      }
    }

    case Type.GET_SUBMISSION_BY_HOMEWORK_FAILED:
      return {
        ...state,
        loadingSubmissionList: false
      }

    case Type.DELETE_SUBMISSION:
      return {
        ...state,
        deletingSubmission: true
      }

    case Type.DELETE_SUBMISSION_SUCCESS: {
      let updatedSubmissionList = state.submissionList
      let deletedSubmissionId = action.payload.data._id
      _.remove(updatedSubmissionList.data, o => {
        return o._id === deletedSubmissionId
      })
      updatedSubmissionList.count--

      return {
        ...state,
        deletingSubmission: false,
        deleteStatus: { status: 'success', id: action.payload.data._id },
        submissionList: updatedSubmissionList
      }
    }

    case Type.DELETE_SUBMISSION_FAILED: {
      let failReason = action.payload.error.message
      let errorMessage = 'Something went wrong'
      if (failReason === 'no-duplicated-submission') {
        errorMessage = 'You only allow to delete duplicated submission'
      }

      return {
        ...state,
        deletingSubmission: false,
        deleteStatus: {
          status: 'failed',
          message: errorMessage,
          id: action.submissionId
        }
      }
    }

    case Type.LOGOUT:
      return initialState

    default:
      return state
  }
}
