import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import WaveSurfer from 'wavesurfer.js'
import { Button, CircularProgress } from '@material-ui/core'
import { PlayCircleOutline, PauseCircleOutline } from '@material-ui/icons'

class Waveform extends React.Component {
  constructor(props) {
    super(props)

    this.waveContainer = React.createRef()

    this.state = {
      loading: true,
      status: 'paused',
      duration: 0,
      currentTime: 0,
      seekTime: 0
    }
  }

  componentDidMount() {
    const { src, isBlob } = this.props

    this.wavesurfer = WaveSurfer.create({
      container: this.waveContainer.current,
      waveColor: '#DDDDDD',
      progressColor: '#00b96c',
      height: 75
    })

    if (isBlob) {
      this.wavesurfer.loadBlob(src)
    } else {
      this.wavesurfer.load(src)
    }

    this.wavesurfer.on('finish', () => {
      this.wavesurfer.stop()
      this.setState({ status: 'paused' })
    })
    this.wavesurfer.on('ready', () => {
      let duration = this.wavesurfer.getDuration()
      this.setState({ loading: false, duration })
    })
    this.wavesurfer.on('audioprocess', () => {
      let currentTime = this.wavesurfer.getCurrentTime()
      this.setState({ currentTime })
    })
    this.wavesurfer.on('seek', progress => {
      let currentTime = this.state.duration * progress
      this.setState({ currentTime })
    })
  }

  componentWillUnmount() {
    this.wavesurfer.destroy()
  }

  playPause = () => {
    this.setState(prevState => ({
      status: prevState.status === 'paused' ? 'playing' : 'paused'
    }))
    this.wavesurfer.playPause()
  }

  timeTracker = e => {
    const { duration } = this.state
    let rect = e.target.getBoundingClientRect()
    let x = e.clientX - rect.left //x position within the element.
    let seekTime = (x / rect.width) * duration

    this.setState({ seekTime })
  }

  resetSeek = () => {
    this.setState({ seekTime: 0 })
  }

  downloadFile = () => {
    const { src, isBlob } = this.props

    let a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'

    let url = isBlob ? window.URL.createObjectURL(src) : src
    a.href = url
    a.download = 'Tenopy Audio File'
    a.click()
    window.URL.revokeObjectURL(url)
  }

  render() {
    const { className } = this.props
    const { loading, status, duration, currentTime, seekTime } = this.state

    let durationMinutes = Math.floor(duration / 60)
    let durationSeconds = Math.round(duration % 60)

    let currentMinutes = Math.floor(currentTime / 60)
    let currentSeconds = Math.round(currentTime % 60)

    if (seekTime) {
      currentMinutes = Math.floor(seekTime / 60)
      currentSeconds = Math.round(seekTime % 60)
    }

    return (
      <div className={classNames('waveform', className)}>
        <div className="wave" ref={this.waveContainer} onMouseMove={this.timeTracker} onMouseOut={this.resetSeek} />
        {loading ? (
          <div className="text-center mb-5">
            <CircularProgress color={'primary'} />
          </div>
        ) : (
          <div className="row">
            <div className="col d-flex align-items-center">
              <div className="media_controls py-2 px-3">
                {status === 'paused' ? (
                  <PlayCircleOutline color={'primary'} className="pointer align-middle" onClick={this.playPause} />
                ) : (
                  <PauseCircleOutline color={'secondary'} className="pointer align-middle" onClick={this.playPause} />
                )}
              </div>
              <span className="ml-2">
                <small>
                  {currentMinutes}:{currentSeconds < 10 ? `0${currentSeconds}` : currentSeconds}/{durationMinutes}:
                  {durationSeconds < 10 ? `0${currentSeconds}` : durationSeconds}
                </small>
              </span>
            </div>
            <div className="col-auto">
              <Button onClick={this.downloadFile}>Download</Button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

Waveform.propTypes = {
  src: PropTypes.any.isRequired,
  isBlob: PropTypes.bool.isRequired
}

Waveform.defaultProps = {
  isBlob: false
}

export default Waveform
