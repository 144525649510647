import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Toolbar,
  Typography,
  Checkbox
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import _ from 'lodash'
import moment from 'moment'
import CKEditor from 'ckeditor4-react'

import agent from 'agent'
import { UPDATE_STUDENT } from 'constants/actionTypes'
import educationLevels from 'assets/education_level.json'
import frequency from 'assets/frequency.json'

const mapStateToProps = state => ({
  isLoading: state.common.isLoading,
  userUpdated: state.common.userUpdated
})

const mapDispatchToProps = dispatch => ({
  updateStudent: (userId, userObj) =>
    dispatch({ type: UPDATE_STUDENT, payload: agent.Auth.updateStudent(userId, userObj) })
})

class DialogUserEdit extends React.Component {
  state = {
    email: '',
    name: '',
    password: '',
    dateOfBirth: '',
    gender: 'nil',
    phoneCountryCode: 65,
    phone: '',
    level: '',
    othersLevel: '',
    showOthersLevel: false,
    school: '',
    firstLanguage: '',
    facebook: '',
    line: '',
    grades: '',
    studyMediumEng: false,
    studyMediumThai: false,
    courseType: '',
    examType: '',
    otherSchoolYes: false,
    otherSchoolNo: true,
    otherSchoolName: '',
    showSchoolName: false,
    parentSalute: 'Mr',
    parentName: '',
    parentEmail: '',
    parentPhoneCountryCode: '',
    parentPhone: '',
    contactWhatsapp: false,
    contactLine: false,
    contactLineId: '',
    contactOthers: false,
    contactOthersChannel: '',
    studentBackground: ''
  }

  componentDidMount() {
    const { userData } = this.props
    if (userData) {
      const { parent } = userData
      let displayLevel = '',
        showOthersLevel = false,
        othersLevel = '',
        showSchoolName = false
      let parentPhoneCountryCode = 65

      if (userData.level) {
        let levelObj = _.find(educationLevels, function (o) {
          return o.code === userData.level
        })
        if (levelObj) {
          displayLevel = levelObj.code
        } else {
          showOthersLevel = true
          othersLevel = userData.level
          displayLevel = 'others'
        }
      }

      if (parent?.phone_country_code) {
        parentPhoneCountryCode = parent.phone_country_code
      } else {
        parentPhoneCountryCode = userData.phone_country_code
      }

      if (userData.otherSchoolYes === true) {
        showSchoolName = true
      }

      this.setState({
        email: userData.email,
        name: userData.name ?? '',
        dateOfBirth: userData.date_of_birth ? moment(userData.date_of_birth).startOf('day').format('YYYY-MM-DD') : '',
        gender: userData.gender ?? 'nil',
        phoneCountryCode: userData.phone_country_code,
        phone: userData.phone ?? '',
        level: displayLevel,
        othersLevel,
        showOthersLevel,
        school: userData.school ?? '',
        firstLanguage: userData.first_language ?? '',
        facebook: userData.facebook ?? '',
        line: userData.line ?? '',
        grades: userData.grades ?? '',
        studyMediumEng: userData.studyMediumEng ? true : false,
        studyMediumThai: userData.studyMediumThai ? true : false,
        courseType: userData.courseType ?? '',
        examType: userData.examType ?? '',
        otherSchoolYes: userData?.otherSchoolYes ? true : false,
        otherSchoolNo: userData?.otherSchoolNo ? true : false,
        otherSchoolName: userData.otherSchoolName ?? '',
        showSchoolName,
        punctual: userData.punctual,
        examDate: userData.examDate ? moment(userData.examDate).startOf('day').format('YYYY-MM-DD') : '',
        cancelClass: userData.cancelClass,
        questionEngagement: userData.questionEngagement,
        parentSalute: parent?.salute ?? 'Mr',
        parentName: parent?.name ?? '',
        parentEmail: parent?.email ?? '',
        parentPhoneCountryCode,
        parentPhone: parent?.phone ?? '',
        contactWhatsapp: parent?.contact_preference?.whatsapp ? true : false,
        contactLine: parent?.contact_preference?.line ? true : false,
        contactLineId: parent?.contact_preference?.line_id ?? '',
        contactOthers: parent?.contact_preference?.others ? true : false,
        contactOthersChannel: parent?.contact_preference?.others_detail ?? '',
        studentBackground: userData.meta?.background ?? ''
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isLoading !== prevProps.isLoading) {
      this.props.onSave()
    }
  }

  handleChange = event => {
    const type = event.target.name
    const value = event.target.value
    this.setState({ [type]: value })

    if (type === 'level') {
      if (value === 'others') {
        this.setState({ showOthersLevel: true })
      } else {
        this.setState({ showOthersLevel: false })
      }
    }
  }

  handleCheckboxChange = (e, checked) => {
    this.setState({ [e.target.name]: checked })
  }

  handleEditorChange = targetName => e => {
    this.setState({
      [targetName]: e.editor.getData()
    })
  }

  saveChanges = () => {
    const { userData } = this.props
    const {
      name,
      dateOfBirth,
      gender,
      level,
      othersLevel,
      school,
      firstLanguage,
      facebook,
      line,
      grades,
      studyMediumEng,
      studyMediumThai,
      courseType,
      examType,
      otherSchoolYes,
      otherSchoolNo,
      otherSchoolName,
      punctual,
      examDate,
      cancelClass,
      questionEngagement,
      parentSalute,
      parentName,
      parentEmail,
      parentPhoneCountryCode,
      parentPhone,
      contactWhatsapp,
      contactLine,
      contactLineId,
      contactOthers,
      contactOthersChannel,
      studentBackground
    } = this.state

    let userDoc = {
      name
    }

    userDoc.gender = gender
    userDoc.date_of_birth = moment(dateOfBirth).startOf('day').toISOString()
    userDoc.level = level === 'others' ? othersLevel : level
    userDoc.school = school
    userDoc.first_language = firstLanguage

    userDoc.facebook = facebook
    userDoc.line = line
    userDoc.grades = grades
    userDoc.studyMediumEng = studyMediumEng
    userDoc.studyMediumThai = studyMediumThai
    userDoc.courseType = courseType
    userDoc.examType = examType
    userDoc.otherSchoolYes = otherSchoolYes
    userDoc.otherSchoolNo = otherSchoolNo
    userDoc.otherSchoolName = otherSchoolName

    userDoc.punctual = punctual
    userDoc.examDate = moment(examDate).startOf('day').toISOString()
    userDoc.cancelClass = cancelClass
    userDoc.questionEngagement = questionEngagement

    userDoc.parent = {
      salute: parentSalute,
      name: parentName,
      email: parentEmail,
      phone_country_code: parentPhoneCountryCode,
      phone: parentPhone,
      contact_preference: {
        whatsapp: contactWhatsapp,
        line: contactLine,
        line_id: contactLineId ?? '',
        others: contactOthers,
        others_detail: contactOthersChannel ?? ''
      }
    }

    if (studentBackground) {
      userDoc['meta'] = {
        background: studentBackground
      }
    }

    this.props.updateStudent(userData._id, userDoc)
  }

  render() {
    const { isOpen, toggle, userData, isLoading } = this.props
    const {
      name,
      email,
      dateOfBirth,
      gender,
      level,
      othersLevel,
      showOthersLevel,
      school,
      firstLanguage,
      facebook,
      line,
      grades,
      studyMediumEng,
      studyMediumThai,
      courseType,
      examType,
      otherSchoolYes,
      otherSchoolNo,
      otherSchoolName,
      showSchoolName,
      punctual,
      examDate,
      cancelClass,
      questionEngagement,
      contactWhatsapp,
      contactLine,
      contactLineId,
      contactOthers,
      contactOthersChannel,
      studentBackground
    } = this.state

    const toolbarGroups = [
      { name: 'basicstyles', groups: ['basicstyles', 'Underline'] },
      { name: 'paragraph', groups: ['list', 'align', 'bidi', 'links'] }
    ]

    const CkConfig = {
      toolbarGroups
    }

    return (
      <Dialog disableEnforceFocus open={isOpen} onClose={toggle} fullScreen={true}>
        <AppBar className="position-relative">
          <Toolbar>
            <IconButton onClick={toggle} aria-label="Close" className="text-white">
              <CloseIcon />
            </IconButton>
            <Typography
              type="title"
              color="inherit"
              style={{
                flex: 1
              }}>
              {'Edit User'}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ padding: '24px' }}>
          <div className="row">
            <div className="col-md">
              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <FormControl className={'mb-3 w-100'}>
                  <TextField label={'Name'} name={'name'} value={name} onChange={this.handleChange} />
                </FormControl>
              </Paper>
            </div>

            <div className="col-12">
              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <div className="row">
                  <div className="col-auto mb-3">
                    <FormLabel component="legend">Student Characteristics</FormLabel>

                    <div className="d-flex align-items-center">
                      <FormControl>
                        <FormLabel component="legend">Next exam date</FormLabel>
                        <TextField type="date" name="examDate" onChange={this.handleChange} value={examDate} />
                        <FormHelperText>Put exam name below.</FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                  <div className="col-auto mb-3 mt-md-4">
                    <div className="d-flex align-items-center">
                      <FormControl>
                        <FormLabel component="legend">Student interactivity</FormLabel>
                        <Select value={questionEngagement} onChange={this.handleChange} name="questionEngagement">
                          {frequency.map(level => {
                            return (
                              <MenuItem key={level.code} value={level.code}>
                                {level.name}
                              </MenuItem>
                            )
                          })}
                        </Select>
                        <FormHelperText>Does the student answer questions?</FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                  <div className="col-auto mb-3 mt-md-4">
                    <div className="d-flex align-items-center">
                      <FormControl>
                        <FormLabel component="legend">Late to class?</FormLabel>
                        <Select value={punctual} onChange={this.handleChange} name="punctual">
                          {frequency.map(level => {
                            return (
                              <MenuItem key={level.code} value={level.code}>
                                {level.name} ({level.rating})
                              </MenuItem>
                            )
                          })}
                        </Select>
                        <FormHelperText>If late, put date below.</FormHelperText>
                      </FormControl>
                    </div>
                  </div>

                  <div className="col-auto mb-3 mt-md-4">
                    <div className="d-flex align-items-center">
                      <FormControl>
                        <FormLabel component="legend">Cancel classes often?</FormLabel>
                        <Select value={cancelClass} onChange={this.handleChange} name="cancelClass">
                          {frequency.map(level => {
                            return (
                              <MenuItem key={level.code} value={level.code}>
                                {level.name} ({level.rating})
                              </MenuItem>
                            )
                          })}
                        </Select>
                        <FormHelperText>If cancelled class, put date & reason below.</FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </Paper>
            </div>

            <div className="col-12">
              <div className="row">
                <div className="col-md">
                  <Paper elevation={2} className={'app-wrapper mb-3'}>
                    <FormLabel component="legend">Education Background</FormLabel>
                    <div className="col"></div>
                    <FormControl className={'mb-3 w-100'}>
                      <TextField
                        label={'Exam Grades (GPA)'}
                        name={'grades'}
                        value={grades}
                        onChange={this.handleChange}
                      />
                    </FormControl>

                    <FormLabel className={'mt-3'} component="legend">
                      Medium of study
                    </FormLabel>

                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={'studyMediumEng'}
                            checked={studyMediumEng}
                            onChange={this.handleCheckboxChange}
                          />
                        }
                        label="English"
                      />
                    </FormControl>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={'studyMediumThai'}
                            checked={studyMediumThai}
                            onChange={this.handleCheckboxChange}
                          />
                        }
                        label="Thai"
                      />
                    </FormControl>
                    <FormControl className={'mb-3 w-100'}>
                      <TextField
                        label={'Type of courses taken'}
                        name={'courseType'}
                        value={courseType}
                        onChange={this.handleChange}
                      />
                    </FormControl>
                    <FormControl className={'mb-3 w-100'}>
                      <TextField
                        label={'Type of exams taken'}
                        name={'examType'}
                        value={examType}
                        onChange={this.handleChange}
                      />
                    </FormControl>

                    <FormLabel className={'mt-3'} component="legend">
                      Currently studying with another school?
                    </FormLabel>
                    <div className="row">
                      <div className="col-auto">
                        <FormControlLabel
                          control={
                            <Radio
                              color="primary"
                              checked={otherSchoolYes}
                              onChange={() => {
                                this.setState({
                                  otherSchoolYes: true,
                                  showSchoolName: true,
                                  otherSchoolNo: false
                                })
                              }}
                            />
                          }
                          label="Yes"
                        />
                      </div>

                      {showSchoolName && (
                        <div className="col-md-3 col-6">
                          <TextField
                            type="text"
                            placeholder="Name of school"
                            name="otherSchoolName"
                            onChange={this.handleChange}
                            fullWidth
                            defaultValue={otherSchoolName}
                          />
                        </div>
                      )}

                      <div className="col-auto">
                        <FormControlLabel
                          control={
                            <Radio
                              color="primary"
                              checked={otherSchoolNo}
                              onChange={() => {
                                this.setState({
                                  otherSchoolYes: false,
                                  showSchoolName: false,
                                  otherSchoolNo: true
                                })
                              }}
                            />
                          }
                          label="No"
                        />
                      </div>
                    </div>
                  </Paper>
                </div>
              </div>
            </div>

            <div className="col-12">
              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <div className="row">
                  <div className="col-auto mb-3">
                    <FormControl>
                      <FormLabel component="legend">Gender</FormLabel>
                      <RadioGroup row aria-label="gender" name="gender" value={gender} onChange={this.handleChange}>
                        <FormControlLabel value="f" control={<Radio color="primary" />} label="Female" />
                        <FormControlLabel value="m" control={<Radio color="primary" />} label="Male" />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="col-auto mb-3">
                    <FormControl>
                      <FormLabel component="legend">Date of birth</FormLabel>
                      <TextField type="date" name="dateOfBirth" onChange={this.handleChange} value={dateOfBirth} />
                    </FormControl>
                  </div>
                  <div className="col-6 mb-3">
                    <FormLabel component="legend">School</FormLabel>
                    <TextField
                      className="w-100 mb-3"
                      type="text"
                      name="school"
                      onChange={this.handleChange}
                      value={school}
                    />
                  </div>
                  <div className="col-auto mb-3">
                    <div className="d-flex align-items-center">
                      <FormControl>
                        <FormLabel component="legend">Level</FormLabel>
                        <Select value={level} onChange={this.handleChange} name="level">
                          {educationLevels.map(level => {
                            return (
                              <MenuItem key={level.code} value={level.code}>
                                {level.name}
                              </MenuItem>
                            )
                          })}
                        </Select>
                        <FormHelperText>
                          Grade/ Level in School (as of January {moment().format('YYYY')})
                        </FormHelperText>
                      </FormControl>

                      {showOthersLevel && (
                        <TextField
                          type="text"
                          placeholder="Please specify other level"
                          name="othersLevel"
                          onChange={this.handleChange}
                          defaultValue={othersLevel}
                          className="ml-2"
                        />
                      )}
                    </div>
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col">
                    <FormLabel component="legend">Contact Preference</FormLabel>
                  </div>
                  <div className="col-12 ml-3">
                    <div className="row">
                      <div className="col-12">
                        <FormControlLabel
                          control={
                            <Radio
                              color="primary"
                              checked={contactWhatsapp}
                              onChange={() => {
                                this.setState({
                                  contactWhatsapp: true,
                                  contactLine: false,
                                  contactOthers: false
                                })
                              }}
                            />
                          }
                          label="Whatsapp"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-auto">
                        <FormControlLabel
                          control={
                            <Radio
                              color="primary"
                              checked={contactLine}
                              onChange={() => {
                                this.setState({
                                  contactWhatsapp: false,
                                  contactLine: true,
                                  contactOthers: false
                                })
                              }}
                            />
                          }
                          label="LINE"
                        />
                      </div>
                      <div className="col-md-3 col-6">
                        <TextField
                          type="text"
                          placeholder="LINE ID"
                          name="contactLineId"
                          onChange={this.handleChange}
                          fullWidth
                          defaultValue={contactLineId}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-auto">
                        <FormControlLabel
                          control={
                            <Radio
                              color="primary"
                              checked={contactOthers}
                              onChange={() => {
                                this.setState({
                                  contactWhatsapp: false,
                                  contactLine: false,
                                  contactOthers: true
                                })
                              }}
                            />
                          }
                          label="Other"
                        />
                      </div>
                      <div className="col-md-3 col-6">
                        <TextField
                          type="text"
                          placeholder="e.g. phone call, SMS, e-mail"
                          name="contactOthersChannel"
                          onChange={this.handleChange}
                          fullWidth
                          defaultValue={contactOthersChannel}
                          className="mt-0 mb-2"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                <div className="row">
                  <div className="col">
                    <FormLabel component="legend">Social Media</FormLabel>
                  </div>
                </div>

                <FormControl className={'mb-3 w-100'}>
                  <TextField
                    label={'Facebook'}
                    name={'facebook'}
                    value={facebook}
                    placeholder="https://www.facebook.com/zuck"
                    onChange={this.handleChange}
                  />
                </FormControl>

                <FormControl className={'w-100'}>
                  <TextField label={'Line'} name={'line'} value={line} onChange={this.handleChange} />
                </FormControl>
              </Paper>

              <Paper elevation={2} className="app-wrapper mb-3">
                <FormLabel component="legend">Additional Information</FormLabel>
                <FormControl className="w-100">
                  <CKEditor
                    name="studentBackground"
                    data={studentBackground}
                    config={CkConfig}
                    onChange={this.handleEditorChange('studentBackground')}
                  />
                  <FormHelperText>
                    e.g. Cancelled 18/7 class due to sickness <br /> e.g. BMAT Exam - 6/7
                  </FormHelperText>
                </FormControl>
              </Paper>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant={'text'} color={'default'} onClick={toggle}>
            Cancel
          </Button>
          <Button
            disabled={!name || !email || isLoading}
            variant="contained"
            color="primary"
            onClick={this.saveChanges}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DialogUserEdit.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  userData: PropTypes.object
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DialogUserEdit))
