import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import { Button } from '@material-ui/core'
import { Videocam } from '@material-ui/icons'
import PropTypes from 'prop-types'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

const TutorProfile = props => {
  const { tutor } = props
  let enableNavigation = tutor.length > 1 ? true : false

  return (
    <div className="tutor-profile" id="tutor-profile">
      <h2>Teacher Profile</h2>
      <Carousel showThumbs={false} showIndicators={enableNavigation} showArrows={enableNavigation} showStatus={false}>
        {tutor.map((t, index) => {
          return (
            <div
              key={index}
              className={`row
                ${enableNavigation && index > 0 ? 'pl-5' : ''}
                ${enableNavigation && index < tutor.length - 1 ? 'pr-5' : ''}`}>
              <div className="col-3 d-none d-md-block">
                <img src={t.profile_pic} alt={t.name} className="img-fluid rounded mb-2 shadow" />
                {t.meta?.video?.intro && (
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={props.showIntroVideo(t.meta.video.intro)}>
                    <Videocam className="mr-2" />
                    Watch Intro
                  </Button>
                )}
              </div>
              <div className="col-12 d-md-none">
                <div className="row mb-3">
                  <div className="col-6">
                    <img src={t.profile_pic} alt={t.name} className="img-fluid rounded" />
                  </div>
                  <div className="col-6 align-self-center">
                    <h3>Teacher {t.name}</h3>
                    {t.meta?.video?.intro && (
                      <Button variant="outlined" color="primary" onClick={props.showIntroVideo(t.meta.video.intro)}>
                        <Videocam className="mr-2" />
                        Watch Intro
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              <div className="col">
                <h3 className="d-none d-md-block text-left">Teacher {t.name}</h3>
                <div dangerouslySetInnerHTML={{ __html: t.desc }} className="text-justify mb-md-4 mb-3" />
              </div>
            </div>
          )
        })}
      </Carousel>
    </div>
  )
}

TutorProfile.propTypes = {
  tutor: PropTypes.array.isRequired
}

export default TutorProfile
