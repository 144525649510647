export const DepartmentTeacher = [
  {
    tags: 'language',
    languageDepartmentPage: 0,
    languageDepartmentPage: 0,
    page: 0,
    tagCA: 'yes',
    // name: 'Anuparb Chayakul (Kru Golf)',
    name: 'Kru Golf',
    department: 'English',
    expertise: 'IELTS, SAT, SSAT, TOEFL',
    experience: '10+ years experience',
    education: 'Masters in East Asian Studies',
    // education: 'MA in East Asian Studies, Sogang University, South Korea',
    rexperience: '-',
    texperience: '10+ years experience',
    curriculum: 'Key-stage (Yr. 6-8), IGCSE (Yr. 9-11), Standardized exam (SAT, BMAT, IELTS)',
    display: 'no',
    teachInEnglish: 'yes'
  },
  {
    tags: 'language',
    languageDepartmentPage: 0,
    page: 0,
    // name: 'Phantira Waranukrohchok (Kru Jip)',
    name: 'Kru Jip',
    department: 'English Literature',
    expertise: 'English Language, English Literature, Essay Writing',
    education: 'Teaching Certificate Masters in Education & Technology',
    // education: `Master of Arts in Education and Technology, University College London, England, 2018 (Merit);
    //   Bachelor of Arts, second class honour, Chulalongkorn University, Bangkok, Thailand 2009; English Major, Entrepreneurship Minor`,
    experience: '13+ years experience',
    rexperience: '6 published books, translated 2 IELTS books',
    texperience: '10+ years teaching English',
    curriculum: 'Key-stage (Yr. 6-8), IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, Standardized exam (SAT, BMAT, IELTS)',
    display: 'yes',
    teachInEnglish: 'yes',
    image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+Jip.jpeg'
  },
  {
    tags: 'language',
    languageDepartmentPage: 0,
    page: 0,
    // name: 'Pitchaon Harnskuldee​​ (Kru Gift)',
    name: 'Kru Gift',
    department: 'Thai',
    expertise: 'Thai',
    education: 'Bachelor of Arts, Chulalongkorn University',
    rexperience:
      '4+ years of working experience as a liaison and translator, 2+ years of teaching in Thai for foreigners',
    texperience: '4+ years teaching Thai',
    curriculum: 'Key-stage (Yr. 6-8), IGCSE (Yr. 9-11), IB',
    display: 'yes',
    teachInEnglish: 'no',
    image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+Gift.jpg'
  },
  {
    tags: 'language',
    languageDepartmentPage: 0,
    page: 0,
    // name: 'Thitirat Raklao ​​(Kru Mint)',
    name: 'Kru Mint',
    department: 'English',
    expertise: 'English for Academic Writing, English for specific purposes',
    education:
      'BA in German (Chiang Mai University); MA in Linguistics (Mahidol University); Currently a PhD student (Potsdam University, Germany)',
    rexperience: '5+ years of experience as English mentor',
    texperience: '5+ years teaching English',
    curriculum:
      'IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, Standardized exam (SAT, BMAT, IELTS), English for Academic Writing and German',
    display: 'no',
    teachInEnglish: 'yes',
    image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+Mint.jpg'
  },
  // {
  //   tags: 'language',
  //   languageDepartmentPage: 0,
  //   page: 0,
  //   // name: '​Amanda Ellis ​(Kru Amanda)',
  //   name: 'Kru Amanda',
  //   department: 'English',
  //   expertise: 'English, UK national curriculum, IGCSE, IELTS',
  //   education: 'Bosc. Architectural Design, Chulalongkorn University, M.arch Urban Design, University college London',
  //   rexperience: 'Guest Lecturer and studio instructor at Thammasat Design School',
  //   texperience: '3 years teaching English',
  //   curriculum: 'Key-stage (Yr. 6-8), IGCSE (Yr. 9-11), Standardized exam (SAT, BMAT, IELTS)',
  //   display: 'no',
  //   teachInEnglish: 'yes'
  // },
  {
    tags: 'language',
    languageDepartmentPage: 0,
    page: 0,
    // name: '​Kelly Loh ​(Kru Kelly)',
    name: 'Kru Kelly',
    department: 'English',
    expertise:
      'Preparation for IGCSE English, Language and Literature. Preparation for A Level English and Literature. Preparation for IB English Language and Literature. Writing personal statements for college applications. Professional English for business communication and presentations',
    education: `BA in English literature, King's College, London. TEFL Certification, TEFL International, Phuket`,
    rexperience:
      '3+ years working at a London publishing house as a junior assistant editor. 2+ years working with business professionals looking to communicate more effectively in the corporate world.',
    texperience:
      'Nearly two decades of experience teaching high school students and preparing them to enter elite universities and colleges.',
    curriculum:
      'IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, Standardized exam (SAT, BMAT, IELTS), College applications/personal statements',
    display: 'no',
    teachInEnglish: 'yes'
  },
  {
    tags: 'language',
    languageDepartmentPage: 0,
    page: 0,
    // name: '​Luke Smith ​(Kru Luke)',
    name: 'Kru Luke',
    department: 'English',
    expertise: 'English Literature and Language, IELTS preparation, personal statement writing',
    education:
      'BA in English Literature, University of Brighton; MA in English Literature and Language, Freie Universität Berlin',
    rexperience: 'Worked as a professional and academic editor and proofreader in the UK, Germany and Thailand',
    texperience: '3+ years teaching English',
    curriculum: 'Key-stage (Yr. 6-8), IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, Standardized exam (SAT, BMAT, IELTS)',
    display: 'no',
    teachInEnglish: 'yes'
  },
  {
    tags: 'science',
    scienceDepartmentPage: 0,
    tagOthers: 'yes',
    page: 0,
    // name: 'Phuwadej Pornaroontham (Kru Team)',
    name: 'Kru Team',
    department: 'Chemistry',
    expertise: 'Material, chemical engineering research',
    education: '2 yrs assistant professor at Nagoya University',
    // education: 'Ph.D in Science (Chemical Technology), Chulalongkorn University',
    experience: ' Ph.D in Chemical Technology at Chulalongkorn University',
    rexperience: '2 years as assistant professor at Nagoya University',
    texperience: '10+ years (Thai and Inter curricula)',
    curriculum:
      'IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, Standardized exam (SAT, BMAT, IELTS), University level e.g. general chemistry, organic chemistry, Thermodynamics',
    display: 'yes',
    teachInEnglish: 'yes'
  },
  {
    tags: 'science',
    scienceDepartmentPage: 0,
    page: 0,
    // name: '​​Jirachote Karunyakorn (Kru Sun)',
    name: 'Kru Sun',
    department: 'Biology',
    expertise: 'Bioinformatics',
    education:
      'M.Sc. in Bioinformatics and Computational Biology Program, Chulalongkorn University; B.Sc. in Biology, Mahidol University',
    rexperience: '3+ years of Bioinformatics academic research',
    texperience: '4+ years teaching Biology',
    curriculum: 'IGCSE (Yr. 9-11)',
    display: 'yes',
    teachInEnglish: 'yes'
  },
  {
    tags: 'science',
    scienceDepartmentPage: 0,
    page: 0,
    // name: 'Akarapitch Siripraparat (Kru Pea)',
    name: 'Kru Pea',
    department: 'Chemistry',
    expertise: 'Researching in related field, Teaching chemistry',
    education: 'Ph.D Chemistry, Faculty of Science, Department of Chemistry, Mahidol University',
    rexperience: 'Part-time teacher assistant',
    texperience: '3+ years teaching Chemistry',
    curriculum: 'IGCSE (Yr. 9-11)',
    display: 'yes',
    teachInEnglish: 'yes',
    image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+Pea.jpg'
  },
  {
    tags: 'science',
    scienceDepartmentPage: 0,
    page: 1,
    // name: '​​Sukosin Thongrattanasiri (Kru Suko)',
    name: 'Kru Suko',
    department: 'Physics',
    expertise: 'Physics Education',
    education: 'PhD in Physics',
    rexperience: '-',
    texperience: '10 years teaching Physics',
    curriculum: 'A-level (Yr. 12-13), IB, AP1, AP2, APC, U1, U2',
    display: 'no',
    teachInEnglish: 'yes'
  },
  // {
  //   tags: 'science',
  //   scienceDepartmentPage: 0,
  //   page: 1,
  //   name: '​​Kamol Wasapinyokul (Kru Tum)',
  //   department: 'Physics',
  //   expertise: '-',
  //   education: 'PhD in Engineering',
  //   rexperience: '-',
  //   texperience: '10+ years (Highschool)',
  //   curriculum: 'IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, Standardized exam (SAT, BMAT, IELTS)',
  //   display: 'no',
  //   teachInEnglish: 'yes'
  // },
  {
    tags: 'science',
    scienceDepartmentPage: 0,
    page: 1,
    // name: 'Kriengkamol Teerachanan (Kru Tee​​)',
    name: 'Kru Tee​​',
    department: 'Chemistry',
    expertise: 'Chemistry',
    education:
      'M.Sc Material Science and Engineering (Inorganic Chemistry), Mahidol University; B.Sc Chemistry, Mahidol University',
    rexperience: '2 years Teacher Assistant in Organic Laboratory at Mahidol University',
    texperience: '8+ years (International Education System)',
    curriculum: 'IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, Standardized exam (SAT, BMAT, IELTS)',
    display: 'yes',
    teachInEnglish: 'no'
  },
  {
    tags: 'science',
    scienceDepartmentPage: 0,
    page: 1,
    // name: '​​Nattachai Jeerawatana (Kru Art)',
    name: 'Kru Art',
    department: 'Physics',
    expertise: '-',
    education: 'Master degree in Physics, Mahidol University',
    rexperience: '-',
    texperience: '2+ years teaching Mathematics and Physics',
    curriculum: 'Key-stage (Yr. 6-8), IGCSE (Yr. 9-11), A-level (Yr. 12-13)',
    display: 'no',
    teachInEnglish: 'no'
  },
  {
    tags: 'science',
    scienceDepartmentPage: 0,
    page: 1,
    // name: '​​Chanakarn Sripong (Kru Joobjang)',
    name: 'Kru Joobjang',
    department: 'Biology',
    expertise: 'Biology especially IGCSE and A-level, Infertility treatment, Medical technology',
    education: 'BSc in Biology, Mahidol University; MSc in Physiology, Mahidol University',
    rexperience: '7+ years of medical research and 4+ of Scientist in IVF',
    texperience: '5+ years teaching Biology',
    curriculum: 'IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB',
    display: 'no',
    teachInEnglish: 'yes'
  },
  {
    tags: 'science',
    scienceDepartmentPage: 0,
    page: 1,
    // name: 'Supinda Luangkamchorn ​​(Kru Lew)',
    name: 'Kru Lew',
    department: 'Biology',
    expertise: 'Researcher in Analytical Science',
    education: 'Ph.D. in Biology, Mahidol University',
    rexperience:
      'One year research experience in the Department of Nutitional Science at University of Wisconsin-Madison, USA',
    texperience: '8+ years (IGCSE Biology and KS3 Science)',
    curriculum: 'Key-stage (Yr. 6-8), IGCSE (Yr. 9-11), A-level (Yr. 12-13)',
    display: 'yes',
    teachInEnglish: 'yes'
  },
  {
    tags: 'science',
    scienceDepartmentPage: 0,
    page: 1,
    // name: 'Chairote Piyakulworawat ​​(Kru Jay)',
    name: 'Kru Jay',
    department: 'Physics',
    expertise: 'Experimental research in strongly correlated electron systems',
    education: `Bachelor's degree in Science (Physics); Ph.D. in physics`,
    rexperience: '8+ years of research experience in Quantum Magnetism Group at Mahidol University',
    texperience: '4+ years teaching Physics',
    curriculum: 'IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB',
    display: 'no',
    teachInEnglish: 'yes'
  },
  {
    tags: 'science',
    scienceDepartmentPage: 1,
    page: 1,
    // name: '​Parinya Kareeso ​(Kru Champ)',
    name: 'Kru Champ',
    department: 'Physics',
    expertise: 'Physics and Math',
    education: 'M.Sc. in Physics, Chulalongkorn university',
    rexperience: 'Cern summer student program 2015 in Geneva, Switzerland',
    texperience: '7+ years teaching Physics',
    curriculum: 'IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, Standardized exam (SAT, BMAT, IELTS)',
    display: 'no',
    teachInEnglish: 'no',
    image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+Champ.jpg'
  },
  {
    tags: 'science',
    scienceDepartmentPage: 1,
    page: 1,
    // name: 'Som-a-nong Jindapaphan ​​(Kru Betty)',
    name: 'Kru Betty',
    department: 'Chemistry',
    expertise: 'A-level,IGCSE-Chemistry. Middle school-Mathematics',
    education: `M.Sc in Material science and engineering, Mahidol University (international program); B.Sc in Chemistry, Srinakarinwiron University`,
    rexperience:
      '10+ Years of working experience teaching as science & math. Coordinator in the information technology foundation under the initiative of Her Royal Highness Princess Maha Chakri Sirindhorn.',
    texperience:
      '10+ years teaching Chemistry, 5+ years teaching GED math & science, 3+ years teaching Math of middle school',
    curriculum:
      'Key-stage (Yr. 6-8), IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, Standardized exam (SAT, BMAT, IELTS), GED',
    display: 'yes',
    teachInEnglish: 'yes'
  },
  {
    tags: 'science',
    scienceDepartmentPage: 1,
    page: 1,
    // name: 'Pimlada Rangsrithanachailert ​​(Kru Bell)',
    name: 'Kru Bell',
    department: 'Chemistry',
    expertise: 'Chemistry',
    education: 'B.Sc in chemistry  at Chulongkorn University; (Coursework) M.Ed.Science at Chulalongkorn University',
    rexperience: '7+ years of working experience as laboratory analyst',
    texperience: '10+ years teaching chemistry',
    curriculum: 'Key-stage (Yr. 6-8), IGCSE (Yr. 9-11), Standardized exam (SAT, BMAT, IELTS)',
    display: 'yes',
    teachInEnglish: 'no'
  },
  {
    tags: 'science',
    scienceDepartmentPage: 1,
    page: 2,
    // name: '​Weerawan Wichitranchuan ​(Kru Immie)',
    name: 'Kru Immie',
    department: 'Chemistry',
    expertise: 'Chemistry amd secondary science',
    education:
      'B.Sc in Earth Science (Gemmology), Kasetsart University, Graduate Colored Stones Diploma of Gemological Institute of America.',
    rexperience: '8+ years of working experience as chemistry teacher in English program at leading schools.',
    texperience: '10+ years teaching Chemistry',
    curriculum: 'IGCSE (Yr. 9-11)',
    display: 'yes',
    teachInEnglish: 'yes',
    image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+Immie.jpeg'
  },
  {
    tags: 'science',
    scienceDepartmentPage: 1,
    page: 2,
    // name: 'อภิรัตน์ คำอรรถ ​​(Kru Mac)',
    name: 'Kru Mac',
    department: 'Biology',
    expertise: 'Molecular biology',
    education: 'B.Sc in Biotecnology and M.Sc in Biochemistry from Mahidol University',
    rexperience: '-',
    texperience: '8+ years teaching biology',
    curriculum: 'Key-stage (Yr. 6-8), IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, Standardized exam (SAT, BMAT, IELTS)',
    display: 'no',
    teachInEnglish: 'yes'
  },
  {
    tags: 'science',
    scienceDepartmentPage: 1,
    page: 2,
    // name: '​Natcha Jitsuk ​(Kru Natcha)',
    name: 'Kru Natcha',
    department: 'Physics',
    expertise: 'Physics/Biophysics, infectious disease modeling',
    education: 'PhD in Physics (Mahidol University)',
    rexperience: '-',
    texperience: '2+ years teaching Physics, 1+ year teaching Mathematics',
    curriculum: 'IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, Standardized exam (SAT, BMAT, IELTS)',
    display: 'yes',
    teachInEnglish: 'yes'
  },
  {
    tags: 'science',
    scienceDepartmentPage: 1,
    page: 2,
    // name: 'Chawinya Trakoolsoontorn ​​(Kru Eve)',
    name: 'Kru Eve',
    department: 'Biology',
    expertise: 'Biology, Microbiology and Immunology',
    education:
      'BA in Biology, Chulalongkorn University; PhD in Microbiology and Immunology, Mahidol University (in progress)',
    rexperience: '6+ Years of working experience as freelance Biology tutor for Thai curriculum students',
    texperience: '6+ Years teaching Biology (Thai curriculum students)',
    curriculum: 'IGCSE (Yr. 9-11)',
    display: 'yes',
    teachInEnglish: 'yes',
    image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+Eve.jpg'
  },
  {
    tags: 'mathematics',
    mathematicsDepartmentPage: 0,
    page: 2,
    // name: '​​Jittichai Lertphinyovong (Kru Jeep)',
    name: 'Kru Jeep',
    department: 'Mathematics',
    expertise: 'Solving math problems.',
    education: 'BEng and MEng Electrical engineering, Chulalongkorn University.',
    rexperience:
      'เคยผ่านการเข้าค่ายอบรมคณิตศาสตร์โอลิมปิก สสวท รอบ 25 คนสุดท้าย และได้รางวัลจากการแข่งขันสมาคณิตศาสตร์แห่งประเทศไทย (ไม่แน่ใจว่าเข้าข่ายหรือเปล่านะครับ)',
    texperience: '19 years teaching Mathematics',
    curriculum:
      'Key-stage (Yr. 6-8), IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, Standardized exam (SAT, BMAT, IELTS), University level and Contest (ยกเว้น decision math)',
    display: 'no',
    teachInEnglish: 'no',
    image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+Jeep.jpg'
  },
  {
    tags: 'mathematics',
    mathematicsDepartmentPage: 0,
    page: 2,
    // name: '​​Itthi Chatnuntawech (Kru Kanoon)',
    name: 'Kru Kanoon',
    department: 'Mathematics',
    expertise: 'Mathematics, Artificial Intelligence, Computer Science',
    education: `S.M. in Electrical Engineering and Computer Science, MIT;
        Ph.D. in Electrical Engineering and Computer Science, MIT`,
    rexperience: '5+ years of working as a research scientist and data scientist',
    texperience: '5+ years of teaching in Mathematics and 3+ years in Signal Processing and Image Processing',
    curriculum:
      'IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, Standardized exam (SAT, BMAT, IELTS), Signal Processing, Linear Algebra, Optimization, Artificial Intelligence',
    display: 'no',
    teachInEnglish: 'yes',
    image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+Kanoon.png'
  },
  {
    tags: 'mathematics',
    mathematicsDepartmentPage: 0,
    page: 2,
    // name: 'Ek Kuwisetsang ​​(Kru Ek)',
    name: 'Kru Ek',
    department: 'Mathematics',
    expertise: 'Mathematics tutoring',
    education: 'BE in Industrial Engineering, Kasetsart University',
    rexperience: '-',
    texperience: '10+ years teaching Mathematics',
    curriculum: 'Key-stage (Yr. 6-8), IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, Standardized exam (SAT, BMAT, IELTS)',
    display: 'yes',
    teachInEnglish: 'yes'
  },
  {
    tags: 'mathematics',
    mathematicsDepartmentPage: 0,
    page: 2,
    // name: 'Kriangkrai Rungruangthaweekul ​​(Kru Kriang)',
    name: 'Kru Kriang',
    department: 'Accounting',
    expertise: 'Accounting, Finance, Business Studies',
    education: 'BBA in Accounting, Thammasat University, MSC in Financial Engineering, Chulalongkorn University',
    rexperience:
      '5+ years of working experience in accounting and finance of pharmaceutical company in Thailand, 2+ years of working experience in financial advisory service field in Thailand, 5+ years of experience as auditor in one of Big4 firm in Thailand',
    texperience: '16+ years in accounting, 15+ years in finance, 15+ years in business studies',
    curriculum: 'IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, University',
    display: 'no',
    teachInEnglish: 'yes'
  },
  {
    tags: 'mathematics',
    mathematicsDepartmentPage: 0,
    page: 2,
    // name: '​Supaporn Kiatpaiboon ​(Kru Ning)',
    name: 'Kru Ning',
    department: 'Mathematics',
    expertise: 'Math, Basic computer programming, ICT',
    education: 'Master of Information Systems Management',
    rexperience: '10+ years of working experience IT',
    texperience: '10 years teaching Math and ICT, 5 years teaching computer science',
    curriculum: 'Key-stage (Yr. 6-8), IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, Computer science AP',
    display: 'yes',
    teachInEnglish: 'yes'
  },
  {
    tags: 'mathematics',
    mathematicsDepartmentPage: 0,
    page: 3,
    // name: '​Pattarasuda Sudsaen ​(Kru Aoy)',
    name: 'Kru Aoy',
    department: 'Mathematics',
    expertise: 'Mathematics and Statistics tutoring',
    education:
      'B.Ed ( first class honors ) in teaching mathematics, Chulalongkorn University; M.Sc ( Statistics), Chulalongkorn University',
    rexperience:
      '17 Years of experience as a teacher and tutor in mathematics for all levels and Statistics in university level',
    texperience: '17 Years teaching Mathematics and 15 Years teaching Statistics ',
    curriculum:
      'Key-stage (Yr. 6-8), IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, Standardized exam (SAT, BMAT, IELTS), Calculus and statistics in university level',
    display: 'no',
    teachInEnglish: 'yes'
  },
  {
    tags: 'mathematics',
    mathematicsDepartmentPage: 0,
    page: 3,
    // name: '​Phairoj Wongwiboonsin ​(Kru House)',
    name: 'Kru House',
    department: 'Mathematics',
    expertise: 'Mathematics, Further Mathematics, Calculus, Mechanics1, Statistics1',
    education:
      'BE in Electrical Engineering, Chulalongkorn University; ME in Electrical Engineering, Chulalongkorn University',
    rexperience: `2 Years as math academic team at "A Level" (by P' Tap)  tutoring school`,
    texperience: '20+ years teaching Mathematics',
    curriculum:
      'Key-stage (Yr. 6-8), IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, Standardized exam (SAT, BMAT, IELTS), secondary math for Thai school/Calculus 1,2 for university',
    display: 'yes',
    teachInEnglish: 'no',
    image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+House.jpg'
  },
  {
    tags: 'mathematics',
    mathematicsDepartmentPage: 0,
    page: 3,
    // name: 'Samach Sathitvudh ​​(Kru Boom)',
    name: 'Kru Boom',
    department: 'Mathematics',
    expertise: 'Mathematics, Statistics, Programming languages for data science and machine learning',
    education:
      'MS in Statistics (Data Science), College of Letters and Science, University of Wisconsin-Madison (Expected graduation in 2023)',
    rexperience:
      '3 years of working experience as data analyst and data scientist in leading company/organization in Thailand',
    texperience: '8+ years teaching Mathematics ',
    curriculum: 'IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, Standardized exam (SAT, BMAT, IELTS)',
    display: 'yes',
    teachInEnglish: 'yes'
  },
  {
    tags: 'mathematics',
    mathematicsDepartmentPage: 0,
    page: 3,
    // name: 'Sasimas Katanyutanon ​​(Kru Por)',
    name: 'Kru Por',
    department: 'Mathematics',
    expertise: 'Heterogeneous catalyst research',
    education: 'BS in Engineering, Harvey Mudd College; Master of Chemical Engineering, Carnegie Mellon University',
    rexperience: '7 years of studing heterogeneous catalysts in USA and Thailand',
    texperience: '3+ years teaching Mathematics',
    curriculum: 'IGCSE (Yr. 9-11), IB, Standardized exam (SAT, BMAT, IELTS)',
    display: 'no',
    teachInEnglish: 'yes'
  },
  {
    tags: 'mathematics',
    mathematicsDepartmentPage: 1,
    page: 3,
    // name: 'Arnon Teawnarong ​​(Kru P)',
    name: 'Kru P',
    department: 'Mathematics',
    expertise: 'Mathematics Consulting, Electrical Engineering Career Advising',
    education: 'M.Eng. in electrical engineering from Chulalongkorn University in Bangkok',
    rexperience:
      '4+ years of working experience as electrical engineer researcher at Chulalongkorn University in Bangkok ',
    texperience: '8+ years teaching Mathematics ',
    curriculum: 'Key-stage (Yr. 6-8), IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, Standardized exam (SAT, BMAT, IELTS)',
    display: 'yes',
    teachInEnglish: 'no',
    image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+P.jpg'
  },
  {
    tags: 'mathematics',
    mathematicsDepartmentPage: 1,
    page: 3,
    // name: 'Krit Chusanapak ​​(Kru Krit)',
    name: 'Kru Krit',
    department: 'Economics',
    expertise: 'Microeconomics, Macroeconomics ',
    education:
      'BA in Economics, Vancouver School of Economics, University of British Columbia; MBA, Sasin Graduate Institute of Business Administration, Chulalongkorn University',
    rexperience: 'Experience working in financial public policies in governmental agencies',
    texperience: '10+ years teaching Economics',
    curriculum: 'IGCSE (Yr. 9-11), A-level (Yr. 12-13), IB, AP,  Selected university courses',
    display: 'yes',
    teachInEnglish: 'yes',
    image: 'https://epa-assets.s3.ap-southeast-1.amazonaws.com/team/Kru+Krit.jpeg'
  }
]
