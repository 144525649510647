import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { ONLINE_CLASS_DETAILS } from 'routes/routes'
import { roundPrice } from 'utils/merchandise'
import RemoveCartItemButton from './button-remove-items'

function populateItemDesc(lessonCount, lessonStart, lessonEnd) {
  let itemDesc = ''
  if (lessonCount === 1) {
    itemDesc = `${moment(lessonStart).format('Do MMMM YYYY')}`
  } else {
    let startMonth = moment(lessonStart)
    let endMonth = moment(lessonEnd)
    if (startMonth.isSame(endMonth, 'month')) {
      itemDesc = `${startMonth.format('Do')} to ${endMonth.format('Do MMMM YYYY')}, ${lessonCount} lessons`
    } else {
      itemDesc = `${startMonth.format('Do MMMM')} to ${endMonth.format('Do MMMM YYYY')}, ${lessonCount} lessons`
    }
  }

  return itemDesc
}

let ItemRow = props => {
  const { itemData, cartItemId } = props

  let itemDesc = populateItemDesc(
    itemData.billable_lesson.length,
    itemData.subscription_started_on,
    itemData.subscription_ended_on
  )

  return (
    <div className="mt-3 border-bottom">
      <div className="row align-items-center">
        <div className="col">
          <div className="py-2">
            <div className="row align-items-center">
              <div className="col">
                <div className="font-weight-semibold">
                  <Link to={ONLINE_CLASS_DETAILS.replace(':classId', itemData.class_obj.id)}>
                    {itemData.class_obj.name}
                  </Link>
                </div>
                <div className="text-muted">{itemDesc}</div>
              </div>
              <div className="col-3">
                <div className="text-md-center text-right font-weight-semibold">
                  <div className={'text-right'}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-auto">
          <div className="d-flex align-items-center">
            {itemData.quote < itemData.original_price && (
              <del className="text-danger mr-1">
                <small className="text-muted">${roundPrice(itemData.original_price)}</small>
              </del>
            )}
            <div className="font-weight-semibold mr-3">${roundPrice(itemData.quote)}</div>

            <RemoveCartItemButton classId={itemData.class_obj.id} cartItemId={cartItemId} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ItemRow
