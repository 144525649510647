import _ from 'lodash'
import * as Type from 'constants/actionTypes'

const initialState = {
  loading: false,
  lessonTickets: { data: [], lessonId: '' },
  updating: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.GET_LESSON_TICKETS:
      return {
        ...state,
        loading: true
      }

    case Type.GET_LESSON_TICKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        lessonTickets: {
          data: action.payload.data,
          lessonId: action.lessonId
        }
      }

    case Type.GET_LESSON_TICKETS_FAILED:
      return {
        ...state,
        loading: false
      }

    case Type.UPDATE_TICKET:
      return {
        ...state,
        updating: true
      }

    case Type.UPDATE_TICKET_SUCCESS: {
      let updatedLessonTickets = state.lessonTickets
      let updatedTicket = action.payload.data

      if (updatedTicket) {
        let existingTicketIndex = _.findIndex(updatedLessonTickets.data, o => {
          return o._id === updatedTicket._id
        })

        if (existingTicketIndex > -1) {
          updatedLessonTickets.data[existingTicketIndex] = updatedTicket
        }
      }

      return {
        ...state,
        updating: false,
        lessonTickets: updatedLessonTickets
      }
    }

    case Type.UPDATE_TICKET_FAILED:
      return {
        ...state,
        updating: false
      }

    default:
      return state
  }
}
