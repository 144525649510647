import * as Type from '../constants/actionTypes'

const initialState = {
  loadingLessonMaterial: {},
  lessonMaterial: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.GET_LESSON_MATERIAL:
      return {
        ...state,
        loadingLessonMaterial: {
          ...state.loadingLessonMaterial,
          [action.lessonId]: true
        }
      }

    case Type.GET_LESSON_MATERIAL_SUCCESS:
      return {
        ...state,
        loadingLessonMaterial: {
          ...state.loadingLessonMaterial,
          [action.lessonId]: false
        },
        lessonMaterial: {
          ...state.lessonMaterial,
          [action.lessonId]: action.payload.data
        }
      }

    case Type.GET_LESSON_MATERIAL_FAILED:
      return {
        ...state,
        loadingLessonMaterial: {
          ...state.loadingLessonMaterial,
          [action.lessonId]: false
        }
      }

    default:
      return state
  }
}
