import React from 'react'
import Proptypes from 'prop-types'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core'
import { Check } from '@material-ui/icons'
import _ from 'lodash'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DialogPollDetail = props => {
  const { isOpen, close, title, questionData, answerData } = props
  let sortedQuestions = questionData ? _.orderBy(questionData, ['index']) : []

  return (
    <Dialog open={isOpen} onClose={close} TransitionComponent={Transition} maxWidth="lg">
      <DialogTitle>
        <span className="h2 mb-2">{title}</span>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableBody>
            {sortedQuestions.map((row, index) => {
              let studentAnswer = _.find(answerData, o => {
                return o.question === row.question
              })
              let correctAnswer = row.answer

              return (
                <TableRow key={index}>
                  <TableCell>
                    <p className="mb-1">
                      <span className="text-muted">Question:</span> {row.question}
                    </p>
                    <p>
                      <span className="text-muted">Correct Answer:</span>&nbsp;
                      {row.answer_index.map(answerIndex => (
                        <span key={answerIndex}>{row.option[answerIndex].label}</span>
                      ))}
                    </p>
                  </TableCell>
                  <TableCell>
                    {row.option.map((opt, index) => {
                      let studentSelected = _.find(studentAnswer, o => {
                        return o === opt.content
                      })
                      let color = '#e0e0e0'
                      if (studentSelected) {
                        if (correctAnswer.includes(studentSelected)) {
                          color = '#4caf50'
                        } else {
                          color = '#E91E63'
                        }
                      }

                      return (
                        <div
                          key={index}
                          className="d-flex align-items-center mb-1 p-1"
                          style={{ border: `1px solid ${color}`, borderRadius: '3px' }}>
                          {studentSelected && <Check className="text-small" style={{ color: color }} />}
                          {opt.label}: {opt.content}
                        </div>
                      )
                    })}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogPollDetail.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired
}

export default DialogPollDetail
