import React from 'react'
import { isMobile } from 'react-device-detect'
import { Avatar, Button, CircularProgress, Dialog, DialogContent, Slide } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Proptypes from 'prop-types'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

// NOTE: This regular tutor profile dialog is to handle tutor who dont have half body shoot and didnt have slug yet
const RegularTutorProfile = props => {
  const { isOpen, close, tutorData } = props

  return (
    <Dialog open={isOpen} onClose={close} TransitionComponent={Transition} maxWidth="md">
      <DialogContent>
        {tutorData ? (
          <div className="row">
            <div className="col-md-auto col-12 pb-2">
              <Avatar
                src={tutorData.profile_pic}
                alt={tutorData.name}
                className={`avatar-shadow mx-auto ${isMobile ? 'size-100' : 'size-180'}`}
              />

              {isMobile && (
                <Close
                  className="position-absolute clickable m-2"
                  onClick={close}
                  style={{ right: '-5px', top: '-15px' }}
                />
              )}
            </div>
            <div className="col align-self-end" id="profile-desc">
              {!isMobile && <h2 className="title--rubik font-weight-normal my-2">Teacher {tutorData.name}</h2>}
              <p className="text-justify" dangerouslySetInnerHTML={{ __html: tutorData.desc }} />
              <div className="text-right">
                <Button variant="outlined" color="primary" onClick={close}>
                  Close
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="m-5">
            <CircularProgress color="primary" size={35} />
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

RegularTutorProfile.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired,
  tutorData: Proptypes.object
}

export default RegularTutorProfile
