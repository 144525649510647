import FeaturedClassCarousel from 'components/misc/featured-class-carousel'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { FeaturedPlayListSharp } from '@material-ui/icons'
import { Helmet } from 'react-helmet'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

class Services extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Our Mission | EPA</title>
          <meta
            name="description"
            content="Enabling students to unleash their potential while embracing the future with confidence and wisdom."
          />
          <meta
            name="keywords"
            content="Attaining Excellence, Unleashing your potential,Bringing out the best in you, Your dream, our mission 
            Nurturing uniqueness, Igniting your passion"
          />
        </Helmet>
        <FeaturedPlayListSharp />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Services))
