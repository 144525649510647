import React from 'react'
import { connect } from 'react-redux'
import { PARENT } from 'constants/userTypes'
import { MenuItem, Tooltip, CircularProgress, CardContent, Card, Popover } from '@material-ui/core'

import { Clear as CancelIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import Chart from 'react-apexcharts'
import { isMobile } from 'react-device-detect'

import agent from 'agent'
const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})
// used in parent view, for tooltip
class ClassReportToolTip extends React.Component {
  state = {
    anchorEl: null,
    punctualityDialog: false,
    cancellationDialog: false,
    homeworkDialog: false,
    revisionNotesDialog: false,
    engagementDialog: false,
    masteryDialog: false
  }

  handlePopoverOpen = (event, value) => {
    this.setState({ anchorEl: event.currentTarget })
    if (value === 'punctual') {
      this.setState({
        punctualityDialog: true,
        cancellationDialog: false,
        homeworkDialog: false,
        revisionNotesDialog: false,
        engagementDialog: false,
        masteryDialog: false
      })
    }
    if (value === 'cancelled') {
      this.setState({
        punctualityDialog: false,
        cancellationDialog: true,
        homeworkDialog: false,
        revisionNotesDialog: false,
        engagementDialog: false,
        masteryDialog: false
      })
    }
    if (value === 'homework') {
      this.setState({
        punctualityDialog: false,
        cancellationDialog: false,
        homeworkDialog: true,
        revisionNotesDialog: false,
        engagementDialog: false,
        masteryDialog: false
      })
    }
    if (value === 'revisionnotes') {
      this.setState({
        punctualityDialog: false,
        cancellationDialog: false,
        homeworkDialog: false,
        revisionNotesDialog: true,
        engagementDialog: false,
        masteryDialog: false
      })
    }
    if (value === 'engagement') {
      this.setState({
        punctualityDialog: false,
        cancellationDialog: false,
        homeworkDialog: false,
        revisionNotesDialog: false,
        engagementDialog: true,
        masteryDialog: false
      })
    }
    if (value === 'mastery') {
      this.setState({
        punctualityDialog: false,
        cancellationDialog: false,
        homeworkDialog: false,
        revisionNotesDialog: false,
        engagementDialog: false,
        masteryDialog: true
      })
    }
  }

  handlePopoverClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const openDialog = Boolean(this.state.anchorEl)
    const {
      currentUser,
      options,
      series,
      optionsMastery,
      seriesMastery,
      punctualityColor,
      homeworkColor,
      revisionNotesColor,
      cancellationColor,
      attendanceEarly,
      cancelledActual,
      filteredAttendanceLate,
      filteredAttendanceTotal,
      filteredCancelledActual,
      homeworkSubmissionTotal,
      revisionSubmissionTotal,
      filteredCancelledTotal,
      filteredOnTimeHWS,
      filteredOnTimeRNS,
      filteredEngagementLessons,
      filteredMasteryLessons,
      filteredLessonParentComments,
      filteredLessonComments,
      filteredLessonTopics,
      filteredLateHWS,
      filteredNoHWS,
      filteredLateRNS,
      filteredNoRNS
    } = this.props

    const {
      punctualityDialog,
      cancellationDialog,
      homeworkDialog,
      revisionNotesDialog,
      engagementDialog,
      masteryDialog
    } = this.state

    let sumEngagement = 0,
      totalSumEngagement = 0,
      engagementPercentage,
      sumMastery = 0,
      totalSumMastery = 0,
      masteryPercentage,
      engagementColor = '#000',
      masteryColor = '#000'
    return (
      <>
        {/* <h2>Lessons</h2> */}
        <Card>
          <CardContent>
            <div className="col-12 align-items-center">
              <div className="row justify-content-around">
                <div className="col-md-3 col-12">
                  <div className="col-md-12 col-12 my-2 py-4 font-weight-medium box-dashboard">
                    <div style={{ fontSize: 25 }}>
                      <div style={{ display: 'grid', justifyContent: 'center' }}>
                        <strong style={{ fontSize: 50, color: punctualityColor }}>
                          {attendanceEarly >= 0 ? `${Math.round(attendanceEarly)}%` : 'N/A'}
                        </strong>
                      </div>
                      <div style={{ display: 'grid', justifyContent: 'center' }}>punctuality </div>
                    </div>
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                      {isMobile ? (
                        <span
                          className="pointer"
                          onClick={(event, value) => {
                            if (value !== null) this.handlePopoverOpen(event, 'punctual')
                          }}>
                          late to {filteredAttendanceLate?.length} out of {filteredAttendanceTotal?.length} lessons ▼
                        </span>
                      ) : (
                        <Tooltip
                          title={
                            <div style={{ fontSize: '1.5vh' }}>
                              <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                                late to these lessons
                              </div>
                              {filteredAttendanceLate?.map((lesson, index) => (
                                <MenuItem key={index}>
                                  {/* {lesson.name.slice(6,-16)}, */}
                                  {moment(lesson.scheduled_on).format('LL')}
                                </MenuItem>
                              ))}
                            </div>
                          }>
                          <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                            late to {filteredAttendanceLate?.length} out of {filteredAttendanceTotal?.length} lessons ▼
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-12">
                  <div className="col-md-12 col-12 my-2 py-4 font-weight-medium box-dashboard">
                    {' '}
                    <div style={{ fontSize: 25 }}>
                      <div style={{ display: 'grid', justifyContent: 'center' }}>
                        <strong
                          style={{
                            fontSize: 50,
                            color: cancellationColor
                          }}>
                          {cancelledActual >= 0 ? `${Math.round(cancelledActual)}%` : 'N/A'}
                        </strong>
                      </div>
                      <div style={{ display: 'grid', justifyContent: 'center' }}>cancellation rate </div>
                    </div>
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                      {isMobile ? (
                        <span
                          className="pointer"
                          onClick={(event, value) => {
                            if (value !== null) this.handlePopoverOpen(event, 'cancelled')
                          }}>
                          cancelled {filteredCancelledActual?.length} out of {filteredCancelledTotal?.length} lessons{' '}
                        </span>
                      ) : (
                        <Tooltip
                          title={
                            <div style={{ fontSize: '1.5vh' }}>
                              <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                                cancelled these lessons
                              </div>
                              {filteredCancelledActual?.map((lesson, index) => (
                                <MenuItem key={index}>{moment(lesson.scheduled_on).format('LL')}</MenuItem>
                              ))}
                            </div>
                          }>
                          <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                            cancelled {filteredCancelledActual?.length} out of {filteredCancelledTotal?.length} lessons
                            ▼
                          </div>
                        </Tooltip>
                      )}
                    </div>{' '}
                  </div>
                </div>
                <div className="col-md-3 col-12">
                  <div className="col-md-12 col-12 my-2 py-4  font-weight-medium box-dashboard">
                    <div style={{ fontSize: 25 }}>
                      <div style={{ display: 'grid', justifyContent: 'center' }}>
                        <strong style={{ fontSize: 50, color: homeworkColor }}>
                          {homeworkSubmissionTotal >= 0 ? `${Math.round(homeworkSubmissionTotal)}%` : 'N/A'}
                        </strong>
                      </div>
                      <div style={{ display: 'grid', justifyContent: 'center', textAlign: 'center' }}>
                        homework submission
                      </div>
                    </div>
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                      {isMobile ? (
                        <span
                          className="pointer"
                          onClick={(event, value) => {
                            if (value !== null) this.handlePopoverOpen(event, 'homework')
                          }}>
                          {filteredOnTimeHWS?.length} submitted on time ▼
                        </span>
                      ) : (
                        <Tooltip
                          title={
                            <div style={{ fontSize: '1.5vh' }}>
                              <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                                {`${filteredLateHWS?.length !== 0 ? filteredLateHWS?.length : ' no'} late submission `}
                              </div>
                              {filteredLateHWS?.map((lesson, index) => (
                                <MenuItem key={index}>{moment(lesson.scheduled_on).format('LL')}</MenuItem>
                              ))}
                              <div className="mt-2" style={{ justifyContent: 'center', display: 'flex' }}>
                                {filteredNoHWS?.length !== 0 ? `${filteredNoHWS?.length} did not submit` : ''}
                              </div>
                              {filteredNoHWS?.map((lesson, index) => (
                                <MenuItem key={index}>{moment(lesson.scheduled_on).format('LL')}</MenuItem>
                              ))}
                            </div>
                          }>
                          <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                            {filteredOnTimeHWS?.length} submitted on time ▼
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-12">
                  <div className="col-md-12 col-12 my-2 py-4  font-weight-medium box-dashboard">
                    <div style={{ fontSize: 25 }}>
                      <div style={{ display: 'grid', justifyContent: 'center' }}>
                        <strong style={{ fontSize: 50, color: revisionNotesColor }}>
                          {revisionSubmissionTotal >= 0 ? `${Math.round(revisionSubmissionTotal)}%` : 'N/A'}
                        </strong>
                      </div>
                      <div style={{ display: 'grid', justifyContent: 'center', textAlign: 'center' }}>
                        revision notes submission{' '}
                      </div>
                    </div>
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                      {isMobile ? (
                        <span
                          className="pointer"
                          onClick={(event, value) => {
                            if (value !== null) this.handlePopoverOpen(event, 'revisionnotes')
                          }}>
                          ▼
                        </span>
                      ) : (
                        <Tooltip
                          title={
                            <div style={{ fontSize: '1.5vh' }}>
                              <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                                {`${filteredLateRNS?.length !== 0 ? filteredLateRNS?.length : ' no'} late submission `}
                              </div>
                              {filteredLateRNS?.map((lesson, index) => (
                                <MenuItem key={index}>{moment(lesson.scheduled_on).format('LL')}</MenuItem>
                              ))}
                              <div className="mt-2" style={{ justifyContent: 'center', display: 'flex' }}>
                                {filteredNoRNS?.length !== 0 ? `${filteredNoRNS?.length} did not submit` : ''}
                              </div>
                              {filteredNoRNS?.map((lesson, index) => (
                                <MenuItem key={index}>{moment(lesson.scheduled_on).format('LL')}</MenuItem>
                              ))}
                            </div>
                          }>
                          <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                            {filteredOnTimeRNS?.length} submitted on time ▼
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-around">
                <div className="col-md-3 col-12">
                  <div
                    className="col-md-12 col-12 my-2 font-weight-medium box-dashboard"
                    style={{
                      paddingTop: isMobile ? '20px' : '85px',
                      paddingBottom: isMobile ? '20px' : '85px'
                    }}>
                    {filteredEngagementLessons?.map(lesson => {
                      {
                        let total =
                          (parseInt(lesson?.lesson_meta?.engagement?.engagementParticipation) +
                            parseInt(lesson?.lesson_meta?.engagement?.engagementDistracted) +
                            parseInt(lesson?.lesson_meta?.engagement?.engagementTiredBored) +
                            parseInt(lesson?.lesson_meta?.engagement?.engagementMotivate) +
                            parseInt(lesson?.lesson_meta?.engagement?.engagementAskQuestions)) /
                          5
                        let overall = Math.round(total)
                        sumEngagement = sumEngagement + overall
                        totalSumEngagement = sumEngagement / filteredEngagementLessons?.length
                      }

                      if (totalSumEngagement >= 1 && totalSumEngagement <= 2) {
                        engagementColor = '#F44336'
                      }
                      if (totalSumEngagement >= 3 && totalSumEngagement <= 4) {
                        engagementColor = '#F28C28'
                      }
                      if (totalSumEngagement >= 5 && totalSumEngagement <= 6) {
                        engagementColor = '#50C878'
                      }

                      engagementPercentage = Math.round((totalSumEngagement / 6) * 100)
                    })}
                    <div style={{ fontSize: 25 }}>
                      <div style={{ display: 'grid', justifyContent: 'center' }}>
                        <strong style={{ fontSize: 50, color: engagementColor }}>
                          {engagementPercentage >= 0 ? `${engagementPercentage}%` : 'N/A'}
                        </strong>
                      </div>
                      <div style={{ display: 'grid', justifyContent: 'center' }}>engagement </div>
                    </div>
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                      {isMobile ? (
                        <span
                          className="pointer"
                          onClick={(event, value) => {
                            if (value !== null) this.handlePopoverOpen(event, 'engagement')
                          }}>
                          Show More ▼
                        </span>
                      ) : (
                        <Tooltip
                          title={
                            <div style={{ fontSize: '1.5vh' }}>
                              <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                                How this is calculated:
                              </div>
                              {`( `}
                              {filteredEngagementLessons?.map((lesson, index) => (
                                <span key={index}>
                                  {Math.round(
                                    (parseInt(lesson?.lesson_meta?.engagement?.engagementParticipation) +
                                      parseInt(lesson?.lesson_meta?.engagement?.engagementDistracted) +
                                      parseInt(lesson?.lesson_meta?.engagement?.engagementTiredBored) +
                                      parseInt(lesson?.lesson_meta?.engagement?.engagementMotivate) +
                                      parseInt(lesson?.lesson_meta?.engagement?.engagementAskQuestions)) /
                                      5
                                  )}{' '}
                                  {index === filteredEngagementLessons?.length - 1 ? '' : '+ '}
                                </span>
                              ))}
                              {`)`} ÷ {filteredEngagementLessons?.length} (no. of lessons) ÷ 6 (score) * 100%
                            </div>
                          }>
                          <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                            Show more ▼
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </div>{' '}
                </div>

                <div className="col-md-9">
                  <div className="col-md-12 my-2 font-weight-medium box-dashboard">
                    {' '}
                    <Chart options={options} series={series} type="line" height="310" />
                  </div>
                </div>
              </div>

              <div className="row justify-content-around">
                <div className="col-md-3 col-12">
                  <div
                    className="col-md-12 col-12 my-2 font-weight-medium box-dashboard"
                    style={{
                      paddingTop: isMobile ? '20px' : '85px',
                      paddingBottom: isMobile ? '20px' : '85px'
                    }}>
                    {filteredMasteryLessons?.map(lesson => {
                      {
                        let total =
                          (parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion) +
                            parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryConfused) +
                            parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding) +
                            parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryParticipative) +
                            parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept)) /
                          5
                        let overall = Math.round(total)
                        sumMastery = sumMastery + overall
                        totalSumMastery = sumMastery / filteredMasteryLessons?.length
                      }

                      if (totalSumMastery >= 1 && totalSumMastery <= 2) {
                        masteryColor = '#F44336'
                      }
                      if (totalSumMastery >= 3 && totalSumMastery <= 4) {
                        masteryColor = '#F28C28'
                      }
                      if (totalSumMastery >= 5 && totalSumMastery <= 6) {
                        masteryColor = '#50C878'
                      }

                      masteryPercentage = Math.round((totalSumMastery / 6) * 100)
                    })}

                    <div style={{ fontSize: 25 }}>
                      <div style={{ display: 'grid', justifyContent: 'center' }}>
                        <strong style={{ fontSize: 50, color: masteryColor }}>
                          {' '}
                          {masteryPercentage >= 0 ? `${masteryPercentage}%` : 'N/A'}
                        </strong>
                      </div>
                      <div style={{ display: 'grid', justifyContent: 'center', textAlign: 'center' }}>
                        mastery of skills{' '}
                      </div>
                    </div>
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                      {isMobile ? (
                        <span
                          className="pointer"
                          onClick={(event, value) => {
                            if (value !== null) this.handlePopoverOpen(event, 'mastery')
                          }}>
                          Show More ▼
                        </span>
                      ) : (
                        <Tooltip
                          title={
                            <div style={{ fontSize: '1.5vh' }}>
                              <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                                How this is calculated:
                              </div>
                              {`( `}
                              {filteredMasteryLessons?.map((lesson, index) => (
                                <span key={index}>
                                  {Math.round(
                                    (parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion) +
                                      parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryConfused) +
                                      parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding) +
                                      parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryParticipative) +
                                      parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept)) /
                                      5
                                  )}{' '}
                                  {index === filteredMasteryLessons?.length - 1 ? '' : '+ '}
                                </span>
                              ))}
                              {`)`} ÷ {filteredMasteryLessons?.length} (no. of lessons) ÷ 6 (score) * 100%
                            </div>
                          }>
                          <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                            Show more ▼
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-9 col-12">
                  <div className="col-md-12 col-12 my-2 font-weight-medium box-dashboard">
                    <Chart options={optionsMastery} series={seriesMastery} type="line" height="310" />
                  </div>
                </div>
              </div>

              <div className="">
                <div className="col-md-12 col-12 my-2 py-4  font-weight-medium box-dashboard">
                  <div style={{ fontSize: 25 }}>Lesson Topics</div>
                  {filteredLessonTopics &&
                    [...filteredLessonTopics].reverse().map((lesson, index) => (
                      <div key={index}>
                        Lesson {index + 1}) {lesson?.lesson_meta?.lesson_topic}
                      </div>
                    ))}
                </div>
              </div>

              <div className="">
                <div className="col-md-12 col-12 my-2 py-4  font-weight-medium box-dashboard">
                  <div style={{ fontSize: 25 }}>Teacher Comments</div>
                  {currentUser._type === PARENT
                    ? filteredLessonParentComments &&
                      filteredLessonParentComments &&
                      [...filteredLessonParentComments].reverse().map((lesson, index) => (
                        <div key={index}>
                          Lesson {index + 1}) {lesson?.lesson_meta?.comment?.comment}
                        </div>
                      ))
                    : filteredLessonComments &&
                      [...filteredLessonComments].reverse().map((lesson, index) => (
                        <div key={index}>
                          Lesson {index + 1}) {lesson?.lesson_meta?.comment?.comment}
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
        <Popover
          id="mouse-over-popover"
          style={{ borderRadius: '20px' }}
          open={openDialog}
          onClose={this.handlePopoverClose}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          disableRestoreFocus>
          {' '}
          <div>
            {punctualityDialog && (
              <>
                <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                  late to these lessons
                </div>
                {filteredAttendanceLate?.map((lesson, index) => (
                  <MenuItem key={index}>
                    {lesson.name}, {moment(lesson.scheduled_on).format('LL')}
                  </MenuItem>
                ))}{' '}
              </>
            )}

            {cancellationDialog && (
              <>
                <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                  cancelled lessons
                </div>
                {filteredCancelledActual?.map((lesson, index) => (
                  <MenuItem key={index}>
                    {lesson.name}, {moment(lesson.scheduled_on).format('LL')}
                  </MenuItem>
                ))}{' '}
              </>
            )}

            {homeworkDialog && (
              <>
                <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                  {`${filteredLateHWS?.length !== 0 ? filteredLateHWS?.length : ' no'} late submission `}
                </div>
                {filteredLateHWS?.map((lesson, index) => (
                  <MenuItem key={index}>
                    {lesson.name}, {moment(lesson.scheduled_on).format('LL')}
                  </MenuItem>
                ))}
                <div className="mt-2" style={{ justifyContent: 'center', display: 'flex' }}>
                  {filteredNoHWS?.length !== 0 ? `${filteredNoHWS?.length} did not submit` : ''}
                </div>
                {filteredNoHWS?.map((lesson, index) => (
                  <MenuItem key={index}>
                    {lesson.name}, {moment(lesson.scheduled_on).format('LL')}
                  </MenuItem>
                ))}
              </>
            )}

            {revisionNotesDialog && (
              <>
                <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                  {`${filteredLateRNS?.length !== 0 ? filteredLateRNS?.length : ' no'} late submission `}
                </div>
                {filteredLateRNS?.map((lesson, index) => (
                  <MenuItem key={index}>
                    {lesson.name}, {moment(lesson.scheduled_on).format('LL')}
                  </MenuItem>
                ))}
                <div className="mt-2" style={{ justifyContent: 'center', display: 'flex' }}>
                  {filteredNoRNS?.length !== 0 ? `${filteredNoRNS?.length} did not submit` : ''}
                </div>
                {filteredNoRNS?.map((lesson, index) => (
                  <MenuItem key={index}>
                    {lesson.name}, {moment(lesson.scheduled_on).format('LL')}
                  </MenuItem>
                ))}
              </>
            )}

            {engagementDialog && (
              <>
                <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                  {/* {`${filteredEngagementLessons?.length !== 0 ? filteredEngagementLessons?.length : ''} `} */}
                </div>
                {`( `}
                {filteredEngagementLessons?.map((lesson, index) => (
                  <span key={index}>
                    {Math.round(
                      (parseInt(lesson?.lesson_meta?.engagement?.engagementParticipation) +
                        parseInt(lesson?.lesson_meta?.engagement?.engagementDistracted) +
                        parseInt(lesson?.lesson_meta?.engagement?.engagementTiredBored) +
                        parseInt(lesson?.lesson_meta?.engagement?.engagementMotivate) +
                        parseInt(lesson?.lesson_meta?.engagement?.engagementAskQuestions)) /
                        5
                    )}{' '}
                    {index === filteredEngagementLessons.length - 1 ? '' : '+ '}
                  </span>
                ))}
                {`)`} ÷ {filteredEngagementLessons.length} (no. of lessons) ÷ 6 (score) * 100%
                {/* <div className="mt-2" style={{ justifyContent: 'center', display: 'flex' }}>
                  {filteredNoRNS?.length !== 0 ? `${filteredNoRNS?.length} did not submit` : ''}
                </div>
               */}
              </>
            )}

            {masteryDialog && (
              <>
                {/* <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                  {`${filteredLateRNS?.length !== 0 ? filteredLateRNS?.length : 'no'} late submission`}
                </div> */}
                {`( `}
                {filteredMasteryLessons?.map((lesson, index) => (
                  <span key={index}>
                    {Math.round(
                      (parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion) +
                        parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryConfused) +
                        parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding) +
                        parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryParticipative) +
                        parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept)) /
                        5
                    )}{' '}
                    {index === filteredMasteryLessons.length - 1 ? '' : '+ '}
                  </span>
                ))}
                {`)`} ÷ {filteredMasteryLessons.length} (no. of lessons) ÷ 6 (score) * 100%
              </>
            )}
          </div>
        </Popover>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassReportToolTip)
