import React from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Button from '@mui/material/Button'
import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Input,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@material-ui/core'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

import _ from 'lodash'
import { Link } from 'react-router-dom'

import countrycodes from 'assets/country_code.json'
import { USER_PROFILE } from 'routes/routes'
import { NumberOnly } from 'utils/format'
import PersonalityParenting from 'components/profile/preferencepayment'

const steps = ['Basic Information', 'Preference & Payment']
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function ParentStepperProfile(props) {
  const [activeStep, setActiveStep] = React.useState(0)
  const [completed, setCompleted] = React.useState({})

  const {
    currentUser,
    handleChange,
    facebook,
    line,
    onFocusFormElement,
    submitChanges,
    email,
    name,
    dateOfBirth,
    gender,
    phoneCountryCode,
    phone,
    formError,

    contactPreference,
    contactPreferenceOther,
    isSaved,
    handleClose,

    handleBlurInput,
    handleCheckboxChange,
    handleAutoCompleteOccupationChange,
    handleAutoCompleteOccupationSpecificChange,
    parentOccupation,
    parentOccupationSpecific,
    parentFamilyBackground,
    parentNetworkingFB,
    parentNetworkingWebsite,
    parentNetworkingFriends,
    parentNetworkingOthersBox,
    parentNetworkingOthersField,

    paymentMode,
    parentInterestedTopicsPublicSpeaking,
    parentInterestedTopicsDebate,
    parentInterestedTopicsCoding,
    parentInterestedTopicsLeadership,
    parentInterestedTopicsWriting,
    parentInterestedTopicsCreativeWriting,
    parentInterestedTopicsFormalWriting,
    parentInterestedTopicsReadingComprehension,
    parentInterestedTopicsLiterature,
    parentInterestedTopicsOthersBox,
    parentInterestedTopicsOthersField,
    parentDateOfRenewal
  } = props

  const totalSteps = () => {
    return steps.length
  }

  const completedSteps = () => {
    return Object.keys(completed).length
  }

  const isLastStep = () => {
    return activeStep === totalSteps() - 1
  }

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps()
  }

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1
    setActiveStep(newActiveStep)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStep = step => () => {
    setActiveStep(step)
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div className="my-5">
          {activeStep === 0 ? (
            <Card>
              <CardContent>
                <h1 className="mb-0">Parent details</h1>
                <div className="row align-items-center mt-3">
                  <div className="col-md-3 col-5 font-weight-medium">Email</div>
                  <div className="col-md-9 col-7">
                    <Input className="w-100 mb-3" type="email" name="email" value={email} disabled />
                  </div>

                  <div className="col-md-3 col-5 font-weight-medium">Name</div>
                  <div className="col-md-9 col-7">
                    <TextField
                      className="w-100 mb-3"
                      type="text"
                      name="name"
                      onChange={handleChange}
                      value={name}
                      error={!!formError.name}
                      helperText={formError.name}
                      onFocus={onFocusFormElement}
                    />
                  </div>

                  <div className="col-md-3 col-5 font-weight-medium mb-2">Gender</div>
                  <div className="col-md-9 col-7 mb-2">
                    <FormControl component="fieldset">
                      <RadioGroup row aria-label="gender" name="gender" value={gender} onChange={handleChange}>
                        <FormControlLabel value="f" control={<Radio color="primary" />} label="Female" />
                        <FormControlLabel value="m" control={<Radio color="primary" />} label="Male" />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <div className="col-md-3 col-5 font-weight-medium mb-2">Date of birth</div>
                  <div className="col-md-9 col-7 mb-2">
                    <TextField
                      type="date"
                      className="mb-3"
                      name="dateOfBirth"
                      onChange={handleChange}
                      value={dateOfBirth}
                      error={!!formError.dateOfBirth}
                      helperText={formError.dateOfBirth}
                      onFocus={onFocusFormElement}
                    />
                  </div>

                  <div className="col-md-3 col-5 font-weight-medium">Phone number</div>
                  <div className="col-md-9 col-7">
                    <div className="row">
                      <div className="col-auto">
                        <Select
                          value={Number(phoneCountryCode)}
                          onChange={handleChange}
                          className="mt-0"
                          name="phoneCountryCode">
                          {countrycodes.map(cc => {
                            return (
                              <MenuItem key={cc.code} value={cc.code}>
                                <span className={`flag ${cc.flag} mr-1`} /> +{cc.code}{' '}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </div>
                      <div className="col">
                        <TextField
                          className="w-100 mb-3"
                          type="tel"
                          name="phone"
                          onChange={handleChange}
                          value={phone}
                          onFocus={onFocusFormElement}
                          InputProps={{ inputComponent: NumberOnly }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-5 font-weight-medium">Facebook URL</div>
                  <div className="col-md-9 col-7">
                    <div className="row">
                      <div className="col">
                        <TextField
                          className="w-100 mb-3"
                          type="tel"
                          name="facebook"
                          onChange={handleChange}
                          value={facebook}
                          onFocus={onFocusFormElement}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-5 font-weight-medium">LINE ID</div>
                  <div className="col-md-9 col-7">
                    <div className="row">
                      <div className="col">
                        <TextField
                          className="w-100 mb-3"
                          type="tel"
                          name="line"
                          onChange={handleChange}
                          value={line}
                          onFocus={onFocusFormElement}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-5 font-weight-medium">Preferred mode of contact</div>
                  <div className="col-md-9 col-7">
                    <div className="row">
                      <div className="col">
                        <RadioGroup
                          row
                          aria-label="contactPreference"
                          name="contactPreference"
                          value={contactPreference}
                          onChange={handleChange}>
                          <FormControlLabel value="line" control={<Radio color="primary" />} label="Line" />
                          <FormControlLabel value="whatsapp" control={<Radio color="primary" />} label="Whatsapp" />
                          <FormControlLabel value="other" control={<Radio color="primary" />} label="Other" />
                        </RadioGroup>
                        {contactPreference === 'other' && (
                          <TextField
                            className="w-100 mb-3"
                            type="text"
                            name="contactPreferenceOther"
                            onChange={handleChange}
                            value={contactPreferenceOther}
                            onFocus={onFocusFormElement}
                            placeholder="e.g. SMS, email"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-row flex-wrap justify-content-between align-items-center mt-3 mr-3">
                  <Link to={USER_PROFILE}>Go Back to Profile</Link>
                  <Button variant="contained" color="primary" className="ml-3" onClick={submitChanges}>
                    Save Changes
                  </Button>
                  {/* <Button variant="outlined" onClick={handleClick}>
                    Open success snackbar
                  </Button> */}
                </div>
              </CardContent>
            </Card>
          ) : activeStep === 1 ? (
            <PersonalityParenting
              currentUser={currentUser}
              handleChange={handleChange}
              handleCheckboxChange={handleCheckboxChange}
              handleAutoCompleteOccupationChange={handleAutoCompleteOccupationChange}
              handleAutoCompleteOccupationSpecificChange={handleAutoCompleteOccupationSpecificChange}
              handleBlurInput={handleBlurInput}
              submitChanges={submitChanges}
              parentOccupation={parentOccupation}
              parentOccupationSpecific={parentOccupationSpecific}
              parentFamilyBackground={parentFamilyBackground}
              parentNetworkingFB={parentNetworkingFB}
              parentNetworkingWebsite={parentNetworkingWebsite}
              parentNetworkingFriends={parentNetworkingFriends}
              parentNetworkingOthersBox={parentNetworkingOthersBox}
              parentNetworkingOthersField={parentNetworkingOthersField}
              paymentMode={paymentMode}
              parentInterestedTopicsPublicSpeaking={parentInterestedTopicsPublicSpeaking}
              parentInterestedTopicsDebate={parentInterestedTopicsDebate}
              parentInterestedTopicsCoding={parentInterestedTopicsCoding}
              parentInterestedTopicsLeadership={parentInterestedTopicsLeadership}
              parentInterestedTopicsWriting={parentInterestedTopicsWriting}
              parentInterestedTopicsCreativeWriting={parentInterestedTopicsCreativeWriting}
              parentInterestedTopicsFormalWriting={parentInterestedTopicsFormalWriting}
              parentInterestedTopicsReadingComprehension={parentInterestedTopicsReadingComprehension}
              parentInterestedTopicsLiterature={parentInterestedTopicsLiterature}
              parentInterestedTopicsOthersBox={parentInterestedTopicsOthersBox}
              parentInterestedTopicsOthersField={parentInterestedTopicsOthersField}
            />
          ) : (
            ''
          )}
          {
            <React.Fragment>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleNext} sx={{ mr: 1 }}>
                  Next
                </Button>
              </Box>
            </React.Fragment>
          }
        </div>
        <Snackbar open={isSaved} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Changes has been successfully saved.
          </Alert>
        </Snackbar>
      </Box>
    </>
  )
}
