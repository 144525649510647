import React from 'react'
import { withRouter } from 'react-router-dom'

const ClassExperience = props => {
  return (
    <section className="bg-white">
      <div className="section__header mb-5">
        <div
          className="title mb-2"
          style={{
            fontSize: '2.3rem',
            fontWeight: '400',
            fontFamily: "'Lato', sans-serif",
            lineHeight: 1.2,
            color: '#000000'
          }}>
          Our uniqueness
        </div>
      </div>
      <div className="container h-100">
        <div className="row align-items-center mb-5">
          <div className="col-12 col-md-9">
            <div
              className="mb-3"
              style={{
                fontSize: '1.7rem',
                fontFamily: "'Lato', sans-serif",
                lineHeight: 1.4,
                color: '#000000',
                padding: '20px',
                border: '2px solid'
              }}>
              <ul>
                <li>
                  We think the way parents think, we understand and sit in the heart of the student, we understand
                  learning type and learning style of each student.
                </li>
                <li> See beyond college. </li>
                <li> Not just ranking or match or lifelong success. </li>
                <li> Expert counseling.</li>
              </ul>
            </div>
          </div>

          <div className="d-flex col-12 col-md-3">
            <img
              src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/image7c.jpg"
              alt="img"
              width="85%"
              className="mb-3 shadow hide-on-mobile"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default withRouter(ClassExperience)
